import React from "react";

const ComingSoon = ({ title }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black p-4 md:p-10">
      <p className="text-primary text-6xl -mt-80 font-bold">
        <span className="text-[#ED4F9D] animate-pulse">{title.toUpperCase()}</span> will come
        soon!
      </p>
    </div>
  );
};

export default ComingSoon;

import React from "react";
import AlertImg from "../assets/images/alert-circle.png";
import { useSelector } from "react-redux";
import { getCurrentEntity } from "../redux/slices/entities";
import { getKYCLoading } from "../redux/slices/entityKYC";

const NoKYC = (noKyc) => {
  const currentEntity = useSelector(getCurrentEntity);
  return (
    <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
      <div className="top-5 z-20 flex justify-between w-full px-0">
        <p className="flex items-center text-primary font-bold text-2xl">
          No KYC
          <img
            src={AlertImg}
            alt="alert"
            className="mx-2"
            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          />
        </p>
      </div>
      <div className=" w-full py-5">
        <p className=" text-primary text-center font-bold text-xl">
          <span className=" font-extrabold tracking-wider uppercase">
            {Object.values(noKyc)[0].comment}
          </span>{" "}
          {/* follows a risk-based KYC procedure and asks customers to complete a
          KYC procedure only if their threat detection system flags the
          transaction. */}
        </p>
      </div>
    </div>
  );
};

export default NoKYC;

import React, { useEffect, useState } from "react";
import NonTiered from "../components/NonTiered";
import Tiered from "../components/Tiered";
import { useSelector } from "react-redux";
import { getTieredKYC, getNonTieredKYC,getNoKYC } from "../redux/slices/entityKYC";
import NoKYC from "../components/NoKYC";

const KYC = () => {
  const [isTiered, setIsTiered] = useState(true);
  const [isReqModal, setIsReqModal] = useState(false);
  const [reqEmail, setReqEmail] = useState("");

  const tieredKYC = useSelector(getTieredKYC);
  const nonTieredKYC = useSelector(getNonTieredKYC);
  const noKYC = useSelector(getNoKYC);

  const onSubmit = () => {
    setIsReqModal(false);
    console.log("Requested with this E-mail:", reqEmail);
    setReqEmail("");
  };

  useEffect(() => {}, [tieredKYC, nonTieredKYC,noKYC]);

  return (
    <>
      {nonTieredKYC.length > 0 || tieredKYC.length > 0 ? (
        <div className="flex flex-col pt-[100px] pb-10 h-screen overflow-scroll scrollbar-hide bg-black">
          {/* <div className=" relative flex justify-end z-20">
            <div className="flex items-center justify-center m-5 gap-5">
              <p className=" whitespace-nowrap text-primary font-bold text-2xl tracking-wider">
                AML & KYC REQUEST
              </p>
              <button
                className=" bg-normal rounded text-primary text-sm font-bold w-60 h-[58px] hover:opacity-90 transition duration-200"
                onClick={() => setIsReqModal(!isReqModal)}
              >
                REQUEST
              </button>
              {isReqModal && (
                <form
                  className="absolute top-24 right-5 bg-[#161616] w-80 rounded-2xl py-5 px-8 shadow-lg shadow-[#5d4f9b]"
                  action="/#"
                  onSubmit={() => onSubmit()}
                >
                  <p className=" text-primary font-bold text-2xl pt-4 pb-8">
                    AML & KYC REQUEST
                  </p>
                  <div>
                    <p className=" text-primary text-left p-2">Email</p>
                    <input
                      placeholder="My Email"
                      type="email"
                      required
                      value={reqEmail}
                      onChange={(e) => setReqEmail(e.target.value)}
                      className=" bg-black h-12 w-60 px-5 text-primary rounded-lg"
                    />
                  </div>
                  <button
                    type="submit"
                    className=" bg-normal rounded text-primary text-sm font-bold w-60 h-[58px] mt-8 hover:opacity-90"
                  >
                    REQUEST
                  </button>
                </form>
              )}
            </div>
          </div> */}
          {tieredKYC.length > 0 ? (
            <Tiered tieredData={tieredKYC} setIsTiered={setIsTiered} />
          ) : (
            <NonTiered nonTieredData={nonTieredKYC} setIsTiered={setIsTiered} />
          )}
        </div>
      ) : (
        <div>
          {noKYC.length > 0 ? (
            <div className="flex flex-col pt-[100px] pb-10 h-screen overflow-scroll scrollbar-hide bg-black">
              <NoKYC noKYC={noKYC}/>
            </div>
          ):(
            <div className="flex flex-col pt-[100px] pb-10 h-screen overflow-scroll scrollbar-hide bg-black">
              <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
                <div className="top-5 z-20 flex justify-between w-full px-0">
                  <p className="flex items-center text-primary font-bold text-2xl">
                    No data
                  </p>
                </div>
              </div>   
            </div>       
          )}
        </div>
      )}
    </>
  );
};

export default KYC;

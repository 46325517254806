import React from "react";
import { useSelector } from "react-redux";
import { getAlerts, getAlertsLoading } from "../redux/slices/alert";
import RegulatoryAlertTable from "./RegulatoryAlertTable";

const RegulatoryWarningCard = () => {
  const alertsLoading = useSelector(getAlertsLoading);
  const alerts = useSelector(getAlerts);
  const headerTitleClass =
    "px-2 md:px-12 py-2 font-bold text-primary text-lg text-center";

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + " . . ." : str;
  };

  return (
    <RegulatoryAlertTable alerts={alerts} alertsLoading={alertsLoading} headerTitleClass={headerTitleClass} truncate={truncate} showEntityName={false}/>
  );
};

export default RegulatoryWarningCard;

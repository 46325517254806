import React from "react";
import { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { changeCurrentEntity, getEntityList } from "../redux/slices/entities";
import { fetchLicenses } from "../redux/slices/licenses";
import { useNavigate } from "react-router";
import { SearchIcon } from "../features/Symbols";
import { fetchLegalInfo, fetchVaspsByEntity } from "../redux/slices/legalInfo";

const Search = () => {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");

  const entityList = useSelector(getEntityList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemChanged = (item) => {
    dispatch(fetchLicenses({ payload: item }));
    dispatch(fetchLegalInfo({ payload: item }));
    dispatch(fetchVaspsByEntity({ payload: item }));
    dispatch(changeCurrentEntity(item));
    localStorage.setItem("current-entity", item);
    navigate("/legal-info", { replace: true });
  };
  const filteredEntitiesList =
    query === ""
      ? []
      : entityList.filter((unit) =>
          unit
            ?.toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  return (
    <div className="flex justify-center items-center w-full max-w-[500px] px-3 py-0 md:py-5 z-50 flex-1 min-w-0">
      <div className="w-full">
        <Combobox value={selected} onChange={itemChanged}>
          <div className="relative">
            <div className="relative w-full text-left sm:text-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon />
              </div>
              <Combobox.Input
                className="w-full border-[1px] border-[#3D3E3F] rounded-[12px] h-12 pl-10 pr-10 bg-black text-search text-[14px] min-w-0"
                displayValue="Search anything..."
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-black py-0 text-base ring-1 ring-black ring-opacity-5 sm:text-sm shadow-md shadow-[#252233]">
                {filteredEntitiesList.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-search">
                    No entity found.
                  </div>
                ) : (
                  filteredEntitiesList.map((unit, i) => (
                    <Combobox.Option
                      key={"entities" + i}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? "bg-primary text-search" : "text-search"
                        }`
                      }
                      value={unit}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate text-[14px] text-search ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {unit}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-search">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default Search;

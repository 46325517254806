import axios from "axios";
import { config } from "../../helper/config";
import { filterCountriesToCode } from "../../features/GeoLocation";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchVasps = createAsyncThunk("vasps/getAPI", async (payload) => {
  const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
  const response = await axios.get(
    `${config.API_BASE_URL}/vasps?limit=${payload.limit}&offset=${payload.offset}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return [response.data, payload.type];
});

export const fetchFilteredVasps = createAsyncThunk(
  "filteredVasps/getAPI",
  async (payload) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const countries = filterCountriesToCode(payload.country);
    const response = await axios.get(
      `${config.API_BASE_URL}/vasps?country=${countries[0]}&holds_permission_issued_in=${countries[2]}&offer_services_in=${countries[3]}&has_legal_entity=${countries[1]}&limit=${payload.limit}&offset=${payload.offset}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);
const initialState = {
  vasps: [],
  filteredVasps: [],
  vaspsAll: [],
  vaspsLoading: "idle",
  filteredVaspsLoading: "idle",
  selectedCountry: "",
  currentPage: 0,
  linesPerPage: 10,
  vaspsViewMode: "normal",
};

const vaspsslice = createSlice({
  name: "vasps",
  initialState,
  reducers: {
    changeCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    changeLinesPerPage: (state, { payload }) => {
      state.linesPerPage = payload;
    },
    changeVaspsViewMode: (state, { payload }) => {
      state.vaspsViewMode = payload;
    },
    changeSelectedCountry: (state, { payload }) => {
      state.selectedCountry = payload;
    },
    initVasps: (state) => {
      state.vasps = [];
    },
    initFilteredVasps: (state) => {
      state.filteredVasps = [];
    },
    initLoading: (state) => {
      state.vaspsLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVasps.pending, (state, action) => {
      state.vaspsLoading = "pending";
    });
    builder.addCase(fetchVasps.fulfilled, (state, action) => {
      state.vaspsLoading = "idle";
      if (action.payload[1] === "normal") {
        state.vasps = action.payload[0];
      } else if (action.payload[1] === "filter") {
        state.vaspsAll = action.payload[0];
      }
    });
    builder.addCase(fetchVasps.rejected, (state, action) => {
      state.vaspsLoading = "failed";
    });
    builder.addCase(fetchFilteredVasps.pending, (state, action) => {
      state.filteredVaspsLoading = "pending";
    });
    builder.addCase(fetchFilteredVasps.fulfilled, (state, action) => {
      state.filteredVaspsLoading = "idle";
      state.filteredVasps = action.payload;
    });
    builder.addCase(fetchFilteredVasps.rejected, (state, action) => {
      state.filteredVaspsLoading = "failed";
    });
  },
});

export const {
  changeCurrentPage,
  changeLinesPerPage,
  changeVaspsViewMode,
  changeSelectedCountry,
  initVasps,
  initFilteredVasps,
  initLoading,
} = vaspsslice.actions;

export const getVasps = (state) => state.vasps.vasps;
export const getFilteredVasps = (state) => state.vasps.filteredVasps;
export const getVaspsAll = (state) => state.vasps.vaspsAll;
export const getVaspsLoading = (state) => state.vasps.vaspsLoading;
export const getFilteredVaspsLoading = (state) =>
  state.vasps.filteredVaspsLoading;
export const getCurrentPage = (state) => state.vasps.currentPage;
export const getLinesPerPage = (state) => state.vasps.linesPerPage;
export const getVaspsViewMode = (state) => state.vasps.vaspsViewMode;
export const getSelectedCountry = (state) => state.vasps.selectedCountry;

export default vaspsslice.reducer;

import React from "react";
import ComingSoon from "./ComingSoon";

const Network = () => {
  return (
    <div className="bg-black pt-[88px] pb-10 h-screen overflow-scroll scrollbar-hide">
      <ComingSoon title="Network" />
    </div>
  );
};

export default Network;

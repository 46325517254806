import React, { useState } from "react";
import Bell from "../assets/images/bell.png";
import DownArrow from "../assets/images/down-arrow.png";
import SearchIcon from "../assets/images/search.png";
import Search from "./Search";
import { useSelector } from "react-redux";
import Logo from "../assets/images/logo2.png";
import { useDispatch } from "react-redux";
import {
  getTabName,
  changeTabListOpen,
  changeTabName,
} from "../redux/slices/tabs";
import {
  changeCurrentEntity,
  getCurrentEntity,
} from "../redux/slices/entities";
import { useNavigate } from "react-router";

const Header = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const currentEntity = useSelector(getCurrentEntity);
  const tabName = useSelector(getTabName);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <div className="fixed hidden md:flex flex-col w-full py-4 md:py-0 md:flex-row justify-between md:h-[88px] bg-primary items-center z-50 shadow-sm shadow-[#555555]">
        <div className="flex items-center">
          <div
            className="flex h-[50px] md:w-[250px] items-center cursor-pointer"
            onClick={() => {
              navigate("/home", { replace: true });
              dispatch(changeCurrentEntity(""));
              dispatch(changeTabName("Home"));
            }}
          >
            <img src={Logo} alt="logo" className="h-16 px-4" />
          </div>
          <div className="flex flex-col items-start pl-8">
            <p className="text-primary text-[24px] font-semibold md:w-44 lg:w-auto md:truncate">
              {tabName}
            </p>
            <p className="text-entity text-[12px] font-semibold pt-2">
              {currentEntity.toUpperCase()}
            </p>
          </div>
        </div>
        <Search />
        <div className="flex items-center mr-8">
          {/* <div className="mr-8">
            <img src={Bell} alt="bell" />
          </div>
          <div className="flex items-center">
            <div className="w-10 h-10 bg-hot rounded-full mr-3"></div>
            <button onClick={() => alert("Coming soon...")}>
              <img src={DownArrow} alt="down" />
            </button>
          </div> */}
        </div>
      </div>
      <div className="fixed flex flex-col md:hidden w-full py-0 justify-between bg-primary items-center z-50 shadow-sm shadow-[#555555]">
        <div className="flex items-center justify-between w-full">
          <div
            className="flex h-20 md:w-[250px] items-center"
            onClick={() => {
              navigate("/home", { replace: true });
              dispatch(changeCurrentEntity(""));
              dispatch(changeTabName("Home"));
            }}
          >
            <img src={Logo} alt="logo" className="h-12 px-4" />
          </div>
          <div className="flex items-center mr-4">
            {/* <div className="mr-8">
              <img src={Bell} alt="bell" />
            </div>
            <div className="flex items-center">
              <div className="w-10 h-10 bg-hot rounded-full mr-3"></div>
              <button onClick={() => alert("Coming soon...")}>
                <img src={DownArrow} alt="down" />
              </button>
            </div> */}

            {currentEntity && (
              <>
                <div
                  className="flex h-16 md:w-[250px] items-center"
                  onClick={() => {
                    setSearchOpen(!searchOpen);
                  }}
                >
                  <img src={SearchIcon} alt="logo" className="px-6" />
                </div>
                <div
                  className="HAMBURGER-ICON space-y-1.5"
                  onClick={() => dispatch(changeTabListOpen(true))}
                >
                  <span className="block h-0.5 w-6 bg-white"></span>
                  <span className="block h-0.5 w-6 bg-white"></span>
                  <span className="block h-0.5 w-6 bg-white"></span>
                </div>
              </>
            )}
          </div>
        </div>
        {searchOpen && <Search />}
        {currentEntity && (
          <div className="flex items-center justify-between w-full px-4 py-2">
            <div className="flex w-full items-center justify-between">
              <p className="text-primary text-left text-xl font-semibold">
                {tabName}
              </p>
              <p className="text-entity text-right text-xs font-semibold pt-2">
                {currentEntity.toUpperCase()}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchLegalInfo = createAsyncThunk(
  "legalInformation/getAPI",
  async ({ payload }) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/legal-information/?entity=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchVaspsByEntity = createAsyncThunk(
  "legalVaspsByEntity/getAPI",
  async ({ payload }) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/vasps?q=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  legalInfo: [],
  vasps: [],
  legalInfoMarkers: [],
  vaspsMarkers: [],
  legalRegion: {},
  vaspsRegion: {},
  legalInfoLoading: "idle",
  vaspsLoading: "idle",
  markersLoading: "idle",
};

const legalInfoslice = createSlice({
  name: "legal-information",
  initialState,
  reducers: {
    changeLegalMarkers: (state, { payload }) => {
      state.legalInfoMarkers = payload;
      state.markersLoading = "idle";
    },
    changeVaspsMarkers: (state, { payload }) => {
      state.vaspsMarkers = payload;
    },
    changeLegalRegion: (state, { payload }) => {
      state.legalRegion = payload;
    },
    changeVaspsRegion: (state, { payload }) => {
      state.vaspsRegion = payload;
    },
    changeLegalMarkerLoading: (state, { payload }) => {
      state.markersLoading = payload;
    },
    initLegalInfoLoading: (state) => {
      state.legalInfoLoading = "idle";
    },
    initVaspsLoading: (state) => {
      state.vaspsLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLegalInfo.pending, (state, action) => {
      state.legalInfoLoading = "pending";
    });
    builder.addCase(fetchLegalInfo.fulfilled, (state, action) => {
      state.legalInfoLoading = "idle";
      state.legalInfo = action.payload;
    });
    builder.addCase(fetchLegalInfo.rejected, (state, action) => {
      state.legalInfoLoading = "failed";
    });
    builder.addCase(fetchVaspsByEntity.pending, (state, action) => {
      state.vaspsLoading = "pending";
    });
    builder.addCase(fetchVaspsByEntity.fulfilled, (state, action) => {
      state.vaspsLoading = "idle";
      state.vasps = action.payload;
    });
    builder.addCase(fetchVaspsByEntity.rejected, (state, action) => {
      state.vaspsLoading = "failed";
    });
  },
});

export const {
  changeLegalMarkers,
  changeVaspsMarkers,
  changeLegalRegion,
  changeVaspsRegion,
  initLegalInfoLoading,
  initVaspsLoading,
  changeLegalMarkerLoading,
} = legalInfoslice.actions;

export const getLegalInfo = (state) => state.legalInfo.legalInfo;
export const getVasps = (state) => state.legalInfo.vasps;
export const getLegalInfoMarkers = (state) => state.legalInfo.legalInfoMarkers;
export const getVaspsMarkers = (state) => state.legalInfo.vaspsMarkers;
export const getLegalRegion = (state) => state.legalInfo.legalRegion;
export const getVaspsRegion = (state) => state.legalInfo.vaspsRegion;
export const getLegalInfoLoading = (state) => state.legalInfo.legalInfoLoading;
export const getVaspsLoading = (state) => state.legalInfo.vaspsLoading;
export const getLegalMarkerLoading = (state) => state.legalInfo.markersLoading;

export default legalInfoslice.reducer;

import React, { useEffect, useCallback, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  getToastData,
  getToastType,
  toastChanged,
} from "../redux/slices/toast";
import { fetchEntities } from "../redux/slices/entities";
import HomeEntityList from "../components/HomeEntityList";
import RegAlertsView from "../components/Dashboard/RegAlertsView";
import RegulatoryAnalytics from "../components/Dashboard/RegulatoryAnalytics";
import {
  changeCurrentPage,
  changeLinesPerPage,
  fetchFilteredVasps,
  fetchVasps,
  getCurrentPage,
  getFilteredVasps,
  getLinesPerPage,
  getVasps,
  getVaspsAll,
  getVaspsLoading,
  initFilteredVasps,
  initLoading,
} from "../redux/slices/vasps";
import { getAlerts,fetchAlerts } from "../redux/slices/regulatory-alerts";
import { getLicenses,fetchLicenses } from "../redux/slices/allLicenses";
import { loginClear } from "../redux/slices/login";
import FilterBy from "../components/FilterBy";
import { fetchOnlyCountries } from "../redux/slices/onlyCountries";
import {
  changeFilters,
  getFilters,
  removeFilterAll,
} from "../redux/slices/filters";
import { Chip } from "../components/Chip";
import GeoAllocationVasps from '../components/Dashboard/GeoAllocationVasps';

const Home = () => {
  const [counter, setCounter] = useState(0);
  const [section,setSection] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastData = useSelector(getToastData);
  const toastType = useSelector(getToastType);
  const vaspsLoading = useSelector(getVaspsLoading);
  const vasps = useSelector(getVasps);
  const filteredVasps = useSelector(getFilteredVasps);
  const regulatoryAlerts = useSelector(getAlerts);
  const licenses = useSelector(getLicenses);
  const vaspsAll = useSelector(getVaspsAll);
  const currentPage = useSelector(getCurrentPage);
  const linesPerPage = useSelector(getLinesPerPage);
  const filters = useSelector(getFilters);

  useEffect(() => {
    if (vaspsLoading === "failed") {
      dispatch(
        toastChanged({
          data: "Your session has expired, please login again!",
          type: "error",
        })
      );

      dispatch(loginClear());
      dispatch(initLoading());
      localStorage.removeItem("vasp-token");
      navigate("/", { replace: true });
    }
  }, [vaspsLoading]);

  const handleFilteredVasps = (payload) => {
    payload["country"] = filters;
    dispatch(fetchFilteredVasps(payload));
  };

  const Button2 = ({ content, onClick, active, disabled }) => {
    return (
      <button
        className={`flex flex-col cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
      ${
        disabled
          ? "text-[#2563EB] cursor-not-allowed"
          : "hover:bg-[#608ff5] hover:text-[#fff]"
      }
      ${active ? "bg-[#2563EB] text-[#fff]" : "bg-white text-[#2563EB]"}
      `}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
    );
  };

  const PaginationNav1 = ({
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
  }) => {
    const renderPageLinks = useCallback(() => {
      if (pageCount === 0) return null;
      const visiblePageButtonCount = counter === 4 ? 5 : 5;
      let numberOfButtons =
        pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
      const pageIndices = [pageIndex];
      numberOfButtons--;
      [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
        const pageNumberBefore = pageIndices[0] - 1;
        const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
        if (
          pageNumberBefore >= 0 &&
          (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
        ) {
          pageIndices.unshift(pageNumberBefore);
        } else {
          pageIndices.push(pageNumberAfter);
        }
      });
      return pageIndices.map((pageIndexToMap) => (
        <li key={pageIndexToMap}>
          <Button2
            content={pageIndexToMap + 1}
            onClick={() => {
              dispatch(changeCurrentPage(pageIndexToMap));
              if (counter === 4) {
                dispatch(
                  fetchVasps({
                    limit: linesPerPage,
                    offset: pageIndexToMap * linesPerPage,
                    type: "normal",
                  })
                );
              } else if (counter < 4) {
                handleFilteredVasps({
                  limit: linesPerPage,
                  offset: pageIndexToMap * linesPerPage,
                });
              }
            }}
            active={pageIndex === pageIndexToMap}
            disabled={!canNextPage && pageIndex < pageIndexToMap}
          />
        </li>
      ));
    }, [pageCount, pageIndex]);
    return (
      <ul className="flex gap-2">
        {counter === 4 && (
          <li>
            <Button2
              content={
                <div className="flex ml-1">
                  <FaChevronLeft size="0.6rem" />
                  <FaChevronLeft size="0.6rem" className="-translate-x-1/2" />
                </div>
              }
              onClick={() => {
                dispatch(changeCurrentPage(0));
                dispatch(
                  fetchVasps({ limit: linesPerPage, offset: 0, type: "normal" })
                );
              }}
              disabled={!canPreviousPage}
            />
          </li>
        )}
        <li>
          <Button2
            content={
              <div className="flex">
                <FaChevronLeft size="0.6rem" />
              </div>
            }
            onClick={() => {
              dispatch(changeCurrentPage(currentPage - 1));
              if (counter === 4) {
                dispatch(
                  fetchVasps({
                    limit: linesPerPage,
                    offset: (currentPage - 1) * linesPerPage,
                    type: "normal",
                  })
                );
              } else if (counter < 4) {
                handleFilteredVasps({
                  limit: linesPerPage,
                  offset: (currentPage - 1) * linesPerPage,
                });
              }
            }}
            disabled={!canPreviousPage}
          />
        </li>
        {renderPageLinks()}
        <li>
          <Button2
            content={
              <div className="flex">
                <FaChevronRight size="0.6rem" />
              </div>
            }
            onClick={() => {
              dispatch(changeCurrentPage(currentPage + 1));
              if (counter === 4) {
                dispatch(
                  fetchVasps({
                    limit: linesPerPage,
                    offset: (currentPage + 1) * linesPerPage,
                    type: "normal",
                  })
                );
              } else if (counter < 4) {
                handleFilteredVasps({
                  limit: linesPerPage,
                  offset: (currentPage + 1) * linesPerPage,
                });
              }
            }}
            disabled={!canNextPage}
          />
        </li>
        {counter === 4 && (
          <li>
            <Button2
              content={
                <div className="flex ml-1">
                  <FaChevronRight size="0.6rem" />
                  <FaChevronRight size="0.6rem" className="-translate-x-1/2" />
                </div>
              }
              onClick={() => {
                dispatch(changeCurrentPage(pageCount - 1));
                dispatch(
                  fetchVasps({
                    limit: linesPerPage,
                    offset: (pageCount - 1) * linesPerPage,
                    type: "normal",
                  })
                );
              }}
              disabled={!canNextPage}
            />
          </li>
        )}
      </ul>
    );
  };

  const PaginationNav1Presentation = () => {
    const pageCount = Math.ceil(vaspsAll?.length / linesPerPage);
    return (
      <div className="flex gap-3 flex-wrap py-2 md:py-0">
        <PaginationNav1
          canPreviousPage={currentPage > 0}
          canNextPage={
            counter === 4 ? currentPage < pageCount - 1 : filteredVasps.length
          }
          pageCount={pageCount}
          pageIndex={currentPage}
        />
      </div>
    );
  };

  const changeLines = (e) => {
    dispatch(changeLinesPerPage(e.target.value));
    dispatch(fetchVasps({ limit: e.target.value, offset: 0, type: "normal" }));
    handleFilteredVasps({
      limit: e.target.value,
      offset: 0,
    });
    dispatch(changeCurrentPage(0));
  };

  const emptyCounter = (data) => {
    let temp = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].value.length === 0) temp++;
    }
    if (temp > 0 && data[0].value === "") dispatch(initFilteredVasps());
    setCounter(temp);
  };

  useEffect(() => {
    if (toastType === "success") {
      toast.success(toastData, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [toastData]);

  useEffect(() => {}, [vasps, filteredVasps,regulatoryAlerts,licenses]);
  useEffect(() => {
    emptyCounter(filters);
    dispatch(changeCurrentPage(0));
    handleFilteredVasps({
      limit: linesPerPage,
      offset: 0,
      country: filters
    });
  }, [filters]);

  useEffect(() => {
    const userToken = localStorage.getItem("vasp-token");
    const storedFilters = JSON.parse(localStorage.getItem("filters"));
    if (storedFilters) {
      dispatch(changeFilters(storedFilters));
    }
    if (!userToken) {
      navigate("/", { replace: true });
    } else {
      dispatch(fetchLicenses());
      dispatch(fetchAlerts());
      dispatch(fetchVasps({ limit: linesPerPage, offset: 0, type: "normal" }));
      dispatch(fetchVasps({ limit: 5000, offset: 0, type: "filter" }));
      dispatch(fetchEntities());
      dispatch(fetchOnlyCountries());
    }
  }, []);
  return (
    <>
      <Header />
      <div className="bg-black pt-[50px] md:pt-[50px] pb-10 h-screen overflow-scroll scrollbar-hide">
        <div style={{ marginTop:'5%',marginLeft:'30%' }}>
          <div style={{color:'#fff',backgroundColor:(section === 0?'blue':'black'),width:'200px',float:'left',borderRadius:'10px',cursor:'pointer'}} onClick={()=>{setSection(0)}}>Vasp directory</div>
          <div style={{color:'#fff',backgroundColor:(section === 2?'blue':'black'),width:'200px',float:'left',borderRadius:'10px',cursor:'pointer'}} onClick={()=>{setSection(2)}}>Geo Analytics</div>
          <div style={{color:'#fff',backgroundColor:(section === 3?'blue':'black'),width:'200px',float:'left',borderRadius:'10px',cursor:'pointer'}} onClick={()=>{setSection(3)}}>Regulatory Analytics</div>
          <div style={{color:'#fff',backgroundColor:(section === 1?'blue':'black'),width:'200px',float:'left',borderRadius:'10px',cursor:'pointer'}} onClick={()=>{setSection(1)}}>Alerts</div>
        </div>
        <div style={{ clear:'both' }}></div>
      {section === 1?
        (regulatoryAlerts) &&
          <>
            <RegAlertsView/>
          </>
        
      :null}
      {section === 2?
          <GeoAllocationVasps/>
        :null
      }
      {section === 3?
          <RegulatoryAnalytics/>
        :null
      }
      {section === 0?
      <>
        <div className="flex justify-between">
          <div className="flex flex-wrap items-center gap-5 px-12 pt-20">
            {filters?.map(
              (item, i) =>
                item.value.length && <Chip item={item} key={"chip" + i} />
            )}
            {counter < 3 && (
              <p
                className=" text-blue underline cursor-pointer"
                onClick={() => dispatch(removeFilterAll())}
              >
                Remove all filters
              </p>
            )}
          </div>
          <FilterBy />
        </div>
        
       
        <HomeEntityList vasps={counter < 4 ? filteredVasps : vasps} />
       
        <div className="flex flex-col md:flex-row items-center justify-between mt-4 md:mt-0 px-12">
          {counter === 4 && (
            <p className="text-primary">
              Page <span className="font-bold">{currentPage + 1}</span> of{" "}
              <span className="font-bold">
                {Math.ceil(vaspsAll?.length / linesPerPage)}
              </span>{" "}
              - Showing{" "}
              <span className="font-bold">
                {currentPage * linesPerPage + 1}
              </span>{" "}
              of{" "}
              <span className="font-bold">
                {(currentPage + 1) * linesPerPage}
              </span>
            </p>
          )}
          <PaginationNav1Presentation />
          <div className="flex items-center">
            <p className="text-primary whitespace-nowrap px-4">
              Lines per page
            </p>
            <select
              id="lines"
              value={linesPerPage}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg outline-none focus:outline-none block w-full py-1 px-2"
              onChange={(e) => changeLines(e)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        </>
      :null}
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;

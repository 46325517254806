import React from "react";
// import DotImg from "../assets/images/dots.png";
import {
  CheckMark,
  CircleLoading,
  DeleteMark,
  NoItems,
} from "../features/Symbols";
import { getKYCLoading } from "../redux/slices/entityKYC";
import { useSelector } from "react-redux";
// import AlertImg from "../assets/images/alert-circle.png";

const NonTiered = ({ nonTieredData, setIsTiered }) => {
  const kycLoading = useSelector(getKYCLoading);
  const rowTextClass = "flex flex-wrap px-2 md:px-5 py-2 text-primary text-lg";
  return (
    <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
      <div className="top-5 z-20 flex justify-between w-full px-0">
        <p className="flex items-center text-primary font-bold text-2xl">
          Non-Tiered
          {/* <img
            src={AlertImg}
            alt="alert"
            className="mx-2"
            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          /> */}
        </p>
        {/* <button onClick={() => setIsTiered(true)} title="To Tiered">
          <img src={DotImg} alt="dots" />
        </button> */}
      </div>
      <div className="flex flex-col w-full">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 scrollbar-hide">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              {kycLoading === "pending" ? (
                <CircleLoading />
              ) : (
                <table className="min-w-full text-left text-sm font-light">
                  <tbody>
                    {nonTieredData !== null &&
                      nonTieredData?.map((item, i) => (
                        <tr
                          className={`transition duration-300 ease-in-out hover:bg-primary ${
                            i !== nonTieredData?.length - 1
                              ? " border-b-[1px] border-[#F1F4F9]"
                              : ""
                          }`}
                          key={i}
                        >
                          <td
                            className={`px-2 md:px-4 py-4 font-bold whitespace-nowrap ${rowTextClass}`}
                          >
                            {item.type}
                          </td>
                          <td className="px-2 md:px-4 py-4 font-medium">
                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                              <p
                                className={`flex min-w-[270px] justify-center text-left ${rowTextClass} font-normal`}
                              >
                                {item.value === "Yes" || item.value === true ? (
                                  <CheckMark />
                                ) : item.value === "No" ||
                                  item.value === false ? (
                                  <DeleteMark />
                                ) : (
                                  item.value
                                )}
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {nonTieredData.length === 0 && kycLoading !== "pending" && (
                <NoItems />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NonTiered;

import React from "react";
import WebTrafficMap from "../components/WebTrafficMap";
import SupportedCountries from "../components/SupportedCountries";
import { useSelector } from "react-redux";
import {
  getAllCountries,
  getSupportedCountries,
} from "../redux/slices/supportedcountries";
import { getLicenses } from "../redux/slices/licenses";
import { getCurrentEntity } from "../redux/slices/entities";
import GeoMapJVector from "../components/GeoMapJVector";
import GeoResMap from "../components/GeoResMap";
import GeoResNotes from "../components/GeoResNotes";

const GeoInfo = () => {
  const supportedCData = useSelector(getSupportedCountries);
  const allCountries = useSelector(getAllCountries);
  const currentEntity = useSelector(getCurrentEntity);
  const licenses = useSelector(getLicenses);

  return (
    <div className="bg-black pt-16 md:pt-24 pb-10 h-screen overflow-scroll scrollbar-hide">
      {currentEntity && (
        <GeoMapJVector supportedCData={supportedCData} licenses={licenses} />
      )}
      {currentEntity && <SupportedCountries allCountries={allCountries} licenses={licenses} />}
      {/* {currentEntity && <GeoResMap />} */}
      {currentEntity && <GeoResNotes />}
      {/* {currentEntity && <WebTrafficMap />} */}
    </div>
  );
};

export default GeoInfo;

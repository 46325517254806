import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  tension: 0.5,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#809FB8",
        boxWidth: 18,
        boxHeight: 18,
        padding: 20,
        useBorderRadius: true,
        borderRadius: 5,
        font: {
          size: 14
        }
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 10,
        font: {
          size: 16
        },
      }
    },
    y: {
      position: "left",
      suggestedMax: 30000,
      grid: {
        display: true,
        color: "rgba(56, 68, 85, 0.4)",
      },
      ticks: {
        callback: (v) => {
          return `${v / 1000}k`
        },
        font: {
          size: 16
        },
      }
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
  }
};

const labels = [
  "01.Nov",
  "02.Nov",
  "03.Nov",
  "04.Nov",
  "05.Nov",
  "06.Nov",
  "07.Nov",
  "08.Nov",
  "09.Nov",
  "10.Nov",
  "11.Nov",
  "12.Nov",
  "13.Nov",
  "14.Nov",
  "15.Nov",
  "16.Nov",
  "17.Nov",
  "18.Nov",
  "19.Nov",
  "20.Nov",
  "21.Nov",
  "22.Nov",
  "23.Nov",
  "24.Nov",
  "25.Nov",
  "26.Nov",
  "27.Nov",
  "28.Nov",
  "29.Nov",
  "30.Nov",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Men",
      data: [
        0, 6000, 9000, 10500, 12000, 14000, 17000, 18000, 17000, 19000, 16000,
        12000, 11000, 17000, 19000, 18000, 6000, 5000, 12000, 13000, 15000,
        14000, 16000, 15000, 12000, 17000, 18000, 11000, 13000, 8000,
      ],
      borderColor: "rgba(26, 213, 152, 0)",
      backgroundColor: "rgb(26, 213, 152)",
      pointRadius: 0,
      hoverPointRadius: 0,
      fill: {
        target: "origin", /// Set the fill options/
        above: "rgba(26, 213, 152, 0.8)",
      },
    },
    {
      label: "Women",
      data: [
        0, 12000, 18000, 18000, 16000, 13000, 10000, 22000, 16000, 11000, 12000,
        17000, 18000, 13000, 9000, 14000, 17000, 20000, 13000, 17000, 19000,
        11000, 13000, 8000, 15000, 20000, 18000, 13000, 10000, 19000,
      ],
      pointRadius: 0,
      hoverPointRadius: 0,
      pointBackgrounColor: 'rgba(0, 144, 255, 0.8)',
      borderColor: "rgba(0, 144, 255, 0)",
      backgroundColor: "rgba(0, 144, 255, 0.8)",
      width: "30px",
      height: "30px",
      fill: {
        target: "origin",
        above: "rgba(0, 144, 255, 0.8)",
      },
    },
  ],
  options: options,
};

const GenderDistribution = () => {
  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden">
      <div className="relative min-h-[200px] md:min-h-[620px] w-full overflow-x-auto scrollbar-hide">
        <div className="flex flex-col px-3 md:px-10 w-full h-[350px] md:h-[520px] mt-10 mb-10">
          <p className="flex items-center text-primary font-bold text-2xl">
            Gender Distribution
          </p>
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default GenderDistribution;

import React from "react";
import { Bar } from "react-chartjs-2";
import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 10,
        font: {
          size: 16
        },
      }
    },
    y: {
      position: "left",
      suggestedMax: 35,
      grid: {
        display: true,
        color: "rgba(56, 68, 85, 0.4)",
      },
      ticks: {
        callback: (v, index) => {
          return index % 3 === 0 ? `${v}%` : ""
        },
        maxTicksLimit: 8,
        autoSkip: true,
        font: {
          size: 16
        },
      }
    },
  },
};

const labels = [
  "18-20",
  "21-30",
  "31-40",
  "41-50",
  "51-60",
  "61-70",
  "71-80",
  "81-90",
  "91-100",
];

export const data = {
  labels,
  datasets: [
    {
      data: [10, 21, 22, 19, 10, 8, 5, 3, 2],
      borderColor: "rgba(26, 213, 152, 0)",
      backgroundColor: "#3A36DB",
      borderRadius: 12,
      borderSkipped: false
    },
  ],
  options: options,
};

const AgeDistribution = () => {
  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden">
      <div className="relative min-h-[200px] md:min-h-[620px] w-full overflow-x-auto scrollbar-hide">
        <div className="flex flex-col px-3 md:px-10 w-full  h-[350px] md:h-[520px] mt-10 mb-10">
          <p className="flex items-center text-primary font-bold text-2xl">
            Age Distribution
          </p>
          <Bar options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default AgeDistribution;

import React from "react";
import AssetsSupported from "../components/AssetsSupported";
import PrivacyTokensSupported from "../components/PrivacyTokensSupported";
import FiatCurrencySupported from "../components/FiatCurrencySupported";
import TradingPairs from "../components/TradingPairs";
import { useSelector } from "react-redux";
import { getSupportedAssets } from "../redux/slices/supportedAssets";
import { useEffect } from "react";
import AssetsNotes from "../components/AssetsNotes";

const SupportedAssets = () => {
  const supportedAssets = useSelector(getSupportedAssets);
  useEffect(() => {}, [supportedAssets]);
  return (
    <div className="bg-black pt-[88px] pb-10 h-screen overflow-scroll scrollbar-hide">
      <AssetsSupported data={supportedAssets[0]?.assets || []} />
      <PrivacyTokensSupported data={supportedAssets[0]?.privacy_token || []} />
      <FiatCurrencySupported
        data={supportedAssets[0]?.fiat_currency_supported || []}
      />
      {supportedAssets[0]?.comments && (
        <AssetsNotes data={supportedAssets[0]?.comments} />
      )}
      {/* <TradingPairs /> */}
    </div>
  );
};

export default SupportedAssets;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchFounders = createAsyncThunk(
  "founders/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/founders?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  founders: [],
};

const founderslice = createSlice({
  name: "founders",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFounders.fulfilled, (state, action) => {
      state.founders = action.payload;
    });
  },
});

export const { foundersClear } = founderslice.actions;

export const getFounders = (state) => state.founders.founders;

export default founderslice.reducer;

import React from "react";
import AlertImg from "../assets/images/alert-circle.png";
import JVectorMap from "./JVectorMap";

const CountriesJVMap = () => {
  return (
    <div className="relative">
      <div className="absolute top-5 z-20 flex justify-between w-full px-10">
        <p className="flex items-center text-primary font-bold text-2xl">
          Overview
          <img src={AlertImg} alt="alert" className="mx-2" />
        </p>
      </div>
      <JVectorMap />
    </div>
  );
};

export default CountriesJVMap;

export const insets = [
  {
    bbox: [
      {
        x: -20004297.151525836,
        y: -18449355.69035302,
      },
      {
        x: 20026572.394749384,
        y: 7485321.539093307,
      },
    ],
    height: 583.0802520919394,
    left: 0,
    top: 0,
    width: 900,
  },
];

import React, { useEffect, useState } from "react";
// import DotImg from "../assets/images/dots.png";
import {
  CheckMark,
  CircleLoading,
  DeleteMark,
  NoItems,
} from "../features/Symbols";
import { useSelector } from "react-redux";
import { getKYCLoading, getNonTieredKYC } from "../redux/slices/entityKYC";

const Tiered = ({ setIsTiered, tieredData }) => {
  const kycLoading = useSelector(getKYCLoading);
  // const nonTieredKYC = useSelector(getNonTieredKYC);
  const [titles, setTitles] = useState([]);

  const getHeaderTitles = () => {
    let temp = tieredData.length ? Object.keys(tieredData[0]) : [];
    temp.pop();
    setTitles(temp);
  };
  const TierItem = ({ data }) => {
    return (
      <td className="px-2 md:px-4 py-4 font-medium">
        <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]" >
          <p
            className={`flex min-w-[270px] justify-left text-left ${rowTextClass} font-normal`}
          >
            {data === "Yes" ? (
              <CheckMark />
            ) : data === "No" ? (
              <DeleteMark />
            ) : (
              <div style={{ maxHeight:'400px', overflowY:'scroll' }}>
                {
                  data.split("\\n\n\n\n\n\n\n\n").map(function (item, idx) {
                    return (
                      <span key={idx} >
                        {item.length > 2 ? "• " + item : item} <br /> <br />
                      </span>
                    );
                  })
                }
              </div>
            )}
          </p>
        </div>
      </td>
    );
  };
  const headerTitleClass = "px-6 py-4 font-bold text-primary text-lg ";
  const rowTextClass = "flex flex-wrap px-2 md:px-5 py-2 text-primary text-lg";

  useEffect(() => {
    getHeaderTitles();
  }, []);
  return (
    <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
      <div className="top-5 z-20 flex justify-between w-full px-0">
        <p className="flex items-center text-primary font-bold text-2xl">
          Tiered
        </p>
        {/* {nonTieredKYC.length > 0 && (
          <button onClick={() => setIsTiered(false)} title="To Non-Tiered">
            <img src={DotImg} alt="dots" />
          </button>
        )} */}
      </div>

      <div className="flex flex-col w-full">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 scrollbar-hide">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              {kycLoading === "pending" ? (
                <CircleLoading />
              ) : (
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="font-medium ">
                    <tr>
                      <th scope="col"></th>
                      {titles.map((title, index) => (
                        <th
                          scope="col"
                          className={headerTitleClass}
                          key={"title" + index}
                        >
                          <p className="flex items-center justify-center">
                            {title}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tieredData !== null &&
                      tieredData.map((item, i) => (
                        <tr
                          className={`transition duration-300 ease-in-out hover:bg-primary ${
                            i !== tieredData.length - 1
                              ? " border-b-[1px] border-[#F1F4F9]"
                              : ""
                          }`}
                          key={i}
                        >
                          <td className={"text-left py-4 font-bold"}>
                            <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                              <p
                                className={`flex items-center min-w-[200px] justify-start text-left ${rowTextClass}`}
                              >
                                {item.description}
                              </p>
                            </div>
                          </td>
                          {titles.map((title, index) => (
                            <TierItem data={item[title]} key={"item" + index} />
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {tieredData.length === 0 && kycLoading !== "pending" && (
                <NoItems />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiered;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import newsImg from "../assets/images/news-logo.png";
import { useSelector } from "react-redux";
import { getAlerts } from "../redux/slices/alert";
import { FaChevronLeft } from "react-icons/fa";

const RegAlert = () => {
  const [newsData, setNewsData] = useState(null);
  const { itemId } = useParams();
  const alerts = useSelector(getAlerts);
  const navigate = useNavigate();

  useEffect(() => {
    if (itemId) {
      const temp = alerts.find((item) => item.id === itemId);
      setNewsData(temp);
    }
  }, []);

  return (
    <div className="flex flex-col pt-[100px] pb-10 h-screen overflow-scroll scrollbar-hide bg-black">
      <p
        className=" flex items-center gap-2 w-16 text-secondary text-left mx-10 hover:text-primary hover:cursor-pointer"
        onClick={() =>
          navigate("/regulatory-alert", {
            replace: true,
          })
        }
      >
        <FaChevronLeft size="0.6rem" /> Back
      </p>
      {newsData && (
        <div className="flex items-start w-full px-8 py-5 rounded-2xl">
          <div className="bg-primary w-full rounded-2xl px-6 pb-6">
            <div className="flex flex-col md:flex-row items-center justify-between border-b-2 border-[#F1F0F0] py-6">
              <img src={newsImg} alt="newslogo" />
              <p className="text-primary text-xl lg:text-2xl px-2 pt-2 md:pt-0">
                {newsData.regulatory_authority}
              </p>
              <img src={newsImg} alt="newslogo" className="hidden md:block" />
            </div>
            <div className="flex items-center justify-center border-b-2 border-[#F1F0F0] py-10">
              <p className="text-primary text-xl lg:text-2xl">
                {newsData.alert_type}
              </p>
            </div>
            <div className="flex items-center justify-center border-b-2 border-[#F1F0F0] py-10">
              <p className="text-primary text-xl lg:text-2xl hover:cursor-pointer">
                <a href={newsData.link} target="_blank">
                  Click for details
                </a>
              </p>
            </div>
            <div className="flex items-center justify-center border-b-2 border-[#F1F0F0] py-10">
              <p className="text-primary text-xl lg:text-2xl">
                {newsData.date}
              </p>
            </div>
            <div className="flex items-center justify-center py-6">
              <p className="text-primary text-lg lg:text-xl">
                {newsData.note}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegAlert;

import React, { useState, useEffect } from "react";
import PrivacyTokensCard from "./PrivacyTokensCard";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CircleLoading, NoItems } from "../features/Symbols";
import { useSelector } from "react-redux";
import { getAsssetsLoading } from "../redux/slices/supportedAssets";

const FiatCurrencySupported = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [chunks, setChunks] = useState([]);
  const assetsLoading = useSelector(getAsssetsLoading);
  const prevBtnClick = (prev) => {
    if (prev) {
      currentPage === chunks.length - 1
        ? setCurrentPage(0)
        : setCurrentPage(currentPage + 1);
    } else {
      currentPage === 0
        ? setCurrentPage(chunks.length - 1)
        : setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    let numbers = data;
    let chunkSize = 25;
    let res = [];
    for (let i = 0; i < numbers.length; i += chunkSize) {
      res.push(numbers.slice(i, i + chunkSize));
    }
    setChunks(res);
  }, [data]);
  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden">
      <div className="relative px-3 pb-5 md:px-10 min-h-[200px] md:min-h-[200px] w-full overflow-x-auto scrollbar-hide">
        <div className="flex justify-between mt-5 w-full">
          <p className="flex items-center text-primary text-left font-bold text-2xl">
            Fiat Currencies Supported
          </p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1">
          {chunks.length !== 0 &&
            chunks[currentPage].map(
              (item, i) =>
                item !== "" && (
                  <PrivacyTokensCard
                    data={item}
                    key={"currency" + i}
                    iconHidden={true}
                  />
                )
            )}
        </div>
        {assetsLoading === "pending" && <CircleLoading />}
        {data[0] === "" && chunks.length === 1 && <NoItems />}
        {chunks.length > 1 && (
          <div className="flex w-full items-center justify-center gap-12 pt-3">
            <button
              type="button"
              onClick={() => prevBtnClick(0)}
              className="rounded-md bg-none px-3 py-3 text-xs font-semibold text-[#fff] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <FaChevronLeft size="0.6rem" />
            </button>
            <button
              type="button"
              onClick={() => prevBtnClick(1)}
              className="rounded-md bg-none px-3 py-3 text-xs font-semibold text-[#fff] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <FaChevronRight size="0.6rem" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FiatCurrencySupported;

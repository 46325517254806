import React from "react";
import ComingSoon from "./ComingSoon";

const Settings = () => {
  return (
    <div className="bg-black pt-[88px] pb-10 h-screen overflow-scroll scrollbar-hide">
      <ComingSoon title="Settings" />
    </div>
  );
};

export default Settings;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchEntities = createAsyncThunk("onlyNames/getAPI", async () => {
  const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
  const response = await axios.get(`${config.API_BASE_URL}/vasps?onlyNames=1`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.data;
});

const initialState = {
  entityLoading: "idle",
  currentEntity: "",
  entityList: [],
};

const entityslice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    changeCurrentEntity: (state, { payload }) => {
      state.currentEntity = payload;
    },
    initEntLoading: (state) => {
      state.entityLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEntities.pending, (state, action) => {
      state.entityLoading = "pending";
    });
    builder.addCase(fetchEntities.fulfilled, (state, action) => {
      state.entityLoading = "idle";
      state.entityList = action.payload;
    });
    builder.addCase(fetchEntities.rejected, (state, action) => {
      state.entityLoading = "failed";
    });
  },
});

export const { changeCurrentEntity, initEntLoading } = entityslice.actions;

export const getCurrentEntity = (state) => state.entities.currentEntity;
export const getEntityList = (state) => state.entities.entityList;
export const getEntityLoading = (state) => state.entities.entityLoading;

export default entityslice.reducer;

import React from 'react';
import LoginForm from '../components/Login/LoginForm';

const Login = () => {
  return (
    <div className='flex items-center justify-center h-screen w-screen bg-black'>
      <LoginForm />
    </div>
  );
};

export default Login;

import axios from "axios";
import { Country } from "country-state-city";

export const getCountry = (data) => {
  let countryName;
  if (data.includes("Abu Dhabi") || data === "Dubai") {
    countryName = "United Arab Emirates";
  } else if (data === "Washington") {
    countryName = "United States";
  } else {
    countryName = data;
  }
  const countryInfo = Country.getAllCountries().find(
    (item) => item.name === countryName
  );
  return countryInfo;
};

export const getCountryByCode = (data) => {
  const countryInfo = Country.getCountryByCode(data);
  return countryInfo.name;
};

export const getGeoByCode = (data) => {
  const countryInfo = Country.getCountryByCode(data);
  return countryInfo;
};

export const getZone = async (countryName) => {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${countryName}&key=AIzaSyBJDDOSHtrJ1kkyo5Zrjj55XYE55v77fus`
  );
  return response.data;
};

export const setLicensesMarkers = async (licenses) => {
  let markers = [];
  for (let i = 0; i < licenses.length; i++) {
    const countryInfo = getCountry(licenses[i].country);
    markers.push({
      latLng: [countryInfo?.latitude, countryInfo?.longitude],
      name: licenses[i].license_legal_name,
    });
  }
  return markers;
};

export const setLegalInfoMarkers = async (legalInfo) => {
  let markers = [];
  for (let i = 0; i < legalInfo.length; i++) {
    if (
      markers.findIndex(
        (m) => m.countryCode === legalInfo[i].country_of_incorporation
      ) < 0
    ) {
      const countryInfo = getGeoByCode(legalInfo[i].country_of_incorporation);
      markers.push({
        latLng: [countryInfo?.latitude, countryInfo?.longitude],
        name: legalInfo[i].legal_name,
        type: 1,
        countryCode: legalInfo[i].country_of_incorporation,
      });
    } else {
      markers[
        markers.findIndex(
          (m) => m.countryCode === legalInfo[i].country_of_incorporation
        )
      ].name += `<br/>${legalInfo[i].legal_name}`;
    }
  }
  return markers;
};

export const setLegalInfoRegion = async (legalInfo) => {
  let region = {};
  for (let i = 0; i < legalInfo.length; i++) {
    const countryInfo = legalInfo[i].country_of_incorporation;
    region[countryInfo] = 1;
  }
  return region;
};

export const setVaspsRegion = async (vasps) => {
  let region = {};
  for (let i = 0; i < vasps.length; i++) {
    const countryInfo = getCountry(vasps[i].country);
    region[countryInfo?.isoCode] = 2;
  }
  return region;
};

export const filterCountriesToCode = (countries) => {
  let res = [];
  const arr0 = [...countries[0].value];
  const arr1 = [...countries[1].value];
  const arr2 = [...countries[2].value];
  const arr3 = [...countries[3].value];
  res.push(
    arr0.map((item) => getCountry(item)?.isoCode),
    arr1.map((item) => getCountry(item)?.isoCode),
    arr2.map((item) => getCountry(item)?.isoCode),
    arr3.map((item) => getCountry(item)?.isoCode)
  );
  return res;
};

export const setVaspsMarkers = async (vasps) => {
  let markers = [];
  for (let i = 0; i < vasps.length; i++) {
    const countryInfo = await getZone(vasps[i].country);
    markers.push({
      latLng: [
        countryInfo.results[0]?.geometry?.location?.lat.toString(),
        countryInfo.results[0]?.geometry?.location?.lng.toString(),
      ],
      name: vasps[i].entity_name,
      type: 2,
      countryCode: countryInfo.results[0]?.address_components[0]?.short_name,
    });
  }
  return markers;
};

export const setRestrictedMarkers = async (data) => {
  let markers = [];

  for (let i = 0; i < data[0]?.restricted_zones?.length; i++) {
    const geoData = await getZone(data[0].restricted_zones[i]);
    markers.push({
      latLng: [
        geoData.results[0].geometry.location.lat,
        geoData.results[0].geometry.location.lng,
      ],
      name: data[0].restricted_zones[i],
      type: "restricted",
    });
  }
  for (let i = 0; i < data[0]?.no_data?.length; i++) {
    const geoData = await getZone(data[0].no_data[i]);
    markers.push({
      latLng: [
        geoData.results[0].geometry.location.lat,
        geoData.results[0].geometry.location.lng,
      ],
      name: data[0].no_data[i],
      type: "no-data",
    });
  }

  return markers;
};

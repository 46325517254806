import React from "react";
import RegulatoryWarningCard from "../components/RegulatoryWarningCard";

const RegulatoryWarning = () => {
  return (
    <div className="bg-black pt-16 md:pt-24 pb-10 h-screen overflow-scroll scrollbar-hide">
      <RegulatoryWarningCard />
    </div>
  );
};

export default RegulatoryWarning;

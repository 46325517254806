import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Card } from 'semantic-ui-react';
import BarChart from './BarChart';

const DashboardNumbers = ({continents=[],regions=[], highRisk=[],showLastGraph=true}) => {
    return (
        <>
        <div style={{ margin:'auto',width:'80%',textAlign:'center' }}>
            { continents.map((continent) => {
                return (
                        <Card style={{ height: '450px',    display: 'inline-block',color: '#fff',fontSize: '22px','backgroundColor': '#0C0B15',borderRadius: '20px',margin: '20px',padding: '20px',textAlign:'left' }}>
                            <label>{continent.name}</label>
                            <CircularProgressbar value={Math.round(continent.average*100,2)} text={`${Math.round(continent.average*100,2)}%`} />
                            <div style={{ textAlign:'center',fontSize:'30px' }}>{continent.number}</div>
                        </Card>
                )
            })}
        </div>
            <div style={{ clear:'both' }}></div>
            <div style={{ width:'80%',margin:'auto',backgroundColor: '#0C0B15',padding:'20px',height:'700px' }}>
                <BarChart data={regions} />
            </div>
            <br/>
            {showLastGraph &&
            <div style={{ width:'80%',margin:'auto',backgroundColor: '#0C0B15',padding:'20px',height:'700px' }}>
                <BarChart data={highRisk} />
            </div>}
        </>
    )
}
export default DashboardNumbers;
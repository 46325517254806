import React, { useEffect, useState } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { paths } from "../utils/paths";
import { useSelector } from "react-redux";
import MarkerIcon1 from "../assets/images/marker_icon_1.png";
import MarkerIcon11 from "../assets/images/marker_icon_11.png";
import MarkerIcon2 from "../assets/images/marker_icon_2.png";
import MarkerIcon21 from "../assets/images/marker_icon_21.png";
import MarkerIcon3 from "../assets/images/marker_icon_3.png";
import { getMapRange } from "../redux/slices/tabs";
import { insets } from "../utils/insets";
import { CircleLoading } from "../features/Symbols";
import {
  getLegalInfoLoading,
  getLegalInfoMarkers,
  getLegalMarkerLoading,
  getLegalRegion,
  getVaspsMarkers,
  getVaspsRegion,
} from "../redux/slices/legalInfo";

const JVectorMap = () => {
  const [markers, setMarkers] = useState([]);
  const [regions, setRegions] = useState({});
  const [showMap, setShowMap] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const legalInfoMarkers = useSelector(getLegalInfoMarkers);
  const vaspsMarkers = useSelector(getVaspsMarkers);
  const vaspsRegion = useSelector(getVaspsRegion);
  const legalMarkerLoading = useSelector(getLegalMarkerLoading);
  const legalInfoLoading = useSelector(getLegalInfoLoading);
  const mapRange = useSelector(getMapRange);
  const legalRegion = useSelector(getLegalRegion);

  useEffect(() => {
    let legalTemp = JSON.parse(JSON.stringify(legalInfoMarkers));
    for (let i = 0; i < vaspsMarkers.length; i++) {
      if (
        legalTemp.findIndex(
          (m) => m.countryCode === vaspsMarkers[i].countryCode
        ) < 0
      ) {
        legalTemp.push(vaspsMarkers[i]);
      } else {
        legalTemp[
          legalTemp.findIndex(
            (m) => m.countryCode === vaspsMarkers[i].countryCode
          )
        ].type = 2;
      }
    }
    setMarkers(legalTemp);
    setShowMap(false);
  }, [vaspsMarkers, legalInfoMarkers]);

  useEffect(() => {
    setRegions({ ...legalRegion, ...vaspsRegion });
    setShowMap(false);
  }, [vaspsRegion, legalRegion]);

  useEffect(() => {
    if (!showMap) {
      setShowMap(true);
      setTimeout(() => {
        setShowTooltip(true);
      }, 3000);
    }
  }, [showMap]);

  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden pt-4">
      <div className="flex items-center relative w-[1000px] h-[700px] overflow-y-hidden overflow-x-auto scrollbar-hide map-marker">
        <div
          className={`absolute w-full h-full z-50 ${
            showTooltip ? "hidden" : ""
          }`}
        ></div>
        {legalMarkerLoading === "pending" || legalInfoLoading === "pending" ? (
          <CircleLoading />
        ) : showMap ? (
          <div
            className={`relative w-[1000px] h-[620px] ${
              mapRange !== "world" ? "mt-16" : ""
            }`}
          >
            <div className=" absolute flex items-center gap-5 -top-10 right-0">
              <div className=" flex items-center gap-2">
                <img
                  src={MarkerIcon1}
                  alt="Legal Entities"
                  className="w-7 h-7 pr-1"
                />
                <p className="text-primary text-xl font-medium">
                  Legal Entities
                </p>
              </div>
              <div className=" flex items-center gap-2">
                <img
                  src={MarkerIcon2}
                  alt="Primary Location"
                  className="w-7 h-7 pr-1"
                />
                <p className="text-primary text-xl font-medium">
                  Primary Location
                </p>
              </div>
              {/* <div className=" flex items-center gap-2">
                <img src={MarkerIcon3} alt="Offices" className="w-7 h-7 pr-1" />
                <p className="text-primary text-xl font-medium">Offices</p>
              </div> */}
            </div>
            <VectorMap
              backgroundColor="none"
              zoomOnScroll={false}
              map={{
                content: {
                  height: 600,
                  insets: insets,
                  paths: paths,
                  projection: {
                    centralMeridian: 11.5,
                    type: "merc",
                  },
                  width: 900,
                },
                name: "world_merc",
              }}
              zoomButtons={true}
              markerStyle={{
                initial: {
                  image: MarkerIcon21,
                },
              }}
              markers={markers}
              onMarkerTipShow={(event, label, index) => {
                label.html("<p>" + markers[index].name + "</p>");
                // label.css("max-width", 700);
                // label.css("overflow", "hidden");
                // label.css("padding", 10);
              }}
              regionStyle={{
                initial: {
                  fill: "#585858",
                },
              }}
              mapRef={{
                current: "[Circular]",
              }}
              selectedRegions={[]}
              series={{
                markers: [
                  {
                    attribute: "image",
                    scale: {
                      1: MarkerIcon11,
                      2: MarkerIcon21,
                    },
                    values: markers.reduce(function (p, c, i) {
                      p[i] = c.type;
                      return p;
                    }, {}),
                  },
                ],
                regions: [
                  {
                    attribute: "fill",
                    normalizeFunction: undefined,
                    scale: {
                      1: "#2563EB",
                      2: "#EB9C25",
                    },
                    values: regions,
                  },
                ],
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default JVectorMap;

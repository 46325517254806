import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { changeCurrentEntity } from "../redux/slices/entities";
import { fetchLicenses } from "../redux/slices/licenses";
import { countryFlag } from "../features/Countries";
import { fetchLegalInfo, fetchVaspsByEntity } from "../redux/slices/legalInfo";

const HomeEntityList = ({ vasps }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const headerTitleClass = "px-6 py-4 font-thin italic text-primary text-base";
  const rowTextClass = "whitespace-nowrap px-6 py-4 text-primary text-lg";
  const rowTextClass1 = "px-6 py-4 text-primary text-lg";

  return (
    <div className="flex flex-col items-start bg-black mx-2 my-0 md:m-5 px-2 md:px-8 py-0 md:py-5 md:pt-0">
      <p className="text-primary font-bold text-2xl">LIST</p>
      <div className="flex flex-col w-full">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 scrollbar-hide">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full text-left text-sm font-light">
                <thead className="font-medium ">
                  <tr>
                    <th scope="col" className={headerTitleClass}>
                      Business Name
                    </th>
                    <th
                      scope="col"
                      className={`text-center ${headerTitleClass}`}
                    >
                      Website
                    </th>
                    <th
                      scope="col"
                      className={`text-center ${headerTitleClass}`}
                    ></th>
                    <th
                      scope="col"
                      className={`text-left pl-20 ${headerTitleClass}`}
                    >
                      Primary Location
                    </th>
                    <th scope="col" className={headerTitleClass}></th>
                  </tr>
                </thead>
                <tbody>
                  {vasps?.map((item, i) => (
                    <tr
                      className={`transition duration-300 ease-in-out hover:bg-primary dark:hover:bg-neutral-600 ${
                        i !== vasps.length - 1
                          ? " border-b-[1px] border-[#F1F4F9]"
                          : ""
                      }`}
                      key={i}
                    >
                      <td className={`font-bold text-lg ${rowTextClass1} `}>
                        {item.entity_name}
                      </td>
                      <td className={`flex justify-center ${rowTextClass1}`}>
                        <a href={item.website} target="_blank" rel="noreferrer">
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0 13.0303C-0.00762939 7.96826 3.03577 3.40039 7.71014 1.45801C12.3846 -0.484375 17.7689 0.581543 21.3509 4.15771C24.9329 7.73437 26.0068 13.1172 24.0715 17.7944C22.1361 22.4722 17.573 25.522 12.511 25.5225C5.60986 25.5195 0.0131226 19.9312 0 13.0303V13.0303ZM2.35901 13.0303C2.35284 17.1377 4.82269 20.8442 8.61615 22.4209C12.4097 23.9971 16.7792 23.1318 19.686 20.2295C22.5929 17.3267 23.4642 12.9585 21.8935 9.1626C20.3228 5.36719 16.6194 2.8916 12.5115 2.89209C6.91101 2.89404 2.36932 7.42969 2.35895 13.0298L2.35901 13.0303ZM7.73901 17.7964C7.42761 17.4873 7.31525 17.0298 7.448 16.6118L9.32501 10.6211C9.44025 10.252 9.72913 9.96289 10.098 9.84766L16.098 7.97314C16.5165 7.84131 16.9736 7.95361 17.283 8.26514V8.26514C17.5944 8.57422 17.7068 9.03174 17.574 9.44971L15.697 15.4404C15.5817 15.8091 15.2927 16.0977 14.924 16.2119L8.92401 18.0864C8.81018 18.123 8.69147 18.1416 8.57196 18.1421C8.25928 18.1421 7.95947 18.0171 7.73901 17.7954V17.7964ZM11.391 11.9126L10.372 15.1665L13.633 14.1475L14.652 10.8906L11.391 11.9126Z"
                              fill="#85A3BB"
                            />
                          </svg>
                        </a>
                      </td>
                      <td className={`font-bold ${rowTextClass1} `}></td>
                      <td className={`flex items-center ${rowTextClass}`}>
                        <div className="flex items-center justify-between w-9 h-9 overflow-hidden mr-3 rounded-full">
                          {countryFlag(item.country)}
                        </div>
                        <p className="font-light text-lg italic whitespace-normal text-center">
                          {item.country}
                        </p>
                      </td>
                      <td className={`font-light ${rowTextClass}`}>
                        <p
                          className="flex items-center border-[1px] border-[#27344A] py-3 px-3 justify-center rounded-xl text-sm italic text-details hover:text-primary hover:bg-normal hover:cursor-pointer"
                          onClick={() => {
                            dispatch(
                              fetchLicenses({ payload: item.entity_name })
                            );
                            dispatch(
                              fetchLegalInfo({ payload: item.entity_name })
                            );
                            dispatch(
                              fetchVaspsByEntity({ payload: item.entity_name })
                            );
                            dispatch(changeCurrentEntity(item.entity_name));
                            localStorage.setItem(
                              "current-entity",
                              item.entity_name
                            );
                            navigate("/legal-info", { replace: true });
                          }}
                        >
                          Details
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeEntityList;

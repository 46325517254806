import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchOnlyCountries = createAsyncThunk(
  "onlyCountries/getAPI",
  async () => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/vasps?onlyCountries=true`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  countriesLoading: "idle",
  countries: [],
};

const onlyCountriesslice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    initEntLoading: (state) => {
      state.countriesLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnlyCountries.pending, (state, action) => {
      state.countriesLoading = "pending";
    });
    builder.addCase(fetchOnlyCountries.fulfilled, (state, action) => {
      state.countriesLoading = "idle";
      state.countries = action.payload;
    });
    builder.addCase(fetchOnlyCountries.rejected, (state, action) => {
      state.countriesLoading = "failed";
    });
  },
});

export const { initEntLoading } = onlyCountriesslice.actions;

export const getOnlyCountries = (state) => state.onlyCountries.countries;
export const getCountriesLoading = (state) =>
  state.onlyCountries.countriesLoading;

export default onlyCountriesslice.reducer;

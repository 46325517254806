const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  filters: [
    { id: 0, value: [] },
    { id: 1, value: [] },
    { id: 2, value: [] },
    { id: 3, value: [] },
  ],
  filterKeys: [[], [], [], []],
  filterSearchOpen: false,
};

const filtersslice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    changeFilters: (state, { payload }) => {
      localStorage.setItem("filters", JSON.stringify(payload));
      state.filters = payload;
    },
    removeFilterAll: (state) => {
      localStorage.removeItem("filters");
      state.filters = [
        { id: 0, value: [] },
        { id: 1, value: [] },
        { id: 2, value: [] },
        { id: 3, value: [] },
      ];
    },
    changeFilterKeys: (state, { payload }) => {
      state.filterKeys = payload;
    },
    clearFilterKeys: (state) => {
      state.filterKeys = [[], [], [], []];
    },
    changeFilterSearchOpen: (state, { payload }) => {
      state.filterSearchOpen = payload;
    },
  },
});

export const {
  changeFilters,
  removeFilterAll,
  changeFilterKeys,
  clearFilterKeys,
  changeFilterSearchOpen,
} = filtersslice.actions;

export const getFilters = (state) => state.filters.filters;
export const getFilterKeys = (state) => state.filters.filterKeys;
export const getFilterSearchOpen = (state) => state.filters.filterSearchOpen;

export default filtersslice.reducer;

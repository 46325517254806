import React from "react";
import { icons } from "../utils/icons";

const PrivacyTokensCard = ({ data, icon, iconHidden = false }) => {
  return (
    <div className="flex items-center justify-between text-primary border-[1px] border-[#ffffff] leading-4 py-1 px-2 my-3  w-40 h-8 rounded-lg cursor-pointer">
      <p className="text-primary text-xs font-semibold text-center flex-1 p-0 m-0">
        {data}
      </p>
      <span className="flex items-center justify-center w-5 h-5">
        {iconHidden === false && icons[data.trim()] && (
          <img
            src={icons[data.trim()]}
            onError={(e) => (e.target.style.display = "none")}
            alt=""
            className="mx-1 w-5 h-5"
          />
        )}
        <p>{icon}</p>
      </span>
    </div>
  );
};

export default PrivacyTokensCard;

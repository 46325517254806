export const USinsets = [
  {
    bbox: [
      {
        x: -19771895.08914502,
        y: -9738357.401846493
      },
      {
        x: -13363916.660010302,
        y: -6319208.790477941
      }
    ],
    height: 106.71535958432516,
    left: 10,
    top: 370,
    width: 200
  },
  {
    bbox: [
      {
        x: -16682694.225454755,
        y: -2517270.40434964
      },
      {
        x: -16128804.220829042,
        y: -2130577.4706086228
      }
    ],
    height: 69.81402995389341,
    left: 220,
    top: 400,
    width: 100
  },
  {
    bbox: [
      {
        x: -12778676.130802888,
        y: -5994178.75039794
      },
      {
        x: -6345903.8252634145,
        y: -2857123.635928248
      }
    ],
    height: 438.9009075591628,
    left: 0,
    top: 0,
    width: 900
  }
];

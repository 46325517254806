import React from "react";
import { useSelector } from "react-redux";
import { getRestrictedCountries } from "../redux/slices/restrictedCountries";
import { getCountryByCode } from "../features/GeoLocation";
import { countryFlag } from "../features/Countries";

const GeoResNotes = () => {
  const restrictedZones = useSelector(getRestrictedCountries);

  return (
    <div className="flex flex-col p-5 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden pt-4">
      <p className=" text-primary text-left font-bold text-2xl">Notes</p>
      <div className=" h-[1px] w-full bg-white my-12" />
      {restrictedZones.length > 0 && (
        <>
          <p className="text-primary text-lg font-bold text-left mb-5">
            Restricted Zones:{" "}
          </p>
          {restrictedZones.map((item, index) => (
            <div className=" my-3">
              <div className="flex items-center gap-4 pb-2">
                <span className=" text-primary italic text-lg font-semibold">
                  {getCountryByCode(item.country_name)}
                </span>
                <span className="flex items-center justify-between w-9 h-9 overflow-hidden mr-3 rounded-full">
                  {countryFlag(
                    getCountryByCode(item.country_name)
                  )}
                </span>
              </div>
              <p className=" text-primary text-left text-lg font-light">
                {item.comment}
              </p>
            </div>
          ))}
        </>
      )}
      {restrictedZones.length === 0 && (
        <p className=" w-full text-center italic text-primary font-semibold text-2xl px-10 ">
          
        </p>
      )}
      <div className=" h-[1px] w-full bg-white my-12" />
    </div>
  );
};

export default GeoResNotes;

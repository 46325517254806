import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLicensesLoading,
  getLicensesMarkers,
  getLMarkerLoading,
} from "../redux/slices/licenses";
import PlusImg from "../assets/images/plus.png";
import MinusImg from "../assets/images/minus.png";
import MarkerIcon from "../assets/images/marker_icon_1.png";
import { changeMapRange, getMapRange } from "../redux/slices/tabs";
import { VectorMap } from "@react-jvectormap/core";
import { paths } from "../utils/paths";
import { insets } from "../utils/insets";
import { USinsets } from "../utils/US-insets";
import { USpaths } from "../utils/US-paths";
import { getUSsupported } from "../redux/slices/USsupported";
import { CircleLoading } from "../features/Symbols";

function GeoChartJVector({ geoData, licenses }) {
  const markersLoading = useSelector(getLMarkerLoading);
  const licensesLoading = useSelector(getLicensesLoading);
  const licensesMarkers = useSelector(getLicensesMarkers);
  const mapRange = useSelector(getMapRange);
  const USsupported = useSelector(getUSsupported);
  const [showMap, setShowMap] = useState(true);
  const [noData, setNoData] = useState(false);

  const dispatch = useDispatch();

  const setRegion = (reg) => {
    dispatch(changeMapRange(reg));
    setShowMap(false);
  };

  const checkNoData = () => {
    setNoData(false);
    if (mapRange === "world") {
      setNoData(Object.keys(geoData).length === 0);
    } else {
      setNoData(Object.keys(USsupported).length === 0);
    }
  };

  useEffect(() => {
    if (!showMap) setShowMap(true);
  }, [showMap]);

  useEffect(() => {
    setShowMap(false);
  }, [licensesMarkers]);

  useEffect(() => {
    checkNoData();
  }, [geoData, mapRange]);

  const adjustedGeoData = React.useMemo(() => {
    const licenseCountryCodes = licenses.map(license => license.country);
    const adjustedData = { ...geoData };

    Object.keys(adjustedData).forEach(countryCode => {
      if (!licenseCountryCodes.includes(countryCode)) {
        // If country code is not in licenses and current color scale is 1, change color to orange
        if (adjustedData[countryCode] === 1) {
          adjustedData[countryCode] = "orange"; // Use a distinct value or color code for orange
        }
        // Implement additional logic if needed, e.g., for yellow border
      }
    });

    return adjustedData;
  }, [geoData, licenses]);

  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden pt-4">
      <div className="flex items-center relative w-[1200px] h-[700px] overflow-y-hidden overflow-x-auto scrollbar-hide map-marker">
        {markersLoading === "pending" || licensesLoading === "pending" ? (
          <CircleLoading />
        ) : showMap ? (
          <div className="relative w-[1200px] h-[620px]">
            <VectorMap
              backgroundColor="none"
              zoomOnScroll={false}
              map={{
                content: {
                  height: 600,
                  insets: mapRange === "world" ? insets : USinsets,
                  paths: mapRange === "world" ? paths : USpaths,
                  projection: {
                    centralMeridian: 11.5,
                    type: "merc",
                  },
                  width: 900,
                },
                name: "world_merc",
              }}
              zoomButtons={false}
              // markerStyle={{
              //   initial: {
              //     image: MarkerIcon,
              //   },
              //   selected: {
              //     fill: "#CA0020",
              //   },
              // }}
              // markers={licensesMarkers}
              // markersSelectable
              regionStyle={{
                initial: {
                  fill: "#585858",
                },
              }}
              mapRef={{
                current: "[Circular]",
              }}
              selectedRegions={[]}
              series={{
                regions: [
                  {
                    attribute: "fill",
                    normalizeFunction: undefined,
                    scale: {
                      1: "#0F8344",
                      2: "#AF3A3A",
                      orange: "#FF5F00", // Define the orange color for unmatched countries
                    },
                    values: mapRange === "world" ? adjustedGeoData : USsupported,
                  },
                ],
              }}
            />
            {noData && (
              <p className=" w-full text-center italic text-primary font-semibold text-2xl px-10 ">
                
              </p>
            )}
            {mapRange === "US" && (
              <div className="flex items-center absolute right-0 top-28 bg-[#2e2e2e] w-[140px] min-h-[300px] p-4 rounded-2xl">
                <p className=" text-primary font-bold text-xs leading-5">
                  Entity is legally authorized to conduct MSB activities in the
                  following states (source: FinCEN MSB registration).
                </p>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        {markersLoading !== "pending" && licensesLoading !== "pending" && (
          <button
            onClick={() => setRegion(mapRange === "world" ? "US" : "world")}
          >
            <img
              className="absolute top-[340px] left-[280px] w-5 h-5 cursor-pointer"
              src={mapRange === "world" ? PlusImg : MinusImg}
              alt="To US"
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default GeoChartJVector;

import React, { useEffect, useState } from "react";
import TrendingUpImg from "../assets/images/trending-up.png";

const data = [
  { id: 1, country: "United State", score: 34275, bg: true, badge: "#2563EB" },
  { id: 2, country: "Indonesia", score: 20390, bg: false, badge: "#FFD023" },
  { id: 3, country: "France", score: 15732, bg: true, badge: "#FF0000" },
  { id: 4, country: "Canada", score: 14589, bg: false, badge: "#04D866" },
  {
    id: 5,
    country: "United Kingdom",
    score: 10264,
    bg: false,
    badge: "#FE964A",
  },
];
const TrafficInformation = () => {
  const [totalValue, setTotalValue] = useState(0);

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    let temp = 0;
    data.map((item) => (temp = temp + item.score));
    setTotalValue(temp);
  }, []);
  return (
    <div>
      <div className="flex items-center">
        <div className="bg-primary rounded-[8px] px-3 py-2 min-w-[160px] md:min-w-[200px]">
          <p className="text-primary text-[24px] font-extrabold text-left">
            {numberWithCommas(totalValue)}
          </p>
          <p className="text-info text-left text-[10px]">
            Compared To Last Month
          </p>
        </div>
        <div className="flex items-center bg-active rounded-full py-1 px-2 ml-4 md:ml-40 animate-pulse">
          <img src={TrendingUpImg} alt="trendingUp" />
          <p className="text-[8px] font-bold ml-2">12, 00%</p>
        </div>
      </div>

      {data.map((item, i) => (
        <div
          key={item.id}
          className={`flex items-center md:w-1/2 justify-between rounded-[8px] my-2 px-3 py-2 ${
            item.bg ? "bg-black" : "bg-primary"
          }`}
        >
          <div className="flex items-center">
            <div
              className="w-2 h-2 rounded-full mr-2"
              style={{ backgroundColor: item.badge }}
            />
            <p className="text-primary">{item.country}</p>
          </div>
          <p className={`text-info ${item.bg ? "animate-pulse" : ""}`}>{numberWithCommas(item.score)}</p>
        </div>
      ))}
    </div>
  );
};

export default TrafficInformation;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchAlerts = createAsyncThunk(
  "alerts/getAPI",
  async () => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/regulatory-alerts`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    let data = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
    console.log(data);
    return data;
  }
);

const initialState = {
  regalerts: [],
  loading: "idle",
};

const alertsslice = createSlice({
  name: "regulatory-alerts",
  initialState,
  reducers: {
    initAlertsLoading: (state) => {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlerts.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchAlerts.fulfilled, (state, action) => {
      state.loading = "idle";
      state.regalerts = action.payload;
    });
    builder.addCase(fetchAlerts.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const { initAlertsLoading } = alertsslice.actions;

export const getAlerts = (state) => state.regalerts;
export const getAlertsLoading = (state) => state.regalerts.loading;

export default alertsslice.reducer;

// components/GeoChart.js
import React, { useEffect } from 'react';
import {
    getLicenses,
  } from "../../redux/slices/allLicenses";
import { useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import DashboardNumbers from './DashboardNumbers';
import jsonData from '../../regions.json';
const GeoAllocationVasps = () => {
    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    const licensesAll = useSelector(getLicenses);

    const [chartData,setChartData] = React.useState(null);
    const [chartDataByRisk,setChartDataByRisk] = React.useState(null);
    const [continentData,setContinentData] = React.useState([]);
  useEffect(() => {
    // Ensure google charts library is loaded
    if (window.google && window.google.charts) {
      window.google.charts.load('current', {
        'packages': ['geochart'],
      });
      window.google.charts.setOnLoadCallback(drawRegionsMap);
    }

    function drawRegionsMap() {
        if(licensesAll){
            // let array = [
            //     ['Country', 'Popularity'],
            // ];
            const newCountryCounts = licensesAll.reduce((acc, vasp) => ({ ...acc, [vasp.country]: (acc[vasp.country] || 0) + 1 }), {});
            const transformedData = Object.entries(newCountryCounts).map(([country, count]) => [countries.getName(country, 'en'), count]);
            const dataNoHeader = [...transformedData];
            transformedData.unshift(['Country', 'Vasps']);
    


            //REGION DATA
            const countryToRegionMap = jsonData.reduce((acc, curr) => {
                acc[curr.country] = curr.region;
                return acc;
            }, {});


            const aggregatedData = dataNoHeader.reduce((acc, [country, value]) => {
              const region = countryToRegionMap[country];
              if(region) {
                  acc[region] = (acc[region] || 0) + value;
              }
              return acc;
            }, {});

            setChartData({
              labels: Object.keys(aggregatedData),
              datasets: [
                  {
                      label: 'Entities by Region',
                      data: Object.values(aggregatedData),
                      backgroundColor: [
                        '#217EFD'
                      ],
                      borderColor: [
                        '#217EFD'
                      ],
                      borderWidth: 1,
                  },
              ],
          });
          console.log('cd',chartData);

          //HighRisk

          const riskKeys = [
            "High Risk Third Country (EU)",
            "High Risk Third Country (UK)",
            "FATF Jurisdictions under Increased Monitoring",
            "FATF High-Risk Jurisdictions"
        ];
        
        const countryToRiskMap = jsonData.reduce((acc, countryData) => {
            riskKeys.forEach(riskKey => {
                if (countryData[riskKey] === "Yes") {
                    if (!acc[countryData.country]) {
                        acc[countryData.country] = [];
                    }
                    acc[countryData.country].push(riskKey);
                }
            });
            return acc;
        }, {});
        
        const aggregatedDataByRisk = dataNoHeader.reduce((acc, [country, value]) => {
            const riskCategories = countryToRiskMap[country] || [];
            riskCategories.forEach(riskCategory => {
                acc[riskCategory] = (acc[riskCategory] || 0) + value;
            });
            return acc;
        }, {});
        setChartDataByRisk({
          labels: riskKeys,
          datasets: [
              {
                  label: 'Entities by High-Risk Jurisdictions',
                  data: riskKeys.map(riskKey => aggregatedDataByRisk[riskKey] || 0),
                  backgroundColor: [
                    '#217EFD'
                  ],
                  borderColor: [
                    '#217EFD'
                  ],
                  borderWidth: 1,
              },
          ],
      });

        const countryToContinentMap = jsonData.reduce((acc, curr) => {
            acc[curr.country] = curr.continent;
            return acc;
        }, {});

        const aggregatedDataByContinent = dataNoHeader.reduce((acc, [country, value]) => {
        const continent = countryToContinentMap[country];
        if(continent) {
            acc[continent] = (acc[continent] || 0) + value;
        }
        return acc;
    }, {});
    
    const mainContinents = ["Africa", "Antarctica", "Asia", "Europe", "North America", "Oceania", "South America"];
    const totalValue = Object.values(aggregatedDataByContinent).reduce((sum, value) => sum + value, 0);

    const result = mainContinents.map(continent => {
        const totalForContinent = aggregatedDataByContinent[continent] || 0;
        return {
            name: continent,
            number: totalForContinent,
            average: totalForContinent / totalValue
        };
    });
    console.log('result',result);
    setContinentData(result);
    
    
    
    
    
    
            const data = window.google.visualization.arrayToDataTable(transformedData);
    
          const options = {
            colorAxis: {
                colors: ['#5c73f2', '#4b4bd6', '#0707ed'],  // You can add more colors here as needed.
                values: [1, 50, 200]  // Adjust this based on your data's range
              },
            backgroundColor:'#000'
          };
    
          const chart = new window.google.visualization.GeoChart(document.getElementById('licenses_div'));
    
          chart.draw(data, options);
        }
        
    }
  }, [licensesAll]);
  const headerTitleClass =
    "px-2 md:px-12 py-2 font-bold text-primary text-lg text-center";

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + " . . ." : str;
  };

  return (
    <>
      <div id="licenses_div" style={{ width: '90%', height: '500px',margin:'5%' }}></div>
      <DashboardNumbers regions={chartData} highRisk={chartDataByRisk} continents={continentData} showLastGraph={false}/>


      {/* <GraphsVaspsGeo/> */}
      {/* {regalerts.regalerts&&
        <RegulatoryAlertTable alerts={regalerts.regalerts} alertsLoading={regalertsLoading} headerTitleClass={headerTitleClass} truncate={truncate} showEntityName={true}/>
      } */}
    </>
  );
};

export default GeoAllocationVasps;

import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as RegulatoryInfo } from "../assets/images/regulatory-info.svg";
import { ReactComponent as LegalInfo } from "../assets/images/legal-info.svg";
import { ReactComponent as GeoInfo } from "../assets/images/geo-info.svg";
import { ReactComponent as SupportedAssets } from "../assets/images/supported-assets.svg";
import { ReactComponent as KYC } from "../assets/images/kyc.svg";
import { ReactComponent as Network } from "../assets/images/network.svg";
import { ReactComponent as WebTraffic } from "../assets/images/web-traffic.svg";
import { ReactComponent as RegulatoryWarning } from "../assets/images/alerts.svg";
import { ReactComponent as Lorem } from "../assets/images/user.svg";
import { ReactComponent as Settings } from "../assets/images/settings.svg";
import { ReactComponent as LogOut } from "../assets/images/logout.svg";
import { ReactComponent as Close } from "../assets/images/close.svg";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import { useDispatch } from "react-redux";
import { loginClear } from "../redux/slices/login";
import {
  changeTabName,
  changeTabListOpen,
  getTabListOpen,
} from "../redux/slices/tabs";
import {
  getCurrentEntity,
  changeCurrentEntity,
  fetchEntities,
  getEntityLoading,
  initEntLoading,
} from "../redux/slices/entities";
import {
  fetchLicenses,
  getLicensesLoading,
  initLicensesLoading,
} from "../redux/slices/licenses";
import {
  fetchSupportedCountries,
  getCLoading,
  initCLoading,
} from "../redux/slices/supportedcountries";
import {
  setLegalInfoMarkers,
  setLegalInfoRegion,
  setVaspsMarkers,
  setVaspsRegion,
} from "../features/GeoLocation";
import { toastChanged } from "../redux/slices/toast";
import { fetchUSsupported } from "../redux/slices/USsupported";
import { fetchSupportedAssets } from "../redux/slices/supportedAssets";
import { fetchRestrictedCountries } from "../redux/slices/restrictedCountries";
import { fetchTieredKYC, fetchNonTieredKYC,fetchNoKYC } from "../redux/slices/entityKYC";
import { fetchFounders, getFounders } from "../redux/slices/founders";
import { fetchAlerts, getAlerts } from "../redux/slices/alert";
import {
  changeLegalMarkerLoading,
  changeLegalMarkers,
  changeLegalRegion,
  changeVaspsMarkers,
  changeVaspsRegion,
  fetchLegalInfo,
  fetchVaspsByEntity,
  getLegalInfo,
  getLegalInfoLoading,
  getVasps,
  initLegalInfoLoading,
} from "../redux/slices/legalInfo";
import { removeFilterAll } from "../redux/slices/filters";

const Layout = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentEntity = useSelector(getCurrentEntity);
  const tabListOpen = useSelector(getTabListOpen);
  const founders = useSelector(getFounders);
  const alerts = useSelector(getAlerts);
  const legalInfo = useSelector(getLegalInfo);
  const vasps = useSelector(getVasps);
  const entityLoading = useSelector(getEntityLoading);
  const licensesLoading = useSelector(getLicensesLoading);
  const legalInfoLoading = useSelector(getLegalInfoLoading);
  const countriesLoading = useSelector(getCLoading);

  const testMenuItems = [
    {
      logo: <LegalInfo />,
      href: "legal-info",
      title: "Legal Information",
      badge: 0,
    },
    {
      logo: <RegulatoryInfo />,
      href: "regulatory-info",
      title: "Regulatory Authorizations",
      badge: 0,
    },
    {
      logo: <GeoInfo />,
      href: "geo-info",
      title: "Geo Information",
      badge: 0,
    },
    {
      logo: <SupportedAssets />,
      href: "supported-assets",
      title: "Supported Assets",
      badge: 0,
    },
    {
      logo: <KYC />,
      href: "kyc",
      title: "AML & KYC",
      badge: 0,
    },
    // {
    //   logo: <Network />,
    //   href: "network",
    //   title: "Network",
    //   badge: 0,
    // },
    // {
    //   logo: <WebTraffic />,
    //   href: "web-traffic",
    //   title: "Web Traffic",
    //   badge: 0,
    // },
    {
      logo: <RegulatoryWarning />,
      href: "regulatory-alert",
      title: "Regulatory Alert",
      badge: 10,
    },
    // {
    //   logo: <Lorem />,
    //   href: "other",
    //   title: "Lorem Ipsum",
    //   badge: 0,
    // },
    // {
    //   logo: <Settings />,
    //   href: "settings",
    //   title: "Settings",
    //   badge: 0,
    // },
  ];

  useEffect(() => {
    if (
      entityLoading === "failed" ||
      licensesLoading === "failed" ||
      legalInfoLoading === "failed" ||
      countriesLoading === "failed"
    ) {
      dispatch(
        toastChanged({
          data: "Your session has expired, please login again!",
          type: "error",
        })
      );
      dispatch(loginClear());
      dispatch(initEntLoading());
      dispatch(initCLoading());
      dispatch(initLicensesLoading());
      dispatch(initLegalInfoLoading());
      localStorage.removeItem("vasp-token");
      navigate("/", { replace: true });
    }
  }, [entityLoading, licensesLoading, legalInfoLoading, countriesLoading]);

  const handleLogOut = () => {
    localStorage.removeItem("vasp-token");
    localStorage.removeItem("filters");
    dispatch(loginClear());
    dispatch(removeFilterAll());
    dispatch(changeCurrentEntity(""));
    navigate("/", { replace: true });
  };

  const getLegalInfoMarkers = async () => {
    const temp = await setLegalInfoMarkers(legalInfo);
    dispatch(changeLegalMarkers(temp));
  };

  const getLegalInfoRegion = async () => {
    const temp = await setLegalInfoRegion(legalInfo);
    dispatch(changeLegalRegion(temp));
  };

  const getVaspsMarkers = async () => {
    const temp = await setVaspsMarkers(vasps);
    dispatch(changeVaspsMarkers(temp));
  };

  const getVaspsRegion = async () => {
    const temp = await setVaspsRegion(vasps);
    dispatch(changeVaspsRegion(temp));
  };

  const normalItemClass =
    "text-secondary text-[14px] font-medium flex items-center h-12 hover:opacity-75 hover:text-primary";
  const activeItemClass =
    "text-primary text-[14px] font-bold bg-normal rounded-[12px] flex items-center h-12  hover:opacity-75";
  const buttonClass =
    "w-[100px] h-5 rounded-full text-primary text-[11px] my-4  hover:opacity-75";

  useEffect(() => {
    testMenuItems.map((item) => {
      if (location.pathname.includes(item.href))
        dispatch(changeTabName(item.title));
    });
    dispatch(changeTabListOpen(false));
  }, [location]);

  useEffect(() => {
    dispatch(changeLegalMarkerLoading("pending"));
    getLegalInfoMarkers();
    getLegalInfoRegion();
  }, [legalInfo]);

  useEffect(() => {
    getVaspsMarkers();
    getVaspsRegion();
  }, [vasps]);

  useEffect(() => {
    dispatch(fetchSupportedCountries(currentEntity));
    dispatch(fetchUSsupported(currentEntity));
    dispatch(fetchSupportedAssets(currentEntity));
    dispatch(fetchRestrictedCountries(currentEntity));
    dispatch(fetchTieredKYC(currentEntity));
    dispatch(fetchFounders(currentEntity));
    dispatch(fetchNonTieredKYC(currentEntity));
    dispatch(fetchNoKYC(currentEntity));
    dispatch(fetchAlerts(currentEntity));
  }, [currentEntity]);

  useEffect(() => {
    const userToken = localStorage.getItem("vasp-token");
    if (!userToken) navigate("/", { replace: true });
    const currentEnt = localStorage.getItem("current-entity");
    if (!currentEntity) {
      dispatch(changeCurrentEntity(currentEnt));
      dispatch(fetchLicenses({ payload: currentEnt }));
      dispatch(fetchLegalInfo({ payload: currentEnt }));
      dispatch(fetchVaspsByEntity({ payload: currentEnt }));
    }
    dispatch(fetchEntities());
  }, []);

  return (
    <>
      <Header />
      <div className="hidden md:flex min-h-screen flex-col">
        <div className="flex flex-col md:flex-row">
          {currentEntity && (
            <aside className="bg-primary pt-[88px] md:w-[250px] h-screen overflow-y-scroll scrollbar-hide">
              <div className="flex flex-col items-start h-[280px] border-b-2 border-[#505050]">
                {/* <button
                  className={`bg-hot ml-2.5 ${buttonClass}`}
                  onClick={() => alert("this is Sanctioned button")}
                >
                  Sanctioned
                </button> */}
                <p className="text-primary text-xl font-bold ml-2.5 my-4">
                  {currentEntity.toUpperCase()}
                </p>
                <div className="flex ml-2.5">
                  <button
                    className={`bg-normal mr-4 ${buttonClass}`}
                    onClick={() => alert("this is Exchange button")}
                  >
                    Exchange
                  </button>
                  <button
                    className={
                      isActive
                        ? `bg-active ${buttonClass}`
                        : `bg-inActive ${buttonClass}`
                    }
                    onClick={() => setIsActive(!isActive)}
                  >
                    {isActive ? "Active" : "Inactive"}
                  </button>
                </div>
                <p className="text-primary text-md font-normal mx-2.5">
                  {founders[0]?.founders ? "Founded By:" : ""}
                </p>
                <p className="text-primary text-md font-bold text-left mx-2.5">
                  {founders[0]?.founders}
                </p>
                <p className="text-primary text-md pt-3 font-normal mx-2.5">
                  {founders[0]?.date_founded ? "Founding date:" : ""}
                </p>
                <p className="text-primary text-md font-bold mx-2.5">
                  {founders[0]?.date_founded}
                </p>
              </div>
              <nav>
                <ul>
                  {testMenuItems.map(({ logo, href, title, badge }) => (
                    <li className="m-2" key={title}>
                      <NavLink to={href}>
                        <p
                          className={
                            location.pathname.includes(href) ||
                            (location.pathname === "/" &&
                              title === "Legal Information")
                              ? activeItemClass
                              : normalItemClass
                          }
                        >
                          <span
                            style={{
                              color:
                                location.pathname.includes(href) ||
                                (location.pathname === "/" &&
                                  title === "Legal Information")
                                  ? "#fff"
                                  : "#938D8D",
                              marginRight: 10,
                              marginLeft: 16,
                              width: 25,
                            }}
                            className="font-inter"
                          >
                            {logo}
                          </span>

                          {title}
                          {badge !== 0 && (
                            <span className="flex items-center justify-center ml-2 px-2 h-[22px] text-xs bg-badge text-primary rounded-full">
                              {alerts.length}
                            </span>
                          )}
                        </p>
                      </NavLink>
                    </li>
                  ))}
                  <li className="m-2" key="logout">
                    <button className="w-full" onClick={() => handleLogOut()}>
                      <p className={normalItemClass}>
                        <span
                          style={{
                            color: "#938D8D",
                            marginRight: 16,
                            marginLeft: 16,
                          }}
                          className="font-inter"
                        >
                          <LogOut />
                        </span>
                        Log Out
                      </p>
                    </button>
                  </li>
                </ul>
              </nav>
            </aside>
          )}
          <main className={"flex-1 min-w-0"}>
            <Outlet />
          </main>
        </div>
      </div>
      <div
        className={`${
          tabListOpen ? "block" : "hidden"
        } md:hidden absolute top-0 bg-primary w-full h-screen p-4 pt-8 overflow-y-scroll scrollbar-hide z-50`}
      >
        <div className="flex flex-col items-start h-[200px] border-b-2 border-[#505050]">
          <div
            style={{ color: "white" }}
            className=" absolute right-3 top-7"
            onClick={() => dispatch(changeTabListOpen(false))}
          >
            <Close />
          </div>
          {/* <button
            className={`bg-hot ml-2.5 ${buttonClass}`}
            onClick={() => alert("this is Sanctioned button")}
          >
            Sanctioned
          </button> */}
          <p className="text-primary text-[20px] font-bold mx-2.5">
            {currentEntity.toUpperCase()}
          </p>
          <div className="flex mx-2.5">
            <button
              className={`bg-normal mr-4 ${buttonClass}`}
              onClick={() => alert("this is Exchange button")}
            >
              Exchange
            </button>
            <button
              className={
                isActive
                  ? `bg-active ${buttonClass}`
                  : `bg-inActive ${buttonClass}`
              }
              onClick={() => setIsActive(!isActive)}
            >
              {isActive ? "Active" : "Inactive"}
            </button>
          </div>
          <p className="text-primary text-md font-bold mx-2.5">
            {founders[0]?.founders ? "Founded By:" : ""} &nbsp;
            <span className="font-semibold">{founders[0]?.founders}</span>
          </p>
          <p className="text-primary text-md font-bold mx-2.5">
            {founders[0]?.date_founded ? "Founding date:" : ""} &nbsp;
            <span className="font-semibold">{founders[0]?.date_founded}</span>
          </p>
        </div>
        <nav>
          <ul>
            {testMenuItems.map(({ logo, href, title, badge }) => (
              <li className="m-2" key={title}>
                <NavLink to={href}>
                  <p
                    className={
                      location.pathname.includes(href) ||
                      (location.pathname === "/" &&
                        title === "Legal Information")
                        ? activeItemClass
                        : normalItemClass
                    }
                  >
                    <span
                      style={{
                        color:
                          location.pathname.includes(href) ||
                          (location.pathname === "/" &&
                            title === "Legal Information")
                            ? "#fff"
                            : "#938D8D",
                        marginRight: 10,
                        marginLeft: 16,
                        width: 25,
                      }}
                      className="font-inter"
                    >
                      {logo}
                    </span>

                    {title}
                    {badge !== 0 && (
                      <span className="flex items-center justify-center ml-2 px-2 h-[22px] text-[12px] bg-badge rounded-full animate-pulse">
                        10
                      </span>
                    )}
                  </p>
                </NavLink>
              </li>
            ))}
            <li className="m-2" key="logout">
              <button className="w-full" onClick={() => handleLogOut()}>
                <p className={normalItemClass}>
                  <span
                    style={{
                      color: "#938D8D",
                      marginRight: 16,
                      marginLeft: 16,
                    }}
                    className="font-inter"
                  >
                    <LogOut />
                  </span>
                  Log Out
                </p>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <main
        className={`${tabListOpen ? "hidden" : "block"} md:hidden pt-28 flex-1`}
      >
        <Outlet />
      </main>
    </>
  );
};

export default Layout;

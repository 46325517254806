import React, { useState } from "react";
import AlertImg from "../assets/images/alert-circle.png";
import { useEffect } from "react";
import GeoChartJVector from "./GeoChartJVector";
import { getCode } from "country-list";
import { useSelector } from "react-redux";
import { getMapRange } from "../redux/slices/tabs";

const GeoMapJVector = ({ supportedCData, licenses }) => {
  const [geoData, setGeoData] = useState({});
  const mapRange = useSelector(getMapRange)

  const getfilteredCountry = (data) => {
    let list = {};
    for (let i = 0; i < data[0]?.supported_countries.length; i++) {
      list[getCode(getCountryName(data[0]?.supported_countries[i]))] = 1;
      if (getCode(getCountryName(data[0]?.supported_countries[i])) === "SO") {
        list["XS"] = 1;
      }
      if (getCode(getCountryName(data[0]?.supported_countries[i])) === "RS") {
        list["XK"] = 1;
      }
    }
    for (let i = 0; i < data[0]?.not_supported_countries.length; i++) {
      list[getCode(getCountryName(data[0]?.not_supported_countries[i]))] = 2;
      if (
        getCode(getCountryName(data[0]?.not_supported_countries[i])) === "SO"
      ) {
        list["XS"] = 2;
      }
      if (
        getCode(getCountryName(data[0]?.not_supported_countries[i])) === "RS"
      ) {
        list["XK"] = 2;
      }
    }
    setGeoData(list);
  };

  const getCountryName = (name) => {
    if (name === "Russia") return "Russian Federation";
    if (name === "Iran") return "Iran, Islamic Republic of";
    if (name === "Democratic Republic of the Congo")
      return "Congo, Democratic Republic of the";
    if (name === "Turkey") return "Türkiye";
    if (name === "United Kingdom")
      return "United Kingdom of Great Britain and Northern Ireland";
    if (name === "Tanzania") return "Tanzania, United Republic of";
    if (name === "Vietnam") return "Viet Nam";
    if (name === "Bolivia") return "Bolivia, Plurinational State of";
    if (name === "Venezuela") return "Venezuela, Bolivarian Republic of";
    if (name === "Czech Rep.") return "CzechiaCzechia";
    if (name === "Venezuela") return "Venezuela, Bolivarian Republic of";
    if (name === "United States") return "United States of America";
    if (name === "Moldova") return "Moldova, Republic of";
    if (name === "Syria") return "Syrian Arab Republic";
    if (name === "North Korea") return "Korea, Democratic People's Republic of";
    if (name === "South Korea") return "Korea, Republic of";
    if (name === "Moldova") return "Moldova, Republic of";
    if (name === "Czech Republic") return "Czechia";
    if (name === "Republic of the Congo") return "Congo";
    if (name === "Taiwan") return "Taiwan, Province of China";
    if (name === "Falkland Islands") return "Falkland Islands (Malvinas)";

    return name;
  };

  useEffect(() => {
    getfilteredCountry(supportedCData);
  }, [supportedCData]);
  return (
    <div className="relative">
      <div className="absolute top-5 z-20 flex justify-between w-full px-10">
        <p className="flex items-center text-primary font-bold text-2xl">
          Supported & Restricted {mapRange === "world" ? "Locations" : "States"}
          <img
            src={AlertImg}
            alt="alert"
            className="mx-2"
            title="Geo Information is self-reported. Self-reported information refers to data or information provided directly by the company, through a public disclosure."
          />
        </p>
      </div>
      <GeoChartJVector geoData={geoData} licenses={licenses} />
      <div style={{ backgroundColor:'#FF5F00',padding:10,margin:10 }}>
      <label>Country where VASP claims to offer services and where no regulatory permission was identified. Please note that VASP may not be required to obtain regulatory permission in certain jurisdictions.</label>
      </div>
    </div>
  );
};

export default GeoMapJVector;

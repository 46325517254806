import React from "react";

const AssetsNotes = ({ data }) => {
  return (
    <div className="flex flex-col p-5 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden pt-4">
      <p className=" text-primary text-left font-bold text-2xl">Notes</p>
      <div className=" h-[1px] w-full bg-white my-10" />
      <p className=" text-primary text-left text-lg font-light">{data}</p>
      <div className=" h-[1px] w-full bg-white my-10" />
    </div>
  );
};

export default AssetsNotes;

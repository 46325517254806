
const {createSlice} = require("@reduxjs/toolkit");

const initialState = {
    toastData: "Hello",
    toastType: "",
};

const toastslice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        toastChanged: (state, {payload}) => {
            state.toastData = payload.data;
            state.toastType = payload.type;
        },
    },
});

export const {toastChanged} = toastslice.actions;

export const getToastData = (state) => state.toast.toastData;
export const getToastType = (state) => state.toast.toastType;

export default toastslice.reducer;
import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchLicenses = createAsyncThunk(
  "licenses/getAPI",
  async () => {
    console.log('testing 123');
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/licenses`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  allLicenses: [],
  allLicensesMarkers: [],
  allLicensesLoading: "idle",
  markersLoading: "idle",
};

const licenseslice = createSlice({
  name: "licence",
  initialState,
  reducers: {
    changeLMarkers: (state, { payload }) => {
      state.allLicensesMarkers = payload;
      state.markersLoading = "idle";
    },
    changeLMarkerLoading: (state, { payload }) => {
      state.allLicensesMarkers = payload;
    },
    initLicensesLoading: (state) => {
      state.allLicensesLoading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLicenses.pending, (state, action) => {
      state.allLicensesLoading = "pending";
    });
    builder.addCase(fetchLicenses.fulfilled, (state, action) => {
      state.allLicensesLoading = "idle";
      state.allLicenses = action.payload;
    });
    builder.addCase(fetchLicenses.rejected, (state, action) => {
      state.allLicensesLoading = "failed";
    });
  },
});

export const { changeLMarkers, initLicensesLoading, changeLMarkerLoading } =
  licenseslice.actions;

export const getLicenses = (state) => state.allLicenses.allLicenses;
export const getLicensesMarkers = (state) => state.allLicenses.allLicensesMarkers;
export const getLicensesLoading = (state) => state.allLicenses.allLicensesLoading;
export const getLMarkerLoading = (state) => state.allLicenses.markersLoading;

export default licenseslice.reducer;

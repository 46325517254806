import React from "react";
import WebTrafficMap from "../components/WebTrafficMap";
import GenderDistribution from "../components/GenderDistribution";
import AgeDistribution from "../components/AgeDistribution";

const WebTraffic = () => {
  return (
    <div className="bg-black pt-[88px] pb-10 h-screen overflow-scroll scrollbar-hide">
      <WebTrafficMap />
      <GenderDistribution />
      <AgeDistribution />
    </div>
  );
};

export default WebTraffic;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchRestrictedCountries = createAsyncThunk(
  "restrictedCountries/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/restricted-zones?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  restrictedCountries: [],
  resMarkers: [],
  loading: "idle",
  resMarkersLoading: "idle",
};

const restrictedCountriesslice = createSlice({
  name: "restrictedCountries",
  initialState,
  reducers: {
    changeResMarkers: (state, { payload }) => {
      state.resMarkers = payload;
      state.resMarkersLoading = "idle";
    },
    changeResMarkersLoading: (state, { payload }) => {
      state.resMarkersLoading = payload;
    },
    initRCLoading: (state) => {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRestrictedCountries.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchRestrictedCountries.fulfilled, (state, action) => {
      state.loading = "idle";
      state.restrictedCountries = action.payload;
    });
    builder.addCase(fetchRestrictedCountries.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const {
  initRCLoading,
  changeResMarkers,
  changeResMarkersLoading,
} = restrictedCountriesslice.actions;

export const getRestrictedCountries = (state) =>
  state.restrictedCountries.restrictedCountries;
export const getResMarkers = (state) => state.restrictedCountries.resMarkers;
export const getResMarkersLoading = (state) =>
  state.restrictedCountries.resMarkersLoading;
export const getRCLoading = (state) => state.restrictedCountries.loading;

export default restrictedCountriesslice.reducer;


const {createSlice} = require("@reduxjs/toolkit");

const initialState = {
    tabName: "",
    tabListOpen: false,
    mapRange: "world",
};

const tabsslice = createSlice({
    name: "tabs",
    initialState,
    reducers: {
        changeTabName: (state, {payload}) => {
            state.tabName = payload;
        },
        changeTabListOpen: (state, {payload}) => {
            state.tabListOpen = payload;
        },
        changeMapRange: (state, {payload}) => {
            state.mapRange = payload;
        },
    },
});

export const {changeTabName, changeTabListOpen, changeMapRange} = tabsslice.actions;

export const getTabName = (state) => state.tabs.tabName;
export const getTabListOpen = (state) => state.tabs.tabListOpen;
export const getMapRange = (state) => state.tabs.mapRange;

export default tabsslice.reducer;
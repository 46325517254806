import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeFilters, getFilters } from "../redux/slices/filters";
import { addOrReplaceArray } from "../features/Filters";

export const Chip = ({ item }) => {
  const dispatch = useDispatch();

  const filters = useSelector(getFilters);
  const removeItem = (id) => {
    let temps = [...filters];
    dispatch(changeFilters(addOrReplaceArray(temps, { id: id, value: [] })));
  };
  return (
    <div className="flex items-center border border-[#fff] rounded-3xl bg-inActive px-3 hover:border-opacity-20 hover:cursor-pointer">
      <div className=" leading-5">
        <p className="text-primary italic text-xs p-0 text-left">
          {item.id === 0
            ? "Primary Location"
            : item.id === 1
            ? "Has Legal Entity in"
            : item.id === 2
            ? "Holds Permission Issued in"
            : "Offers services in"}
        </p>
        <div className="flex justify-center items-center">
          <p className="text-black text-md font-semibold p-0">{item.value.map(a => JSON.stringify(a)).join(", ")}</p>
        </div>
      </div>
      <p
        className="flex items-center justify-center font-bold text-xl hover:bg-hover rounded-xl ml-3 pb-1 w-5 h-5"
        onClick={() => removeItem(item.id)}
      >
        &times;
      </p>
    </div>
  );
};

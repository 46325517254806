import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlusImg from "../assets/images/plus.png";
import MinusImg from "../assets/images/minus.png";
import ResMarkerIcon from "../assets/images/resMarker.png";
import NoDataMarkerIcon from "../assets/images/noDataMarker.png";
import { changeMapRange, getMapRange } from "../redux/slices/tabs";
import { VectorMap } from "@react-jvectormap/core";
import { paths } from "../utils/paths";
import { insets } from "../utils/insets";
import { USinsets } from "../utils/US-insets";
import { USpaths } from "../utils/US-paths";
import {
  getResMarkers,
  getResMarkersLoading,
} from "../redux/slices/restrictedCountries";
import { getLicensesLoading } from "../redux/slices/licenses";
import { CircleLoading } from "../features/Symbols";

function GeoResMapJVector({ zoomScroll }) {
  const resMarkersLoading = useSelector(getResMarkersLoading);
  const licensesLoading = useSelector(getLicensesLoading);
  const mapRange = useSelector(getMapRange);
  const resMarkers = useSelector(getResMarkers);
  const [showMap, setShowMap] = useState(true);

  const dispatch = useDispatch();

  const setRegion = (reg) => {
    dispatch(changeMapRange(reg));
    setShowMap(false);
  };

  useEffect(() => {
    if (!showMap) setShowMap(true);
  }, [showMap]);

  useEffect(() => {
    setShowMap(false);
  }, [resMarkers, zoomScroll]);
  return (
    <div className="flex items-center justify-center m-3 md:m-5 bg-primary rounded-lg md:rounded-2xl shadow-md shadow-[#2563EB] overflow-hidden pt-4">
      <div className="relative w-[1000px] h-[620px] overflow-y-hidden overflow-x-auto scrollbar-hide map-marker">
        {resMarkersLoading === "pending" || licensesLoading === "pending" ? (
          <CircleLoading />
        ) : showMap ? (
          <div
            className={`relative w-[1000px] h-[620px] ${
              mapRange !== "world" ? "mt-16" : ""
            }`}
          >
            <VectorMap
              backgroundColor="none"
              zoomOnScroll={zoomScroll}
              map={{
                content: {
                  height: 600,
                  insets: mapRange === "world" ? insets : USinsets,
                  paths: mapRange === "world" ? paths : USpaths,
                  projection: {
                    centralMeridian: 11.5,
                    type: "merc",
                  },
                  width: 900,
                },
                name: "world_merc",
              }}
              zoomButtons={false}
              markerStyle={{
                initial: {
                  image: ResMarkerIcon,
                },
              }}
              markers={resMarkers}
              onMarkerTipShow={(event, label, index) => {
                label.html(
                  "<p>RESTRICTED AREA:</p> <p>" +
                    resMarkers[index].name +
                    "</p>"
                );
              }}
              markersSelectable
              regionStyle={{
                initial: {
                  fill: "#585858",
                },
              }}
              mapRef={{
                current: "[Circular]",
              }}
              selectedRegions={[]}
              series={{}}
            />
          </div>
        ) : (
          <></>
        )}
        {resMarkersLoading !== "pending" && licensesLoading !== "pending" && (
          <button
            onClick={() => setRegion(mapRange === "world" ? "US" : "world")}
          >
            <img
              className="absolute top-[320px] left-[200px] w-5 h-5 cursor-pointer"
              src={mapRange === "world" ? PlusImg : MinusImg}
              alt="To US"
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default GeoResMapJVector;

export const addOrReplaceArray = (arr1, item) => {
  const temp = [...arr1];
  const index = temp.findIndex((obj) => obj.id === item.id);

  if (index === -1) {
    temp.push(item);
  } else {
    temp[index] = item;
  }

  return temp.sort((a, b) => a.id > b.id ? 1 : -1);
};

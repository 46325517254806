import React from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import AlertImg from "../assets/images/alert-circle.png";
import { CircleLoading, NoItems } from "../features/Symbols";
import { getLegalInfo, getLegalInfoLoading } from "../redux/slices/legalInfo";
import { getCountryByCode } from "../features/GeoLocation";

const ListLegalNames = () => {
  const legalInfoLoading = useSelector(getLegalInfoLoading);
  const legalInfo = useSelector(getLegalInfo);

  const headerTitleClass =
    " whitespace-nowrap text-center text-base px-2 md:px-12 py-4 font-thin italic text-primary";
  const rowTextClass = "text-center px-2 md:px-12 py-4 text-primary text-lg";
  const rowTextClass1 = "px-2 md:px-12 py-4 text-primary text-lg";

  return (
    <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
      <p className="flex items-center text-primary font-bold text-2xl">
        Legal Entities
        <img
          src={AlertImg}
          alt="alert"
          className="mx-2"
          title="Section refers to the official names under which the entity is registered and conducts its business activities."
        />
      </p>
      <div className="flex flex-col w-full">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 scrollbar-hide">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              {legalInfoLoading === "pending" ? (
                <CircleLoading />
              ) : (
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="font-medium ">
                    <tr>
                      <th
                        scope="col"
                        className={`${headerTitleClass} text-left`}
                      >
                        Business Name
                      </th>
                      <th scope="col" className={headerTitleClass}>
                        Country of Incorporation
                      </th>
                      <th scope="col" className={headerTitleClass}></th>
                      <th scope="col" className={headerTitleClass}>
                        Date of Incorporation
                      </th>
                      <th scope="col" className={headerTitleClass}>
                        Company Registration Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {legalInfo?.map((item, i) => (
                      <tr
                        className={`transition duration-300 ease-in-out hover:bg-primary dark:hover:bg-neutral-600 ${
                          i !== legalInfo.length - 1
                            ? " border-b-[1px] border-[#F1F4F9]"
                            : ""
                        }`}
                        key={item.id}
                      >
                        <td
                          className={`relative font-bold max-w-md whitespace-normal overflow-hidden ${rowTextClass1} `}
                        >
                          {item.legal_name}
                        </td>
                        <td className={`font-light ${rowTextClass}`}>
                          <div className="flex items-center h-full whitespace-nowrap">
                            <div className="w-9 h-9 overflow-hidden mr-3 rounded-full">
                              <img
                                alt={item.country_of_incorporation}
                                onError={(e) =>
                                  (e.target.style.display = "none")
                                }
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item.country_of_incorporation}.svg`}
                                className="w-full h-full object-cover"
                              />
                            </div>
                            {getCountryByCode(item.country_of_incorporation)}
                          </div>
                        </td>
                        <td className={` ${rowTextClass1}`}>
                          {item.comment !== "" && (
                            <span title={item.comment}>
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
                                  stroke="#85A3BB"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 8.66666V13"
                                  stroke="#85A3BB"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 17.3333H13.0108"
                                  stroke="#85A3BB"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          )}
                          {item.source !== "" && (
                            <a href={item.source} target="_blank">
                              <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 13.0303C-0.00762939 7.96826 3.03577 3.40039 7.71014 1.45801C12.3846 -0.484375 17.7689 0.581543 21.3509 4.15771C24.9329 7.73437 26.0068 13.1172 24.0715 17.7944C22.1361 22.4722 17.573 25.522 12.511 25.5225C5.60986 25.5195 0.0131226 19.9312 0 13.0303V13.0303ZM2.35901 13.0303C2.35284 17.1377 4.82269 20.8442 8.61615 22.4209C12.4097 23.9971 16.7792 23.1318 19.686 20.2295C22.5929 17.3267 23.4642 12.9585 21.8935 9.1626C20.3228 5.36719 16.6194 2.8916 12.5115 2.89209C6.91101 2.89404 2.36932 7.42969 2.35895 13.0298L2.35901 13.0303ZM7.73901 17.7964C7.42761 17.4873 7.31525 17.0298 7.448 16.6118L9.32501 10.6211C9.44025 10.252 9.72913 9.96289 10.098 9.84766L16.098 7.97314C16.5165 7.84131 16.9736 7.95361 17.283 8.26514V8.26514C17.5944 8.57422 17.7068 9.03174 17.574 9.44971L15.697 15.4404C15.5817 15.8091 15.2927 16.0977 14.924 16.2119L8.92401 18.0864C8.81018 18.123 8.69147 18.1416 8.57196 18.1421C8.25928 18.1421 7.95947 18.0171 7.73901 17.7954V17.7964ZM11.391 11.9126L10.372 15.1665L13.633 14.1475L14.652 10.8906L11.391 11.9126Z"
                                  fill="#85A3BB"
                                />
                              </svg>
                            </a>
                          )}
                        </td>
                        <td className={`font-light ${rowTextClass}`}>
                          {moment(item.incorporation_date).format(
                            "YYYY-MM-DD"
                          ) === "Invalid date"
                            ? item.incorporation_date
                            : moment(item.incorporation_date).format(
                                "YYYY-MM-DD"
                              )}
                        </td>
                        <td className={`font-light ${rowTextClass}`}>
                          {item.registration_number}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {legalInfo.length === 0 && legalInfoLoading !== "pending" && (
                <NoItems />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListLegalNames;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const fetchLogin = createAsyncThunk(
  "login/getAPI",
  async (loginPayload) => {
    const response = await axios.post(
      `${config.API_BASE_URL}/login`,
      loginPayload
    );
    return response.data;
  }
);
const initialState = {
  loginData: [],
  loading: "idle",
};

const loginslice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginClear: (state) => {
      state.loading = "idle";
      state.loginData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.loading = "idle";
      state.loginData = action.payload;
    });
  },
});

export const { loginClear } = loginslice.actions;

export const getLogin = (state) => state.login.loginData;
export const getLoading = (state) => state.login.loading;

export default loginslice.reducer;

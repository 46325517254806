import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLicensesLoading } from "../redux/slices/licenses";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { CircleLoading } from "../features/Symbols";
import { getMapRange } from "../redux/slices/tabs";
import { getUSsupported, getUSsupported_1 } from "../redux/slices/USsupported";
import { getCode } from "country-list";

const SupportedCountries = ({ allCountries,licenses }) => {
  const licensesLoading = useSelector(getLicensesLoading);
  const USsupported = useSelector(getUSsupported_1);
  const mapRange = useSelector(getMapRange);
  const [currentPage, setCurrentPage] = useState(0);
  const [chunks, setChunks] = useState([]);
  const [supSelect, setSupSelect] = useState(true);
  const [resSelect, setResSelect] = useState(true);
  const [othSelect, setOthSelect] = useState(true);

  const cardClassTitle =
    "flex items-center justify-center text-primary text-base font-semibold h-16 w-48 border-b-2";
  const cardClassNormal =
    "flex items-center justify-center text-primary text-xs border-[3px] bg-[#585858] border-[#0F8344] leading-4 py-1 px-1 md:mx-2 lg:mx-3 xl:mx-5 min-h-[40px] rounded-lg mb-8";
  const cardClassSupport =
    "flex items-center justify-center text-primary text-xs border-[3px] bg-[#0F8344] border-[#0F8344] leading-4 py-1 px-1 md:mx-2 lg:mx-3 xl:mx-5 min-h-[40px] rounded-lg mb-8";
  const cardClassSupportNotLicensed = 
  "flex items-center justify-center text-primary text-xs border-[3px] bg-[#FF5F00] border-[#FF5F00] leading-4 py-1 px-1 md:mx-2 lg:mx-3 xl:mx-5 min-h-[40px] rounded-lg mb-8";
  const cardClassRestrict =
    "flex items-center justify-center text-primary text-xs border-[3px] bg-[#AF3A3A] border-[#0F8344] leading-4 md:py-1 px-1 md:mx-2 lg:mx-3 xl:mx-5 min-h-[40px] rounded-lg mb-8";

  const prevBtnClick = (prev) => {
    if (prev) {
      currentPage === chunks.length - 1
        ? setCurrentPage(0)
        : setCurrentPage(currentPage + 1);
    } else {
      currentPage === 0
        ? setCurrentPage(chunks.length - 1)
        : setCurrentPage(currentPage - 1);
    }
  };
  const CountryCard = (data) => {
    return (
      <div
        className={
          
          data.type === "supported"
            ? (data.licensed != -1?cardClassSupport:cardClassSupportNotLicensed)
            : data.type === "non-supported"
            ? cardClassRestrict
            : cardClassNormal
          
        }
      >
        {data.country}
      </div>
    );
  };

  const getFilteredArray = (arr) => {
    let tempArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (supSelect && arr[i].type === "supported") {
        tempArr.push(arr[i]);
      }
      if (resSelect && arr[i].type === "non-supported") {
        tempArr.push(arr[i]);
      }
      if (othSelect && arr[i].type === "other") {
        tempArr.push(arr[i]);
      }
    }
    return tempArr;
  };

  useEffect(() => {
    let numbers =
      mapRange === "world"
        ? getFilteredArray(allCountries)
        : supSelect
        ? USsupported
        : [];
    let chunkSize = 25;
    let res = [];
    for (let i = 0; i < numbers?.length; i += chunkSize) {
      res.push(numbers.slice(i, i + chunkSize));
    }
    setChunks(res);
  }, [allCountries, supSelect, resSelect, othSelect, mapRange]);

  return (
    <div className="relative flex flex-col items-start bg-primary m-3 md:m-5 mt-10 rounded-lg md:rounded-2xl overflow-hidden shadow-md shadow-[#2563EB] py-8">
      <p className="text-primary font-bold text-2xl px-10">
        Supported & Restricted {mapRange === "world" ? "Countries" : "States"}
      </p>
      {licensesLoading === "pending" ? (
        <CircleLoading />
      ) : (
        <>
          <div className="flex justify-center w-full py-7">
            <button
              className={`${cardClassTitle} ${
                supSelect ? "bg-[#217EFD] border-none" : " hover:text-[#217EFD]"
              } hover:opacity-90 transition duration-200`}
              onClick={() => setSupSelect(!supSelect)}
              style={{ borderRadius: "20% 20% 0% 0%" }}
            >
              SUPPORTED
            </button>
            <button
              className={`${cardClassTitle} ${
                resSelect ? "bg-[#217EFD] border-none" : " hover:text-[#217EFD]"
              } hover:opacity-90 transition duration-200`}
              onClick={() => setResSelect(!resSelect)}
              style={{ borderRadius: "20% 20% 0% 0%" }}
            >
              RESTRICTED
            </button>
            <button
              className={`${cardClassTitle} ${
                othSelect ? "bg-[#217EFD] border-none" : " hover:text-[#217EFD]"
              } hover:opacity-90 transition duration-200`}
              onClick={() => setOthSelect(!othSelect)}
              style={{ borderRadius: "20% 20% 0% 0%" }}
            >
              NO DATA
            </button>
          </div>
          <div className="py-10 px-4 md:px-8 w-full grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5  lg:gap-3 xl:gap-4 ">
            {chunks.length !== 0 &&
              chunks[currentPage].map((item, i) =>
                mapRange === "world" ? (
                  <CountryCard
                    country={item.country}
                    type={item.type}
                    licensed={licenses.findIndex(d=>d.country === getCode(item.country))}
                    key={i}
                  />
                ) : (
                  <CountryCard
                    country={item.country}
                    type={item.type}
                    key={i}
                  />
                )
              )}
          </div>
          {chunks.length === 0 && (
            <p className="w-full text-center italic text-primary font-semibold text-2xl px-10">
              
            </p>
          )}
          {chunks.length > 1 && (
            <div className="flex w-full items-center justify-center gap-16 pt-3">
              <button
                type="button"
                onClick={() => prevBtnClick(0)}
                className="rounded-lg bg-none px-3 py-3 text-xs font-semibold text-[#fff] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FaChevronLeft size="0.6rem" />
              </button>
              <button
                type="button"
                onClick={() => prevBtnClick(1)}
                className="rounded-lg bg-none px-3 py-3 text-xs font-semibold text-[#fff] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FaChevronRight size="0.6rem" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SupportedCountries;

import React from "react";
import ComingSoon from "./ComingSoon";

const Lorem = () => {
  return (
    <div className="bg-black pt-[88px] pb-10 h-screen overflow-scroll scrollbar-hide">
      <ComingSoon title="Lorem Ipsum" />
    </div>
  );
};

export default Lorem;

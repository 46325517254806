import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchTieredKYC = createAsyncThunk(
  "tieredKYC/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/entity-kyc?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

export const fetchNonTieredKYC = createAsyncThunk(
  "nonTieredKYC/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/non_tiered_kyc?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);
export const fetchNoKYC = createAsyncThunk(
  "noKYC/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/no_kyc?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  tieredKYC: [],
  nonTieredKYC: [],
  noKyc:[],
  loading: "idle",
};

const entityKYCslice = createSlice({
  name: "entityKYC",
  initialState,
  reducers: {
    initKYCLoading: (state) => {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTieredKYC.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchTieredKYC.fulfilled, (state, action) => {
      state.tieredKYC = action.payload[0]?.kycTiers || [];
      state.loading = "idle";
    });
    builder.addCase(fetchTieredKYC.rejected, (state, action) => {
      state.loading = "failed";
    });
    builder.addCase(fetchNonTieredKYC.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchNonTieredKYC.fulfilled, (state, action) => {
      state.nonTieredKYC = action.payload[0]?.kyc || [];
      state.loading = "idle";
    });
    builder.addCase(fetchNonTieredKYC.rejected, (state, action) => {
      state.loading = "failed";
    });
    builder.addCase(fetchNoKYC.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchNoKYC.fulfilled, (state, action) => {
      console.log(action.payload[0]);
      state.noKyc = action.payload[0] ?? [];
      state.loading = "idle";
    });
    builder.addCase(fetchNoKYC.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const { initKYCLoading } = entityKYCslice.actions;

export const getTieredKYC = (state) => state.entityKYC.tieredKYC;
export const getKYCLoading = (state) => state.entityKYC.loading;
export const getNonTieredKYC = (state) => state.entityKYC.nonTieredKYC;
export const getNoKYC = (state) => state.entityKYC.noKyc;

export default entityKYCslice.reducer;

export const CheckMark = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="checkbox_on">
        <path
          id="checked"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 13V8C0 5.79086 0.781049 3.90524 2.34315 2.34315C3.90524 0.781049 5.79086 0 8 0H18C20.2091 0 22.0948 0.781049 23.6569 2.34315C25.2189 3.90524 26 5.79086 26 8V18C26 20.2091 25.2189 22.0948 23.6569 23.6569C22.0948 25.2189 20.2091 26 18 26H8C5.79086 26 3.90524 25.2189 2.34315 23.6569C0.781049 22.0948 0 20.2091 0 18V13Z"
          fill="#1AD598"
        />
        <path
          id="check"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1929 17.3993L7.02292 14.2103C6.53143 13.7109 6.53143 12.9097 7.02292 12.4103C7.26007 12.1714 7.58279 12.037 7.91943 12.037C8.25607 12.037 8.5788 12.1714 8.81595 12.4103L11.0889 14.6973L17.1639 8.58429C17.4011 8.34534 17.7238 8.21094 18.0604 8.21094C18.3971 8.21094 18.7198 8.34534 18.9569 8.58429C19.4501 9.08298 19.4501 9.88559 18.9569 10.3843L11.9849 17.4003C11.7481 17.6394 11.4255 17.774 11.0889 17.774C10.7523 17.774 10.4297 17.6394 10.1929 17.4003V17.3993Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const SearchCheckMark = () => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48673 15.8769L24.9036 1.09382C25.2819 0.730103 25.8332 0.588026 26.3498 0.721578C26.8665 0.854419 27.2701 1.243 27.4086 1.73955C27.5471 2.23682 27.3994 2.76677 27.0212 3.13119L10.544 18.9287C9.95791 19.4884 9.0126 19.4884 8.42649 18.9287L0.936546 11.7474C0.558329 11.3837 0.410624 10.8537 0.549097 10.3565C0.687571 9.85992 1.09118 9.47135 1.60787 9.3385C2.12456 9.20495 2.67587 9.34703 3.05408 9.71074L9.48673 15.8769Z"
        fill="#0090FF"
      />
    </svg>
  );
};

export const DeleteMark = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13V8C0 5.79086 0.781049 3.90524 2.34315 2.34315C3.90524 0.781049 5.79086 0 8 0H18C20.2091 0 22.0948 0.781049 23.6569 2.34315C25.2189 3.90524 26 5.79086 26 8V18C26 20.2091 25.2189 22.0948 23.6569 23.6569C22.0948 25.2189 20.2091 26 18 26H8C5.79086 26 3.90524 25.2189 2.34315 23.6569C0.781049 22.0948 0 20.2091 0 18V13Z"
        fill="#D80027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3066 18.5236L12.9822 15.199L9.6578 18.5236C9.04295 19.119 8.06419 19.1114 7.4591 18.506C6.85392 17.9006 6.84607 16.9225 7.4415 16.3072L10.7659 12.9826L7.4415 9.65799C6.84607 9.04267 6.85392 8.06457 7.4591 7.4592C8.06419 6.85382 9.04295 6.84617 9.6578 7.4416L12.9822 10.7662L16.3066 7.4416C16.9215 6.84617 17.9002 6.85382 18.5053 7.4592C19.1105 8.06457 19.1183 9.04267 18.5229 9.65799L15.1985 12.9841L18.5229 16.3087C18.9296 16.7021 19.0923 17.2845 18.9487 17.8317C18.8052 18.3797 18.3777 18.8068 17.8302 18.9506C17.2828 19.0945 16.7005 18.9315 16.3067 18.5251L16.3066 18.5236Z"
        fill="white"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5001 17.5L14.1667 14.1666"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CircleLoading = () => {
  return (
    <div
      role="status"
      className="flex w-full h-full items-center justify-center"
    >
      <svg
        aria-hidden="true"
        className="w-8 h-8 mr-2 text-primary animate-spin dark:text-gray-600 fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      <span className="sr-only text-primary">Loading...</span>
    </div>
  );
};

export const UpDownArrow = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1069_6568)">
        <path
          d="M4.99984 16.0007L0.756836 11.7577L2.17184 10.3438L4.99984 13.1727L7.82784 10.3438L9.24284 11.7577L4.99984 16.0007Z"
          fill="#8D8D8D"
        />
      </g>
      <g clipPath="url(#clip1_1069_6568)">
        <path
          d="M5.00016 -0.000749217L9.24316 4.24225L7.82816 5.65625L5.00016 2.82725L2.17216 5.65625L0.757164 4.24225L5.00016 -0.000749217Z"
          fill="#8D8D8D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1069_6568">
          <rect
            width="10"
            height="6"
            fill="white"
            transform="translate(0 10)"
          />
        </clipPath>
        <clipPath id="clip1_1069_6568">
          <rect
            width="10"
            height="6"
            fill="white"
            transform="translate(10 6) rotate(180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const NoItems = () => {
  return (
    <p className="w-full text-center italic text-primary font-semibold text-2xl px-10 py-4">
    </p>
  );
};

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchSupportedAssets = createAsyncThunk(
  "supportedAssets/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/assets-supported?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const initialState = {
  supportedAssets: [],
  asssetsLoading: "idle",
};

const supportedAssetsslice = createSlice({
  name: "supportedAssets",
  initialState,
  reducers: {
    countriesClear: (state) => {
      state.asssetsLoading = "idle";
      state.supportedAssets = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupportedAssets.pending, (state, action) => {
      state.asssetsLoading = "pending";
    });
    builder.addCase(fetchSupportedAssets.fulfilled, (state, action) => {
      state.asssetsLoading = "idle";
      state.supportedAssets = action.payload;
    });
    builder.addCase(fetchSupportedAssets.rejected, (state, action) => {
      state.asssetsLoading = "failed";
    });
  },
});

export const { countriesClear } = supportedAssetsslice.actions;

export const getSupportedAssets = (state) =>
  state.supportedAssets.supportedAssets;
export const getAsssetsLoading = (state) =>
  state.supportedAssets.asssetsLoading;

export default supportedAssetsslice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import { getCurrentEntity } from "../redux/slices/entities";
import RegulatoryTable from "../components/RegulatoryTable";

const RegulatoryInfo = () => {
  const currentEntity = useSelector(getCurrentEntity);

  return (
    <div className="bg-black pt-16 md:pt-24 pb-10 h-screen overflow-scroll scrollbar-hide">
      {currentEntity && <RegulatoryTable />}
    </div>
  );
};

export default RegulatoryInfo;

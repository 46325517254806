export const icons = {
  "42": "https://assets.coingecko.com/coins/images/1283/thumb/42-coin.png",
  "300": "https://assets.coingecko.com/coins/images/13371/thumb/spartan300-200x200.png",
  "777": "https://assets.coingecko.com/coins/images/9453/thumb/DYpXRpN.png",
  "888": "https://assets.coingecko.com/coins/images/448/thumb/octocoin.png",
  "1337": "https://assets.coingecko.com/coins/images/13348/thumb/logo.png",
  "2044": "https://assets.coingecko.com/coins/images/27111/thumb/2044.jpg",
  "BTC": "https://assets.coingecko.com/coins/images/1/thumb/bitcoin.png",
  "ETH": "https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png",
  "USDT": "https://assets.coingecko.com/coins/images/325/thumb/Tether.png",
  "BNB": "https://assets.coingecko.com/coins/images/22884/thumb/BNB_wh_small.png",
  "USDC": "https://assets.coingecko.com/coins/images/23263/thumb/3408.png",
  "XRP": "https://assets.coingecko.com/coins/images/15458/thumb/ryyrCapt_400x400.jpg",
  "BUSD": "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png",
  "ADA": "https://assets.coingecko.com/coins/images/15455/thumb/ZE8LxNBf_400x400.jpg",
  "DOGE": "https://assets.coingecko.com/coins/images/15768/thumb/dogecoin.png",
  "MATIC": "https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png",
  "OKB": "https://assets.coingecko.com/coins/images/4463/thumb/WeChat_Image_20220118095654.png",
  "SOL": "https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png",
  "STETH": "https://assets.coingecko.com/coins/images/13442/thumb/steth_logo.png",
  "SHIB": "https://assets.coingecko.com/coins/images/22983/thumb/SHIB_wh_small.png",
  "DOT": "https://assets.coingecko.com/coins/images/15457/thumb/-Tj2WF_6_400x400.jpg",
  "LTC": "https://assets.coingecko.com/coins/images/15456/thumb/LrysCc5Q_400x400.jpg",
  "TRX": "https://assets.coingecko.com/coins/images/15468/thumb/NxLfA-LK_400x400.png",
  "AVAX": "https://assets.coingecko.com/coins/images/18674/thumb/avax_logo_1.png",
  "DAI": "https://assets.coingecko.com/coins/images/9956/thumb/4943.png",
  "UNI": "https://assets.coingecko.com/coins/images/22969/thumb/UNI_wh_small.png",
  "ATOM": "https://assets.coingecko.com/coins/images/1481/thumb/cosmos_hub.png",
  "WBTC": "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png",
  "TON": "missing_thumb.png",
  "LINK": "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png",
  "LEO": "https://assets.coingecko.com/coins/images/24976/thumb/leonidasfinancepic.jpg",
  "ETC": "https://assets.coingecko.com/coins/images/453/thumb/ethereum-classic-logo.png",
  "XMR": "https://assets.coingecko.com/coins/images/69/thumb/monero_logo.png",
  "BCH": "https://assets.coingecko.com/coins/images/15774/thumb/bitcoin-cash-circle.png",
  "APT": "https://assets.coingecko.com/coins/images/26276/thumb/ApidaeTokenLogo.png",
  "XLM": "https://assets.coingecko.com/coins/images/100/thumb/Stellar_symbol_black_RGB.png",
  "HBAR": "https://assets.coingecko.com/coins/images/3688/thumb/hbar.png",
  "LDO": "https://assets.coingecko.com/coins/images/22995/thumb/LDO_wh_small.png",
  "QNT": "https://assets.coingecko.com/coins/images/3370/thumb/5ZOu7brX_400x400.jpg",
  "FIL": "https://assets.coingecko.com/coins/images/15776/thumb/filecoin.png",
  "APE": "https://assets.coingecko.com/coins/images/20762/thumb/uXhjvO7.png",
  "NEAR": "https://assets.coingecko.com/coins/images/10365/thumb/near_icon.png",
  "CRO": "https://assets.coingecko.com/coins/images/7310/thumb/cro_token_logo.png",
  "ALGO": "https://assets.coingecko.com/coins/images/4380/thumb/download.png",
  "VET": "https://assets.coingecko.com/coins/images/1167/thumb/VeChain-Logo-768x725.png",
  "ICP": "https://assets.coingecko.com/coins/images/14495/thumb/Internet_Computer_logo.png",
  "GRT": "https://assets.coingecko.com/coins/images/27648/thumb/GRT_200.png",
  "FTM": "https://assets.coingecko.com/coins/images/16111/thumb/gold.png",
  "SAND": "https://assets.coingecko.com/coins/images/22984/thumb/SAND_wh_small.png",
  "MANA": "https://assets.coingecko.com/coins/images/23050/thumb/MANA_wh_small.png",
  "AAVE": "https://assets.coingecko.com/coins/images/12645/thumb/AAVE.png",
  "EOS": "https://assets.coingecko.com/coins/images/15771/thumb/eos-eos-logo.png",
  "AXS": "https://assets.coingecko.com/coins/images/13029/thumb/axie_infinity_logo.png",
  "EGLD": "https://assets.coingecko.com/coins/images/12335/thumb/egld-token-logo.png",
  "FLOW": "https://assets.coingecko.com/coins/images/24630/thumb/Logo_solo_flower_en_rosado.png",
  "THETA": "https://assets.coingecko.com/coins/images/2538/thumb/theta-token-logo.png",
  "FRAX": "https://assets.coingecko.com/coins/images/13422/thumb/ethCanonicalFRAX.png",
  "LUNC": "https://assets.coingecko.com/coins/images/22951/thumb/LUNA_wh_small.png",
  "XTZ": "https://assets.coingecko.com/coins/images/976/thumb/Tezos-logo.png",
  "TUSD": "https://assets.coingecko.com/coins/images/3449/thumb/tusd.png",
  "MINA": "https://assets.coingecko.com/coins/images/15628/thumb/JM4_vQ34_400x400.png",
  "IMX": "https://assets.coingecko.com/coins/images/17233/thumb/immutableX-symbol-BLK-RGB.png",
  "RPL": "https://assets.coingecko.com/coins/images/2090/thumb/rocket_pool_%28RPL%29.png",
  "HT": "https://assets.coingecko.com/coins/images/2822/thumb/huobi-token-logo.png",
  "CRV": "https://assets.coingecko.com/coins/images/12124/thumb/Curve.png",
  "BSV": "https://assets.coingecko.com/coins/images/6799/thumb/BSV.png",
  "KCS": "https://assets.coingecko.com/coins/images/1047/thumb/sa9z79.png",
  "USDP": "https://assets.coingecko.com/coins/images/13234/thumb/USDP.png",
  "DASH": "https://assets.coingecko.com/coins/images/19/thumb/dash-logo.png",
  "SNX": "https://assets.coingecko.com/coins/images/3406/thumb/SNX.png",
  "FXS": "https://assets.coingecko.com/coins/images/13423/thumb/ethCanonicalFXS.png",
  "USDD": "https://assets.coingecko.com/coins/images/25380/thumb/UUSD.jpg",
  "CHZ": "https://assets.coingecko.com/coins/images/8834/thumb/CHZ_Token_updated.png",
  "BTT": "https://assets.coingecko.com/coins/images/22457/thumb/btt_logo.png",
  "BIT": "https://assets.coingecko.com/coins/images/25282/thumb/BT-logo.png",
  "MKR": "https://assets.coingecko.com/coins/images/1364/thumb/Mark_Maker.png",
  "CAKE": "https://assets.coingecko.com/coins/images/12632/thumb/pancakeswap-cake-logo_%281%29.png",
  "XEC": "https://assets.coingecko.com/coins/images/16646/thumb/Logo_final-22.png",
  "BABYDOGE": "https://assets.coingecko.com/coins/images/16923/thumb/BABYDOGE.png",
  "MIOTA": "https://assets.coingecko.com/coins/images/692/thumb/IOTA_Swirl.png",
  "KLAY": "https://assets.coingecko.com/coins/images/9672/thumb/klaytn.png",
  "GT": "https://assets.coingecko.com/coins/images/8183/thumb/gt.png",
  "GMX": "https://assets.coingecko.com/coins/images/17630/thumb/gamex.PNG",
  "NEO": "https://assets.coingecko.com/coins/images/480/thumb/NEO_512_512.png",
  "CUSDC": "https://assets.coingecko.com/coins/images/9442/thumb/Compound_USDC.png",
  "TWT": "https://assets.coingecko.com/coins/images/28755/thumb/23251.png",
  "WBT": "https://assets.coingecko.com/coins/images/27121/thumb/download.png",
  "BGB": "https://assets.coingecko.com/coins/images/11610/thumb/photo_2022-01-24_14-08-03.jpg",
  "AR": "https://assets.coingecko.com/coins/images/4343/thumb/oRt6SiEN_400x400.jpg",
  "GUSD": "https://assets.coingecko.com/coins/images/5992/thumb/gemini-dollar-gusd.png",
  "CDAI": "https://assets.coingecko.com/coins/images/9281/thumb/cDAI.png",
  "ZEC": "https://assets.coingecko.com/coins/images/486/thumb/circle-zcash-color.png",
  "AGIX": "https://assets.coingecko.com/coins/images/2138/thumb/singularitynet.png",
  "EDGT": "https://assets.coingecko.com/coins/images/14409/thumb/edgecoin_bank_logo-4_%281%29.png",
  "OSMO": "https://assets.coingecko.com/coins/images/16724/thumb/osmo.png",
  "RNDR": "https://assets.coingecko.com/coins/images/11636/thumb/rndr.png",
  "OP": "https://assets.coingecko.com/coins/images/25244/thumb/Optimism.png",
  "RUNE": "https://assets.coingecko.com/coins/images/13677/thumb/IMG_20210123_132049_458.png",
  "LRC": "https://assets.coingecko.com/coins/images/25925/thumb/lorologo.png",
  "TKX": "https://assets.coingecko.com/coins/images/4984/thumb/TKX_-_Logo_-_RGB-15.png",
  "XRD": "https://assets.coingecko.com/coins/images/8187/thumb/bAY1Smt.png",
  "ZIL": "https://assets.coingecko.com/coins/images/2687/thumb/Zilliqa-logo.png",
  "OKT": "https://assets.coingecko.com/coins/images/13708/thumb/WeChat_Image_20220118095654.png",
  "PAXG": "https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG",
  "FLR": "https://assets.coingecko.com/coins/images/28624/thumb/FLR-icon200x200.png",
  "CVX": "https://assets.coingecko.com/coins/images/15585/thumb/convex.png",
  "FET": "https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg",
  "XAUT": "https://assets.coingecko.com/coins/images/10481/thumb/Tether_Gold.png",
  "BAT": "https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png",
  "ENJ": "https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png",
  "CETH": "https://assets.coingecko.com/coins/images/10643/thumb/ceth2.JPG",
  "STX": "https://assets.coingecko.com/coins/images/26689/thumb/Colour_Logo_PNG.png",
  "BNX": "https://assets.coingecko.com/coins/images/14862/thumb/Artboard_1_copy0.png",
  "1INCH": "https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png",
  "XCN": "https://assets.coingecko.com/coins/images/24210/thumb/onyxlogo.jpg",
  "NEXO": "https://assets.coingecko.com/coins/images/3695/thumb/nexo.png",
  "ROSE": "https://assets.coingecko.com/coins/images/22104/thumb/rose.PNG",
  "LUNA": "https://assets.coingecko.com/coins/images/25767/thumb/01_Luna_color.png",
  "ETHW": "https://assets.coingecko.com/coins/images/26997/thumb/logo-clear.png",
  "CSPR": "https://assets.coingecko.com/coins/images/15279/thumb/casper.PNG",
  "DYDX": "https://assets.coingecko.com/coins/images/23039/thumb/DYDX_wh_small.png",
  "BTSE": "https://assets.coingecko.com/coins/images/10807/thumb/BTSE_logo_Square.jpeg",
  "ASTR": "https://assets.coingecko.com/coins/images/22617/thumb/astr.png",
  "HOT": "https://assets.coingecko.com/coins/images/25878/thumb/HOT.png",
  "ENS": "https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg",
  "DFI": "https://assets.coingecko.com/coins/images/16817/thumb/dfi.png",
  "CELO": "https://assets.coingecko.com/coins/images/11090/thumb/InjXBNx9_400x400.jpg",
  "XDC": "https://assets.coingecko.com/coins/images/2912/thumb/xdc-icon.png",
  "KAVA": "https://assets.coingecko.com/coins/images/9761/thumb/kava.png",
  "RVN": "https://assets.coingecko.com/coins/images/3412/thumb/ravencoin.png",
  "WOO": "https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg",
  "BONE": "https://assets.coingecko.com/coins/images/24560/thumb/MIBYsiqF_400x400.jpg",
  "HNT": "https://assets.coingecko.com/coins/images/9871/thumb/hHy2h_mf_400x400.jpg",
  "MAGIC": "https://assets.coingecko.com/coins/images/25556/thumb/magicofgold-200x200.png",
  "DCR": "https://assets.coingecko.com/coins/images/329/thumb/decred.png",
  "COMP": "https://assets.coingecko.com/coins/images/3570/thumb/cropped-compoundcoin.png",
  "XEM": "https://assets.coingecko.com/coins/images/242/thumb/NEM_WC_Logo_200px.png",
  "AZERO": "https://assets.coingecko.com/coins/images/17212/thumb/gtmuTVa.png",
  "GALA": "https://assets.coingecko.com/coins/images/12493/thumb/GALA-COINGECKO.png",
  "RETH": "https://assets.coingecko.com/coins/images/24842/thumb/reth.png",
  "TFUEL": "https://assets.coingecko.com/coins/images/8029/thumb/1_0YusgngOrriVg4ZYx4wOFQ.png",
  "WEMIX": "https://assets.coingecko.com/coins/images/12998/thumb/wemixcoin_color_200.png",
  "KSM": "https://assets.coingecko.com/coins/images/9568/thumb/m4zRhP5e_400x400.jpg",
  "ONE": "https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png",
  "OHM": "https://assets.coingecko.com/coins/images/21496/thumb/OHM.jpg",
  "USTC": "https://assets.coingecko.com/coins/images/15462/thumb/ust.png",
  "FLOKI": "https://assets.coingecko.com/coins/images/19333/thumb/wg8-8vHI_400x400.jpg",
  "BTG": "https://assets.coingecko.com/coins/images/1043/thumb/bitcoin-gold-logo.png",
  "IOTX": "https://assets.coingecko.com/coins/images/15978/thumb/iotex-logo.png",
  "BAL": "https://assets.coingecko.com/coins/images/11683/thumb/Balancer.png",
  "GNO": "https://assets.coingecko.com/coins/images/662/thumb/logo_square_simple_300px.png",
  "QTUM": "https://assets.coingecko.com/coins/images/684/thumb/Qtum_Logo_blue_CG.png",
  "SAFEMOON": "https://assets.coingecko.com/coins/images/14362/thumb/174x174-white.png",
  "NXM": "https://assets.coingecko.com/coins/images/11810/thumb/NXMmain.png",
  "INJ": "https://assets.coingecko.com/coins/images/12882/thumb/Secondary_Symbol.png",
  "AUDIO": "https://assets.coingecko.com/coins/images/22936/thumb/AUDIO_wh_small.png",
  "SYN": "https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png",
  "CVXCRV": "https://assets.coingecko.com/coins/images/15586/thumb/convex-crv.png",
  "XCH": "https://assets.coingecko.com/coins/images/15174/thumb/zV5K5y9f_400x400.png",
  "LN": "https://assets.coingecko.com/coins/images/6450/thumb/linklogo.png",
  "BLUR": "https://assets.coingecko.com/coins/images/28453/thumb/blur.png",
  "GMT": "https://assets.coingecko.com/coins/images/15662/thumb/gmt.png",
  "AMP": "https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png",
  "GFARM2": "https://assets.coingecko.com/coins/images/13703/thumb/gfarm_v2.png",
  "GLMR": "https://assets.coingecko.com/coins/images/22459/thumb/glmr.png",
  "SUSHI": "https://assets.coingecko.com/coins/images/12271/thumb/512x512_Logo_no_chop.png",
  "MASK": "https://assets.coingecko.com/coins/images/17046/thumb/Hashie.png",
  "OMI": "https://assets.coingecko.com/coins/images/4428/thumb/ECOMI.png",
  "GLM": "https://assets.coingecko.com/coins/images/542/thumb/Golem_Submark_Positive_RGB.png",
  "LPT": "https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png",
  "WAVES": "https://assets.coingecko.com/coins/images/24388/thumb/playgorund_waves.PNG",
  "JST": "https://assets.coingecko.com/coins/images/11095/thumb/JUST.jpg",
  "KDA": "https://assets.coingecko.com/coins/images/3693/thumb/djLWD6mR_400x400.jpg",
  "ANKR": "https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png",
  "YFI": "https://assets.coingecko.com/coins/images/11849/thumb/yearn-finance-yfi.png",
  "BAND": "https://assets.coingecko.com/coins/images/9545/thumb/Band_token_blue_violet_token.png",
  "ELG": "https://assets.coingecko.com/coins/images/13566/thumb/escoin-200.png",
  "SSV": "https://assets.coingecko.com/coins/images/19155/thumb/ssv.png",
  "LUSD": "https://assets.coingecko.com/coins/images/22541/thumb/5EGOQZSG_400x400.jpg",
  "GNS": "https://assets.coingecko.com/coins/images/19737/thumb/logo.png",
  "CEL": "https://assets.coingecko.com/coins/images/3263/thumb/CEL_logo.png",
  "OMG": "https://assets.coingecko.com/coins/images/776/thumb/OMG_Network.jpg",
  "EURT": "https://assets.coingecko.com/coins/images/17385/thumb/Tether_full_logo_dm.png",
  "CHSB": "https://assets.coingecko.com/coins/images/2117/thumb/YJUrRy7r_400x400.png",
  "CDT": "https://assets.coingecko.com/coins/images/20370/thumb/token-200x200_%281%29.png",
  "FLUX": "https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png",
  "SKL": "https://assets.coingecko.com/coins/images/13245/thumb/SKALE_token_300x300.png",
  "ELON": "https://assets.coingecko.com/coins/images/23041/thumb/ELON_wh_small.png",
  "ALI": "https://assets.coingecko.com/coins/images/17750/thumb/Logo-200x200_%286%29.png",
  "ZRX": "https://assets.coingecko.com/coins/images/863/thumb/0x.png",
  "OCEAN": "https://assets.coingecko.com/coins/images/3687/thumb/ocean-protocol-logo.jpg",
  "SC": "https://assets.coingecko.com/coins/images/27338/thumb/IMG_20220917_135639_644.png",
  "HBTC": "https://assets.coingecko.com/coins/images/12407/thumb/Unknown-5.png",
  "ICX": "https://assets.coingecko.com/coins/images/1060/thumb/icon-icx-logo.png",
  "ONT": "https://assets.coingecko.com/coins/images/18559/thumb/ont.png",
  "DAO": "https://assets.coingecko.com/coins/images/21439/thumb/0xca0235058985fcC1839E9e37c10900a73C126708.png",
  "NU": "https://assets.coingecko.com/coins/images/3318/thumb/photo1198982838879365035.jpg",
  "IOST": "https://assets.coingecko.com/coins/images/2523/thumb/IOST.png",
  "RSR": "https://assets.coingecko.com/coins/images/8365/thumb/rsr.png",
  "SFP": "https://assets.coingecko.com/coins/images/28826/thumb/SFP.png",
  "SXP": "https://assets.coingecko.com/coins/images/9368/thumb/swipe.png",
  "BRISE": "https://assets.coingecko.com/coins/images/17388/thumb/bitgert.png",
  "ZEN": "https://assets.coingecko.com/coins/images/691/thumb/horizen.png",
  "EWT": "https://assets.coingecko.com/coins/images/24407/thumb/28382.jpeg",
  "MULTI": "https://assets.coingecko.com/coins/images/18063/thumb/multigame.PNG",
  "EVMOS": "https://assets.coingecko.com/coins/images/24023/thumb/evmos.png",
  "CANTO": "https://assets.coingecko.com/coins/images/26959/thumb/canto-network.png",
  "BORA": "https://assets.coingecko.com/coins/images/7646/thumb/mqFw8hxm_400x400.jpeg",
  "WAXP": "https://assets.coingecko.com/coins/images/1372/thumb/WAX_Coin_Tickers_P_512px.png",
  "ALUSD": "https://assets.coingecko.com/coins/images/14114/thumb/Alchemix_USD.png",
  "EVER": "https://assets.coingecko.com/coins/images/16943/thumb/8GcpNCM.png",
  "POLY": "https://assets.coingecko.com/coins/images/28236/thumb/photo_2022-11-09_12-53-56.jpg",
  "BICO": "https://assets.coingecko.com/coins/images/21061/thumb/biconomy_logo.jpg",
  "TEL": "https://assets.coingecko.com/coins/images/1899/thumb/tel.png",
  "ILV": "https://assets.coingecko.com/coins/images/14468/thumb/ILV.JPG",
  "TRAC": "https://assets.coingecko.com/coins/images/1877/thumb/TRAC.jpg",
  "LYXE": "https://assets.coingecko.com/coins/images/11423/thumb/1_QAHTciwVhD7SqVmfRW70Pw.png",
  "BDX": "https://assets.coingecko.com/coins/images/5111/thumb/Beldex.png",
  "HIVE": "https://assets.coingecko.com/coins/images/10840/thumb/logo_transparent_4x.png",
  "KUB": "https://assets.coingecko.com/coins/images/15760/thumb/KUB.png",
  "DGB": "https://assets.coingecko.com/coins/images/63/thumb/digibyte.png",
  "LSK": "https://assets.coingecko.com/coins/images/385/thumb/Lisk_Symbol_-_Blue.png",
  "METIS": "https://assets.coingecko.com/coins/images/15595/thumb/metis.jpeg",
  "BLD": "https://assets.coingecko.com/coins/images/24487/thumb/agoric_bld_logo.png",
  "LOOKS": "https://assets.coingecko.com/coins/images/22173/thumb/circle-black-256.png",
  "RLC": "https://assets.coingecko.com/coins/images/646/thumb/pL1VuXm.png",
  "CORE": "https://assets.coingecko.com/coins/images/12635/thumb/cvault.finance_logo.png",
  "NEST": "https://assets.coingecko.com/coins/images/11284/thumb/52954052.png",
  "SCRT": "https://assets.coingecko.com/coins/images/11871/thumb/Secret.png",
  "SYS": "https://assets.coingecko.com/coins/images/119/thumb/Syscoin.png",
  "FRXETH": "https://assets.coingecko.com/coins/images/28284/thumb/JjqQ9ROz_400x400.jpeg",
  "RBN": "https://assets.coingecko.com/coins/images/18864/thumb/1vZaRdJ__400x400.png",
  "SFM": "https://assets.coingecko.com/coins/images/21863/thumb/photo_2021-12-22_14.43.36.jpeg",
  "BTC.B": "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png",
  "CUSDT": "https://assets.coingecko.com/coins/images/11621/thumb/cUSDT.png",
  "PEOPLE": "https://assets.coingecko.com/coins/images/23009/thumb/PEOPLE_wh_small.png",
  "VGX": "https://assets.coingecko.com/coins/images/794/thumb/Voyager-vgx.png",
  "ECOIN": "https://assets.coingecko.com/coins/images/15901/thumb/ecoin.png",
  "TSUKA": "https://assets.coingecko.com/coins/images/26405/thumb/Tsuka_200x200.png",
  "DAG": "https://assets.coingecko.com/coins/images/4645/thumb/Constellation_Network_Logo.png",
  "BLID": "https://assets.coingecko.com/coins/images/25548/thumb/BLID_token_logo_200x200.png",
  "UMA": "https://assets.coingecko.com/coins/images/10951/thumb/UMA.png",
  "RON": "https://assets.coingecko.com/coins/images/17128/thumb/ron.PNG",
  "NFT": "https://assets.coingecko.com/coins/images/16986/thumb/nft.PNG",
  "API3": "https://assets.coingecko.com/coins/images/13256/thumb/api3.jpg",
  "EURS": "https://assets.coingecko.com/coins/images/5164/thumb/EURS_300x300.png",
  "VVS": "https://assets.coingecko.com/coins/images/20210/thumb/8glAYOTM_400x400.jpg",
  "POLYX": "https://assets.coingecko.com/coins/images/23496/thumb/POLYX_logo.png",
  "KNC": "https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg",
  "CET": "https://assets.coingecko.com/coins/images/4817/thumb/coinex-token.png",
  "HFT": "https://assets.coingecko.com/coins/images/20213/thumb/hft_coingecko.png",
  "STG": "https://assets.coingecko.com/coins/images/24413/thumb/STG_LOGO.png",
  "SRM": "https://assets.coingecko.com/coins/images/11970/thumb/serum-logo.png",
  "CKB": "https://assets.coingecko.com/coins/images/9566/thumb/Nervos_White.png",
  "NMR": "https://assets.coingecko.com/coins/images/752/thumb/numeraire.png",
  "ULT": "https://assets.coingecko.com/coins/images/8383/thumb/final_logo_photoshop.png",
  "AXL": "https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png",
  "PLA": "https://assets.coingecko.com/coins/images/4567/thumb/pla.png",
  "PUNDIX": "https://assets.coingecko.com/coins/images/14571/thumb/vDyefsXq_400x400.jpg",
  "CTC": "https://assets.coingecko.com/coins/images/17731/thumb/final-comunity-logo.png",
  "10SET": "https://assets.coingecko.com/coins/images/14629/thumb/PNG_Tenset_Sign-back_RGB.png",
  "CELR": "https://assets.coingecko.com/coins/images/4379/thumb/Celr.png",
  "CFX": "https://assets.coingecko.com/coins/images/13079/thumb/3vuYMbjN.png",
  "SGB": "https://assets.coingecko.com/coins/images/18663/thumb/SGB_512x512.png",
  "SLP": "https://assets.coingecko.com/coins/images/10366/thumb/SLP.png",
  "FLEX": "https://assets.coingecko.com/coins/images/9108/thumb/coinflex_logo.png",
  "FX": "https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg",
  "GFT": "https://assets.coingecko.com/coins/images/25811/thumb/logo_%281%29.png",
  "SAITAMA": "https://assets.coingecko.com/coins/images/16353/thumb/SOIKDUWf_400x400.jpeg",
  "TRIBE": "https://assets.coingecko.com/coins/images/14575/thumb/tribe.PNG",
  "MC": "https://assets.coingecko.com/coins/images/27200/thumb/mc.jpeg",
  "ALICE": "https://assets.coingecko.com/coins/images/14375/thumb/alice_logo.jpg",
  "XNO": "https://assets.coingecko.com/coins/images/13830/thumb/XNO_logo_200x200.png",
  "CHR": "https://assets.coingecko.com/coins/images/5000/thumb/Chromia.png",
  "MIM": "https://assets.coingecko.com/coins/images/26170/thumb/mim.png",
  "ANT": "https://assets.coingecko.com/coins/images/681/thumb/JelZ58cv_400x400.png",
  "SETH2": "https://assets.coingecko.com/coins/images/16569/thumb/emerald256.png",
  "VOLT": "https://assets.coingecko.com/coins/images/22419/thumb/VoltToken.png",
  "GTC": "https://assets.coingecko.com/coins/images/26853/thumb/20220803_000244.png",
  "ERG": "https://assets.coingecko.com/coins/images/2484/thumb/Ergo.png",
  "KAS": "https://assets.coingecko.com/coins/images/25751/thumb/kaspa-icon-exchanges.png",
  "SNT": "https://assets.coingecko.com/coins/images/21083/thumb/256SNT.png",
  "POKT": "https://assets.coingecko.com/coins/images/22506/thumb/33689860.png",
  "COTI": "https://assets.coingecko.com/coins/images/2962/thumb/Coti.png",
  "ELF": "https://assets.coingecko.com/coins/images/27571/thumb/logo-02.png",
  "DUSK": "https://assets.coingecko.com/coins/images/5217/thumb/D_ticker_purple_on_circle_%282%29.png",
  "AKT": "https://assets.coingecko.com/coins/images/12785/thumb/akash-logo.png",
  "CTSI": "https://assets.coingecko.com/coins/images/11038/thumb/cartesi.png",
  "VRA": "https://assets.coingecko.com/coins/images/14025/thumb/VRA.jpg",
  "KEEP": "https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg",
  "SAVAX": "https://assets.coingecko.com/coins/images/23657/thumb/savax_blue.png",
  "C98": "https://assets.coingecko.com/coins/images/17117/thumb/logo.png",
  "DEXE": "https://assets.coingecko.com/coins/images/12713/thumb/logo_%2814%29.png",
  "MSOL": "https://assets.coingecko.com/coins/images/17752/thumb/mSOL.png",
  "GAL": "https://assets.coingecko.com/coins/images/11585/thumb/Galatasaray-10.png",
  "ORBS": "https://assets.coingecko.com/coins/images/4630/thumb/Orbs.jpg",
  "STEEM": "https://assets.coingecko.com/coins/images/398/thumb/steem.png",
  "NYM": "https://assets.coingecko.com/coins/images/24488/thumb/NYM_Token.png",
  "MVL": "https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png",
  "RARE": "https://assets.coingecko.com/coins/images/28993/thumb/logo.RARe.png",
  "USDX": "https://assets.coingecko.com/coins/images/12377/thumb/rCdP56C.png",
  "MED": "https://assets.coingecko.com/coins/images/1374/thumb/medibloc_basic.png",
  "ARDR": "https://assets.coingecko.com/coins/images/525/thumb/Ardor_Vertical_1.png",
  "ALPHA": "https://assets.coingecko.com/coins/images/18402/thumb/Poly-Alpha-200x200.png",
  "DESO": "https://assets.coingecko.com/coins/images/16310/thumb/deso-logo-refresh-white.png",
  "RAD": "https://assets.coingecko.com/coins/images/22854/thumb/unknown.png",
  "SPELL": "https://assets.coingecko.com/coins/images/15861/thumb/abracadabra-3.png",
  "CEEK": "https://assets.coingecko.com/coins/images/2581/thumb/ceek-smart-vr-token-logo.png",
  "PLTC": "https://assets.coingecko.com/coins/images/7178/thumb/PLTC.png",
  "LCX": "https://assets.coingecko.com/coins/images/9985/thumb/zRPSu_0o_400x400.jpg",
  "MX": "https://assets.coingecko.com/coins/images/19659/thumb/png256x256.png",
  "IQ": "https://assets.coingecko.com/coins/images/5284/thumb/IQ.cash.png",
  "PHA": "https://assets.coingecko.com/coins/images/12451/thumb/phala.png",
  "BFC": "https://assets.coingecko.com/coins/images/10278/thumb/MvzxzkGi_400x400.png",
  "HUM": "https://assets.coingecko.com/coins/images/25116/thumb/6238d9732c84fbda3a99f518_hummus_ssymbol.png",
  "STSOL": "https://assets.coingecko.com/coins/images/18369/thumb/logo_-_2021-09-15T100934.765.png",
  "ANKRETH": "https://assets.coingecko.com/coins/images/13403/thumb/aETHc.png",
  "DKA": "https://assets.coingecko.com/coins/images/11875/thumb/bVD0g0dlmrEOPIkt943KZIBZ086eCshyY0jIQFti4zxYdOlFltU8tKa6uJlcA14HvNjX4bc7dxdMvlpoW5NFMND85oG4aiiCbFRhI6eowDfKEBY3BoSVY0IrBbA9SFGIxh_IYrkNC5uNdG-roZ0_TlGO3098now6Tbzga0p4IDqVk6lnaX3TuRC7pgnAYWZM15RD-uEIHr3O_3OoIIWP-.jpg",
  "WCFG": "https://assets.coingecko.com/coins/images/17106/thumb/WCFG.jpg",
  "CFG": "https://assets.coingecko.com/coins/images/15380/thumb/centrifuge.PNG",
  "PYR": "https://assets.coingecko.com/coins/images/14770/thumb/1617088937196.png",
  "DENT": "https://assets.coingecko.com/coins/images/1152/thumb/gLCEA2G.png",
  "EUL": "https://assets.coingecko.com/coins/images/26149/thumb/YCvKDfl8_400x400.jpeg",
  "MBOX": "https://assets.coingecko.com/coins/images/14751/thumb/mobox.PNG",
  "PROM": "https://assets.coingecko.com/coins/images/8825/thumb/Ticker.png",
  "XVS": "https://assets.coingecko.com/coins/images/12677/thumb/download.jpg",
  "JUNO": "https://assets.coingecko.com/coins/images/19249/thumb/Juno_Logo_%28Coingecko%29.png",
  "WIN": "https://assets.coingecko.com/coins/images/15472/thumb/hDLzwfqu_400x400.jpg",
  "STRAX": "https://assets.coingecko.com/coins/images/531/thumb/stratis.png",
  "CVC": "https://assets.coingecko.com/coins/images/18875/thumb/cvc.PNG",
  "REN": "https://assets.coingecko.com/coins/images/3139/thumb/REN.png",
  "CQT": "https://assets.coingecko.com/coins/images/14168/thumb/covalent-cqt.png",
  "WRX": "https://assets.coingecko.com/coins/images/10547/thumb/WazirX.png",
  "EXRD": "https://assets.coingecko.com/coins/images/13145/thumb/exrd_logo.png",
  "VERI": "https://assets.coingecko.com/coins/images/695/thumb/veritaseum.png",
  "XSUSHI": "https://assets.coingecko.com/coins/images/13725/thumb/xsushi.png",
  "POND": "https://assets.coingecko.com/coins/images/8903/thumb/POND_200x200.png",
  "JOE": "https://assets.coingecko.com/coins/images/17569/thumb/joe_200x200.png",
  "STPT": "https://assets.coingecko.com/coins/images/8713/thumb/STP.png",
  "MRS": "https://assets.coingecko.com/coins/images/21799/thumb/_8Ijk_oO_400x400.jpg",
  "HEZ": "https://assets.coingecko.com/coins/images/12826/thumb/hermez_logo.png",
  "XPRT": "https://assets.coingecko.com/coins/images/14582/thumb/512_Light.png",
  "BMEX": "https://assets.coingecko.com/coins/images/21831/thumb/bitmex-token.jpeg",
  "MDX": "https://assets.coingecko.com/coins/images/13736/thumb/png-cmc.png",
  "REQ": "https://assets.coingecko.com/coins/images/1031/thumb/Request_icon_green.png",
  "POWR": "https://assets.coingecko.com/coins/images/1104/thumb/power-ledger.png",
  "DPX": "https://assets.coingecko.com/coins/images/16652/thumb/DPX_%281%29.png",
  "BOBA": "https://assets.coingecko.com/coins/images/24072/thumb/200_x_200_logo.png",
  "ARRR": "https://assets.coingecko.com/coins/images/6905/thumb/Pirate_Chain.png",
  "REP": "https://assets.coingecko.com/coins/images/309/thumb/REP.png",
  "VTHO": "https://assets.coingecko.com/coins/images/5230/thumb/vethor-token.png",
  "CCD": "https://assets.coingecko.com/coins/images/23547/thumb/Sf_cldmL_400x400.jpg",
  "ACH": "https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png",
  "NRV": "https://assets.coingecko.com/coins/images/14233/thumb/nerve_finance_logo.png",
  "MLK": "https://assets.coingecko.com/coins/images/11978/thumb/2.png",
  "ACA": "https://assets.coingecko.com/coins/images/19127/thumb/aca-icon256x256.png",
  "BSW": "https://assets.coingecko.com/coins/images/16845/thumb/biswap.png",
  "MNW": "https://assets.coingecko.com/coins/images/2379/thumb/MRPH_CoinGecko.png",
  "EMAID": "https://assets.coingecko.com/coins/images/80/thumb/maidsafecoin.png",
  "LQTY": "https://assets.coingecko.com/coins/images/14665/thumb/200-lqty-icon.png",
  "SFRXETH": "https://assets.coingecko.com/coins/images/28285/thumb/JjqQ9ROz_400x400.jpeg",
  "TLM": "https://assets.coingecko.com/coins/images/14676/thumb/kY-C4o7RThfWrDQsLCAG4q4clZhBDDfJQVhWUEKxXAzyQYMj4Jmq1zmFwpRqxhAJFPOa0AsW_PTSshoPuMnXNwq3rU7Imp15QimXTjlXMx0nC088mt1rIwRs75GnLLugWjSllxgzvQ9YrP4tBgclK4_rb17hjnusGj_c0u2fx0AvVokjSNB-v2poTj0xT9BZRCbzRE3-lF1.jpg",
  "XYO": "https://assets.coingecko.com/coins/images/4519/thumb/XYO_Network-logo.png",
  "RACA": "https://assets.coingecko.com/coins/images/17841/thumb/ez44_BSs_400x400.jpg",
  "FUN": "https://assets.coingecko.com/coins/images/761/thumb/funfair.png",
  "FIDU": "https://assets.coingecko.com/coins/images/25944/thumb/GFI-asset-icon.png",
  "QKC": "https://assets.coingecko.com/coins/images/3849/thumb/quarkchain.png",
  "NKN": "https://assets.coingecko.com/coins/images/3375/thumb/nkn.png",
  "OGN": "https://assets.coingecko.com/coins/images/3296/thumb/op.jpg",
  "CTK": "https://assets.coingecko.com/coins/images/2729/thumb/th.jpg",
  "REEF": "https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png",
  "KUJI": "https://assets.coingecko.com/coins/images/20685/thumb/kuji-200x200.png",
  "DOCK": "https://assets.coingecko.com/coins/images/3978/thumb/dock-icon-dark-large.png",
  "SHR": "https://assets.coingecko.com/coins/images/23743/thumb/P8dAWBj.jpeg",
  "PCX": "https://assets.coingecko.com/coins/images/9133/thumb/chainx.png",
  "BNT": "https://assets.coingecko.com/coins/images/736/thumb/bancor-bnt.png",
  "PHB": "https://assets.coingecko.com/coins/images/20052/thumb/Phoenix_logo_blue_bird.png",
  "MXC": "https://assets.coingecko.com/coins/images/4604/thumb/mxc.png",
  "STMX": "https://assets.coingecko.com/coins/images/1369/thumb/StormX.png",
  "UOS": "https://assets.coingecko.com/coins/images/4480/thumb/Ultra.png",
  "WMT": "https://assets.coingecko.com/coins/images/21314/thumb/xn5gni.png",
  "MTL": "https://assets.coingecko.com/coins/images/763/thumb/Metal.png",
  "UQC": "https://assets.coingecko.com/coins/images/1341/thumb/uquid-coin.png",
  "SURE": "https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png",
  "HUNT": "https://assets.coingecko.com/coins/images/27566/thumb/Token_Hunters__CoinMarketCap.png",
  "HI": "https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png",
  "ARK": "https://assets.coingecko.com/coins/images/613/thumb/ark.png",
  "SDAO": "https://assets.coingecko.com/coins/images/26343/thumb/YpyYshd.png",
  "LAT": "https://assets.coingecko.com/coins/images/20628/thumb/liquidify.PNG",
  "SOLO": "https://assets.coingecko.com/coins/images/18349/thumb/solo.png",
  "KISHU": "https://assets.coingecko.com/coins/images/14890/thumb/uVLzCoP.png",
  "FCT": "https://assets.coingecko.com/coins/images/9892/thumb/6mHcLurm_400x400.jpg",
  "XIDO": "https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png",
  "ATA": "https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg",
  "BETA": "https://assets.coingecko.com/coins/images/18746/thumb/Poly-Beta-200-x-200-px.png",
  "MTRG": "https://assets.coingecko.com/coins/images/11848/thumb/meter.png",
  "RDPX": "https://assets.coingecko.com/coins/images/16659/thumb/rDPX_200x200_Coingecko.png",
  "VLX": "https://assets.coingecko.com/coins/images/9651/thumb/logo_blue.png",
  "KILT": "https://assets.coingecko.com/coins/images/20861/thumb/kilt.png",
  "DODO": "https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png",
  "DAWN": "https://assets.coingecko.com/coins/images/11555/thumb/dawn_protocol.png",
  "PLEX": "https://assets.coingecko.com/coins/images/13383/thumb/plex.png",
  "EFI": "https://assets.coingecko.com/coins/images/16558/thumb/efi-200px_%281%29.png",
  "TEMPLE": "https://assets.coingecko.com/coins/images/20040/thumb/LPK15ZOW_400x400.jpg",
  "STORJ": "https://assets.coingecko.com/coins/images/949/thumb/storj.png",
  "AERGO": "https://assets.coingecko.com/coins/images/4490/thumb/aergo.png",
  "RIF": "https://assets.coingecko.com/coins/images/7460/thumb/RIF.png",
  "OXT": "https://assets.coingecko.com/coins/images/3916/thumb/download_%285%29.png",
  "MATICX": "https://assets.coingecko.com/coins/images/25383/thumb/maticx.png",
  "AURA": "https://assets.coingecko.com/coins/images/25768/thumb/LOGO-AURA-WHITE.png",
  "DOME": "https://assets.coingecko.com/coins/images/23267/thumb/Ix-ms0fq_400x400.jpg",
  "MARU": "https://assets.coingecko.com/coins/images/27672/thumb/90e23f86-426d-4a0f-9d66-10da3d58baf5.png",
  "DERO": "https://assets.coingecko.com/coins/images/3522/thumb/dero_logo_png.png",
  "BADGER": "https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg",
  "SUPER": "https://assets.coingecko.com/coins/images/23462/thumb/logo-superciety-200px.png",
  "SUN": "https://assets.coingecko.com/coins/images/24832/thumb/token_V2.png",
  "TLOS": "https://assets.coingecko.com/coins/images/7588/thumb/TLOS_200.png",
  "XTP": "https://assets.coingecko.com/coins/images/10291/thumb/0_3SJYkk_400x400.jpg",
  "BTRFLY": "https://assets.coingecko.com/coins/images/26745/thumb/redacted_v2.jpg",
  "DXP": "https://assets.coingecko.com/coins/images/23106/thumb/dxp.png",
  "BOR": "https://assets.coingecko.com/coins/images/12917/thumb/bor_logo.png",
  "HXRO": "https://assets.coingecko.com/coins/images/7805/thumb/Hxro_Profile_Transparent.png",
  "SSX": "https://assets.coingecko.com/coins/images/28704/thumb/CRYTOLOGO.png",
  "CTXC": "https://assets.coingecko.com/coins/images/3861/thumb/2638.png",
  "META": "https://assets.coingecko.com/coins/images/20466/thumb/META-INU.png",
  "YGG": "https://assets.coingecko.com/coins/images/17358/thumb/le1nzlO6_400x400.jpg",
  "QUACK": "https://assets.coingecko.com/coins/images/28683/thumb/Quack_logo_200x200.png",
  "GODS": "https://assets.coingecko.com/coins/images/17139/thumb/10631.png",
  "RLY": "https://assets.coingecko.com/coins/images/12843/thumb/image.png",
  "MBX": "https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg",
  "STRK": "https://assets.coingecko.com/coins/images/14607/thumb/Jw-36llq_400x400.jpg",
  "KRD": "https://assets.coingecko.com/coins/images/25467/thumb/krd.png",
  "MOVR": "https://assets.coingecko.com/coins/images/17984/thumb/9285.png",
  "MBL": "https://assets.coingecko.com/coins/images/8460/thumb/MBL_Logo.png",
  "FLEXUSD": "https://assets.coingecko.com/coins/images/13323/thumb/flexUSD_2x.png",
  "REV": "https://assets.coingecko.com/coins/images/985/thumb/RChain_Icon_Red.png",
  "LOKA": "https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png",
  "POLIS": "https://assets.coingecko.com/coins/images/2452/thumb/circlePolisLogo.png",
  "COL": "https://assets.coingecko.com/coins/images/26070/thumb/col.png",
  "KWENTA": "https://assets.coingecko.com/coins/images/27409/thumb/kwenta.png",
  "OPUL": "https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG",
  "XVG": "https://assets.coingecko.com/coins/images/203/thumb/verge-symbol-color_logo.png",
  "IXT": "https://assets.coingecko.com/coins/images/814/thumb/ixledger.png",
  "RAY": "https://assets.coingecko.com/coins/images/13928/thumb/PSigc4ie_400x400.jpg",
  "XCM": "https://assets.coingecko.com/coins/images/3125/thumb/XCM_Token_Logo_.png",
  "IDIA": "https://assets.coingecko.com/coins/images/17803/thumb/IDIA.png",
  "COCOS": "https://assets.coingecko.com/coins/images/4932/thumb/_QPpjoUi_400x400.jpg",
  "PREMIA": "https://assets.coingecko.com/coins/images/13962/thumb/apple-touch-icon.png",
  "DOLA": "https://assets.coingecko.com/coins/images/14287/thumb/dola.png",
  "KODA": "https://assets.coingecko.com/coins/images/15577/thumb/koda.png",
  "ETN": "https://assets.coingecko.com/coins/images/1109/thumb/electroneum.png",
  "OUSD": "https://assets.coingecko.com/coins/images/16830/thumb/cUixn42.png",
  "UTK": "https://assets.coingecko.com/coins/images/1824/thumb/300x300_logo.png",
  "SUSD": "https://assets.coingecko.com/coins/images/19754/thumb/SUSD_200.png",
  "SWEAT": "https://assets.coingecko.com/coins/images/25057/thumb/fhD9Xs16_400x400.jpg",
  "UFO": "https://assets.coingecko.com/coins/images/178/thumb/UFO_coin_logo_large_4.png",
  "SX": "https://assets.coingecko.com/coins/images/28335/thumb/X200.png",
  "YFII": "https://assets.coingecko.com/coins/images/11902/thumb/YFII-logo.78631676.png",
  "SFUND": "https://assets.coingecko.com/coins/images/14614/thumb/Favicon_Icon.png",
  "GRS": "https://assets.coingecko.com/coins/images/24810/thumb/18444.png",
  "BAKE": "https://assets.coingecko.com/coins/images/12588/thumb/bakerytoken_logo.jpg",
  "LYRA": "https://assets.coingecko.com/coins/images/7748/thumb/1_zHuFWIdNMu9dD2I3HrV2sw.png",
  "LIT": "https://assets.coingecko.com/coins/images/6580/thumb/Lition_ico.png",
  "RAIL": "https://assets.coingecko.com/coins/images/16840/thumb/railgun.jpeg",
  "XMON": "https://assets.coingecko.com/coins/images/14008/thumb/xmon_logo.png",
  "LEASH": "https://assets.coingecko.com/coins/images/15802/thumb/Leash.png",
  "BTM": "https://assets.coingecko.com/coins/images/1087/thumb/2qNyrhUxEmnGCKi.png",
  "VEGA": "https://assets.coingecko.com/coins/images/28409/thumb/icon_%281%29_%281%29.png",
  "GHST": "https://assets.coingecko.com/coins/images/12467/thumb/ghst_200.png",
  "FOLD": "https://assets.coingecko.com/coins/images/15928/thumb/Manifold.png",
  "GAS": "https://assets.coingecko.com/coins/images/22086/thumb/SjrgK6sf_400x400.jpg",
  "HIFI": "https://assets.coingecko.com/coins/images/17635/thumb/hifi.PNG",
  "MFT": "https://assets.coingecko.com/coins/images/27122/thumb/logo.png",
  "NTX": "https://assets.coingecko.com/coins/images/13976/thumb/ntx-icon.png",
  "FLM": "https://assets.coingecko.com/coins/images/12618/thumb/flamingo_finance_logo.jpg",
  "TT": "https://assets.coingecko.com/coins/images/22770/thumb/39cb52d6-cd27-510e-6c7b-a876a455613a.png",
  "LM": "https://assets.coingecko.com/coins/images/25761/thumb/SVG_16533804486374586M.jpg",
  "QLC": "https://assets.coingecko.com/coins/images/2053/thumb/2321.png",
  "XSGD": "https://assets.coingecko.com/coins/images/12832/thumb/StraitsX_Singapore_Dollar_%28XSGD%29_Token_Logo.png",
  "MEX": "https://assets.coingecko.com/coins/images/20657/thumb/MEX-icon.png",
  "AURORA": "https://assets.coingecko.com/coins/images/15345/thumb/aurora_tokenlogo.png",
  "ASTO": "https://assets.coingecko.com/coins/images/24893/thumb/ASTO.png",
  "POLS": "https://assets.coingecko.com/coins/images/12648/thumb/polkastarter.png",
  "CBAT": "https://assets.coingecko.com/coins/images/10645/thumb/cBAT1.JPG",
  "MIMATIC": "https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png",
  "MUTE": "https://assets.coingecko.com/coins/images/14331/thumb/MUTE.png",
  "STRD": "https://assets.coingecko.com/coins/images/27275/thumb/STRD_800.png",
  "AXN": "https://assets.coingecko.com/coins/images/13110/thumb/AXION.png",
  "AMPL": "https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png",
  "AVINOC": "https://assets.coingecko.com/coins/images/4659/thumb/qX7fh69Q_400x400-1.jpg",
  "AURABAL": "https://assets.coingecko.com/coins/images/26538/thumb/auraBAL.png",
  "CUSD": "https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png",
  "AMA": "https://assets.coingecko.com/coins/images/28229/thumb/10b06d6c-379f-4c34-b3f2-196a770a4512.png",
  "XCAD": "https://assets.coingecko.com/coins/images/15857/thumb/logoWhiteX.jpg",
  "ALPACA": "https://assets.coingecko.com/coins/images/14165/thumb/Logo200.png",
  "ZIG": "https://assets.coingecko.com/coins/images/14796/thumb/zignaly.jpg",
  "IETH": "https://assets.coingecko.com/coins/images/20051/thumb/photo-2021-10-24-17-37-28.jpg",
  "KLV": "https://assets.coingecko.com/coins/images/12240/thumb/hIonpevN_400x400.jpg",
  "LOOMOLD": "https://assets.coingecko.com/coins/images/3387/thumb/1_K76UVoLq-FOL7l-_Fag-Qw_2x.png",
  "ADS": "https://assets.coingecko.com/coins/images/868/thumb/rnO9DyJ.png",
  "WILD": "https://assets.coingecko.com/coins/images/22196/thumb/logo-moneda200.png",
  "HAY": "https://assets.coingecko.com/coins/images/21063/thumb/Contagion_Crew_5-2.jpg",
  "SNM": "https://assets.coingecko.com/coins/images/861/thumb/sonm.png",
  "MTD": "https://assets.coingecko.com/coins/images/26893/thumb/MTD_Token_%282%29.png",
  "CERE": "https://assets.coingecko.com/coins/images/20008/thumb/cere.png",
  "MPL": "https://assets.coingecko.com/coins/images/22833/thumb/9uTc91QZ_400x400.jpg",
  "WNXM": "https://assets.coingecko.com/coins/images/11890/thumb/wNXM.png",
  "ATLAS": "https://assets.coingecko.com/coins/images/27668/thumb/Asset_50_4x.png",
  "TRU": "https://assets.coingecko.com/coins/images/15053/thumb/Truebit.png",
  "GBEX": "https://assets.coingecko.com/coins/images/24859/thumb/gbex.png",
  "HERO": "https://assets.coingecko.com/coins/images/26567/thumb/bug-CC-200.png",
  "CRE": "https://assets.coingecko.com/coins/images/25061/thumb/logo_200x200.png",
  "BWO": "https://assets.coingecko.com/coins/images/25799/thumb/battleworld_logo_200x200.png",
  "GAFI": "https://assets.coingecko.com/coins/images/18292/thumb/gamefi.PNG",
  "RDNT": "https://assets.coingecko.com/coins/images/26536/thumb/Radiant-Logo-200x200.png",
  "DEI": "https://assets.coingecko.com/coins/images/18779/thumb/DEI-logo-round-white-3.png",
  "PERP": "https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png",
  "ARPA": "https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg",
  "FEI": "https://assets.coingecko.com/coins/images/14570/thumb/ZqsF51Re_400x400.png",
  "WAN": "https://assets.coingecko.com/coins/images/3482/thumb/wanchain-logo.png",
  "TRB": "https://assets.coingecko.com/coins/images/9644/thumb/Blk_icon_current.png",
  "VRSC": "https://assets.coingecko.com/coins/images/6769/thumb/Screenshot_33.png",
  "QRDO": "https://assets.coingecko.com/coins/images/17541/thumb/qrdo.png",
  "LTO": "https://assets.coingecko.com/coins/images/6068/thumb/lto.png",
  "UMEE": "https://assets.coingecko.com/coins/images/20635/thumb/Umee_Logo.png",
  "STARL": "https://assets.coingecko.com/coins/images/16824/thumb/ZxJbRWJ.png",
  "BOND": "https://assets.coingecko.com/coins/images/28159/thumb/logo_%283%29.png",
  "ASD": "https://assets.coingecko.com/coins/images/5003/thumb/bitmax.png",
  "VELA": "https://assets.coingecko.com/coins/images/28739/thumb/VELA_logo_-_no_background.png",
  "CNC": "https://assets.coingecko.com/coins/images/21857/thumb/_LcCRbOp_400x400.png",
  "WOZX": "https://assets.coingecko.com/coins/images/13310/thumb/rZ6Oe3dm_400x400.jpg",
  "SOFI": "https://assets.coingecko.com/coins/images/14686/thumb/sofi.png",
  "FEG": "https://assets.coingecko.com/coins/images/13878/thumb/F-Icon-FEG-200x200-288dpi-01_%281%29.png",
  "AUCTION": "https://assets.coingecko.com/coins/images/13860/thumb/1_KtgpRIJzuwfHe0Rl0avP_g.jpeg",
  "CULT": "https://assets.coingecko.com/coins/images/23331/thumb/quxZPrbC_400x400.jpg",
  "TIME": "https://assets.coingecko.com/coins/images/18854/thumb/timeleap.PNG",
  "KMD": "https://assets.coingecko.com/coins/images/611/thumb/KMD_Mark_Color.png",
  "HC": "https://assets.coingecko.com/coins/images/20610/thumb/hc200x200.png",
  "BIFI": "https://assets.coingecko.com/coins/images/13671/thumb/ysYIu7Q.png",
  "IDEX": "https://assets.coingecko.com/coins/images/2565/thumb/logomark-purple-286x286.png",
  "CAW": "https://assets.coingecko.com/coins/images/25130/thumb/x-Logo-color-10x.png",
  "REI": "https://assets.coingecko.com/coins/images/25240/thumb/c51_j0aB_400x400.jpg",
  "VAI": "https://assets.coingecko.com/coins/images/13861/thumb/VAI_logo.png",
  "SB": "https://assets.coingecko.com/coins/images/28211/thumb/200_%281%29.png",
  "AGLD": "https://assets.coingecko.com/coins/images/18125/thumb/lpgblc4h_400x400.jpg",
  "KP3R": "https://assets.coingecko.com/coins/images/12966/thumb/kp3r_logo.jpg",
  "FORTH": "https://assets.coingecko.com/coins/images/14917/thumb/photo_2021-04-22_00.00.03.jpeg",
  "DIVI": "https://assets.coingecko.com/coins/images/1273/thumb/red_logomark.png",
  "ERN": "https://assets.coingecko.com/coins/images/14238/thumb/LOGO_HIGH_QUALITY.png",
  "ALPINE": "https://assets.coingecko.com/coins/images/23717/thumb/18112.png",
  "VR": "https://assets.coingecko.com/coins/images/21178/thumb/vr.png",
  "CCS": "https://assets.coingecko.com/coins/images/19072/thumb/200.png",
  "SHI": "https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png",
  "GRAIL": "https://assets.coingecko.com/coins/images/25217/thumb/GRAIL.png",
  "UNFI": "https://assets.coingecko.com/coins/images/13152/thumb/logo-2.png",
  "LEVER": "https://assets.coingecko.com/coins/images/26205/thumb/WI72SpBl_400x400.jpeg",
  "HEGIC": "https://assets.coingecko.com/coins/images/12454/thumb/new.png",
  "ALCX": "https://assets.coingecko.com/coins/images/14113/thumb/Alchemix.png",
  "PSTAKE": "https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png",
  "CRTS": "https://assets.coingecko.com/coins/images/17322/thumb/cratos.png",
  "VOXEL": "https://assets.coingecko.com/coins/images/21260/thumb/voxies.png",
  "DIA": "https://assets.coingecko.com/coins/images/28961/thumb/866C1AC4-3F58-4E4A-9EAC-FAA5DC7B04FA.png",
  "ITAMCUBE": "https://assets.coingecko.com/coins/images/22396/thumb/CoinGecko_Logo_%281%29.jpg",
  "TOMO": "https://assets.coingecko.com/coins/images/3416/thumb/Asset_59_2x.png",
  "SETH": "https://assets.coingecko.com/coins/images/2101/thumb/sether.png",
  "CRU": "https://assets.coingecko.com/coins/images/24919/thumb/cru.png",
  "PUPPETS": "https://assets.coingecko.com/coins/images/25335/thumb/Puppets_Arts_200.png",
  "U": "https://assets.coingecko.com/coins/images/26691/thumb/Unidef_Logo-_Square-_200_200.png",
  "SRLTY": "https://assets.coingecko.com/coins/images/27289/thumb/s_mksCdB_400x400.jpeg",
  "LINA": "https://assets.coingecko.com/coins/images/5412/thumb/3083.png",
  "CWEB": "https://assets.coingecko.com/coins/images/21607/thumb/Logo_200x200.png",
  "BEL": "https://assets.coingecko.com/coins/images/17411/thumb/Belrium.png",
  "GQ": "https://assets.coingecko.com/coins/images/24059/thumb/logo_gg.png",
  "ROUTE": "https://assets.coingecko.com/coins/images/13709/thumb/route_token_200x200-19.png",
  "VITA": "https://assets.coingecko.com/coins/images/19190/thumb/icon_vita.png",
  "GXC": "https://assets.coingecko.com/coins/images/1089/thumb/26296223.png",
  "ROOT": "https://assets.coingecko.com/coins/images/28366/thumb/root-200x200.png",
  "VIDT": "https://assets.coingecko.com/coins/images/27552/thumb/VIDTDAO_logo.png",
  "MLN": "https://assets.coingecko.com/coins/images/605/thumb/Enzyme_Icon_Secondary.png",
  "EUROC": "https://assets.coingecko.com/coins/images/26045/thumb/euro-coin.png",
  "B2M": "https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png",
  "AIOZ": "https://assets.coingecko.com/coins/images/14631/thumb/aioz-logo-200.png",
  "FINE": "https://assets.coingecko.com/coins/images/14925/thumb/profilepic_square.png",
  "AVA": "https://assets.coingecko.com/coins/images/28368/thumb/17ddcf3cae174463a018a9566c4f3424.png",
  "SBD": "https://assets.coingecko.com/coins/images/652/thumb/steem_dollars.png",
  "BEAN": "https://assets.coingecko.com/coins/images/18447/thumb/bean-logo-coingecko.png",
  "QI": "https://assets.coingecko.com/coins/images/14007/thumb/logocoingecko.png",
  "BLZ": "https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png",
  "DFG": "https://assets.coingecko.com/coins/images/24672/thumb/dfg.jpg",
  "BZZ": "https://assets.coingecko.com/coins/images/16509/thumb/Circle_Orange_onWhite.png",
  "FIRO": "https://assets.coingecko.com/coins/images/20128/thumb/firocoingecko.png",
  "SDN": "https://assets.coingecko.com/coins/images/24817/thumb/sdn.png",
  "RAMP": "https://assets.coingecko.com/coins/images/12837/thumb/RAMP-Logo-v2-1000pxsq.png",
  "SAITO": "https://assets.coingecko.com/coins/images/14750/thumb/SAITO.png",
  "DNT": "https://assets.coingecko.com/coins/images/2073/thumb/2aSL-Tvl_400x400.jpeg",
  "SWISE": "https://assets.coingecko.com/coins/images/15044/thumb/stakewise200.png",
  "CHESS": "https://assets.coingecko.com/coins/images/12945/thumb/200x200_%281%29_cheescoin_0.32_.png",
  "X2Y2": "https://assets.coingecko.com/coins/images/23633/thumb/logo-60b81ff87b40b11739105acf5ad1e075.png",
  "COVAL": "https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png",
  "FITFI": "https://assets.coingecko.com/coins/images/25015/thumb/801485424e1f49bc8d0facff9287eb9b_photo.png",
  "CHNG": "https://assets.coingecko.com/coins/images/16526/thumb/symbol-only-on-dark.png",
  "ORN": "https://assets.coingecko.com/coins/images/11841/thumb/orion_logo.png",
  "KAI": "https://assets.coingecko.com/coins/images/7942/thumb/kai.png",
  "CPH": "https://assets.coingecko.com/coins/images/7332/thumb/cph.PNG",
  "FIS": "https://assets.coingecko.com/coins/images/12423/thumb/stafi_logo.jpg",
  "CLV": "https://assets.coingecko.com/coins/images/12888/thumb/clover_logo.png",
  "AE": "https://assets.coingecko.com/coins/images/1091/thumb/aeternity.png",
  "JASMY": "https://assets.coingecko.com/coins/images/13876/thumb/JASMY200x200.jpg",
  "PLU": "https://assets.coingecko.com/coins/images/1241/thumb/pluton.png",
  "BAR": "https://assets.coingecko.com/coins/images/21429/thumb/heiro.png",
  "MDT": "https://assets.coingecko.com/coins/images/28346/thumb/MDT_LOGO%28200X200%29.png",
  "AGEUR": "https://assets.coingecko.com/coins/images/19479/thumb/agEUR.png",
  "NEBL": "https://assets.coingecko.com/coins/images/894/thumb/Mark_Color_4x.png",
  "MCB": "https://assets.coingecko.com/coins/images/11796/thumb/mux.jpg",
  "VXV": "https://assets.coingecko.com/coins/images/2063/thumb/vectorspace-ai-logo.jpeg",
  "PRQ": "https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png",
  "FPIS": "https://assets.coingecko.com/coins/images/24944/thumb/fpis.png",
  "ZCX": "https://assets.coingecko.com/coins/images/14830/thumb/7xjpHaG.png",
  "EGC": "https://assets.coingecko.com/coins/images/20987/thumb/RSGoYMG.png",
  "BONK": "https://assets.coingecko.com/coins/images/28600/thumb/bonk.jpg",
  "BTS": "https://assets.coingecko.com/coins/images/95/thumb/bts.png",
  "MELD": "https://assets.coingecko.com/coins/images/21297/thumb/tDs6XoHL_400x400.png",
  "AQT": "https://assets.coingecko.com/coins/images/12872/thumb/alpha_quark_logo.png",
  "PLT": "https://assets.coingecko.com/coins/images/22153/thumb/262aoVTp_400x400.jpg",
  "STARS": "https://assets.coingecko.com/coins/images/17533/thumb/DhtU6Z.jpg",
  "MOC": "https://assets.coingecko.com/coins/images/2374/thumb/moc.png",
  "DATA": "https://assets.coingecko.com/coins/images/18500/thumb/data_32.png",
  "THOR": "https://assets.coingecko.com/coins/images/21322/thumb/ThorFi-Mark-Storm2x.png",
  "ETHM": "https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png",
  "JEWEL": "https://assets.coingecko.com/coins/images/18570/thumb/fAisLIV.png",
  "KOGE": "https://assets.coingecko.com/coins/images/13827/thumb/bnb48.png",
  "UXP": "https://assets.coingecko.com/coins/images/20319/thumb/UXP.jpg",
  "SPHERE": "https://assets.coingecko.com/coins/images/23452/thumb/IMG-20220204-114926-962.jpg",
  "APX": "https://assets.coingecko.com/coins/images/21783/thumb/logo_%282%29.png",
  "RSV": "https://assets.coingecko.com/coins/images/10952/thumb/rsv.png",
  "RFR": "https://assets.coingecko.com/coins/images/2102/thumb/refereum.png",
  "QOM": "https://assets.coingecko.com/coins/images/24430/thumb/l1KzMcL.png",
  "CXO": "https://assets.coingecko.com/coins/images/2580/thumb/cargox.png",
  "THALES": "https://assets.coingecko.com/coins/images/18388/thumb/CLVZJN_C_400x400.png",
  "PRO": "https://assets.coingecko.com/coins/images/25031/thumb/8aa91569950f5011ca8a9.png",
  "INSTAR": "https://assets.coingecko.com/coins/images/3504/thumb/2558.png",
  "ORAI": "https://assets.coingecko.com/coins/images/12931/thumb/orai.png",
  "NPXS": "https://assets.coingecko.com/coins/images/2170/thumb/pundi-x.png",
  "FORT": "https://assets.coingecko.com/coins/images/20626/thumb/dANE5hB.png",
  "NOIA": "https://assets.coingecko.com/coins/images/3269/thumb/Component_1.png",
  "NULS": "https://assets.coingecko.com/coins/images/1053/thumb/Nuls.png",
  "BCD": "https://assets.coingecko.com/coins/images/1254/thumb/bitcoin-diamond.png",
  "BCT": "https://assets.coingecko.com/coins/images/12929/thumb/bitcointrust.512.x.512.png",
  "CUNI": "https://assets.coingecko.com/coins/images/12696/thumb/compound-uni.png",
  "TALK": "https://assets.coingecko.com/coins/images/20979/thumb/Tzu4wc-k_400x400.jpg",
  "FRA": "https://assets.coingecko.com/coins/images/27780/thumb/France_Fan_Token.jpg",
  "BITCI": "https://assets.coingecko.com/coins/images/13816/thumb/bitcichain.png",
  "GAMI": "https://assets.coingecko.com/coins/images/24482/thumb/IMG_20220319_170534_629.jpg",
  "FER": "https://assets.coingecko.com/coins/images/26111/thumb/FER_Token.png",
  "MDAO": "https://assets.coingecko.com/coins/images/23242/thumb/ezgif.com-gif-maker_%281%29.jpg",
  "USDK": "https://assets.coingecko.com/coins/images/8824/thumb/usdk.png",
  "GOG": "https://assets.coingecko.com/coins/images/17362/thumb/V2QDNoLg_400x400.jpg",
  "IRIS": "https://assets.coingecko.com/coins/images/18361/thumb/hermes.PNG",
  "RIDE": "https://assets.coingecko.com/coins/images/3095/thumb/ridemycar.png",
  "UBT": "https://assets.coingecko.com/coins/images/2707/thumb/UnibrightLogo_colorful_500x500_preview.png",
  "PIVX": "https://assets.coingecko.com/coins/images/548/thumb/PIVX-Shield.png",
  "HYDRA": "https://assets.coingecko.com/coins/images/13631/thumb/photo_2020-11-27_18-25-06.jpg",
  "H2O": "https://assets.coingecko.com/coins/images/23818/thumb/h2o.png",
  "XDATA": "https://assets.coingecko.com/coins/images/1115/thumb/streamr.png",
  "BOSON": "https://assets.coingecko.com/coins/images/14710/thumb/boson_logo.png",
  "CITY": "https://assets.coingecko.com/coins/images/24679/thumb/qeBsYVE0_400x400.png",
  "XKI": "https://assets.coingecko.com/coins/images/15694/thumb/xki-color.png",
  "DG": "https://assets.coingecko.com/coins/images/13267/thumb/%28Old%29_DG.png",
  "QUICK": "https://assets.coingecko.com/coins/images/25393/thumb/quickswap.jpg",
  "TPT": "https://assets.coingecko.com/coins/images/28362/thumb/TPT.jpg",
  "RFOX": "https://assets.coingecko.com/coins/images/12956/thumb/rfox.png",
  "ONIT": "https://assets.coingecko.com/coins/images/12252/thumb/d4suDHSzEZjDXc0uxFGPZXfDFp0L1tfoEusBx03zB1bvMLLbxa7Yq5naXAOutkirIlLXacFRRebX_hF2Ez69_fIPUfr2LuN0Z_UrXTLYds4aT95C6eHGXsZnezMHKAaOmjLpKto0xPTiYkXExvEvXrqL7O_J1wecb4eJsgwCoVprMLpm89BdKO9IyMmpNsfbW7JJFMiHgL8vq9fPhaeEi.jpg",
  "ASTRAFER": "https://assets.coingecko.com/coins/images/26246/thumb/ATSRA_Token.png",
  "ICHI": "https://assets.coingecko.com/coins/images/26004/thumb/legacy.png",
  "ADX": "https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png",
  "FIDA": "https://assets.coingecko.com/coins/images/13395/thumb/bonfida.png",
  "BZRX": "https://assets.coingecko.com/coins/images/11811/thumb/bzrx.png",
  "SNL": "https://assets.coingecko.com/coins/images/7762/thumb/745ba052-73b7-4735-a0bf-e75474def055.png",
  "MARS": "https://assets.coingecko.com/coins/images/24480/thumb/mars.png",
  "DC": "https://assets.coingecko.com/coins/images/24783/thumb/dc.png",
  "FIO": "https://assets.coingecko.com/coins/images/11821/thumb/fio200x200.png",
  "REGEN": "https://assets.coingecko.com/coins/images/16733/thumb/REGEN.png",
  "MIR": "https://assets.coingecko.com/coins/images/13295/thumb/mirror_logo_transparent.png",
  "SANTOS": "https://assets.coingecko.com/coins/images/21132/thumb/santos.png",
  "SOUL": "https://assets.coingecko.com/coins/images/21919/thumb/HELLHOUND-LOGO-LARGE.png",
  "GFI": "https://assets.coingecko.com/coins/images/18421/thumb/w2FyVpg.jpg",
  "PSG": "https://assets.coingecko.com/coins/images/11620/thumb/psg.png",
  "AHT": "https://assets.coingecko.com/coins/images/28086/thumb/7FbZmTSKMsSqtlfe8yH1n3QgdBc3RBBt0B6dsdprVBs.png",
  "AUTO": "https://assets.coingecko.com/coins/images/3503/thumb/2559.png",
  "WING": "https://assets.coingecko.com/coins/images/12477/thumb/s_wing_icon.png",
  "DPI": "https://assets.coingecko.com/coins/images/12465/thumb/defi_pulse_index_set.png",
  "JPEG": "https://assets.coingecko.com/coins/images/24025/thumb/et_43CNi_400x400.jpg",
  "FWT": "https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png",
  "OATH": "https://assets.coingecko.com/coins/images/24075/thumb/oath.png",
  "HUSD": "https://assets.coingecko.com/coins/images/9567/thumb/HUSD.jpg",
  "HARD": "https://assets.coingecko.com/coins/images/13008/thumb/HARD_coin_256.png",
  "SD": "https://assets.coingecko.com/coins/images/28215/thumb/sd_logo.png",
  "ZPAY": "https://assets.coingecko.com/coins/images/16813/thumb/zpay.png",
  "SAI": "https://assets.coingecko.com/coins/images/1442/thumb/dai.png",
  "FARM": "https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png",
  "TKO": "https://assets.coingecko.com/coins/images/14577/thumb/tko-logo.png",
  "EPS": "https://assets.coingecko.com/coins/images/12295/thumb/SBqy8NapjZHtkIb4gkQrAXfxFI-j5G4mvfTxCVb2b1HuNGVv0m_6JTq4Ika8BOSHgbWBm0zKSH1dSohftNOaMI00Nb9hW_q5wnMoMi78BIbqTmf810zAHJPpXlFuMhZJ1Q1pUIjyKhb476mFwn5fys_EIF_26GhEE_n9TgAuyMI71hnYDCguzD-ZA7SsMoq.jpg",
  "CUDOS": "https://assets.coingecko.com/coins/images/13651/thumb/CudosIconTransparent.png",
  "OM": "https://assets.coingecko.com/coins/images/12151/thumb/OM_Token.png",
  "TCR": "https://assets.coingecko.com/coins/images/7836/thumb/tcr.jpg",
  "ION": "https://assets.coingecko.com/coins/images/465/thumb/ion.png",
  "ELA": "https://assets.coingecko.com/coins/images/2780/thumb/Elastos.png",
  "MLT": "https://assets.coingecko.com/coins/images/15659/thumb/milc_200x200.png",
  "GNY": "https://assets.coingecko.com/coins/images/5300/thumb/GNY_LOGO_NEW_TRANS.png",
  "AXEL": "https://assets.coingecko.com/coins/images/9765/thumb/axel-logo-250px.png",
  "CEUR": "https://assets.coingecko.com/coins/images/27870/thumb/5ETdHxeE_400x400.jpeg",
  "KEY": "https://assets.coingecko.com/coins/images/14812/thumb/2e7a347a975a232693d4467bd4d6546.png",
  "RVP": "https://assets.coingecko.com/coins/images/14772/thumb/vsd0Wnc2_400x400.png",
  "MATH": "https://assets.coingecko.com/coins/images/11335/thumb/2020-05-19-token-200.png",
  "COT": "https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png",
  "MOON": "https://assets.coingecko.com/coins/images/18187/thumb/download_%2833%29.png",
  "MNGO": "https://assets.coingecko.com/coins/images/14773/thumb/token-mango.png",
  "CHO": "https://assets.coingecko.com/coins/images/25935/thumb/cho_%282%29.png",
  "MV": "https://assets.coingecko.com/coins/images/23143/thumb/geno.png",
  "VITE": "https://assets.coingecko.com/coins/images/4513/thumb/Vite.png",
  "TOKE": "https://assets.coingecko.com/coins/images/17969/thumb/toke.PNG",
  "RARI": "https://assets.coingecko.com/coins/images/11845/thumb/Rari.png",
  "BURGER": "https://assets.coingecko.com/coins/images/12563/thumb/burger.png",
  "DEP": "https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png",
  "VBG": "https://assets.coingecko.com/coins/images/27549/thumb/DfQRpDCm_400x400.jpeg",
  "YVE-CRVDAO": "https://assets.coingecko.com/coins/images/13065/thumb/yearn_veCRV.png",
  "FSN": "https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png",
  "PRE": "https://assets.coingecko.com/coins/images/1299/thumb/presearch.png",
  "YLD": "https://assets.coingecko.com/coins/images/13365/thumb/Google_Play_Store_Icon.png",
  "XPR": "https://assets.coingecko.com/coins/images/10941/thumb/Proton-Icon.png",
  "LEND": "https://assets.coingecko.com/coins/images/28251/thumb/LEND-Green.png",
  "COS": "https://assets.coingecko.com/coins/images/8379/thumb/4036.png",
  "HTR": "https://assets.coingecko.com/coins/images/27959/thumb/KxnPzSud_400x400.jpeg",
  "UFT": "https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png",
  "RMRK": "https://assets.coingecko.com/coins/images/18656/thumb/download_%281%29_%281%29.png",
  "LAZIO": "https://assets.coingecko.com/coins/images/19263/thumb/B4Lla6V6_400x400.png",
  "XFT": "https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png",
  "DERC": "https://assets.coingecko.com/coins/images/17438/thumb/DERC_logo_coingecko.png",
  "AMB": "https://assets.coingecko.com/coins/images/25205/thumb/Apple_AMB.jpeg",
  "ITHEUM": "https://assets.coingecko.com/coins/images/25361/thumb/itheum.png",
  "AFC": "https://assets.coingecko.com/coins/images/17661/thumb/HEKLrDI.png",
  "TVK": "https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png",
  "RBLS": "https://assets.coingecko.com/coins/images/24094/thumb/logo-footer-356.88b37f33.png",
  "DBC": "https://assets.coingecko.com/coins/images/22803/thumb/Ia0yVef.png",
  "DEXT": "https://assets.coingecko.com/coins/images/11603/thumb/dext.png",
  "SNFTS": "https://assets.coingecko.com/coins/images/27131/thumb/seedify.jpg",
  "KLIMA": "https://assets.coingecko.com/coins/images/19169/thumb/klima-logo.png",
  "ORCA": "https://assets.coingecko.com/coins/images/18858/thumb/ic_orca.png",
  "HDX": "https://assets.coingecko.com/coins/images/25177/thumb/HDXdarkblueInv.png",
  "UPP": "https://assets.coingecko.com/coins/images/3369/thumb/Sentinel_Protocol.jpg",
  "BOO": "https://assets.coingecko.com/coins/images/28814/thumb/logo-BOO.png",
  "CUBE": "https://assets.coingecko.com/coins/images/25943/thumb/toBf_SEy_400x400.png",
  "BSCPAD": "https://assets.coingecko.com/coins/images/14135/thumb/bscpad_logo.jpg",
  "BERRY": "https://assets.coingecko.com/coins/images/14609/thumb/Berry.jpg",
  "GF": "https://assets.coingecko.com/coins/images/20933/thumb/guildfi-logo-200.png",
  "DACXI": "https://assets.coingecko.com/coins/images/4466/thumb/dacxi.png",
  "GZONE": "https://assets.coingecko.com/coins/images/18697/thumb/Th98fbg__400x400.jpg",
  "AION": "https://assets.coingecko.com/coins/images/1023/thumb/Aion_Currency_Symbol_Transparent_PNG.png",
  "FRONT": "https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png",
  "MAP": "https://assets.coingecko.com/coins/images/25476/thumb/CoinMarketCapPNG_%281%29.png",
  "ECOX": "https://assets.coingecko.com/coins/images/27962/thumb/_ECOx.png",
  "$ADS": "https://assets.coingecko.com/coins/images/17979/thumb/Alkimi_Alternatives_PNG.png",
  "TONIC": "https://assets.coingecko.com/coins/images/21982/thumb/TONIC_logo.png",
  "KNCL": "https://assets.coingecko.com/coins/images/947/thumb/logo-kncl.png",
  "DGD": "https://assets.coingecko.com/coins/images/397/thumb/dgd.png",
  "TAMA": "https://assets.coingecko.com/coins/images/15702/thumb/Q0bhsxl.png",
  "PTS": "https://assets.coingecko.com/coins/images/26154/thumb/pts.png",
  "UNIDX": "https://assets.coingecko.com/coins/images/13178/thumb/unidx.png",
  "SILO": "https://assets.coingecko.com/coins/images/11228/thumb/seed2need256.png",
  "DF": "https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg",
  "GDDY": "https://assets.coingecko.com/coins/images/24960/thumb/gddy.png",
  "OOKI": "https://assets.coingecko.com/coins/images/21719/thumb/Ooki_Token.png",
  "AST": "https://assets.coingecko.com/coins/images/24590/thumb/ast.png",
  "COPI": "https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg",
  "KUNCI": "https://assets.coingecko.com/coins/images/23723/thumb/xZX34B26_400x400.jpg",
  "WLKN": "https://assets.coingecko.com/coins/images/25566/thumb/wlkn.jpg",
  "CGS": "https://assets.coingecko.com/coins/images/19402/thumb/logo.png",
  "CATE": "https://assets.coingecko.com/coins/images/14529/thumb/CG.jpg",
  "GEL": "https://assets.coingecko.com/coins/images/15026/thumb/Gelato_Icon_Logo_1024x1024.png",
  "CPOOL": "https://assets.coingecko.com/coins/images/19252/thumb/photo_2022-08-31_12.45.02.jpeg",
  "BOB": "https://assets.coingecko.com/coins/images/3550/thumb/PNG_BOB_Token_Large.png",
  "BMX": "https://assets.coingecko.com/coins/images/5236/thumb/bitmart-token.png",
  "PNK": "https://assets.coingecko.com/coins/images/3833/thumb/kleros.png",
  "NAKA": "https://assets.coingecko.com/coins/images/19073/thumb/flCKDeh6_400x400.jpg",
  "PTU": "https://assets.coingecko.com/coins/images/20281/thumb/image_1_8dd79a68aa.png",
  "ZKS": "https://assets.coingecko.com/coins/images/13585/thumb/zkspace_logo.png",
  "PINKSALE": "https://assets.coingecko.com/coins/images/17377/thumb/4cC4Fgs.png",
  "HOPR": "https://assets.coingecko.com/coins/images/14061/thumb/Shared_HOPR_logo_512px.png",
  "HNS": "https://assets.coingecko.com/coins/images/10562/thumb/circle-handshakeLogo.png",
  "ATPAY": "https://assets.coingecko.com/coins/images/27254/thumb/atpay.png",
  "GET": "https://assets.coingecko.com/coins/images/27210/thumb/LOGO_%281%29.jpeg",
  "CAP": "https://assets.coingecko.com/coins/images/11775/thumb/cap.png",
  "XAI": "https://assets.coingecko.com/coins/images/28254/thumb/Xai_logo.png",
  "GYEN": "https://assets.coingecko.com/coins/images/14191/thumb/icon_gyen_200_200.png",
  "YOSHI": "https://assets.coingecko.com/coins/images/19355/thumb/YOSHI_200.png",
  "FUSE": "https://assets.coingecko.com/coins/images/10347/thumb/fuse.png",
  "DREP": "https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg",
  "SDT": "https://assets.coingecko.com/coins/images/19124/thumb/stabledoc.PNG",
  "DXD": "https://assets.coingecko.com/coins/images/11148/thumb/dxdao.png",
  "TREEB": "https://assets.coingecko.com/coins/images/17710/thumb/logo-twitter.png",
  "ZB": "https://assets.coingecko.com/coins/images/4773/thumb/zb-token-logo.png",
  "TOR": "https://assets.coingecko.com/coins/images/27037/thumb/torches.jpg",
  "VIB": "https://assets.coingecko.com/coins/images/983/thumb/Viberate.png",
  "SOLVE": "https://assets.coingecko.com/coins/images/1768/thumb/Solve.Token_logo_200_200_wiyhout_BG.png",
  "TORN": "https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg",
  "USD+": "https://assets.coingecko.com/coins/images/25757/thumb/USD__logo.png",
  "ANC": "https://assets.coingecko.com/coins/images/14420/thumb/anchor_protocol_logo.jpg",
  "ICETH": "https://assets.coingecko.com/coins/images/24483/thumb/icETH-token-logo.png",
  "XAVA": "https://assets.coingecko.com/coins/images/15466/thumb/avalaunch.png",
  "TETU": "https://assets.coingecko.com/coins/images/17882/thumb/gradient_icon_926.png",
  "UNCX": "https://assets.coingecko.com/coins/images/12871/thumb/unicrypt_logo.png",
  "AMO": "https://assets.coingecko.com/coins/images/4009/thumb/amo-logomark_black.png",
  "KAR": "https://assets.coingecko.com/coins/images/17172/thumb/karura.jpeg",
  "PROS": "https://assets.coingecko.com/coins/images/13668/thumb/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg",
  "NIM": "https://assets.coingecko.com/coins/images/5119/thumb/nimiq.png",
  "ADCO": "https://assets.coingecko.com/coins/images/14344/thumb/advertisecoin200x200.png",
  "HAI": "https://assets.coingecko.com/coins/images/11081/thumb/logo_haken_vector.png",
  "SWAP": "https://assets.coingecko.com/coins/images/18961/thumb/nYrDOWha_400x400.png",
  "PSP": "https://assets.coingecko.com/coins/images/20403/thumb/ep7GqM19_400x400.jpg",
  "LSS": "https://assets.coingecko.com/coins/images/15917/thumb/Group_57.png",
  "RSS3": "https://assets.coingecko.com/coins/images/23575/thumb/RSS3.png",
  "TABOO": "https://assets.coingecko.com/coins/images/6111/thumb/taboo.PNG",
  "MIX": "https://assets.coingecko.com/coins/images/26992/thumb/%E1%84%86%E1%85%B5%E1%86%A8%E1%84%89%E1%85%B3%E1%84%90%E1%85%A9%E1%84%8F%E1%85%B3%E1%86%AB.png",
  "BEZOGE": "https://assets.coingecko.com/coins/images/15639/thumb/token-logo.png",
  "CAPS": "https://assets.coingecko.com/coins/images/15921/thumb/e55393fa-7b4d-40f5-9f36-9a8a6bdcb570.png",
  "NCT": "https://assets.coingecko.com/coins/images/24484/thumb/NCT-removebg-preview.png",
  "TRIAS": "https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg",
  "CROID": "https://assets.coingecko.com/coins/images/28498/thumb/Cronos_ID_Logo.png",
  "ZKP": "https://assets.coingecko.com/coins/images/18611/thumb/panther.jpg",
  "SAMO": "https://assets.coingecko.com/coins/images/15051/thumb/IXeEj5e.png",
  "BIGSB": "https://assets.coingecko.com/coins/images/18235/thumb/bigsb-token-logo.png",
  "FUTURE": "https://assets.coingecko.com/coins/images/23806/thumb/3ptM03y.png",
  "INST": "https://assets.coingecko.com/coins/images/14688/thumb/30hFM0-n_400x400.jpg",
  "XTN": "https://assets.coingecko.com/coins/images/10117/thumb/USDN_Logo.jpg",
  "FOX": "https://assets.coingecko.com/coins/images/25627/thumb/ouIXdRFJ_400x400.png",
  "DEGO": "https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png",
  "VELO": "https://assets.coingecko.com/coins/images/12538/thumb/Logo_200x_200.png",
  "VRO": "https://assets.coingecko.com/coins/images/11112/thumb/wsBaVF.png",
  "OCT": "https://assets.coingecko.com/coins/images/826/thumb/oraclechain.png",
  "XDG": "https://assets.coingecko.com/coins/images/21176/thumb/xDG_Logo.png",
  "ANGLE": "https://assets.coingecko.com/coins/images/19060/thumb/ANGLE_Token-light.png",
  "GEAR": "https://assets.coingecko.com/coins/images/26651/thumb/logo_%282%29.png",
  "ZMT": "https://assets.coingecko.com/coins/images/13866/thumb/ZMT_Token.png",
  "YCC": "https://assets.coingecko.com/coins/images/5328/thumb/G5gCEo-J_400x400.jpg",
  "YUSD": "https://assets.coingecko.com/coins/images/25024/thumb/1_oJ0F2Zf6CuAhLP0zOboo6w.png",
  "DEUS": "https://assets.coingecko.com/coins/images/18778/thumb/DEUS-logo-200x200.png",
  "LON": "https://assets.coingecko.com/coins/images/13454/thumb/lon_logo.png",
  "PDT": "https://assets.coingecko.com/coins/images/22642/thumb/PDT_LOGO_200.jpg",
  "XDAG": "https://assets.coingecko.com/coins/images/5823/thumb/4nVh5M8u_400x400.jpg",
  "SIX": "https://assets.coingecko.com/coins/images/3769/thumb/Webp.net-resizeimage_%2834%29.png",
  "QMALL": "https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg",
  "ABT": "https://assets.coingecko.com/coins/images/27620/thumb/photo_2022-12-24_10-26-51.jpg",
  "SWTH": "https://assets.coingecko.com/coins/images/3645/thumb/SWTH_Symbol_Origin.png",
  "INV": "https://assets.coingecko.com/coins/images/14205/thumb/inverse_finance.jpg",
  "HAPI": "https://assets.coingecko.com/coins/images/14298/thumb/R9i2HjAL_400x400.jpg",
  "ETH2X-FLI": "https://assets.coingecko.com/coins/images/14392/thumb/ETH2x-FLI_%281%29.png",
  "ATOLO": "https://assets.coingecko.com/coins/images/21028/thumb/atolo.png",
  "XETA": "https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png",
  "JONES": "https://assets.coingecko.com/coins/images/23290/thumb/3c8c2ed8-afb3-4b67-9937-5493acd88b50.jpg",
  "STOS": "https://assets.coingecko.com/coins/images/16213/thumb/token_512x512.png",
  "GENI": "https://assets.coingecko.com/coins/images/23697/thumb/GemUni_Logo_%28200x200%29.png",
  "GST-SOL": "https://assets.coingecko.com/coins/images/21841/thumb/gst.png",
  "IMPT": "https://assets.coingecko.com/coins/images/28486/thumb/IMPT_logo.png",
  "ES": "https://assets.coingecko.com/coins/images/6031/thumb/era_swap.PNG",
  "WISE": "https://assets.coingecko.com/coins/images/13552/thumb/Wise.jpg.jpg",
  "BEPRO": "https://assets.coingecko.com/coins/images/10251/thumb/logo.png",
  "ALEPH": "https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png",
  "PSI": "https://assets.coingecko.com/coins/images/19080/thumb/aK3V5I56_400x400.jpg",
  "NUM": "https://assets.coingecko.com/coins/images/20495/thumb/NP_Social_media_profile_pic.png",
  "GEIST": "https://assets.coingecko.com/coins/images/18832/thumb/GEIST-token.png",
  "GBYTE": "https://assets.coingecko.com/coins/images/561/thumb/byteball.png",
  "CVP": "https://assets.coingecko.com/coins/images/23573/thumb/CVPCMC.png",
  "DAFI": "https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png",
  "BLUSD": "https://assets.coingecko.com/coins/images/27681/thumb/bLUSD_logo.png",
  "SHDW": "https://assets.coingecko.com/coins/images/2488/thumb/shadow-token.png",
  "NSBT": "https://assets.coingecko.com/coins/images/13268/thumb/83005f7ab1.png",
  "APEX": "https://assets.coingecko.com/coins/images/16864/thumb/cg.png",
  "PENDLE": "https://assets.coingecko.com/coins/images/15069/thumb/Pendle_Logo_Normal-03.png",
  "BTC2": "https://assets.coingecko.com/coins/images/5925/thumb/bitcoin2_logo256.png",
  "BANANA": "https://assets.coingecko.com/coins/images/13419/thumb/bananacoin.png",
  "SPS": "https://assets.coingecko.com/coins/images/17332/thumb/splinter.PNG",
  "SAMA": "https://assets.coingecko.com/coins/images/28308/thumb/Small.png",
  "PDEX": "https://assets.coingecko.com/coins/images/14833/thumb/dIze5Ztl_400x400.jpg",
  "$RAINI": "https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png",
  "SWASH": "https://assets.coingecko.com/coins/images/18774/thumb/swash.png",
  "STRP": "https://assets.coingecko.com/coins/images/18327/thumb/Logo-Strips-200-x-200px---without-words.png",
  "ORB": "https://assets.coingecko.com/coins/images/261/thumb/orbitcoin.png",
  "DLC": "https://assets.coingecko.com/coins/images/26339/thumb/soul_prism.png",
  "JADE": "https://assets.coingecko.com/coins/images/28526/thumb/jade_coin_v1-bigboi-no-whitespace_%281%29.png",
  "KAT": "https://assets.coingecko.com/coins/images/281/thumb/logo1.jpg",
  "GARI": "https://assets.coingecko.com/coins/images/22615/thumb/gari.png",
  "ACM": "https://assets.coingecko.com/coins/images/4763/thumb/actinium-token-logo.png",
  "SQUIDGROW": "https://assets.coingecko.com/coins/images/26573/thumb/Squidgrow-Logo.png",
  "AURY": "https://assets.coingecko.com/coins/images/19324/thumb/Ico_Blanc.png",
  "BEND": "https://assets.coingecko.com/coins/images/25616/thumb/bend200x200.png",
  "ROOK": "https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg",
  "ALD": "https://assets.coingecko.com/coins/images/18277/thumb/78200839.png",
  "OOE": "https://assets.coingecko.com/coins/images/17014/thumb/ooe_log.png",
  "SHIK": "https://assets.coingecko.com/coins/images/28560/thumb/shikoku_inu02.png",
  "PKF": "https://assets.coingecko.com/coins/images/14422/thumb/LOGO_FB.png",
  "ALU": "https://assets.coingecko.com/coins/images/15127/thumb/logo-dark.png",
  "LUNR": "https://assets.coingecko.com/coins/images/19256/thumb/lunr.png",
  "NAP": "https://assets.coingecko.com/coins/images/21709/thumb/T8eK64cLp9w3O3kDvVNRmtS2GKA4LGfK78Agubhg.png",
  "VINU": "https://assets.coingecko.com/coins/images/20698/thumb/vinu.png",
  "NFTX": "https://assets.coingecko.com/coins/images/13574/thumb/NFTX_%28Real%29.jpg",
  "PPC": "https://assets.coingecko.com/coins/images/28398/thumb/PokePlay-LOGO_200x200.png",
  "RISE": "https://assets.coingecko.com/coins/images/23083/thumb/RiseHero-LOGO.jpg",
  "SFTMX": "https://assets.coingecko.com/coins/images/26586/thumb/Fantom_Light.png.png",
  "TOWN": "https://assets.coingecko.com/coins/images/19581/thumb/town.png",
  "PNT": "https://assets.coingecko.com/coins/images/22799/thumb/logogradientsm.png",
  "CHEQ": "https://assets.coingecko.com/coins/images/20855/thumb/79901197.png",
  "EJS": "https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg",
  "NSFW": "https://assets.coingecko.com/coins/images/15315/thumb/pleasurecoin-icon.png",
  "SWFTC": "https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg",
  "VEED": "https://assets.coingecko.com/coins/images/15919/thumb/VEED.png",
  "CELL": "https://assets.coingecko.com/coins/images/14465/thumb/cellframe-coingecko.png",
  "HOGE": "https://assets.coingecko.com/coins/images/14360/thumb/hoge.jpg",
  "DORA": "https://assets.coingecko.com/coins/images/14478/thumb/dora_logo.jpg",
  "LBT": "https://assets.coingecko.com/coins/images/26266/thumb/LBT_symbol_200x200.png",
  "SLND": "https://assets.coingecko.com/coins/images/19573/thumb/i6AMOwun_400x400.jpg",
  "POOLZ": "https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png",
  "LOC": "https://assets.coingecko.com/coins/images/1357/thumb/LOC_Transparent_New.png",
  "BRIDGE": "https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png",
  "FOR": "https://assets.coingecko.com/coins/images/15139/thumb/5p5phe5YWstSKxQpGulQ962KfxO046klq3ftaTDw.png",
  "SCLP": "https://assets.coingecko.com/coins/images/19059/thumb/scallop.PNG",
  "SBTC": "https://assets.coingecko.com/coins/images/7089/thumb/image-62DF_5C05991C.jpg",
  "IBEUR": "https://assets.coingecko.com/coins/images/17285/thumb/Iron_Bank_Euro.png",
  "XFUND": "https://assets.coingecko.com/coins/images/13770/thumb/xfund.png",
  "KOIN": "https://assets.coingecko.com/coins/images/13116/thumb/koinos-mark-circle-250px.png",
  "VTC": "https://assets.coingecko.com/coins/images/18/thumb/vertcoin-logo-2018.png",
  "NRG": "https://assets.coingecko.com/coins/images/5795/thumb/3218.png",
  "QSP": "https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png",
  "MNDE": "https://assets.coingecko.com/coins/images/18867/thumb/MNDE.png",
  "ZZ": "https://assets.coingecko.com/coins/images/26141/thumb/zig_zag.",
  "AVT": "https://assets.coingecko.com/coins/images/901/thumb/Aventus.png",
  "INDEX": "https://assets.coingecko.com/coins/images/12729/thumb/index.png",
  "ATM": "https://assets.coingecko.com/coins/images/27159/thumb/IMG_20220831_033854_156.png",
  "BOOT": "https://assets.coingecko.com/coins/images/24617/thumb/boot.png",
  "DDX": "https://assets.coingecko.com/coins/images/13453/thumb/ddx_logo.png",
  "SERO": "https://assets.coingecko.com/coins/images/8245/thumb/43697637.png",
  "VST": "https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg",
  "OXEN": "https://assets.coingecko.com/coins/images/3462/thumb/ct0LitT.png",
  "KRL": "https://assets.coingecko.com/coins/images/2807/thumb/krl.png",
  "EL": "https://assets.coingecko.com/coins/images/27450/thumb/el.png",
  "LUFFY": "https://assets.coingecko.com/coins/images/17736/thumb/LUFFY_LOGO.png",
  "CMDX": "https://assets.coingecko.com/coins/images/21540/thumb/_ooQky6B_400x400.jpg",
  "YDF": "https://assets.coingecko.com/coins/images/26699/thumb/logo.png",
  "COW": "https://assets.coingecko.com/coins/images/20408/thumb/caash.png",
  "CRPT": "https://assets.coingecko.com/coins/images/1901/thumb/crypt.png",
  "OG": "https://assets.coingecko.com/coins/images/27699/thumb/Origin.jpeg",
  "WXT": "https://assets.coingecko.com/coins/images/8835/thumb/Wirex.jpg",
  "BOA": "https://assets.coingecko.com/coins/images/9202/thumb/Picture1.png",
  "BTR": "https://assets.coingecko.com/coins/images/19273/thumb/btr.png",
  "ZNN": "https://assets.coingecko.com/coins/images/8177/thumb/kC-sx49e_400x400.jpg",
  "MMPRO": "https://assets.coingecko.com/coins/images/20386/thumb/MMPRO_LOGO_W.png",
  "BTCP": "https://assets.coingecko.com/coins/images/3413/thumb/icon_btcp-1000px.png",
  "FLX": "https://assets.coingecko.com/coins/images/23492/thumb/Felix.jpeg",
  "PART": "https://assets.coingecko.com/coins/images/21302/thumb/logo.png",
  "SAUCE": "https://assets.coingecko.com/coins/images/27401/thumb/SAUCE_Token_Icon.png",
  "CLO": "https://assets.coingecko.com/coins/images/4051/thumb/CLO.png",
  "PBX": "https://assets.coingecko.com/coins/images/23677/thumb/ezgif-4-225dbc8377.jpg",
  "BTU": "https://assets.coingecko.com/coins/images/3697/thumb/btuprotocol.jpeg",
  "INTER": "https://assets.coingecko.com/coins/images/18288/thumb/chz.png",
  "XED": "https://assets.coingecko.com/coins/images/13518/thumb/exeedme.png",
  "KIN": "https://assets.coingecko.com/coins/images/959/thumb/kin-circle-white.png",
  "WAIT": "https://assets.coingecko.com/coins/images/26860/thumb/wait_etherscan_logo.png",
  "MCRT": "https://assets.coingecko.com/coins/images/21318/thumb/mcrt.png",
  "GO": "https://assets.coingecko.com/coins/images/3286/thumb/GoChain.png",
  "KOMPETE": "https://assets.coingecko.com/coins/images/24298/thumb/KOMPETE_LOGO.",
  "JUV": "https://assets.coingecko.com/coins/images/10060/thumb/Juve-10.png",
  "PZM": "https://assets.coingecko.com/coins/images/674/thumb/prizm.png",
  "XDEFI": "https://assets.coingecko.com/coins/images/19524/thumb/xdefi.png",
  "VNO": "https://assets.coingecko.com/coins/images/28799/thumb/Veno_Token_dark.png",
  "HELLO": "https://assets.coingecko.com/coins/images/27891/thumb/uFXN2S1N_400x400.jpeg",
  "SOCKS": "https://assets.coingecko.com/coins/images/10717/thumb/qFrcoiM.png",
  "LBC": "https://assets.coingecko.com/coins/images/463/thumb/lbc.png",
  "MANC": "https://assets.coingecko.com/coins/images/25084/thumb/IKpuMYuGaMHEE0nrDUwpf6DqLQ5zTfZ1sak6ZqeIe2snV4GqQbh4Eic6hHcPl_OpBmlhcrRJ5kqoGzwB1CezMiDYnwR6rDT08_l8zb3-y3aPhTv7_0x5g4IO9JpQXDViEIqTZ7gYzTXqkcP6MH_uCFxQF-l-ohR0-FdT8O-ZTmNUKm--OuqEf9DVa-miAFp4t2pN-kcJ5Jv8woPj51yk-zna8c.jpg",
  "WOOP": "https://assets.coingecko.com/coins/images/24904/thumb/coin-gecko.png",
  "URUS": "https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png",
  "WICC": "https://assets.coingecko.com/coins/images/2439/thumb/ff4700019d0c24af713d.png",
  "CIRUS": "https://assets.coingecko.com/coins/images/17798/thumb/8p0Bvi90_400x400.jpg",
  "EDEN": "https://assets.coingecko.com/coins/images/19404/thumb/paradisefi.PNG",
  "ADP": "https://assets.coingecko.com/coins/images/14203/thumb/logo_on.png",
  "MYC": "https://assets.coingecko.com/coins/images/16041/thumb/200x2001.png",
  "WEGRO": "https://assets.coingecko.com/coins/images/21675/thumb/wegrocoin_logo_favicon.png",
  "MINTME": "https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png",
  "OVR": "https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png",
  "OAX": "https://assets.coingecko.com/coins/images/853/thumb/OAXlogo.png",
  "GMM": "https://assets.coingecko.com/coins/images/11660/thumb/gmm_logo_token.png",
  "MIMO": "https://assets.coingecko.com/coins/images/21071/thumb/200xMIMO.png",
  "TONE": "https://assets.coingecko.com/coins/images/15669/thumb/nft_tone.PNG",
  "DMD": "https://assets.coingecko.com/coins/images/18981/thumb/dmd.PNG",
  "RENBTC": "https://assets.coingecko.com/coins/images/11370/thumb/Bitcoin.jpg",
  "SYLO": "https://assets.coingecko.com/coins/images/6430/thumb/SYLO.svg",
  "SIDUS": "https://assets.coingecko.com/coins/images/21401/thumb/SIDUS_coin_logo.png",
  "HERA": "https://assets.coingecko.com/coins/images/19160/thumb/hera.png",
  "FIBO": "https://assets.coingecko.com/coins/images/24745/thumb/logo.png",
  "XHV": "https://assets.coingecko.com/coins/images/3647/thumb/transparent.png",
  "ZCN": "https://assets.coingecko.com/coins/images/4934/thumb/200x200_transparent.png",
  "CREAM": "https://assets.coingecko.com/coins/images/23295/thumb/neWvicW.png",
  "REALM": "https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG",
  "XCP": "https://assets.coingecko.com/coins/images/9/thumb/counterparty.png",
  "PKT": "https://assets.coingecko.com/coins/images/27589/thumb/PKT_icon_2048.png",
  "XOR": "https://assets.coingecko.com/coins/images/12488/thumb/hHQJg1xu_400x400.png",
  "PHONON": "https://assets.coingecko.com/coins/images/22308/thumb/ezgif-2-e7fb84364d.png",
  "NTVRK": "https://assets.coingecko.com/coins/images/15721/thumb/netvrk_icon.png",
  "VAL": "https://assets.coingecko.com/coins/images/26889/thumb/ValhallaCoin_200_x_200.png",
  "BUX": "https://assets.coingecko.com/coins/images/2316/thumb/BUXC.png",
  "LTX": "https://assets.coingecko.com/coins/images/13050/thumb/Lattice.jpg",
  "CYCE": "https://assets.coingecko.com/coins/images/17806/thumb/cyceicon.png",
  "ACX": "https://assets.coingecko.com/coins/images/18783/thumb/logo_-_2021-10-05T133349.661.png",
  "SFD": "https://assets.coingecko.com/coins/images/12661/thumb/Eb2xbVb.png",
  "NWC": "https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png",
  "VEE": "https://assets.coingecko.com/coins/images/18418/thumb/logo_-_2021-09-17T100305.788.png",
  "PEEL": "https://assets.coingecko.com/coins/images/26450/thumb/PEEL200x200.png",
  "CTX": "https://assets.coingecko.com/coins/images/24500/thumb/c2x.jpeg",
  "RBIF": "https://assets.coingecko.com/coins/images/20821/thumb/O68Gs5SE_400x400.jpg",
  "XDB": "https://assets.coingecko.com/coins/images/8089/thumb/digitalbits-logo.jpg",
  "PALLA": "https://assets.coingecko.com/coins/images/16200/thumb/palla.png",
  "QUBE": "https://assets.coingecko.com/coins/images/21599/thumb/hYjrsaHU_400x400.png",
  "FYN": "https://assets.coingecko.com/coins/images/23275/thumb/fyn.png",
  "QRL": "https://assets.coingecko.com/coins/images/700/thumb/yellow-black.512x512.png",
  "BDP": "https://assets.coingecko.com/coins/images/3890/thumb/AxiFAoHc_400x400.jpg",
  "BNC": "https://assets.coingecko.com/coins/images/19259/thumb/bifrost.png",
  "DIGITS": "https://assets.coingecko.com/coins/images/23551/thumb/Logo-Digits-DAO-Icon-01.jpg",
  "ZANO": "https://assets.coingecko.com/coins/images/8370/thumb/zano.png",
  "HEC": "https://assets.coingecko.com/coins/images/22813/thumb/logo_-_2022-01-20T140628.062.png",
  "SUKU": "https://assets.coingecko.com/coins/images/11969/thumb/suku-200.png",
  "PLANETS": "https://assets.coingecko.com/coins/images/16127/thumb/cwCG_U8M_400x400.png",
  "REVV": "https://assets.coingecko.com/coins/images/12373/thumb/REVV_TOKEN_Refined_2021_%281%29.png",
  "MITX": "https://assets.coingecko.com/coins/images/23879/thumb/edmBHbS2_400x400.jpg",
  "RAE": "https://assets.coingecko.com/coins/images/9428/thumb/Copy_of_raetoken.png",
  "SOV": "https://assets.coingecko.com/coins/images/9575/thumb/sov.PNG",
  "WRLD": "https://assets.coingecko.com/coins/images/22112/thumb/ZyBrRgfO.jpg",
  "MMF": "https://assets.coingecko.com/coins/images/26840/thumb/1.png",
  "BEETS": "https://assets.coingecko.com/coins/images/19158/thumb/beets-icon-large.png",
  "NATION": "https://assets.coingecko.com/coins/images/25106/thumb/Orb_gradient.png",
  "PBR": "https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png",
  "SNOW": "https://assets.coingecko.com/coins/images/12751/thumb/uQBJL3A.png",
  "EQUAD": "https://assets.coingecko.com/coins/images/4462/thumb/equad.png",
  "MOOV": "https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png",
  "BAN": "https://assets.coingecko.com/coins/images/6226/thumb/banano-transparent.png",
  "BUY": "https://assets.coingecko.com/coins/images/12216/thumb/Ddphl9BD.png",
  "WAS": "https://assets.coingecko.com/coins/images/15374/thumb/wasderBoxedLogoWhite-200x200.png",
  "GCR": "https://assets.coingecko.com/coins/images/14815/thumb/gcr.jpeg",
  "XCHF": "https://assets.coingecko.com/coins/images/8465/thumb/WhuiuJBc_400x400.jpg",
  "CWBTC": "https://assets.coingecko.com/coins/images/10823/thumb/cwbtc.png",
  "GRIN": "https://assets.coingecko.com/coins/images/7340/thumb/grin.png",
  "WEMIX$": "https://assets.coingecko.com/coins/images/28637/thumb/Color_96.png",
  "DMTR": "https://assets.coingecko.com/coins/images/18530/thumb/HqEiru32_400x400.jpg",
  "GULF": "https://assets.coingecko.com/coins/images/26053/thumb/gulf-coin-logo-A2F3BBC063-seeklogo.com.png",
  "QASH": "https://assets.coingecko.com/coins/images/1220/thumb/qash.jpeg",
  "PSWAP": "https://assets.coingecko.com/coins/images/15014/thumb/bbPTbZZY_400x400.jpg",
  "QTCON": "https://assets.coingecko.com/coins/images/8208/thumb/QTCON.png",
  "MARSH": "https://assets.coingecko.com/coins/images/14554/thumb/img_circle_256x256.png",
  "VOICE": "https://assets.coingecko.com/coins/images/12926/thumb/OjCQtdL.png",
  "DOG": "https://assets.coingecko.com/coins/images/14647/thumb/WechatIMG3603.png",
  "UBSN": "https://assets.coingecko.com/coins/images/1599/thumb/silent-notary.png",
  "VINA": "https://assets.coingecko.com/coins/images/28261/thumb/light-removebg-preview.png",
  "MAN": "https://assets.coingecko.com/coins/images/25914/thumb/Screenshot_2022-03-31_163228.png",
  "BOTTO": "https://assets.coingecko.com/coins/images/18892/thumb/bottos_logo.jpg",
  "DSLA": "https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png",
  "CIV": "https://assets.coingecko.com/coins/images/17626/thumb/civ-200x200.png",
  "SKEB": "https://assets.coingecko.com/coins/images/27155/thumb/skebcoin-cmc.png",
  "PND": "https://assets.coingecko.com/coins/images/138/thumb/Pandacoin.png",
  "XRT": "https://assets.coingecko.com/coins/images/28861/thumb/xrt.png",
  "UWU": "https://assets.coingecko.com/coins/images/18355/thumb/uwu.png",
  "DVF": "https://assets.coingecko.com/coins/images/16414/thumb/rhinofii.jpg",
  "WABI": "https://assets.coingecko.com/coins/images/1338/thumb/Tael.png",
  "MYST": "https://assets.coingecko.com/coins/images/24481/thumb/myst.png",
  "IDRT": "https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg",
  "OBSR": "https://assets.coingecko.com/coins/images/7474/thumb/obsr.png",
  "ZYN": "https://assets.coingecko.com/coins/images/7587/thumb/LOGO-HD-Z-de-ZYNECOIN-coinmarket.png",
  "Y2K": "https://assets.coingecko.com/coins/images/28542/thumb/l7jRo-5-_400x400.jpg",
  "PNG": "https://assets.coingecko.com/coins/images/14023/thumb/PNG_token.png",
  "KMA": "https://assets.coingecko.com/coins/images/20976/thumb/kma.png",
  "DFX": "https://assets.coingecko.com/coins/images/28351/thumb/b3a69dfd-3250-4382-8fee-be61acc7c5de.png",
  "PAI": "https://assets.coingecko.com/coins/images/18439/thumb/pai.png",
  "MUSD": "https://assets.coingecko.com/coins/images/25480/thumb/musd.png",
  "RETH2": "https://assets.coingecko.com/coins/images/16570/thumb/red256.png",
  "DVPN": "https://assets.coingecko.com/coins/images/3625/thumb/Sentinel_512X512.png",
  "CGO": "https://assets.coingecko.com/coins/images/25682/thumb/CGO_200x200.png",
  "EPIC": "https://assets.coingecko.com/coins/images/9520/thumb/Epic_Coin_NO_drop_shadow.png",
  "FEVR": "https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png",
  "XEN": "https://assets.coingecko.com/coins/images/27713/thumb/Xen.jpeg",
  "POLAR": "https://assets.coingecko.com/coins/images/23672/thumb/polar-200px.png",
  "SPA": "https://assets.coingecko.com/coins/images/19744/thumb/3IdUVrGl_400x400.jpg",
  "EPIK": "https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg",
  "HAMI": "https://assets.coingecko.com/coins/images/28783/thumb/icon.png",
  "AQUA": "https://assets.coingecko.com/coins/images/15566/thumb/d5bnQ0H.png",
  "BRG": "https://assets.coingecko.com/coins/images/12512/thumb/brg.png",
  "RAI": "https://assets.coingecko.com/coins/images/13127/thumb/logo.png",
  "WHALE": "https://assets.coingecko.com/coins/images/18347/thumb/whale.png",
  "PTP": "https://assets.coingecko.com/coins/images/21724/thumb/7r2U_2Ig_400x400.jpg",
  "BCN": "https://assets.coingecko.com/coins/images/29036/thumb/IMG_20230207_011808_491.png",
  "CHAIN": "https://assets.coingecko.com/coins/images/26563/thumb/CHAINS.png",
  "CELT": "https://assets.coingecko.com/coins/images/18683/thumb/celt.png",
  "TITAN": "https://assets.coingecko.com/coins/images/16031/thumb/titan.png",
  "APM": "https://assets.coingecko.com/coins/images/10069/thumb/PvDCvh5h_400x400.png",
  "BAX": "https://assets.coingecko.com/coins/images/2168/thumb/babb.png",
  "XEP": "https://assets.coingecko.com/coins/images/13589/thumb/Apple-iPhone-Icon-Retina.png",
  "GLQ": "https://assets.coingecko.com/coins/images/14474/thumb/graphlinq_logo.jpg",
  "NFTB": "https://assets.coingecko.com/coins/images/15484/thumb/nftb_logo.png",
  "KAN": "https://assets.coingecko.com/coins/images/4651/thumb/kan-token.png",
  "SPANK": "https://assets.coingecko.com/coins/images/1226/thumb/spankchain.png",
  "VLXPAD": "https://assets.coingecko.com/coins/images/18535/thumb/11654.png",
  "LING": "https://assets.coingecko.com/coins/images/27271/thumb/ling.png",
  "SEUR": "https://assets.coingecko.com/coins/images/13637/thumb/sEUR.png",
  "SOS": "https://assets.coingecko.com/coins/images/18290/thumb/FkqAAqSd_400x400.jpg",
  "RING": "https://assets.coingecko.com/coins/images/20677/thumb/Logo-rond-RING.png",
  "DIME": "https://assets.coingecko.com/coins/images/860/thumb/logo3white.png",
  "GENE": "https://assets.coingecko.com/coins/images/14145/thumb/logo.a60a0c80_%281%29.png",
  "LOOT": "https://assets.coingecko.com/coins/images/13414/thumb/ezgif-2-c057c84f38.jpg",
  "TPAD": "https://assets.coingecko.com/coins/images/27968/thumb/pSFtjSk.png",
  "JAM": "https://assets.coingecko.com/coins/images/17992/thumb/ClubNLwz.png",
  "ALOT": "https://assets.coingecko.com/coins/images/24188/thumb/logo_200x200.png",
  "ASR": "https://assets.coingecko.com/coins/images/11688/thumb/Roma-10.png",
  "GOVI": "https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png",
  "PIB": "https://assets.coingecko.com/coins/images/2972/thumb/pibble.jpg",
  "WTC": "https://assets.coingecko.com/coins/images/1093/thumb/ggx6nnW.png",
  "SCP": "https://assets.coingecko.com/coins/images/7508/thumb/SPRho_512x512.png",
  "MVX": "https://assets.coingecko.com/coins/images/23830/thumb/mvx.png",
  "IPAD": "https://assets.coingecko.com/coins/images/17302/thumb/ipad.jpg",
  "DEL": "https://assets.coingecko.com/coins/images/17133/thumb/logo_-_2021-07-16T133613.232.png",
  "BDT": "https://assets.coingecko.com/coins/images/13426/thumb/Black-Dragon-Black.png",
  "SWP": "https://assets.coingecko.com/coins/images/22843/thumb/P6jfqnuo.png",
  "ARIA20": "https://assets.coingecko.com/coins/images/5054/thumb/Aria_Logo_256.png",
  "TBTC": "https://assets.coingecko.com/coins/images/14663/thumb/download.png",
  "HOP": "https://assets.coingecko.com/coins/images/16844/thumb/TOKEN-ICON-T.png",
  "IPOR": "https://assets.coingecko.com/coins/images/28373/thumb/IPOR-token-200x200.png",
  "SHILL": "https://assets.coingecko.com/coins/images/18176/thumb/SHILL_Logo.png",
  "LORDS": "https://assets.coingecko.com/coins/images/22171/thumb/Frame_1.png",
  "HIBS": "https://assets.coingecko.com/coins/images/11574/thumb/51367870.png",
  "RVF": "https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png",
  "ARC": "https://assets.coingecko.com/coins/images/21270/thumb/arcadenetwork.PNG",
  "BRUSH": "https://assets.coingecko.com/coins/images/18579/thumb/sDYeBj9T_400x400.png",
  "POLYPAD": "https://assets.coingecko.com/coins/images/24905/thumb/BXApUK87_400x400.png",
  "MUSE": "https://assets.coingecko.com/coins/images/13230/thumb/muse_logo.png",
  "MONSTA": "https://assets.coingecko.com/coins/images/16441/thumb/Monster-Icon-CG200x200transparent.png",
  "POP": "https://assets.coingecko.com/coins/images/28160/thumb/Popcoin-logo-200x200.png",
  "UMAMI": "https://assets.coingecko.com/coins/images/21159/thumb/_UMAMI-200px.jpg",
  "RDT": "https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png",
  "XHT": "https://assets.coingecko.com/coins/images/16157/thumb/xGyZxK8.png",
  "FWB": "https://assets.coingecko.com/coins/images/14391/thumb/xRGEXmQN_400x400.png",
  "GMEE": "https://assets.coingecko.com/coins/images/14716/thumb/gmee-200x200.png",
  "RAVEN": "https://assets.coingecko.com/coins/images/8673/thumb/Raven_Protocol.jpg",
  "CBX": "https://assets.coingecko.com/coins/images/133/thumb/bullion.png",
  "BNBX": "https://assets.coingecko.com/coins/images/26842/thumb/BNBx_Logo.png",
  "NOTE": "https://assets.coingecko.com/coins/images/26988/thumb/iShot_2022-08-23_09.17.46.png",
  "DFYN": "https://assets.coingecko.com/coins/images/15368/thumb/SgqhfWz4_400x400_%281%29.jpg",
  "SHIRYO-INU": "https://assets.coingecko.com/coins/images/19652/thumb/shiryo_inu.png",
  "NEWO": "https://assets.coingecko.com/coins/images/24236/thumb/newo.png",
  "MASQ": "https://assets.coingecko.com/coins/images/13699/thumb/masq.png",
  "BTCST": "https://assets.coingecko.com/coins/images/14449/thumb/4a3IskOf_400x400.png",
  "IGNIS": "https://assets.coingecko.com/coins/images/1345/thumb/ignis.png",
  "POLK": "https://assets.coingecko.com/coins/images/14084/thumb/polkamarkets.jpg",
  "SHFT": "https://assets.coingecko.com/coins/images/28401/thumb/ShibcraftLogo200x200.png",
  "SLCL": "https://assets.coingecko.com/coins/images/24583/thumb/1_N9szP0_400x400.jpg",
  "GRAV": "https://assets.coingecko.com/coins/images/21844/thumb/kfw9xA0X_400x400.jpg",
  "LBL": "https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png",
  "POLYDOGE": "https://assets.coingecko.com/coins/images/15146/thumb/p1kSco1h_400x400.jpg",
  "SPOOL": "https://assets.coingecko.com/coins/images/21532/thumb/spool.png",
  "TOMB": "https://assets.coingecko.com/coins/images/16133/thumb/tomb_icon_noBG.png",
  "STFX": "https://assets.coingecko.com/coins/images/28631/thumb/WqBqIWcQ_400x400.jpg",
  "GEEQ": "https://assets.coingecko.com/coins/images/11953/thumb/GeeqLogoPNGTransparent-1.png",
  "OCC": "https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG",
  "SALT": "https://assets.coingecko.com/coins/images/962/thumb/salt.png",
  "LEON": "https://assets.coingecko.com/coins/images/23714/thumb/leon-logo-square.png",
  "DIP": "https://assets.coingecko.com/coins/images/4586/thumb/dip.png",
  "BBS": "https://assets.coingecko.com/coins/images/4328/thumb/bbscoin.png",
  "RDD": "https://assets.coingecko.com/coins/images/40/thumb/redd_logo.png",
  "UPUNK": "https://assets.coingecko.com/coins/images/14905/thumb/cryptopunk7804.png",
  "PLS": "https://assets.coingecko.com/coins/images/21902/thumb/Ipulse-con.png",
  "WALLET": "https://assets.coingecko.com/coins/images/23154/thumb/wallet.PNG",
  "EDG": "https://assets.coingecko.com/coins/images/635/thumb/Edgeless-Token.jpg",
  "VIDYA": "https://assets.coingecko.com/coins/images/12219/thumb/VIDYA_TOKEN.png",
  "ICE": "https://assets.coingecko.com/coins/images/17024/thumb/ice_logo.jpg",
  "APL": "https://assets.coingecko.com/coins/images/13054/thumb/Apollon-10_%28NEW%29.jpg",
  "TXAU": "https://assets.coingecko.com/coins/images/27828/thumb/tGOLD_token_2D.jpg",
  "RAZOR": "https://assets.coingecko.com/coins/images/13797/thumb/icon.png",
  "TROY": "https://assets.coingecko.com/coins/images/10057/thumb/%E5%A4%B4%E5%83%8F-twitter.jpg",
  "SIGNA": "https://assets.coingecko.com/coins/images/154/thumb/SIGNA.png",
  "DYP": "https://assets.coingecko.com/coins/images/13480/thumb/DYP-200x200px.png",
  "SWAPXI": "https://assets.coingecko.com/coins/images/23568/thumb/6nNj22P.png",
  "BEAM": "https://assets.coingecko.com/coins/images/7339/thumb/BEAM.png",
  "MMIT": "https://assets.coingecko.com/coins/images/26687/thumb/Mr._B.png",
  "DHT": "https://assets.coingecko.com/coins/images/12508/thumb/dht.png",
  "PSL": "https://assets.coingecko.com/coins/images/14293/thumb/A4qGwEij_400x400.jpg",
  "SANI": "https://assets.coingecko.com/coins/images/25222/thumb/B7r0ocfQ_400x400.jpg",
  "BLOCK": "https://assets.coingecko.com/coins/images/236/thumb/blocknet.jpg",
  "TRVL": "https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg",
  "NGM": "https://assets.coingecko.com/coins/images/13722/thumb/logo-200x200.jpg",
  "SHOP": "https://assets.coingecko.com/coins/images/27242/thumb/shop.png",
  "DFL": "https://assets.coingecko.com/coins/images/18261/thumb/defily.PNG",
  "GPCX": "https://assets.coingecko.com/coins/images/25268/thumb/logo_yuvarlak.png",
  "VEUSD": "https://assets.coingecko.com/coins/images/24595/thumb/veusd.png",
  "KATA": "https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png",
  "CROWD": "https://assets.coingecko.com/coins/images/23928/thumb/icon_192.png",
  "MBD": "https://assets.coingecko.com/coins/images/25617/thumb/mbd-transparent_%281%29.png",
  "COLA": "https://assets.coingecko.com/coins/images/18806/thumb/colawork_logo_200x200.png",
  "TGT": "https://assets.coingecko.com/coins/images/16725/thumb/Yn1ebvX.png",
  "INSUR": "https://assets.coingecko.com/coins/images/14226/thumb/insur.png",
  "MNTL": "https://assets.coingecko.com/coins/images/25181/thumb/thumbnail.png",
  "NMX": "https://assets.coingecko.com/coins/images/14424/thumb/NMX_c_200px.png",
  "DETO": "https://assets.coingecko.com/coins/images/14610/thumb/deto-logo.png",
  "SRX": "https://assets.coingecko.com/coins/images/28620/thumb/srx.png",
  "VALOR": "https://assets.coingecko.com/coins/images/7231/thumb/smart_valor.png",
  "GAMMA": "https://assets.coingecko.com/coins/images/19590/thumb/gamma.png",
  "TYRANT": "https://assets.coingecko.com/coins/images/27911/thumb/image_%283%29.png",
  "MEME": "https://assets.coingecko.com/coins/images/27795/thumb/memeprotocol.png",
  "TRA": "https://assets.coingecko.com/coins/images/13059/thumb/TRABZ-Token-10.png",
  "XPX": "https://assets.coingecko.com/coins/images/3735/thumb/proximax.png",
  "FRM": "https://assets.coingecko.com/coins/images/8251/thumb/FRM.png",
  "SENATE": "https://assets.coingecko.com/coins/images/21272/thumb/siIBOmcs.png",
  "KROM": "https://assets.coingecko.com/coins/images/20541/thumb/KROM_Transparent.png",
  "POSI": "https://assets.coingecko.com/coins/images/17459/thumb/posi.png",
  "MONA": "https://assets.coingecko.com/coins/images/21742/thumb/mona.PNG",
  "HANDY": "https://assets.coingecko.com/coins/images/21944/thumb/Handy_Token_symbol-01.png",
  "LQDR": "https://assets.coingecko.com/coins/images/15782/thumb/LQDR_Glowing_Icon.png",
  "FOAM": "https://assets.coingecko.com/coins/images/3345/thumb/K51lJQc.png",
  "WAMPL": "https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg",
  "PRISM": "https://assets.coingecko.com/coins/images/23422/thumb/Bi9ffYa.png",
  "XELS": "https://assets.coingecko.com/coins/images/14729/thumb/Xels_logo_square_%281%29.png",
  "TAKI": "https://assets.coingecko.com/coins/images/25271/thumb/logo.png",
  "KASTA": "https://assets.coingecko.com/coins/images/22293/thumb/256d1307b1bc42f4a3f0a013dfde2e59.png",
  "ASK": "https://assets.coingecko.com/coins/images/12521/thumb/rjQjTU3c_400x400.jpg",
  "WOM": "https://assets.coingecko.com/coins/images/4559/thumb/wom_logo_small.png",
  "KAP": "https://assets.coingecko.com/coins/images/27682/thumb/KAP-200px.png",
  "CARD": "https://assets.coingecko.com/coins/images/3247/thumb/cardstack.png",
  "JRT": "https://assets.coingecko.com/coins/images/10390/thumb/cfeii0y.png",
  "FLOOR": "https://assets.coingecko.com/coins/images/24026/thumb/icon-floor_2x.png",
  "CCT": "https://assets.coingecko.com/coins/images/995/thumb/crystal-clear.png",
  "METAV": "https://assets.coingecko.com/coins/images/21397/thumb/metav.png",
  "K21": "https://assets.coingecko.com/coins/images/14466/thumb/k21-token.png",
  "BGVT": "https://assets.coingecko.com/coins/images/27975/thumb/bgvt.png",
  "NVT": "https://assets.coingecko.com/coins/images/11873/thumb/NerveNetwork.png",
  "XIDR": "https://assets.coingecko.com/coins/images/21126/thumb/XIDR_Logo_256_X_256.png",
  "WEX": "https://assets.coingecko.com/coins/images/14882/thumb/wex_v3.png",
  "SCNSOL": "https://assets.coingecko.com/coins/images/18468/thumb/biOTzfxE_400x400.png",
  "ADAPAD": "https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg",
  "ARG": "https://assets.coingecko.com/coins/images/25542/thumb/EP_f1090image_story.jpeg",
  "NEX": "https://assets.coingecko.com/coins/images/3246/thumb/nash.png",
  "GLCH": "https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg",
  "RVST": "https://assets.coingecko.com/coins/images/18622/thumb/Qma94n6waADECpde1ukBBS8iNiECcdVcxjfgubnWPE9ZT7.png",
  "JUP": "https://assets.coingecko.com/coins/images/10351/thumb/logo512.png",
  "UNO": "https://assets.coingecko.com/coins/images/19469/thumb/uno.png",
  "GYMNET": "https://assets.coingecko.com/coins/images/24654/thumb/Blue.png",
  "PEAK": "https://assets.coingecko.com/coins/images/23747/thumb/peak.png",
  "SPC": "https://assets.coingecko.com/coins/images/27644/thumb/wXXGCkQ.png",
  "CLY": "https://assets.coingecko.com/coins/images/20811/thumb/IMG_20211104_182317_937.png",
  "GRND": "https://assets.coingecko.com/coins/images/27186/thumb/GRND_3x.png",
  "SKEY": "https://assets.coingecko.com/coins/images/13425/thumb/SKEY_Network_logo_black.png",
  "CAS": "https://assets.coingecko.com/coins/images/2979/thumb/Logo.png",
  "CRP": "https://assets.coingecko.com/coins/images/23364/thumb/SAVE_20220126_232119.jpg",
  "RADAR": "https://assets.coingecko.com/coins/images/13909/thumb/RADAR.png",
  "SLIM": "https://assets.coingecko.com/coins/images/15816/thumb/logo_cg.png",
  "MOD": "https://assets.coingecko.com/coins/images/13980/thumb/modefi_logo.png",
  "DERI": "https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg",
  "HORD": "https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png",
  "UXD": "https://assets.coingecko.com/coins/images/22850/thumb/UXD-White.png",
  "SHD": "https://assets.coingecko.com/coins/images/23804/thumb/NEAKadeZ_400x400.jpg",
  "SIPHER": "https://assets.coingecko.com/coins/images/21070/thumb/SipherToken.png",
  "WCHI": "https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png",
  "DRGN": "https://assets.coingecko.com/coins/images/1289/thumb/dragonchain.png",
  "BPRO": "https://assets.coingecko.com/coins/images/15110/thumb/66428641.jpg",
  "STAX": "https://assets.coingecko.com/coins/images/12967/thumb/photo_2021-01-12_00.19.13.jpeg",
  "PUSH": "https://assets.coingecko.com/coins/images/14769/thumb/aiOxYOJI_400x400.jpeg",
  "RTM": "https://assets.coingecko.com/coins/images/14412/thumb/MM2019bird200pxRTM.png",
  "DOSE": "https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG",
  "MTV": "https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png",
  "IWBTC": "https://assets.coingecko.com/coins/images/25784/thumb/iWBTC-100x100.png",
  "MBS": "https://assets.coingecko.com/coins/images/21720/thumb/HtFQuT-c_400x400.jpg",
  "ARV": "https://assets.coingecko.com/coins/images/23716/thumb/uUMU6_Cr_400x400.jpg",
  "STEAMX": "https://assets.coingecko.com/coins/images/16914/thumb/steamx.png",
  "ZILLIONXO": "https://assets.coingecko.com/coins/images/26515/thumb/tg_image_4289710331.jpeg",
  "VSTA": "https://assets.coingecko.com/coins/images/23306/thumb/vesta-finance.png",
  "ISP": "https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG",
  "NEER": "https://assets.coingecko.com/coins/images/26705/thumb/14294.png",
  "VFOX": "https://assets.coingecko.com/coins/images/15363/thumb/vfox2.png",
  "PI": "https://assets.coingecko.com/coins/images/10021/thumb/8mKz2RnV_400x400.jpg",
  "MVI": "https://assets.coingecko.com/coins/images/14684/thumb/MVI_logo.png",
  "GHX": "https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png",
  "KENSHI": "https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png",
  "FOREX": "https://assets.coingecko.com/coins/images/6852/thumb/forex-logo.PNG",
  "NXT": "https://assets.coingecko.com/coins/images/18939/thumb/07FgXHOc_400x400.jpg",
  "SCAR": "https://assets.coingecko.com/coins/images/23805/thumb/Xe5h3l0p_400x400.jpg",
  "DVI": "https://assets.coingecko.com/coins/images/13020/thumb/WGAhHOLv_400x400.png",
  "POLA": "https://assets.coingecko.com/coins/images/13817/thumb/tHQrxdYQ_400x400.png",
  "IUSDC": "https://assets.coingecko.com/coins/images/18960/thumb/usdc.png",
  "GM": "https://assets.coingecko.com/coins/images/11612/thumb/logoGM_%281%29.png",
  "RGT": "https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png",
  "WIKEN": "https://assets.coingecko.com/coins/images/9182/thumb/2_0C4zaAE66q6I_k4uBmgqkw.png",
  "RBW": "https://assets.coingecko.com/coins/images/24498/thumb/icon_rbw.png",
  "SMT": "https://assets.coingecko.com/coins/images/21905/thumb/smt-logo.png",
  "ATRI": "https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png",
  "ECO": "https://assets.coingecko.com/coins/images/23363/thumb/Eco-CREDIT-Logo.png",
  "TORI": "https://assets.coingecko.com/coins/images/27639/thumb/photo_2022-10-04_11-06-40.jpg",
  "VID": "https://assets.coingecko.com/coins/images/3376/thumb/1638280236344.jpg",
  "NIOB": "https://assets.coingecko.com/coins/images/24141/thumb/niob.png",
  "COV": "https://assets.coingecko.com/coins/images/1950/thumb/covesting.png",
  "NCR": "https://assets.coingecko.com/coins/images/17935/thumb/s4C4tVi.png",
  "NRCH": "https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg",
  "QUAD": "https://assets.coingecko.com/coins/images/21364/thumb/GkMXE_7A_400x400.png",
  "MAPS": "https://assets.coingecko.com/coins/images/13556/thumb/Copy_of_image_%28139%29.png",
  "JMPT": "https://assets.coingecko.com/coins/images/22603/thumb/200x200.png",
  "SLRS": "https://assets.coingecko.com/coins/images/15762/thumb/9989.png",
  "WWY": "https://assets.coingecko.com/coins/images/22418/thumb/wwy.png",
  "SHA": "https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png",
  "QUIDD": "https://assets.coingecko.com/coins/images/19725/thumb/quidd.png",
  "PKOIN": "https://assets.coingecko.com/coins/images/15276/thumb/pocketnet_logo-11.png",
  "TCH": "https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png",
  "LITH": "https://assets.coingecko.com/coins/images/20080/thumb/rHeGfsvH_400x400.jpg",
  "LAMB": "https://assets.coingecko.com/coins/images/4703/thumb/lambda_200.png",
  "IBAT": "https://assets.coingecko.com/coins/images/27069/thumb/ibat_logo.png",
  "AMDG": "https://assets.coingecko.com/coins/images/24545/thumb/amdg-logo.png",
  "PLASTIK": "https://assets.coingecko.com/coins/images/21212/thumb/plastik.png",
  "DOGEGF": "https://assets.coingecko.com/coins/images/18651/thumb/dogf.png",
  "GBPT": "https://assets.coingecko.com/coins/images/26591/thumb/gbpt.jpeg",
  "AIR": "https://assets.coingecko.com/coins/images/24853/thumb/9CmgcH6.png",
  "BONDLY": "https://assets.coingecko.com/coins/images/13322/thumb/FORJ_twitter_twitter-linked_in_profile_%281%29.png",
  "WIGO": "https://assets.coingecko.com/coins/images/23603/thumb/wigo.png",
  "DOV": "https://assets.coingecko.com/coins/images/1072/thumb/Dovu_Earth_72x72_leaf_blue.png",
  "ID": "https://assets.coingecko.com/coins/images/27851/thumb/2022-10-16_17-47-37.png",
  "MPS": "https://assets.coingecko.com/coins/images/11471/thumb/MPS.png",
  "DNX": "https://assets.coingecko.com/coins/images/27776/thumb/Transparent_Logo.png",
  "AZIT": "https://assets.coingecko.com/coins/images/24515/thumb/ofbC3qtQ_400x400.jpg",
  "TIFI": "https://assets.coingecko.com/coins/images/24990/thumb/logo200_%281%29.png",
  "LCR": "https://assets.coingecko.com/coins/images/27335/thumb/LCR_logo_200x200-1.png",
  "SPRT": "https://assets.coingecko.com/coins/images/20981/thumb/sportium_logo_symbolmark_skyblue.png",
  "OXY": "https://assets.coingecko.com/coins/images/13509/thumb/8DjBZ79V_400x400.jpg",
  "DOBO": "https://assets.coingecko.com/coins/images/19153/thumb/dobo.png",
  "STEP": "https://assets.coingecko.com/coins/images/18633/thumb/11965.png",
  "ORE": "https://assets.coingecko.com/coins/images/20951/thumb/ppLk7GCl_400x400.jpg",
  "BLANK": "https://assets.coingecko.com/coins/images/14209/thumb/blank.png",
  "PARA": "https://assets.coingecko.com/coins/images/27219/thumb/parallel_finance.png",
  "XTM": "https://assets.coingecko.com/coins/images/19049/thumb/xtime.PNG",
  "WNK": "https://assets.coingecko.com/coins/images/22260/thumb/wnk.png",
  "BSCX": "https://assets.coingecko.com/coins/images/13582/thumb/icon-bscex-200x200.png",
  "FRIN": "https://assets.coingecko.com/coins/images/13222/thumb/frin.png",
  "STRX": "https://assets.coingecko.com/coins/images/15839/thumb/StrikeX-Icon-200px.png",
  "MCONTENT": "https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg",
  "JGN": "https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png",
  "PEX": "https://assets.coingecko.com/coins/images/20916/thumb/RC5dZfC4_400x400.jpg",
  "TSHARE": "https://assets.coingecko.com/coins/images/16405/thumb/crypto_tomb_share.bf1a6c52.png",
  "GAINS": "https://assets.coingecko.com/coins/images/14681/thumb/GAINS_Token_200x200.png",
  "VEMP": "https://assets.coingecko.com/coins/images/18074/thumb/VEMP_Token_200x200.png",
  "BOX": "https://assets.coingecko.com/coins/images/22660/thumb/qggoHE1__400x400.jpg",
  "SHX": "https://assets.coingecko.com/coins/images/7427/thumb/Blue_Mark_-_White_BG.png",
  "SPORT": "https://assets.coingecko.com/coins/images/25823/thumb/SPORT_LOGO_PNG.png",
  "FST": "https://assets.coingecko.com/coins/images/28162/thumb/fst.png",
  "STABLZ": "https://assets.coingecko.com/coins/images/28983/thumb/Frame_2869.png",
  "PXP": "https://assets.coingecko.com/coins/images/23881/thumb/AJLTxV30.png",
  "CGG": "https://assets.coingecko.com/coins/images/14326/thumb/cgg_logo.png",
  "NEBO": "https://assets.coingecko.com/coins/images/13436/thumb/csp_dao.png",
  "STC": "https://assets.coingecko.com/coins/images/26975/thumb/2KstL9si_400x400.jpeg",
  "FLASH": "https://assets.coingecko.com/coins/images/19827/thumb/flash.PNG",
  "SIB": "https://assets.coingecko.com/coins/images/417/thumb/sibcoin.png",
  "PLI": "https://assets.coingecko.com/coins/images/26870/thumb/PlanetinfinityLogo200x200.png",
  "GRC": "https://assets.coingecko.com/coins/images/18376/thumb/Untitled-design-2-removebg-preview.png",
  "CLINK": "https://assets.coingecko.com/coins/images/23943/thumb/iShot2022-02-25_14.35.54.png",
  "KYL": "https://assets.coingecko.com/coins/images/14164/thumb/kyl_logo.jpg",
  "EVN": "https://assets.coingecko.com/coins/images/1547/thumb/envion.jpg",
  "MINT": "https://assets.coingecko.com/coins/images/21/thumb/MintCoin-128.png",
  "ABYSS": "https://assets.coingecko.com/coins/images/2105/thumb/NrFmpxs.png",
  "REF": "https://assets.coingecko.com/coins/images/2575/thumb/reftoken.png",
  "GZIL": "https://assets.coingecko.com/coins/images/13410/thumb/gzil.png",
  "PROB": "https://assets.coingecko.com/coins/images/6765/thumb/ProBit-Exchange-logo.png",
  "KALM": "https://assets.coingecko.com/coins/images/15849/thumb/kalmar.png",
  "TARA": "https://assets.coingecko.com/coins/images/4372/thumb/CPuCDZX.jpg",
  "SFI": "https://assets.coingecko.com/coins/images/28357/thumb/strx.png",
  "OMAX": "https://assets.coingecko.com/coins/images/19853/thumb/omxlogo.png",
  "TRG": "https://assets.coingecko.com/coins/images/28750/thumb/TRG_Logo_Circular_200px.png",
  "SIENNA": "https://assets.coingecko.com/coins/images/15196/thumb/608f130b2f3aa2c29957c608_sienna-200x200.png",
  "$NMKR": "https://assets.coingecko.com/coins/images/25183/thumb/W0Ub7F39_400x400.jpg",
  "WPP": "https://assets.coingecko.com/coins/images/8103/thumb/WzdD53fh_400x400.jpg",
  "LIME": "https://assets.coingecko.com/coins/images/16243/thumb/lim_200.2.png",
  "SAFEMARS": "https://assets.coingecko.com/coins/images/14451/thumb/MARTIAN.",
  "ONIGI": "https://assets.coingecko.com/coins/images/28673/thumb/ONIGI.png",
  "NAV": "https://assets.coingecko.com/coins/images/233/thumb/Navcoin_Logo.png",
  "MTC": "https://assets.coingecko.com/coins/images/9391/thumb/WgwrooU9_400x400.jpg",
  "STAT": "https://assets.coingecko.com/coins/images/26602/thumb/stat.png",
  "OPT2": "https://assets.coingecko.com/coins/images/27187/thumb/optimus-logo-03.png",
  "MOBI": "https://assets.coingecko.com/coins/images/18467/thumb/MOBI-200.png",
  "PAID": "https://assets.coingecko.com/coins/images/13761/thumb/512.png",
  "CTI": "https://assets.coingecko.com/coins/images/13266/thumb/CTI.png",
  "RVLT": "https://assets.coingecko.com/coins/images/26360/thumb/revolt2earn_32.png",
  "BSX": "https://assets.coingecko.com/coins/images/26810/thumb/bsx.png",
  "HART": "https://assets.coingecko.com/coins/images/5138/thumb/hara.jpg",
  "GSWAP": "https://assets.coingecko.com/coins/images/13026/thumb/gameswap.jpg",
  "VSP": "https://assets.coingecko.com/coins/images/13527/thumb/vesper_logo.jpg",
  "RPG": "https://assets.coingecko.com/coins/images/18481/thumb/HQ3.png",
  "LEGO": "https://assets.coingecko.com/coins/images/27545/thumb/jP0kpqR6_400x400.jpeg",
  "POR": "https://assets.coingecko.com/coins/images/21567/thumb/coin-icon.png",
  "BLT": "https://assets.coingecko.com/coins/images/25951/thumb/101215144.png",
  "TAROT": "https://assets.coingecko.com/coins/images/17881/thumb/tarot-200px.png",
  "VSYS": "https://assets.coingecko.com/coins/images/7492/thumb/VSYS_Coin_200.png",
  "TRONPAD": "https://assets.coingecko.com/coins/images/16066/thumb/weUUiy33_400x400.jpg",
  "DOP": "https://assets.coingecko.com/coins/images/14826/thumb/logo-200x200_%282%29.png",
  "DTX": "https://assets.coingecko.com/coins/images/4898/thumb/digital-ticks-logo.jpg",
  "HOO": "https://assets.coingecko.com/coins/images/12968/thumb/LOGO1.jpeg",
  "DINO": "https://assets.coingecko.com/coins/images/24191/thumb/Logo_200x200.png",
  "$WEAPON": "https://assets.coingecko.com/coins/images/20833/thumb/weapon.png",
  "EOSDT": "https://assets.coingecko.com/coins/images/8542/thumb/eosdt.png",
  "BYTZ": "https://assets.coingecko.com/coins/images/4017/thumb/bytz.png",
  "SOLID": "https://assets.coingecko.com/coins/images/22100/thumb/logo.png",
  "BCUBE": "https://assets.coingecko.com/coins/images/15948/thumb/bcube.PNG",
  "VERSE": "https://assets.coingecko.com/coins/images/18946/thumb/verse_200.png",
  "OTO": "https://assets.coingecko.com/coins/images/7447/thumb/oto.png",
  "RELAY": "https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png",
  "SMBR": "https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png",
  "ZMN": "https://assets.coingecko.com/coins/images/2103/thumb/zmn.png",
  "BTCMT": "https://assets.coingecko.com/coins/images/21819/thumb/MNVvqe2n_400x400.png",
  "LIQ": "https://assets.coingecko.com/coins/images/16534/thumb/85853665.png",
  "DPAY": "https://assets.coingecko.com/coins/images/26530/thumb/paydexlogo.png",
  "BIP": "https://assets.coingecko.com/coins/images/21587/thumb/logo_-_2021-12-15T120333.709.png",
  "STACK": "https://assets.coingecko.com/coins/images/14218/thumb/stack.jpg",
  "SPE": "https://assets.coingecko.com/coins/images/19759/thumb/spe.png",
  "VIRES": "https://assets.coingecko.com/coins/images/19205/thumb/VF_Vtoken200.png",
  "MTRM": "https://assets.coingecko.com/coins/images/24613/thumb/mtrm.png",
  "APW": "https://assets.coingecko.com/coins/images/15597/thumb/ApWine.png",
  "FKX": "https://assets.coingecko.com/coins/images/5050/thumb/fkx.png",
  "SAN": "https://assets.coingecko.com/coins/images/27667/thumb/Asset_51_4x.png",
  "BBANK": "https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg.png",
  "EQUAL": "https://assets.coingecko.com/coins/images/28231/thumb/hq_png_icon_file.png",
  "EPK": "https://assets.coingecko.com/coins/images/15188/thumb/epk.PNG",
  "SUTER": "https://assets.coingecko.com/coins/images/9830/thumb/p-NFlBlw_400x400.jpg",
  "OBOT": "https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png",
  "OGV": "https://assets.coingecko.com/coins/images/26353/thumb/ogv-200x200.png",
  "BORING": "https://assets.coingecko.com/coins/images/16429/thumb/Tjq3pXEH_400x400.jpg",
  "WIT": "https://assets.coingecko.com/coins/images/6420/thumb/wit-logo.png",
  "STIMA": "https://assets.coingecko.com/coins/images/26101/thumb/STIMA_200x200.png",
  "VERSA": "https://assets.coingecko.com/coins/images/25491/thumb/versa_token_96_96.png",
  "UMB": "https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png",
  "USP": "https://assets.coingecko.com/coins/images/26322/thumb/usp-200px.png",
  "PING": "https://assets.coingecko.com/coins/images/16463/thumb/sonar_logo-200.png",
  "PMR": "https://assets.coingecko.com/coins/images/25854/thumb/pomerium-PMR_2000x2000.png",
  "HAUS": "https://assets.coingecko.com/coins/images/14551/thumb/jN3kkqke_400x400.png",
  "SNC": "https://assets.coingecko.com/coins/images/1166/thumb/suncontract.png",
  "YAM": "https://assets.coingecko.com/coins/images/12530/thumb/YAM-icon.png",
  "SQUAD": "https://assets.coingecko.com/coins/images/28466/thumb/SQUAD200X200.png",
  "QUARTZ": "https://assets.coingecko.com/coins/images/19368/thumb/sandclock.jpg",
  "SPIRIT": "https://assets.coingecko.com/coins/images/15118/thumb/token-icon_m.png",
  "HDP.Ф": "https://assets.coingecko.com/coins/images/7496/thumb/icon_hedpay.png",
  "VTX": "https://assets.coingecko.com/coins/images/6558/thumb/1_jFAdfyrgeT7g6ARwGeh3PQ.png",
  "PACT": "https://assets.coingecko.com/coins/images/15265/thumb/pact.PNG",
  "WEBD": "https://assets.coingecko.com/coins/images/8024/thumb/logo-coin.png",
  "CUMMIES": "https://assets.coingecko.com/coins/images/15088/thumb/cr.png",
  "SAFE": "https://assets.coingecko.com/coins/images/26604/thumb/logo.png",
  "XCUR": "https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png",
  "PORTX": "https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png",
  "TRYB": "https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg",
  "ROME": "https://assets.coingecko.com/coins/images/21078/thumb/rome-dao.jpeg",
  "BFR": "https://assets.coingecko.com/coins/images/18540/thumb/Qk6pjeZ3_400x400.jpg",
  "C3": "https://assets.coingecko.com/coins/images/15052/thumb/download_%288%29.png",
  "CGT": "https://assets.coingecko.com/coins/images/27947/thumb/cgt.png",
  "PPT": "https://assets.coingecko.com/coins/images/28980/thumb/logo_200.jpeg",
  "HTB": "https://assets.coingecko.com/coins/images/5990/thumb/hotbit-token.png",
  "PATH": "https://assets.coingecko.com/coins/images/24795/thumb/path.png",
  "TULIP": "https://assets.coingecko.com/coins/images/15764/thumb/TqrUdBG.png",
  "ECC": "https://assets.coingecko.com/coins/images/20287/thumb/14404.png",
  "TEMP": "https://assets.coingecko.com/coins/images/20547/thumb/Tempus_CoinGecko_200x200.png",
  "TEN": "https://assets.coingecko.com/coins/images/23856/thumb/18320.png",
  "BSTY": "https://assets.coingecko.com/coins/images/73/thumb/boost.png",
  "TMG": "https://assets.coingecko.com/coins/images/28117/thumb/tmg.png",
  "DPET": "https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG",
  "CNHT": "https://assets.coingecko.com/coins/images/5251/thumb/cny-tether-logo.png",
  "NGC": "https://assets.coingecko.com/coins/images/1439/thumb/naga.jpg",
  "MATTER": "https://assets.coingecko.com/coins/images/14112/thumb/antimatter_icon.png",
  "BLUESPARROW": "https://assets.coingecko.com/coins/images/19995/thumb/bluesparrow.png",
  "SDL": "https://assets.coingecko.com/coins/images/28406/thumb/stake.png",
  "TOWER": "https://assets.coingecko.com/coins/images/14134/thumb/tower-circular-1000.png",
  "EEUR": "https://assets.coingecko.com/coins/images/18817/thumb/Currencies-Countries-Square-EEUR.png",
  "MCHC": "https://assets.coingecko.com/coins/images/15399/thumb/MCHC.jpg",
  "YOU": "https://assets.coingecko.com/coins/images/25353/thumb/youwho_200.png",
  "ENQ": "https://assets.coingecko.com/coins/images/4749/thumb/enq.png",
  "WOMBAT": "https://assets.coingecko.com/coins/images/26430/thumb/Project_Page_Icon.png",
  "EFX": "https://assets.coingecko.com/coins/images/3012/thumb/logomark_effect-network.png",
  "XCAL": "https://assets.coingecko.com/coins/images/28134/thumb/XCAL_Logo.png",
  "UNDEAD": "https://assets.coingecko.com/coins/images/19548/thumb/200x_200.png",
  "BETU": "https://assets.coingecko.com/coins/images/18184/thumb/BETU_Token_-_200x200.png",
  "MAHA": "https://assets.coingecko.com/coins/images/13404/thumb/MAHA_Token.png",
  "OS": "https://assets.coingecko.com/coins/images/19592/thumb/newOS.png",
  "YAK": "https://assets.coingecko.com/coins/images/17654/thumb/yieldyak.png",
  "GMR": "https://assets.coingecko.com/coins/images/6693/thumb/gimmer_token.jpg",
  "ASM": "https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg",
  "POLC": "https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png",
  "SVS": "https://assets.coingecko.com/coins/images/16436/thumb/9UbufKC.png",
  "WACME": "https://assets.coingecko.com/coins/images/27207/thumb/accumulate-logo-200x200.png",
  "SWRV": "https://assets.coingecko.com/coins/images/12361/thumb/swerve.png",
  "THT": "https://assets.coingecko.com/coins/images/3071/thumb/IeNAK31H_400x400.jpg",
  "TETHYS": "https://assets.coingecko.com/coins/images/22141/thumb/wjoZJvzA_400x400.jpg",
  "MTH": "https://assets.coingecko.com/coins/images/902/thumb/LOGO-MTH-200-200.png",
  "MOVE": "https://assets.coingecko.com/coins/images/28917/thumb/BlueMoveCoin.png",
  "ARA": "https://assets.coingecko.com/coins/images/13877/thumb/ara-icon-200.png",
  "DUSD": "https://assets.coingecko.com/coins/images/24343/thumb/Untitled_%285_%C3%97_3_cm%29_%282%29.png",
  "BUILD": "https://assets.coingecko.com/coins/images/26932/thumb/Screen-Shot-2022-08-18-at-12-15-03-PM_%281%29.png",
  "BAO": "https://assets.coingecko.com/coins/images/28287/thumb/BAOv2_128px.png",
  "POOL": "https://assets.coingecko.com/coins/images/14003/thumb/PoolTogether.png",
  "CYBER": "https://assets.coingecko.com/coins/images/28924/thumb/cc-logo.png",
  "JUR": "https://assets.coingecko.com/coins/images/9714/thumb/JUL.jpeg",
  "SAVG": "https://assets.coingecko.com/coins/images/22750/thumb/savg_logo.jpg",
  "WOW": "https://assets.coingecko.com/coins/images/15412/thumb/wow.png",
  "ALPH": "https://assets.coingecko.com/coins/images/21598/thumb/Alephium-Logo_200x200_listing.png",
  "UFC": "https://assets.coingecko.com/coins/images/18534/thumb/nDcBVdzyEUpcUOjCKzgTH5VihLJpWRhqhyinhX6Z.png",
  "CND": "https://assets.coingecko.com/coins/images/1006/thumb/cindicator.png",
  "BLN": "https://assets.coingecko.com/coins/images/26797/thumb/BLN_Logo.png",
  "O3": "https://assets.coingecko.com/coins/images/15460/thumb/o3.png",
  "LGCY": "https://assets.coingecko.com/coins/images/12181/thumb/LGCY_network.jpg",
  "MIST": "https://assets.coingecko.com/coins/images/14841/thumb/pKmPjCN-_200x200.jpg",
  "WGC": "https://assets.coingecko.com/coins/images/17776/thumb/Green_Climate.png",
  "XOLO": "https://assets.coingecko.com/coins/images/28567/thumb/photo_2022-12-15_22-39-03.jpg",
  "MANDOX": "https://assets.coingecko.com/coins/images/24968/thumb/NmqN8g7.png",
  "MEDIA": "https://assets.coingecko.com/coins/images/15142/thumb/media50x50.png",
  "LIKE": "https://assets.coingecko.com/coins/images/17501/thumb/like-token.png",
  "AART": "https://assets.coingecko.com/coins/images/22012/thumb/all-art.PNG",
  "PET": "https://assets.coingecko.com/coins/images/21828/thumb/Peace_v2_-_5.png",
  "EQX": "https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png",
  "OOKS": "https://assets.coingecko.com/coins/images/16281/thumb/onooks-logo.png",
  "BELT": "https://assets.coingecko.com/coins/images/14319/thumb/belt_logo.jpg",
  "NGL": "https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg",
  "MENGO": "https://assets.coingecko.com/coins/images/19461/thumb/0hKQH2Cd1W3QliKBSfTdtE9yzAAuSRWhO3Ny985c.png",
  "ZOO": "https://assets.coingecko.com/coins/images/17269/thumb/zoo.PNG",
  "BRWL": "https://assets.coingecko.com/coins/images/24701/thumb/fuDdvI8Y_400x400.png",
  "ROCO": "https://assets.coingecko.com/coins/images/19234/thumb/86109466.png",
  "HERMES": "https://assets.coingecko.com/coins/images/22124/thumb/dwp9keQD_400x400.jpg",
  "FEAR": "https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png",
  "VCF": "https://assets.coingecko.com/coins/images/18362/thumb/U4MF3fmF1ZJ9r5iqWyNVKbwPpemWWlN6T4wgtX7A.png",
  "CLOAK": "https://assets.coingecko.com/coins/images/27483/thumb/IMG_20220926_114311_979.jpg",
  "RADIO": "https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg",
  "1WO": "https://assets.coingecko.com/coins/images/3443/thumb/unnamed.png",
  "FLAME": "https://assets.coingecko.com/coins/images/17359/thumb/WhiteOnBlack_Primary_Logo.png",
  "SIS": "https://assets.coingecko.com/coins/images/20805/thumb/SymbiosisFinance_logo-150x150.jpeg",
  "MINX": "https://assets.coingecko.com/coins/images/7116/thumb/innovaminex.jpg",
  "NHT": "https://assets.coingecko.com/coins/images/22055/thumb/social_media_logo_black_bg.png",
  "LUA": "https://assets.coingecko.com/coins/images/12627/thumb/Screenshot_2020-09-28_at_6.24.59_PM.jpg",
  "TUT": "https://assets.coingecko.com/coins/images/22007/thumb/exiled.2e9d4c91.png",
  "NAVI": "https://assets.coingecko.com/coins/images/9952/thumb/pxXwSG_J_400x400.jpg",
  "DDIM": "https://assets.coingecko.com/coins/images/12146/thumb/token_DDIM-01.png",
  "DSM": "https://assets.coingecko.com/coins/images/22610/thumb/dsm.png",
  "DOGA": "https://assets.coingecko.com/coins/images/24510/thumb/doga_logo.png",
  "SKY": "https://assets.coingecko.com/coins/images/28037/thumb/sky.png",
  "BPT": "https://assets.coingecko.com/coins/images/25112/thumb/LogoToken.png",
  "XWIN": "https://assets.coingecko.com/coins/images/15400/thumb/200x200_%28transparent_background%29.png",
  "CWS": "https://assets.coingecko.com/coins/images/13835/thumb/crowns_logo.png",
  "DEFIT": "https://assets.coingecko.com/coins/images/14699/thumb/Defit.png",
  "SONNE": "https://assets.coingecko.com/coins/images/27540/thumb/Token1.png",
  "TKN": "https://assets.coingecko.com/coins/images/21623/thumb/logo-coin-tkn.png",
  "CHG": "https://assets.coingecko.com/coins/images/2159/thumb/charg-coin.png",
  "KCCPAD": "https://assets.coingecko.com/coins/images/16994/thumb/kcc.png",
  "LCC": "https://assets.coingecko.com/coins/images/3138/thumb/LCC.png",
  "KCAL": "https://assets.coingecko.com/coins/images/25447/thumb/Screenshot_from_2022-05-06_09-46-03.png",
  "OJA": "https://assets.coingecko.com/coins/images/18947/thumb/ojamu-icon-PNK.png",
  "GAIA": "https://assets.coingecko.com/coins/images/19629/thumb/gaia.png",
  "PAR": "https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png",
  "NFTART": "https://assets.coingecko.com/coins/images/15027/thumb/nft-art-coingecko-logo.png",
  "SRK": "https://assets.coingecko.com/coins/images/8371/thumb/SRK.png",
  "MARS4": "https://assets.coingecko.com/coins/images/18709/thumb/mars4_logo.jpg",
  "BCDT": "https://assets.coingecko.com/coins/images/2014/thumb/evidenz-512.png",
  "SRCX": "https://assets.coingecko.com/coins/images/25898/thumb/80368b_7b0a30441d7c4134a35067553ef6de3e_mv2_%281%29.png",
  "PMON": "https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png",
  "ETHPAD": "https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg",
  "CPD": "https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG",
  "LA": "https://assets.coingecko.com/coins/images/1051/thumb/LA_token.png",
  "GOZ": "https://assets.coingecko.com/coins/images/15129/thumb/uPBcuAuWYBYPrMIf39FRfPyTXjnJR9B9rxOwIzqM.png",
  "HBB": "https://assets.coingecko.com/coins/images/22070/thumb/hubble.PNG",
  "Z7": "https://assets.coingecko.com/coins/images/25962/thumb/z7.png",
  "MHC": "https://assets.coingecko.com/coins/images/5114/thumb/mhc_1024.png",
  "STRONG": "https://assets.coingecko.com/coins/images/12092/thumb/STRONG-Token-256x256.png",
  "MPH": "https://assets.coingecko.com/coins/images/12619/thumb/morpher_200_200.png",
  "COC": "https://assets.coingecko.com/coins/images/13121/thumb/coc.png",
  "BST": "https://assets.coingecko.com/coins/images/7335/thumb/bitsten.png",
  "EFL": "https://assets.coingecko.com/coins/images/234/thumb/efl.jpg",
  "GST-BSC": "https://assets.coingecko.com/coins/images/25484/thumb/gst.png",
  "EDE": "https://assets.coingecko.com/coins/images/28450/thumb/ede.png",
  "ERSDL": "https://assets.coingecko.com/coins/images/12975/thumb/logo_eRSDL.png",
  "IXS": "https://assets.coingecko.com/coins/images/18069/thumb/ixswap.PNG",
  "NEARX": "https://assets.coingecko.com/coins/images/27787/thumb/Nearx.png",
  "REVU": "https://assets.coingecko.com/coins/images/17902/thumb/200x200___krug_sredina.png",
  "IDLE": "https://assets.coingecko.com/coins/images/24019/thumb/pZgR4golsHfj-Lmw79XULWbZ7K_F7nr0C_jq2CygrCx9j-Fd9MRl8tbxQxbetJZfAB5dj2puRgExlecOjdKObRGXrC1-KmDBe17ckcLFzcIctoP330NNkTrpT_mndu4cEyp6LU9yDZN4rKbFVbM-Qn3ZAJRkFDD46piUrfPkfVsSQ8d8hlZ1Ay6vUUU7aMnpOsOqYUrORW80od.jpg",
  "GHOST": "https://assets.coingecko.com/coins/images/21888/thumb/444z.png",
  "MM": "https://assets.coingecko.com/coins/images/12941/thumb/mm%EB%A1%9C%EA%B3%A0-2.png",
  "SRY": "https://assets.coingecko.com/coins/images/16260/thumb/serey.png",
  "PMGT": "https://assets.coingecko.com/coins/images/10730/thumb/pmgt_logo_256x256.png",
  "RET": "https://assets.coingecko.com/coins/images/5955/thumb/200-01.png",
  "REVO": "https://assets.coingecko.com/coins/images/25892/thumb/revo.jpg",
  "8PAY": "https://assets.coingecko.com/coins/images/14667/thumb/8pay.jpeg",
  "LIFE": "https://assets.coingecko.com/coins/images/22268/thumb/divita.PNG",
  "CONV": "https://assets.coingecko.com/coins/images/14519/thumb/Convergence_Logo_%28Final%29.png",
  "1ART": "https://assets.coingecko.com/coins/images/19307/thumb/token_light_3x.png",
  "SIN": "https://assets.coingecko.com/coins/images/6552/thumb/sinovate.png",
  "LOV": "https://assets.coingecko.com/coins/images/13023/thumb/lovechain.png",
  "TAU": "https://assets.coingecko.com/coins/images/21394/thumb/atlastnsis.PNG",
  "BREED": "https://assets.coingecko.com/coins/images/25203/thumb/BreederDAO-Breed_Token-FINAL.png",
  "EVC": "https://assets.coingecko.com/coins/images/14281/thumb/evc-round.png",
  "MITH": "https://assets.coingecko.com/coins/images/3484/thumb/mithril.png",
  "TRUBGR": "https://assets.coingecko.com/coins/images/16398/thumb/trubadger_logo.PNG",
  "RBC": "https://assets.coingecko.com/coins/images/17494/thumb/IMG-20210802-213909-942-removebg-preview.png",
  "MIA": "https://assets.coingecko.com/coins/images/28281/thumb/MIA_200x200.png",
  "AIRI": "https://assets.coingecko.com/coins/images/16428/thumb/alright.PNG",
  "BTNTV2": "https://assets.coingecko.com/coins/images/2875/thumb/Logobit.jpg",
  "ZEE": "https://assets.coingecko.com/coins/images/12861/thumb/logo.",
  "NFTY": "https://assets.coingecko.com/coins/images/18584/thumb/nfty_logo.png",
  "TIDAL": "https://assets.coingecko.com/coins/images/14460/thumb/Tidal-mono.png",
  "FODL": "https://assets.coingecko.com/coins/images/19040/thumb/Fodl_Symbol_Gradient.png",
  "DUCK": "https://assets.coingecko.com/coins/images/18346/thumb/duck.png",
  "SVN": "https://assets.coingecko.com/coins/images/23692/thumb/SVN_LOGO.png",
  "CPC": "https://assets.coingecko.com/coins/images/24471/thumb/cpc.png",
  "OLT": "https://assets.coingecko.com/coins/images/3422/thumb/One_Ledger.jpeg",
  "TRADE": "https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png",
  "PILOT": "https://assets.coingecko.com/coins/images/17235/thumb/QmSjCnb74Q88o9gcTMMYNggj6BDkFEPukAZ5nWrhPaRxoW.png",
  "ABL": "https://assets.coingecko.com/coins/images/4393/thumb/airbloc-protocol-logo.png",
  "PRIMATE": "https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png",
  "XEND": "https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png",
  "DIO": "https://assets.coingecko.com/coins/images/8271/thumb/dio_logo_coloured_transparent.png",
  "IDNA": "https://assets.coingecko.com/coins/images/10548/thumb/idena200x200.png",
  "MFG": "https://assets.coingecko.com/coins/images/1842/thumb/Smart_MFG_Cropped_Logo.png",
  "DAX": "https://assets.coingecko.com/coins/images/4063/thumb/daex.png",
  "MSHARE": "https://assets.coingecko.com/coins/images/23400/thumb/mshare.png",
  "EXNT": "https://assets.coingecko.com/coins/images/12328/thumb/exnt_logo.png",
  "SKU": "https://assets.coingecko.com/coins/images/17830/thumb/sakura.png",
  "ETP": "https://assets.coingecko.com/coins/images/1086/thumb/metaverse.jpg",
  "SHROOM": "https://assets.coingecko.com/coins/images/12341/thumb/niftyx_logo.jpg",
  "AMKT": "https://assets.coingecko.com/coins/images/28496/thumb/AMKT-Circle.png",
  "BTCZ": "https://assets.coingecko.com/coins/images/1004/thumb/BTCZ_LOGO-1.png",
  "KOL": "https://assets.coingecko.com/coins/images/27855/thumb/logo_200_200.png",
  "RXD": "https://assets.coingecko.com/coins/images/27382/thumb/Radiant_new_circle.png",
  "LYM": "https://assets.coingecko.com/coins/images/2620/thumb/lympo_token.png",
  "ONES": "https://assets.coingecko.com/coins/images/12552/thumb/logo.png",
  "TXA": "https://assets.coingecko.com/coins/images/17746/thumb/jwjcN5Kx_400x400.jpg",
  "LOCG": "https://assets.coingecko.com/coins/images/15175/thumb/LOC_GAME.png",
  "LAYER": "https://assets.coingecko.com/coins/images/12164/thumb/logo-layer.jpg",
  "CNFI": "https://assets.coingecko.com/coins/images/13592/thumb/cf-logo-iconic-black.png",
  "DINGO": "https://assets.coingecko.com/coins/images/16315/thumb/tzI4QPI7_400x400.png",
  "KOM": "https://assets.coingecko.com/coins/images/17483/thumb/1_f1S3h57YLT1e1cl8g7RJpw_2x.jpeg",
  "HIRAM": "https://assets.coingecko.com/coins/images/23457/thumb/logo_%281%29.png",
  "AAG": "https://assets.coingecko.com/coins/images/21382/thumb/aag-token-icon.png",
  "PAY": "https://assets.coingecko.com/coins/images/24175/thumb/logo.png",
  "LED": "https://assets.coingecko.com/coins/images/16144/thumb/photo-2021-06-05-07-45-06.jpg",
  "IBBTC": "https://assets.coingecko.com/coins/images/15500/thumb/ibbtc.png",
  "AMPLIFI": "https://assets.coingecko.com/coins/images/26869/thumb/amplifi.jpg",
  "JDB": "https://assets.coingecko.com/coins/images/28105/thumb/new_logo.png",
  "NEOX": "https://assets.coingecko.com/coins/images/26417/thumb/Logo-Round_%281%29.png",
  "SNTVT": "https://assets.coingecko.com/coins/images/7383/thumb/2x9veCp.png",
  "HZN": "https://assets.coingecko.com/coins/images/14795/thumb/horizon-logo-200-cg.png",
  "BANK": "https://assets.coingecko.com/coins/images/14116/thumb/FLOAT-Bank_LOGO-reduced_01.png",
  "FCL": "https://assets.coingecko.com/coins/images/14096/thumb/fractal_logo.png",
  "MORK": "https://assets.coingecko.com/coins/images/12183/thumb/mork-logo.png",
  "PAC": "https://assets.coingecko.com/coins/images/808/thumb/PAC-teal-icon.png",
  "TXL": "https://assets.coingecko.com/coins/images/12432/thumb/txl.png",
  "NYC": "https://assets.coingecko.com/coins/images/693/thumb/nyc_logo_wallet_2018.png",
  "MNG": "https://assets.coingecko.com/coins/images/25997/thumb/41625-E26-037-E-430-D-B125-DB02558-C5024.png",
  "USDS": "https://assets.coingecko.com/coins/images/7596/thumb/Stably_USDS_-_Icon_%28Standard%29.png",
  "CARDS": "https://assets.coingecko.com/coins/images/14612/thumb/CARDSx200.png",
  "GLO": "https://assets.coingecko.com/coins/images/9237/thumb/ItBMqQKt_400x400.jpg",
  "LNR": "https://assets.coingecko.com/coins/images/20446/thumb/rsz_lunarprofileiconcircleblack_1.png",
  "CZRX": "https://assets.coingecko.com/coins/images/10644/thumb/czrx1.JPG",
  "VEX": "https://assets.coingecko.com/coins/images/15793/thumb/vex.png",
  "YFIII": "https://assets.coingecko.com/coins/images/12628/thumb/zNTAjrF.png",
  "BFT": "https://assets.coingecko.com/coins/images/28355/thumb/BFT_LOGO_200X200.png",
  "DCAU": "https://assets.coingecko.com/coins/images/20226/thumb/7f7e57.jpeg",
  "XTT-B20": "https://assets.coingecko.com/coins/images/17921/thumb/XTblock-Icon-round-200.png",
  "ROX": "https://assets.coingecko.com/coins/images/3153/thumb/rox.png",
  "VENT": "https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png",
  "RBIS": "https://assets.coingecko.com/coins/images/21906/thumb/imgonline-com-ua-Resize-cMjOYOwg12bLazt.png",
  "IST": "https://assets.coingecko.com/coins/images/28273/thumb/Copy_of_07-Inter-Protocol-logo-symbol-color_200x200.png",
  "WAM": "https://assets.coingecko.com/coins/images/21576/thumb/KrJueEpI_400x400.jpg",
  "CLAM": "https://assets.coingecko.com/coins/images/230/thumb/clams.png",
  "NETT": "https://assets.coingecko.com/coins/images/22262/thumb/netswpa.PNG",
  "IDAI": "https://assets.coingecko.com/coins/images/25821/thumb/iDAI_100x100.png",
  "INTR": "https://assets.coingecko.com/coins/images/26180/thumb/Interlay-Coinbase-2.png",
  "RHYTHM": "https://assets.coingecko.com/coins/images/17698/thumb/rhythm.png",
  "0XBTC": "https://assets.coingecko.com/coins/images/4454/thumb/0xbtc.png",
  "ASIA": "https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png",
  "XTK": "https://assets.coingecko.com/coins/images/14089/thumb/xtk.png",
  "1FLR": "https://assets.coingecko.com/coins/images/19114/thumb/flr.png",
  "SCROOGE": "https://assets.coingecko.com/coins/images/21510/thumb/top-hat-200x200.png",
  "CERES": "https://assets.coingecko.com/coins/images/17959/thumb/sQLDgqx.png",
  "RAIDER": "https://assets.coingecko.com/coins/images/17638/thumb/Ue3Hfh8.png",
  "XOT": "https://assets.coingecko.com/coins/images/25473/thumb/xot.png",
  "EMC2": "https://assets.coingecko.com/coins/images/46/thumb/einsteinium.png",
  "ROSN": "https://assets.coingecko.com/coins/images/15478/thumb/Roseon_Finance.png",
  "TNB": "https://assets.coingecko.com/coins/images/1265/thumb/time-new-bank.png",
  "FWC": "https://assets.coingecko.com/coins/images/24258/thumb/IMG_20221202_222442_239.jpg",
  "TKP": "https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png",
  "UNV": "https://assets.coingecko.com/coins/images/18119/thumb/unvest.PNG",
  "UNIC": "https://assets.coingecko.com/coins/images/14720/thumb/Unicly.png",
  "NFTD": "https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png",
  "PICKLE": "https://assets.coingecko.com/coins/images/12435/thumb/0M4W6Yr6_400x400.jpg",
  "KRIPTO": "https://assets.coingecko.com/coins/images/25637/thumb/kripto_token.png",
  "IQN": "https://assets.coingecko.com/coins/images/1736/thumb/IQN_logo_64x64.png",
  "MTA": "https://assets.coingecko.com/coins/images/11846/thumb/mStable.png",
  "DASHD": "https://assets.coingecko.com/coins/images/9628/thumb/DASHD.jpg",
  "ELK": "https://assets.coingecko.com/coins/images/17813/thumb/elk.png",
  "BTC2X-FLI": "https://assets.coingecko.com/coins/images/15406/thumb/Copy_of_BTC2x-FLI_token_logo.png",
  "CWT": "https://assets.coingecko.com/coins/images/24201/thumb/Crown_Token_JPG_%282%29.jpg",
  "SMI": "https://assets.coingecko.com/coins/images/16091/thumb/SMI.png",
  "BOG": "https://assets.coingecko.com/coins/images/15980/thumb/bog.png",
  "NFTL": "https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG",
  "PEBBLE": "https://assets.coingecko.com/coins/images/17963/thumb/Screen-Shot-2021-08-25-at-8-42-22-PM.png",
  "ALBT": "https://assets.coingecko.com/coins/images/12392/thumb/alliance_block_logo.jpg",
  "OLY": "https://assets.coingecko.com/coins/images/13983/thumb/oly-logo.png",
  "DBL": "https://assets.coingecko.com/coins/images/27642/thumb/6lCO7C9y_400x400.jpeg",
  "BAI": "https://assets.coingecko.com/coins/images/25385/thumb/BAI_Token_Logo.png",
  "TIPS": "https://assets.coingecko.com/coins/images/35/thumb/fedoracoin.png",
  "VXL": "https://assets.coingecko.com/coins/images/21419/thumb/Voxel-Logo-200x200-Transparent.png",
  "PERC": "https://assets.coingecko.com/coins/images/23302/thumb/PERC.png",
  "VCG": "https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png",
  "MGH": "https://assets.coingecko.com/coins/images/20625/thumb/mgh.PNG",
  "SLAM": "https://assets.coingecko.com/coins/images/15023/thumb/slam2-0-logo.png",
  "FIAT": "https://assets.coingecko.com/coins/images/25477/thumb/fiat.png",
  "KINGSHIB": "https://assets.coingecko.com/coins/images/19215/thumb/kingshib.png",
  "STRNGR": "https://assets.coingecko.com/coins/images/24922/thumb/200x200_logo.png",
  "DCN": "https://assets.coingecko.com/coins/images/850/thumb/dentacoin.png",
  "IDV": "https://assets.coingecko.com/coins/images/14229/thumb/71922199.png",
  "ACT": "https://assets.coingecko.com/coins/images/1147/thumb/achain-logo.png",
  "USDEX": "https://assets.coingecko.com/coins/images/25748/thumb/USDEX_200x200_%281%29.png",
  "CHE": "https://assets.coingecko.com/coins/images/17502/thumb/cherry.PNG",
  "MUE": "https://assets.coingecko.com/coins/images/170/thumb/monetaryunit.png",
  "STARSHIP": "https://assets.coingecko.com/coins/images/15580/thumb/starship.png",
  "SHIELD": "https://assets.coingecko.com/coins/images/24286/thumb/M_x-9DyT_400x400.png",
  "BIRD": "https://assets.coingecko.com/coins/images/1994/thumb/MLSILOZA_400x400.png",
  "ELT": "https://assets.coingecko.com/coins/images/13715/thumb/logo_-_2021-01-20T105016.992.png",
  "JET": "https://assets.coingecko.com/coins/images/25547/thumb/photo-2022-05-04-17-14-27.jpg",
  "SBR": "https://assets.coingecko.com/coins/images/17162/thumb/oYs_YFz8_400x400.jpg",
  "SFIL": "https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg",
  "SN": "https://assets.coingecko.com/coins/images/27576/thumb/sn.png",
  "STANDARD": "https://assets.coingecko.com/coins/images/20119/thumb/stquY-WB_400x400.jpg",
  "INT": "https://assets.coingecko.com/coins/images/2519/thumb/int-logo-red-transparent_200x200.png",
  "DWEB": "https://assets.coingecko.com/coins/images/18971/thumb/dweb-logo-transparent.png",
  "NBS": "https://assets.coingecko.com/coins/images/12532/thumb/Screenshot_2.png",
  "CS": "https://assets.coingecko.com/coins/images/1566/thumb/cs.png",
  "GIV": "https://assets.coingecko.com/coins/images/8996/thumb/giv.png",
  "RES": "https://assets.coingecko.com/coins/images/10026/thumb/logo_%281%29.png",
  "LPOOL": "https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg",
  "XYZ": "https://assets.coingecko.com/coins/images/15809/thumb/universexyz.png",
  "FREE": "https://assets.coingecko.com/coins/images/21648/thumb/free.jpg",
  "BULL": "https://assets.coingecko.com/coins/images/27581/thumb/bull.png",
  "NAS": "https://assets.coingecko.com/coins/images/2431/thumb/193394331.png",
  "RDN": "https://assets.coingecko.com/coins/images/1132/thumb/raiden-logo.jpg",
  "UBQ": "https://assets.coingecko.com/coins/images/181/thumb/ubiq.png",
  "XEQ": "https://assets.coingecko.com/coins/images/7478/thumb/XEQ_logo_blackbackground_rounded.png",
  "0NE": "https://assets.coingecko.com/coins/images/25156/thumb/stone.png",
  "PPAY": "https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png",
  "BCMC": "https://assets.coingecko.com/coins/images/19045/thumb/bcmc-coin-200x200.png",
  "APRIL": "https://assets.coingecko.com/coins/images/15689/thumb/200-by-200-01.png",
  "DEXSHARE": "https://assets.coingecko.com/coins/images/25749/thumb/dexSHARE_200x200.png",
  "USV": "https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png",
  "TXAG": "https://assets.coingecko.com/coins/images/27829/thumb/tSILVER_token_2D.jpg",
  "SPEC": "https://assets.coingecko.com/coins/images/17647/thumb/SPEC_200x200.png",
  "STAK": "https://assets.coingecko.com/coins/images/14978/thumb/jigstack.PNG",
  "HGOLD": "https://assets.coingecko.com/coins/images/13652/thumb/QnfGCea3aO_Qb-Gd9n6MSFE_eBVAr87WPoxAzf0gKdcGOEL4K5J6A5cpGinzLvHclj_UHkCnw9XTlGoNYd0H8T_Ebe8voLS49MAZO2NIknCxytaYFtt5_u12RQg-kpmWHcETzAFlDgk9iNXAL-88fB_l-DRi8WEsgSJzXXjztKzQlpXuET6d4_98pykED6uyq1-4PMVg05P7tUN.jpg",
  "FHM": "https://assets.coingecko.com/coins/images/20021/thumb/fhm.png",
  "UFI": "https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG",
  "GTH": "https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png",
  "RVC": "https://assets.coingecko.com/coins/images/11606/thumb/YIe_RSTu_400x400.jpg",
  "CXD": "https://assets.coingecko.com/coins/images/25224/thumb/cortex.jpg",
  "NISHIB": "https://assets.coingecko.com/coins/images/27288/thumb/nishib_logo.png",
  "LABS": "https://assets.coingecko.com/coins/images/26913/thumb/monsta-scientist.jpg",
  "JRIT": "https://assets.coingecko.com/coins/images/19407/thumb/jerit256x256.png",
  "ERR": "https://assets.coingecko.com/coins/images/24031/thumb/ERR-logo.png",
  "ZUSD": "https://assets.coingecko.com/coins/images/17664/thumb/zusd_200.png",
  "IN": "https://assets.coingecko.com/coins/images/6169/thumb/InCoin.png",
  "CHANGE": "https://assets.coingecko.com/coins/images/26487/thumb/Changex-mark-200x200.png",
  "AOG": "https://assets.coingecko.com/coins/images/22133/thumb/aog.PNG",
  "GYRO": "https://assets.coingecko.com/coins/images/19020/thumb/gyro.jpg",
  "UAXIE": "https://assets.coingecko.com/coins/images/14921/thumb/uaxie-mystic.png",
  "BUMP": "https://assets.coingecko.com/coins/images/19301/thumb/babypump.PNG",
  "KALE": "https://assets.coingecko.com/coins/images/28031/thumb/kale.png",
  "LUNAT": "https://assets.coingecko.com/coins/images/26684/thumb/rsz_3-pca17g_400x400.png",
  "KLEE": "https://assets.coingecko.com/coins/images/15548/thumb/Klee-Kai-Logo.png",
  "COMBO": "https://assets.coingecko.com/coins/images/13629/thumb/COMBO_token_ol.png",
  "TRAVA": "https://assets.coingecko.com/coins/images/17553/thumb/TRAVA_OFFICIAL_LOGO.png",
  "AIMX": "https://assets.coingecko.com/coins/images/21316/thumb/photo_2023-01-09_13-07-02.jpg",
  "XRUNE": "https://assets.coingecko.com/coins/images/16835/thumb/thorstarter.jpg",
  "OPCT": "https://assets.coingecko.com/coins/images/7237/thumb/Opacity.jpg",
  "VIS": "https://assets.coingecko.com/coins/images/21245/thumb/VIS.PNG",
  "UNCL": "https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png",
  "NORD": "https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg",
  "IOI": "https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png",
  "WGR": "https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png",
  "DEPAY": "https://assets.coingecko.com/coins/images/13183/thumb/DEPAY.png",
  "UJENNY": "https://assets.coingecko.com/coins/images/15453/thumb/ujenny.png",
  "GRG": "https://assets.coingecko.com/coins/images/1532/thumb/Symbol-RigoblockRGB.png",
  "VRX": "https://assets.coingecko.com/coins/images/13669/thumb/IMG-20210115-000024.png",
  "VAB": "https://assets.coingecko.com/coins/images/17508/thumb/vabble_tplogo_200_x_200.png",
  "PAL": "https://assets.coingecko.com/coins/images/22822/thumb/logo_%283%29.png",
  "ZLK": "https://assets.coingecko.com/coins/images/20884/thumb/zenlink.PNG",
  "LANA": "https://assets.coingecko.com/coins/images/2627/thumb/LanaCoin_logo_v3.png",
  "UBE": "https://assets.coingecko.com/coins/images/15317/thumb/ubeswap.png",
  "GUILD": "https://assets.coingecko.com/coins/images/21271/thumb/BednjMw.png",
  "RFUEL": "https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png",
  "CMFI": "https://assets.coingecko.com/coins/images/22269/thumb/CMFI-Token-Logo-Compendium-Finance.png",
  "KEX": "https://assets.coingecko.com/coins/images/13232/thumb/avatar.png",
  "NABOX": "https://assets.coingecko.com/coins/images/16445/thumb/NyemjVRA_400x400.png",
  "TRACE": "https://assets.coingecko.com/coins/images/23266/thumb/Token_Icon_02.png",
  "IBS": "https://assets.coingecko.com/coins/images/26003/thumb/a.jpg",
  "BRKL": "https://assets.coingecko.com/coins/images/18763/thumb/brkl.png",
  "NFD": "https://assets.coingecko.com/coins/images/17834/thumb/doge-fractionalized.png",
  "GST-ETH": "https://assets.coingecko.com/coins/images/26528/thumb/gst_eth.png",
  "RIN": "https://assets.coingecko.com/coins/images/16849/thumb/Aldrin.png",
  "CLH": "https://assets.coingecko.com/coins/images/27836/thumb/New-Clash-Icon_copy.png",
  "MODA": "https://assets.coingecko.com/coins/images/20870/thumb/ModaDAO__logomark-primary_3x.png",
  "KNDX": "https://assets.coingecko.com/coins/images/28244/thumb/kndx.png",
  "CMT": "https://assets.coingecko.com/coins/images/28382/thumb/cmt.png",
  "FAIR": "https://assets.coingecko.com/coins/images/27109/thumb/fairmint.png",
  "KUMA": "https://assets.coingecko.com/coins/images/15526/thumb/kuma_inu.PNG",
  "SWINGBY": "https://assets.coingecko.com/coins/images/11861/thumb/swingby.png",
  "SDC": "https://assets.coingecko.com/coins/images/24078/thumb/jtJwjux.png",
  "TNT": "https://assets.coingecko.com/coins/images/25563/thumb/tnt.png",
  "WMX": "https://assets.coingecko.com/coins/images/27844/thumb/WMX_logo.png",
  "NFTI": "https://assets.coingecko.com/coins/images/23647/thumb/NFTI-logo-white-bg.png",
  "VALUE": "https://assets.coingecko.com/coins/images/12525/thumb/value_logo_-_500x500.png",
  "PAPER": "https://assets.coingecko.com/coins/images/23510/thumb/PAPERLOGOCryptoLink1-1.png",
  "HST": "https://assets.coingecko.com/coins/images/26371/thumb/LogoHeadStarter_circle.png",
  "EDOGE": "https://assets.coingecko.com/coins/images/15806/thumb/Edoge.png",
  "METAMUSK": "https://assets.coingecko.com/coins/images/21822/thumb/Logo-Symbol-kh-ng-n-n.png",
  "KTN": "https://assets.coingecko.com/coins/images/28214/thumb/Kostren_%281%29.png",
  "PLY": "https://assets.coingecko.com/coins/images/29010/thumb/Group_1000003415.png",
  "TOP": "https://assets.coingecko.com/coins/images/17687/thumb/tokenplay.PNG",
  "UNIX": "https://assets.coingecko.com/coins/images/20674/thumb/unix.png",
  "COVER": "https://assets.coingecko.com/coins/images/13563/thumb/1_eWBbDaqpxXqt7WYPSP4qSw.jpeg",
  "XNL": "https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png",
  "ARCONA": "https://assets.coingecko.com/coins/images/4312/thumb/icon_ARCONA_%281%29.png",
  "UWL": "https://assets.coingecko.com/coins/images/13166/thumb/uniwhale.png",
  "BRICK": "https://assets.coingecko.com/coins/images/16741/thumb/TILE1.png",
  "METF": "https://assets.coingecko.com/coins/images/24282/thumb/METF_Token-svg.png",
  "WELL": "https://assets.coingecko.com/coins/images/26133/thumb/62b2bd0f0d661a0001b640eb_artemis.png",
  "SMARTCREDIT": "https://assets.coingecko.com/coins/images/13036/thumb/smartcredit_logo_02_white_a-1.png",
  "DEC": "https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png",
  "HNST": "https://assets.coingecko.com/coins/images/7345/thumb/honest-mining.png",
  "DOGEWHALE": "https://assets.coingecko.com/coins/images/22068/thumb/dogewhale_logo_3d_x200.png",
  "ETHIX": "https://assets.coingecko.com/coins/images/3031/thumb/ETHIX_icon_256x256-256.png",
  "ORC": "https://assets.coingecko.com/coins/images/9782/thumb/-p1Br7oh_400x400.png",
  "KINE": "https://assets.coingecko.com/coins/images/14339/thumb/kine.jpg",
  "BCP": "https://assets.coingecko.com/coins/images/8143/thumb/43Stz1Tw.png",
  "CZR": "https://assets.coingecko.com/coins/images/28524/thumb/200x200.png",
  "STBU": "https://assets.coingecko.com/coins/images/12637/thumb/exchange.png",
  "MAKI": "https://assets.coingecko.com/coins/images/28909/thumb/Maki_Logo.png",
  "DNXC": "https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png",
  "XPNET": "https://assets.coingecko.com/coins/images/18363/thumb/200.png",
  "SCREAM": "https://assets.coingecko.com/coins/images/17509/thumb/output-onlinepngtools_%286%29.png",
  "PLR": "https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png",
  "TERN": "https://assets.coingecko.com/coins/images/2534/thumb/darkblue-darkblue.png",
  "DPS": "https://assets.coingecko.com/coins/images/17953/thumb/f1LFu897_400x400.jpg",
  "BENT": "https://assets.coingecko.com/coins/images/21274/thumb/bent-logo-200x200.png",
  "UFEWO": "https://assets.coingecko.com/coins/images/16387/thumb/uFEWO.png",
  "ARMOR": "https://assets.coingecko.com/coins/images/13748/thumb/armor.png",
  "OWC": "https://assets.coingecko.com/coins/images/7203/thumb/ODUWA_COIN_OFFICIAL__LOGO_transparent_%281%29.png",
  "SALE": "https://assets.coingecko.com/coins/images/12250/thumb/dx-light.png",
  "YVAULT-LP-YCURVE": "https://assets.coingecko.com/coins/images/12210/thumb/yUSD.png",
  "ATD": "https://assets.coingecko.com/coins/images/14509/thumb/8926.png",
  "EYE": "https://assets.coingecko.com/coins/images/28623/thumb/EYE.png",
  "SMART": "https://assets.coingecko.com/coins/images/29072/thumb/smart.png",
  "UNB": "https://assets.coingecko.com/coins/images/21412/thumb/Unbound_icon_png.png",
  "SI": "https://assets.coingecko.com/coins/images/13955/thumb/siren_token_200x200.png",
  "CYT": "missing_thumb.png",
  "3ULL": "https://assets.coingecko.com/coins/images/25115/thumb/logo_200px2.png",
  "CMERGE": "https://assets.coingecko.com/coins/images/17146/thumb/cmerge.png",
  "ROOBEE": "https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png",
  "MSU": "https://assets.coingecko.com/coins/images/21670/thumb/Om_U3jQg_400x400.jpg",
  "CMST": "https://assets.coingecko.com/coins/images/28696/thumb/_CMST_Logo.png",
  "CATHEON": "https://assets.coingecko.com/coins/images/28052/thumb/_removal.ai__tmp-635fe0271d6a3%281%29.png",
  "EQZ": "https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png",
  "BIR": "https://assets.coingecko.com/coins/images/6063/thumb/birake.png",
  "SNP": "https://assets.coingecko.com/coins/images/17962/thumb/Webp-net-resizeimage_%282%29.png",
  "PLAY": "https://assets.coingecko.com/coins/images/21804/thumb/ig.png",
  "BXX": "https://assets.coingecko.com/coins/images/17108/thumb/BXX_Token_logo.png",
  "BTX": "https://assets.coingecko.com/coins/images/680/thumb/BitCoreBTXlogoJan2021inv.png",
  "XAUR": "https://assets.coingecko.com/coins/images/461/thumb/xaurum.png",
  "BIX": "https://assets.coingecko.com/coins/images/1441/thumb/bibox-token.png",
  "GAME": "https://assets.coingecko.com/coins/images/193/thumb/XlQmXoU.png",
  "$DCAP": "https://assets.coingecko.com/coins/images/25478/thumb/dcap.png",
  "ARI10": "https://assets.coingecko.com/coins/images/19904/thumb/ari10.PNG",
  "CRA": "https://assets.coingecko.com/coins/images/20011/thumb/crabada_icon_%281%29.png",
  "YETI": "https://assets.coingecko.com/coins/images/25022/thumb/1_oJ0F2Zf6CuAhLP0zOboo6w.png",
  "1EARTH": "https://assets.coingecko.com/coins/images/20822/thumb/earthfund.PNG",
  "BLXM": "https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png",
  "DHN": "https://assets.coingecko.com/coins/images/24966/thumb/dhn.png",
  "BAS": "https://assets.coingecko.com/coins/images/25509/thumb/19587.png",
  "SNFT": "https://assets.coingecko.com/coins/images/15300/thumb/ZkJWmP1.png",
  "KLO": "https://assets.coingecko.com/coins/images/18641/thumb/KALAO.jpg",
  "XSP": "https://assets.coingecko.com/coins/images/12541/thumb/256x256_%282%29.png",
  "AME": "https://assets.coingecko.com/coins/images/13539/thumb/amechain-logo-200x200.png",
  "DGX": "https://assets.coingecko.com/coins/images/4171/thumb/DGX_Token.png",
  "AMLT": "https://assets.coingecko.com/coins/images/2703/thumb/amlt.png",
  "ADK": "https://assets.coingecko.com/coins/images/6077/thumb/Logoldpi.png",
  "ACE": "https://assets.coingecko.com/coins/images/23990/thumb/ace.png",
  "FROYO": "https://assets.coingecko.com/coins/images/22386/thumb/95039059.png",
  "SRN": "https://assets.coingecko.com/coins/images/1872/thumb/sirin-labs-token.png",
  "HND": "https://assets.coingecko.com/coins/images/18445/thumb/hnd.PNG",
  "GRID": "https://assets.coingecko.com/coins/images/1106/thumb/grid.png",
  "IBFK": "https://assets.coingecko.com/coins/images/13881/thumb/IBFK-10.png",
  "FTC": "https://assets.coingecko.com/coins/images/28509/thumb/ftc.png",
  "PLSPAD": "https://assets.coingecko.com/coins/images/20102/thumb/Mypk8PG_-_Imgur.jpg",
  "MUST": "https://assets.coingecko.com/coins/images/13688/thumb/must_logo.png",
  "SPARTA": "https://assets.coingecko.com/coins/images/12638/thumb/coin_sparta_black_bg.png",
  "USK": "https://assets.coingecko.com/coins/images/27274/thumb/usk.png",
  "OKLG": "https://assets.coingecko.com/coins/images/20377/thumb/Screenshot-2021-11-14-at-23-45-11.png",
  "WLITI": "https://assets.coingecko.com/coins/images/16810/thumb/liticapital_real.png",
  "MTLX": "https://assets.coingecko.com/coins/images/12730/thumb/nrEqFTEW_400x400.jpg",
  "ECT": "https://assets.coingecko.com/coins/images/18304/thumb/Logo-Transparency.png",
  "OPIUM": "https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png",
  "OKS": "https://assets.coingecko.com/coins/images/11486/thumb/B49FnNM.png",
  "KINT": "https://assets.coingecko.com/coins/images/22045/thumb/Kintsugi_logo-150x150.jpeg",
  "DEV": "https://assets.coingecko.com/coins/images/26805/thumb/200x200.png",
  "PERI": "https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png",
  "MONI": "https://assets.coingecko.com/coins/images/18396/thumb/moni.png",
  "FACTR": "https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png",
  "EULER": "https://assets.coingecko.com/coins/images/14677/thumb/euler_logo.png",
  "MDA": "https://assets.coingecko.com/coins/images/895/thumb/moeda-loyalty-points.png",
  "NAOS": "https://assets.coingecko.com/coins/images/15144/thumb/bafybeibkztkshitabrf7yqqkqtbjqestjknpgv7lsjfzdsa3ufspagqs2e.ipfs.infura-ipfs.io.png",
  "JULIEN": "https://assets.coingecko.com/coins/images/12954/thumb/julien_logo.jpg",
  "OK": "https://assets.coingecko.com/coins/images/274/thumb/ok-logo-200px.png",
  "RCN": "https://assets.coingecko.com/coins/images/1057/thumb/ripio-credit-network.png",
  "HYVE": "https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png",
  "ODDZ": "https://assets.coingecko.com/coins/images/14421/thumb/NewLogo.png",
  "LCS": "https://assets.coingecko.com/coins/images/25468/thumb/lcs.png",
  "YEL": "https://assets.coingecko.com/coins/images/17429/thumb/Logo200.png",
  "KICKS": "https://assets.coingecko.com/coins/images/27873/thumb/GetKicks.jpg",
  "GOMT": "https://assets.coingecko.com/coins/images/25593/thumb/thumbnail.png",
  "SMLY": "https://assets.coingecko.com/coins/images/685/thumb/smileycoin.png",
  "KWAI": "https://assets.coingecko.com/coins/images/28599/thumb/KWAI_Logo.jpg",
  "TOL": "https://assets.coingecko.com/coins/images/4487/thumb/tolar.png",
  "PNY": "https://assets.coingecko.com/coins/images/4539/thumb/Peony-logo-with-text-transparent-600x600.png",
  "PHTK": "https://assets.coingecko.com/coins/images/24773/thumb/2q6YoWkb_400x400.jpg",
  "OXB": "https://assets.coingecko.com/coins/images/27781/thumb/CG_logo_%282%29.png",
  "DEX": "https://assets.coingecko.com/coins/images/12949/thumb/AlphaDex.png",
  "THX": "https://assets.coingecko.com/coins/images/21323/thumb/logo-thx-resized-200-200.png",
  "BED": "https://assets.coingecko.com/coins/images/17175/thumb/BED_Logo_-_No_border.png",
  "GAMEFI": "https://assets.coingecko.com/coins/images/26005/thumb/icon.png",
  "IGG": "https://assets.coingecko.com/coins/images/7697/thumb/N7aEdYrY_400x400.png",
  "EGT": "https://assets.coingecko.com/coins/images/19899/thumb/evergreen.PNG",
  "BTCS": "https://assets.coingecko.com/coins/images/296/thumb/bitcoin-scrypt.png",
  "CUT": "https://assets.coingecko.com/coins/images/28068/thumb/logo.png",
  "TCT": "https://assets.coingecko.com/coins/images/16971/thumb/TopCoin_logo_remove200x200.png",
  "PIST": "https://assets.coingecko.com/coins/images/16397/thumb/main_img01_pc.png",
  "SASHIMI": "https://assets.coingecko.com/coins/images/12427/thumb/SashimiSwap-200x200.png",
  "SGTV2": "https://assets.coingecko.com/coins/images/13948/thumb/sgt-png.png",
  "BRG.X": "https://assets.coingecko.com/coins/images/20875/thumb/bridgescrypto.PNG",
  "OCE": "https://assets.coingecko.com/coins/images/4963/thumb/oceanex.jpg",
  "PORT": "https://assets.coingecko.com/coins/images/14997/thumb/PP-Logomark.png",
  "XBC": "https://assets.coingecko.com/coins/images/15161/thumb/logo_xbc.png",
  "MOVEZ": "https://assets.coingecko.com/coins/images/25661/thumb/JYwFw77.png",
  "SKILL": "https://assets.coingecko.com/coins/images/15334/thumb/cryptoblade.PNG",
  "SWFL": "https://assets.coingecko.com/coins/images/12263/thumb/swapfolio-token-logo-icon-symbol-256-256.png",
  "HTO": "https://assets.coingecko.com/coins/images/25553/thumb/rTFh6BD.png",
  "ACS": "https://assets.coingecko.com/coins/images/28747/thumb/dR4FovX4_400x400.jpg",
  "NLC": "https://assets.coingecko.com/coins/images/27441/thumb/nlc.png",
  "IRT": "https://assets.coingecko.com/coins/images/20619/thumb/irt.PNG",
  "ONSTON": "https://assets.coingecko.com/coins/images/20669/thumb/onston.PNG",
  "CTG": "https://assets.coingecko.com/coins/images/11474/thumb/crystal_200.png",
  "BLKC": "https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png",
  "TRL": "https://assets.coingecko.com/coins/images/13548/thumb/TTz5YqTtwhmPitHJJ1r9AwF6X2KJuTz1cD.png",
  "WAVE": "https://assets.coingecko.com/coins/images/27895/thumb/photo_5947110558027332699_m.jpg",
  "DIVER": "https://assets.coingecko.com/coins/images/18666/thumb/DIVER.jpg",
  "WANA": "https://assets.coingecko.com/coins/images/18020/thumb/wanaka.PNG",
  "ROBOT": "https://assets.coingecko.com/coins/images/13517/thumb/MF_Robot_200px.png",
  "DON": "https://assets.coingecko.com/coins/images/14413/thumb/L5XbWSEk_400x400.png",
  "BUIDL": "https://assets.coingecko.com/coins/images/11131/thumb/buidllogo.png",
  "AOA": "https://assets.coingecko.com/coins/images/4755/thumb/aurora-logo.png",
  "HE": "https://assets.coingecko.com/coins/images/24113/thumb/3fau.png",
  "SUMO": "https://assets.coingecko.com/coins/images/1137/thumb/sumokoin.png",
  "SCCP": "https://assets.coingecko.com/coins/images/17998/thumb/XMRckIDaOnwFhFWEkrOHxrV1JhkI1JM5X4Y6LI5v.png",
  "ABR": "https://assets.coingecko.com/coins/images/27269/thumb/fav_%281%29.png",
  "MFI": "https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG",
  "BID": "https://assets.coingecko.com/coins/images/12344/thumb/IMG_20200919_115022_477.png",
  "BLK": "https://assets.coingecko.com/coins/images/22366/thumb/BLK_300x300px.png",
  "SOY": "https://assets.coingecko.com/coins/images/25351/thumb/soy.png",
  "OBTC": "https://assets.coingecko.com/coins/images/27276/thumb/fx-gs.jpg",
  "GTON": "https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png",
  "OPEN": "https://assets.coingecko.com/coins/images/2953/thumb/open-platform.png",
  "IDO": "https://assets.coingecko.com/coins/images/20523/thumb/logo_-_2021-11-18T065417.612.png",
  "POODL": "https://assets.coingecko.com/coins/images/14405/thumb/poodl-logo200x200.jpg",
  "TDX": "https://assets.coingecko.com/coins/images/3137/thumb/1.png",
  "IPX": "https://assets.coingecko.com/coins/images/10335/thumb/krTP5zP.png",
  "SHOPX": "https://assets.coingecko.com/coins/images/14600/thumb/shopx_icon_.png",
  "NEXT": "https://assets.coingecko.com/coins/images/28681/thumb/shopnex.jpg",
  "DAPPT": "https://assets.coingecko.com/coins/images/8282/thumb/dappt_logo.png",
  "AIT": "https://assets.coingecko.com/coins/images/2525/thumb/aichain.png",
  "TREAT": "https://assets.coingecko.com/coins/images/27349/thumb/88.png",
  "LBA": "https://assets.coingecko.com/coins/images/3673/thumb/libra-credit.png",
  "WSG": "https://assets.coingecko.com/coins/images/15872/thumb/X3Awe42.png",
  "ABLOCK": "https://assets.coingecko.com/coins/images/14869/thumb/blocknet.jpg",
  "AVG": "https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png",
  "TRCL": "https://assets.coingecko.com/coins/images/11670/thumb/Mj4nZSD9_400x400.jpg",
  "COFI": "https://assets.coingecko.com/coins/images/1709/thumb/coinfi.png",
  "XFI": "https://assets.coingecko.com/coins/images/14002/thumb/7223.png",
  "LZ": "https://assets.coingecko.com/coins/images/17761/thumb/icon_%287%29.png",
  "ZYX": "https://assets.coingecko.com/coins/images/11964/thumb/zyx.png",
  "UNIQ": "https://assets.coingecko.com/coins/images/14808/thumb/Hnet-com-image.png",
  "ITGR": "https://assets.coingecko.com/coins/images/18381/thumb/icon.png",
  "BHC": "https://assets.coingecko.com/coins/images/12803/thumb/BHC_BLACK_TRANSPARENT.png",
  "KICK": "https://assets.coingecko.com/coins/images/18942/thumb/KICK.png",
  "STATE": "https://assets.coingecko.com/coins/images/27387/thumb/icon.png",
  "POOP": "https://assets.coingecko.com/coins/images/21826/thumb/poopsicle-200x200.png",
  "DIGG": "https://assets.coingecko.com/coins/images/13737/thumb/digg.PNG",
  "INUKO": "https://assets.coingecko.com/coins/images/27821/thumb/inuko-logo-200x200.png",
  "KONO": "https://assets.coingecko.com/coins/images/14406/thumb/konomi.jpg",
  "UTU": "https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png",
  "UDO": "https://assets.coingecko.com/coins/images/20914/thumb/3c2IfwK-_400x400.jpg",
  "DXL": "https://assets.coingecko.com/coins/images/17276/thumb/3_GradientSymbol.png",
  "INVEST": "https://assets.coingecko.com/coins/images/16382/thumb/FINAL_FINAL.png",
  "ARTH": "https://assets.coingecko.com/coins/images/16876/thumb/Ik5dhOq.png",
  "CCX": "https://assets.coingecko.com/coins/images/27900/thumb/3e88b61451995da1a5aa572cd4f48e78023e010c0bf984cd5456b2a7bd85f7a5.png",
  "SHND": "https://assets.coingecko.com/coins/images/859/thumb/SHND_icon_primary_512x512.png",
  "LYR": "https://assets.coingecko.com/coins/images/13241/thumb/lyra-icon-200x200-transparent-bg.png",
  "KLT": "https://assets.coingecko.com/coins/images/14942/thumb/spaces_-MYEepcYKLFRJ0c-U3Pb_avatar-1618398349022.png",
  "NUSA": "https://assets.coingecko.com/coins/images/27967/thumb/Asset_1_2x.png",
  "EMT": "https://assets.coingecko.com/coins/images/27074/thumb/emt.png",
  "APEFI": "https://assets.coingecko.com/coins/images/25625/thumb/APEFI.png",
  "3AIR": "https://assets.coingecko.com/coins/images/27399/thumb/200x200_icon.png",
  "XY": "https://assets.coingecko.com/coins/images/21541/thumb/xy.png",
  "BALPHA": "https://assets.coingecko.com/coins/images/14224/thumb/logo_bAlpha_200.png",
  "YAE": "https://assets.coingecko.com/coins/images/14693/thumb/yae.png",
  "IONX": "https://assets.coingecko.com/coins/images/15836/thumb/DrKjSQMH_400x400.png",
  "DOUGH": "https://assets.coingecko.com/coins/images/18800/thumb/dough.png",
  "MOJO": "https://assets.coingecko.com/coins/images/27978/thumb/Twitter_Logo.png",
  "FIEF": "https://assets.coingecko.com/coins/images/23710/thumb/61f837d2703b649ab0213d45_Black_logo_-_no_background-p-500.png",
  "TFL": "https://assets.coingecko.com/coins/images/940/thumb/tfl.png",
  "XET": "https://assets.coingecko.com/coins/images/18422/thumb/Xfinite_icon_%28black%29.png",
  "GVT": "https://assets.coingecko.com/coins/images/18628/thumb/613f170fa75cd0ec6fd17f50_Icon-Vault-256x256-Square.png",
  "LOA": "https://assets.coingecko.com/coins/images/11164/thumb/1a67ac81c1803ee172b7ce8805b5da3d-full.jpg",
  "FERMA": "https://assets.coingecko.com/coins/images/14482/thumb/ferma.png",
  "STAKE": "https://assets.coingecko.com/coins/images/11061/thumb/xdai.png",
  "ONION": "https://assets.coingecko.com/coins/images/1148/thumb/deeponion-circle-icon.png",
  "EXPO": "https://assets.coingecko.com/coins/images/6548/thumb/t4GE48eH_400x400.png",
  "YEC": "https://assets.coingecko.com/coins/images/8978/thumb/y_sign.png",
  "BMI": "https://assets.coingecko.com/coins/images/13808/thumb/bmi_logo.png",
  "DAV": "https://assets.coingecko.com/coins/images/2842/thumb/DAV-Network-logo.jpg",
  "PUT": "https://assets.coingecko.com/coins/images/773/thumb/putincoin.png",
  "ZAP": "https://assets.coingecko.com/coins/images/23954/thumb/jlpH5j-t_400x400.jpg",
  "DARK": "https://assets.coingecko.com/coins/images/22456/thumb/DARK_bgWhite.png",
  "BEACH": "https://assets.coingecko.com/coins/images/17875/thumb/Beach-token-200x200.png",
  "SPH": "https://assets.coingecko.com/coins/images/11968/thumb/spheroid-sph-icon-400x400.png",
  "$CRAMER": "https://assets.coingecko.com/coins/images/27463/thumb/cramercoin.jpg",
  "OIL": "https://assets.coingecko.com/coins/images/28350/thumb/OIL_200.png",
  "BIOFI": "https://assets.coingecko.com/coins/images/25207/thumb/CoinGekoBioFiImage.png",
  "LUFC": "https://assets.coingecko.com/coins/images/19733/thumb/fanmarketcap.png",
  "PXC": "https://assets.coingecko.com/coins/images/177/thumb/phoenixcoin.png",
  "BLITZ": "https://assets.coingecko.com/coins/images/25554/thumb/cmc2.png",
  "FABRIC": "https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png",
  "WHITE": "https://assets.coingecko.com/coins/images/26667/thumb/IMG_2923.PNG",
  "MMO": "https://assets.coingecko.com/coins/images/27012/thumb/21545.png",
  "HOTCROSS": "https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png",
  "YUZU": "https://assets.coingecko.com/coins/images/24131/thumb/ZSafLE4.png",
  "LKR": "https://assets.coingecko.com/coins/images/14692/thumb/lokr.png",
  "BRN": "https://assets.coingecko.com/coins/images/25363/thumb/brn.png",
  "XMS": "https://assets.coingecko.com/coins/images/16349/thumb/MARS.png",
  "FLOAT": "https://assets.coingecko.com/coins/images/15502/thumb/FLOAT_LOGO-reduced_01.png",
  "GNX": "https://assets.coingecko.com/coins/images/1361/thumb/genaro-network.png",
  "ACRE": "https://assets.coingecko.com/coins/images/23659/thumb/acre_token-02.png",
  "UCASH": "https://assets.coingecko.com/coins/images/2901/thumb/ucash.png",
  "TAD": "https://assets.coingecko.com/coins/images/13134/thumb/9DmF_cs3_400x400.jpg",
  "LINKA": "https://assets.coingecko.com/coins/images/9487/thumb/C2RRuVH6eVbXIDbew-0VJoGpPb_bMPjRjM9qlYud7tqtGgb1GskioTkIntKuugvXnX-Y3O54L-4zWeWWm_Fffo1kJiqJG5vE4Iu1FSvZ-N750y_tlB7yAGeYztAD-MDrwEPaF3Y9j6E94mPVyKuidqbxf9vBeR5r4GAya3friWZjMzpnJ_q9v0jiIBxEC5YAy1C0Zx6yqVoHJjiTW.jpg",
  "FTS": "https://assets.coingecko.com/coins/images/24517/thumb/logo200.png",
  "SWOP": "https://assets.coingecko.com/coins/images/14212/thumb/OwU4oA9.jpg",
  "URQA": "https://assets.coingecko.com/coins/images/14605/thumb/R_O2enOX_400x400.png",
  "FLP": "https://assets.coingecko.com/coins/images/2173/thumb/flp.png",
  "ZTG": "https://assets.coingecko.com/coins/images/26210/thumb/LsjPurB1DcPm4cjLIZPNwmf9HP4qi-AZNesp9jIeLTwhQqYcdazsjTB4WgmZIVxf0KRpJQ2FQRY4q85MO2xkhi4Ygml1LoFXSG0zbF79I2jth4c5MeJh65SCyXQ15JxBKP4inx0NmJqnzNVS5jIorb3IYowlrySARMc6hFIDq-t02Ax6gVqSxERewbJ_ZkuSWPMdB3do_ko-ccdFKyG.jpg",
  "DOGECOLA": "https://assets.coingecko.com/coins/images/17371/thumb/11271.png",
  "COLLIE": "https://assets.coingecko.com/coins/images/27365/thumb/pfp2.jpg",
  "CATCOIN": "https://assets.coingecko.com/coins/images/28404/thumb/logo_coingecko.png",
  "VISION": "https://assets.coingecko.com/coins/images/24460/thumb/eQh2HGH3_400x400.jpg",
  "WOOF": "https://assets.coingecko.com/coins/images/28903/thumb/WWlogoTransparent_200x200.png",
  "X": "https://assets.coingecko.com/coins/images/18412/thumb/x.PNG",
  "FARA": "https://assets.coingecko.com/coins/images/16273/thumb/fara.PNG",
  "JULD": "https://assets.coingecko.com/coins/images/13839/thumb/Logo-D-Unicorn.png",
  "UBXN": "https://assets.coingecko.com/coins/images/12476/thumb/UBXT.png",
  "BOMB": "https://assets.coingecko.com/coins/images/26399/thumb/Bombcrypto_Coin.png",
  "MSTR": "https://assets.coingecko.com/coins/images/24377/thumb/MSTR.png",
  "WOOFY": "https://assets.coingecko.com/coins/images/15410/thumb/woofy.png",
  "KWT": "https://assets.coingecko.com/coins/images/18880/thumb/8f4IyY3_%281%29.jpg",
  "HY": "https://assets.coingecko.com/coins/images/11518/thumb/Hybrix_PNG_200x200.png",
  "BTSG": "https://assets.coingecko.com/coins/images/5041/thumb/logo_-_2021-01-10T210801.390.png",
  "SLICE": "https://assets.coingecko.com/coins/images/14119/thumb/slice.png",
  "VBK": "https://assets.coingecko.com/coins/images/8075/thumb/photo_2021-02-03_11.54.55.jpeg",
  "ZWAP": "https://assets.coingecko.com/coins/images/13888/thumb/1Artboard_2_2x.png",
  "GOAL": "https://assets.coingecko.com/coins/images/20197/thumb/Goal_Token_200_x_200.png",
  "COGI": "https://assets.coingecko.com/coins/images/19619/thumb/COGI_coin.png",
  "UP": "https://assets.coingecko.com/coins/images/12492/thumb/jKHY5u_S_200x200.png",
  "FRKT": "https://assets.coingecko.com/coins/images/18926/thumb/logo_-_2021-10-11T132203.751.png",
  "ARX": "https://assets.coingecko.com/coins/images/10068/thumb/arcs.png",
  "WBM": "https://assets.coingecko.com/coins/images/28240/thumb/logo200.png",
  "DIS": "https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png",
  "UDOO": "https://assets.coingecko.com/coins/images/3078/thumb/HYPRR-WIDGET-CIRCLE-ONBLACK-1.jpg",
  "SWPR": "https://assets.coingecko.com/coins/images/24260/thumb/output-onlinepngtools.png",
  "NPX": "https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg",
  "BRDG": "https://assets.coingecko.com/coins/images/25139/thumb/bridge-logo-blue.png",
  "USF": "https://assets.coingecko.com/coins/images/14177/thumb/Unslashed.jpeg",
  "PGX": "https://assets.coingecko.com/coins/images/26319/thumb/ICfb5Z0c_400x400.jpg",
  "WSB": "https://assets.coingecko.com/coins/images/24170/thumb/18622.png",
  "MINE": "https://assets.coingecko.com/coins/images/26444/thumb/Logotype_Fashion_Store_Logo_Neon_%282%29-modified.png",
  "DUST": "https://assets.coingecko.com/coins/images/24289/thumb/dust-protocol-degod.png",
  "DEGEN": "https://assets.coingecko.com/coins/images/14143/thumb/alpha_logo.png",
  "DEFX": "https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png",
  "MGPT": "https://assets.coingecko.com/coins/images/25983/thumb/d5edaae7-46c4-4cb4-821f-43f6ffc7ec71.png",
  "LINU": "https://assets.coingecko.com/coins/images/22029/thumb/unknown_%281%29.png",
  "SHIBX": "https://assets.coingecko.com/coins/images/17913/thumb/Logo_Shibx_200x200.jpg",
  "MRX": "https://assets.coingecko.com/coins/images/782/thumb/metrix-coin.png",
  "FLY": "https://assets.coingecko.com/coins/images/24296/thumb/FLY.png",
  "VALAS": "https://assets.coingecko.com/coins/images/24733/thumb/valas-finance.png",
  "GRLC": "https://assets.coingecko.com/coins/images/21011/thumb/logo.png",
  "HDAO": "https://assets.coingecko.com/coins/images/19468/thumb/hdao.png",
  "ETHO": "https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png",
  "PLOT": "https://assets.coingecko.com/coins/images/24495/thumb/3MgvCVi.jpeg",
  "SWAG": "https://assets.coingecko.com/coins/images/12805/thumb/photo_2020-10-14_23.17.02.jpeg",
  "AGVE": "https://assets.coingecko.com/coins/images/14146/thumb/agve.png",
  "MAD": "https://assets.coingecko.com/coins/images/2031/thumb/Logo.png",
  "XPM": "https://assets.coingecko.com/coins/images/27759/thumb/xpspin_512.png",
  "BIS": "https://assets.coingecko.com/coins/images/974/thumb/bis200px.png",
  "ANCHOR": "https://assets.coingecko.com/coins/images/18909/thumb/anchorswap.PNG",
  "TRANQ": "https://assets.coingecko.com/coins/images/18708/thumb/tranquil.png",
  "MINIDOGE": "https://assets.coingecko.com/coins/images/16837/thumb/MiniDoge_Token_200_svlozg.png",
  "2KEY": "https://assets.coingecko.com/coins/images/6090/thumb/2key.png",
  "CATPAY": "https://assets.coingecko.com/coins/images/24731/thumb/catpay.png",
  "PHTR": "https://assets.coingecko.com/coins/images/16071/thumb/phtr.png",
  "MUNT": "https://assets.coingecko.com/coins/images/143/thumb/gulden.png",
  "1-UP": "https://assets.coingecko.com/coins/images/17376/thumb/1up.png",
  "NRFB": "https://assets.coingecko.com/coins/images/23360/thumb/pcMlP25e_400x400.jpg",
  "EAI": "https://assets.coingecko.com/coins/images/25043/thumb/communityIcon_en839u7vj1q71.png",
  "TAG": "https://assets.coingecko.com/coins/images/26418/thumb/TAG_PNG.png",
  "HMQ": "https://assets.coingecko.com/coins/images/647/thumb/humaniq.jpg",
  "TBC": "https://assets.coingecko.com/coins/images/27312/thumb/tbc.png",
  "PAINT": "https://assets.coingecko.com/coins/images/14103/thumb/paint_logo_200x200.png",
  "CORN": "https://assets.coingecko.com/coins/images/27389/thumb/corn_200px.png",
  "SYNC": "https://assets.coingecko.com/coins/images/19283/thumb/sync.png",
  "MZC": "https://assets.coingecko.com/coins/images/25/thumb/mazacoin.png",
  "GLEEC": "https://assets.coingecko.com/coins/images/10361/thumb/gleec-coin.7cdcc475.png",
  "GAT": "https://assets.coingecko.com/coins/images/7008/thumb/a3b65903bffb6e47b225bdd70c635701.png",
  "ARTR": "https://assets.coingecko.com/coins/images/17380/thumb/200x200_%2823%29.png",
  "EROWAN": "https://assets.coingecko.com/coins/images/14044/thumb/EROWAN.png",
  "BRD": "https://assets.coingecko.com/coins/images/1440/thumb/bread.png",
  "EMC": "https://assets.coingecko.com/coins/images/27888/thumb/02-59-48-22275.png",
  "GLC": "https://assets.coingecko.com/coins/images/2886/thumb/globalcoin.png",
  "LAUNCH": "https://assets.coingecko.com/coins/images/14817/thumb/SL-V3-1024.png",
  "FLIXX": "https://assets.coingecko.com/coins/images/1261/thumb/flixxo.png",
  "TRI": "https://assets.coingecko.com/coins/images/28106/thumb/TRICE-COIN-LOGO-FOR-CMC.png",
  "NUDES": "https://assets.coingecko.com/coins/images/24888/thumb/NUDES.png",
  "FNT": "https://assets.coingecko.com/coins/images/11579/thumb/falcon_ava_black.png",
  "HAKA": "https://assets.coingecko.com/coins/images/16575/thumb/USqW1QX.png",
  "TIA": "https://assets.coingecko.com/coins/images/23994/thumb/tia.png",
  "AM": "https://assets.coingecko.com/coins/images/20743/thumb/am200-200.png",
  "SPORE": "https://assets.coingecko.com/coins/images/14470/thumb/SPORE.png",
  "BSCS": "https://assets.coingecko.com/coins/images/14963/thumb/BSCS_LOGO.png",
  "KIT": "https://assets.coingecko.com/coins/images/19378/thumb/13019.png",
  "GCOIN": "https://assets.coingecko.com/coins/images/22364/thumb/M1oqSwPA_400x400.jpg",
  "PACOCA": "https://assets.coingecko.com/coins/images/16567/thumb/pacoca.PNG",
  "ACQ": "https://assets.coingecko.com/coins/images/27199/thumb/ACQisotypeSept2022_1000x1000px.png",
  "YUP": "https://assets.coingecko.com/coins/images/12322/thumb/photo_2021-10-26_00-47-35.jpg",
  "ADB": "https://assets.coingecko.com/coins/images/2806/thumb/adbank.png",
  "SHOO": "https://assets.coingecko.com/coins/images/21544/thumb/shoo.jpeg",
  "WELT": "https://assets.coingecko.com/coins/images/20505/thumb/welt.PNG",
  "RATING": "https://assets.coingecko.com/coins/images/5383/thumb/dprating.png",
  "ANJI": "https://assets.coingecko.com/coins/images/20000/thumb/anji.PNG",
  "LXT": "https://assets.coingecko.com/coins/images/5133/thumb/litex-logo.png",
  "CREDI": "https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg",
  "CBC": "https://assets.coingecko.com/coins/images/7114/thumb/eqIkj-ZZ_400x400.jpg",
  "DMAGIC": "https://assets.coingecko.com/coins/images/15842/thumb/Dark_Magic.png",
  "MILK": "https://assets.coingecko.com/coins/images/20772/thumb/cashcow_protocol_milk.png",
  "DRK": "https://assets.coingecko.com/coins/images/15754/thumb/logo_200x200_square_trans.png",
  "THO": "https://assets.coingecko.com/coins/images/22297/thumb/THO_200x200.png",
  "WSN": "https://assets.coingecko.com/coins/images/21343/thumb/WSN_200_x_200px.png",
  "KAMPAY": "https://assets.coingecko.com/coins/images/8289/thumb/6130e86152bdb07e2848de00_200_4x.png",
  "MINDS": "https://assets.coingecko.com/coins/images/11517/thumb/Minds.png",
  "MNST": "https://assets.coingecko.com/coins/images/16146/thumb/MoonStarter.png",
  "UNISTAKE": "https://assets.coingecko.com/coins/images/12813/thumb/unistake.png",
  "GALO": "https://assets.coingecko.com/coins/images/18648/thumb/galo.png",
  "BMON": "https://assets.coingecko.com/coins/images/16346/thumb/bmon.png",
  "DLTA": "https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png",
  "FILDA": "https://assets.coingecko.com/coins/images/13934/thumb/76676995.png",
  "POLX": "https://assets.coingecko.com/coins/images/15331/thumb/logo-mark-transparent_4x.png",
  "EGG": "https://assets.coingecko.com/coins/images/17574/thumb/lRAQ2MOTpWqrHhI.png",
  "ASTROC": "https://assets.coingecko.com/coins/images/20804/thumb/Astro_coin_icon.png",
  "DDD": "https://assets.coingecko.com/coins/images/25212/thumb/batkXqm.png",
  "SAUBER": "https://assets.coingecko.com/coins/images/16869/thumb/ts2avj5Atx3BbSVP3fUkxY7Oz0Y9P9jktIGr4985.png",
  "ONX": "https://assets.coingecko.com/coins/images/17469/thumb/200_200.jpg",
  "BNKR": "https://assets.coingecko.com/coins/images/10142/thumb/logo-exchange-200.png",
  "IOV": "https://assets.coingecko.com/coins/images/12660/thumb/iov.png",
  "BOP": "https://assets.coingecko.com/coins/images/16828/thumb/imgonline-com-ua-resize-VT59gqn-Bya-WGG.jpg",
  "ROY": "https://assets.coingecko.com/coins/images/15339/thumb/royal_protocol.jpg",
  "LEAN": "https://assets.coingecko.com/coins/images/15854/thumb/lean.PNG",
  "PKR": "https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png",
  "ESS": "https://assets.coingecko.com/coins/images/2483/thumb/Essentia-token.jpg",
  "SCIFI": "https://assets.coingecko.com/coins/images/13868/thumb/yaxtD4c.png",
  "DCB": "https://assets.coingecko.com/coins/images/16909/thumb/Logo-Decubate_200x200.png",
  "OMNI": "https://assets.coingecko.com/coins/images/7/thumb/omni_%28mastercoin%29.png",
  "PIKA": "https://assets.coingecko.com/coins/images/14419/thumb/pika-logo-2022-nbg.png",
  "YBO": "https://assets.coingecko.com/coins/images/13784/thumb/_YB_TOKEN-10.png",
  "PAWTH": "https://assets.coingecko.com/coins/images/19275/thumb/pawth.png",
  "XCT": "https://assets.coingecko.com/coins/images/17852/thumb/logo200on200.png",
  "COOK": "https://assets.coingecko.com/coins/images/14603/thumb/logo-200x200.jpg",
  "UPI": "https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg",
  "STND": "https://assets.coingecko.com/coins/images/15100/thumb/standard.jpeg",
  "HUSL": "https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png",
  "DTA": "https://assets.coingecko.com/coins/images/2604/thumb/data.png",
  "MTS": "https://assets.coingecko.com/coins/images/24637/thumb/LOGO-2.png",
  "SMTY": "https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg",
  "$KMC": "https://assets.coingecko.com/coins/images/21812/thumb/cg_%283%29.png",
  "FLS": "https://assets.coingecko.com/coins/images/8579/thumb/FLITS_BrandMark_Classic1.png",
  "AVL": "https://assets.coingecko.com/coins/images/19444/thumb/jzifYYk6Q7vLPXDnI5oUKspt4pL8vHyeoF3xHKtq.png",
  "CRBN": "https://assets.coingecko.com/coins/images/13262/thumb/carbon.png",
  "YEE": "https://assets.coingecko.com/coins/images/2576/thumb/YeeCo-logo.png",
  "ARES": "https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png",
  "TWD": "https://assets.coingecko.com/coins/images/19715/thumb/twd.png",
  "FDT": "https://assets.coingecko.com/coins/images/27626/thumb/FDT_color_symbol_200.png",
  "BOXA": "https://assets.coingecko.com/coins/images/28776/thumb/BOXA.png",
  "MSHEESHA": "https://assets.coingecko.com/coins/images/23494/thumb/sheesha.PNG",
  "SHAK": "https://assets.coingecko.com/coins/images/19026/thumb/shak.png",
  "CHICKS": "https://assets.coingecko.com/coins/images/20978/thumb/chicks.png",
  "SARCO": "https://assets.coingecko.com/coins/images/15091/thumb/E2S2-CcUcAAyNxD.jpeg",
  "IF": "https://assets.coingecko.com/coins/images/27118/thumb/IMG_20220830_191414_156.jpg",
  "TFLOW": "https://assets.coingecko.com/coins/images/27843/thumb/photo_2022-10-17_12-17-44.jpg",
  "DSETH": "https://assets.coingecko.com/coins/images/28751/thumb/dsETH-logo.png",
  "TLPT": "https://assets.coingecko.com/coins/images/26972/thumb/tenderLPT.png",
  "USDAP": "https://assets.coingecko.com/coins/images/14874/thumb/USDap_%281%29.png",
  "LUNES": "https://assets.coingecko.com/coins/images/5658/thumb/Lunes-Symbol-200x200.png",
  "ORS": "https://assets.coingecko.com/coins/images/4646/thumb/origin-sport-logo.png",
  "PST": "https://assets.coingecko.com/coins/images/27067/thumb/200.png",
  "CPO": "https://assets.coingecko.com/coins/images/19163/thumb/PgnW_SGk_400x400.jpg",
  "AERA": "https://assets.coingecko.com/coins/images/24852/thumb/aerarium-red.png",
  "XLA": "https://assets.coingecko.com/coins/images/3736/thumb/round_128x128.png",
  "DUEL": "https://assets.coingecko.com/coins/images/18061/thumb/duel.PNG",
  "THC": "https://assets.coingecko.com/coins/images/20328/thumb/kdEastmD_400x400.jpg",
  "BTO": "https://assets.coingecko.com/coins/images/2048/thumb/bottos.png",
  "VRN": "https://assets.coingecko.com/coins/images/17633/thumb/vrn.png",
  "OCN": "https://assets.coingecko.com/coins/images/2559/thumb/ocn.png",
  "GLB": "https://assets.coingecko.com/coins/images/20274/thumb/glb.PNG",
  "PUSSY": "https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png",
  "ARCHA": "https://assets.coingecko.com/coins/images/18814/thumb/Archa_200_x_200_PNG.png",
  "LET": "https://assets.coingecko.com/coins/images/1814/thumb/linkeye.png",
  "IMO": "https://assets.coingecko.com/coins/images/23330/thumb/6189656aa934fd0f709d1121_favilg.png",
  "$GENE": "https://assets.coingecko.com/coins/images/20807/thumb/1637683704200x200.png",
  "OLO": "https://assets.coingecko.com/coins/images/20637/thumb/Capture.PNG",
  "TEER": "https://assets.coingecko.com/coins/images/21131/thumb/teer.PNG",
  "XIL": "https://assets.coingecko.com/coins/images/18247/thumb/projectx.PNG",
  "HUSH": "https://assets.coingecko.com/coins/images/701/thumb/HUSH_200x200.png",
  "POC": "https://assets.coingecko.com/coins/images/13759/thumb/PQ7MaT8N_400x400.png",
  "MOMENTO": "https://assets.coingecko.com/coins/images/18532/thumb/momento.png",
  "LODE": "https://assets.coingecko.com/coins/images/28296/thumb/LODE-Token_200.png",
  "METADOGE": "https://assets.coingecko.com/coins/images/28815/thumb/Dogemeta.jpeg",
  "HIT": "https://assets.coingecko.com/coins/images/16395/thumb/JgMkz95.png",
  "DWZ": "https://assets.coingecko.com/coins/images/21215/thumb/1s6XP8k.png",
  "HTML": "https://assets.coingecko.com/coins/images/2451/thumb/html.png",
  "GSE": "https://assets.coingecko.com/coins/images/4776/thumb/gsenetwork.png",
  "SATT": "https://assets.coingecko.com/coins/images/4070/thumb/r3s9XKk.png",
  "WNT": "https://assets.coingecko.com/coins/images/21223/thumb/wicrypt.PNG",
  "EOSC": "https://assets.coingecko.com/coins/images/6264/thumb/GB0qvsYM_400x400.jpg",
  "PTOY": "https://assets.coingecko.com/coins/images/746/thumb/patientory.png",
  "PETS": "https://assets.coingecko.com/coins/images/19409/thumb/pets_polka.PNG",
  "FRC": "https://assets.coingecko.com/coins/images/30/thumb/freicoin.png",
  "IXC": "https://assets.coingecko.com/coins/images/29/thumb/ixcoin.png",
  "VATRENI": "https://assets.coingecko.com/coins/images/28295/thumb/Vatreni.png",
  "CRWNY": "https://assets.coingecko.com/coins/images/14958/thumb/crowny-icon-rounded_2x.png",
  "SBONK": "https://assets.coingecko.com/coins/images/28675/thumb/bonklogo2.png",
  "BABI": "https://assets.coingecko.com/coins/images/17838/thumb/CW32Ubsk_400x400.jpg",
  "DAVIS": "https://assets.coingecko.com/coins/images/20793/thumb/tokens_7514c284-9026-46cf-bdc6-97d8c6076b8e.png",
  "CLEG": "https://assets.coingecko.com/coins/images/26440/thumb/logo1.png",
  "AT": "https://assets.coingecko.com/coins/images/5132/thumb/abcc-token.png",
  "ONI": "https://assets.coingecko.com/coins/images/9405/thumb/Oni_Round.png",
  "PEG": "https://assets.coingecko.com/coins/images/15610/thumb/Pegazus.png",
  "BSTN": "https://assets.coingecko.com/coins/images/24228/thumb/1_hm7Nmrw_PmniLj3zlzIdZA.png",
  "HUSKY": "https://assets.coingecko.com/coins/images/27224/thumb/image.jpg",
  "SPO": "https://assets.coingecko.com/coins/images/24244/thumb/spo.png",
  "PHNX": "https://assets.coingecko.com/coins/images/28292/thumb/android-chrome-512x512-1_%281%29.png",
  "GDOGE": "https://assets.coingecko.com/coins/images/15531/thumb/200x200.png",
  "AG8": "https://assets.coingecko.com/coins/images/11044/thumb/Nnz7oU1v.jpg",
  "BET": "https://assets.coingecko.com/coins/images/27935/thumb/new-logo.png",
  "FAME": "https://assets.coingecko.com/coins/images/25267/thumb/FJTwuXxE_400x400.jpg",
  "DGS": "https://assets.coingecko.com/coins/images/24100/thumb/272017889_106112668638625_8328245986225138945_n.jpg",
  "EXM": "https://assets.coingecko.com/coins/images/9154/thumb/200x200.png",
  "WNDR": "https://assets.coingecko.com/coins/images/25461/thumb/Wonderman_Logo_Menu.png",
  "UFARM": "https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg",
  "F2C": "https://assets.coingecko.com/coins/images/22250/thumb/d2pxPhBW_400x400.png",
  "YAXIS": "https://assets.coingecko.com/coins/images/12620/thumb/Logo.png",
  "STON": "https://assets.coingecko.com/coins/images/13001/thumb/ston-v2-200x200.png",
  "TANKS": "https://assets.coingecko.com/coins/images/19819/thumb/tank-200.png",
  "RYO": "https://assets.coingecko.com/coins/images/5046/thumb/roy-logo.png",
  "CAN": "https://assets.coingecko.com/coins/images/14093/thumb/KpSUqAH.png",
  "FND": "https://assets.coingecko.com/coins/images/28099/thumb/ddsaaa.png",
  "DCAR": "https://assets.coingecko.com/coins/images/26613/thumb/dcar%283%29.png",
  "CAT": "https://assets.coingecko.com/coins/images/21491/thumb/kittyicon-yellow-cat.60a0fd5e.png",
  "DUCKER": "https://assets.coingecko.com/coins/images/26325/thumb/coin.png",
  "XDN": "https://assets.coingecko.com/coins/images/132/thumb/DN2020_circle_hires.png",
  "AFR": "https://assets.coingecko.com/coins/images/18255/thumb/afreum.PNG",
  "NITRO": "https://assets.coingecko.com/coins/images/28330/thumb/200x200.png",
  "MIBR": "https://assets.coingecko.com/coins/images/27479/thumb/Asset_94_4x.png",
  "CNV": "https://assets.coingecko.com/coins/images/24492/thumb/concave.jpg",
  "XCASH": "https://assets.coingecko.com/coins/images/6331/thumb/5fbb9e5b8f27d74dd8bcb912_xcash_coingecko_logo.png",
  "FYP": "https://assets.coingecko.com/coins/images/1098/thumb/flypme.png",
  "RNT": "https://assets.coingecko.com/coins/images/2520/thumb/OneRootNetwork.PNG",
  "FPFT": "https://assets.coingecko.com/coins/images/25982/thumb/8d6a8074-7649-4e85-be2e-a1dc0d6674c8.png",
  "SHEESHA": "https://assets.coingecko.com/coins/images/23053/thumb/MLBmh4z0.png",
  "SATA": "https://assets.coingecko.com/coins/images/14704/thumb/logo.png",
  "EFC": "https://assets.coingecko.com/coins/images/19566/thumb/nPC8rv1oa4bomiuvXBCxnnp6P4wf8TCi8sNLATBY.png",
  "EVERETH": "https://assets.coingecko.com/coins/images/18221/thumb/200x200.png",
  "RUN": "https://assets.coingecko.com/coins/images/26072/thumb/logo-200.png",
  "SHACK": "https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png",
  "REVA": "https://assets.coingecko.com/coins/images/19048/thumb/61920010aaba4d16b1d57215_gradient-bg_200.png",
  "EDDA": "https://assets.coingecko.com/coins/images/14368/thumb/edda.png",
  "AUR": "https://assets.coingecko.com/coins/images/18427/thumb/W7l4Jxsh_400x400.jpg",
  "KRB": "https://assets.coingecko.com/coins/images/2430/thumb/karbo_coin.png",
  "STA": "https://assets.coingecko.com/coins/images/12313/thumb/stable_200x200.png",
  "NIOX": "https://assets.coingecko.com/coins/images/1122/thumb/NewLogo.png",
  "MOCA": "https://assets.coingecko.com/coins/images/15829/thumb/photo_2021-06-04_09.36.16.jpeg",
  "RAZE": "https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png",
  "X8X": "https://assets.coingecko.com/coins/images/1912/thumb/x8x.png",
  "ONG": "https://assets.coingecko.com/coins/images/5716/thumb/ONG_logo.png",
  "HBOT": "https://assets.coingecko.com/coins/images/21717/thumb/PDPuf0tJ_400x400.jpg",
  "ZOON": "https://assets.coingecko.com/coins/images/17419/thumb/logo200_%2820%29.png",
  "DRCT": "https://assets.coingecko.com/coins/images/16807/thumb/ally_icon.png",
  "TCAP": "https://assets.coingecko.com/coins/images/14703/thumb/tcap.png",
  "EPAN": "https://assets.coingecko.com/coins/images/13192/thumb/ava3.png",
  "LQT": "https://assets.coingecko.com/coins/images/16384/thumb/ktQzdAe.png",
  "RWS": "https://assets.coingecko.com/coins/images/11885/thumb/rws_logo.png",
  "NCDT": "https://assets.coingecko.com/coins/images/8932/thumb/nucloud_logo.png",
  "NUX": "https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg",
  "DESU": "https://assets.coingecko.com/coins/images/20583/thumb/desu.png",
  "SPFC": "https://assets.coingecko.com/coins/images/20462/thumb/SPFC.png",
  "GYSR": "https://assets.coingecko.com/coins/images/12995/thumb/logo_padded_shifted.png",
  "SWAPZ": "https://assets.coingecko.com/coins/images/16811/thumb/EWV5xQB.jpeg",
  "YFL": "https://assets.coingecko.com/coins/images/12081/thumb/YFLink.png",
  "MVD": "https://assets.coingecko.com/coins/images/23145/thumb/MeQ7gBjE_400x400.jpg",
  "SKM": "https://assets.coingecko.com/coins/images/3826/thumb/skrumble-network.png",
  "VERA": "https://assets.coingecko.com/coins/images/9766/thumb/hn9LRFGrVzyuPJrDIrVeCdUN2IfxIwWDk6mM5U9cGm_b0oPyENjOrXzTJ7vwHE65PIzM_H4nGlcHJHPBV5qHoQ8cfwRSud89RJHUngAGMnYIoLZ-UzEk9HuspfOmNu2Finm8RZW-UIM1_Jykw3Cj743iNPqjsqNxkuazLsa0h76oIwf61Sn3h9-IFiwgVLXyfRVuCO0bTY6q_aLJLwzE-P1OALNJfpLSVY52ygRlDGMZS1qj.jpg",
  "FYD": "https://assets.coingecko.com/coins/images/8139/thumb/48912706.png",
  "EUNO": "https://assets.coingecko.com/coins/images/5190/thumb/EUNO_LOGO.png",
  "SENSE": "https://assets.coingecko.com/coins/images/1488/thumb/sense.png",
  "ILSI": "https://assets.coingecko.com/coins/images/21788/thumb/16292.png",
  "PMA": "https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png",
  "JFI": "https://assets.coingecko.com/coins/images/12358/thumb/inKutD9.jpeg",
  "HUNNY": "https://assets.coingecko.com/coins/images/16043/thumb/hunny-logo.c87da9c4.png",
  "GSTS": "https://assets.coingecko.com/coins/images/21079/thumb/gst.png",
  "CURE": "https://assets.coingecko.com/coins/images/18881/thumb/CURE-v2-square.png",
  "PIRATE": "https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png",
  "GLINT": "https://assets.coingecko.com/coins/images/22893/thumb/glint.png",
  "SAV3": "https://assets.coingecko.com/coins/images/13132/thumb/sav3_logo.png",
  "COPE": "https://assets.coingecko.com/coins/images/28717/thumb/coingecko.png",
  "IXI": "https://assets.coingecko.com/coins/images/8368/thumb/ixi.png",
  "DMT": "https://assets.coingecko.com/coins/images/25171/thumb/JNBofoc.png",
  "PBTC35A": "https://assets.coingecko.com/coins/images/13653/thumb/pBTC35A.png",
  "EOSDAC": "https://assets.coingecko.com/coins/images/3878/thumb/2644.png",
  "ADAX": "https://assets.coingecko.com/coins/images/16768/thumb/adax.pro_icon_rpjihi.png",
  "UNIFI": "https://assets.coingecko.com/coins/images/12533/thumb/unifi_defi_logo.jpg",
  "DFAI": "https://assets.coingecko.com/coins/images/23345/thumb/dfai.png",
  "WARP": "https://assets.coingecko.com/coins/images/28895/thumb/Logo_warp_black_200x200.png",
  "LAVAX": "https://assets.coingecko.com/coins/images/15494/thumb/image0.jpeg",
  "IMT": "https://assets.coingecko.com/coins/images/28187/thumb/im200x200.png",
  "UCH": "https://assets.coingecko.com/coins/images/15140/thumb/IYEdWfZTNYCvfSeuiKw53HgCWRaPQm51um3zL7N5.png",
  "FALCX": "https://assets.coingecko.com/coins/images/19992/thumb/L55TBQhW_400x400.jpg",
  "BOT": "https://assets.coingecko.com/coins/images/21823/thumb/coin_%286%29.png",
  "MSCP": "https://assets.coingecko.com/coins/images/21142/thumb/14522.png",
  "SUNNY": "https://assets.coingecko.com/coins/images/18039/thumb/90dbe787-8e5f-473c-b923-fe138a7a30ea.png",
  "EZ": "https://assets.coingecko.com/coins/images/12742/thumb/Logo_Icon.png",
  "OCTO": "https://assets.coingecko.com/coins/images/27326/thumb/octo.png",
  "CFI": "https://assets.coingecko.com/coins/images/28809/thumb/coinbet-transparent.png",
  "USDR": "https://assets.coingecko.com/coins/images/26066/thumb/usdr_logo.png",
  "BBP": "https://assets.coingecko.com/coins/images/928/thumb/bible.png",
  "LFG": "https://assets.coingecko.com/coins/images/27669/thumb/lfg.png",
  "BFK": "https://assets.coingecko.com/coins/images/19387/thumb/faviconlogo.png",
  "KGO": "https://assets.coingecko.com/coins/images/14443/thumb/8VKJDPsp_400x400.jpg",
  "INXT": "https://assets.coingecko.com/coins/images/986/thumb/inxt.png",
  "NLIFE": "https://assets.coingecko.com/coins/images/16277/thumb/F6jp9eh.png",
  "ECTE": "https://assets.coingecko.com/coins/images/7634/thumb/logotoken200x200.png",
  "UBXS": "https://assets.coingecko.com/coins/images/22558/thumb/logo_200x200.png",
  "CCV2": "https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png",
  "CAI": "https://assets.coingecko.com/coins/images/20198/thumb/cai.PNG",
  "SKRT": "https://assets.coingecko.com/coins/images/15495/thumb/skrt.png",
  "PHX": "https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png",
  "IDEA": "https://assets.coingecko.com/coins/images/13938/thumb/idea_logo.png",
  "VIBE": "https://assets.coingecko.com/coins/images/948/thumb/vibe.png",
  "ITP": "https://assets.coingecko.com/coins/images/28338/thumb/ITP_Logo_200.png",
  "TRUE": "https://assets.coingecko.com/coins/images/20972/thumb/61962c17bdb496f689e0612d_Phoenix-Alpha-1_200x200.png",
  "UGOTCHI": "https://assets.coingecko.com/coins/images/14901/thumb/uGOTCHI.jpg",
  "PULI": "https://assets.coingecko.com/coins/images/22999/thumb/ITAVulj.png",
  "ZIPT": "https://assets.coingecko.com/coins/images/4302/thumb/zippie.jpg",
  "RAINBOWTOKEN": "https://assets.coingecko.com/coins/images/17828/thumb/WsLiOeJ.png",
  "TRTL": "https://assets.coingecko.com/coins/images/2892/thumb/turtlecoin.png",
  "PROPS": "https://assets.coingecko.com/coins/images/6735/thumb/photo-2017-10-10-03-32-02.png",
  "SG": "https://assets.coingecko.com/coins/images/3948/thumb/logo_200.png",
  "ATID": "https://assets.coingecko.com/coins/images/27082/thumb/Frame_55.jpg",
  "GLINK": "https://assets.coingecko.com/coins/images/24212/thumb/18770.png",
  "HGET": "https://assets.coingecko.com/coins/images/12453/thumb/Hedget.png",
  "LORD": "https://assets.coingecko.com/coins/images/18204/thumb/qkgkBYw.png",
  "SOD": "https://assets.coingecko.com/coins/images/22220/thumb/IMG-20220516-121514-817.jpg",
  "ACSI": "https://assets.coingecko.com/coins/images/13307/thumb/ACSI_200x200.png",
  "NINO": "https://assets.coingecko.com/coins/images/19936/thumb/CXZox2iX_400x400.jpg",
  "MTN": "https://assets.coingecko.com/coins/images/2850/thumb/medicalchain.png",
  "HAKKA": "https://assets.coingecko.com/coins/images/12163/thumb/Hakka-icon.png",
  "ARQ": "https://assets.coingecko.com/coins/images/6191/thumb/ms-icon-310x310.png",
  "GGTK": "https://assets.coingecko.com/coins/images/13666/thumb/ggtk.png",
  "LGO": "https://assets.coingecko.com/coins/images/28629/thumb/LGO-256.png",
  "TEM": "https://assets.coingecko.com/coins/images/9086/thumb/2020-05-06_9.08.45_PM.png",
  "ROCK": "https://assets.coingecko.com/coins/images/25147/thumb/96251267.png",
  "MINERS": "https://assets.coingecko.com/coins/images/18809/thumb/Miners_Defi_Logo_for_Coingecko.png",
  "ALA": "https://assets.coingecko.com/coins/images/18966/thumb/spV2Ly3gIaB9XVdib9FUJVPvfT6KBq2qQttTH275.png",
  "MOTA": "https://assets.coingecko.com/coins/images/8125/thumb/motacoin-logo.png",
  "EVE": "https://assets.coingecko.com/coins/images/2681/thumb/devery.png",
  "ELEC": "https://assets.coingecko.com/coins/images/3415/thumb/d45b1d82743c749d05697da200179874.jpg",
  "ZCL": "https://assets.coingecko.com/coins/images/540/thumb/zclassic.png",
  "BNI": "https://assets.coingecko.com/coins/images/27811/thumb/160.png",
  "MFAM": "https://assets.coingecko.com/coins/images/23914/thumb/moonwell-logo-200px.png",
  "FNDZ": "https://assets.coingecko.com/coins/images/18386/thumb/fndz.PNG",
  "LACE": "https://assets.coingecko.com/coins/images/20801/thumb/voOmcwrV_400x400.jpg",
  "PAK": "https://assets.coingecko.com/coins/images/607/thumb/pakcoin.png",
  "ARTE": "https://assets.coingecko.com/coins/images/23556/thumb/token_200.png",
  "STV": "https://assets.coingecko.com/coins/images/13842/thumb/STVV_TOKEN-10.png",
  "SNET": "https://assets.coingecko.com/coins/images/5382/thumb/snetwork.png",
  "DMG": "https://assets.coingecko.com/coins/images/11677/thumb/dmm.png",
  "PTF": "https://assets.coingecko.com/coins/images/28839/thumb/200x200potfolio.png",
  "EKTA": "https://assets.coingecko.com/coins/images/22350/thumb/token.jpg",
  "CUB": "https://assets.coingecko.com/coins/images/14743/thumb/cub.png",
  "CALI": "https://assets.coingecko.com/coins/images/14998/thumb/cropped-Logo-transparent-background-1.png",
  "NFTFY": "https://assets.coingecko.com/coins/images/15292/thumb/nfty.PNG",
  "SCC": "https://assets.coingecko.com/coins/images/7457/thumb/38614721_275755863227012_7516598818503131136_n.png",
  "PRX": "https://assets.coingecko.com/coins/images/7160/thumb/proxynode.png",
  "B20": "https://assets.coingecko.com/coins/images/13803/thumb/b20.png",
  "BASE": "https://assets.coingecko.com/coins/images/27683/thumb/IMG_1131.PNG",
  "IDH": "https://assets.coingecko.com/coins/images/1909/thumb/indahash.png",
  "ROCKI": "https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png",
  "UNQT": "https://assets.coingecko.com/coins/images/23382/thumb/D31DBA6B-FB7E-4ACB-82B4-98E255C1C483.jpg",
  "BFLY": "https://assets.coingecko.com/coins/images/13882/thumb/ButterflyProtocolNoText_sm.png",
  "COMB": "https://assets.coingecko.com/coins/images/22376/thumb/logo-background-circle-200x200.png",
  "ELFI": "https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png",
  "XIO": "https://assets.coingecko.com/coins/images/10029/thumb/blockzero.jpg",
  "HELMET": "https://assets.coingecko.com/coins/images/13680/thumb/ZMdK-1J4_400x400.png",
  "SOLAPE": "https://assets.coingecko.com/coins/images/16930/thumb/128px-coin.png",
  "UFR": "https://assets.coingecko.com/coins/images/1170/thumb/upfiring.png",
  "MEGA": "https://assets.coingecko.com/coins/images/28317/thumb/mega-protocol.png",
  "VSO": "https://assets.coingecko.com/coins/images/15169/thumb/versa.PNG",
  "ZERO": "https://assets.coingecko.com/coins/images/21530/thumb/UlyJW87D_400x400.jpg",
  "MVP": "https://assets.coingecko.com/coins/images/3483/thumb/feature_token.png",
  "TRIVIA": "https://assets.coingecko.com/coins/images/26303/thumb/trv-icon-full.png",
  "PNODE": "https://assets.coingecko.com/coins/images/15469/thumb/icon-Logo-pink.png",
  "PWAR": "https://assets.coingecko.com/coins/images/16776/thumb/polkawar.PNG",
  "DEFI++": "https://assets.coingecko.com/coins/images/13304/thumb/piedao__.png",
  "PBTC": "https://assets.coingecko.com/coins/images/25861/thumb/wMTpRljt_400x400.png",
  "TRY": "https://assets.coingecko.com/coins/images/13865/thumb/try-logo.png",
  "MUSK": "https://assets.coingecko.com/coins/images/6614/thumb/1aCM-fLB_400x400.jpg",
  "DEO": "https://assets.coingecko.com/coins/images/28811/thumb/logo_deo_small_200x200.png",
  "AZR": "https://assets.coingecko.com/coins/images/10412/thumb/aezora-app-icon-164x164-1.png",
  "VHC": "https://assets.coingecko.com/coins/images/23120/thumb/vhclogo.3929f10d.png",
  "PVU": "https://assets.coingecko.com/coins/images/17461/thumb/token-200x200.png",
  "RENZEC": "https://assets.coingecko.com/coins/images/11564/thumb/Zcash.jpg",
  "MOONED": "https://assets.coingecko.com/coins/images/17393/thumb/ME_logo_200_200.png",
  "EVED": "https://assets.coingecko.com/coins/images/7721/thumb/Variations-09.png",
  "COOKIE": "https://assets.coingecko.com/coins/images/27898/thumb/logo.png",
  "REL": "https://assets.coingecko.com/coins/images/11586/thumb/Relevant.png",
  "ANGEL": "https://assets.coingecko.com/coins/images/22094/thumb/LoZsoZZf_400x400.jpg",
  "HSC": "https://assets.coingecko.com/coins/images/4989/thumb/hashfuture-logo.png",
  "ZONE": "https://assets.coingecko.com/coins/images/23526/thumb/51ZrRDB1_400x400.jpg",
  "HOL": "https://assets.coingecko.com/coins/images/21538/thumb/hol.PNG",
  "TBFT": "https://assets.coingecko.com/coins/images/17649/thumb/tbft.png",
  "NAFT": "https://assets.coingecko.com/coins/images/15493/thumb/Nafter_White_logo_1_200x200.png",
  "ENG": "https://assets.coingecko.com/coins/images/26579/thumb/CoinGecko_ENG_Logo.png",
  "ACCEL": "https://assets.coingecko.com/coins/images/22405/thumb/accel_new.png",
  "TFI": "https://assets.coingecko.com/coins/images/16297/thumb/frny3dSs_400x400.jpg",
  "GHNY": "https://assets.coingecko.com/coins/images/26816/thumb/GHNY-token-logo.png",
  "AXIS": "https://assets.coingecko.com/coins/images/16972/thumb/lUeFYUeu_400x400.png",
  "ENTR": "https://assets.coingecko.com/coins/images/18684/thumb/entr.png",
  "PTM": "https://assets.coingecko.com/coins/images/2349/thumb/ptm.png",
  "WAIFU": "https://assets.coingecko.com/coins/images/17045/thumb/waifu.png",
  "CORGI": "https://assets.coingecko.com/coins/images/15646/thumb/mWum355Y_400x400.jpg",
  "RFT": "https://assets.coingecko.com/coins/images/17644/thumb/rft.png",
  "SWM": "https://assets.coingecko.com/coins/images/197/thumb/swarm.jpg",
  "TAB": "https://assets.coingecko.com/coins/images/17549/thumb/Mat_sau_512.png",
  "GMAT": "https://assets.coingecko.com/coins/images/7162/thumb/qXNbxVny_400x400.png",
  "GEO": "https://assets.coingecko.com/coins/images/658/thumb/geocoin.png",
  "HER": "https://assets.coingecko.com/coins/images/18571/thumb/hero.png",
  "MARK": "https://assets.coingecko.com/coins/images/24657/thumb/16089.png",
  "POG": "https://assets.coingecko.com/coins/images/28649/thumb/nav1.png",
  "TASTE": "https://assets.coingecko.com/coins/images/15532/thumb/3C1Qq1yf_400x400.jpg",
  "FRY": "https://assets.coingecko.com/coins/images/12348/thumb/fry-icon.png",
  "AXI": "https://assets.coingecko.com/coins/images/12562/thumb/axioms_logo.png",
  "TPRO": "https://assets.coingecko.com/coins/images/26694/thumb/tpro-logo-200x200.png",
  "ARCH": "https://assets.coingecko.com/coins/images/28970/thumb/arch.png",
  "VADER": "https://assets.coingecko.com/coins/images/20497/thumb/AcF08Jk1_400x400.jpg",
  "METH": "https://assets.coingecko.com/coins/images/14059/thumb/mirror_logo_transparent.png",
  "STN": "https://assets.coingecko.com/coins/images/13760/thumb/sting.png",
  "ALYA": "https://assets.coingecko.com/coins/images/22645/thumb/lEKKpUDi_400x400.jpg",
  "VDL": "https://assets.coingecko.com/coins/images/6893/thumb/vidulum.png",
  "SCRL": "https://assets.coingecko.com/coins/images/21046/thumb/scroll_200.png",
  "GEN": "https://assets.coingecko.com/coins/images/27416/thumb/JDqtkClG_400x400.jpeg",
  "XPRO": "https://assets.coingecko.com/coins/images/26688/thumb/logo200x200circle.png",
  "DFY": "https://assets.coingecko.com/coins/images/14561/thumb/DFY-200x200.png",
  "CANDY": "https://assets.coingecko.com/coins/images/28505/thumb/TTndY-iM_400x400.png",
  "NAMI": "https://assets.coingecko.com/coins/images/13729/thumb/nami_logo_256.png",
  "SEEN": "https://assets.coingecko.com/coins/images/13052/thumb/seen_logo.png",
  "CLMRS": "https://assets.coingecko.com/coins/images/26385/thumb/resized-image-Promo-removebg-preview.png",
  "BRY": "https://assets.coingecko.com/coins/images/13987/thumb/berry.jpg",
  "BCOIN": "https://assets.coingecko.com/coins/images/18567/thumb/bcoin.png",
  "KDC": "https://assets.coingecko.com/coins/images/17243/thumb/dronefly_symbol_200x200.png",
  "IB": "https://assets.coingecko.com/coins/images/22902/thumb/ironbank.png",
  "LFI": "https://assets.coingecko.com/coins/images/24594/thumb/lfi.png",
  "KTON": "https://assets.coingecko.com/coins/images/11895/thumb/KTON.png",
  "AKRO": "https://assets.coingecko.com/coins/images/3328/thumb/Akropolis.png",
  "TRC": "https://assets.coingecko.com/coins/images/28564/thumb/tracer.png",
  "EXRN": "https://assets.coingecko.com/coins/images/1049/thumb/exrn.png",
  "UIP": "https://assets.coingecko.com/coins/images/2642/thumb/unlimitedip.png",
  "ZEFU": "https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg",
  "TYC": "https://assets.coingecko.com/coins/images/17516/thumb/200x200_%2826%29.png",
  "GLIDE": "https://assets.coingecko.com/coins/images/24854/thumb/logo_big.png",
  "FIRA": "https://assets.coingecko.com/coins/images/27211/thumb/fira-flat.png",
  "CV": "https://assets.coingecko.com/coins/images/2098/thumb/carvertical.png",
  "ISHND": "https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png",
  "FSW": "https://assets.coingecko.com/coins/images/12256/thumb/falconswap.png",
  "FORM": "https://assets.coingecko.com/coins/images/16145/thumb/FORM.png",
  "BCPT": "https://assets.coingecko.com/coins/images/1022/thumb/mason.jpg",
  "SUB": "https://assets.coingecko.com/coins/images/950/thumb/substratum.png",
  "SAM": "https://assets.coingecko.com/coins/images/19914/thumb/sam1.png",
  "ALL": "https://assets.coingecko.com/coins/images/13841/thumb/Alliance-10.png",
  "QRX": "https://assets.coingecko.com/coins/images/12916/thumb/qrx_logo.png",
  "NSURE": "https://assets.coingecko.com/coins/images/12948/thumb/Nsure_token.png",
  "SPHRI": "https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png",
  "SOLAR": "https://assets.coingecko.com/coins/images/27743/thumb/solar.png",
  "LOVE": "https://assets.coingecko.com/coins/images/21864/thumb/cbV2t9V.jpeg",
  "CALO": "https://assets.coingecko.com/coins/images/21875/thumb/CALO-Token_200x200.png",
  "CO": "https://assets.coingecko.com/coins/images/25969/thumb/Corite-symbol-red-neg-2000px.png",
  "FTI": "https://assets.coingecko.com/coins/images/4952/thumb/fanstime.png",
  "CAVE": "https://assets.coingecko.com/coins/images/19358/thumb/token.png",
  "SEFI": "https://assets.coingecko.com/coins/images/14601/thumb/_Icon___SVG__Primary_-_Metallic_Gold_in_Circle_%282%29.png",
  "TOK": "https://assets.coingecko.com/coins/images/26510/thumb/tok%281%29.png",
  "ETH2X-FLI-P": "https://assets.coingecko.com/coins/images/21183/thumb/ETH2x-FLO_token_logo.jpg",
  "LUCHOW": "https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg",
  "RAID": "https://assets.coingecko.com/coins/images/28923/thumb/RaiderInuLogo.png",
  "WAG": "https://assets.coingecko.com/coins/images/18696/thumb/waggle.PNG",
  "YLA": "https://assets.coingecko.com/coins/images/14447/thumb/yla.png",
  "CRX": "https://assets.coingecko.com/coins/images/26556/thumb/Wn48hpj7_400x400.jpeg",
  "FS": "https://assets.coingecko.com/coins/images/19821/thumb/13237.png",
  "MAGE": "https://assets.coingecko.com/coins/images/21244/thumb/MAGE-CG.png",
  "MON": "https://assets.coingecko.com/coins/images/27493/thumb/logo-moneta.png",
  "MASS": "https://assets.coingecko.com/coins/images/28342/thumb/_AollWG0_400x400.jpeg",
  "SURF": "https://assets.coingecko.com/coins/images/10783/thumb/200x200-logo-blu-grey-bkg-4-e1583512409629.png",
  "GRO": "https://assets.coingecko.com/coins/images/18673/thumb/613f171979749061aaa1edf9_Icon-GRO-256x256-Square.png",
  "TENFI": "https://assets.coingecko.com/coins/images/15631/thumb/TEN-Square200.png",
  "BITT": "https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png",
  "RIC": "https://assets.coingecko.com/coins/images/21734/thumb/QmVRVhNWj2Q2qZG3WBSRfwwF6x6onLtSE4MbxvHu3uudW4.png",
  "LAND": "https://assets.coingecko.com/coins/images/24531/thumb/META-UTOPIA__logo-02.png",
  "SHUEY": "https://assets.coingecko.com/coins/images/24306/thumb/shuey.jpg",
  "QRK": "https://assets.coingecko.com/coins/images/10/thumb/qrk.png",
  "VEIL": "https://assets.coingecko.com/coins/images/7771/thumb/Veil.png",
  "DHV": "https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png",
  "EMON": "https://assets.coingecko.com/coins/images/15889/thumb/LtET0reH_400x400.jpg",
  "TFC": "https://assets.coingecko.com/coins/images/13486/thumb/tfc_logo.png",
  "DOWS": "https://assets.coingecko.com/coins/images/14160/thumb/C3E49eZx_400x400.jpg",
  "DDOS": "https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png",
  "MOO": "https://assets.coingecko.com/coins/images/21177/thumb/moo.png",
  "LND": "https://assets.coingecko.com/coins/images/2988/thumb/LB_Icon__L.png",
  "SOLX": "https://assets.coingecko.com/coins/images/21362/thumb/output-onlinepngtools_%2811%29.png",
  "RUFF": "https://assets.coingecko.com/coins/images/2726/thumb/ruff.png",
  "ALGOBLK": "https://assets.coingecko.com/coins/images/25120/thumb/tncthV9K_400x400.jpg",
  "UMX": "https://assets.coingecko.com/coins/images/13615/thumb/sloYxHx.jpeg",
  "GOF": "https://assets.coingecko.com/coins/images/27477/thumb/Token_GOF.png",
  "PIN": "https://assets.coingecko.com/coins/images/22482/thumb/PINlogo.png",
  "MIMAS": "https://assets.coingecko.com/coins/images/22516/thumb/mimas.PNG",
  "BRIGHT": "https://assets.coingecko.com/coins/images/18415/thumb/bright.PNG",
  "NBT": "https://assets.coingecko.com/coins/images/23698/thumb/WpcmdLW.png",
  "DRC": "https://assets.coingecko.com/coins/images/13457/thumb/drc_symbol.png",
  "SNTR": "https://assets.coingecko.com/coins/images/19268/thumb/sentre.PNG",
  "FUNEX": "https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png",
  "LUC": "https://assets.coingecko.com/coins/images/1772/thumb/luc.png",
  "D": "https://assets.coingecko.com/coins/images/760/thumb/denarius.png",
  "MILKY": "https://assets.coingecko.com/coins/images/20269/thumb/milky.png",
  "GDAO": "https://assets.coingecko.com/coins/images/13140/thumb/GDAOlogo2-bird.png",
  "DFIAT": "https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png",
  "L2": "https://assets.coingecko.com/coins/images/12950/thumb/Gluon256x256.png",
  "KTLYO": "https://assets.coingecko.com/coins/images/13347/thumb/katalyo_logo_aqua_256.png",
  "XGT": "https://assets.coingecko.com/coins/images/14039/thumb/xgt.png",
  "SOMEE": "https://assets.coingecko.com/coins/images/16406/thumb/SoMeeBallLogo200x200.png",
  "ISO": "https://assets.coingecko.com/coins/images/23765/thumb/logo_%283%29.png",
  "KART": "https://assets.coingecko.com/coins/images/19916/thumb/kart.png",
  "SOLACE": "https://assets.coingecko.com/coins/images/4202/thumb/_hgaMpnx_400x400.jpg",
  "USTX": "https://assets.coingecko.com/coins/images/20225/thumb/logoSolid200.png",
  "WPR": "https://assets.coingecko.com/coins/images/1951/thumb/wpr.png",
  "POA": "https://assets.coingecko.com/coins/images/27896/thumb/POA.png",
  "MORE": "https://assets.coingecko.com/coins/images/28825/thumb/cg.png",
  "PP": "https://assets.coingecko.com/coins/images/19670/thumb/pensionplan-logo-200CG2.png",
  "OST": "https://assets.coingecko.com/coins/images/1367/thumb/ost.jpg",
  "PYM": "https://assets.coingecko.com/coins/images/20682/thumb/eALRa3rZ_400x400.png",
  "HNTR": "https://assets.coingecko.com/coins/images/27793/thumb/Hunter-Logo-large.png",
  "YOYOW": "https://assets.coingecko.com/coins/images/1270/thumb/yoyow.png",
  "MUDOL2": "https://assets.coingecko.com/coins/images/26432/thumb/iShot_2022-07-16_09.59.28.png",
  "SENSI": "https://assets.coingecko.com/coins/images/25449/thumb/xdirLdlX_400x400.png",
  "CHEEMS": "https://assets.coingecko.com/coins/images/18358/thumb/newlogo.png",
  "MT": "https://assets.coingecko.com/coins/images/27457/thumb/mt_logo_200x200.png",
  "SPWN": "https://assets.coingecko.com/coins/images/16513/thumb/token_logo.png",
  "YF-DAI": "https://assets.coingecko.com/coins/images/12385/thumb/1619048513068.png",
  "IRD": "https://assets.coingecko.com/coins/images/6208/thumb/iridium.png",
  "ABST": "https://assets.coingecko.com/coins/images/7882/thumb/ABST.png",
  "ASTRO": "https://assets.coingecko.com/coins/images/26817/thumb/logoredonda111.png",
  "HVN": "https://assets.coingecko.com/coins/images/899/thumb/Hiveterminal_token.jpg",
  "INF": "https://assets.coingecko.com/coins/images/14291/thumb/logo3_new.png",
  "MILK2": "https://assets.coingecko.com/coins/images/12771/thumb/milk.png",
  "KAWA": "https://assets.coingecko.com/coins/images/16369/thumb/kawakami-2022-red-logo.png",
  "SKMT": "https://assets.coingecko.com/coins/images/19014/thumb/Soakmont_-_Icone_RGB_Normal.jpeg",
  "DZG": "https://assets.coingecko.com/coins/images/18757/thumb/dinamo.png",
  "EBA": "https://assets.coingecko.com/coins/images/20642/thumb/B%E1%BA%A3n_sao_c%E1%BB%A7a_token_EBA.png",
  "BTC++": "https://assets.coingecko.com/coins/images/10959/thumb/BTC__.png",
  "SPHR": "https://assets.coingecko.com/coins/images/1199/thumb/w001_JR4_400x400.jpg",
  "BTB": "https://assets.coingecko.com/coins/images/97/thumb/bitbar-logo-128.png",
  "BNSD": "https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png",
  "AID": "https://assets.coingecko.com/coins/images/2144/thumb/aid.png",
  "OWN": "https://assets.coingecko.com/coins/images/10950/thumb/cropped-WhatsApp-Image-2020-04-04-at-9.04.01-PM.jpeg",
  "THN": "https://assets.coingecko.com/coins/images/16965/thumb/aXhD3i_g_400x400.jpg",
  "LEG": "https://assets.coingecko.com/coins/images/15138/thumb/rhY1Ak6ANp5oA7AhgQgpr5SVwtO0pRP3UKOjqSzm.png",
  "$LORDZ": "https://assets.coingecko.com/coins/images/17412/thumb/meme-lordz-icon-green.png",
  "ZDEX": "https://assets.coingecko.com/coins/images/12412/thumb/Untitled-design-4.png",
  "KACY": "https://assets.coingecko.com/coins/images/22918/thumb/kacy.png",
  "SVD": "https://assets.coingecko.com/coins/images/2852/thumb/savedroid.png",
  "KDG": "https://assets.coingecko.com/coins/images/10914/thumb/8VIE2nF1_400x400.jpg",
  "WASP": "https://assets.coingecko.com/coins/images/15183/thumb/3l8ivBT.png",
  "QANX": "https://assets.coingecko.com/coins/images/15977/thumb/qanx.png",
  "WIRTUAL": "https://assets.coingecko.com/coins/images/24435/thumb/jxtu9s6F_400x400_%281%29.jpg",
  "RED": "https://assets.coingecko.com/coins/images/27070/thumb/RED.png",
  "RAC": "https://assets.coingecko.com/coins/images/25037/thumb/pyxqYYA2gUM5v0YMaFI3kOGXQ-B89GxbmqB0ecv1XFM.png",
  "TOON": "https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG",
  "MER": "https://assets.coingecko.com/coins/images/1236/thumb/mercury.png",
  "COIN": "https://assets.coingecko.com/coins/images/12375/thumb/coin_artist_logo.png",
  "FOUR": "https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png",
  "0XMR": "https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG",
  "VKR": "https://assets.coingecko.com/coins/images/19255/thumb/logo_-_2021-10-22T091048.505.png",
  "ZORA": "https://assets.coingecko.com/coins/images/13255/thumb/zora.png",
  "YIELD": "https://assets.coingecko.com/coins/images/16656/thumb/polylogo.png",
  "FWCL": "https://assets.coingecko.com/coins/images/28315/thumb/fwcl.png",
  "KUS": "https://assets.coingecko.com/coins/images/16917/thumb/kus.PNG",
  "FBL": "https://assets.coingecko.com/coins/images/26124/thumb/logo.png",
  "$ICONS": "https://assets.coingecko.com/coins/images/20856/thumb/icons-coin-gold.png",
  "SNK": "https://assets.coingecko.com/coins/images/19660/thumb/icon_%289%29.png",
  "WATCH": "https://assets.coingecko.com/coins/images/14186/thumb/WATCH1.png",
  "APY": "https://assets.coingecko.com/coins/images/13041/thumb/1*AvkD-OLocausbxqUzezZ0A.png",
  "IME": "https://assets.coingecko.com/coins/images/21933/thumb/X6_UI3rs_400x400.jpg",
  "KALLY": "https://assets.coingecko.com/coins/images/15812/thumb/polkally.PNG",
  "SYC": "https://assets.coingecko.com/coins/images/28267/thumb/9DC88DDF-CAB3-410A-87BA-6AB98DCD1B86.jpeg",
  "GFN": "https://assets.coingecko.com/coins/images/19159/thumb/Graphene-Logo.png",
  "ASTAR": "https://assets.coingecko.com/coins/images/24355/thumb/FB_IMG_1643688458160.jpg",
  "KANGAL": "https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png",
  "FIU": "https://assets.coingecko.com/coins/images/25995/thumb/FIU_Token.jpg",
  "MOFI": "https://assets.coingecko.com/coins/images/14697/thumb/MOFI_Coin_Green_200x200.png",
  "COLI": "https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png",
  "CHART": "https://assets.coingecko.com/coins/images/12523/thumb/chartex.png",
  "AGA": "https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png",
  "PGEN": "https://assets.coingecko.com/coins/images/21476/thumb/polygen-logo_1.jpeg",
  "TOSHI": "https://assets.coingecko.com/coins/images/28959/thumb/toshi_logo_200_by_200.png",
  "DOGO": "https://assets.coingecko.com/coins/images/17480/thumb/dogemongo.PNG",
  "DENA": "https://assets.coingecko.com/coins/images/22167/thumb/denations.PNG",
  "UNN": "https://assets.coingecko.com/coins/images/13408/thumb/unn_finance.png",
  "CORGIB": "https://assets.coingecko.com/coins/images/16069/thumb/corbi.PNG",
  "XMX": "https://assets.coingecko.com/coins/images/4643/thumb/xmx.png",
  "ASAP": "https://assets.coingecko.com/coins/images/14945/thumb/chainswap_logo.png",
  "RUBY": "https://assets.coingecko.com/coins/images/24301/thumb/v7j5bIgX_400x400.jpg",
  "PRCY": "https://assets.coingecko.com/coins/images/14151/thumb/prcy.png",
  "SAK3": "https://assets.coingecko.com/coins/images/15890/thumb/Sak3.jpg",
  "MERGE": "https://assets.coingecko.com/coins/images/8769/thumb/Merge_Icon_Colored.png",
  "FDZ": "https://assets.coingecko.com/coins/images/3685/thumb/download_%2812%29.png",
  "CNTR": "https://assets.coingecko.com/coins/images/12743/thumb/logo_%2898%29.png",
  "UGAS": "https://assets.coingecko.com/coins/images/4684/thumb/ultrain.png",
  "DEXTF": "https://assets.coingecko.com/coins/images/12634/thumb/0qgT0aMu_400x400.jpg",
  "GIO": "https://assets.coingecko.com/coins/images/5431/thumb/graviocoin.png",
  "USDB": "https://assets.coingecko.com/coins/images/10619/thumb/busd.png",
  "DYOR": "https://assets.coingecko.com/coins/images/23654/thumb/13987.png",
  "KEK": "https://assets.coingecko.com/coins/images/27061/thumb/20220816_155427.png",
  "DPY": "https://assets.coingecko.com/coins/images/1133/thumb/delphy.png",
  "IUSDS": "https://assets.coingecko.com/coins/images/25698/thumb/iUSDS2.png",
  "PINKM": "https://assets.coingecko.com/coins/images/15018/thumb/pink-logo-200.png",
  "PRARE": "https://assets.coingecko.com/coins/images/15388/thumb/Image_from_iOS.png",
  "SHIH": "https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG",
  "CLU": "https://assets.coingecko.com/coins/images/15685/thumb/CoinGecko.png",
  "PNL": "https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png",
  "BITTO": "https://assets.coingecko.com/coins/images/2814/thumb/BITTO.jpg",
  "PFL": "https://assets.coingecko.com/coins/images/28706/thumb/PFL_token.png",
  "SEBA": "https://assets.coingecko.com/coins/images/24475/thumb/seba.png",
  "COR": "https://assets.coingecko.com/coins/images/19326/thumb/bitcor.png",
  "WINGS": "https://assets.coingecko.com/coins/images/648/thumb/wings.png",
  "IAI": "https://assets.coingecko.com/coins/images/24221/thumb/iAI.jpg",
  "POLI": "https://assets.coingecko.com/coins/images/18096/thumb/Polinate_Games___Guilds_Elements-05.png",
  "ETHY": "https://assets.coingecko.com/coins/images/13191/thumb/rOIuPZM.png",
  "MGO": "https://assets.coingecko.com/coins/images/768/thumb/mobilego.png",
  "COMT": "https://assets.coingecko.com/coins/images/22353/thumb/500x500comt.png",
  "METAL": "https://assets.coingecko.com/coins/images/27222/thumb/metal-chain.png",
  ".ALPHA": "https://assets.coingecko.com/coins/images/24340/thumb/alpha_icon.png",
  "PEFI": "https://assets.coingecko.com/coins/images/26159/thumb/20747.png",
  "SKUY": "https://assets.coingecko.com/coins/images/23788/thumb/SKUY_TOKEN_%282%29.png",
  "EFT": "https://assets.coingecko.com/coins/images/22612/thumb/eft.png",
  "TSX": "https://assets.coingecko.com/coins/images/15229/thumb/WsO9siKG_400x400.png",
  "WEVE": "https://assets.coingecko.com/coins/images/22741/thumb/S0aENDh0_400x400.jpg",
  "ECD": "https://assets.coingecko.com/coins/images/24000/thumb/ecd.png",
  "WTT": "https://assets.coingecko.com/coins/images/907/thumb/wtt.png",
  "DANA": "https://assets.coingecko.com/coins/images/18440/thumb/ardana.PNG",
  "KOBE": "https://assets.coingecko.com/coins/images/13577/thumb/shabu_shabu_logo.jpg",
  "MU": "https://assets.coingecko.com/coins/images/25301/thumb/2zkUxCiY_400x400.jpg",
  "MRCR": "https://assets.coingecko.com/coins/images/15938/thumb/6LIc1-Sm_400x400.png",
  "CRW": "https://assets.coingecko.com/coins/images/27098/thumb/coincrowdify2.png",
  "ZSC": "https://assets.coingecko.com/coins/images/1009/thumb/zeusshield.png",
  "N1": "https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png",
  "KFT": "https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg",
  "ADABOY": "https://assets.coingecko.com/coins/images/19096/thumb/ADABoy-Trans.png",
  "INK": "https://assets.coingecko.com/coins/images/23511/thumb/0xffabb85adb5c25d57343547a8b32b62f03814b12.png",
  "ETHYS": "https://assets.coingecko.com/coins/images/13328/thumb/eths_logo.png",
  "PECO": "https://assets.coingecko.com/coins/images/19270/thumb/amun-peco.png",
  "NDS": "https://assets.coingecko.com/coins/images/14658/thumb/n1EiZLt.png",
  "FF": "https://assets.coingecko.com/coins/images/14659/thumb/N2kir6jx_400x400.jpg",
  "APC": "https://assets.coingecko.com/coins/images/28685/thumb/APC.png",
  "EVX": "https://assets.coingecko.com/coins/images/997/thumb/everex.png",
  "AXPR": "https://assets.coingecko.com/coins/images/2682/thumb/6QRknY7.png",
  "RENA": "https://assets.coingecko.com/coins/images/15667/thumb/photo_2021-05-21_17-31-35.png",
  "SWT": "https://assets.coingecko.com/coins/images/18159/thumb/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF_%D0%BD%D0%B0_%D0%B1%D0%B5%D0%BB%D0%BE%D0%BC_%D1%84%D0%BE%D0%BD%D0%B5.png",
  "KIF": "https://assets.coingecko.com/coins/images/12340/thumb/SnQPkABT_400x400.png",
  "MERKLE": "https://assets.coingecko.com/coins/images/19838/thumb/merkle.PNG",
  "DOS": "https://assets.coingecko.com/coins/images/7991/thumb/DOS.png",
  "ETNA": "https://assets.coingecko.com/coins/images/14518/thumb/EtnaNewLogo200x200.png",
  "RACING": "https://assets.coingecko.com/coins/images/23144/thumb/racing.png",
  "YSL": "https://assets.coingecko.com/coins/images/17466/thumb/YSL.png",
  "DOGEC": "https://assets.coingecko.com/coins/images/6421/thumb/original.png",
  "SDOGE": "https://assets.coingecko.com/coins/images/23742/thumb/sdoge.png",
  "BAC": "https://assets.coingecko.com/coins/images/13246/thumb/BAC.png",
  "ODE": "https://assets.coingecko.com/coins/images/3752/thumb/odem.png",
  "DINU": "https://assets.coingecko.com/coins/images/27001/thumb/logo.png",
  "KITTY": "https://assets.coingecko.com/coins/images/17037/thumb/KITTY.png",
  "NASSR": "https://assets.coingecko.com/coins/images/25121/thumb/r4YopolZ_400x400.jpg",
  "PFI": "https://assets.coingecko.com/coins/images/26981/thumb/Logo200x200.png",
  "FOXGIRL": "https://assets.coingecko.com/coins/images/19448/thumb/foxgirl.png",
  "TUS": "https://assets.coingecko.com/coins/images/20012/thumb/img-tus.png",
  "HEROEGG": "https://assets.coingecko.com/coins/images/18249/thumb/herofi.png",
  "GARD": "https://assets.coingecko.com/coins/images/26827/thumb/gard.png",
  "CAG": "https://assets.coingecko.com/coins/images/1021/thumb/200x200.png",
  "ZODI": "https://assets.coingecko.com/coins/images/21181/thumb/s4TSr4S_-_Imgur.png",
  "BNPL": "https://assets.coingecko.com/coins/images/18543/thumb/Untitled_%28200_%C3%97_200_px%29_%281%29.png",
  "GMI": "https://assets.coingecko.com/coins/images/22748/thumb/glTVHgq__400x400.jpg",
  "GOD": "https://assets.coingecko.com/coins/images/2543/thumb/bitcoin-god.png",
  "DYNA": "https://assets.coingecko.com/coins/images/28962/thumb/d-200px.png",
  "CROP": "https://assets.coingecko.com/coins/images/19910/thumb/farmer.jpeg",
  "LEAG": "https://assets.coingecko.com/coins/images/19130/thumb/leag_token_icon.png",
  "CNS": "https://assets.coingecko.com/coins/images/11864/thumb/CNS.png",
  "RABBIT": "https://assets.coingecko.com/coins/images/28476/thumb/200.png",
  "AIRX": "https://assets.coingecko.com/coins/images/9201/thumb/Aircoins.png",
  "CASH": "https://assets.coingecko.com/coins/images/27558/thumb/qRnUZoW2_400x400.jpeg",
  "POT": "https://assets.coingecko.com/coins/images/19924/thumb/pot.png",
  "XMY": "https://assets.coingecko.com/coins/images/66/thumb/myriadcoin.png",
  "APRT": "https://assets.coingecko.com/coins/images/20636/thumb/hF_3FMuH_400x400.jpg",
  "SEAN": "https://assets.coingecko.com/coins/images/27533/thumb/SEAN_Token_icon.png",
  "EDN": "https://assets.coingecko.com/coins/images/3292/thumb/Eden.png",
  "MBTC": "https://assets.coingecko.com/coins/images/16086/thumb/bitcoin-96x96.png",
  "MOBIC": "https://assets.coingecko.com/coins/images/11949/thumb/mobic-logo-white-transparent.png",
  "MOLA": "https://assets.coingecko.com/coins/images/16278/thumb/NW8RySX.png",
  "FUEL": "https://assets.coingecko.com/coins/images/1082/thumb/etherparty.png",
  "MXT": "https://assets.coingecko.com/coins/images/12148/thumb/MXT_LOGO_200_200.png",
  "YIN": "https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg",
  "NEU": "https://assets.coingecko.com/coins/images/28813/thumb/NEU_Token_logo.png",
  "FAMILY": "https://assets.coingecko.com/coins/images/13776/thumb/the_bitcoin_family.png",
  "DONUT": "https://assets.coingecko.com/coins/images/7538/thumb/Donut.png",
  "CNNS": "https://assets.coingecko.com/coins/images/8362/thumb/cnns.png",
  "FIN": "https://assets.coingecko.com/coins/images/25990/thumb/pxRtmF77_400x400.png",
  "DEFO": "https://assets.coingecko.com/coins/images/13168/thumb/defhold_logo.png",
  "TECH": "https://assets.coingecko.com/coins/images/17983/thumb/tech.png",
  "UUU": "https://assets.coingecko.com/coins/images/3877/thumb/2645.png",
  "DRT": "https://assets.coingecko.com/coins/images/1030/thumb/domraider.png",
  "LEPA": "https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG",
  "XM": "https://assets.coingecko.com/coins/images/20576/thumb/0d6414_04bb2c3af25b4e51835d91bc89a8c8f6_mv2.png",
  "BNS": "https://assets.coingecko.com/coins/images/25150/thumb/bitbns_logo.png",
  "ALPA": "https://assets.coingecko.com/coins/images/13070/thumb/alpaca_logo.png",
  "RIA": "https://assets.coingecko.com/coins/images/28817/thumb/icon200x200.png",
  "MMAON": "https://assets.coingecko.com/coins/images/14645/thumb/MMAON.png",
  "ZPTC": "https://assets.coingecko.com/coins/images/19109/thumb/2000x2000zptc_square3_%281%29.png",
  "TMFT": "https://assets.coingecko.com/coins/images/26020/thumb/e50df75e-f7c1-406a-af81-4010b316cf6f.png",
  "NMS": "https://assets.coingecko.com/coins/images/21486/thumb/nms_bsc.png",
  "QUAN": "https://assets.coingecko.com/coins/images/4752/thumb/1huxJr5.png",
  "AZUKI": "https://assets.coingecko.com/coins/images/13091/thumb/bdUBSCo.png",
  "ATL": "https://assets.coingecko.com/coins/images/19362/thumb/atl.png",
  "NFTP": "https://assets.coingecko.com/coins/images/14543/thumb/nftp-light-circular.c54a6825.png",
  "PAD": "https://assets.coingecko.com/coins/images/25860/thumb/wMTpRljt_400x400.png",
  "BITORB": "https://assets.coingecko.com/coins/images/19850/thumb/bitorbit.PNG",
  "GAJ": "https://assets.coingecko.com/coins/images/15257/thumb/logo200x200.png",
  "TNC": "https://assets.coingecko.com/coins/images/27949/thumb/Untitled_design_%2844%29.png",
  "PIF": "https://assets.coingecko.com/coins/images/22904/thumb/pif.png",
  "HQX": "https://assets.coingecko.com/coins/images/1793/thumb/hqx.png",
  "WG0": "https://assets.coingecko.com/coins/images/12870/thumb/wg0_logo.png",
  "ETGP": "https://assets.coingecko.com/coins/images/7337/thumb/llxg4bqE_400x400.jpg",
  "NR1": "https://assets.coingecko.com/coins/images/17763/thumb/nr1.png",
  "RDR": "https://assets.coingecko.com/coins/images/22294/thumb/nlXMSc9__400x400.jpg",
  "GLTR": "https://assets.coingecko.com/coins/images/25790/thumb/gltr-token.png",
  "BITG": "https://assets.coingecko.com/coins/images/3467/thumb/BITG.png",
  "XIV": "https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg",
  "RVRS": "https://assets.coingecko.com/coins/images/18109/thumb/AezG5si_%281%29.png",
  "OSWAP": "https://assets.coingecko.com/coins/images/15785/thumb/openswap.PNG",
  "SHARD": "https://assets.coingecko.com/coins/images/24017/thumb/hello_gophers_logo.png",
  "TCC": "https://assets.coingecko.com/coins/images/1197/thumb/tcc_logo.jpg",
  "FIWA": "https://assets.coingecko.com/coins/images/27810/thumb/admin-ajax.png",
  "WSWAP": "https://assets.coingecko.com/coins/images/17506/thumb/wswap_200.png",
  "CVR": "https://assets.coingecko.com/coins/images/13720/thumb/cvr.png",
  "MEED": "https://assets.coingecko.com/coins/images/24281/thumb/s-YfFWYu_400x400.png",
  "TELOS": "https://assets.coingecko.com/coins/images/5648/thumb/image5.png",
  "CATO": "https://assets.coingecko.com/coins/images/5666/thumb/catocoin.png",
  "PRT": "https://assets.coingecko.com/coins/images/18443/thumb/PRT.png",
  "FIGHT": "https://assets.coingecko.com/coins/images/21695/thumb/zGwwAgL.png",
  "SVT": "https://assets.coingecko.com/coins/images/15820/thumb/pf-46468a65--TRANS.png",
  "BZN": "https://assets.coingecko.com/coins/images/17368/thumb/bzn-logo-200.png",
  "SAO": "https://assets.coingecko.com/coins/images/19410/thumb/sator-logo-CMC.png",
  "VERVE": "https://assets.coingecko.com/coins/images/22804/thumb/16902.png",
  "QBT": "https://assets.coingecko.com/coins/images/17957/thumb/qubit.PNG",
  "APPC": "https://assets.coingecko.com/coins/images/1876/thumb/appcoins.png",
  "TCP": "https://assets.coingecko.com/coins/images/15054/thumb/tcp.PNG",
  "NYZO": "https://assets.coingecko.com/coins/images/7888/thumb/pkEprs8-.png",
  "WSPP": "https://assets.coingecko.com/coins/images/19514/thumb/wspplogo.png",
  "PXLC": "https://assets.coingecko.com/coins/images/18793/thumb/pixl_coin.jpg",
  "CABO": "https://assets.coingecko.com/coins/images/20917/thumb/cabo.png",
  "DIFF": "https://assets.coingecko.com/coins/images/25331/thumb/photo5451952870917257644.jpg",
  "ADT": "https://assets.coingecko.com/coins/images/22802/thumb/Logo_Dot_Arcade.png",
  "VIG": "https://assets.coingecko.com/coins/images/10445/thumb/vigor__Badge.png",
  "NAME": "https://assets.coingecko.com/coins/images/14832/thumb/name.png",
  "CSWAP": "https://assets.coingecko.com/coins/images/18002/thumb/cross.png",
  "LLN": "https://assets.coingecko.com/coins/images/19097/thumb/lunaland.PNG",
  "FOTA": "https://assets.coingecko.com/coins/images/26023/thumb/logo_thunder.jpg",
  "IHT": "https://assets.coingecko.com/coins/images/3158/thumb/iht-real-estate-protocol.png",
  "LEAD": "https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg",
  "SOLD": "https://assets.coingecko.com/coins/images/17634/thumb/sold.png",
  "MCA": "https://assets.coingecko.com/coins/images/25610/thumb/qnmFw6Qw_400x400.jpeg",
  "ZIP": "https://assets.coingecko.com/coins/images/22910/thumb/zip.png",
  "SQM": "https://assets.coingecko.com/coins/images/20560/thumb/squidmoon-logo.png",
  "OASIS": "https://assets.coingecko.com/coins/images/18755/thumb/Oasis_Logo_1.2%28scaled%29.png",
  "BLUE": "https://assets.coingecko.com/coins/images/21825/thumb/logo_-_2021-12-21T164806.743.png",
  "LIEN": "https://assets.coingecko.com/coins/images/12224/thumb/Lien.png",
  "EBOX": "https://assets.coingecko.com/coins/images/14528/thumb/ebox.png",
  "IND": "https://assets.coingecko.com/coins/images/848/thumb/indorse_token.png",
  "AGAR": "https://assets.coingecko.com/coins/images/14207/thumb/AR256.png",
  "NOV": "https://assets.coingecko.com/coins/images/13883/thumb/Novara-10.png",
  "PCHF": "https://assets.coingecko.com/coins/images/16529/thumb/7d578071-601e-4ef6-9a98-cc7984b258c4.png",
  "GEM": "missing_thumb.png",
  "RENBCH": "https://assets.coingecko.com/coins/images/11563/thumb/Bitcoin_Cash.jpg",
  "CATBREAD": "https://assets.coingecko.com/coins/images/16120/thumb/22TJQu3t_400x400.jpg",
  "FXF": "https://assets.coingecko.com/coins/images/13916/thumb/raVh1Iw.png",
  "TICK": "https://assets.coingecko.com/coins/images/21840/thumb/tick.png",
  "BDAMM": "https://assets.coingecko.com/coins/images/27646/thumb/t9rbdwlR_400x400.jpg",
  "BOBO": "https://assets.coingecko.com/coins/images/17620/thumb/bobo_coingecko.png",
  "CREO": "https://assets.coingecko.com/coins/images/25419/thumb/17000.png",
  "SCO": "https://assets.coingecko.com/coins/images/18151/thumb/Score-Coin-200-x-200.png",
  "FOLO": "https://assets.coingecko.com/coins/images/19988/thumb/folo.PNG",
  "PIPT": "https://assets.coingecko.com/coins/images/13281/thumb/pipt_logo.jpg",
  "CRE8": "https://assets.coingecko.com/coins/images/21115/thumb/logo-v3-small.png",
  "MNY": "https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png",
  "FRGST": "https://assets.coingecko.com/coins/images/20405/thumb/200x200.png",
  "FVT": "https://assets.coingecko.com/coins/images/13181/thumb/finance.png",
  "RIB": "https://assets.coingecko.com/coins/images/19007/thumb/rib.png",
  "FTML": "https://assets.coingecko.com/coins/images/21984/thumb/ftmlaunchlogo.png",
  "MDS": "https://assets.coingecko.com/coins/images/1343/thumb/medishares.png",
  "OBT": "https://assets.coingecko.com/coins/images/19855/thumb/obt.png",
  "BLOX": "https://assets.coingecko.com/coins/images/17722/thumb/HvBYAQz.png",
  "CHX": "https://assets.coingecko.com/coins/images/1943/thumb/ZlGXwUDv_400x400.jpg",
  "XRC": "https://assets.coingecko.com/coins/images/7642/thumb/mlfE02__dS8lNip44E3EzOdW98xhBVC3iiDBmyBocVsOx6i44aAYaGrOpdkWFIQFXGo_lNr4f4U7cRTKnIKk0ZePM8fS_Eu7w8v6IbCw4yYaI-A5zPXEjnFREG0yLTUyG4AGstcl6laIgC8hoCq9HQO3M-9v4faj6vXyUaXw6PW1rXiYhqcu2eeE_oUqZxW1ww1Bup41yIUSFCdFyNSh.jpg",
  "HULK": "https://assets.coingecko.com/coins/images/26358/thumb/HULKINU_LOGO.png",
  "MRUN": "https://assets.coingecko.com/coins/images/24219/thumb/GDgQSjgZ_400x400.jpg",
  "TSCT": "https://assets.coingecko.com/coins/images/19061/thumb/200x200_%2833%29.png",
  "AC": "https://assets.coingecko.com/coins/images/26127/thumb/ngiBSUT7_400x400.png",
  "TOD": "https://assets.coingecko.com/coins/images/24270/thumb/Logo_Travacapitai_4x.png",
  "SNOV": "https://assets.coingecko.com/coins/images/1330/thumb/logo2.png",
  "WCK": "https://assets.coingecko.com/coins/images/8797/thumb/WCK.png",
  "RAGE": "https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png",
  "LTT": "https://assets.coingecko.com/coins/images/24193/thumb/D8YzkZxo_400x400.png",
  "NIFT": "https://assets.coingecko.com/coins/images/20558/thumb/AWPDLxLK_400x400.jpg",
  "KT": "https://assets.coingecko.com/coins/images/23411/thumb/kt.png",
  "XGRO": "https://assets.coingecko.com/coins/images/12239/thumb/GRO_logo_icon_black_background_round_500_x_500-01.png",
  "LEV": "https://assets.coingecko.com/coins/images/15323/thumb/lever.PNG",
  "ITS": "https://assets.coingecko.com/coins/images/13297/thumb/1_LOssD4ENHv72I5e9PAsndA_%281%29.png",
  "ORION": "https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png",
  "SMG": "https://assets.coingecko.com/coins/images/23612/thumb/smg.png",
  "TRAVEL": "https://assets.coingecko.com/coins/images/24164/thumb/zDm5HX8I_400x400.jpg",
  "POLVEN": "https://assets.coingecko.com/coins/images/14613/thumb/polven.png",
  "FINN": "https://assets.coingecko.com/coins/images/19642/thumb/Finn_1_%28200x200%29.png",
  "BLACK": "https://assets.coingecko.com/coins/images/15124/thumb/blackhole.PNG",
  "CRAFT": "https://assets.coingecko.com/coins/images/20934/thumb/3mrl6Lfw_400x400.jpg",
  "SAKE": "https://assets.coingecko.com/coins/images/12428/thumb/sake.png",
  "DFD": "https://assets.coingecko.com/coins/images/12959/thumb/DFD.jpg",
  "OH": "https://assets.coingecko.com/coins/images/19493/thumb/oh-token-logo-200.png",
  "PEPS": "https://assets.coingecko.com/coins/images/3719/thumb/logo512x512.png",
  "CWE": "https://assets.coingecko.com/coins/images/21376/thumb/token200.png",
  "ZPT": "https://assets.coingecko.com/coins/images/2759/thumb/zeepin.png",
  "OIN": "https://assets.coingecko.com/coins/images/12339/thumb/OIN_FInance_-_cLogo-01.png",
  "ZRC": "https://assets.coingecko.com/coins/images/699/thumb/zrcoin.png",
  "GRAPE": "https://assets.coingecko.com/coins/images/22718/thumb/grape.png",
  "GOGO": "https://assets.coingecko.com/coins/images/13857/thumb/gogofinance.png",
  "ZENITH": "https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png",
  "NTK": "https://assets.coingecko.com/coins/images/5162/thumb/NTK.png",
  "CSTR": "https://assets.coingecko.com/coins/images/20209/thumb/cstr.png",
  "ONC": "https://assets.coingecko.com/coins/images/13529/thumb/onc_logo.png",
  "ZUKI": "https://assets.coingecko.com/coins/images/20655/thumb/C1A1yP2J_400x400.jpg",
  "FTX": "https://assets.coingecko.com/coins/images/1928/thumb/token-500x500.png",
  "BCPAY": "https://assets.coingecko.com/coins/images/18342/thumb/bcpay.jpg",
  "SIGN": "https://assets.coingecko.com/coins/images/24030/thumb/wjnKYes.png",
  "BANCA": "https://assets.coingecko.com/coins/images/2795/thumb/banca.png",
  "VIT": "https://assets.coingecko.com/coins/images/17254/thumb/UsTG0vRcYLwlXTwgxAM1Q7hs4wi5U78oqCFaoh19.png",
  "ETHA": "https://assets.coingecko.com/coins/images/14141/thumb/etha_logo200x200.png",
  "MAT": "https://assets.coingecko.com/coins/images/21227/thumb/photo-2021-12-02-10-09-21.png",
  "REM": "https://assets.coingecko.com/coins/images/2152/thumb/semme.png",
  "TC": "https://assets.coingecko.com/coins/images/18887/thumb/200px.png",
  "BPX": "https://assets.coingecko.com/coins/images/16948/thumb/photo_2021-05-11_01-35-19.jpg",
  "VICEDAO": "https://assets.coingecko.com/coins/images/18161/thumb/cg.png",
  "FR": "https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg",
  "UAT": "https://assets.coingecko.com/coins/images/9300/thumb/UltrAlpha.png",
  "NCASH": "https://assets.coingecko.com/coins/images/28415/thumb/XeaMbijo_400x400.jpeg",
  "FOMO": "https://assets.coingecko.com/coins/images/28824/thumb/1674074931479-5f4fd466ad4ac43dd1bebc300d709a6a.jpg",
  "BDG": "https://assets.coingecko.com/coins/images/1653/thumb/bitdegree.jpg",
  "SAIL": "https://assets.coingecko.com/coins/images/16657/thumb/SAIL.png",
  "PINK": "https://assets.coingecko.com/coins/images/16446/thumb/dot_finance.PNG",
  "X42": "https://assets.coingecko.com/coins/images/5359/thumb/x42-round.png",
  "PRL": "https://assets.coingecko.com/coins/images/22064/thumb/prl.png",
  "TROLL": "https://assets.coingecko.com/coins/images/248/thumb/Hs4mfPc_-_Imgur.png",
  "IDYP": "https://assets.coingecko.com/coins/images/21976/thumb/iDYP-200x200px.png",
  "YSEC": "https://assets.coingecko.com/coins/images/12993/thumb/ysec.png",
  "ELAND": "https://assets.coingecko.com/coins/images/14432/thumb/eland.png",
  "STF": "https://assets.coingecko.com/coins/images/18409/thumb/v4RAXKdq_400x400_%281%29.jpg",
  "CWAP": "https://assets.coingecko.com/coins/images/15722/thumb/defire.PNG",
  "MATIC2X-FLI-P": "https://assets.coingecko.com/coins/images/23388/thumb/MATIC2x-FLI.png",
  "GRIM": "https://assets.coingecko.com/coins/images/17866/thumb/83727875.png",
  "SAF": "https://assets.coingecko.com/coins/images/16376/thumb/saf-icon-200px-gold.png",
  "IDEAS": "https://assets.coingecko.com/coins/images/21535/thumb/IDEAS_logo_200.png",
  "LAB-V2": "https://assets.coingecko.com/coins/images/20327/thumb/lab_v2.png",
  "BTL": "https://assets.coingecko.com/coins/images/21990/thumb/200x200.png",
  "MATRIX": "https://assets.coingecko.com/coins/images/28989/thumb/m.png",
  "XIASI": "https://assets.coingecko.com/coins/images/16116/thumb/NvQK4SV.png",
  "PCNT": "https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png",
  "UMI": "https://assets.coingecko.com/coins/images/16953/thumb/umi.PNG",
  "SSTX": "https://assets.coingecko.com/coins/images/17000/thumb/Logo-Silver-Stonks.png",
  "NYAN-2": "https://assets.coingecko.com/coins/images/13093/thumb/v2-logo.png",
  "RMT": "https://assets.coingecko.com/coins/images/1830/thumb/j9T_C-Wl_400x400.jpg",
  "ROUSH": "https://assets.coingecko.com/coins/images/18667/thumb/BHHO6vjEyDeFLhCqnKrmT42uormc4NeEAFWOzpeM.png",
  "CVT": "https://assets.coingecko.com/coins/images/24267/thumb/cvt.png",
  "DEFI+L": "https://assets.coingecko.com/coins/images/12731/thumb/DeFi_L.png",
  "SHARE": "https://assets.coingecko.com/coins/images/12306/thumb/logo_%281%29.png",
  "DMLG": "https://assets.coingecko.com/coins/images/19195/thumb/dmlg.png",
  "LFW": "https://assets.coingecko.com/coins/images/19570/thumb/LFW_New_logo.png",
  "MNTP": "https://assets.coingecko.com/coins/images/1944/thumb/goldmint.png",
  "FAST": "https://assets.coingecko.com/coins/images/25879/thumb/fast.png",
  "GAIN": "https://assets.coingecko.com/coins/images/17296/thumb/gain.PNG",
  "ANKA": "https://assets.coingecko.com/coins/images/17648/thumb/anka.png",
  "GFX": "https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png",
  "EGEM": "https://assets.coingecko.com/coins/images/5459/thumb/ethergem.png",
  "WVG0": "https://assets.coingecko.com/coins/images/12875/thumb/wvg0.png",
  "ZCO": "https://assets.coingecko.com/coins/images/2756/thumb/zebi.png",
  "FNC": "https://assets.coingecko.com/coins/images/28329/thumb/Fan-Coin.png",
  "CHRP": "https://assets.coingecko.com/coins/images/27243/thumb/Chirpley-Logo_200x200.png",
  "SUPERBID": "https://assets.coingecko.com/coins/images/14179/thumb/sign_super_bid.png",
  "MAINST": "https://assets.coingecko.com/coins/images/20268/thumb/MainstCoinLogo.png",
  "RACEX": "https://assets.coingecko.com/coins/images/20424/thumb/logo-1_%281%29.png",
  "PDI": "https://assets.coingecko.com/coins/images/25772/thumb/2.PDI_logo_solid_blue_-_Circle_3x.png",
  "SION": "https://assets.coingecko.com/coins/images/25985/thumb/0c2335e4-fd12-47a6-ab6e-71fdd2f121bc.png",
  "BUTT": "https://assets.coingecko.com/coins/images/19554/thumb/Logo_%282%29.png",
  "CYS": "https://assets.coingecko.com/coins/images/18139/thumb/cyclos-text-logo.png",
  "HTZ": "https://assets.coingecko.com/coins/images/16373/thumb/HTZ_logo_200_x_200.png",
  "SEX": "https://assets.coingecko.com/coins/images/23855/thumb/photo_2022-02-23_09.01.21.jpeg",
  "GENIX": "https://assets.coingecko.com/coins/images/8091/thumb/JrVvS2j.png",
  "KALI": "https://assets.coingecko.com/coins/images/21261/thumb/logo-noir.png",
  "RBT": "https://assets.coingecko.com/coins/images/28139/thumb/Untitled_design_%283%29.png",
  "IVN": "https://assets.coingecko.com/coins/images/15588/thumb/ivn_logo.png",
  "APYS": "https://assets.coingecko.com/coins/images/14163/thumb/apys.png",
  "POWER": "https://assets.coingecko.com/coins/images/21489/thumb/95974036.png",
  "KENNEL": "https://assets.coingecko.com/coins/images/14807/thumb/kenenlsphere.jpg",
  "AVS": "https://assets.coingecko.com/coins/images/13874/thumb/icon-200.png",
  "PINKE": "https://assets.coingecko.com/coins/images/15289/thumb/qgtyiA0.png",
  "KYOKO": "https://assets.coingecko.com/coins/images/24454/thumb/kyoko.png",
  "UT": "https://assets.coingecko.com/coins/images/6033/thumb/ulord.png",
  "SWHAL": "https://assets.coingecko.com/coins/images/21012/thumb/photo-2021-11-28-22-36-58.jpg",
  "BAKED": "https://assets.coingecko.com/coins/images/19178/thumb/rebaked-logo-full.png",
  "NNI": "https://assets.coingecko.com/coins/images/25068/thumb/SeSkZxx7_400x400.jpeg",
  "DEPO": "https://assets.coingecko.com/coins/images/21082/thumb/EnjwdEis_400x400.png",
  "OAP": "https://assets.coingecko.com/coins/images/12400/thumb/256x256-OAP.png",
  "SWING": "https://assets.coingecko.com/coins/images/27779/thumb/aUQKFUM.png",
  "SILVA": "https://assets.coingecko.com/coins/images/19689/thumb/13592.png",
  "BCDN": "https://assets.coingecko.com/coins/images/1301/thumb/blockcdn.png",
  "KEYFI": "https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg",
  "ARTEM": "https://assets.coingecko.com/coins/images/21998/thumb/ARTM_Logo_MAIN_%281%29.png",
  "SENC": "https://assets.coingecko.com/coins/images/2960/thumb/sentinel-chain.jpg",
  "DFND": "https://assets.coingecko.com/coins/images/15286/thumb/1zbdX36.png",
  "ZER": "https://assets.coingecko.com/coins/images/1292/thumb/Zero_Full_Logo.png",
  "SHIBIC": "https://assets.coingecko.com/coins/images/22478/thumb/shibic.jpg",
  "BIOS": "https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png",
  "DVD": "https://assets.coingecko.com/coins/images/13971/thumb/DAOventures.co_final_icon_200x200.png",
  "BC": "https://assets.coingecko.com/coins/images/28832/thumb/bitcoinlogo.png",
  "PKG": "https://assets.coingecko.com/coins/images/5422/thumb/pkg-token.png",
  "RCH": "https://assets.coingecko.com/coins/images/22896/thumb/rch.png",
  "WQT": "https://assets.coingecko.com/coins/images/14662/thumb/WQT-1_%281%29.png",
  "MSR": "https://assets.coingecko.com/coins/images/3526/thumb/masari.png",
  "GAP": "https://assets.coingecko.com/coins/images/1393/thumb/gap.png",
  "ASAFE": "https://assets.coingecko.com/coins/images/1424/thumb/1439.png",
  "KOBO": "https://assets.coingecko.com/coins/images/1319/thumb/kobocoin.png",
  "TSL": "https://assets.coingecko.com/coins/images/8212/thumb/treasure_sl.png",
  "LUCHA": "https://assets.coingecko.com/coins/images/22794/thumb/lucha.png",
  "MOVEY": "https://assets.coingecko.com/coins/images/25309/thumb/uh6VZ8b.png",
  "LATX": "https://assets.coingecko.com/coins/images/3500/thumb/latium.jpg",
  "HEROS": "https://assets.coingecko.com/coins/images/20381/thumb/200x200.png",
  "KOROMARU": "https://assets.coingecko.com/coins/images/19884/thumb/koromaru.png",
  "SCB": "https://assets.coingecko.com/coins/images/14178/thumb/AUC3R40.png",
  "LHC": "https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG",
  "SNY": "https://assets.coingecko.com/coins/images/14835/thumb/synthetify_token.png",
  "ARTX": "https://assets.coingecko.com/coins/images/14652/thumb/logo_black_cmc.png",
  "FDR": "https://assets.coingecko.com/coins/images/5986/thumb/fdr-logo-241x241.png",
  "ENDCEX": "https://assets.coingecko.com/coins/images/21790/thumb/tokens_1ab1c33d-0ddc-4730-98b5-e9cc2cec4a10.png",
  "MOMA": "https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG",
  "AVXL": "https://assets.coingecko.com/coins/images/18270/thumb/avaxlauncher.png",
  "DYNAMO": "https://assets.coingecko.com/coins/images/16897/thumb/Token_Logo2.png",
  "GAZE": "https://assets.coingecko.com/coins/images/14717/thumb/Gaze_Icon_Positive.png",
  "ATS": "https://assets.coingecko.com/coins/images/23002/thumb/logo.png",
  "DMB": "https://assets.coingecko.com/coins/images/2884/thumb/dmb_logo.png",
  "CHRO": "https://assets.coingecko.com/coins/images/26165/thumb/Untitled_%28200_%C3%97_200_px%29.png",
  "PVT": "https://assets.coingecko.com/coins/images/8977/thumb/j5ovdTKP_400x400.png",
  "CRDT": "https://assets.coingecko.com/coins/images/11859/thumb/image_%281%29.png",
  "WASABI": "https://assets.coingecko.com/coins/images/14522/thumb/WASABI.299ec8f7.png",
  "BLES": "https://assets.coingecko.com/coins/images/14537/thumb/BLES-Logo-BW.png",
  "EMD": "https://assets.coingecko.com/coins/images/2655/thumb/emd.png",
  "BSPT": "https://assets.coingecko.com/coins/images/23735/thumb/lUjOcgBNs1fSMbGND5bmUMSKaVYybK5oDblEFgqX5Tvcg89_U3yzRRHzcR5UMpzObjlps5ZpumoYjtP9R2ddgsUkP0xyw3p5R_VMAh5qIQfUu8IGicH2xEzzwHM5ayPOh8PaO_BEFxjvYlYpt1Fknz6OdOhQrTBhh8kBfG0De1HEGZLe7dJGKnoLRzlyYkzYcIimMy-rzp2swB.jpg",
  "AMPLE": "https://assets.coingecko.com/coins/images/19475/thumb/ample.PNG",
  "CTASK": "https://assets.coingecko.com/coins/images/13965/thumb/cryptotask_logo.png",
  "GHSP": "https://assets.coingecko.com/coins/images/19684/thumb/ghsp.png",
  "PSOL": "https://assets.coingecko.com/coins/images/21551/thumb/icon.png",
  "MEL": "https://assets.coingecko.com/coins/images/15028/thumb/melx.jpeg",
  "SKYRIM": "https://assets.coingecko.com/coins/images/18003/thumb/Xo3oCb53_400x400.png",
  "NFTS": "https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png",
  "EXCC": "https://assets.coingecko.com/coins/images/3660/thumb/excc.png",
  "ZNZ": "https://assets.coingecko.com/coins/images/6734/thumb/Ln7KSa6.png",
  "HYDRO": "https://assets.coingecko.com/coins/images/3678/thumb/hydro-drop.png",
  "ROGUE": "https://assets.coingecko.com/coins/images/18569/thumb/rogue.png",
  "ALEX": "https://assets.coingecko.com/coins/images/25837/thumb/ALEX_Token.png",
  "ARGO": "https://assets.coingecko.com/coins/images/25381/thumb/argo-protocol.jpeg",
  "WARS": "https://assets.coingecko.com/coins/images/19534/thumb/13105.png",
  "PERA": "https://assets.coingecko.com/coins/images/16185/thumb/Pera-Finance-200x200.png",
  "CHI": "https://assets.coingecko.com/coins/images/26051/thumb/CHI_Logo.png",
  "UNT": "https://assets.coingecko.com/coins/images/17143/thumb/unity.PNG",
  "MYB": "https://assets.coingecko.com/coins/images/1240/thumb/mybit.png",
  "AOK": "https://assets.coingecko.com/coins/images/20516/thumb/dFtBcV56_400x400.jpg",
  "NBC": "https://assets.coingecko.com/coins/images/4095/thumb/logo-niobium.png",
  "DOTX": "https://assets.coingecko.com/coins/images/12265/thumb/logo200x200.jpg",
  "EXRT": "https://assets.coingecko.com/coins/images/5954/thumb/exrt.png",
  "BNTY": "https://assets.coingecko.com/coins/images/24975/thumb/bnty.png",
  "DAM": "https://assets.coingecko.com/coins/images/11695/thumb/qxsFH8W.png",
  "ALN": "https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png",
  "DEFI+S": "https://assets.coingecko.com/coins/images/12701/thumb/DefiS.png",
  "OCP": "https://assets.coingecko.com/coins/images/5614/thumb/OCP-logo_icon-color.png",
  "NDX": "https://assets.coingecko.com/coins/images/13546/thumb/indexed-light.74bb5471.png",
  "MOOO": "https://assets.coingecko.com/coins/images/21139/thumb/Hashtagger_Logo_No_text_200_200.png",
  "TTK": "https://assets.coingecko.com/coins/images/18948/thumb/TTK_-_Square_Icon_01.png",
  "PHR": "https://assets.coingecko.com/coins/images/1129/thumb/phore.png",
  "ROYA": "https://assets.coingecko.com/coins/images/13602/thumb/roya.png",
  "KUNU": "https://assets.coingecko.com/coins/images/19520/thumb/robert199805_Order_FO41AEBD476C8_KZ00A_R00A%28SY%29_copy.png",
  "MIS": "https://assets.coingecko.com/coins/images/18916/thumb/136704691-920ef315-4906-4a82-bca1-144b07dea3fb.png",
  "SAFU": "https://assets.coingecko.com/coins/images/17021/thumb/safuyield.png",
  "OTB": "https://assets.coingecko.com/coins/images/5246/thumb/otcbtc-token.jpg",
  "PTT": "https://assets.coingecko.com/coins/images/17651/thumb/Untitled-design_%281%29.png",
  "FLOBO": "https://assets.coingecko.com/coins/images/20118/thumb/communityIcon_wz9dy5ga39x71.png",
  "SLB": "https://assets.coingecko.com/coins/images/18918/thumb/logo_%2822%29.png",
  "AUC": "https://assets.coingecko.com/coins/images/24369/thumb/200x200.png",
  "AFIN": "https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png",
  "LMT": "https://assets.coingecko.com/coins/images/15155/thumb/coin_%282%29.png",
  "HAPPY": "https://assets.coingecko.com/coins/images/29029/thumb/HappyCG.png",
  "GNT": "https://assets.coingecko.com/coins/images/15171/thumb/U4tF68c.png",
  "BETTER": "https://assets.coingecko.com/coins/images/12838/thumb/BETTER_MONEY_MASK_ICON.png",
  "HPAY": "https://assets.coingecko.com/coins/images/27725/thumb/200x200CG.png",
  "FLUF": "https://assets.coingecko.com/coins/images/20656/thumb/fluf.png",
  "WORLD": "https://assets.coingecko.com/coins/images/13801/thumb/WORLD.png",
  "&#127760;": "https://assets.coingecko.com/coins/images/17159/thumb/qao.PNG",
  "IFUND": "https://assets.coingecko.com/coins/images/12833/thumb/unifund_logo.png",
  "ISA": "https://assets.coingecko.com/coins/images/22375/thumb/islander-new-logo.png",
  "DOKI": "https://assets.coingecko.com/coins/images/27018/thumb/Okidoki_Logo.png",
  "SKULL": "https://assets.coingecko.com/coins/images/10641/thumb/skull.png",
  "MGOD": "https://assets.coingecko.com/coins/images/21666/thumb/n9xlniyOphEZp40cR7OnXO1p5H8kI8rLGn6vbx8AaVGiYi250HIeNISTx7eMHU-Ym5XRc9nFPmNXfRZmr3QglH1Svte4Tisu2EZPU3JVFKlPLLnQwDXhodgYcSt0Xb33kyrA_yqFEZnMw1q5qi-4KgdHd4bHv7MKNz2rF3MuA_RWdylgpB3xdUoaxoiMLqzlVbEatJYnDQyhqvh.jpg",
  "STATIK": "https://assets.coingecko.com/coins/images/22298/thumb/STATIK_200x200.png",
  "$ANRX": "https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg",
  "MUSIC": "https://assets.coingecko.com/coins/images/16149/thumb/nftmusic.PNG",
  "DAOX": "https://assets.coingecko.com/coins/images/25042/thumb/200px-DAOx.png",
  "ADD": "https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png",
  "KAE": "https://assets.coingecko.com/coins/images/18833/thumb/kanpeki-logo-square_2.png",
  "GPOOL": "https://assets.coingecko.com/coins/images/18122/thumb/GP_BRAND_GREEN_Main_Icon_.png",
  "PASC": "https://assets.coingecko.com/coins/images/547/thumb/pas.png",
  "INCI": "https://assets.coingecko.com/coins/images/21704/thumb/token.png",
  "TACO": "https://assets.coingecko.com/coins/images/12108/thumb/photo_2020-08-12_05-50-46.jpg",
  "ORO": "https://assets.coingecko.com/coins/images/20965/thumb/oro.png",
  "DRACE": "https://assets.coingecko.com/coins/images/22894/thumb/drace.png",
  "PIE": "https://assets.coingecko.com/coins/images/11956/thumb/98j5E_EG_400x400.png",
  "UPDOG": "https://assets.coingecko.com/coins/images/15369/thumb/wKA2xZr.png",
  "LUX": "https://assets.coingecko.com/coins/images/1068/thumb/logo.png",
  "DUCATO": "https://assets.coingecko.com/coins/images/12550/thumb/ducato.png",
  "BXR": "https://assets.coingecko.com/coins/images/19857/thumb/bxr-left2.png",
  "CNN": "https://assets.coingecko.com/coins/images/2787/thumb/cnn_logo_big.png",
  "HAC": "https://assets.coingecko.com/coins/images/2336/thumb/hac.png",
  "KGC": "https://assets.coingecko.com/coins/images/25021/thumb/Q-SHreiW_400x400.jpg",
  "ICH": "https://assets.coingecko.com/coins/images/11053/thumb/4fJlFzca_400x400.jpg",
  "KPAD": "https://assets.coingecko.com/coins/images/14531/thumb/kickpad_logo.jpg",
  "PSLIP": "https://assets.coingecko.com/coins/images/17960/thumb/MgCiNzk.png",
  "IBZ": "https://assets.coingecko.com/coins/images/19349/thumb/AZHnAHiY_400x400.jpg",
  "FUFU": "https://assets.coingecko.com/coins/images/18812/thumb/fufu_icon_RGB.png",
  "TIP": "https://assets.coingecko.com/coins/images/21487/thumb/sugarbounce.PNG",
  "HAT": "https://assets.coingecko.com/coins/images/24789/thumb/logo_%281%29.png",
  "UCT": "https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png",
  "GENESIS": "https://assets.coingecko.com/coins/images/19905/thumb/kKkWmniB_400x400.jpg",
  "SHAKE": "https://assets.coingecko.com/coins/images/12765/thumb/shake.png",
  "FUD": "https://assets.coingecko.com/coins/images/19439/thumb/xqVmqZKF_400x400.jpeg",
  "YCO": "https://assets.coingecko.com/coins/images/14128/thumb/BIG-POTAT-200x200-removebg-preview.png",
  "SNN": "https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg",
  "CATT": "https://assets.coingecko.com/coins/images/6353/thumb/catex.png",
  "SWIFT": "https://assets.coingecko.com/coins/images/7030/thumb/swiftcash.png",
  "BITE": "https://assets.coingecko.com/coins/images/16386/thumb/dragonbite.PNG",
  "CMSN": "https://assets.coingecko.com/coins/images/24993/thumb/pGaXc6eB_400x400.jpg",
  "NTRN": "https://assets.coingecko.com/coins/images/27930/thumb/ntrn.png",
  "NPXSXEM": "https://assets.coingecko.com/coins/images/5416/thumb/pundi-x-nem.png",
  "UDOKI": "https://assets.coingecko.com/coins/images/14900/thumb/uDOKI.jpg",
  "BKC": "https://assets.coingecko.com/coins/images/8461/thumb/sFNOkmVx_400x400.jpg",
  "XP": "https://assets.coingecko.com/coins/images/15414/thumb/xpose.PNG",
  "ZLOT": "https://assets.coingecko.com/coins/images/13073/thumb/z-LOT-logo-transparent.png",
  "TUBE": "https://assets.coingecko.com/coins/images/3501/thumb/BitTube_Icon_Gradient_texture_RGB.png",
  "$CRDN": "https://assets.coingecko.com/coins/images/17744/thumb/logo_-_2021-08-17T084037.897.png",
  "ESD": "https://assets.coingecko.com/coins/images/12440/thumb/esd_logo_circle.png",
  "KURO": "https://assets.coingecko.com/coins/images/19325/thumb/83105670.png",
  "BNTX": "https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png",
  "MAS": "https://assets.coingecko.com/coins/images/27093/thumb/20220822_011701.png",
  "ESH": "https://assets.coingecko.com/coins/images/8608/thumb/Cco9sLN.png",
  "NOAHP": "https://assets.coingecko.com/coins/images/3442/thumb/FvG3XweD.png",
  "SNGLS": "https://assets.coingecko.com/coins/images/521/thumb/singulardtv.png",
  "AIDOC": "https://assets.coingecko.com/coins/images/2449/thumb/aidoc.png",
  "FANT": "https://assets.coingecko.com/coins/images/21604/thumb/Phantasia_Logo.png",
  "CPS": "https://assets.coingecko.com/coins/images/26068/thumb/Cryptostone.png",
  "KSC": "https://assets.coingecko.com/coins/images/1630/thumb/ksc.png",
  "BWI": "https://assets.coingecko.com/coins/images/16553/thumb/GV6H78pq_400x400.jpg",
  "CSPN": "https://assets.coingecko.com/coins/images/7826/thumb/cspn_logo_250.png",
  "ISLA": "https://assets.coingecko.com/coins/images/14471/thumb/256.png",
  "JPG": "https://assets.coingecko.com/coins/images/25071/thumb/JPG-token-logo-01.png",
  "TCAKE": "https://assets.coingecko.com/coins/images/14620/thumb/sygnet-e1616750584571.png",
  "HES": "https://assets.coingecko.com/coins/images/25782/thumb/hes.png",
  "FONT": "https://assets.coingecko.com/coins/images/14085/thumb/font.png",
  "MCRN": "https://assets.coingecko.com/coins/images/14633/thumb/macaron.png",
  "$ROPE": "https://assets.coingecko.com/coins/images/12567/thumb/Rope_Icon.jpg",
  "GUM": "https://assets.coingecko.com/coins/images/13846/thumb/gum.png",
  "SSP": "https://assets.coingecko.com/coins/images/4642/thumb/smartshare.png",
  "HANU": "https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png",
  "ORI": "https://assets.coingecko.com/coins/images/23696/thumb/ori-token-logo.png",
  "YAMV2": "https://assets.coingecko.com/coins/images/12179/thumb/YAM-v2.png",
  "BRRR": "https://assets.coingecko.com/coins/images/24856/thumb/burrow.PNG",
  "DIAMOND": "https://assets.coingecko.com/coins/images/21099/thumb/Color_logo_with_background.png",
  "AVXT": "https://assets.coingecko.com/coins/images/18336/thumb/avxt.PNG",
  "BTA": "https://assets.coingecko.com/coins/images/24967/thumb/bta.png",
  "COLL": "https://assets.coingecko.com/coins/images/14682/thumb/cpay.jpg",
  "XWP": "https://assets.coingecko.com/coins/images/7977/thumb/Swap.png",
  "ML": "https://assets.coingecko.com/coins/images/22763/thumb/Clear-background-logo-200-x-200.png",
  "LDFI": "https://assets.coingecko.com/coins/images/17990/thumb/lendefi.jpg",
  "LMY": "https://assets.coingecko.com/coins/images/4149/thumb/IMG_2073.png",
  "ADEL": "https://assets.coingecko.com/coins/images/12300/thumb/adel_on_white_10x.png",
  "SIG": "https://assets.coingecko.com/coins/images/3508/thumb/5906667d95a2d43d31378ce7_60x60logo.png",
  "SNOB": "https://assets.coingecko.com/coins/images/14347/thumb/snowball.png",
  "METO": "https://assets.coingecko.com/coins/images/22097/thumb/logo200x200.png",
  "NFY": "https://assets.coingecko.com/coins/images/12766/thumb/NFY_logo.png",
  "L3P": "https://assets.coingecko.com/coins/images/14436/thumb/l3p.png",
  "AVME": "https://assets.coingecko.com/coins/images/17184/thumb/avme_logo.png",
  "SFUEL": "https://assets.coingecko.com/coins/images/13564/thumb/SFUEL.png",
  "SOLI": "https://assets.coingecko.com/coins/images/23550/thumb/Token-SOLI-200x200.png",
  "ABCD": "https://assets.coingecko.com/coins/images/23449/thumb/logo_256px.png",
  "BREW": "https://assets.coingecko.com/coins/images/13966/thumb/coingecko_listing.png",
  "DGVC": "https://assets.coingecko.com/coins/images/12208/thumb/degen_vc_logo.png",
  "CLIQ": "https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png",
  "FRF": "https://assets.coingecko.com/coins/images/22224/thumb/LOGO200_FRF.png",
  "TRND": "https://assets.coingecko.com/coins/images/11941/thumb/trnd-ico-200.png",
  "FLURRY": "https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png",
  "CNB": "https://assets.coingecko.com/coins/images/10340/thumb/cbicon.png",
  "TRDG": "https://assets.coingecko.com/coins/images/14371/thumb/200x200.png",
  "XBP": "https://assets.coingecko.com/coins/images/2458/thumb/BlitzPredict.jpg",
  "MFO": "https://assets.coingecko.com/coins/images/16337/thumb/logo-mf-new-200px-trans.png",
  "KP4R": "https://assets.coingecko.com/coins/images/13006/thumb/kp4r.png",
  "R3FI": "https://assets.coingecko.com/coins/images/14036/thumb/IMG_20210518_073652_114.png",
  "AUX": "https://assets.coingecko.com/coins/images/28298/thumb/AUX-Coin_200.png",
  "TARO": "https://assets.coingecko.com/coins/images/25276/thumb/Taroverse.png",
  "UEDC": "https://assets.coingecko.com/coins/images/16333/thumb/UEDC.jpg",
  "DEG": "https://assets.coingecko.com/coins/images/24333/thumb/DEG_Logo.png",
  "DEXF": "https://assets.coingecko.com/coins/images/17280/thumb/dexp-ticker-logo-256.png",
  "WTF": "https://assets.coingecko.com/coins/images/27775/thumb/Logo_WTF_%281%29.png",
  "KLP": "https://assets.coingecko.com/coins/images/12082/thumb/K5_y_A19.jpg",
  "DOGEDI": "https://assets.coingecko.com/coins/images/21334/thumb/dogedi.png",
  "CW": "https://assets.coingecko.com/coins/images/17820/thumb/cardwallet%28circle%29.png",
  "MTHD": "https://assets.coingecko.com/coins/images/14619/thumb/mthd.PNG",
  "IFEX": "https://assets.coingecko.com/coins/images/13346/thumb/U6uYZry.png",
  "BAG": "https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png",
  "FLOT": "https://assets.coingecko.com/coins/images/23591/thumb/200flot.png",
  "SKIN": "https://assets.coingecko.com/coins/images/830/thumb/skincoin.png",
  "NERIAN": "https://assets.coingecko.com/coins/images/21238/thumb/Nerian-Network-200x200-Glyph-Black.png",
  "DAPS": "https://assets.coingecko.com/coins/images/6244/thumb/round_1.png",
  "XCB": "https://assets.coingecko.com/coins/images/21646/thumb/XCB_Token_Logo.png",
  "TANGO": "https://assets.coingecko.com/coins/images/21853/thumb/Adobe_20211124_194931.png",
  "MIVA": "https://assets.coingecko.com/coins/images/14732/thumb/MIVA-Token_200x200.png",
  "TZC": "https://assets.coingecko.com/coins/images/967/thumb/trezar.png",
  "NCC": "https://assets.coingecko.com/coins/images/15491/thumb/nocapcoin.PNG",
  "ARGON": "https://assets.coingecko.com/coins/images/14280/thumb/200x200-no-bg.png",
  "AMM": "https://assets.coingecko.com/coins/images/1356/thumb/micromoney.png",
  "WANNA": "https://assets.coingecko.com/coins/images/21955/thumb/wannaswap.PNG",
  "MVC": "https://assets.coingecko.com/coins/images/13146/thumb/kXSdwuxD_400x400.jpg",
  "MOAR": "https://assets.coingecko.com/coins/images/15057/thumb/moar.PNG",
  "BTW": "https://assets.coingecko.com/coins/images/2766/thumb/bitwhite.png",
  "BAPE": "https://assets.coingecko.com/coins/images/23734/thumb/DY4jjsMH_400x400.jpg",
  "LUN": "https://assets.coingecko.com/coins/images/735/thumb/lunyr.png",
  "BONTE": "https://assets.coingecko.com/coins/images/24277/thumb/logo_%281%29.png",
  "SAFTI": "https://assets.coingecko.com/coins/images/24535/thumb/vHQ6BpuO_400x400.jpg",
  "ROGE": "https://assets.coingecko.com/coins/images/17440/thumb/njbVg2f.png",
  "WOLF": "https://assets.coingecko.com/coins/images/23934/thumb/6E433721-C204-48ED-8467-EFA2FBA79794.png",
  "SSGTX": "https://assets.coingecko.com/coins/images/20769/thumb/GBpj6TpI.png",
  "SKELETON": "https://assets.coingecko.com/coins/images/26249/thumb/free-image-resizer-cropper_%285%29.png",
  "UPX": "https://assets.coingecko.com/coins/images/7309/thumb/TkKKAyvV_400x400.jpg",
  "ASIMI": "https://assets.coingecko.com/coins/images/9715/thumb/Asimi-icon.png",
  "POE": "https://assets.coingecko.com/coins/images/910/thumb/poet.png",
  "ROPE": "https://assets.coingecko.com/coins/images/14661/thumb/rope-v6.png",
  "BLV3": "https://assets.coingecko.com/coins/images/27526/thumb/200-200.jpg",
  "CHONK": "https://assets.coingecko.com/coins/images/12899/thumb/a2LHjXZ.jpeg",
  "RELI": "https://assets.coingecko.com/coins/images/14906/thumb/xPZRuspj_400x400.jpg",
  "DLT": "https://assets.coingecko.com/coins/images/900/thumb/delta_200x200.png",
  "NOBL": "https://assets.coingecko.com/coins/images/204/thumb/noblecoin.png",
  "RFI": "https://assets.coingecko.com/coins/images/13189/thumb/reflect_finance_logo.png",
  "TOTM": "https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png",
  "CENT": "https://assets.coingecko.com/coins/images/18493/thumb/cent.png",
  "PHT": "https://assets.coingecko.com/coins/images/22623/thumb/download.png",
  "KFX": "https://assets.coingecko.com/coins/images/11960/thumb/kfx.png",
  "ZIOT": "https://assets.coingecko.com/coins/images/14546/thumb/1_iSvPXhw4frHgjyMejcmoKQ.png",
  "FIRE": "https://assets.coingecko.com/coins/images/22305/thumb/logo.png",
  "SAT": "https://assets.coingecko.com/coins/images/27761/thumb/SAT_200.png",
  "SVX": "https://assets.coingecko.com/coins/images/14591/thumb/savix_200px.png",
  "SOSX": "https://assets.coingecko.com/coins/images/20752/thumb/sosx_200x200.png",
  "VFT": "https://assets.coingecko.com/coins/images/23384/thumb/vft_new_%281%29.png",
  "PAW": "https://assets.coingecko.com/coins/images/28946/thumb/PAWSWAP.png",
  "DSD": "https://assets.coingecko.com/coins/images/27304/thumb/photo_2022-09-07_17-20-18.jpg",
  "NFXC": "https://assets.coingecko.com/coins/images/10694/thumb/nfx.png",
  "COVA": "https://assets.coingecko.com/coins/images/4707/thumb/covalent.jpg",
  "EVIL": "https://assets.coingecko.com/coins/images/2650/thumb/evil-coin.png",
  "BCUG": "https://assets.coingecko.com/coins/images/14425/thumb/bcug_logo.png",
  "ADI": "https://assets.coingecko.com/coins/images/1867/thumb/adi.png",
  "NESS": "https://assets.coingecko.com/coins/images/24247/thumb/ness.png",
  "AXIAL": "https://assets.coingecko.com/coins/images/20502/thumb/E5nD9pPv_400x400.jpg",
  "SCHO": "https://assets.coingecko.com/coins/images/14385/thumb/77821571.png",
  "ADM": "https://assets.coingecko.com/coins/images/2372/thumb/adamant.png",
  "QUAI": "https://assets.coingecko.com/coins/images/27928/thumb/quai.png",
  "CNT": "https://assets.coingecko.com/coins/images/24927/thumb/61f1d2b3bae74faea4ea34ef_32x32.png",
  "STMAN": "https://assets.coingecko.com/coins/images/24641/thumb/KUJBPxTC_400x400.jpg",
  "AMN": "https://assets.coingecko.com/coins/images/2369/thumb/amon.png",
  "METACEX": "https://assets.coingecko.com/coins/images/21179/thumb/metacex.png",
  "BOLI": "https://assets.coingecko.com/coins/images/1325/thumb/Bolicoin_black_and_yellow.svg.png",
  "CRED": "https://assets.coingecko.com/coins/images/1349/thumb/verify.png",
  "BECOIN": "https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png",
  "MAX": "https://assets.coingecko.com/coins/images/26933/thumb/MAX_token_200.png",
  "RAVE": "https://assets.coingecko.com/coins/images/28823/thumb/RaveBase.png",
  "YFFI": "https://assets.coingecko.com/coins/images/11940/thumb/yffi-finance.jpg",
  "HNDC": "https://assets.coingecko.com/coins/images/6551/thumb/honda.png",
  "CYL": "https://assets.coingecko.com/coins/images/6419/thumb/crystal-token.png",
  "ATB": "https://assets.coingecko.com/coins/images/873/thumb/atbcoin-logo.png",
  "ISKY": "https://assets.coingecko.com/coins/images/22583/thumb/isky.png",
  "BABYQUICK": "https://assets.coingecko.com/coins/images/20147/thumb/4fa6uMzp_400x400.jpg",
  "SPN": "https://assets.coingecko.com/coins/images/6155/thumb/spartancoin-logo-gold-spartan-200p.png",
  "VUSD": "https://assets.coingecko.com/coins/images/16253/thumb/Vesper_Token_600.png",
  "RENDOGE": "https://assets.coingecko.com/coins/images/13796/thumb/Dogecoin.jpg",
  "BUNNY": "https://assets.coingecko.com/coins/images/14350/thumb/n8TgW06A_400x400.jpg",
  "SPDR": "https://assets.coingecko.com/coins/images/13378/thumb/spiderdao_logo.png",
  "NFSG": "https://assets.coingecko.com/coins/images/23461/thumb/nftsg_logo_200.png",
  "IPL": "https://assets.coingecko.com/coins/images/1858/thumb/ipl.png",
  "NONI": "https://assets.coingecko.com/coins/images/26910/thumb/NONI-Logo.png",
  "ROAD": "https://assets.coingecko.com/coins/images/10099/thumb/tCCNu7s8_400x400.jpg",
  "FMT": "https://assets.coingecko.com/coins/images/27960/thumb/FMT.png",
  "BLS": "https://assets.coingecko.com/coins/images/29028/thumb/wzTKVsO__400x400.png",
  "INFO": "https://assets.coingecko.com/coins/images/21130/thumb/INFO.png",
  "VOX": "https://assets.coingecko.com/coins/images/12880/thumb/BSensIa.png",
  "AZUM": "https://assets.coingecko.com/coins/images/10483/thumb/WDncHJ5.png",
  "CVNX": "https://assets.coingecko.com/coins/images/862/thumb/CVNX-logo.png",
  "DELOT": "https://assets.coingecko.com/coins/images/25561/thumb/Logo_200.png",
  "PLURA": "https://assets.coingecko.com/coins/images/3706/thumb/pluracoin.png",
  "PRIX": "https://assets.coingecko.com/coins/images/1183/thumb/dZZrRl8.png",
  "BMCC": "https://assets.coingecko.com/coins/images/22480/thumb/logo_200x200.png",
  "CRUSADER": "https://assets.coingecko.com/coins/images/17178/thumb/crusaders.PNG",
  "BKING": "https://assets.coingecko.com/coins/images/16162/thumb/king-arthur.png",
  "LKN": "https://assets.coingecko.com/coins/images/20176/thumb/lockness_bubles.png",
  "SOLA": "https://assets.coingecko.com/coins/images/18263/thumb/sola-new.png",
  "PERRY": "https://assets.coingecko.com/coins/images/18005/thumb/Swaperry_token-2.png",
  "ADC": "https://assets.coingecko.com/coins/images/628/thumb/audiocoin.png",
  "STQ": "https://assets.coingecko.com/coins/images/1929/thumb/storiqa.png",
  "LEOS": "https://assets.coingecko.com/coins/images/16899/thumb/LEOS-icon.png",
  "LUS": "https://assets.coingecko.com/coins/images/22295/thumb/16197.png",
  "WHEAT": "https://assets.coingecko.com/coins/images/14778/thumb/wheat_logo.png",
  "MARTK": "https://assets.coingecko.com/coins/images/11007/thumb/8F1TiAO.png",
  "OLIVE": "https://assets.coingecko.com/coins/images/14880/thumb/2_%285%29.png",
  "STAR": "https://assets.coingecko.com/coins/images/26559/thumb/star-dao-token-logo-200.png",
  "MRCH": "https://assets.coingecko.com/coins/images/14540/thumb/logo_256x256.png",
  "KARMA": "https://assets.coingecko.com/coins/images/11884/thumb/Karma.png",
  "SCH": "https://assets.coingecko.com/coins/images/5521/thumb/SCH_logo-1024x1024.png",
  "WAGMI": "https://assets.coingecko.com/coins/images/21069/thumb/logo.png",
  "PROPEL": "https://assets.coingecko.com/coins/images/8794/thumb/-RNl00DU_400x400.jpg",
  "WAIF": "https://assets.coingecko.com/coins/images/19424/thumb/cropped-LogoMakr-3GX0jv-300dpi-1.png",
  "ANOIR": "https://assets.coingecko.com/coins/images/6391/thumb/Noir_white.png",
  "XIOT": "https://assets.coingecko.com/coins/images/12182/thumb/xiot_logo_512x512.png",
  "PROGE": "https://assets.coingecko.com/coins/images/16993/thumb/Proge_200x200.png",
  "ORK": "https://assets.coingecko.com/coins/images/23975/thumb/ork.png",
  "BACON": "https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg",
  "FEED": "https://assets.coingecko.com/coins/images/22417/thumb/bJIvBemg_400x400.jpg",
  "PPOLL": "https://assets.coingecko.com/coins/images/19558/thumb/ppoll.png",
  "RITO": "https://assets.coingecko.com/coins/images/7063/thumb/45213711.png",
  "GOLC": "https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png",
  "DEXG": "https://assets.coingecko.com/coins/images/12223/thumb/dextoken-logo-v2_200.png",
  "COWRIE": "https://assets.coingecko.com/coins/images/28879/thumb/photo_2023-01-29_22.18.24.jpeg",
  "AGF": "https://assets.coingecko.com/coins/images/20077/thumb/200x200-agf.png",
  "ZUT": "https://assets.coingecko.com/coins/images/12706/thumb/logo.png",
  "GENCAP": "https://assets.coingecko.com/coins/images/22282/thumb/200x200_logo.png",
  "SWARM": "https://assets.coingecko.com/coins/images/19927/thumb/13988.png",
  "NAAL": "https://assets.coingecko.com/coins/images/19909/thumb/miso-ethernaal-logo-256.png",
  "DBET": "https://assets.coingecko.com/coins/images/1168/thumb/decent-bet.jpg",
  "EUROS": "https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png",
  "GREEN": "https://assets.coingecko.com/coins/images/22119/thumb/Green-Chart.png",
  "DYT": "https://assets.coingecko.com/coins/images/20296/thumb/HUA9G2S4_400x400.jpg",
  "BART": "https://assets.coingecko.com/coins/images/12142/thumb/200x200-transparent.png",
  "GSAIL": "https://assets.coingecko.com/coins/images/17658/thumb/logo_GSAIL.png",
  "BSOV": "https://assets.coingecko.com/coins/images/9205/thumb/bsov.png",
  "WTL": "https://assets.coingecko.com/coins/images/3612/thumb/welltrado.png",
  "YDR": "https://assets.coingecko.com/coins/images/17807/thumb/icon.png",
  "SPD": "https://assets.coingecko.com/coins/images/3542/thumb/2616.png",
  "MORA": "https://assets.coingecko.com/coins/images/14553/thumb/3vcbfVWS_400x400.png",
  "WHIRL": "https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png",
  "NDR": "https://assets.coingecko.com/coins/images/13400/thumb/ndr.jpg",
  "BMC": "https://assets.coingecko.com/coins/images/13472/thumb/bmc-logo-200.png",
  "TAP": "https://assets.coingecko.com/coins/images/27938/thumb/tap.png",
  "BPET": "https://assets.coingecko.com/coins/images/17703/thumb/Binapet-200x200.png",
  "SCRIV": "https://assets.coingecko.com/coins/images/5684/thumb/Al7TZrAN_400x400.jpg",
  "DOGES": "https://assets.coingecko.com/coins/images/12763/thumb/20200926-220107.png",
  "SHARDS": "https://assets.coingecko.com/coins/images/23728/thumb/logo_%282%29.png",
  "OCTI": "https://assets.coingecko.com/coins/images/14736/thumb/Oction_PNG.png",
  "YELD": "https://assets.coingecko.com/coins/images/16401/thumb/pxSN2UtB_400x400.jpg",
  "MDF": "https://assets.coingecko.com/coins/images/18538/thumb/MDF.png",
  "WIVA": "https://assets.coingecko.com/coins/images/18142/thumb/n8Vu43vs.png",
  "SWAY": "https://assets.coingecko.com/coins/images/18915/thumb/sway.png",
  "OBS": "https://assets.coingecko.com/coins/images/16521/thumb/OBS.50eb9474.png",
  "MERI": "https://assets.coingecko.com/coins/images/6387/thumb/merebellogo.png",
  "JETS": "https://assets.coingecko.com/coins/images/21104/thumb/fav-icon.png",
  "XFG": "https://assets.coingecko.com/coins/images/11137/thumb/9A5102B8-D28F-40D8-8B74-08F954C1FE52.jpeg",
  "BSL": "https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png",
  "MEDI": "https://assets.coingecko.com/coins/images/23037/thumb/Medi_200_x_200.PNG",
  "RNB": "https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png",
  "ELTCOIN": "https://assets.coingecko.com/coins/images/1118/thumb/eltcoin.png",
  "HORUS": "https://assets.coingecko.com/coins/images/5319/thumb/horuspay-logo.png",
  "DOGEFI": "https://assets.coingecko.com/coins/images/12290/thumb/DOGEFI-Logo.png",
  "AUSCM": "https://assets.coingecko.com/coins/images/13015/thumb/auric-1-high-res_icon_300_PNG.png",
  "MIB": "https://assets.coingecko.com/coins/images/5691/thumb/mibcoin.png",
  "OOGI": "https://assets.coingecko.com/coins/images/19714/thumb/oogi.PNG",
  "CRC": "https://assets.coingecko.com/coins/images/27013/thumb/j3bsk-13_400x400.png",
  "EKO": "https://assets.coingecko.com/coins/images/2437/thumb/echolink.png",
  "STR": "https://assets.coingecko.com/coins/images/25931/thumb/https___s3.us-east-2.amazonaws.com_nomics-api_static_images_currencies_STR6.png",
  "AFEN": "https://assets.coingecko.com/coins/images/14957/thumb/cropped-cropped-afen-e1616095076861.png",
  "KAKA": "https://assets.coingecko.com/coins/images/20877/thumb/10768.png",
  "SBS": "https://assets.coingecko.com/coins/images/14161/thumb/ifHot4z_%281%29.png",
  "MELT": "https://assets.coingecko.com/coins/images/26750/thumb/21276.png",
  "IUX": "https://assets.coingecko.com/coins/images/26078/thumb/black_IUX-coin-logo2.png",
  "SCONEX": "https://assets.coingecko.com/coins/images/15421/thumb/icon200.png",
  "$FUR": "https://assets.coingecko.com/coins/images/26035/thumb/furio.png",
  "AGORA": "https://assets.coingecko.com/coins/images/24730/thumb/Agora_Token_Logo.png",
  "YLC": "https://assets.coingecko.com/coins/images/4368/thumb/received_2320303067998561.png",
  "GUP": "https://assets.coingecko.com/coins/images/683/thumb/matchpool.png",
  "CORX": "https://assets.coingecko.com/coins/images/13129/thumb/x_log.png",
  "HORSE": "https://assets.coingecko.com/coins/images/2531/thumb/ethorse.png",
  "50C": "https://assets.coingecko.com/coins/images/16431/thumb/50cents.png",
  "ZM": "https://assets.coingecko.com/coins/images/18807/thumb/icon_zoomswap.png",
  "MSS": "https://assets.coingecko.com/coins/images/13834/thumb/coin-share-Custom.png",
  "VISR": "https://assets.coingecko.com/coins/images/14381/thumb/visor_logo.png",
  "ELVN": "https://assets.coingecko.com/coins/images/24790/thumb/11Minutes_Logo_CG.png",
  "SOTA": "https://assets.coingecko.com/coins/images/14067/thumb/sota_logo.png",
  "TDP": "https://assets.coingecko.com/coins/images/6567/thumb/truedeck.png",
  "BASK": "https://assets.coingecko.com/coins/images/14636/thumb/BASK_200_CIRCLE.png",
  "KOMBAT": "https://assets.coingecko.com/coins/images/22415/thumb/I99B1oK9_400x400.jpg",
  "YVS": "https://assets.coingecko.com/coins/images/13471/thumb/cu0LSzE.png",
  "RGP": "https://assets.coingecko.com/coins/images/15837/thumb/A_qRYvB2_400x400.png",
  "HAND": "https://assets.coingecko.com/coins/images/5554/thumb/showhand.png",
  "RC": "https://assets.coingecko.com/coins/images/19760/thumb/RC-logo-200x200-1.png",
  "UKG": "https://assets.coingecko.com/coins/images/1120/thumb/unikoin-gold.png",
  "BSCV": "https://assets.coingecko.com/coins/images/14356/thumb/photo_2021-03-12_23-07-18.jpg",
  "SISHI": "https://assets.coingecko.com/coins/images/14552/thumb/sishi_200_200.png",
  "VEO": "https://assets.coingecko.com/coins/images/7373/thumb/amoveo.jpg",
  "YOP": "https://assets.coingecko.com/coins/images/13678/thumb/J7zykPx.jpg",
  "SGLY": "https://assets.coingecko.com/coins/images/22071/thumb/sgly_square.png",
  "MONK": "https://assets.coingecko.com/coins/images/1260/thumb/MONK.png",
  "ANI": "https://assets.coingecko.com/coins/images/29009/thumb/ani.png",
  "WFAIR": "https://assets.coingecko.com/coins/images/18920/thumb/wallfair_logo.png",
  "BLZZ": "https://assets.coingecko.com/coins/images/20010/thumb/blizz_finance.jpg",
  "MBN": "https://assets.coingecko.com/coins/images/5117/thumb/membrana_logo.png",
  "XJO": "https://assets.coingecko.com/coins/images/787/thumb/joulecoin.png",
  "DMOD": "https://assets.coingecko.com/coins/images/16262/thumb/Logo_coingecko_%281%29.png",
  "SHIBA": "https://assets.coingecko.com/coins/images/18580/thumb/0aXZknGD_400x400.jpg",
  "BTCRED": "https://assets.coingecko.com/coins/images/1054/thumb/bitcoin-red.png",
  "LNC": "https://assets.coingecko.com/coins/images/3885/thumb/linker-coin.png",
  "AXE": "https://assets.coingecko.com/coins/images/6598/thumb/axe-sq.png",
  "SPRX": "https://assets.coingecko.com/coins/images/11911/thumb/logo_sprint_200x200.png",
  "RMX": "https://assets.coingecko.com/coins/images/17900/thumb/12.png",
  "LTB": "https://assets.coingecko.com/coins/images/1384/thumb/litebar.png",
  "GXT": "https://assets.coingecko.com/coins/images/12226/thumb/a.png",
  "HUR": "https://assets.coingecko.com/coins/images/2705/thumb/Hurify.jpg",
  "THEAD": "https://assets.coingecko.com/coins/images/27078/thumb/200x200.PNG",
  "GSR": "https://assets.coingecko.com/coins/images/816/thumb/geysercoin.png",
  "HAKU": "https://assets.coingecko.com/coins/images/23611/thumb/haku.png",
  "VRC": "https://assets.coingecko.com/coins/images/78/thumb/vericoin.png",
  "NIF": "https://assets.coingecko.com/coins/images/13726/thumb/logo_transparent.png",
  "STE": "https://assets.coingecko.com/coins/images/28662/thumb/STE_Logo.png",
  "COMFY": "https://assets.coingecko.com/coins/images/23062/thumb/COMFY-200x200.png",
  "UCM": "https://assets.coingecko.com/coins/images/12079/thumb/UKm2qXh.png",
  "DND": "https://assets.coingecko.com/coins/images/28200/thumb/dnd.png",
  "IMG": "https://assets.coingecko.com/coins/images/5395/thumb/ImageCoin.png",
  "$MANGA": "https://assets.coingecko.com/coins/images/17982/thumb/logo-200x200_%287%29.png",
  "CDS": "https://assets.coingecko.com/coins/images/20332/thumb/cds.png",
  "PEECOIN": "https://assets.coingecko.com/coins/images/24946/thumb/peecoin.png",
  "BT": "https://assets.coingecko.com/coins/images/26963/thumb/Bictory-Finance-logo.png",
  "BABY": "https://assets.coingecko.com/coins/images/20262/thumb/386VuTho_400x400.jpg",
  "BOOFI": "https://assets.coingecko.com/coins/images/18727/thumb/xrbdgaJc_400x400.jpg",
  "VIPER": "https://assets.coingecko.com/coins/images/15305/thumb/0c4b902b40f14db918f4500956386414ab7ebcfe.jpeg",
  "WHEY": "https://assets.coingecko.com/coins/images/23256/thumb/whey-coin-2048x2048.png",
  "TBX": "https://assets.coingecko.com/coins/images/2644/thumb/tokenbox.png",
  "TNS": "https://assets.coingecko.com/coins/images/21957/thumb/lBuWVxQg_400x400.jpg",
  "MNTIS": "https://assets.coingecko.com/coins/images/13597/thumb/ZWtjzMfP_400x400.png",
  "VLS": "https://assets.coingecko.com/coins/images/6813/thumb/veles.png",
  "WFIL": "https://assets.coingecko.com/coins/images/13238/thumb/WFIL.png",
  "CAKEBANK": "https://assets.coingecko.com/coins/images/17924/thumb/logo_256px.jpg",
  "PPDEX": "https://assets.coingecko.com/coins/images/13022/thumb/output-onlinepngtools-1.png",
  "TEND": "https://assets.coingecko.com/coins/images/11924/thumb/aaaaa.jpg",
  "BSDS": "https://assets.coingecko.com/coins/images/13411/thumb/bshare.png",
  "EPC": "https://assets.coingecko.com/coins/images/6129/thumb/download_%2818%29.png",
  "I7": "https://assets.coingecko.com/coins/images/14514/thumb/Impulseven.png",
  "UUNICLY": "https://assets.coingecko.com/coins/images/14903/thumb/Unicly_%28unicly%29.jpg",
  "LXF": "https://assets.coingecko.com/coins/images/20095/thumb/LPue04PB_400x400_%281%29.png",
  "KRW": "https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png",
  "MOOI": "https://assets.coingecko.com/coins/images/14125/thumb/mooi.png",
  "SETS": "https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png",
  "KIAN": "https://assets.coingecko.com/coins/images/14844/thumb/Kianite.png",
  "RNTB": "https://assets.coingecko.com/coins/images/3499/thumb/photo.png",
  "PTD": "https://assets.coingecko.com/coins/images/14442/thumb/logo.png",
  "ALPHR": "https://assets.coingecko.com/coins/images/15029/thumb/alphr.jpg",
  "CYCLE": "https://assets.coingecko.com/coins/images/17786/thumb/cycle-logo-round-small.png",
  "ARCO": "https://assets.coingecko.com/coins/images/2657/thumb/aquariuscoin.png",
  "KLAP": "https://assets.coingecko.com/coins/images/26140/thumb/ZsMjkr6u_400x400.png",
  "TWIN": "https://assets.coingecko.com/coins/images/14897/thumb/Full.png",
  "CTT": "https://assets.coingecko.com/coins/images/27814/thumb/logo.png",
  "STU": "https://assets.coingecko.com/coins/images/1172/thumb/bitjob.jpg",
  "CRD": "https://assets.coingecko.com/coins/images/28188/thumb/CRDlogo200_%281%29.png",
  "NODE": "https://assets.coingecko.com/coins/images/9522/thumb/61410314_394870017826322_8137973916426567680_n.png",
  "NSDX": "https://assets.coingecko.com/coins/images/18634/thumb/nadex.PNG",
  "YPIE": "https://assets.coingecko.com/coins/images/13572/thumb/YPIE.png",
  "ABX": "https://assets.coingecko.com/coins/images/5541/thumb/arbidex.png",
  "HBT": "https://assets.coingecko.com/coins/images/14456/thumb/habitat-logo.png",
  "PKEX": "https://assets.coingecko.com/coins/images/18616/thumb/1024-1024-02.png",
  "NUTS": "https://assets.coingecko.com/coins/images/28165/thumb/NSD.png",
  "KLKS": "https://assets.coingecko.com/coins/images/3952/thumb/kalkulus.png",
  "BG": "https://assets.coingecko.com/coins/images/19577/thumb/1_vagg0mEPwG-Dc8f2XMM9tg.png",
  "MTX": "https://assets.coingecko.com/coins/images/20084/thumb/MTX-200x200.png",
  "VINCI": "https://assets.coingecko.com/coins/images/17531/thumb/vinci_blue.png",
  "SFSHLD": "https://assets.coingecko.com/coins/images/17673/thumb/Safe-Shield-SFSHLD-200x200-logo.png",
  "NERVE": "https://assets.coingecko.com/coins/images/22652/thumb/email_logo.png",
  "PIS": "https://assets.coingecko.com/coins/images/13544/thumb/Logo_Polkainsure___Final-200x200-01.png",
  "GUN": "https://assets.coingecko.com/coins/images/2624/thumb/guncoin.png",
  "SLX": "https://assets.coingecko.com/coins/images/19392/thumb/logo_-_2021-10-25T161153.717.png",
  "INFX": "https://assets.coingecko.com/coins/images/311/thumb/influxcoin.png",
  "SPUNK": "https://assets.coingecko.com/coins/images/17063/thumb/Spunks_%281%29.png",
  "ORME": "https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png",
  "CRYPTO": "https://assets.coingecko.com/coins/images/28306/thumb/bcg-token-symbol-500px-1.png",
  "NIL": "https://assets.coingecko.com/coins/images/19667/thumb/_AoPtxGt_400x400.jpg",
  "COMFI": "https://assets.coingecko.com/coins/images/15006/thumb/complifi.PNG",
  "ICAP": "https://assets.coingecko.com/coins/images/18334/thumb/ICAP-logo-icon.png",
  "WGO": "https://assets.coingecko.com/coins/images/1284/thumb/wavesgo.png",
  "AVE": "https://assets.coingecko.com/coins/images/26350/thumb/YJrMm6f.png",
  "CPAY": "https://assets.coingecko.com/coins/images/10803/thumb/3pEzni6.png",
  "CCO": "https://assets.coingecko.com/coins/images/1287/thumb/LOGO_CCO.png",
  "SPB": "https://assets.coingecko.com/coins/images/1389/thumb/gold-coin.png",
  "ECASH": "https://assets.coingecko.com/coins/images/1010/thumb/ethereumcash.png",
  "TIKI": "https://assets.coingecko.com/coins/images/16427/thumb/60cbd8fd912298ca60e1fd7e_logo_TIKI_200_square.png",
  "ELV": "https://assets.coingecko.com/coins/images/23393/thumb/elvantis.PNG",
  "OXS": "https://assets.coingecko.com/coins/images/17304/thumb/9xPPXxJC_400x400.jpg",
  "KMPL": "https://assets.coingecko.com/coins/images/13449/thumb/kappa_logo.png",
  "SPI": "https://assets.coingecko.com/coins/images/13537/thumb/SPI_Token.png",
  "PONY": "https://assets.coingecko.com/coins/images/26131/thumb/pfemoji.png",
  "CPU": "https://assets.coingecko.com/coins/images/9544/thumb/uaz.ms.png",
  "AGS": "https://assets.coingecko.com/coins/images/16918/thumb/AGS.png",
  "ZINU": "https://assets.coingecko.com/coins/images/28562/thumb/zinu_new.png",
  "B21": "https://assets.coingecko.com/coins/images/3510/thumb/B21_%283%29.png",
  "GLOX": "https://assets.coingecko.com/coins/images/13312/thumb/glox_finance.png",
  "YARD": "https://assets.coingecko.com/coins/images/18400/thumb/IMG_7306.JPG",
  "DOEX": "https://assets.coingecko.com/coins/images/21493/thumb/doex-icon.png",
  "BME": "https://assets.coingecko.com/coins/images/18157/thumb/bmelogo.png",
  "DGTX": "https://assets.coingecko.com/coins/images/2188/thumb/DGTX.png",
  "STPL": "https://assets.coingecko.com/coins/images/13277/thumb/STPL.png",
  "APEIN": "https://assets.coingecko.com/coins/images/18262/thumb/apein.PNG",
  "BEET": "https://assets.coingecko.com/coins/images/3657/thumb/Beet_v2.png",
  "NVL": "https://assets.coingecko.com/coins/images/22805/thumb/17486.png",
  "FNG": "https://assets.coingecko.com/coins/images/18305/thumb/Fungie-DAO.png",
  "FOTO": "https://assets.coingecko.com/coins/images/14274/thumb/Symbol_Master-01_200x200_%281%29.png",
  "JMC": "https://assets.coingecko.com/coins/images/6532/thumb/Splash.png",
  "ARMS": "https://assets.coingecko.com/coins/images/7244/thumb/Msm1JBY.png",
  "CRYSTL": "https://assets.coingecko.com/coins/images/17023/thumb/CRYSTL_Rebrand_Logo.png",
  "FCB": "https://assets.coingecko.com/coins/images/19009/thumb/fcb.png",
  "PALG": "https://assets.coingecko.com/coins/images/15254/thumb/palg.png",
  "BOUTS": "https://assets.coingecko.com/coins/images/4296/thumb/boutspro-ico-logo-300x300.jpg",
  "FUSII": "https://assets.coingecko.com/coins/images/14210/thumb/fusible_logo.png",
  "METRIC": "https://assets.coingecko.com/coins/images/12664/thumb/metric_exchange_logo.png",
  "XFR": "https://assets.coingecko.com/coins/images/16331/thumb/XFR-logo.png",
  "DGCL": "https://assets.coingecko.com/coins/images/13519/thumb/DigiCol_Logo-01.png",
  "XUEZ": "https://assets.coingecko.com/coins/images/4640/thumb/Xuez_Coin.png",
  "BOOST": "https://assets.coingecko.com/coins/images/17657/thumb/02FjG1Og.png",
  "CBM": "https://assets.coingecko.com/coins/images/9302/thumb/CryptoBonusMiles.png",
  "SCAP": "https://assets.coingecko.com/coins/images/9984/thumb/solocap.png",
  "ZPAE": "https://assets.coingecko.com/coins/images/11557/thumb/ZPAE_.png",
  "ZXC": "https://assets.coingecko.com/coins/images/4552/thumb/0xcert.png",
  "GDR": "https://assets.coingecko.com/coins/images/8425/thumb/mROuNfEF_400x400.png",
  "START": "https://assets.coingecko.com/coins/images/26193/thumb/20770.png",
  "JEM": "https://assets.coingecko.com/coins/images/13044/thumb/itchiro-defi.png",
  "PFY": "https://assets.coingecko.com/coins/images/18669/thumb/aFlGkr4.png",
  "SBF": "https://assets.coingecko.com/coins/images/15416/thumb/steakbank.PNG",
  "RPD": "https://assets.coingecko.com/coins/images/6523/thumb/Rapids-V2.Logo.png",
  "BBC": "https://assets.coingecko.com/coins/images/28030/thumb/bbc.png",
  "KKO": "https://assets.coingecko.com/coins/images/15366/thumb/kko-coingecko.png",
  "BMF": "https://assets.coingecko.com/coins/images/25192/thumb/Meta_Fame_Token.png",
  "FRIES": "https://assets.coingecko.com/coins/images/19533/thumb/soltato.png",
  "FMG": "https://assets.coingecko.com/coins/images/14527/thumb/FR44th_I_400x400.jpg",
  "DVT": "https://assets.coingecko.com/coins/images/8640/thumb/dvt-icon-dark.png",
  "ARION": "https://assets.coingecko.com/coins/images/5503/thumb/arion.png",
  "BABL": "https://assets.coingecko.com/coins/images/20049/thumb/favicon-260x260.png",
  "MTOP": "https://assets.coingecko.com/coins/images/26774/thumb/Standalone_Square.png",
  "BM": "https://assets.coingecko.com/coins/images/18050/thumb/Burning-Moon-200x200.png",
  "KEMA": "https://assets.coingecko.com/coins/images/8602/thumb/gUgiw7zP_400x400.jpg",
  "SUV": "https://assets.coingecko.com/coins/images/9403/thumb/SUV.png",
  "CRE8R": "https://assets.coingecko.com/coins/images/22568/thumb/cropped-FINALwithbrick-2-1-1.png",
  "RAISE": "https://assets.coingecko.com/coins/images/4411/thumb/Raise.png",
  "MYTH": "https://assets.coingecko.com/coins/images/28045/thumb/Mythos_Logos_200x200.png",
  "PRTCLE": "https://assets.coingecko.com/coins/images/14395/thumb/unknown.png",
  "XBTX": "https://assets.coingecko.com/coins/images/9539/thumb/coin.jpg",
  "ROT": "https://assets.coingecko.com/coins/images/12560/thumb/rot_logo.png",
  "TFF": "https://assets.coingecko.com/coins/images/14445/thumb/VOUyAE3u_400x400.png",
  "BTC2X-FLI-P": "https://assets.coingecko.com/coins/images/24179/thumb/Copy_of_BTC2x-FLI_token_logo.png",
  "RVL": "https://assets.coingecko.com/coins/images/19044/thumb/rvl.PNG",
  "SINS": "https://assets.coingecko.com/coins/images/6158/thumb/sins_256.png",
  "CRETH2": "https://assets.coingecko.com/coins/images/13568/thumb/creth2.png",
  "MRFI": "https://assets.coingecko.com/coins/images/18818/thumb/FLPgwQdc_400x400.jpg",
  "CBFINU": "https://assets.coingecko.com/coins/images/28264/thumb/20221117_174905.png",
  "DOE": "https://assets.coingecko.com/coins/images/19942/thumb/doe.png",
  "GSC": "https://assets.coingecko.com/coins/images/4304/thumb/global-social-chain.png",
  "ROLL": "https://assets.coingecko.com/coins/images/23458/thumb/OslGsgzd_400x400.jpg",
  "MEEB": "https://assets.coingecko.com/coins/images/17044/thumb/Meebs.png",
  "PAWS": "https://assets.coingecko.com/coins/images/20779/thumb/logo-1.png",
  "DOGY": "https://assets.coingecko.com/coins/images/13734/thumb/dogy200px.png",
  "TIC": "https://assets.coingecko.com/coins/images/25919/thumb/VNReVzc5Sg6NqHONgxEQ_4BI9yq3820sYQx0R.jpg",
  "PACIFIC": "https://assets.coingecko.com/coins/images/18269/thumb/Webp.net-resizeimage_%281%29.png",
  "NKA": "https://assets.coingecko.com/coins/images/854/thumb/incakoin.png",
  "IEUROS": "https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png",
  "VYNC": "https://assets.coingecko.com/coins/images/17743/thumb/vynk_chain.PNG",
  "BZNT": "https://assets.coingecko.com/coins/images/3825/thumb/bezant.png",
  "WRC": "https://assets.coingecko.com/coins/images/1358/thumb/worldcore.png",
  "JS": "https://assets.coingecko.com/coins/images/1061/thumb/js.png",
  "SACT": "https://assets.coingecko.com/coins/images/14538/thumb/SACTstake200x200.png",
  "DALC": "https://assets.coingecko.com/coins/images/932/thumb/dalecoin.png",
  "MELO": "https://assets.coingecko.com/coins/images/16571/thumb/melo.PNG",
  "MXX": "https://assets.coingecko.com/coins/images/12119/thumb/MXXlogo.png",
  "ALLEY": "https://assets.coingecko.com/coins/images/15150/thumb/Copy_of_NFT_Alley_Final.png",
  "PLUS1": "https://assets.coingecko.com/coins/images/6002/thumb/plusonecoin-logo.png",
  "TAJ": "https://assets.coingecko.com/coins/images/1419/thumb/Tajcoin.jpg",
  "SAC": "https://assets.coingecko.com/coins/images/24385/thumb/stone_ape.PNG",
  "VITOGE": "https://assets.coingecko.com/coins/images/21781/thumb/OxgwnkA6_400x400.jpg",
  "ARO": "https://assets.coingecko.com/coins/images/5401/thumb/arionum.png",
  "ROOM": "https://assets.coingecko.com/coins/images/13889/thumb/option_room_logo.png",
  "TATA": "https://assets.coingecko.com/coins/images/15718/thumb/output-onlinepngtools-1.png",
  "SHDC": "https://assets.coingecko.com/coins/images/14144/thumb/SHDC-Main-Logo.jpg",
  "HERB": "https://assets.coingecko.com/coins/images/6892/thumb/herbalist-token-twitter.png",
  "EGGP": "https://assets.coingecko.com/coins/images/15481/thumb/eggp.png",
  "UM": "https://assets.coingecko.com/coins/images/18798/thumb/Moneda.png",
  "CRYY": "https://assets.coingecko.com/coins/images/22128/thumb/cry_logo_200_200.png",
  "WIDI": "https://assets.coingecko.com/coins/images/21140/thumb/widiland.PNG",
  "GAUR": "https://assets.coingecko.com/coins/images/22515/thumb/gaur.png",
  "ACXT": "https://assets.coingecko.com/coins/images/13293/thumb/ACDX_Original_05.png",
  "CHL": "https://assets.coingecko.com/coins/images/5488/thumb/ChallegeDAC.jpg",
  "ATG": "https://assets.coingecko.com/coins/images/26184/thumb/astro-transparentgecko-icon.png",
  "TDS": "https://assets.coingecko.com/coins/images/1821/thumb/tokendesk.png",
  "MPAD": "https://assets.coingecko.com/coins/images/18145/thumb/200_x_200_%281%29.png",
  "IMATIC-FLI-P": "https://assets.coingecko.com/coins/images/23424/thumb/iMATIC-FLI-P-512_%28pulse_red%29.png",
  "XVIPER": "https://assets.coingecko.com/coins/images/16574/thumb/xVIPER-200x200.png",
  "BMXX": "https://assets.coingecko.com/coins/images/14235/thumb/FMG5eE0.png",
  "PEAR": "https://assets.coingecko.com/coins/images/17173/thumb/pear200.png",
  "EHRT": "https://assets.coingecko.com/coins/images/8786/thumb/8hours.png",
  "LOOX": "https://assets.coingecko.com/coins/images/17082/thumb/LOOX__200x200_PNG.png",
  "SLM": "https://assets.coingecko.com/coins/images/7440/thumb/817.png",
  "WOD": "https://assets.coingecko.com/coins/images/22126/thumb/Logo.jpg",
  "VGR": "https://assets.coingecko.com/coins/images/7381/thumb/PKQbxUtP_400x400.jpg",
  "BNF": "https://assets.coingecko.com/coins/images/12862/thumb/bonfi_logo.png",
  "OGO": "https://assets.coingecko.com/coins/images/4377/thumb/Origo_Logo_png.png",
  "MEDIBIT": "https://assets.coingecko.com/coins/images/6606/thumb/medibit.png",
  "FYZ": "https://assets.coingecko.com/coins/images/12202/thumb/fyooz.png",
  "SHO": "https://assets.coingecko.com/coins/images/15084/thumb/icon_%284%29.png",
  "SING": "https://assets.coingecko.com/coins/images/18846/thumb/sing_token.png",
  "KWATT": "https://assets.coingecko.com/coins/images/4975/thumb/4new.png",
  "JUNI": "https://assets.coingecko.com/coins/images/27372/thumb/640640_%281%29.jpg",
  "FNTB": "https://assets.coingecko.com/coins/images/3773/thumb/300x300_%281%29.png",
  "CTRT": "https://assets.coingecko.com/coins/images/6110/thumb/cryptrust.png",
  "FTXT": "https://assets.coingecko.com/coins/images/5796/thumb/futurax.png",
  "DATP": "https://assets.coingecko.com/coins/images/6565/thumb/Decentralized_Asset_Trading_Platform.jpg",
  "LUD": "https://assets.coingecko.com/coins/images/17764/thumb/whitelogo200x200.png",
  "GST": "https://assets.coingecko.com/coins/images/9732/thumb/gst-logo.png",
  "RESTAURANTS": "https://assets.coingecko.com/coins/images/22817/thumb/Devour-200x200-2.png",
  "SISTA": "https://assets.coingecko.com/coins/images/17262/thumb/SISTA_Logo200x200.png",
  "CODI": "https://assets.coingecko.com/coins/images/22798/thumb/ovFr4h_Y_n8q_1RIgfg5IFCRtx56Uc0R-GC9LiIcy1HOgigf0mYH2kWVFuvBipErCvpnrp0yps4Y3XTis-boKJg_2_ucFmv3Iu0CaSyCXThFihx-yrr9vo7t0HEL5optQ6jKAXpSLtXvKZPHrmMgMM2VFB2D4UCPxGsCItv6kvSix3LsjLcrKmTAvmHLvCby1om1BvKJLFcXP0.jpg",
  "SQUAWK": "https://assets.coingecko.com/coins/images/25850/thumb/SYp95hCy_400x400.jpg",
  "SAS": "https://assets.coingecko.com/coins/images/13543/thumb/sac.jpg",
  "MFX": "https://assets.coingecko.com/coins/images/26557/thumb/metFx200_wcaqdp.png",
  "YFPI": "https://assets.coingecko.com/coins/images/12640/thumb/200.png",
  "PPR": "https://assets.coingecko.com/coins/images/27800/thumb/PaperDao_logo_200px.png",
  "CLG": "https://assets.coingecko.com/coins/images/7560/thumb/collegicoin.png",
  "2LC": "https://assets.coingecko.com/coins/images/16062/thumb/2local_symbol-512px_margin.png",
  "DGE": "https://assets.coingecko.com/coins/images/20276/thumb/IMG-20211112-152137-726.jpg",
  "KTY": "https://assets.coingecko.com/coins/images/22542/thumb/TU0c6_ZM_400x400.jpg",
  "SOLAB": "https://assets.coingecko.com/coins/images/21014/thumb/2ZR6Gkz.png",
  "INTU": "https://assets.coingecko.com/coins/images/4275/thumb/intucoin-symbol.png",
  "CJT": "https://assets.coingecko.com/coins/images/2207/thumb/connectjob.png",
  "KTS": "https://assets.coingecko.com/coins/images/7899/thumb/74kNYS7Z_400x400.jpg",
  "CPOO": "https://assets.coingecko.com/coins/images/16852/thumb/cpoo.png",
  "TOX": "https://assets.coingecko.com/coins/images/16903/thumb/trollbox-200.png",
  "JENN": "https://assets.coingecko.com/coins/images/15868/thumb/VNhEzm2X_400x400.jpg",
  "DATX": "https://assets.coingecko.com/coins/images/2942/thumb/datx.png",
  "XSR": "https://assets.coingecko.com/coins/images/9224/thumb/Xensor_Logo_%28White__200x200px%29.png",
  "EDAO": "https://assets.coingecko.com/coins/images/17323/thumb/edao_logo.png",
  "R1": "https://assets.coingecko.com/coins/images/22090/thumb/recast_200_200.png",
  "TOTO": "https://assets.coingecko.com/coins/images/5271/thumb/tourist-token.png",
  "KMX": "https://assets.coingecko.com/coins/images/8062/thumb/XSsfMr8.png",
  "PANIC": "https://assets.coingecko.com/coins/images/23643/thumb/chef.09dad978.png",
  "SHMN": "https://assets.coingecko.com/coins/images/5593/thumb/SHMN_icon_primary_512x512.png",
  "SFCP": "https://assets.coingecko.com/coins/images/6239/thumb/36952079_1621155091328297_7456989147790573568_n.jpg",
  "IIC": "https://assets.coingecko.com/coins/images/4307/thumb/intelligent-investment-chain.png",
  "BAKECOIN": "https://assets.coingecko.com/coins/images/18296/thumb/IMG-20210909-160846-983.jpg",
  "AXIAV3": "https://assets.coingecko.com/coins/images/12906/thumb/axia_200x200.png",
  "TWA": "https://assets.coingecko.com/coins/images/13284/thumb/twa_logo.jpg",
  "PMD": "https://assets.coingecko.com/coins/images/18423/thumb/pmd_%281%29.png",
  "ZTC": "https://assets.coingecko.com/coins/images/8017/thumb/aCTsASO8_400x400.jpg",
  "WAV": "https://assets.coingecko.com/coins/images/22176/thumb/IMG-20220101-021048.jpg",
  "YODE": "https://assets.coingecko.com/coins/images/26944/thumb/yodeswap.png",
  "DISTX": "https://assets.coingecko.com/coins/images/12291/thumb/DISTX-icon.png",
  "BOUNTIE": "https://assets.coingecko.com/coins/images/24559/thumb/BountieHunter_LogoDesign_TransparentBackground.png",
  "RIGEL": "https://assets.coingecko.com/coins/images/13674/thumb/logo-200x200_%282%29.png",
  "ZND": "https://assets.coingecko.com/coins/images/7057/thumb/zenad-logo.png",
  "KEC": "https://assets.coingecko.com/coins/images/4902/thumb/keyco-token-logo.jpg",
  "ASH": "https://assets.coingecko.com/coins/images/23870/thumb/Ash-Logo-256.png",
  "RULER": "https://assets.coingecko.com/coins/images/14183/thumb/ruler_token.png",
  "ETHV": "https://assets.coingecko.com/coins/images/16716/thumb/logo_-_2021-06-28T092549.772.png",
  "KOMET": "https://assets.coingecko.com/coins/images/13196/thumb/komet_finance_logo.png",
  "FLOOF": "https://assets.coingecko.com/coins/images/19810/thumb/FLOOF_logo_200x200.png",
  "BRTR": "https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png",
  "FAITH": "https://assets.coingecko.com/coins/images/3648/thumb/Y4u0Cl8.png",
  "WEI": "https://assets.coingecko.com/coins/images/26422/thumb/LogoWEI200x200.png",
  "BAEPAY": "https://assets.coingecko.com/coins/images/13101/thumb/baepay_logo.png",
  "SYBC": "https://assets.coingecko.com/coins/images/12901/thumb/sybc.png",
  "FERA": "https://assets.coingecko.com/coins/images/12143/thumb/IMG_20200908_085545_557.jpg",
  "SPICE": "https://assets.coingecko.com/coins/images/9323/thumb/GXW7199.jpg",
  "SCSX": "https://assets.coingecko.com/coins/images/7803/thumb/SCSX_LOGO_OFFICIAL_200x200.png",
  "LYS": "https://assets.coingecko.com/coins/images/20863/thumb/1637613107-lys-logo-cg-200pix.png",
  "ELD": "https://assets.coingecko.com/coins/images/6469/thumb/logo_%2817%29.png",
  "WDC": "https://assets.coingecko.com/coins/images/17/thumb/16.png",
  "1MT": "https://assets.coingecko.com/coins/images/27347/thumb/1MT.png",
  "BLTG": "https://assets.coingecko.com/coins/images/2680/thumb/BLTG_Logo_276x276.png",
  "WGMI": "https://assets.coingecko.com/coins/images/20330/thumb/new_WGMI_logo-01.png",
  "GUESS": "https://assets.coingecko.com/coins/images/1743/thumb/guess.png",
  "LEONIDAS": "https://assets.coingecko.com/coins/images/20496/thumb/cropped-logo_%281%29.png",
  "VITAL": "https://assets.coingecko.com/coins/images/27790/thumb/logo512.png",
  "LEVIN": "https://assets.coingecko.com/coins/images/16004/thumb/JHqkC8Z.png",
  "BTCD": "https://assets.coingecko.com/coins/images/26354/thumb/logo_png_%283%29.png",
  "SLNA": "https://assets.coingecko.com/coins/images/24845/thumb/icon.f97a40025f9cd28101e4903f23b304ff.png",
  "STOP": "https://assets.coingecko.com/coins/images/12220/thumb/stop.png",
  "SMS": "https://assets.coingecko.com/coins/images/1807/thumb/speed-mining-service.png",
  "NAXAR": "https://assets.coingecko.com/coins/images/16946/thumb/logo.png",
  "FAB": "https://assets.coingecko.com/coins/images/16649/thumb/FABLOGO_TRANS200.png",
  "VIRAL": "https://assets.coingecko.com/coins/images/26172/thumb/viralup.png",
  "GRFT": "https://assets.coingecko.com/coins/images/1979/thumb/GRAFT_Blockchain_logo.png",
  "1SP": "https://assets.coingecko.com/coins/images/26474/thumb/1SP_logo.png",
  "RNBW": "https://assets.coingecko.com/coins/images/16754/thumb/RNBW-256x256.png",
  "TOKO": "https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png",
  "HODL": "https://assets.coingecko.com/coins/images/23563/thumb/hodlicon.png",
  "KERMIT": "https://assets.coingecko.com/coins/images/17109/thumb/NFhvfXh.png",
  "TERA": "https://assets.coingecko.com/coins/images/23880/thumb/Tera-200x200-1.png",
  "INVE": "https://assets.coingecko.com/coins/images/4756/thumb/intervalue-logo.png",
  "FROGEX": "https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png",
  "BURN": "https://assets.coingecko.com/coins/images/26721/thumb/burn.png",
  "MFTU": "https://assets.coingecko.com/coins/images/5519/thumb/Mainstream_for_the_underground.png",
  "VVT": "https://assets.coingecko.com/coins/images/13380/thumb/HkfxEtWh_400x400.jpg",
  "LIX": "https://assets.coingecko.com/coins/images/15333/thumb/SVxrN7DF_400x400.jpg",
  "T": "https://assets.coingecko.com/coins/images/22228/thumb/nFPNiSbL_400x400.jpg",
  "M": "https://assets.coingecko.com/coins/images/26104/thumb/M.png",
  "H": "https://assets.coingecko.com/coins/images/28759/thumb/H.png",
  "DEFI": "https://assets.coingecko.com/coins/images/23112/thumb/D3pro.4e6dfc66.png",
  "PN": "https://assets.coingecko.com/coins/images/20601/thumb/pn.png",
  "G*": "missing_thumb.png",
  "MO": "https://assets.coingecko.com/coins/images/12961/thumb/Group-9.png",
  "GN": "https://assets.coingecko.com/coins/images/20207/thumb/gn_logo.png",
  "X2": "https://assets.coingecko.com/coins/images/17289/thumb/download_%284%29.png",
  "XI": "https://assets.coingecko.com/coins/images/18640/thumb/v5NMxPo.png",
  "M2": "https://assets.coingecko.com/coins/images/18239/thumb/m2_%281%29.png",
  "DO": "https://assets.coingecko.com/coins/images/23790/thumb/O1M_2pwe_400x400.jpg",
  "CC": "https://assets.coingecko.com/coins/images/26223/thumb/No_Text.png",
  "JP": "https://assets.coingecko.com/coins/images/28967/thumb/Jp.png",
  "IG": "https://assets.coingecko.com/coins/images/5434/thumb/igtoken.png",
  "HUH": "https://assets.coingecko.com/coins/images/21549/thumb/huh.png",
  "RHO": "https://assets.coingecko.com/coins/images/17695/thumb/rhinos.PNG",
  "SEA": "https://assets.coingecko.com/coins/images/21711/thumb/12432.png",
  "BXH": "https://assets.coingecko.com/coins/images/14936/thumb/qzfeN3Q.jpeg",
  "3QT": "https://assets.coingecko.com/coins/images/26941/thumb/3qt_logo_200x200_transparent.png",
  "EXP": "https://assets.coingecko.com/coins/images/339/thumb/expanse.png",
  "AAB": "https://assets.coingecko.com/coins/images/11073/thumb/GluwoJk__400x400.jpg",
  "FXT": "https://assets.coingecko.com/coins/images/14131/thumb/7rgEwxRT_400x400.jpg",
  "PYE": "https://assets.coingecko.com/coins/images/26293/thumb/iShot_2022-09-21_20.33.52.png",
  "POP!": "https://assets.coingecko.com/coins/images/14598/thumb/pop.png",
  "T23": "https://assets.coingecko.com/coins/images/28604/thumb/T23-Icon-200x200.png",
  "MPX": "https://assets.coingecko.com/coins/images/28965/thumb/mpx_logo_256.png",
  "TVT": "https://assets.coingecko.com/coins/images/8388/thumb/g8hXw4QX_400x400.jpg",
  "BPM": "https://assets.coingecko.com/coins/images/23869/thumb/bpm_200.png",
  "MEH": "https://assets.coingecko.com/coins/images/27714/thumb/mlogo.png",
  "YZZ": "https://assets.coingecko.com/coins/images/27519/thumb/02fNnB63_400x400.jpeg",
  "MCO": "https://assets.coingecko.com/coins/images/739/thumb/1776.png",
  "DPK TOKEN": "https://assets.coingecko.com/coins/images/22266/thumb/LNjUbO_A_400x400.jpg",
  "IDK": "https://assets.coingecko.com/coins/images/9301/thumb/idk-logo.png",
  "ENO": "https://assets.coingecko.com/coins/images/26501/thumb/Eno_logo.png",
  "DMZ": "https://assets.coingecko.com/coins/images/18214/thumb/dmz.PNG",
  "XRX": "https://assets.coingecko.com/coins/images/26434/thumb/token-icon-rex.jpg",
  "FAM": "https://assets.coingecko.com/coins/images/17809/thumb/Logo_Fam_200px.png",
  "HEX": "https://assets.coingecko.com/coins/images/27498/thumb/hex.jpeg",
  "RAX": "https://assets.coingecko.com/coins/images/27235/thumb/Group_9137_%281%29.png",
  "DUX": "https://assets.coingecko.com/coins/images/28836/thumb/photo_2023-01-12_17-36-54.jpg",
  "OMM": "https://assets.coingecko.com/coins/images/18951/thumb/omm.png",
  "NYXT": "https://assets.coingecko.com/coins/images/22174/thumb/NYX-logo-medium-size.png",
  "VOW": "https://assets.coingecko.com/coins/images/18202/thumb/72Nd63R0_400x400.png",
  "JDI": "https://assets.coingecko.com/coins/images/15220/thumb/JDI.png",
  "TEA": "https://assets.coingecko.com/coins/images/14426/thumb/teachain-logo.png",
  "ZOOT": "https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png",
  "HUB": "https://assets.coingecko.com/coins/images/14639/thumb/hub200.png",
  "USN": "https://assets.coingecko.com/coins/images/24930/thumb/download.png",
  "OJE": "https://assets.coingecko.com/coins/images/19811/thumb/Hv-ROnYV_400x400.jpg",
  "EOX": "https://assets.coingecko.com/coins/images/13754/thumb/Untitled-design-5-removebg-preview.png",
  "X7R": "https://assets.coingecko.com/coins/images/27710/thumb/X7R.png",
  "HEY": "https://assets.coingecko.com/coins/images/28092/thumb/LogoCG.png",
  "IXO": "https://assets.coingecko.com/coins/images/16829/thumb/ixo.png",
  "HTM": "https://assets.coingecko.com/coins/images/14671/thumb/noticeLogo.png",
  "TYV": "https://assets.coingecko.com/coins/images/24802/thumb/tyv.png",
  "FIT": "https://assets.coingecko.com/coins/images/25937/thumb/FIT_200x200.png",
  "MP3": "https://assets.coingecko.com/coins/images/13897/thumb/1_sq13-ssNvv2APEFcy8dWJg.png",
  "GMD": "https://assets.coingecko.com/coins/images/20567/thumb/vlIa57Ki_400x400.png",
  "IFT": "https://assets.coingecko.com/coins/images/14049/thumb/ift_200x200.jpg",
  "DBX": "https://assets.coingecko.com/coins/images/15682/thumb/logo.png",
  "UCX": "https://assets.coingecko.com/coins/images/10131/thumb/UCX_200200.png",
  "XBE": "https://assets.coingecko.com/coins/images/14480/thumb/xbe.png",
  "LIF": "https://assets.coingecko.com/coins/images/1760/thumb/lif-logo.png",
  "NDB": "https://assets.coingecko.com/coins/images/26545/thumb/ba98gdMa_400x400.jpeg",
  "MXM": "https://assets.coingecko.com/coins/images/26346/thumb/Qi3QBp_m_400x400.jpeg",
  "LBK": "https://assets.coingecko.com/coins/images/9492/thumb/lbk.jpeg",
  "TFS": "https://assets.coingecko.com/coins/images/21963/thumb/dOF2IBa.png",
  "CCA": "https://assets.coingecko.com/coins/images/8234/thumb/fufaXCZf_400x400.png",
  "ESG": "https://assets.coingecko.com/coins/images/24932/thumb/esg.png",
  "LCG": "https://assets.coingecko.com/coins/images/12851/thumb/LCG_token.jpg",
  "EGO": "https://assets.coingecko.com/coins/images/26677/thumb/egologo2022.05.20_EGO_AK_Icon_300x300_px.png",
  "BRO": "https://assets.coingecko.com/coins/images/21451/thumb/dexbrwoser.PNG",
  "ZUM": "https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png",
  "CSR": "https://assets.coingecko.com/coins/images/25286/thumb/icon200.png",
  "SWG": "https://assets.coingecko.com/coins/images/16177/thumb/swf.PNG",
  "O5O": "https://assets.coingecko.com/coins/images/27245/thumb/LOGO_JPG-removebg-preview.png",
  "YAS": "https://assets.coingecko.com/coins/images/11975/thumb/36440732.png",
  "FTT": "https://assets.coingecko.com/coins/images/22996/thumb/FTT_wh_small.png",
  "$MUU": "https://assets.coingecko.com/coins/images/25620/thumb/muu.jpg",
  "LAW": "https://assets.coingecko.com/coins/images/24412/thumb/18910.png",
  "ZIN": "https://assets.coingecko.com/coins/images/13250/thumb/coingecko_logo.png",
  "UBU": "https://assets.coingecko.com/coins/images/10686/thumb/NFGYEIaA_400x400_%281%29.png",
  "KUY": "https://assets.coingecko.com/coins/images/26459/thumb/KUY_TOken.png",
  "UME": "https://assets.coingecko.com/coins/images/14348/thumb/UME_Logo.png",
  "7UP": "https://assets.coingecko.com/coins/images/12953/thumb/7up-token.png",
  "CIA": "https://assets.coingecko.com/coins/images/21569/thumb/Untitled-design-24.png",
  "KOK": "https://assets.coingecko.com/coins/images/10502/thumb/logo_navy.png",
  "ITC": "https://assets.coingecko.com/coins/images/15943/thumb/73Gfsv9M_400x400.jpg",
  "OSK": "https://assets.coingecko.com/coins/images/23523/thumb/osk_defi11.jpg",
  "DAD": "https://assets.coingecko.com/coins/images/9833/thumb/q_Vt0ajV_400x400.jpg",
  "BEMT": "https://assets.coingecko.com/coins/images/20549/thumb/bem.png",
  "LOL": "https://assets.coingecko.com/coins/images/8551/thumb/logo100.png",
  "ERA": "https://assets.coingecko.com/coins/images/24061/thumb/18483.png",
  "RBX": "https://assets.coingecko.com/coins/images/29014/thumb/RBX.logo.png",
  "VIP": "https://assets.coingecko.com/coins/images/7745/thumb/vip.png",
  "POW": "https://assets.coingecko.com/coins/images/10466/thumb/pow.PNG",
  "Y2B": "https://assets.coingecko.com/coins/images/28638/thumb/logo.jpg",
  "SIF": "https://assets.coingecko.com/coins/images/21329/thumb/sif.PNG",
  "3GG": "https://assets.coingecko.com/coins/images/27864/thumb/%E8%9E%A2%E5%B9%95%E6%88%AA%E5%9C%96_2022-10-17_%E4%B8%8B%E5%8D%888.28.14.png",
  "IZE": "https://assets.coingecko.com/coins/images/11031/thumb/logo.png",
  "HLP": "https://assets.coingecko.com/coins/images/12668/thumb/simbolo-hlp-token.png",
  "INU": "https://assets.coingecko.com/coins/images/20020/thumb/YGid9wMv_400x400.jpg",
  "BJT": "https://assets.coingecko.com/coins/images/27673/thumb/main20.png",
  "FUZE": "https://assets.coingecko.com/coins/images/8941/thumb/logo-fuze-fix-big.png",
  "WATR": "https://assets.coingecko.com/coins/images/27573/thumb/0703EF66-69DB-43E4-BA7C-927C0C6B543B.png",
  "JOYS": "https://assets.coingecko.com/coins/images/4086/thumb/32073014_232668050621755_3742934543854731264_n.png",
  "EVNY": "https://assets.coingecko.com/coins/images/14555/thumb/envy.jpg",
  "POST": "https://assets.coingecko.com/coins/images/1394/thumb/postcoin.png",
  "MOPS": "https://assets.coingecko.com/coins/images/26900/thumb/mops.png",
  "PAWN": "https://assets.coingecko.com/coins/images/26190/thumb/9K3MCtws_400x400.jpg",
  "NEUY": "https://assets.coingecko.com/coins/images/25870/thumb/logo200x200.png",
  "CEJI": "https://assets.coingecko.com/coins/images/26458/thumb/CEJI.png",
  "TBCC": "https://assets.coingecko.com/coins/images/13353/thumb/200-200.png",
  "PINE": "https://assets.coingecko.com/coins/images/21253/thumb/PINELOGO.png",
  "WEB3.0": "https://assets.coingecko.com/coins/images/26347/thumb/wevb3.jpeg",
  "BDLT": "https://assets.coingecko.com/coins/images/26043/thumb/icon_lg-dark.png",
  "CAMP": "https://assets.coingecko.com/coins/images/13338/thumb/CampGlobal_256256.png",
  "GROK": "https://assets.coingecko.com/coins/images/24574/thumb/ZtRMy9Z7_400x400.png",
  "RAYS": "https://assets.coingecko.com/coins/images/26609/thumb/mch_rays_200.png",
  "XTAL": "https://assets.coingecko.com/coins/images/26351/thumb/XTAL-LOGO.png",
  "EGOD": "https://assets.coingecko.com/coins/images/26370/thumb/rsz_q8ikvoag_400x400.png",
  "BMAX": "https://assets.coingecko.com/coins/images/26427/thumb/BMAX_logo.png",
  "MOGX": "https://assets.coingecko.com/coins/images/9687/thumb/UZpzCJc1_400x400.jpg",
  "BALL": "https://assets.coingecko.com/coins/images/12927/thumb/logo.png",
  "APCH": "https://assets.coingecko.com/coins/images/26403/thumb/appch.jpeg",
  "ALY": "https://assets.coingecko.com/coins/images/9619/thumb/BTf_sTXi_400x400.jpg",
  "OSIS": "https://assets.coingecko.com/coins/images/28581/thumb/IMG_20221225_054733_921.jpg",
  "REDI": "https://assets.coingecko.com/coins/images/6329/thumb/token_blue.png",
  "FATE": "https://assets.coingecko.com/coins/images/19429/thumb/BLVk7aG.png",
  "PAUL": "https://assets.coingecko.com/coins/images/19494/thumb/paul.png",
  "BIRB": "https://assets.coingecko.com/coins/images/26373/thumb/birb-logo.png",
  "NTM": "https://assets.coingecko.com/coins/images/9228/thumb/3DFhadm5_400x400_%281%29.jpg",
  "WEENS": "https://assets.coingecko.com/coins/images/21764/thumb/logo_%281%29.png",
  "APES": "https://assets.coingecko.com/coins/images/22073/thumb/npOIT79d_400x400.png",
  "WAXE": "https://assets.coingecko.com/coins/images/13508/thumb/waxe_logo.png",
  "XPLA": "https://assets.coingecko.com/coins/images/27925/thumb/xpla.png",
  "G999": "https://assets.coingecko.com/coins/images/13431/thumb/g999.png",
  "CYFI": "https://assets.coingecko.com/coins/images/17530/thumb/cyfi.PNG",
  "RENS": "https://assets.coingecko.com/coins/images/25681/thumb/200cx200.png",
  "GASP": "https://assets.coingecko.com/coins/images/13047/thumb/gasp.png",
  "BILL": "https://assets.coingecko.com/coins/images/18480/thumb/photo_2021-08-16_23-27-37.jpg",
  "MINI": "https://assets.coingecko.com/coins/images/12298/thumb/IrTAVc_GqZ7iQucAa3fNYlh_Cqt3tY9wM_pmzOl5SifscRMpuzrp_dizMzGTiMr_NxDJPCKigBgz8THrzvO_DqT3JLzqZIYeytDBRw3qKI73dljS0BnFaaI2aLadpdCZah4RkhydddLIDDbQlGit77farlQRaq7qEgxdjVe0aqEeh4phE-DWAKi_KS_Yz-fFdDfjWgifVCKkZRBeNSWWQEplxxl.jpg",
  "BODAV2": "https://assets.coingecko.com/coins/images/19168/thumb/BODAV2-New-logo-round-200.png",
  "BOJI": "https://assets.coingecko.com/coins/images/25290/thumb/bojiv2-2.png",
  "STAY": "https://assets.coingecko.com/coins/images/25479/thumb/stay.png",
  "MIKU": "https://assets.coingecko.com/coins/images/26680/thumb/PNGLO.png",
  "JEFE": "https://assets.coingecko.com/coins/images/25336/thumb/JEFE_200.png",
  "XUSD": "https://assets.coingecko.com/coins/images/16291/thumb/xUSD-web-transparent.png",
  "NUNA": "https://assets.coingecko.com/coins/images/21553/thumb/nuna.png",
  "MARO": "https://assets.coingecko.com/coins/images/3214/thumb/download_%284%29.png",
  "BR": "https://assets.coingecko.com/coins/images/25451/thumb/jMTfKKWx_400x400.jpg",
  "ZJOE": "https://assets.coingecko.com/coins/images/25532/thumb/zJOE-200px.png",
  "HARE": "https://assets.coingecko.com/coins/images/16327/thumb/200ico.png",
  "YUSE": "https://assets.coingecko.com/coins/images/25416/thumb/NlEpysOy_400x400.png",
  "BRCP": "https://assets.coingecko.com/coins/images/14388/thumb/brcp-500x500-verde.png",
  "ADAM": "https://assets.coingecko.com/coins/images/26861/thumb/fvr0B85g_400x400.jpeg",
  "BRMV": "https://assets.coingecko.com/coins/images/14389/thumb/brmv-logo-256px.png",
  "NRA": "https://assets.coingecko.com/coins/images/20107/thumb/NORA.png",
  "ROC": "https://assets.coingecko.com/coins/images/27280/thumb/Rocket_Raccoon_Logo.png",
  "MLB": "https://assets.coingecko.com/coins/images/27127/thumb/Logo-200.png",
  "XRUN": "https://assets.coingecko.com/coins/images/25272/thumb/19787.png",
  "ROBO": "https://assets.coingecko.com/coins/images/14611/thumb/rlogo200.jpg",
  "NCAT": "https://assets.coingecko.com/coins/images/14556/thumb/ncat_logo.jpg",
  "DEMX": "https://assets.coingecko.com/coins/images/29067/thumb/demx.png",
  "OBOL": "https://assets.coingecko.com/coins/images/26585/thumb/obol_%282%29.png",
  "EASE": "https://assets.coingecko.com/coins/images/27228/thumb/ease_symbol-200x200.png",
  "1SOL": "https://assets.coingecko.com/coins/images/22923/thumb/1SOL_wh_small.png",
  "BIDR": "https://assets.coingecko.com/coins/images/12121/thumb/bidr_mark_transparent.png",
  "VOID": "https://assets.coingecko.com/coins/images/21307/thumb/h5lkasZH_400x400.jpg",
  "HUNY": "https://assets.coingecko.com/coins/images/25241/thumb/_HUNY-icon.png",
  "RCCC": "https://assets.coingecko.com/coins/images/6627/thumb/rccc_token.png",
  "SIFU": "https://assets.coingecko.com/coins/images/24692/thumb/token_%283%29.png",
  "WBX": "https://assets.coingecko.com/coins/images/11009/thumb/wibx_new_logo.png",
  "ENX": "https://assets.coingecko.com/coins/images/25234/thumb/ReKXsCZUnqSChIZZg4dlCIHQTKU0owxPuvj1feBDWaE.png",
  "HATI": "https://assets.coingecko.com/coins/images/25736/thumb/hati-logo.png",
  "TUN": "https://assets.coingecko.com/coins/images/5801/thumb/QJPjn1uD_400x400.jpg",
  "XPTP": "https://assets.coingecko.com/coins/images/24965/thumb/xptp.png",
  "LOFI": "https://assets.coingecko.com/coins/images/27762/thumb/lofi.jpeg",
  "DOPE": "https://assets.coingecko.com/coins/images/27376/thumb/dopamine_orange_200.png",
  "IOEX": "https://assets.coingecko.com/coins/images/4571/thumb/ioeX-_2019_logo-05.png",
  "MEND": "https://assets.coingecko.com/coins/images/25917/thumb/logo-e1637099222337.png",
  "SBET": "https://assets.coingecko.com/coins/images/25312/thumb/sbet_logo_256.png",
  "ZIBU": "https://assets.coingecko.com/coins/images/28448/thumb/zibu_x200.png",
  "VIST": "https://assets.coingecko.com/coins/images/27005/thumb/Logo1-margin-375.png",
  "KALA": "https://assets.coingecko.com/coins/images/2043/thumb/JBEOkcx8_400x400.jpg",
  "NBLH": "https://assets.coingecko.com/coins/images/27077/thumb/nblh.png",
  "OOZE": "https://assets.coingecko.com/coins/images/27660/thumb/ooze.coin.200.png",
  "PYRK": "https://assets.coingecko.com/coins/images/11183/thumb/Logo-Pyrk.png",
  "VIVA": "https://assets.coingecko.com/coins/images/25293/thumb/EeIoXr_0_400x400.jpg",
  "WDOT": "https://assets.coingecko.com/coins/images/26650/thumb/polkadot.png",
  "FRMX": "https://assets.coingecko.com/coins/images/13082/thumb/frmx.png",
  "PETH": "https://assets.coingecko.com/coins/images/28376/thumb/peth.png",
  "GLEX": "https://assets.coingecko.com/coins/images/11609/thumb/1578901576974.png",
  "BOTZ": "https://assets.coingecko.com/coins/images/27583/thumb/photo_2022-10-06_01-05-39.png",
  "HUDI": "https://assets.coingecko.com/coins/images/18454/thumb/coin_200x200.png",
  "M7V2": "https://assets.coingecko.com/coins/images/25952/thumb/256x256-logo_%281%29.png",
  "XYSL": "https://assets.coingecko.com/coins/images/18954/thumb/xYSL-Icon_200x200.png",
  "$DOSA": "https://assets.coingecko.com/coins/images/27587/thumb/ed15a1c1-e0c5-4b6d-8cc5-54e69e296f96.png",
  "MEAD": "https://assets.coingecko.com/coins/images/20837/thumb/mead.png",
  "XTRM": "https://assets.coingecko.com/coins/images/11078/thumb/xtrm.png",
  "BURP": "https://assets.coingecko.com/coins/images/17317/thumb/Big_Town_Chef_-__BURP_-_Avatar_3x.png",
  "AFP": "https://assets.coingecko.com/coins/images/25910/thumb/Screenshot_from_2022-06-07_15-42-58.png",
  "1ETH": "https://assets.coingecko.com/coins/images/27912/thumb/unnamed.jpg",
  "USDO": "https://assets.coingecko.com/coins/images/28290/thumb/USDO_200.png",
  "ELAN": "https://assets.coingecko.com/coins/images/27635/thumb/coin_logo_512x512.png",
  "EXEN": "https://assets.coingecko.com/coins/images/19036/thumb/Exen_Logo_f1f7d970fd.png",
  "HARU": "https://assets.coingecko.com/coins/images/26786/thumb/haru.png",
  "ACDC": "https://assets.coingecko.com/coins/images/5321/thumb/vivo.png",
  "TORG": "https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png",
  "KFAN": "https://assets.coingecko.com/coins/images/16140/thumb/kpop.PNG",
  "DINA": "https://assets.coingecko.com/coins/images/16126/thumb/200-2.png",
  "VICA": "https://assets.coingecko.com/coins/images/24347/thumb/jg_oCOEM_400x400.jpg",
  "OUSG": "https://assets.coingecko.com/coins/images/29023/thumb/OUSG.png",
  "JACY": "https://assets.coingecko.com/coins/images/19740/thumb/tBwbvjZK_400x400.jpg",
  "BOOP": "https://assets.coingecko.com/coins/images/27918/thumb/boop.png",
  "EGGS": "https://assets.coingecko.com/coins/images/29024/thumb/egg200x200.png",
  "XDAO": "https://assets.coingecko.com/coins/images/27363/thumb/XDAO_Token_img.png",
  "RISU": "https://assets.coingecko.com/coins/images/27229/thumb/logo_%288%29.png",
  "RICE": "https://assets.coingecko.com/coins/images/20975/thumb/roosterbattle.png",
  "AEON": "https://assets.coingecko.com/coins/images/321/thumb/aeon.png",
  "FTG": "https://assets.coingecko.com/coins/images/20154/thumb/IMG-20220728-WA0004.jpg",
  "XANK": "https://assets.coingecko.com/coins/images/11599/thumb/9zAYweVj_400x400.png",
  "REMI": "https://assets.coingecko.com/coins/images/27218/thumb/image_%283%29.png",
  "GROW": "https://assets.coingecko.com/coins/images/15253/thumb/grow.PNG",
  "AEUR": "https://assets.coingecko.com/coins/images/7811/thumb/fpboa2ok_400x400.jpg",
  "BUZZ": "missing_thumb.png",
  "ARTL": "https://assets.coingecko.com/coins/images/25110/thumb/atrl_simbol.png",
  "DTNG": "https://assets.coingecko.com/coins/images/24128/thumb/dtng.png",
  "DAMA": "https://assets.coingecko.com/coins/images/23636/thumb/img-token-dama.png",
  "MESO": "https://assets.coingecko.com/coins/images/18782/thumb/sEYpoSe.png",
  "VENO": "https://assets.coingecko.com/coins/images/28000/thumb/VENO.jpg",
  "ZYRO": "https://assets.coingecko.com/coins/images/12460/thumb/ZgY3lJY19Nljpd5T3SMuNMhyW3eVVufukR5_BMUaOXnofn33hGUIc1PkOPyoCLi-UEtW5ZmotNVqva-qIj2dcRBTFOcas4fmGXrGqsFB22BIHoS7AHap8VsuAw3fu3RrR2Ckx4BWGu1kRF8VH7sot1fcQ4db0K91blRzz2i6drL9ivir9S9iraMl39xgHOATYYA5rWHOIXaS_0c.jpg",
  "AINU": "https://assets.coingecko.com/coins/images/17151/thumb/LOGO-AINU-COIN-GECKO.png",
  "CBET": "https://assets.coingecko.com/coins/images/16975/thumb/token-logo-bg-200.png",
  "4INT": "https://assets.coingecko.com/coins/images/23444/thumb/logo_ff_200x200.jpg",
  "2OMB": "https://assets.coingecko.com/coins/images/22311/thumb/VKF8hjD0_400x400.jpg",
  "CRYN": "https://assets.coingecko.com/coins/images/24601/thumb/cryn.png",
  "HIOD": "https://assets.coingecko.com/coins/images/28238/thumb/hiod.png",
  "XPOP": "https://assets.coingecko.com/coins/images/26251/thumb/%EC%9E%90%EC%82%B0_1.png",
  "TNNS": "https://assets.coingecko.com/coins/images/23926/thumb/output-onlinepngtools.png",
  "VNDC": "https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png",
  "ESK": "https://assets.coingecko.com/coins/images/9642/thumb/ESKA.png",
  "XDAI": "https://assets.coingecko.com/coins/images/11062/thumb/Identity-Primary-DarkBG.png",
  "JOJO": "https://assets.coingecko.com/coins/images/27369/thumb/jojo.png",
  "NOKU": "https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png",
  "NBOX": "https://assets.coingecko.com/coins/images/26395/thumb/Nbox_Coin_Blue.png",
  "GEMX": "https://assets.coingecko.com/coins/images/28437/thumb/525BA403-4DD8-4AB4-A3C1-0EFF8C8F3450.jpeg",
  "BUSY": "https://assets.coingecko.com/coins/images/14966/thumb/busy.PNG",
  "XAV": "https://assets.coingecko.com/coins/images/28299/thumb/Xave_Logomark_%28Color%29_%281%29.png",
  "EDGE": "https://assets.coingecko.com/coins/images/1848/thumb/EDGE.png",
  "WETH": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png",
  "GDRT": "https://assets.coingecko.com/coins/images/26287/thumb/YouTube_Profile_Image.png",
  "KRED": "https://assets.coingecko.com/coins/images/22935/thumb/kred_logo_200.png",
  "INKZ": "https://assets.coingecko.com/coins/images/21860/thumb/INKZ_TOKEN.png",
  "GPX": "https://assets.coingecko.com/coins/images/22584/thumb/GPEX-E_200.png",
  "DIMO": "https://assets.coingecko.com/coins/images/28383/thumb/Token_Logo.png",
  "1ECO": "https://assets.coingecko.com/coins/images/22118/thumb/mgrOT_dx_400x400.png",
  "VEAX": "https://assets.coingecko.com/coins/images/28387/thumb/veax.png",
  "KOJI": "https://assets.coingecko.com/coins/images/16124/thumb/koji-token-200.png",
  "PFTM": "https://assets.coingecko.com/coins/images/23501/thumb/logo_pftm.png",
  "ZOGI": "https://assets.coingecko.com/coins/images/28446/thumb/ZOGI_Token_Logo.png",
  "$IDOL": "https://assets.coingecko.com/coins/images/22458/thumb/2cUUypxU_400x400.png",
  "ELYA": "https://assets.coingecko.com/coins/images/4459/thumb/elyatel-logo.png",
  "POMI": "https://assets.coingecko.com/coins/images/22428/thumb/pomilogo.png",
  "MAIA": "https://assets.coingecko.com/coins/images/22502/thumb/whiteicon.4a79cf8b.png",
  "LUMI": "https://assets.coingecko.com/coins/images/19809/thumb/Untitled-design-removebg-preview_%281%29.png",
  "IBTC": "https://assets.coingecko.com/coins/images/10873/thumb/ibtc.png",
  "MOV": "https://assets.coingecko.com/coins/images/11869/thumb/jvftnBL-_400x400.jpg",
  "ARIX": "https://assets.coingecko.com/coins/images/13485/thumb/ARIX.png",
  "PAYB": "https://assets.coingecko.com/coins/images/25805/thumb/logo_payb_200_x_200.png",
  "VECO": "https://assets.coingecko.com/coins/images/7423/thumb/Veco200.png",
  "DJED": "https://assets.coingecko.com/coins/images/28650/thumb/256_Djed__No_Background_%284%29_%283%29.png",
  "XBT": "https://assets.coingecko.com/coins/images/18155/thumb/Artboard-1-1-150x150.png",
  "ABEY": "https://assets.coingecko.com/coins/images/22146/thumb/16690.png",
  "TAHU": "https://assets.coingecko.com/coins/images/18284/thumb/TAHU_LOGO_trans_200x200.png",
  "NODY": "https://assets.coingecko.com/coins/images/26161/thumb/200x.png",
  "PUFF": "https://assets.coingecko.com/coins/images/22049/thumb/logo_%281%29.png",
  "FREN": "https://assets.coingecko.com/coins/images/28113/thumb/200.png",
  "ZADA": "https://assets.coingecko.com/coins/images/20602/thumb/Screenshot-2021-11-18-at-22-55-25.png",
  "SHON": "https://assets.coingecko.com/coins/images/17135/thumb/200x200_LOGO.png",
  "EBSP": "https://assets.coingecko.com/coins/images/7650/thumb/mwlDkYEKIpcWAP00hfCU7KPQti7jgbew1OKea9RGlGwpdkwOTWp7shOWG5EAsXpUonAo8ZnQoVYoWIz0Tju71QcYMesj8-4Qz_CAyxUy12MTknHGjwfpITWwDaRtHXeTDCCGMvPRPR5ebXultUTIQAtB1x9gtvA03QAi5RVg4GkfX6kB_r04Qx6w2lxuLuyUAqd4x6zaQo_1Hy8NV333I.jpg",
  "OPPA": "https://assets.coingecko.com/coins/images/23431/thumb/237009713_104032035322792_2289129852400869182_n.jpg",
  "ASIX": "https://assets.coingecko.com/coins/images/23282/thumb/asix.png",
  "LOOP": "https://assets.coingecko.com/coins/images/24259/thumb/loop.png",
  "EMBR": "https://assets.coingecko.com/coins/images/20904/thumb/embr.png",
  "OUSE": "https://assets.coingecko.com/coins/images/22276/thumb/ouse-token.png",
  "BORK": "https://assets.coingecko.com/coins/images/23438/thumb/dXZsosv.png",
  "GOIN": "https://assets.coingecko.com/coins/images/21773/thumb/GOinfluencer-20.png",
  "KODI": "https://assets.coingecko.com/coins/images/18794/thumb/ahAWxPp.png",
  "DOMI": "https://assets.coingecko.com/coins/images/21633/thumb/Transparent_Circle_Logo_2.png",
  "EFUN": "https://assets.coingecko.com/coins/images/21882/thumb/6GyaKgva_400x400.jpg",
  "ZION": "https://assets.coingecko.com/coins/images/25902/thumb/logo.png",
  "CT": "https://assets.coingecko.com/coins/images/27760/thumb/logo.967f1c75.png",
  "CBBN": "https://assets.coingecko.com/coins/images/18786/thumb/logo-White.png",
  "DIVS": "https://assets.coingecko.com/coins/images/18980/thumb/divs.PNG",
  "1BOX": "https://assets.coingecko.com/coins/images/20204/thumb/Coin-Icon.png",
  "TAKO": "https://assets.coingecko.com/coins/images/16522/thumb/logo192.png",
  "PAGE": "https://assets.coingecko.com/coins/images/19015/thumb/Page_Icon_LG.png",
  "NANA": "https://assets.coingecko.com/coins/images/22407/thumb/200x200.png",
  "PRYZ": "https://assets.coingecko.com/coins/images/15361/thumb/pryz.png",
  "AMMF": "https://assets.coingecko.com/coins/images/28015/thumb/CRYTOLOGO%282%29.png",
  "USNOTA": "https://assets.coingecko.com/coins/images/21459/thumb/nota_200x200.png",
  "AMLP": "https://assets.coingecko.com/coins/images/28014/thumb/CRYTOLOGO%281%29.png",
  "ARTM": "https://assets.coingecko.com/coins/images/21577/thumb/artm_token_logo.png",
  "DALI": "https://assets.coingecko.com/coins/images/20245/thumb/dali.png",
  "XC": "https://assets.coingecko.com/coins/images/16512/thumb/xc.png",
  "SCROH": "https://assets.coingecko.com/coins/images/26882/thumb/SCRO.png",
  "ABBC": "https://assets.coingecko.com/coins/images/3313/thumb/abbc.png",
  "KIRA": "https://assets.coingecko.com/coins/images/28196/thumb/KIRA_CMC.png",
  "PGOV": "https://assets.coingecko.com/coins/images/16437/thumb/pgov.png",
  "NEMO": "https://assets.coingecko.com/coins/images/27473/thumb/NEMO_logo.png",
  "X3TA": "https://assets.coingecko.com/coins/images/26926/thumb/XETA-icon.png",
  "SOWL": "https://assets.coingecko.com/coins/images/27917/thumb/F4494FF2-2914-49A7-8AE1-49056B82D837.png",
  "LYFE": "https://assets.coingecko.com/coins/images/28608/thumb/lyfe-32.png",
  "IUSD": "https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png",
  "POFI": "https://assets.coingecko.com/coins/images/14734/thumb/POLLO200_200.png",
  "ARCC": "https://assets.coingecko.com/coins/images/26831/thumb/arcc.png",
  "1BCH": "https://assets.coingecko.com/coins/images/23460/thumb/1bch.png",
  "XFIT": "https://assets.coingecko.com/coins/images/14904/thumb/xfit_logo.png",
  "FOIN": "https://assets.coingecko.com/coins/images/4844/thumb/foincoin-logo.png",
  "WELD": "https://assets.coingecko.com/coins/images/18544/thumb/weld.png",
  "CMKR": "https://assets.coingecko.com/coins/images/17528/thumb/cmkr.PNG",
  "LEEK": "https://assets.coingecko.com/coins/images/16418/thumb/logo1_%284%29.png",
  "NEXA": "https://assets.coingecko.com/coins/images/28729/thumb/200x200.png",
  "BAMI": "https://assets.coingecko.com/coins/images/24425/thumb/token_bami_200x200.png",
  "CHUG": "https://assets.coingecko.com/coins/images/22474/thumb/chug.png",
  "AMIS": "https://assets.coingecko.com/coins/images/8445/thumb/amis-newlogo.png",
  "UTIP": "https://assets.coingecko.com/coins/images/9336/thumb/nfEhFtLp_400x400.jpg",
  "CHIP": "https://assets.coingecko.com/coins/images/29041/thumb/Ask_Chip.png",
  "MUNI": "https://assets.coingecko.com/coins/images/28166/thumb/I03PYZqO_400x400-removebg-preview.png",
  "MM72": "https://assets.coingecko.com/coins/images/25564/thumb/mm72.png",
  "WEYU": "https://assets.coingecko.com/coins/images/18112/thumb/weyu.PNG",
  "LION": "https://assets.coingecko.com/coins/images/24950/thumb/lion.png",
  "KEYS": "https://assets.coingecko.com/coins/images/20604/thumb/200x200_%2843%29.png",
  "SOBA": "https://assets.coingecko.com/coins/images/14673/thumb/soba-logo-black.png",
  "VIDY": "https://assets.coingecko.com/coins/images/5088/thumb/vidy-logo.jpg",
  "NEKO": "https://assets.coingecko.com/coins/images/15643/thumb/nekotoken.png",
  "LUXO": "https://assets.coingecko.com/coins/images/25545/thumb/ik7UTHJR_400x400.jpg",
  "ONYX": "https://assets.coingecko.com/coins/images/20546/thumb/gg320-FXI-400x400.jpg",
  "APIX": "https://assets.coingecko.com/coins/images/10124/thumb/5sSKmtlA_400x400.png",
  "VOYRME": "https://assets.coingecko.com/coins/images/16214/thumb/VOYR_Icon_Red300dpi-200x200.png",
  "LUCA": "https://assets.coingecko.com/coins/images/21278/thumb/luca.PNG",
  "DAWG": "https://assets.coingecko.com/coins/images/20881/thumb/143299698-37b637ea-7fce-4bd6-8713-71c42e37629e.png",
  "SOVI": "https://assets.coingecko.com/coins/images/14157/thumb/logo_Sovi_Final_200pxx200px.png",
  "YUGI": "https://assets.coingecko.com/coins/images/21741/thumb/yugi.jpg",
  "ROND": "https://assets.coingecko.com/coins/images/27601/thumb/rond.png",
  "GARY": "https://assets.coingecko.com/coins/images/26448/thumb/gary_logo_icon.png",
  "GNFT": "https://assets.coingecko.com/coins/images/23532/thumb/gnft_200x200.png",
  "VXXL": "https://assets.coingecko.com/coins/images/27165/thumb/IMG_20220901_170554_360.jpg",
  "AGPC": "https://assets.coingecko.com/coins/images/17772/thumb/AGPC.jpg",
  "TRYC": "https://assets.coingecko.com/coins/images/21985/thumb/16357.png",
  "USHI": "https://assets.coingecko.com/coins/images/27865/thumb/photo_2022-10-17_22-21-01.jpg",
  "XEUS": "https://assets.coingecko.com/coins/images/19917/thumb/xeus.bd3217c8.png",
  "ASTA": "https://assets.coingecko.com/coins/images/11123/thumb/asta_logo.png",
  "PICK": "https://assets.coingecko.com/coins/images/12243/thumb/nZlFKAmk_400x400.jpg",
  "YOGO": "https://assets.coingecko.com/coins/images/25485/thumb/LOGO.png",
  "NDAU": "https://assets.coingecko.com/coins/images/9620/thumb/sLTq8JxE_400x400.jpg",
  "OKSE": "https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png",
  "BOLT": "https://assets.coingecko.com/coins/images/4489/thumb/bolt.png",
  "MARX": "https://assets.coingecko.com/coins/images/6168/thumb/MarxCoin.png",
  "KIWI": "https://assets.coingecko.com/coins/images/28986/thumb/kiwi.png",
  "NEON": "https://assets.coingecko.com/coins/images/28331/thumb/neon_%281%29.png",
  "LIF3": "https://assets.coingecko.com/coins/images/26007/thumb/LIF3.png",
  "LCMS": "https://assets.coingecko.com/coins/images/17324/thumb/lcms.jpeg",
  "DAO1": "https://assets.coingecko.com/coins/images/16193/thumb/dao1.PNG",
  "FONE": "https://assets.coingecko.com/coins/images/23390/thumb/Fl-zRI0g_400x400.jpg",
  "BNQ": "https://assets.coingecko.com/coins/images/28642/thumb/BNQ_logo_max_width_transparent_bg.png",
  "ZEOS": "https://assets.coingecko.com/coins/images/22245/thumb/lightgrey.png",
  "DAIN": "https://assets.coingecko.com/coins/images/14811/thumb/%C2%B4%C3%99%C3%80%C3%8E%C2%B7%C3%8E%C2%B0%C3%AD.png",
  "RUSD": "https://assets.coingecko.com/coins/images/26144/thumb/rusd-logo.png",
  "GMB": "https://assets.coingecko.com/coins/images/4497/thumb/gmb.png",
  "VSQ": "https://assets.coingecko.com/coins/images/22164/thumb/vesq.png",
  "GNAR": "https://assets.coingecko.com/coins/images/21534/thumb/hL60Xh2.png",
  "MAIN": "https://assets.coingecko.com/coins/images/25258/thumb/logo_circle.png",
  "LYNX": "https://assets.coingecko.com/coins/images/5351/thumb/LYNX_3COLOR_256square.png",
  "XLS": "https://assets.coingecko.com/coins/images/13613/thumb/Elis-Symbol-Green.png",
  "GLOW": "https://assets.coingecko.com/coins/images/29003/thumb/output-onlinepngtools.png",
  "PEOS": "https://assets.coingecko.com/coins/images/8366/thumb/AHBvYJJV_400x400.png",
  "MIDA": "https://assets.coingecko.com/coins/images/27337/thumb/_MIDA_dark.png",
  "YEON": "https://assets.coingecko.com/coins/images/27874/thumb/download_%283%29.jpeg",
  "BITZ": "https://assets.coingecko.com/coins/images/249/thumb/bitz.png",
  "DUDE": "https://assets.coingecko.com/coins/images/25924/thumb/dude-logo-png.jpg",
  "S4F": "https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png",
  "BOSS": "https://assets.coingecko.com/coins/images/22032/thumb/Token_%283%29.png",
  "FINS": "https://assets.coingecko.com/coins/images/18568/thumb/fins_200x200.png",
  "HONO": "https://assets.coingecko.com/coins/images/21870/thumb/hono.png",
  "ERON": "https://assets.coingecko.com/coins/images/21746/thumb/EPA_logo_2.png",
  "DEXO": "https://assets.coingecko.com/coins/images/28506/thumb/DEXO.png",
  "FANG": "https://assets.coingecko.com/coins/images/18982/thumb/-ywrkEbd.png",
  "DOGZ": "https://assets.coingecko.com/coins/images/7544/thumb/dogz.png",
  "HOMT": "https://assets.coingecko.com/coins/images/11597/thumb/HOM-Token-Logo.jpg",
  "COIL": "https://assets.coingecko.com/coins/images/27777/thumb/R1JruDU3_400x400.jpg",
  "IRON": "https://assets.coingecko.com/coins/images/16409/thumb/qBa5_yCL_400x400.jpg",
  "ONUS": "https://assets.coingecko.com/coins/images/24599/thumb/ONUS_200.png",
  "BOFB": "https://assets.coingecko.com/coins/images/24866/thumb/2qsZgpxd_400x400.jpg",
  "PANA": "https://assets.coingecko.com/coins/images/27031/thumb/pana_logo.png",
  "VRS": "https://assets.coingecko.com/coins/images/26110/thumb/17-56-48-vrs.png",
  "TAIL": "https://assets.coingecko.com/coins/images/28260/thumb/IMG_20221210_122215_723.jpg",
  "HILO": "https://assets.coingecko.com/coins/images/28661/thumb/cmchiloo.png",
  "ICZ": "https://assets.coingecko.com/coins/images/28072/thumb/snow-logo.png",
  "INFT": "https://assets.coingecko.com/coins/images/18716/thumb/inft.PNG",
  "POMO": "https://assets.coingecko.com/coins/images/25355/thumb/200x200.png",
  "EDAC": "https://assets.coingecko.com/coins/images/24142/thumb/200x200.png",
  "FUJI": "https://assets.coingecko.com/coins/images/23732/thumb/vopR7PC.png",
  "BHBD": "https://assets.coingecko.com/coins/images/27597/thumb/bHBD_2_200x200.png",
  "MATA": "https://assets.coingecko.com/coins/images/22934/thumb/AvPezpY4TzT4sYmaRRELb7Vyg4GfJNv2CX0nmJehBlBpyNoU9onhbM6-HbcXhz_7yhCjkZlNh8H-gPk5R0IMtfZXyKw00Xc4Zvf_EpvjmcD-Q6liwiladoBHVFpJwj0nf4cdLJm71fs6mW_1wd9P68uabxHOb94gDljk_CmrWG4KHNaEEmITOKoUO39Vc55FML6qYoNiyXYGBo.jpg",
  "IPAY": "https://assets.coingecko.com/coins/images/22148/thumb/i-Pay-Logo.png",
  "DEFY": "https://assets.coingecko.com/coins/images/26877/thumb/8ybr83fb0ca45cm1yvrcaclwbvcp.jpeg",
  "RUYI": "https://assets.coingecko.com/coins/images/22855/thumb/logo200.png",
  "WOOL": "https://assets.coingecko.com/coins/images/20760/thumb/KM3RwIVx_400x400.jpg",
  "DAMM": "https://assets.coingecko.com/coins/images/28082/thumb/dAMM_logo_round_-_dark.png",
  "FANC": "https://assets.coingecko.com/coins/images/26500/thumb/hJ6MD49P_400x400.jpeg",
  "CTEZ": "https://assets.coingecko.com/coins/images/29043/thumb/Qme4ybadbY4H84h5WLPjdo47YQUxxVoJHWZrwYq2JZriM4_%281%29.png",
  "WYND": "https://assets.coingecko.com/coins/images/28457/thumb/wynd.jpeg",
  "SPOT": "https://assets.coingecko.com/coins/images/28426/thumb/SPOT_Logo_200x200_sq_small_centered.png",
  "NEEO": "https://assets.coingecko.com/coins/images/26060/thumb/ZbD8a9pz_400x400.jpg",
  "KOTO": "https://assets.coingecko.com/coins/images/6383/thumb/koto-coin.jpg",
  "ZUNA": "https://assets.coingecko.com/coins/images/19997/thumb/ZUNA_logo_for_CMC.png",
  "XCRX": "https://assets.coingecko.com/coins/images/28034/thumb/xCRX_%28200_%C3%97_200_px%29.png",
  "R34P": "https://assets.coingecko.com/coins/images/13393/thumb/r34p_logo.png",
  "KIRO": "https://assets.coingecko.com/coins/images/12688/thumb/logo_kirobo-04.png",
  "LUXY": "https://assets.coingecko.com/coins/images/21153/thumb/QmbYvptvVWHjAHR3tm2UpsXABcp4sQGzhCKMmc5pgn411R.png",
  "BOID": "https://assets.coingecko.com/coins/images/6664/thumb/boidlogo.png",
  "EV": "https://assets.coingecko.com/coins/images/25313/thumb/EVAI_LOGO.png",
  "SHEN": "https://assets.coingecko.com/coins/images/28651/thumb/256_Shen_No_Background_%288%29_%281%29.png",
  "GR": "https://assets.coingecko.com/coins/images/13216/thumb/gr.png",
  "YAKU": "https://assets.coingecko.com/coins/images/26785/thumb/_YAKU_logo.png",
  "USDH": "https://assets.coingecko.com/coins/images/22941/thumb/USDH_icon.png",
  "ZAIF": "https://assets.coingecko.com/coins/images/6503/thumb/zaif_token.png",
  "IONZ": "https://assets.coingecko.com/coins/images/26113/thumb/Token-IONZ.png",
  "ZOMI": "https://assets.coingecko.com/coins/images/21505/thumb/zomi-coin-front.png",
  "ODOP": "https://assets.coingecko.com/coins/images/15781/thumb/odop.PNG",
  "3OMB": "https://assets.coingecko.com/coins/images/23078/thumb/96671285.png",
  "GOKU": "https://assets.coingecko.com/coins/images/17947/thumb/goku.png",
  "SIMP": "https://assets.coingecko.com/coins/images/28760/thumb/Frame_427318605.png",
  "NETA": "https://assets.coingecko.com/coins/images/23350/thumb/RUXx0equ_400x400.jpg",
  "MUNA": "https://assets.coingecko.com/coins/images/24115/thumb/logo200x200.png",
  "LNKO": "https://assets.coingecko.com/coins/images/9199/thumb/logo_%289%29.png",
  "INVI": "https://assets.coingecko.com/coins/images/17363/thumb/INVI-Ticker.png",
  "PUSD": "https://assets.coingecko.com/coins/images/16762/thumb/PUSD-purple-200.png",
  "PIAS": "https://assets.coingecko.com/coins/images/28080/thumb/41DF3535-B5AA-44E0-994D-ABC56B3DE2F4.png",
  "WFDP": "https://assets.coingecko.com/coins/images/23758/thumb/wfdp.png",
  "LIUX": "https://assets.coingecko.com/coins/images/28713/thumb/IMG_20230111_221902_239.png",
  "MFET": "https://assets.coingecko.com/coins/images/25555/thumb/200x200.png",
  "ASKO": "https://assets.coingecko.com/coins/images/11773/thumb/ASKO.png",
  "WTN": "https://assets.coingecko.com/coins/images/26333/thumb/Xs5yrJym_400x400.jpeg",
  "$WSOHMD": "https://assets.coingecko.com/coins/images/27561/thumb/olympus-doge.png",
  "FNCY": "https://assets.coingecko.com/coins/images/28380/thumb/FNCY_%28%EC%9E%AC%EC%88%98%EC%A0%95%29.png",
  "YCE": "https://assets.coingecko.com/coins/images/3944/thumb/MYCELOGO_WHITE-FULL.png",
  "XZO": "https://assets.coingecko.com/coins/images/27647/thumb/XZO_Token_Logo_White.png",
  "ANON": "https://assets.coingecko.com/coins/images/15637/thumb/logo-main.png",
  "IOWN": "https://assets.coingecko.com/coins/images/8270/thumb/iOWN-Lion-Face.png",
  "DOJO": "https://assets.coingecko.com/coins/images/28249/thumb/Dojo.jpeg",
  "GAFA": "https://assets.coingecko.com/coins/images/24625/thumb/gafalogot.png",
  "KLEX": "https://assets.coingecko.com/coins/images/27500/thumb/klex.jpeg",
  "ZSOL": "https://assets.coingecko.com/coins/images/29076/thumb/zSOL.png",
  "DONA": "https://assets.coingecko.com/coins/images/18429/thumb/DONA.jpg",
  "DIKE": "https://assets.coingecko.com/coins/images/23109/thumb/TvaMw2vw_400x400.jpg",
  "D2D": "https://assets.coingecko.com/coins/images/21609/thumb/RJD82RrV_400x400.jpg",
  "HLDR": "https://assets.coingecko.com/coins/images/28305/thumb/Holdr_200x200_white.png",
  "TUR": "https://assets.coingecko.com/coins/images/15539/thumb/turex-logo.png",
  "BUKH": "https://assets.coingecko.com/coins/images/18401/thumb/bukh_new.png",
  "CUBIX": "https://assets.coingecko.com/coins/images/28327/thumb/cubix.png",
  "INIT": "https://assets.coingecko.com/coins/images/28367/thumb/init_200x200.png",
  "TRAXX": "https://assets.coingecko.com/coins/images/24441/thumb/TT.png",
  "UPARK": "https://assets.coingecko.com/coins/images/26879/thumb/Untitled_%28200_%C3%97_200_px%29.png",
  "TRISM": "https://assets.coingecko.com/coins/images/13549/thumb/76106366.png",
  "CTZN": "https://assets.coingecko.com/coins/images/21558/thumb/ctzn.png",
  "DXCAD": "https://assets.coingecko.com/coins/images/27995/thumb/logo_%286%29.png",
  "SHELL": "https://assets.coingecko.com/coins/images/26925/thumb/slogo.png",
  "GOBTC": "https://assets.coingecko.com/coins/images/26825/thumb/gobtc.png",
  "CREDS": "https://assets.coingecko.com/coins/images/22122/thumb/creds.png",
  "AROR": "https://assets.coingecko.com/coins/images/28654/thumb/cg_200x200.png",
  "LINKS": "https://assets.coingecko.com/coins/images/21351/thumb/getlinks-coin.png",
  "TLR": "https://assets.coingecko.com/coins/images/4273/thumb/Taler_bw200.png",
  "RYOMA": "https://assets.coingecko.com/coins/images/27047/thumb/OabnykXw_400x400.jpeg",
  "MKS": "https://assets.coingecko.com/coins/images/13318/thumb/MKS200.png",
  "SCRAP": "https://assets.coingecko.com/coins/images/23086/thumb/bd1b1275fdc0ac1.png",
  "PASHA": "https://assets.coingecko.com/coins/images/25689/thumb/f4nm6V_h8HBmNPQgDWoOcn6dwHUUJ5DNgf8W1VkW5h92NG2cjqkTqIp5ADdWMzvDXVR6zH-eLQ3qQxlJGwj4HD8EWOvXL-QByoIyV-51xEDh5i_bNQR5SxNBpsVZh8wsmmwm13GVij5l0ZQT3dBXt7MxFG9mIkff6vdX1Juc1OxqG_8wUcurdouajTqNAzsizP77gCuCH0UJJI-0YAAxawAg6x.jpg",
  "BLUC": "https://assets.coingecko.com/coins/images/24661/thumb/BlucToken.png",
  "ARTK": "https://assets.coingecko.com/coins/images/25230/thumb/logo_200x200.png",
  "FAYRE": "https://assets.coingecko.com/coins/images/24522/thumb/11-Fayre-Secondary-Logo-Purple-Blue.png",
  "FORS": "https://assets.coingecko.com/coins/images/24090/thumb/Foruslogo.png",
  "KEYT": "https://assets.coingecko.com/coins/images/9891/thumb/rebit.PNG",
  "BLANC": "https://assets.coingecko.com/coins/images/21815/thumb/jpz9chVT_400x400.jpg",
  "XODEX": "https://assets.coingecko.com/coins/images/25551/thumb/GVBRRXHM_400x400.jpg",
  "$GREED": "https://assets.coingecko.com/coins/images/19462/thumb/greed.png",
  "AMG": "https://assets.coingecko.com/coins/images/25291/thumb/WorYFccvCfc0SQzHHCSJyVHyE7StfNOVU9NajqXcKv3G7hvinJGlISSFBgjuamai1N6aZTUCuDQD8Lm2Nod2loHKAedAtagu-DNv_r7PlxyiAr8pP6TqPHRwmLMkYeVIMAXjUIZtDwS8B1WKLdM1qYfbZb6_9aMD9WMEh2g58Oa2tac0Y1HPSy_Q1ZGIFXSMntGbeRs2-fqMPLjeFztRR0_%281%29.jpg",
  "ATP": "https://assets.coingecko.com/coins/images/4924/thumb/atp.png",
  "YAW": "https://assets.coingecko.com/coins/images/24233/thumb/yaw.PNG",
  "OKDEX": "https://assets.coingecko.com/coins/images/27485/thumb/ok_dex_preto2.png",
  "SON": "https://assets.coingecko.com/coins/images/24297/thumb/WcOQ_Ees_400x400.jpg",
  "STACY": "https://assets.coingecko.com/coins/images/12986/thumb/Stacy.png",
  "HOSKY": "https://assets.coingecko.com/coins/images/23560/thumb/16755.png",
  "MNX": "https://assets.coingecko.com/coins/images/15756/thumb/4Ojhzg6.png",
  "TUP": "https://assets.coingecko.com/coins/images/6254/thumb/uphIf44J_400x400.jpg",
  "VACAY": "https://assets.coingecko.com/coins/images/15930/thumb/Be9BrmM.png",
  "X7101": "https://assets.coingecko.com/coins/images/28419/thumb/X7101_LOGO_black_back_200_x_200_px.png",
  "MODEX": "https://assets.coingecko.com/coins/images/2851/thumb/LhWIeAg.png",
  "IOETH": "https://assets.coingecko.com/coins/images/19342/thumb/io1qfvgvmk6lpxkpqwlzanqx4atyzs86ryqjnfuad.png",
  "STARX": "https://assets.coingecko.com/coins/images/8417/thumb/starworks.PNG",
  "AICO": "https://assets.coingecko.com/coins/images/12892/thumb/5920.png",
  "ZEROX": "https://assets.coingecko.com/coins/images/27319/thumb/Logo_200.png",
  "YO": "https://assets.coingecko.com/coins/images/19680/thumb/1_-F2p9La1mYuEtYAm-tuSeQ.png",
  "LAIKA": "https://assets.coingecko.com/coins/images/26717/thumb/Laikacmcfinal.png",
  "VDR": "https://assets.coingecko.com/coins/images/19338/thumb/vdr.png",
  "VRYNT": "https://assets.coingecko.com/coins/images/25887/thumb/vrynt.png",
  "STZ": "https://assets.coingecko.com/coins/images/21467/thumb/stz.png",
  "MIDAS": "https://assets.coingecko.com/coins/images/6453/thumb/OMrkHMb.png",
  "LPDT": "https://assets.coingecko.com/coins/images/28185/thumb/01-59-28-logo2.png",
  "GINOA": "https://assets.coingecko.com/coins/images/24033/thumb/gintoken.png",
  "OLOID": "https://assets.coingecko.com/coins/images/25950/thumb/OLOID-COIN-3-200x200.png",
  "QNI": "https://assets.coingecko.com/coins/images/27490/thumb/2022-09-27_00.37.38.jpg",
  "TICKR": "https://assets.coingecko.com/coins/images/28835/thumb/Instagram_post_-_1_copy_2.jpg",
  "BASAN": "https://assets.coingecko.com/coins/images/27988/thumb/BigBasanLogo_%281%29.png",
  "MND": "https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png",
  "CHILI": "https://assets.coingecko.com/coins/images/28668/thumb/CHILI_start.png",
  "DIONE": "https://assets.coingecko.com/coins/images/26931/thumb/dione_200x200.png",
  "WEB69": "https://assets.coingecko.com/coins/images/26928/thumb/web69200x200.png",
  "KRZ": "https://assets.coingecko.com/coins/images/17688/thumb/KRZ-Extra-Small-Logo-200x200-1.png",
  "WE": "https://assets.coingecko.com/coins/images/19239/thumb/wanda.PNG",
  "MONO": "https://assets.coingecko.com/coins/images/21184/thumb/ab05Nt4UN1q5.png",
  "CCOMP": "https://assets.coingecko.com/coins/images/12840/thumb/1_z8UrVtod3bme4-J_pXAQQA_2x.png",
  "LVUSD": "https://assets.coingecko.com/coins/images/28971/thumb/lvusd.png",
  "METAQ": "https://assets.coingecko.com/coins/images/24584/thumb/PoQiwrce8gcDf9KxXDk_fU9WEP7ljoPRJnYounF7pgJi-_Jnt3AFXQqB1lLtKDiezguUYEGvntBGCKMCB22Cecf5AcU9bbFV38Sdihravj6x65pVunbXmrl39hyfqQAUyjum_l5KqJgUcsTtLJTBqdpLiAmcg_igURSkEaef_APdfkYepHZ0tJh8TglKgCzLpAle_F-aNMfmAX4.jpg",
  "PKN": "https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg",
  "SAINT": "https://assets.coingecko.com/coins/images/19724/thumb/saint_inu.png",
  "SKZ": "https://assets.coingecko.com/coins/images/27657/thumb/skz_200_200.png",
  "DUZCE": "https://assets.coingecko.com/coins/images/25979/thumb/20569.png",
  "DREAM": "https://assets.coingecko.com/coins/images/10675/thumb/aHon0cqNuXUTYB2MO4DYmM4fmEMDqIXeXvP38dKRaKspANDniVJbmYUU2tVZlyMBYQ9GMHHBHL-YbkIGQDMwnO1QN1VNia1kh_QB6h9je3XwzCmDLP_HKusR1c7kExITpt4BXoGCnsj19wyjY_NywMLGrB1C0f9LrLJJZxNo_pUJNw7D8Cw4tFKUtdVe8B7_pgpb8ngd8aAKi_H.jpg",
  "XNV": "https://assets.coingecko.com/coins/images/5048/thumb/nerva-logo-300x300.png",
  "YVDAI": "https://assets.coingecko.com/coins/images/28743/thumb/yvdai.png",
  "SRUNE": "https://assets.coingecko.com/coins/images/14953/thumb/sRUNE.png",
  "HRD": "https://assets.coingecko.com/coins/images/27300/thumb/hoard.png",
  "SWIRL": "https://assets.coingecko.com/coins/images/22575/thumb/newLogo.png",
  "VIDYX": "https://assets.coingecko.com/coins/images/13558/thumb/vidyx.png",
  "HOUND": "https://assets.coingecko.com/coins/images/27402/thumb/1_0yRP_AV0QksHuhLKLEXtAw.jpeg",
  "VENOM": "https://assets.coingecko.com/coins/images/28660/thumb/Venom_Icon.png",
  "MANNA": "https://assets.coingecko.com/coins/images/5602/thumb/manna.png",
  "AVN": "https://assets.coingecko.com/coins/images/14819/thumb/avn.png",
  "BEPIS": "https://assets.coingecko.com/coins/images/22357/thumb/bepis_200x200.png",
  "IOUNI": "https://assets.coingecko.com/coins/images/19343/thumb/io1ahh04jn2zkqlug6feh7rpq75a75p3rj42hp4ch.png",
  "CHPZ": "https://assets.coingecko.com/coins/images/17598/thumb/chipz-logo-22.png",
  "TGR": "https://assets.coingecko.com/coins/images/26564/thumb/512x512.png",
  "PGALA": "https://assets.coingecko.com/coins/images/28119/thumb/pgala.png",
  "SAFUU": "https://assets.coingecko.com/coins/images/24290/thumb/86tZ8IKj_400x400.jpg",
  "CMS": "https://assets.coingecko.com/coins/images/18730/thumb/UEYC0ty.png",
  "SHUMI": "https://assets.coingecko.com/coins/images/28095/thumb/144CE478-72EA-41C1-9002-6A83400344E6.jpeg",
  "PAVIA": "https://assets.coingecko.com/coins/images/24760/thumb/RI-Pgckr_400x400.jpg",
  "DEFLY": "https://assets.coingecko.com/coins/images/25281/thumb/OJ2HPSh-_400x400.png",
  "EHIVE": "https://assets.coingecko.com/coins/images/27239/thumb/ehive_200x200.png",
  "CLT": "https://assets.coingecko.com/coins/images/1713/thumb/blue-logo-transparent-200x200.png",
  "DAOVC": "https://assets.coingecko.com/coins/images/18259/thumb/daovc.PNG",
  "ECU": "https://assets.coingecko.com/coins/images/10988/thumb/ECU-LOGO-200x200.png",
  "YSR": "https://assets.coingecko.com/coins/images/11575/thumb/256_256.png",
  "HBARX": "https://assets.coingecko.com/coins/images/28590/thumb/hbarx.png",
  "IFARM": "https://assets.coingecko.com/coins/images/14472/thumb/ifarm.png",
  "SHIDO": "https://assets.coingecko.com/coins/images/26674/thumb/Shido.png",
  "OMEGA": "https://assets.coingecko.com/coins/images/3574/thumb/OMEGA_cir.png",
  "AZY": "https://assets.coingecko.com/coins/images/26315/thumb/photo_2022-07-07_12-38-02.jpg",
  "SOLUM": "https://assets.coingecko.com/coins/images/18945/thumb/logo_-_2021-10-12T103445.624.png",
  "MLOKY": "https://assets.coingecko.com/coins/images/24602/thumb/mloky.png",
  "BDC": "https://assets.coingecko.com/coins/images/17405/thumb/eWt6m4W.png",
  "PRMX": "https://assets.coingecko.com/coins/images/27600/thumb/prmx.png",
  "POOCH": "https://assets.coingecko.com/coins/images/23644/thumb/pooch_logo_200px.png",
  "ARATA": "https://assets.coingecko.com/coins/images/18617/thumb/_VHQIycq_400x400.jpg",
  "MAGIK": "https://assets.coingecko.com/coins/images/23399/thumb/magik.png",
  "RAM": "https://assets.coingecko.com/coins/images/14602/thumb/76572462.png",
  "PAYGO": "https://assets.coingecko.com/coins/images/24972/thumb/271869870-650496619328235-2114002614573935348-n-3.png",
  "PUBLX": "https://assets.coingecko.com/coins/images/29074/thumb/PUBLX_Logo_%28200x200%29.png",
  "PIX": "https://assets.coingecko.com/coins/images/27426/thumb/PixieCoin.png",
  "XMARK": "https://assets.coingecko.com/coins/images/14188/thumb/omPQ9-nF_400x400.jpg",
  "HRS": "https://assets.coingecko.com/coins/images/24082/thumb/EkAIccL.jpg",
  "PAE": "https://assets.coingecko.com/coins/images/23420/thumb/logo192_%282%29.png",
  "MIMIR": "https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg",
  "CZUSD": "https://assets.coingecko.com/coins/images/24048/thumb/czusd-200.png",
  "WOLFY": "https://assets.coingecko.com/coins/images/15742/thumb/Wofly.png",
  "ENRX": "https://assets.coingecko.com/coins/images/24952/thumb/enrx.png",
  "CDEX": "https://assets.coingecko.com/coins/images/10568/thumb/cdex.png",
  "AMON": "https://assets.coingecko.com/coins/images/8952/thumb/AmonD_512x512.png",
  "SEEDS": "https://assets.coingecko.com/coins/images/10488/thumb/Seeds_Icon_190626_%281%29_opt.png",
  "DOGGY": "https://assets.coingecko.com/coins/images/15826/thumb/doggy.png",
  "X7103": "https://assets.coingecko.com/coins/images/28421/thumb/X7103_LOGO_black_back_200_x_200_px.png",
  "DOGGO": "https://assets.coingecko.com/coins/images/28740/thumb/pllp.jpeg",
  "VISIO": "https://assets.coingecko.com/coins/images/657/thumb/visio.png",
  "RLOOP": "https://assets.coingecko.com/coins/images/4698/thumb/12903040.png",
  "IMAYC": "https://assets.coingecko.com/coins/images/28949/thumb/imayc.png",
  "CHIV": "https://assets.coingecko.com/coins/images/19432/thumb/chivatoken-ico.png",
  "KRILL": "https://assets.coingecko.com/coins/images/15016/thumb/2.png",
  "ARW": "https://assets.coingecko.com/coins/images/17114/thumb/ARW_logo_final.jpg",
  "CARAT": "https://assets.coingecko.com/coins/images/6434/thumb/carat.png",
  "JELLY": "https://assets.coingecko.com/coins/images/28572/thumb/jellylogo.png",
  "KAERI": "https://assets.coingecko.com/coins/images/27977/thumb/63370e2a1f2bea31fc7f6977_512x512_v2-p-500-2.png",
  "HIP": "https://assets.coingecko.com/coins/images/17386/thumb/hip.PNG",
  "X1": "https://assets.coingecko.com/coins/images/27411/thumb/pNi9ej83_400x400.png",
  "YUMMY": "https://assets.coingecko.com/coins/images/15589/thumb/eFjXvUxN_400x400.jpg",
  "TAILS": "https://assets.coingecko.com/coins/images/19065/thumb/Tails_LOGO.png",
  "JIYUU": "https://assets.coingecko.com/coins/images/28907/thumb/logo_200.png",
  "SHAZU": "https://assets.coingecko.com/coins/images/25819/thumb/Shazu200px.png",
  "TXBIT": "https://assets.coingecko.com/coins/images/21122/thumb/Ft4mjdHu_400x400.jpg",
  "WAGIE": "https://assets.coingecko.com/coins/images/28428/thumb/Screen_Shot_2022-08-12_at_12.56.08.png",
  "NABOB": "https://assets.coingecko.com/coins/images/25961/thumb/20544.png",
  "FAIRY": "https://assets.coingecko.com/coins/images/25399/thumb/200.png",
  "ORBC": "https://assets.coingecko.com/coins/images/25400/thumb/18628.png",
  "YTOFU": "https://assets.coingecko.com/coins/images/17918/thumb/IEpQGhf.png",
  "AFREP": "https://assets.coingecko.com/coins/images/26407/thumb/Afrep._1_%281%29.png",
  "JSOL": "https://assets.coingecko.com/coins/images/20664/thumb/jsol.png",
  "SAFLE": "https://assets.coingecko.com/coins/images/21674/thumb/56835021.png",
  "DGM": "https://assets.coingecko.com/coins/images/3749/thumb/Webp.net-resizeimage_%284%29.png",
  "TTI": "https://assets.coingecko.com/coins/images/22540/thumb/dOMv0Rg2_400x400.jpg",
  "OBROK": "https://assets.coingecko.com/coins/images/22078/thumb/logo_-_2021-12-29T130335.005.png",
  "JUMPX": "https://assets.coingecko.com/coins/images/27452/thumb/200photo_2022-09-15_12-14-58.jpg",
  "KAU": "https://assets.coingecko.com/coins/images/17802/thumb/200%C3%97200-logo.png",
  "AELIN": "https://assets.coingecko.com/coins/images/22303/thumb/aelien.jpg",
  "NIROS": "https://assets.coingecko.com/coins/images/22409/thumb/oJEphc3R_400x400.jpg",
  "BND": "https://assets.coingecko.com/coins/images/26369/thumb/BENED-TICKER-IMG.png",
  "ALLUO": "https://assets.coingecko.com/coins/images/21484/thumb/logo-sm-wb.png",
  "SEELE": "https://assets.coingecko.com/coins/images/3294/thumb/Seele.png",
  "MUNCH": "https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png",
  "PIGGY": "https://assets.coingecko.com/coins/images/22076/thumb/crypto-tomb-cash-3f0e3fdc.png",
  "1DOGE": "https://assets.coingecko.com/coins/images/17768/thumb/1DogeTransparent_png_%281%29.png",
  "OMNIS": "https://assets.coingecko.com/coins/images/17745/thumb/footer_logo_%282%29.png",
  "HORGI": "https://assets.coingecko.com/coins/images/24615/thumb/horgi.png",
  "MNZ": "https://assets.coingecko.com/coins/images/26291/thumb/Menzy-logo.jpeg",
  "FLETA": "https://assets.coingecko.com/coins/images/7865/thumb/Fleta_new_logo_%282%29.png",
  "MYOBU": "https://assets.coingecko.com/coins/images/16122/thumb/myobu.png",
  "XDEN": "https://assets.coingecko.com/coins/images/25137/thumb/19326.png",
  "AZ": "https://assets.coingecko.com/coins/images/4504/thumb/azbit200x200.png",
  "ETHAX": "https://assets.coingecko.com/coins/images/25571/thumb/ethax.png",
  "UNIQO": "https://assets.coingecko.com/coins/images/28271/thumb/logo_coingecko.png",
  "$INR": "https://assets.coingecko.com/coins/images/26215/thumb/R9hQxqQK_400x400.jpg",
  "$FUDGE": "https://assets.coingecko.com/coins/images/26908/thumb/Sundae_finance_fudge_.png",
  "TBS": "https://assets.coingecko.com/coins/images/26376/thumb/input-onlinepngtools.png",
  "AIPRO": "https://assets.coingecko.com/coins/images/28217/thumb/AIPro_logo_png.png",
  "CROM": "https://assets.coingecko.com/coins/images/26212/thumb/photo_2022-06-30_16-39-15.jpg",
  "DKD": "https://assets.coingecko.com/coins/images/27112/thumb/dokdo-dkd-logo.png",
  "ULAND": "https://assets.coingecko.com/coins/images/22040/thumb/logo_globe_200.png",
  "SIMBA": "https://assets.coingecko.com/coins/images/16060/thumb/200x200_%2819%29.png",
  "TEMCO": "https://assets.coingecko.com/coins/images/6210/thumb/bSZ7HUuS_400x400.jpg",
  "XVC": "https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg",
  "CELEB": "https://assets.coingecko.com/coins/images/16402/thumb/celeb.PNG",
  "ZCR": "https://assets.coingecko.com/coins/images/4050/thumb/zcore300.png",
  "NEXM": "https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png",
  "XHP": "https://assets.coingecko.com/coins/images/27740/thumb/emago_coin.png",
  "XCDOT": "https://assets.coingecko.com/coins/images/26044/thumb/polkadot-asset_32.png",
  "PRXY": "https://assets.coingecko.com/coins/images/17442/thumb/Proxy_logo_200.png",
  "TGNB": "https://assets.coingecko.com/coins/images/22406/thumb/250.png",
  "STATS": "https://assets.coingecko.com/coins/images/28853/thumb/stats.png",
  "MCELO": "https://assets.coingecko.com/coins/images/18476/thumb/mcelo.png",
  "LAEEB": "https://assets.coingecko.com/coins/images/27732/thumb/download.png",
  "VETME": "https://assets.coingecko.com/coins/images/28787/thumb/2.png",
  "CBK": "https://assets.coingecko.com/coins/images/13459/thumb/cbk-128-128.png",
  "VGO": "https://assets.coingecko.com/coins/images/21229/thumb/14073.png",
  "DRF": "https://assets.coingecko.com/coins/images/24757/thumb/9VyY40eb_400x400.jpg",
  "GRVE": "https://assets.coingecko.com/coins/images/24088/thumb/monetone_solo.png",
  "KAPPA": "https://assets.coingecko.com/coins/images/18643/thumb/Wp3ALed.png",
  "GRAIN": "https://assets.coingecko.com/coins/images/13331/thumb/784594063499853904.png",
  "GRIMM": "https://assets.coingecko.com/coins/images/10002/thumb/logo200.png",
  "XWAP": "https://assets.coingecko.com/coins/images/21851/thumb/Logo-Twitter-2.png",
  "PXT": "https://assets.coingecko.com/coins/images/6207/thumb/populous.jpg",
  "RUP": "https://assets.coingecko.com/coins/images/801/thumb/rupee.png",
  "DT": "https://assets.coingecko.com/coins/images/8831/thumb/Dcoin_Token.png",
  "IAG": "https://assets.coingecko.com/coins/images/3264/thumb/d8c5hLbX9u0GwYCKcZRbXS2vAQ0Vd-Hfjg-3zQ73ucSZQoFYsLH4NEKN8EQkwwBVR8OPJgrTRG-_dW_XVHL058ezYSvwsSB4bjYtHH7xjZNHBaAaX1NZl7axG8zm2FIRV6AUmgdmxcbP0BcuWvUJkcUKrYYEDf0Msx2_3arxgmS1V85YMb_1SVbWt6E3QnkpvLcGyC0SxN6rGTr.jpg",
  "FRETH": "https://assets.coingecko.com/coins/images/28734/thumb/freth.png",
  "0XPAD": "https://assets.coingecko.com/coins/images/22537/thumb/16846.png",
  "FAS": "https://assets.coingecko.com/coins/images/26469/thumb/logo_200X200.png",
  "SWACE": "https://assets.coingecko.com/coins/images/3727/thumb/swace-symbol.png",
  "EHASH": "https://assets.coingecko.com/coins/images/14180/thumb/ehash.png",
  "XBN": "https://assets.coingecko.com/coins/images/14731/thumb/XBN-GRADIANT-PNG.855c9a1f.png",
  "SOLGE": "https://assets.coingecko.com/coins/images/28678/thumb/solge_%281%29.png",
  "SHIWA": "https://assets.coingecko.com/coins/images/28070/thumb/shiwa200.png",
  "TOPSS": "https://assets.coingecko.com/coins/images/27508/thumb/Topss_logo_final_logo_light.png",
  "TZBTC": "https://assets.coingecko.com/coins/images/19512/thumb/tzbtc.png",
  "DAF": "https://assets.coingecko.com/coins/images/17378/thumb/dafin.PNG",
  "FRONK": "https://assets.coingecko.com/coins/images/28679/thumb/_7XVXQ46_400x400.jpg",
  "PUMLX": "https://assets.coingecko.com/coins/images/27253/thumb/pumlx.png",
  "APPLE": "https://assets.coingecko.com/coins/images/20116/thumb/9pfJYwQk_400x400.jpg",
  "ETH3S": "https://assets.coingecko.com/coins/images/10128/thumb/eth3s.PNG",
  "X7DAO": "https://assets.coingecko.com/coins/images/27709/thumb/X7DAO.png",
  "SIKKA": "https://assets.coingecko.com/coins/images/27201/thumb/SIKKA_logo_cmc.png",
  "ICSA": "https://assets.coingecko.com/coins/images/27708/thumb/icsa.e2b79cbc.png",
  "OX2": "https://assets.coingecko.com/coins/images/27591/thumb/oxyo2_logo_200x200.png",
  "L3USD": "https://assets.coingecko.com/coins/images/26937/thumb/L3USD.png",
  "SHARK": "https://assets.coingecko.com/coins/images/15398/thumb/2KY2RdEo_400x400.png",
  "STEEL": "https://assets.coingecko.com/coins/images/14589/thumb/logo_-_2021-03-31T123426.828.png",
  "AIPAD": "https://assets.coingecko.com/coins/images/28894/thumb/JZadeHu.jpeg",
  "KLEVA": "https://assets.coingecko.com/coins/images/26546/thumb/Kleva_400x400.png",
  "CAAVE": "https://assets.coingecko.com/coins/images/17527/thumb/caave.PNG",
  "NFT11": "https://assets.coingecko.com/coins/images/23792/thumb/token-icon.png",
  "OFE": "https://assets.coingecko.com/coins/images/28744/thumb/ofero.png",
  "ANTEX": "https://assets.coingecko.com/coins/images/20654/thumb/xa4LH4tP_400x400.jpg",
  "XDEUS": "https://assets.coingecko.com/coins/images/28702/thumb/xDEUS_logo_200x200.png",
  "STEPG": "https://assets.coingecko.com/coins/images/25572/thumb/4eb9a3f341d20ae03a51c3af1e90a3f5.png",
  "TOOLS": "https://assets.coingecko.com/coins/images/14330/thumb/bsctools.png",
  "XOS": "https://assets.coingecko.com/coins/images/6310/thumb/oasis-no-bkgd-.png",
  "$HORDE": "https://assets.coingecko.com/coins/images/24586/thumb/cKqzagS.png",
  "SENSO": "https://assets.coingecko.com/coins/images/11064/thumb/senso.png",
  "OAS": "https://assets.coingecko.com/coins/images/27909/thumb/Oasys_token_logo_%28green%29_%281%29.png",
  "L2PAD": "https://assets.coingecko.com/coins/images/23694/thumb/l2pad_200x200.png",
  "BLURT": "https://assets.coingecko.com/coins/images/12996/thumb/8SzwQc8j2KJa5zQFn3ArqGwN8arxoZj3EEz8h4AtHCdLzKWsjWiXAgTPKaNsZAkzyJZdMHrdemGtWAUeeQuaxEks6SKbYTWEoHj9gRv7t322LQ7xh1p.png",
  "DEATH": "https://assets.coingecko.com/coins/images/18350/thumb/death200x200.png",
  "STORM": "https://assets.coingecko.com/coins/images/18170/thumb/nFiYZ2xO_400x400.png",
  "OVO": "https://assets.coingecko.com/coins/images/18124/thumb/ovo.PNG",
  "ACOIN": "https://assets.coingecko.com/coins/images/172/thumb/acoin.png",
  "EMBER": "https://assets.coingecko.com/coins/images/28480/thumb/Ember_Logo.jpg",
  "TRX3L": "https://assets.coingecko.com/coins/images/10560/thumb/trx3l.PNG",
  "4PLAY": "https://assets.coingecko.com/coins/images/23664/thumb/NFT4Play-token-logo-colored-512x512.png",
  "IKOLF": "https://assets.coingecko.com/coins/images/26868/thumb/IMG_20220814_132323_400.jpg",
  "FLQ": "https://assets.coingecko.com/coins/images/22143/thumb/fq_256.png",
  "RIDGE": "https://assets.coingecko.com/coins/images/20487/thumb/logo_200x200_%289%29.png",
  "GOATS": "https://assets.coingecko.com/coins/images/22109/thumb/logo_200x200.png",
  "LBY": "https://assets.coingecko.com/coins/images/14070/thumb/download_%2818%29.png",
  "MKOALA": "https://assets.coingecko.com/coins/images/15904/thumb/10137.png",
  "GHOUL": "https://assets.coingecko.com/coins/images/16455/thumb/rsz-ghoul2.png",
  "SLT": "https://assets.coingecko.com/coins/images/20553/thumb/logo_-_2021-11-18T112941.579.png",
  "JIG": "https://assets.coingecko.com/coins/images/21716/thumb/MbfJ2kSM_400x400.png",
  "ZLP": "https://assets.coingecko.com/coins/images/18213/thumb/ms-icon-200x200.png",
  "STONK": "https://assets.coingecko.com/coins/images/14805/thumb/f9YBOaOF_400x400.jpg",
  "ARKER": "https://assets.coingecko.com/coins/images/21313/thumb/coin_%285%29.png",
  "AMAS": "https://assets.coingecko.com/coins/images/18799/thumb/agmqWjv8_400x400.png",
  "IOTN": "https://assets.coingecko.com/coins/images/22102/thumb/dmkAPfqy_400x400.jpg",
  "RELIC": "https://assets.coingecko.com/coins/images/26424/thumb/EQiM831z_400x400.png",
  "ALOHA": "https://assets.coingecko.com/coins/images/14048/thumb/aloha.png",
  "HONOR": "https://assets.coingecko.com/coins/images/16654/thumb/Qkj26Aj.png",
  "DRE": "https://assets.coingecko.com/coins/images/16901/thumb/DoRen_project_whitepaper_v1_1_EN_pdf.png",
  "MCT": "https://assets.coingecko.com/coins/images/28538/thumb/MCT_200.png",
  "SMELT": "https://assets.coingecko.com/coins/images/26584/thumb/smelt_%282%29.png",
  "MCD": "https://assets.coingecko.com/coins/images/26957/thumb/mcd.png",
  "LIBFX": "https://assets.coingecko.com/coins/images/12225/thumb/libfx.png",
  "MONES": "https://assets.coingecko.com/coins/images/26356/thumb/mones_logo.jpg",
  "DEVL": "https://assets.coingecko.com/coins/images/20721/thumb/pLHVgHY.png",
  "CHARM": "https://assets.coingecko.com/coins/images/23721/thumb/aaaa-removebg-preview.png",
  "VAULT": "https://assets.coingecko.com/coins/images/28611/thumb/IMG_20221231_040125_514.jpg",
  "GBR": "https://assets.coingecko.com/coins/images/27980/thumb/Gabur.png",
  "XDOGE": "https://assets.coingecko.com/coins/images/20622/thumb/xdoge.png",
  "KUBIC": "https://assets.coingecko.com/coins/images/16895/thumb/kubic.png",
  "VNX": "https://assets.coingecko.com/coins/images/5523/thumb/vernoxx.png",
  "GMSOL": "https://assets.coingecko.com/coins/images/20717/thumb/gm-logo-200.png",
  "$BORED": "https://assets.coingecko.com/coins/images/28515/thumb/Bored.png",
  "ALERT": "https://assets.coingecko.com/coins/images/28931/thumb/Safeimagekit-resized-img.png",
  "DCO": "https://assets.coingecko.com/coins/images/29007/thumb/15-38-16-200x200.png",
  "HYC": "https://assets.coingecko.com/coins/images/3289/thumb/hycon.png",
  "DUBBZ": "https://assets.coingecko.com/coins/images/28665/thumb/D8EACA06-18ED-4999-8B3A-6339F9E021CE.jpeg",
  "QUINT": "https://assets.coingecko.com/coins/images/25194/thumb/logo-200x200.png",
  "CWD": "https://assets.coingecko.com/coins/images/24125/thumb/cwd.png",
  "PPO": "https://assets.coingecko.com/coins/images/27328/thumb/PPO_logo_200x200.png",
  "SPY": "https://assets.coingecko.com/coins/images/18813/thumb/logo_smarty-pay.png",
  "EGOLD": "https://assets.coingecko.com/coins/images/1105/thumb/egold.png",
  "XAX": "https://assets.coingecko.com/coins/images/5869/thumb/MBiTC2KN_400x400.jpg",
  "GTRX": "https://assets.coingecko.com/coins/images/27506/thumb/lcSVRB3TqwxffjniiK5S4n4C4lMRhx0wXIWA02iiQCHvz6sS96X69Iy_kb4YZXpe1OpmJTjE7518Bl6nmFh8BhpB_vTVWzk3pGiEjHJ4dHvQKkioP6xcO7VUCkwj2QqY6iN7j39OjzDp_FuBvN1ua4Ov1wVxxkeC6ozV37cLKcU5uuC0N5gFx6lioJyybw8QQ9rOwSEmdWPpJ5KAMgjr9ii-Hj4GwW3M6q8DsTZHy4gXXvH.jpeg",
  "SPUME": "https://assets.coingecko.com/coins/images/27089/thumb/D6lMeW9k_400x400.jpeg",
  "ARNX": "https://assets.coingecko.com/coins/images/1124/thumb/ARNX-token-logo-256x256.png",
  "GGTKN": "https://assets.coingecko.com/coins/images/26275/thumb/ggtokenofficiallogo.png",
  "ALIAS": "https://assets.coingecko.com/coins/images/546/thumb/Alias-Icon-Orange.png",
  "ZYTH": "https://assets.coingecko.com/coins/images/21909/thumb/TTuwJNdp_400x400.jpg",
  "UNITE": "https://assets.coingecko.com/coins/images/22034/thumb/Unite.png",
  "VCK": "https://assets.coingecko.com/coins/images/20063/thumb/9435.png",
  "ELR": "https://assets.coingecko.com/coins/images/26396/thumb/ELRON_logo.png",
  "B8T": "https://assets.coingecko.com/coins/images/26201/thumb/logo_200%D1%85200.png",
  "Z": "https://assets.coingecko.com/coins/images/24513/thumb/DPaP9wkr_400x400.jpg",
  "IKURA": "https://assets.coingecko.com/coins/images/18182/thumb/ikura.PNG",
  "ARTII": "https://assets.coingecko.com/coins/images/20767/thumb/logo-img.png",
  "HANZO": "https://assets.coingecko.com/coins/images/15486/thumb/photo_2022-09-13_16-47-18.jpg",
  "FX1": "https://assets.coingecko.com/coins/images/11117/thumb/FX.png",
  "ATN": "https://assets.coingecko.com/coins/images/29069/thumb/alpha-box-bw-normal-200.png",
  "TKS": "https://assets.coingecko.com/coins/images/1248/thumb/mv-logo.png",
  "NXUSD": "https://assets.coingecko.com/coins/images/25101/thumb/nxusd-logo.png",
  "X7105": "https://assets.coingecko.com/coins/images/28423/thumb/X7105_LOGO_black_back_200_x_200_px.png",
  "CORNI": "https://assets.coingecko.com/coins/images/27901/thumb/corni_logo_m.png",
  "$GNOME": "https://assets.coingecko.com/coins/images/20885/thumb/gnome.png",
  "SLD": "https://assets.coingecko.com/coins/images/16170/thumb/SLD_TOKEN.png",
  "TDOGE": "https://assets.coingecko.com/coins/images/15959/thumb/photo_2021-06-01_11-30-59.png",
  "ALTER": "https://assets.coingecko.com/coins/images/23434/thumb/nXt1e24t_400x400.png",
  "NEOFI": "https://assets.coingecko.com/coins/images/24431/thumb/U1r3rOKv_400x400.png",
  "MCOIN": "https://assets.coingecko.com/coins/images/24942/thumb/mcoin_Logo_%281%29.png",
  "OLV": "https://assets.coingecko.com/coins/images/25471/thumb/olv.png",
  "SLNV2": "https://assets.coingecko.com/coins/images/14032/thumb/token.png",
  "VOYCE": "https://assets.coingecko.com/coins/images/21993/thumb/logo.png",
  "TUPAN": "https://assets.coingecko.com/coins/images/28852/thumb/Tupan_LOGO.png",
  "REBUS": "https://assets.coingecko.com/coins/images/27799/thumb/rebus.png",
  "XSHIP": "https://assets.coingecko.com/coins/images/28084/thumb/CRYTOLOGO%281%29.png",
  "PANDO": "https://assets.coingecko.com/coins/images/14150/thumb/pando_logo.png",
  "UNKAI": "https://assets.coingecko.com/coins/images/27458/thumb/2022-09-24_005337-removebg-preview_%281%29.png",
  "ETHUP": "https://assets.coingecko.com/coins/images/17030/thumb/binance-coin-bnb-logo.png",
  "YASHA": "https://assets.coingecko.com/coins/images/24040/thumb/oX9E7Q7.png",
  "XCKSM": "https://assets.coingecko.com/coins/images/28405/thumb/xcksm.jpeg",
  "MOONI": "https://assets.coingecko.com/coins/images/15045/thumb/logo_cg.png",
  "THEOS": "https://assets.coingecko.com/coins/images/18150/thumb/theos_logo.png",
  "TOI": "https://assets.coingecko.com/coins/images/27284/thumb/Group_12429.png",
  "HADES": "https://assets.coingecko.com/coins/images/28819/thumb/hadeswap.jpeg",
  "RLTV2": "https://assets.coingecko.com/coins/images/25762/thumb/rlttoken.png",
  "LOOBR": "https://assets.coingecko.com/coins/images/26652/thumb/LooBr_logo_200x200_1_1.png",
  "ATC": "https://assets.coingecko.com/coins/images/27308/thumb/ATC.png",
  "VIX": "https://assets.coingecko.com/coins/images/15405/thumb/vixco.png",
  "VI": "https://assets.coingecko.com/coins/images/10978/thumb/vi.png",
  "ALTOM": "https://assets.coingecko.com/coins/images/1112/thumb/92g6K3Xf_400x400.jpg",
  "AG": "https://assets.coingecko.com/coins/images/28071/thumb/AG.jpeg",
  "ISTEP": "https://assets.coingecko.com/coins/images/25888/thumb/ISTEP.png",
  "NAFTY": "https://assets.coingecko.com/coins/images/16264/thumb/logo.213aa375.png",
  "XFUEL": "https://assets.coingecko.com/coins/images/26852/thumb/xfuel200.png",
  "CON": "https://assets.coingecko.com/coins/images/14569/thumb/converter.png",
  "NIIFI": "https://assets.coingecko.com/coins/images/16033/thumb/niifi.PNG",
  "DIG": "https://assets.coingecko.com/coins/images/23413/thumb/-3pugWvk_400x400.jpg",
  "$MUNDO": "https://assets.coingecko.com/coins/images/23663/thumb/crystal_token_logo.png",
  "RASKO": "https://assets.coingecko.com/coins/images/20977/thumb/rASKO.png",
  "XMN": "https://assets.coingecko.com/coins/images/18733/thumb/n96tvvsB_400x400.jpg",
  "FLONA": "https://assets.coingecko.com/coins/images/28838/thumb/E3784046-3121-49D5-A090-1409BD81942D.jpeg",
  "BUSDX": "https://assets.coingecko.com/coins/images/20980/thumb/FgHuTG1.png",
  "BASIC": "https://assets.coingecko.com/coins/images/11050/thumb/unTgJN6U_400x400.jpg",
  "MEC": "https://assets.coingecko.com/coins/images/16952/thumb/mechashiba.png",
  "SEASY": "https://assets.coingecko.com/coins/images/27140/thumb/seasy.png",
  "HNY": "https://assets.coingecko.com/coins/images/24797/thumb/hny.png",
  "XPO": "https://assets.coingecko.com/coins/images/14756/thumb/SU8JVaHN_400x400_%281%29.jpg",
  "MOBX": "https://assets.coingecko.com/coins/images/27445/thumb/0F7231E3-E67F-4D2C-A503-D492C7B6ADAF.jpeg",
  "OMB": "https://assets.coingecko.com/coins/images/3942/thumb/ombre_logo_medium.png",
  "HITOP": "https://assets.coingecko.com/coins/images/25959/thumb/20540.png",
  "AKTIO": "https://assets.coingecko.com/coins/images/25049/thumb/0shF1qhT_400x400.jpg",
  "ATMOS": "https://assets.coingecko.com/coins/images/1242/thumb/Ir87t76.png",
  "VICAT": "https://assets.coingecko.com/coins/images/26125/thumb/ViCat_Logo_200x200.PNG",
  "CRAVE": "https://assets.coingecko.com/coins/images/251/thumb/favicon.png",
  "LIBRE": "https://assets.coingecko.com/coins/images/28805/thumb/Libre-mark-rgb-pos-200x200.png",
  "EXMOC": "https://assets.coingecko.com/coins/images/24592/thumb/exmoc.png",
  "SU": "https://assets.coingecko.com/coins/images/27465/thumb/%EC%8A%A4%EC%B9%B4%EC%9D%B4%EC%97%85_%EB%A1%9C%EA%B3%A0.jpg",
  "TAZOR": "https://assets.coingecko.com/coins/images/27261/thumb/tazor.png",
  "SOP": "https://assets.coingecko.com/coins/images/6736/thumb/sopay.png",
  "SEED": "https://assets.coingecko.com/coins/images/15636/thumb/2_%287%29.png",
  "UNM": "https://assets.coingecko.com/coins/images/24567/thumb/Unium_logo.png",
  "LAMEA": "https://assets.coingecko.com/coins/images/27119/thumb/LAMEA_logofekete_h%C3%A1tt%C3%A9r_200px.png",
  "AKN": "https://assets.coingecko.com/coins/images/13098/thumb/akoin_logo.jpg",
  "THECA": "https://assets.coingecko.com/coins/images/21782/thumb/logo_%281%29.png",
  "SPARQ": "https://assets.coingecko.com/coins/images/27893/thumb/sparq.jpeg",
  "FAVOR": "https://assets.coingecko.com/coins/images/25580/thumb/favor_coin.png",
  "BOARD": "https://assets.coingecko.com/coins/images/27143/thumb/logo-board.png",
  "LVL": "https://assets.coingecko.com/coins/images/5588/thumb/n5DaGBqY_400x400.jpg",
  "TEXAN": "https://assets.coingecko.com/coins/images/28731/thumb/texan_logo_200x200.png",
  "NOWAR": "https://assets.coingecko.com/coins/images/26463/thumb/12.jpg",
  "GOETH": "https://assets.coingecko.com/coins/images/26826/thumb/goeth.png",
  "PORTE": "https://assets.coingecko.com/coins/images/9612/thumb/porte.png",
  "MUDRA": "https://assets.coingecko.com/coins/images/29045/thumb/imgpsh_fullsize_anim_%282%29.png",
  "SINGH": "https://assets.coingecko.com/coins/images/25123/thumb/singh-200x200.png",
  "NTO": "https://assets.coingecko.com/coins/images/25418/thumb/lqT0CJNj_400x400.jpg",
  "XLIST": "https://assets.coingecko.com/coins/images/25913/thumb/X-Logo200x200.png",
  "AFX": "https://assets.coingecko.com/coins/images/28973/thumb/afx.png",
  "ZOMFI": "https://assets.coingecko.com/coins/images/21231/thumb/Zomfi_Coin_Logo_200x200.png",
  "WHIVE": "https://assets.coingecko.com/coins/images/20150/thumb/37770307.png",
  "MOCHI": "https://assets.coingecko.com/coins/images/20164/thumb/4DileY_b_400x400.jpg",
  "SHEEP": "https://assets.coingecko.com/coins/images/18820/thumb/L-sb1AHr_400x400.jpg",
  "MEIN": "https://assets.coingecko.com/coins/images/27820/thumb/mein-symbol.png",
  "FO": "https://assets.coingecko.com/coins/images/8617/thumb/f138605a-6427-482c-84af-1d8495aa3e66.png",
  "MARIA": "https://assets.coingecko.com/coins/images/27838/thumb/mariacoin.png",
  "XMU": "https://assets.coingecko.com/coins/images/27965/thumb/2.png",
  "SYF": "https://assets.coingecko.com/coins/images/18192/thumb/F5OrlwWA_400x400.png",
  "VRJAM": "https://assets.coingecko.com/coins/images/28049/thumb/VRJAM_coin_logo_%28gradient_on_white%29.png",
  "PGPAY": "https://assets.coingecko.com/coins/images/6351/thumb/pgpay-logo.jpg",
  "DOX": "https://assets.coingecko.com/coins/images/23853/thumb/500x500-LOGO.png",
  "ITUBE": "https://assets.coingecko.com/coins/images/27294/thumb/itube.png",
  "FNF": "https://assets.coingecko.com/coins/images/25872/thumb/FNF-200x200.png",
  "METOO": "https://assets.coingecko.com/coins/images/26512/thumb/glimPMvM_400x400.jpeg",
  "FLOCK": "https://assets.coingecko.com/coins/images/22775/thumb/fUNf0Lu.png",
  "APN": "https://assets.coingecko.com/coins/images/14916/thumb/1_GOjoDhGzzpqnMPGpHGeWhg.png",
  "QATAR": "https://assets.coingecko.com/coins/images/27894/thumb/2022-10-18_20.39.03.jpg",
  "DLQ": "https://assets.coingecko.com/coins/images/24457/thumb/JT5SiYhr_400x400.jpg",
  "X7104": "https://assets.coingecko.com/coins/images/28422/thumb/X7104_LOGO_black_back_200_x_200_px.png",
  "LARIX": "https://assets.coingecko.com/coins/images/18450/thumb/larix.PNG",
  "1PECO": "https://assets.coingecko.com/coins/images/22312/thumb/1PECO_200x200.png",
  "GOLD8": "https://assets.coingecko.com/coins/images/27481/thumb/gold8-200x200.png",
  "UPBNB": "https://assets.coingecko.com/coins/images/15092/thumb/upbnb_logo.jpg",
  "ENV": "https://assets.coingecko.com/coins/images/19631/thumb/env.png",
  "GLW": "https://assets.coingecko.com/coins/images/26633/thumb/IMG_20220729_231702_543.jpg",
  "MCEUR": "https://assets.coingecko.com/coins/images/22345/thumb/1OY4GRnl_400x400.jpg",
  "LOOMI": "https://assets.coingecko.com/coins/images/22919/thumb/C8miy1Qi_400x400.jpg",
  "DEGN": "https://assets.coingecko.com/coins/images/20103/thumb/degendex.png",
  "LOG": "https://assets.coingecko.com/coins/images/346/thumb/woodcoin2.png",
  "AGL": "https://assets.coingecko.com/coins/images/21589/thumb/agile.png",
  "LEXI": "https://assets.coingecko.com/coins/images/15699/thumb/LEXi_Coin_Logo.png",
  "SKLAY": "https://assets.coingecko.com/coins/images/13912/thumb/sKLAY_logo.png",
  "IBANK": "https://assets.coingecko.com/coins/images/3105/thumb/ibankcoin.png",
  "4JNET": "https://assets.coingecko.com/coins/images/21145/thumb/4jnet.PNG",
  "ZYR": "https://assets.coingecko.com/coins/images/24063/thumb/LOGO-ZYRRI-50X50.png",
  "KDOE": "https://assets.coingecko.com/coins/images/28709/thumb/CG.png",
  "LENDA": "https://assets.coingecko.com/coins/images/22925/thumb/logo-200x200.png",
  "OXD": "https://assets.coingecko.com/coins/images/22888/thumb/rjks-OoT_400x400.jpg",
  "SLS": "https://assets.coingecko.com/coins/images/359/thumb/salus.png",
  "SPT": "https://assets.coingecko.com/coins/images/26408/thumb/token.png",
  "COBAN": "https://assets.coingecko.com/coins/images/19483/thumb/coban-logo-pebyyxjzxvebwa8nsvpp9t8iple69h6enp9bdknotq.png",
  "CZBNB": "https://assets.coingecko.com/coins/images/26175/thumb/czbnb.9f7f5b59_%281%29.png",
  "SIU": "https://assets.coingecko.com/coins/images/26531/thumb/zx46tIq7_400x400.jpeg",
  "TRO": "https://assets.coingecko.com/coins/images/14713/thumb/KU_Ul7P5_400x400.jpg",
  "ELOIN": "https://assets.coingecko.com/coins/images/17656/thumb/Pics-Art-08-01-02-43-25.png",
  "DOUGE": "https://assets.coingecko.com/coins/images/23940/thumb/QmUXKxk2f1BzKgJRf5aUk3ezmncuyGcNv8Gd5fHmVrDwXN.png",
  "AVR": "https://assets.coingecko.com/coins/images/22688/thumb/new.png",
  "PYRAM": "https://assets.coingecko.com/coins/images/17372/thumb/pyram-token-200x200.png",
  "BOXCH": "https://assets.coingecko.com/coins/images/26119/thumb/Group_212.png",
  "EIDOS": "https://assets.coingecko.com/coins/images/9857/thumb/newenumivologo.png",
  "STYLE": "https://assets.coingecko.com/coins/images/28060/thumb/78oZvtZW_400x400.jpeg",
  "ARZ": "https://assets.coingecko.com/coins/images/25158/thumb/tJ8-lGaw_400x400.jpg",
  "CNETA": "https://assets.coingecko.com/coins/images/23255/thumb/f53EY5hW_o.png",
  "STEMX": "https://assets.coingecko.com/coins/images/19739/thumb/13702.png",
  "$APLUS": "https://assets.coingecko.com/coins/images/27737/thumb/AmetaToken.png",
  "KCASH": "https://assets.coingecko.com/coins/images/2549/thumb/kcash.png",
  "BHIVE": "https://assets.coingecko.com/coins/images/27596/thumb/hive.png",
  "CAF": "https://assets.coingecko.com/coins/images/27461/thumb/200x200.png",
  "FOLK": "https://assets.coingecko.com/coins/images/25667/thumb/folk-cryptovoxels.png",
  "ARBYS": "https://assets.coingecko.com/coins/images/18332/thumb/arby.PNG",
  "XRA": "https://assets.coingecko.com/coins/images/284/thumb/ratecoin.png",
  "THE": "https://assets.coingecko.com/coins/images/27849/thumb/cSar5sDM_400x400.jpg",
  "ERTHA": "https://assets.coingecko.com/coins/images/20317/thumb/Ry9tgUal_400x400.jpg",
  "CREDA": "https://assets.coingecko.com/coins/images/22551/thumb/gZ4EgSXD_400x400.jpg",
  "PIZZA": "https://assets.coingecko.com/coins/images/23771/thumb/eh-lDaUq_400x400.jpg",
  "PARAS": "https://assets.coingecko.com/coins/images/18742/thumb/white-with-bg.png",
  "GOTEM": "https://assets.coingecko.com/coins/images/19108/thumb/logo-transparent-bg.png",
  "X7102": "https://assets.coingecko.com/coins/images/28420/thumb/X7102_LOGO_black_back_200_x_200_px.png",
  "KLING": "https://assets.coingecko.com/coins/images/23861/thumb/Kling-Logo-32.png",
  "XIN": "https://assets.coingecko.com/coins/images/2442/thumb/mixin.png",
  "HVE2": "https://assets.coingecko.com/coins/images/3811/thumb/hve2.png",
  "STORY": "https://assets.coingecko.com/coins/images/18128/thumb/logo_-_2021-09-03T064032.320.png",
  "CMLT": "https://assets.coingecko.com/coins/images/22227/thumb/cmltlogo.png",
  "NXS": "https://assets.coingecko.com/coins/images/576/thumb/nexus-logo.png",
  "AX": "https://assets.coingecko.com/coins/images/27871/thumb/AurusX_token_2D.jpg",
  "TITS": "https://assets.coingecko.com/coins/images/19761/thumb/tits.png",
  "MARCO": "https://assets.coingecko.com/coins/images/27568/thumb/MELEGA_logo_halo_200x200px.png",
  "MELODY": "https://assets.coingecko.com/coins/images/15442/thumb/UajWzxT.png",
  "DNA": "https://assets.coingecko.com/coins/images/10634/thumb/DNA_brand_logos-1.png",
  "SHORTY": "https://assets.coingecko.com/coins/images/1376/thumb/shorty.png",
  "BABYG": "https://assets.coingecko.com/coins/images/28652/thumb/BabyGpng.png",
  "SQUA": "https://assets.coingecko.com/coins/images/22530/thumb/logo_square_token_200x200.png",
  "IJZ": "https://assets.coingecko.com/coins/images/22695/thumb/17379.png",
  "CSO": "https://assets.coingecko.com/coins/images/9125/thumb/crespo.png",
  "SHEESH": "https://assets.coingecko.com/coins/images/15483/thumb/sheesh.png",
  "PREMIO": "https://assets.coingecko.com/coins/images/19958/thumb/premio_61a66349c0.png",
  "NTIC": "https://assets.coingecko.com/coins/images/28257/thumb/ntic.png",
  "HEAL": "https://assets.coingecko.com/coins/images/24123/thumb/GJe1qAQS_400x400.jpg",
  "CREDIT": "https://assets.coingecko.com/coins/images/12212/thumb/_credit.png",
  "GYM AI": "https://assets.coingecko.com/coins/images/29061/thumb/200x200-removebg-preview.png",
  "SHIBGF": "https://assets.coingecko.com/coins/images/19699/thumb/shibgf_logo.png",
  "MUINU": "https://assets.coingecko.com/coins/images/28487/thumb/muinucrown200.png",
  "DARUMA": "https://assets.coingecko.com/coins/images/27357/thumb/CGlogo.png",
  "NII": "https://assets.coingecko.com/coins/images/9786/thumb/nahmii-sm_icon-full-color.png",
  "SIC": "https://assets.coingecko.com/coins/images/13382/thumb/sicash.png",
  "UPSHIB": "https://assets.coingecko.com/coins/images/15533/thumb/upshib.png",
  "HBX": "https://assets.coingecko.com/coins/images/15707/thumb/habit.PNG",
  "SBY": "https://assets.coingecko.com/coins/images/20356/thumb/hdf0.jpg",
  "$SHARBI": "https://assets.coingecko.com/coins/images/29075/thumb/WhatsApp_Image_2023-01-25_at_12.08.22_PM-removebg-1.png",
  "BSK-BAA025": "https://assets.coingecko.com/coins/images/24651/thumb/18022.png",
  "LXTO": "https://assets.coingecko.com/coins/images/26001/thumb/luxtto_logo%28200x200%29.png",
  "PQBERT": "https://assets.coingecko.com/coins/images/19223/thumb/pqbert.jpeg",
  "CREAMA": "https://assets.coingecko.com/coins/images/28073/thumb/200.png",
  "UPCOIN": "https://assets.coingecko.com/coins/images/17077/thumb/Upcoin-logo.png",
  "NAI": "https://assets.coingecko.com/coins/images/27273/thumb/nai.png",
  "MONKEX": "https://assets.coingecko.com/coins/images/28834/thumb/tokenlogo.png",
  "WJEWEL": "https://assets.coingecko.com/coins/images/24831/thumb/jewel.png",
  "ALT": "https://assets.coingecko.com/coins/images/27614/thumb/haLolCTC_200x200.jpg",
  "HIMAYC": "https://assets.coingecko.com/coins/images/27602/thumb/himayc.png",
  "ENVIRO": "https://assets.coingecko.com/coins/images/16028/thumb/enviro.PNG",
  "GMCOIN": "https://assets.coingecko.com/coins/images/19691/thumb/GMC_logo_200x200.png",
  "ECHT": "https://assets.coingecko.com/coins/images/1535/thumb/e-chat.png",
  "TGD": "https://assets.coingecko.com/coins/images/26420/thumb/tgrade-icon-gradient.59044739.png",
  "BUMN": "https://assets.coingecko.com/coins/images/17570/thumb/bumn-logo-200.png",
  "VIAGRA": "https://assets.coingecko.com/coins/images/15576/thumb/viagra.PNG",
  "INN": "https://assets.coingecko.com/coins/images/1131/thumb/Innova.png",
  "MDO": "https://assets.coingecko.com/coins/images/25214/thumb/logo_medano-1.png",
  "REVT": "https://assets.coingecko.com/coins/images/15581/thumb/WhpwgTS.jpg",
  "MMON": "https://assets.coingecko.com/coins/images/15664/thumb/Untitled-design-34-removebg-preview-2.png",
  "USDTZ": "https://assets.coingecko.com/coins/images/19467/thumb/usdtz.png",
  "A5T": "https://assets.coingecko.com/coins/images/13848/thumb/a5t.jpg",
  "IVG": "https://assets.coingecko.com/coins/images/17691/thumb/IMG_20210808_035857_984.jpg",
  "SWI": "https://assets.coingecko.com/coins/images/25534/thumb/LOGO-TOKEN-2.jpeg",
  "RDF": "https://assets.coingecko.com/coins/images/26780/thumb/readfi.png",
  "REVOAI": "https://assets.coingecko.com/coins/images/28952/thumb/roveAI1_%283%29.png",
  "CHEESE": "https://assets.coingecko.com/coins/images/17730/thumb/CHEESE_Transparent_%28200px%29.png",
  "JMZ": "https://assets.coingecko.com/coins/images/27595/thumb/token-logo.png",
  "SSW": "https://assets.coingecko.com/coins/images/25010/thumb/N2xq-Se-Ob-400x400.png",
  "KZEN": "https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png",
  "ME": "https://assets.coingecko.com/coins/images/24754/thumb/MOO-NEYE.png",
  "GOL": "https://assets.coingecko.com/coins/images/14824/thumb/GOL.png",
  "VRTK": "https://assets.coingecko.com/coins/images/28958/thumb/vertek-mark-color-round%282%29.png",
  "SAX": "https://assets.coingecko.com/coins/images/25154/thumb/RKraNLSL_400x400.jpg",
  "ARBINU": "https://assets.coingecko.com/coins/images/28812/thumb/logo.png",
  "SEON": "https://assets.coingecko.com/coins/images/23651/thumb/3nhWnC2t_400x400.jpg",
  "XFL": "https://assets.coingecko.com/coins/images/19460/thumb/eGOoy1sZ_400x400.jpg",
  "DOGENS": "https://assets.coingecko.com/coins/images/28545/thumb/IMG_20221220_122417_314.jpg",
  "ZTX": "https://assets.coingecko.com/coins/images/27181/thumb/Zetrix-Icon-200x200-1.jpg",
  "NBU": "https://assets.coingecko.com/coins/images/14517/thumb/EQMLlI52Dhm7DPe4QPt81MiqPTUa0X7YOZV9G1ki_OpZe6rtVUtmIn5XbQHeMHyuTkX4Y1qU-K8lAn4juL3KnVQLR7WeeJ_r6jI5nrjGgvt4DgMSAnuTrT15AREqbB0wUkosE8NVhjKpPUvvaALnpO0BtFUt4Bo9tsOTsfQDAEFrpH0uhVkEqJG_u09ehkX88hkYKJcm5RkvIgq.jpg",
  "BAOUSD": "https://assets.coingecko.com/coins/images/28592/thumb/baoUSD.png",
  "NPT": "https://assets.coingecko.com/coins/images/23912/thumb/_Logo__Neopin_Token.png",
  "$PAPER": "https://assets.coingecko.com/coins/images/28544/thumb/gj_VMyrN.png",
  "NFTPAD": "https://assets.coingecko.com/coins/images/17930/thumb/nftpad.PNG",
  "XMC": "https://assets.coingecko.com/coins/images/3758/thumb/XMC_Logo_%281%29.jpg",
  "QTO": "https://assets.coingecko.com/coins/images/26024/thumb/8QyOrTCm_400x400.jpg",
  "LHCOIN": "https://assets.coingecko.com/coins/images/6709/thumb/lhcoin.jpg",
  "PRIMAL": "https://assets.coingecko.com/coins/images/28435/thumb/PRIMAL_ICON_200px.jpg",
  "KEL": "https://assets.coingecko.com/coins/images/14851/thumb/logokelvpn200x200.png",
  "XQR": "https://assets.coingecko.com/coins/images/6468/thumb/qredit-square-trans.png",
  "XCE": "https://assets.coingecko.com/coins/images/267/thumb/cerium.png",
  "NTN": "missing_thumb.png",
  "IOUSDC": "https://assets.coingecko.com/coins/images/19346/thumb/io18v4l9dfr74xyu320pz4zsmgrz9d07vnvy20yrh.png",
  "PAPPAY": "https://assets.coingecko.com/coins/images/15394/thumb/pappay.jpeg",
  "MXY": "https://assets.coingecko.com/coins/images/22842/thumb/261052729_115071754344881_6053416489823202921_n.jpg",
  "MDDN": "https://assets.coingecko.com/coins/images/26383/thumb/IMG-20220616-WA0019.jpg",
  "ZAM": "https://assets.coingecko.com/coins/images/19522/thumb/zam.png",
  "LA´EEB": "https://assets.coingecko.com/coins/images/27852/thumb/laeeb.png",
  "AVO": "https://assets.coingecko.com/coins/images/27437/thumb/avo.png",
  "TANUKI": "https://assets.coingecko.com/coins/images/20451/thumb/tanuki.png",
  "LEMD": "https://assets.coingecko.com/coins/images/14964/thumb/D-smP_i-_400x400.png",
  "WLRS": "https://assets.coingecko.com/coins/images/25865/thumb/1.png",
  "KIC": "https://assets.coingecko.com/coins/images/25143/thumb/YYaHE4j4_400x400.jpg",
  "MONETA": "https://assets.coingecko.com/coins/images/690/thumb/moneta.png",
  "POS32": "https://assets.coingecko.com/coins/images/27358/thumb/90532f66-9158-4bd4-8928-bcb8ec012579.png",
  "JUNGLE": "https://assets.coingecko.com/coins/images/20893/thumb/O9Usopi.png",
  "ORBR": "https://assets.coingecko.com/coins/images/24056/thumb/fA2YATWT_400x400.jpg",
  "GEC": "https://assets.coingecko.com/coins/images/18465/thumb/GEC_200x200.png",
  "SNAP": "https://assets.coingecko.com/coins/images/21458/thumb/snap.png",
  "DXO": "https://assets.coingecko.com/coins/images/18068/thumb/dxo.png",
  "TUTL": "https://assets.coingecko.com/coins/images/26226/thumb/logo_%282%29.png",
  "PUP": "https://assets.coingecko.com/coins/images/16420/thumb/2_%288%29.png",
  "APAD": "https://assets.coingecko.com/coins/images/24324/thumb/Untitled-design-1.png",
  "RENFIL": "https://assets.coingecko.com/coins/images/13375/thumb/Filecoin.jpg",
  "EC": "https://assets.coingecko.com/coins/images/27707/thumb/LOGO.png",
  "CTS": "https://assets.coingecko.com/coins/images/22519/thumb/15554.png",
  "REV3L": "https://assets.coingecko.com/coins/images/26121/thumb/rev3l.png",
  "DGNX": "https://assets.coingecko.com/coins/images/27204/thumb/33358FE4-080B-4987-86A9-202CDEE46F06.png",
  "HIODBS": "https://assets.coingecko.com/coins/images/27296/thumb/hiodbs.png",
  "SLR": "https://assets.coingecko.com/coins/images/27920/thumb/solarislogo.png",
  "ZLW": "https://assets.coingecko.com/coins/images/11547/thumb/5614.png",
  "VETTER": "https://assets.coingecko.com/coins/images/19235/thumb/Vetter_Logo.jpg",
  "PYO": "https://assets.coingecko.com/coins/images/22423/thumb/pyo.png",
  "CZBUSD": "https://assets.coingecko.com/coins/images/26174/thumb/czbusd.bf8b5691_%281%29.png",
  "DAOSOL": "https://assets.coingecko.com/coins/images/25488/thumb/logo_%284%29.png",
  "QRT": "https://assets.coingecko.com/coins/images/19320/thumb/logo_-_2021-10-25T080744.116.png",
  "YPLX": "https://assets.coingecko.com/coins/images/18179/thumb/yoplex.PNG",
  "H3RO3S": "https://assets.coingecko.com/coins/images/20906/thumb/h3roes.PNG",
  "AIRT": "https://assets.coingecko.com/coins/images/18370/thumb/AIRT-Logo.png",
  "IMAGIC": "https://assets.coingecko.com/coins/images/18843/thumb/imagic.png",
  "$KRAUSE": "https://assets.coingecko.com/coins/images/21022/thumb/-HEQTtGz_400x400.png",
  "FDM": "https://assets.coingecko.com/coins/images/18548/thumb/200x200_%2831%29.png",
  "REDFEG": "https://assets.coingecko.com/coins/images/17292/thumb/redfeg.PNG",
  "MILKAI": "https://assets.coingecko.com/coins/images/29055/thumb/MilkAI.jpeg",
  "IAZUKI": "https://assets.coingecko.com/coins/images/28720/thumb/iazuki.png",
  "GOLD": "https://assets.coingecko.com/coins/images/18010/thumb/htvlAXaQ3yqLeOn.png",
  "GOOREO": "https://assets.coingecko.com/coins/images/18855/thumb/1_lNtkovXCpGAi7XBpgNRXSw.png",
  "ZOC": "https://assets.coingecko.com/coins/images/5720/thumb/F1nTlw9I_400x400.jpg",
  "RIBT": "https://assets.coingecko.com/coins/images/25404/thumb/EunlozE__400x400.jpg",
  "MNM": "https://assets.coingecko.com/coins/images/2654/thumb/mineum.png",
  "ELMON": "https://assets.coingecko.com/coins/images/20295/thumb/11663.png",
  "PAYBIT": "https://assets.coingecko.com/coins/images/28671/thumb/photo_2022-12-29_14-10-52.jpg",
  "MDM": "https://assets.coingecko.com/coins/images/9961/thumb/VEEFC4sG.png",
  "AQUARI": "https://assets.coingecko.com/coins/images/15177/thumb/aquari-blue-200-notext.png",
  "AVAPAY": "https://assets.coingecko.com/coins/images/21196/thumb/IOowVeS.png",
  "PLGR": "https://assets.coingecko.com/coins/images/18243/thumb/PLGR.png",
  "SPR": "https://assets.coingecko.com/coins/images/23007/thumb/Z0y8aqD.png",
  "FENOMY": "https://assets.coingecko.com/coins/images/23625/thumb/communityIcon_e419z9o5oua81.png",
  "XCUSDT": "https://assets.coingecko.com/coins/images/29019/thumb/usdt.png",
  "STELIA": "https://assets.coingecko.com/coins/images/26041/thumb/X1oqqYf.jpg",
  "EVU": "https://assets.coingecko.com/coins/images/22081/thumb/isotipo-evulus.png",
  "2SHARES": "https://assets.coingecko.com/coins/images/22313/thumb/VKF8hjD0_400x400.jpg",
  "KNK": "https://assets.coingecko.com/coins/images/26597/thumb/knk-cmc-logo.png",
  "CHA": "https://assets.coingecko.com/coins/images/28596/thumb/chains.jpeg",
  "INCOME": "https://assets.coingecko.com/coins/images/21125/thumb/income_island.PNG",
  "BIO": "https://assets.coingecko.com/coins/images/24702/thumb/99951582.png",
  "DOA": "https://assets.coingecko.com/coins/images/24467/thumb/200x200_DOA_Original.png",
  "PLG": "https://assets.coingecko.com/coins/images/5120/thumb/pledgecamop.png",
  "I0C": "https://assets.coingecko.com/coins/images/2625/thumb/i0coin.png",
  "MNTO": "https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png",
  "XRDOGE": "https://assets.coingecko.com/coins/images/20017/thumb/l6cmtS0x_400x400.jpg",
  "$MLNX": "https://assets.coingecko.com/coins/images/20096/thumb/melonx.PNG",
  "LIZARD": "https://assets.coingecko.com/coins/images/19892/thumb/lizard_logo_3-ts1636074175.jpg",
  "HUNGER": "https://assets.coingecko.com/coins/images/20335/thumb/xY3MmSf7_400x400.jpg",
  "MIN": "https://assets.coingecko.com/coins/images/27823/thumb/download_%2811%29.png",
  "ALM": "https://assets.coingecko.com/coins/images/15552/thumb/idEZxWx.png",
  "XT": "https://assets.coingecko.com/coins/images/8391/thumb/XT_token.jpg",
  "CATCHY": "https://assets.coingecko.com/coins/images/20252/thumb/Catchy-200x200.png",
  "EDUX": "https://assets.coingecko.com/coins/images/18365/thumb/edufex.PNG",
  "BITANT": "https://assets.coingecko.com/coins/images/19055/thumb/BitANT.png",
  "QMC": "https://assets.coingecko.com/coins/images/6386/thumb/QMCoin-logo.png",
  "KUSD-T": "https://assets.coingecko.com/coins/images/19627/thumb/KUSD-T.png",
  "ATT": "https://assets.coingecko.com/coins/images/11337/thumb/LOGO_%2874%29.png",
  "CHIHUA": "https://assets.coingecko.com/coins/images/15655/thumb/chihua_token_icon_200x200.png",
  "TMATIC": "https://assets.coingecko.com/coins/images/26971/thumb/tenderMATIC.png",
  "PSPACE": "https://assets.coingecko.com/coins/images/16984/thumb/space_token_2.png",
  "FITM": "https://assets.coingecko.com/coins/images/28664/thumb/fitm.png",
  "SEFA": "https://assets.coingecko.com/coins/images/10977/thumb/Sefa_Pool_Logo_Bleu.png",
  "YTN": "https://assets.coingecko.com/coins/images/1360/thumb/yenten.png",
  "NSHARE": "https://assets.coingecko.com/coins/images/21692/thumb/NF_nshare_2_200x200.png",
  "BOC": "https://assets.coingecko.com/coins/images/26756/thumb/200-200_bo_logo.png",
  "NTR": "https://assets.coingecko.com/coins/images/18009/thumb/Icon_-_Logo_600x600px.png",
  "GBX": "https://assets.coingecko.com/coins/images/1207/thumb/gobyte.png",
  "KON": "https://assets.coingecko.com/coins/images/26128/thumb/konlogo_200x200_white.png",
  "ANB": "https://assets.coingecko.com/coins/images/28773/thumb/anubit-200px.png",
  "ECOB": "https://assets.coingecko.com/coins/images/737/thumb/ecobit.png",
  "SHAMAN": "https://assets.coingecko.com/coins/images/20189/thumb/shamna.png",
  "MOR": "https://assets.coingecko.com/coins/images/18549/thumb/MOR_logo_icon_black_background_round_200_x_200-01.png",
  "INDI": "https://assets.coingecko.com/coins/images/23857/thumb/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_0cb39c1f-db6d-4dd9-9143-1f30ee38d74d_743x726.jpeg",
  "VICS": "https://assets.coingecko.com/coins/images/17582/thumb/s6cPN83.jpeg",
  "VIRTUE": "https://assets.coingecko.com/coins/images/25834/thumb/discord.png",
  "SPHYNX": "https://assets.coingecko.com/coins/images/25079/thumb/200x200-TRANS.png",
  "EM": "https://assets.coingecko.com/coins/images/9155/thumb/KDbQPKIu_400x400.png",
  "VXON": "https://assets.coingecko.com/coins/images/28369/thumb/VoxNETlogo.png",
  "SYP": "https://assets.coingecko.com/coins/images/18518/thumb/sypool.PNG",
  "DOGIRA": "https://assets.coingecko.com/coins/images/14634/thumb/IQgaRw0.png",
  "RNDM": "https://assets.coingecko.com/coins/images/16154/thumb/Neon_RNDM_Symbol.png",
  "CATBOY": "https://assets.coingecko.com/coins/images/24051/thumb/2.png",
  "MEE": "https://assets.coingecko.com/coins/images/27588/thumb/MEE_200x200.png",
  "ALBEDO": "https://assets.coingecko.com/coins/images/28024/thumb/217d99e9-8a21-4678-9019-6b3cf7f714fe.png",
  "UIS": "https://assets.coingecko.com/coins/images/438/thumb/UIS_New_Logo.png",
  "WSBT": "https://assets.coingecko.com/coins/images/23920/thumb/_SjqfFvj_400x400.png",
  "CNR": "https://assets.coingecko.com/coins/images/16764/thumb/logo_-_2021-06-29T113338.436.png",
  "TRAT": "https://assets.coingecko.com/coins/images/6791/thumb/tratok.png",
  "IP3": "https://assets.coingecko.com/coins/images/26344/thumb/f55kBYa2_400x400.jpeg",
  "HIRO": "https://assets.coingecko.com/coins/images/26342/thumb/IMG-20220707-162424-700.jpg",
  "NAO": "https://assets.coingecko.com/coins/images/22576/thumb/NAO.png",
  "JTS": "https://assets.coingecko.com/coins/images/25295/thumb/lAsTdP6-_400x400.jpg",
  "QLINDO": "https://assets.coingecko.com/coins/images/26851/thumb/21315.png",
  "RYOSHI": "https://assets.coingecko.com/coins/images/17614/thumb/OFFICIAL_RYOSHI_LOGO-removebg-preview_2_200x200.png",
  "PICO": "https://assets.coingecko.com/coins/images/27840/thumb/LOGO_200x200.png",
  "ADAO": "https://assets.coingecko.com/coins/images/25852/thumb/algodao_200x200_white.png",
  "FQS": "https://assets.coingecko.com/coins/images/23891/thumb/020.png",
  "FRTS": "https://assets.coingecko.com/coins/images/16059/thumb/FruitsLogo3.png",
  "FOSSIL": "https://assets.coingecko.com/coins/images/22593/thumb/dtdm7P1W_400x400.jpg",
  "LYNK": "https://assets.coingecko.com/coins/images/25069/thumb/oOcTsBsg_400x400_%281%29.jpg",
  "UTED": "https://assets.coingecko.com/coins/images/12820/thumb/uted_new.png",
  "CLPS": "https://assets.coingecko.com/coins/images/26859/thumb/IMG_20220813_230600_320.jpg",
  "SCORAI": "https://assets.coingecko.com/coins/images/28897/thumb/Orchai_LOGO.png",
  "KTT": "https://assets.coingecko.com/coins/images/13939/thumb/KakaoTalk_20220928_162525453.png",
  "TENSHI": "https://assets.coingecko.com/coins/images/17170/thumb/TENSHI.png",
  "VBSWAP": "https://assets.coingecko.com/coins/images/14133/thumb/vbswap_22.png",
  "PLN": "https://assets.coingecko.com/coins/images/24723/thumb/plearn200x200.png",
  "LIFT": "https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png",
  "CUMINU": "https://assets.coingecko.com/coins/images/15832/thumb/CUMINU.png",
  "FLZ": "https://assets.coingecko.com/coins/images/26169/thumb/NUERy1Wy_400x400.png",
  "GEMDAO": "missing_thumb.png",
  "FIVO": "https://assets.coingecko.com/coins/images/27141/thumb/FIVO_200.png",
  "MARMAJ": "https://assets.coingecko.com/coins/images/18457/thumb/marmaj_token_200x200.png",
  "PPI": "https://assets.coingecko.com/coins/images/25019/thumb/512_lightmodeRound.png",
  "GALEON": "https://assets.coingecko.com/coins/images/24261/thumb/Logo-Galeon-Coinmarketcap.png",
  "IOWBTC": "https://assets.coingecko.com/coins/images/19347/thumb/io1c7unwg8h8vph89xwqru4f7zfa4yy5002wxvlrm.png",
  "HGHG": "https://assets.coingecko.com/coins/images/20172/thumb/512hug.png",
  "SWPLY": "https://assets.coingecko.com/coins/images/28555/thumb/IMG-20221221-165656-319.jpg",
  "APTOGE": "https://assets.coingecko.com/coins/images/27951/thumb/aptoge_200_200.png",
  "WKIT": "https://assets.coingecko.com/coins/images/26301/thumb/WORKIT_CI_%EC%8B%AC%EB%B3%BC.png",
  "POM": "https://assets.coingecko.com/coins/images/28246/thumb/Pom.jpeg",
  "UNLOCK": "https://assets.coingecko.com/coins/images/23646/thumb/unlock.png",
  "ZAPC": "https://assets.coingecko.com/coins/images/26535/thumb/zappay200.png",
  "STIK": "https://assets.coingecko.com/coins/images/29063/thumb/STIK.png",
  "KANG3N": "https://assets.coingecko.com/coins/images/23898/thumb/27C4962A-FC83-42E1-B644-2B6F66E4C3A2.png",
  "HFI": "https://assets.coingecko.com/coins/images/14045/thumb/8e113d8.png",
  "XTRI": "https://assets.coingecko.com/coins/images/25526/thumb/logo.png",
  "CBA": "https://assets.coingecko.com/coins/images/19939/thumb/s5y77FP1_400x400.jpg",
  "SPRING": "https://assets.coingecko.com/coins/images/25364/thumb/spring.png",
  "3SHARE": "https://assets.coingecko.com/coins/images/23079/thumb/96671285_%281%29.png",
  "READ": "https://assets.coingecko.com/coins/images/27073/thumb/read.png",
  "IGT": "https://assets.coingecko.com/coins/images/26924/thumb/output-onlinepngtools_%281%29.png",
  "GNNX": "https://assets.coingecko.com/coins/images/19938/thumb/gennix.PNG",
  "DMX": "https://assets.coingecko.com/coins/images/13476/thumb/dmmx.png",
  "BCX": "https://assets.coingecko.com/coins/images/1351/thumb/bitcoinx.png",
  "IOBUSD": "https://assets.coingecko.com/coins/images/19254/thumb/io14nhfkywdfvl40evgsqnh43ev33q6he8yez8c8a.png",
  "TNGL": "https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG",
  "GLK": "https://assets.coingecko.com/coins/images/19482/thumb/Groupe_1774_2x.png",
  "MEF": "https://assets.coingecko.com/coins/images/28752/thumb/_200x200_%28png%29white_logo_meflex.png",
  "NOS": "https://assets.coingecko.com/coins/images/28282/thumb/Nostra_200x200.png",
  "DRNT": "https://assets.coingecko.com/coins/images/28245/thumb/d-run_ticker_200x200.png",
  "BSCB": "https://assets.coingecko.com/coins/images/23998/thumb/bscb.png",
  "GOBLIN": "https://assets.coingecko.com/coins/images/28849/thumb/goblin200.png",
  "MEISHU": "https://assets.coingecko.com/coins/images/24112/thumb/LDtBuFcu_400x400.jpg",
  "SUMMER": "https://assets.coingecko.com/coins/images/25365/thumb/summer.png",
  "REAPER": "https://assets.coingecko.com/coins/images/19133/thumb/Reaper_Logo.png",
  "AFK": "https://assets.coingecko.com/coins/images/19697/thumb/crypto.PNG",
  "KUDO": "https://assets.coingecko.com/coins/images/16928/thumb/kudoge_200.png",
  "UPO": "https://assets.coingecko.com/coins/images/24900/thumb/f-WNwLNJ_400x400.jpg",
  "FLN": "https://assets.coingecko.com/coins/images/28501/thumb/logo-falcon.jpg",
  "NRGY": "https://assets.coingecko.com/coins/images/18075/thumb/nrgy.jpeg",
  "ASP": "https://assets.coingecko.com/coins/images/13048/thumb/box200.png",
  "FID": "https://assets.coingecko.com/coins/images/19446/thumb/nl0qESy3_400x400.jpg",
  "IFV": "https://assets.coingecko.com/coins/images/5279/thumb/INFLIV_LOGO_PNG.png",
  "DXF": "https://assets.coingecko.com/coins/images/13660/thumb/dxf-symbol.png",
  "AUTUMN": "https://assets.coingecko.com/coins/images/25366/thumb/autumn.png",
  "FUCHA": "https://assets.coingecko.com/coins/images/26200/thumb/fuchx300.png",
  "USD1": "https://assets.coingecko.com/coins/images/13661/thumb/Psyche-logo-256.png",
  "SLC": "https://assets.coingecko.com/coins/images/22115/thumb/solice_logo_200x200.png",
  "STRI": "https://assets.coingecko.com/coins/images/15056/thumb/strite.PNG",
  "YAC": "https://assets.coingecko.com/coins/images/2924/thumb/yacoin.png",
  "EDC": "https://assets.coingecko.com/coins/images/15157/thumb/Wechat-IMG33-1.png",
  "EPIX": "https://assets.coingecko.com/coins/images/28163/thumb/byepix.png",
  "MKA": "https://assets.coingecko.com/coins/images/19663/thumb/icon-mka_12.png",
  "JIGSAW": "https://assets.coingecko.com/coins/images/16306/thumb/Logo_200x200_%282%29.png",
  "PSTN": "https://assets.coingecko.com/coins/images/24868/thumb/TyVqDKYf_400x400.jpg",
  "ARNM": "https://assets.coingecko.com/coins/images/24393/thumb/6253dcf123d4f40001e9c793_CryptoCoin_1024.png",
  "CLEV": "https://assets.coingecko.com/coins/images/27727/thumb/%E6%88%AA%E5%B1%8F2022-10-12_%E4%B8%8A%E5%8D%8810.40.19.png",
  "ZSH": "missing_thumb.png",
  "OWL": "https://assets.coingecko.com/coins/images/26453/thumb/Jq6a3dLK_400x400.jpeg",
  "DONK": "https://assets.coingecko.com/coins/images/15330/thumb/donkey_logo.jpg",
  "AWO": "https://assets.coingecko.com/coins/images/15373/thumb/aiwork.PNG",
  "PAN": "https://assets.coingecko.com/coins/images/9543/thumb/pan-logo.png",
  "PERSIA": "https://assets.coingecko.com/coins/images/22856/thumb/rqOrKl3v_400x400.jpg",
  "ARTI": "https://assets.coingecko.com/coins/images/18878/thumb/arti.PNG",
  "CARMIN": "https://assets.coingecko.com/coins/images/29073/thumb/carmin.png",
  "PDX": "https://assets.coingecko.com/coins/images/25095/thumb/vBi23nWw_400x400.jpg",
  "MNS": "https://assets.coingecko.com/coins/images/11640/thumb/logo_%2880%29.png",
  "BNBP": "https://assets.coingecko.com/coins/images/27629/thumb/bnbp-logocircle.png",
  "YFO": "https://assets.coingecko.com/coins/images/13670/thumb/256.png",
  "WANXRP": "https://assets.coingecko.com/coins/images/23617/thumb/download-6.png",
  "SBT": "https://assets.coingecko.com/coins/images/27772/thumb/Logo_200.png",
  "ORYX": "https://assets.coingecko.com/coins/images/26146/thumb/NS6sVTy.jpg",
  "KDS": "https://assets.coingecko.com/coins/images/26970/thumb/kds.png",
  "XHI": "https://assets.coingecko.com/coins/images/572/thumb/hicoin.png",
  "N00D": "https://assets.coingecko.com/coins/images/27850/thumb/%E8%9E%A2%E5%B9%95%E6%88%AA%E5%9C%96_2022-10-17_%E4%B8%8B%E5%8D%888.36.07.png",
  "ULX": "https://assets.coingecko.com/coins/images/26977/thumb/ULX.png",
  "HIENS4": "https://assets.coingecko.com/coins/images/26921/thumb/62f9ec6fd1c44e000102051d_MicrosoftTeams-image.png",
  "DRIV": "https://assets.coingecko.com/coins/images/26648/thumb/N-ld8PAU_400x400.jpeg",
  "EDAT": "https://assets.coingecko.com/coins/images/20342/thumb/logo1.png",
  "CPR": "https://assets.coingecko.com/coins/images/22719/thumb/E-97BTDPNxk2PYsd8EwHzDbEpM6w-VoJc3t5DutqiPPydT57269nRUrxk8Pqig4CboNanb2Rd0MA8a0N9CGUmGG1jKdbzhy8pIwiDEGcPBP0mslchklUxO7BWSbDOv-ouncsR0aHjYGlG1hTWueUJOeqSLcVR90UKpCLhEWtJ6mS4o8_XQh57pEAEw_aiZmetQ9z0-rsla4JFo.jpg",
  "PEAX": "https://assets.coingecko.com/coins/images/18593/thumb/undefined_-_Imgur.jpg",
  "PHY": "https://assets.coingecko.com/coins/images/23087/thumb/PHY-icon-200x200.png",
  "XBX": "https://assets.coingecko.com/coins/images/26381/thumb/xblox-logo.png",
  "GPAY": "https://assets.coingecko.com/coins/images/20766/thumb/200x200_%281%29.jpg",
  "XLT": "https://assets.coingecko.com/coins/images/13420/thumb/XLT.png",
  "RBLX": "https://assets.coingecko.com/coins/images/3253/thumb/rblx.png",
  "HIBAYC": "https://assets.coingecko.com/coins/images/26623/thumb/hibayc.png",
  "BSCD": "https://assets.coingecko.com/coins/images/24753/thumb/BSCD.png",
  "SUZA": "https://assets.coingecko.com/coins/images/27055/thumb/630593a38b3a0019bc34a746_120x120_trans.png",
  "KSN": "https://assets.coingecko.com/coins/images/26414/thumb/200x200-2.png",
  "PCKT": "https://assets.coingecko.com/coins/images/17831/thumb/pckt_twitter.jpg",
  "MCDOGE": "https://assets.coingecko.com/coins/images/23096/thumb/IL1fIBa.png",
  "RUM": "https://assets.coingecko.com/coins/images/27656/thumb/rum.png",
  "EXD": "https://assets.coingecko.com/coins/images/28684/thumb/logo-exorde.png",
  "CODE7": "https://assets.coingecko.com/coins/images/24150/thumb/code7.png",
  "EMP": "https://assets.coingecko.com/coins/images/24422/thumb/emp_logo_200.png",
  "TITANO": "https://assets.coingecko.com/coins/images/20542/thumb/CGTitano.png",
  "POL": "https://assets.coingecko.com/coins/images/11716/thumb/01awRmEP_400x400_%281%29.png",
  "DLN": "https://assets.coingecko.com/coins/images/7982/thumb/logo250.png",
  "ZEND": "https://assets.coingecko.com/coins/images/27922/thumb/zend.png",
  "CEDS": "https://assets.coingecko.com/coins/images/12255/thumb/cedars.png",
  "BX": "https://assets.coingecko.com/coins/images/26765/thumb/200x200.jpg",
  "ATH": "https://assets.coingecko.com/coins/images/26922/thumb/logo.png",
  "APP": "https://assets.coingecko.com/coins/images/20605/thumb/sapp.PNG",
  "QIQ": "https://assets.coingecko.com/coins/images/17905/thumb/qiq.PNG",
  "KRO": "https://assets.coingecko.com/coins/images/27166/thumb/logo.png",
  "DEUSDC": "https://assets.coingecko.com/coins/images/26669/thumb/deUSDC.png",
  "XCRE": "https://assets.coingecko.com/coins/images/8032/thumb/logoXCRE.png",
  "PAT": "https://assets.coingecko.com/coins/images/3169/thumb/patron.png",
  "SCT": "https://assets.coingecko.com/coins/images/19382/thumb/MmOXdygU_400x400.jpg",
  "AMC": "https://assets.coingecko.com/coins/images/17290/thumb/amc.PNG",
  "MKITTY": "https://assets.coingecko.com/coins/images/22180/thumb/kitty_matic.6e35abf7.png",
  "PAYN": "https://assets.coingecko.com/coins/images/15712/thumb/PAYNET-LOGO.jpg",
  "KIND": "https://assets.coingecko.com/coins/images/27434/thumb/200x200_Logo_Kindly_Symbol_Green_White-Inside_Green_Transparent.png",
  "MNR": "https://assets.coingecko.com/coins/images/10996/thumb/D1QOxGkyeNFNxGtTkisHA21_2qUJiiIv3GbcgYln-PJUxMpwSJZzYCllVtbO0en-udpN7c94Llfby0e9dtjyDWlEqcRnssNIW2K2-s-MJqSXavmq2M4ry9CxSwWMWvNVAnqWzvvNWfLYz1FdzZZcgIX0mkveK8rLMsLAfYmhXEWrz8foPKHgSuJf8eNG7wPetoC0Mycyb5r0z46.jpg",
  "URUB": "https://assets.coingecko.com/coins/images/19902/thumb/54UYxwFE_400x400.jpg",
  "FAI": "https://assets.coingecko.com/coins/images/13894/thumb/logo-65ce48ea8d2b64b6478a42c0050214e2.png",
  "KRRX": "https://assets.coingecko.com/coins/images/21643/thumb/GHj1BMqu_400x400.jpg",
  "FREL": "https://assets.coingecko.com/coins/images/15856/thumb/freela.PNG",
  "GOV": "https://assets.coingecko.com/coins/images/22261/thumb/govworld.PNG",
  "BOLE": "https://assets.coingecko.com/coins/images/16317/thumb/Bole.png",
  "XMATIC": "https://assets.coingecko.com/coins/images/28333/thumb/XMATIC.png",
  "99DEFI": "https://assets.coingecko.com/coins/images/25649/thumb/99defiicon.png",
  "TAVA": "https://assets.coingecko.com/coins/images/26299/thumb/altava.jpeg",
  "SWAMP": "https://assets.coingecko.com/coins/images/10671/thumb/logo_green_transparent.png",
  "ILC": "https://assets.coingecko.com/coins/images/6238/thumb/image0.jpeg",
  "WWEMIX": "https://assets.coingecko.com/coins/images/28633/thumb/wemix.png",
  "BCE": "https://assets.coingecko.com/coins/images/25836/thumb/BCE.png",
  "CSUSHI": "https://assets.coingecko.com/coins/images/17529/thumb/csushi.PNG",
  "KDX": "https://assets.coingecko.com/coins/images/26599/thumb/biq_jmvv_400x400.jpg",
  "SHITZU": "https://assets.coingecko.com/coins/images/24857/thumb/sFChtaUA_400x400.jpg",
  "ZOOSHI": "https://assets.coingecko.com/coins/images/17609/thumb/another.png",
  "SHOD": "https://assets.coingecko.com/coins/images/27869/thumb/ShopdiLogoPNG-01_%281%29.png",
  "USHARE": "https://assets.coingecko.com/coins/images/22031/thumb/UShare.png",
  "TOKR": "https://assets.coingecko.com/coins/images/28337/thumb/Tokerr_Logo.png",
  "GENART": "https://assets.coingecko.com/coins/images/23055/thumb/token_logo.jpeg",
  "DEGENS": "https://assets.coingecko.com/coins/images/14526/thumb/degens_logo.png",
  "XNC": "https://assets.coingecko.com/coins/images/10322/thumb/xenios.png",
  "CONJ": "https://assets.coingecko.com/coins/images/22360/thumb/VAfhxZ9__400x400.jpg",
  "LEVELG": "https://assets.coingecko.com/coins/images/11134/thumb/Untitled-design-removebg-preview.png",
  "AVWETH": "https://assets.coingecko.com/coins/images/26671/thumb/aave.png",
  "SPEX": "https://assets.coingecko.com/coins/images/2888/thumb/sproutsextreme.png",
  "BREX": "https://assets.coingecko.com/coins/images/27841/thumb/Icon_3840_%283%29.png",
  "EPILLO": "https://assets.coingecko.com/coins/images/28846/thumb/2CyKXwGy_400x400.jpg",
  "$DWAGON": "https://assets.coingecko.com/coins/images/27480/thumb/logoblk.png",
  "BRT": "https://assets.coingecko.com/coins/images/25677/thumb/X0ZVax0H_400x400.jpg",
  "CRS": "https://assets.coingecko.com/coins/images/28960/thumb/AB268A5A-5DBC-4A4A-B4FC-605DFC4B54FE.png",
  "STARLY": "https://assets.coingecko.com/coins/images/23713/thumb/15139.png",
  "SUNEKU": "https://assets.coingecko.com/coins/images/26672/thumb/Logo_%286%29.png",
  "XQK": "https://assets.coingecko.com/coins/images/23530/thumb/16072.png",
  "H1": "https://assets.coingecko.com/coins/images/28771/thumb/haven1-colored-symbol_2x_%281%29.png",
  "IQQ": "https://assets.coingecko.com/coins/images/14672/thumb/IQONIQ-Cointransparant.png",
  "XPERPS": "https://assets.coingecko.com/coins/images/27270/thumb/xperps.png",
  "XBTG": "https://assets.coingecko.com/coins/images/368/thumb/bitgem.png",
  "DYN": "https://assets.coingecko.com/coins/images/697/thumb/b3EIxPB.png",
  "ZOOPAD": "https://assets.coingecko.com/coins/images/26907/thumb/photo_2022-08-13_00-47-24.png",
  "BTE": "https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png",
  "MIIDAS": "https://assets.coingecko.com/coins/images/26286/thumb/logo_miidas_round_500.png",
  "TGDAO": "https://assets.coingecko.com/coins/images/22834/thumb/JjRiXuqw_400x400.jpg",
  "GFCE": "https://assets.coingecko.com/coins/images/14475/thumb/gforce_logo.png",
  "USG": "https://assets.coingecko.com/coins/images/11952/thumb/us-gold-cube-transparent-bg-200x.png",
  "VANCAT": "https://assets.coingecko.com/coins/images/14597/thumb/1vDw61E.png",
  "SLRR": "https://assets.coingecko.com/coins/images/25624/thumb/Solarr_Emblem_Lockup_Colour_noborder.png",
  "BUH": "https://assets.coingecko.com/coins/images/27835/thumb/CG.jpeg",
  "IOUSDT": "https://assets.coingecko.com/coins/images/19345/thumb/io1d77dcytfk5fsck089eg7669gfpqunrx35ue5ln.png",
  "ZINA": "https://assets.coingecko.com/coins/images/23984/thumb/zina.png",
  "RIO": "https://assets.coingecko.com/coins/images/12206/thumb/0.jpg",
  "AIRTNT": "https://assets.coingecko.com/coins/images/28328/thumb/airtnt.png",
  "BZZONE": "https://assets.coingecko.com/coins/images/17784/thumb/IMG-20210817-182009-762.png",
  "ABI": "https://assets.coingecko.com/coins/images/22331/thumb/abachi.PNG",
  "VSW": "https://assets.coingecko.com/coins/images/26252/thumb/Venera-Swap-Token-Logo-Black-200x200-coingecko.png",
  "HOPERS": "https://assets.coingecko.com/coins/images/28816/thumb/resized.png",
  "PICIPO": "https://assets.coingecko.com/coins/images/18758/thumb/kEpQ95VG_400x400.jpg",
  "$YOUB": "missing_thumb.png",
  "TUNDRA": "https://assets.coingecko.com/coins/images/17987/thumb/Copy-of-wavax-dai-e.png",
  "DTR": "https://assets.coingecko.com/coins/images/25512/thumb/v2j0H_8J_400x400.jpg",
  "PIXEOS": "https://assets.coingecko.com/coins/images/9116/thumb/57129657_1849603145145589_3936675094033596416_n.png",
  "WDEX": "https://assets.coingecko.com/coins/images/27613/thumb/photo_2022-09-23_06-49-58.jpg",
  "SKRP": "https://assets.coingecko.com/coins/images/7987/thumb/60EnDfxK_400x400.jpg",
  "DFA": "https://assets.coingecko.com/coins/images/17579/thumb/define.PNG",
  "ETT": "https://assets.coingecko.com/coins/images/28595/thumb/energy_trade_logo_icon_001.png",
  "XSAUCE": "https://assets.coingecko.com/coins/images/28569/thumb/xSAUCE.png",
  "ORBIT": "https://assets.coingecko.com/coins/images/20238/thumb/orbit.png",
  "TCAT": "https://assets.coingecko.com/coins/images/27632/thumb/rsz_ewewewrwr.png",
  "BEE": "https://assets.coingecko.com/coins/images/25626/thumb/bee.png",
  "XJEWEL": "https://assets.coingecko.com/coins/images/24830/thumb/jewel.png",
  "RFX": "https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png",
  "SPRINK": "https://assets.coingecko.com/coins/images/14345/thumb/sprink200_200.png",
  "NT": "https://assets.coingecko.com/coins/images/17415/thumb/nextype.PNG",
  "GLF": "https://assets.coingecko.com/coins/images/28609/thumb/glx200.png",
  "EMPIRE": "https://assets.coingecko.com/coins/images/16390/thumb/pb_ranDy_400x400.png",
  "SHAN": "https://assets.coingecko.com/coins/images/27559/thumb/shanlogo.png",
  "GREENS": "https://assets.coingecko.com/coins/images/27553/thumb/download_%286%29.png",
  "NSH": "https://assets.coingecko.com/coins/images/22003/thumb/Screenshot-2021-12-26-at-22-12-23.png",
  "GUNTHY": "https://assets.coingecko.com/coins/images/7869/thumb/GUNTHY_128.png",
  "DINGER": "https://assets.coingecko.com/coins/images/19443/thumb/dinger.png",
  "XSH": "https://assets.coingecko.com/coins/images/22168/thumb/QKhXXjAc_400x400.jpg",
  "METANO": "https://assets.coingecko.com/coins/images/28183/thumb/logo200x.jpeg",
  "BECN": "https://assets.coingecko.com/coins/images/7068/thumb/beacon.jpg",
  "NOONE": "https://assets.coingecko.com/coins/images/19393/thumb/logo-200x200_%289%29.png",
  "APEUSD": "https://assets.coingecko.com/coins/images/25621/thumb/ApeUSD.png",
  "DRYP": "https://assets.coingecko.com/coins/images/25569/thumb/dryp.png",
  "VOO": "https://assets.coingecko.com/coins/images/22827/thumb/WhatsApp_Image_2021-11-02_at_16.53.29.jpeg",
  "LYPTUS": "https://assets.coingecko.com/coins/images/15992/thumb/logo_-_2021-06-01T193417.635.png",
  "ELU": "https://assets.coingecko.com/coins/images/24433/thumb/me4oOqTM_400x400.png",
  "MGP": "https://assets.coingecko.com/coins/images/22011/thumb/https-Mpg-com-Logo.jpg",
  "NFTM": "https://assets.coingecko.com/coins/images/22151/thumb/nftime.PNG",
  "GOO": "https://assets.coingecko.com/coins/images/28065/thumb/art-gobblers-goo.png",
  "VMT": "https://assets.coingecko.com/coins/images/27953/thumb/Vemate.jpeg",
  "SGNL": "https://assets.coingecko.com/coins/images/25530/thumb/62438bf59cc45e15c884bb49_coin.png",
  "AKA": "https://assets.coingecko.com/coins/images/4455/thumb/akroma-logo-1000x1000_preview.png",
  "CBT": "https://assets.coingecko.com/coins/images/14701/thumb/CBT-Token-Small.png",
  "DOGGER": "https://assets.coingecko.com/coins/images/25460/thumb/GC94Zwub_400x400.jpg",
  "SOLUST": "https://assets.coingecko.com/coins/images/25372/thumb/solust.png",
  "POPK": "https://assets.coingecko.com/coins/images/24190/thumb/9Arv4u3c_400x400.jpeg",
  "BZEN": "https://assets.coingecko.com/coins/images/27311/thumb/bzen.png",
  "D11": "https://assets.coingecko.com/coins/images/18174/thumb/wjuVH0sC_400x400.jpg",
  "CHEDDA": "https://assets.coingecko.com/coins/images/22654/thumb/cMckil70_400x400.jpg",
  "KZC": "https://assets.coingecko.com/coins/images/2554/thumb/a007ff4c0b00.png",
  "SENSEI": "https://assets.coingecko.com/coins/images/18281/thumb/sensei.png",
  "DOOGEE": "https://assets.coingecko.com/coins/images/17097/thumb/logo200x200_%287%29.png",
  "SIMPLY": "https://assets.coingecko.com/coins/images/20650/thumb/simply.PNG",
  "SHOE": "https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg",
  "RA": "https://assets.coingecko.com/coins/images/27933/thumb/IMG-20221016-WA0017.png",
  "AIRPAY": "https://assets.coingecko.com/coins/images/23782/thumb/ys_Kuqq6_400x400.jpg",
  "WINTER": "https://assets.coingecko.com/coins/images/25367/thumb/winter.png",
  "ALG": "https://assets.coingecko.com/coins/images/12231/thumb/logo-2.png",
  "NRFX": "https://assets.coingecko.com/coins/images/26178/thumb/image_6483441.JPG",
  "TR3": "https://assets.coingecko.com/coins/images/21980/thumb/9T9OoYXE_400x400.jpg",
  "VNDT": "https://assets.coingecko.com/coins/images/18101/thumb/vndt.PNG",
  "LMN": "https://assets.coingecko.com/coins/images/21057/thumb/lemonn-logo-200x200.png",
  "UPR": "https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png",
  "QTZ": "https://assets.coingecko.com/coins/images/23648/thumb/Q_logo-short_dark.png",
  "QWT": "https://assets.coingecko.com/coins/images/23415/thumb/uJSK4tOY_400x400.jpg",
  "GEMS": "https://assets.coingecko.com/coins/images/28141/thumb/logo.png",
  "ZAMZAM": "https://assets.coingecko.com/coins/images/22671/thumb/d7168acd189f475ea38e113af81ebf41.png",
  "HIFLUF": "https://assets.coingecko.com/coins/images/28602/thumb/hifluf.png",
  "DEFIDO": "https://assets.coingecko.com/coins/images/18861/thumb/defido.png",
  "ESP": "https://assets.coingecko.com/coins/images/468/thumb/logo.png",
  "WANETH": "https://assets.coingecko.com/coins/images/23614/thumb/download-3.png",
  "YOC": "https://assets.coingecko.com/coins/images/341/thumb/yocoin.png",
  "KABOSU": "https://assets.coingecko.com/coins/images/15396/thumb/o7LLTW8.png",
  "BSY": "https://assets.coingecko.com/coins/images/12166/thumb/photo-2019-07-04-17-03-29.jpg",
  "YOOSHI": "https://assets.coingecko.com/coins/images/15614/thumb/logo.png",
  "RPZX": "https://assets.coingecko.com/coins/images/7663/thumb/rapidzpay-logo.jpg",
  "DIN": "https://assets.coingecko.com/coins/images/3652/thumb/dinero.png",
  "$BLOW": "https://assets.coingecko.com/coins/images/18962/thumb/Blowup-2.png",
  "NIT": "https://assets.coingecko.com/coins/images/23398/thumb/nit.png",
  "WHX": "https://assets.coingecko.com/coins/images/14557/thumb/output-onlinepngtools_%282%29.png",
  "ICMX": "https://assets.coingecko.com/coins/images/24137/thumb/FzPtoutf_400x400.jpg",
  "QDX": "https://assets.coingecko.com/coins/images/16032/thumb/3k5qGOw1_400x400.jpg",
  "AGE": "https://assets.coingecko.com/coins/images/15841/thumb/logo_-_2021-05-27T111102.846.png",
  "IVI": "https://assets.coingecko.com/coins/images/10372/thumb/Bvp7U25U_400x400.jpg",
  "$WNZ": "https://assets.coingecko.com/coins/images/24786/thumb/wnz.png",
  "DEK": "https://assets.coingecko.com/coins/images/15968/thumb/DEKbox-02%281%29.png",
  "FRL": "https://assets.coingecko.com/coins/images/28442/thumb/Logo.jpg",
  "ATX": "https://assets.coingecko.com/coins/images/28975/thumb/21908_1_.png",
  "ETHTZ": "https://assets.coingecko.com/coins/images/19466/thumb/ethtz.png",
  "CREAMY": "https://assets.coingecko.com/coins/images/25285/thumb/logo200.png",
  "INO": "https://assets.coingecko.com/coins/images/19650/thumb/ino-512.png",
  "GIOTTO": "https://assets.coingecko.com/coins/images/26948/thumb/Bozza_1_200x200px_FRONTE.png",
  "TELE": "https://assets.coingecko.com/coins/images/26485/thumb/WhatsApp_Image_2022-07-20_at_2.51.42_AM.jpeg",
  "ONTP": "https://assets.coingecko.com/coins/images/27496/thumb/ezgif.com-gif-maker.jpg",
  "CYCLUB": "https://assets.coingecko.com/coins/images/12524/thumb/mcicoin-logo.png",
  "FTR": "https://assets.coingecko.com/coins/images/17316/thumb/logo_-_2021-07-26T164152.450.png",
  "XYM": "https://assets.coingecko.com/coins/images/14411/thumb/xym.png",
  "HPX": "https://assets.coingecko.com/coins/images/9422/thumb/HUPAYX-HPX-Logo-New.png",
  "SHPING": "https://assets.coingecko.com/coins/images/2588/thumb/000103_Shping_Logos_Shping_Secondary_Logo.png",
  "RB": "https://assets.coingecko.com/coins/images/28994/thumb/rabbit.JPG",
  "ALLIN": "https://assets.coingecko.com/coins/images/28411/thumb/IMG_20221206_124556_908.png",
  "VNY": "https://assets.coingecko.com/coins/images/15899/thumb/GGkuplD_%281%29.png",
  "HOICHI": "https://assets.coingecko.com/coins/images/27227/thumb/3dBs4VvW_400x400.jpeg",
  "HIENS3": "https://assets.coingecko.com/coins/images/27262/thumb/hiens3.png",
  "SPORTY": "https://assets.coingecko.com/coins/images/27116/thumb/rsz-img-20220827-180356-374.png",
  "SVA": "https://assets.coingecko.com/coins/images/25958/thumb/FUyxC4jE_400x400.jpg",
  "AGLA": "https://assets.coingecko.com/coins/images/27547/thumb/8SGgovDI_400x400.png",
  "OCX": "https://assets.coingecko.com/coins/images/28130/thumb/200x200.png",
  "YARL": "https://assets.coingecko.com/coins/images/19077/thumb/yarloo.png",
  "TX": "https://assets.coingecko.com/coins/images/28997/thumb/Tradix_Logo.png",
  "AAPX": "https://assets.coingecko.com/coins/images/14303/thumb/AAPX.png",
  "LUMIII": "https://assets.coingecko.com/coins/images/27424/thumb/lumiii-icon-200x200.png",
  "PAA": "https://assets.coingecko.com/coins/images/11141/thumb/da443cb2601f5fbdab9a2e176d62d6ce.png",
  "DR1$": "https://assets.coingecko.com/coins/images/26497/thumb/7cagE5c3_400x400.jpeg",
  "BAAS": "https://assets.coingecko.com/coins/images/5463/thumb/baasid.png",
  "WANBTC": "https://assets.coingecko.com/coins/images/23615/thumb/download-4.png",
  "GAC": "https://assets.coingecko.com/coins/images/22453/thumb/gacube.PNG",
  "ORIO": "https://assets.coingecko.com/coins/images/19749/thumb/8kZBYEzC_400x400.jpg",
  "HDRN": "https://assets.coingecko.com/coins/images/27499/thumb/hedron.png",
  "VLTY": "https://assets.coingecko.com/coins/images/17779/thumb/vault.PNG",
  "GLDX": "https://assets.coingecko.com/coins/images/17814/thumb/gldx-logo-200x200.png",
  "POTATO": "https://assets.coingecko.com/coins/images/18254/thumb/logocircle.png",
  "ROAR": "https://assets.coingecko.com/coins/images/20693/thumb/logo_-_2021-11-22T144644.880.png",
  "BLOCKS": "https://assets.coingecko.com/coins/images/19666/thumb/BLOCKS_200x200_logo_%282%29..png",
  "TETUQI": "https://assets.coingecko.com/coins/images/28314/thumb/tetuQi.png",
  "HEART": "https://assets.coingecko.com/coins/images/21273/thumb/h_logo_1x.png",
  "NBR": "https://assets.coingecko.com/coins/images/3474/thumb/niobio-cash.png",
  "EGX": "https://assets.coingecko.com/coins/images/23082/thumb/imgonline-com-ua-Resize-LHUSvWAko1XuD.png",
  "TARM": "https://assets.coingecko.com/coins/images/28935/thumb/Tarmex_Logo.png",
  "ULAB": "https://assets.coingecko.com/coins/images/22330/thumb/unilab-logo-black-text-200.png",
  "ADON": "https://assets.coingecko.com/coins/images/26793/thumb/Jugvt5z5_400x400.jpeg",
  "DXB": "https://assets.coingecko.com/coins/images/19681/thumb/13590.png",
  "WLX": "https://assets.coingecko.com/coins/images/28929/thumb/200x200.png",
  "ABIC": "https://assets.coingecko.com/coins/images/19549/thumb/abic.png",
  "CLXY": "https://assets.coingecko.com/coins/images/25638/thumb/CLXY_logo_sm.png",
  "PRIV": "https://assets.coingecko.com/coins/images/4537/thumb/HO6qLou.png",
  "KOLNET": "https://assets.coingecko.com/coins/images/26064/thumb/Icon_color2.png",
  "KAINET": "https://assets.coingecko.com/coins/images/19071/thumb/kaiinu.1d3b6110.png",
  "FBB": "https://assets.coingecko.com/coins/images/21119/thumb/h6g4-dsp_400x400.jpg",
  "LCNT": "https://assets.coingecko.com/coins/images/9911/thumb/lucentGold-96.png",
  "RACEFI": "https://assets.coingecko.com/coins/images/21873/thumb/XIF9z8Z6_400x400.jpg",
  "UAC": "https://assets.coingecko.com/coins/images/20662/thumb/udacity_logo_icon_169367.png",
  "MUG": "https://assets.coingecko.com/coins/images/24885/thumb/mugold1-1-.png",
  "BTCM": "https://assets.coingecko.com/coins/images/1081/thumb/btcmoon.png",
  "RIM": "https://assets.coingecko.com/coins/images/23904/thumb/rim.png",
  "ATHENASV2": "https://assets.coingecko.com/coins/images/23883/thumb/banner-atn-2.png",
  "COM": "https://assets.coingecko.com/coins/images/28723/thumb/strongestshapetransparent-200h.png",
  "MAIR": "https://assets.coingecko.com/coins/images/23850/thumb/MetaAir.png",
  "DATE": "https://assets.coingecko.com/coins/images/18461/thumb/date.PNG",
  "TREND": "https://assets.coingecko.com/coins/images/23828/thumb/Uptrend200x200.jpg",
  "PROTO": "https://assets.coingecko.com/coins/images/22916/thumb/1_d8Ut8seuWstkmozGddqsrg.png",
  "COHM": "https://assets.coingecko.com/coins/images/28934/thumb/coingeckocantohm.png",
  "VIVAION": "https://assets.coingecko.com/coins/images/22797/thumb/zCKgUOy13nTdqluJ1VWG2aqDfMgD4axJt_g4C9T-W3I.png",
  "VIM": "https://assets.coingecko.com/coins/images/25188/thumb/1_trans.png",
  "TKNFY": "https://assets.coingecko.com/coins/images/22901/thumb/tknfy.png",
  "MBP": "https://assets.coingecko.com/coins/images/26082/thumb/20182.png",
  "$DBET": "https://assets.coingecko.com/coins/images/23125/thumb/R4y30VWE_400x400.jpg",
  "FBX": "https://assets.coingecko.com/coins/images/24117/thumb/financeblocksshadow.png",
  "SYNOPTI": "https://assets.coingecko.com/coins/images/29077/thumb/Synopti_200x200.png",
  "PTX": "https://assets.coingecko.com/coins/images/24697/thumb/SVG_16472322519188426X.jpg",
  "HARAMBE": "https://assets.coingecko.com/coins/images/23489/thumb/unnamed.jpg",
  "LZM": "https://assets.coingecko.com/coins/images/29068/thumb/_LoungeM_Logo.png",
  "$DEFI": "https://assets.coingecko.com/coins/images/24469/thumb/_defi.png",
  "NAGI": "https://assets.coingecko.com/coins/images/24129/thumb/photo_2022-03-04_23-02-56.jpg",
  "KFL": "https://assets.coingecko.com/coins/images/23670/thumb/Kaafila--logo.png",
  "$LOBSTER": "https://assets.coingecko.com/coins/images/28804/thumb/100_.png",
  "JOT": "https://assets.coingecko.com/coins/images/28220/thumb/jot-blue_gradient_bg2.png",
  "ZED": "https://assets.coingecko.com/coins/images/26607/thumb/zed-run.jpeg",
  "AQDC": "https://assets.coingecko.com/coins/images/25771/thumb/aqdc.png",
  "KKMA": "https://assets.coingecko.com/coins/images/28745/thumb/logo_200.png",
  "MNTE": "https://assets.coingecko.com/coins/images/28774/thumb/Mintera_Logo_CoinGecko.png",
  "HBIT": "https://assets.coingecko.com/coins/images/16375/thumb/Coin_Design_2_200.png",
  "PYN": "https://assets.coingecko.com/coins/images/2008/thumb/paycentos-logo-maroon.png",
  "BDO": "https://assets.coingecko.com/coins/images/13487/thumb/bdollar-yellow.png",
  "ECP": "https://assets.coingecko.com/coins/images/15111/thumb/eclipse.PNG",
  "TOMI": "https://assets.coingecko.com/coins/images/28730/thumb/logo_for_token.png",
  "LEXE": "https://assets.coingecko.com/coins/images/29066/thumb/669fbb3b-8f61-4f3d-8ced-c9d47babcae4.png",
  "MMA": "https://assets.coingecko.com/coins/images/23767/thumb/18166.png",
  "CAJ": "https://assets.coingecko.com/coins/images/7354/thumb/RuHhMsFd_400x400.png",
  "WHEN": "https://assets.coingecko.com/coins/images/4136/thumb/when.png",
  "CVSHOT": "https://assets.coingecko.com/coins/images/25875/thumb/cvshot.png",
  "BINS": "https://assets.coingecko.com/coins/images/18160/thumb/200x200_%281%29.png",
  "$ZKN": "https://assets.coingecko.com/coins/images/28610/thumb/_ZKN.jpg",
  "MEOW": "https://assets.coingecko.com/coins/images/28810/thumb/AdZJegQB_400x400.jpg",
  "BTV": "https://assets.coingecko.com/coins/images/20681/thumb/bitvalve-icon-200x200.png",
  "MQL": "https://assets.coingecko.com/coins/images/12278/thumb/200_200_%EB%AF%B8%EB%9D%BC%ED%81%B4_%EB%A1%9C%EA%B3%A0.png",
  "HLN": "https://assets.coingecko.com/coins/images/28510/thumb/Holonus-HLN.png",
  "PALM": "https://assets.coingecko.com/coins/images/27604/thumb/22000.png",
  "PFT": "https://assets.coingecko.com/coins/images/27223/thumb/payflow.png",
  "PICA": "https://assets.coingecko.com/coins/images/28286/thumb/PICA.png",
  "AXLWBTC": "https://assets.coingecko.com/coins/images/28173/thumb/axlwbtc.png",
  "SPRTS": "https://assets.coingecko.com/coins/images/435/thumb/xTEzjFG.png",
  "SXC": "https://assets.coingecko.com/coins/images/27819/thumb/coingesxc.png",
  "FEY": "https://assets.coingecko.com/coins/images/13689/thumb/1_XiKKk5_400x400.jpg",
  "BAFE": "https://assets.coingecko.com/coins/images/15120/thumb/bafeio_logo.png",
  "CROWN": "https://assets.coingecko.com/coins/images/21182/thumb/IMG-20211202-223646-744.jpg",
  "HIAZUKI": "https://assets.coingecko.com/coins/images/28565/thumb/hiazuki.png",
  "DKYC": "https://assets.coingecko.com/coins/images/7123/thumb/1btu7Lp__400x400.jpg",
  "NKPL": "https://assets.coingecko.com/coins/images/28182/thumb/logo_200x200.png",
  "NAX": "https://assets.coingecko.com/coins/images/9929/thumb/nax_logo.png",
  "NET": "https://assets.coingecko.com/coins/images/36/thumb/netcoin.png",
  "DAE": "https://assets.coingecko.com/coins/images/28107/thumb/ticker_200x200.png",
  "OGX": "https://assets.coingecko.com/coins/images/12269/thumb/uvdOryl3j5LF89B.png",
  "EUM": "https://assets.coingecko.com/coins/images/7874/thumb/elitium-coin.png",
  "MPT": "https://assets.coingecko.com/coins/images/14323/thumb/Tqlsvhn1_400x400.png",
  "BABYETH": "https://assets.coingecko.com/coins/images/17891/thumb/BabyEth_Ticker.png",
  "CBOMBER": "https://assets.coingecko.com/coins/images/27060/thumb/bomb_symbol200.png",
  "HAM": "https://assets.coingecko.com/coins/images/16115/thumb/hamster.png",
  "EQ9": "https://assets.coingecko.com/coins/images/28233/thumb/1648392274941.jpeg",
  "ZFM": "https://assets.coingecko.com/coins/images/28232/thumb/20221106_193725.png",
  "BLU": "https://assets.coingecko.com/coins/images/96/thumb/bluecoin.png",
  "SBX": "https://assets.coingecko.com/coins/images/28028/thumb/logo_200.png",
  "GOSU": "https://assets.coingecko.com/coins/images/28027/thumb/1666426374596-d0096ec6c83575373e3a21d129ff8fef.jpg",
  "MARKS": "https://assets.coingecko.com/coins/images/125/thumb/bitmark.png",
  "EID": "https://assets.coingecko.com/coins/images/28081/thumb/jWUF0xEF_400x400.jpeg",
  "ALCAZAR": "https://assets.coingecko.com/coins/images/28010/thumb/Alcazar.jpeg",
  "WEZ": "https://assets.coingecko.com/coins/images/28265/thumb/Logo_WEZ_png.png",
  "TTY": "https://assets.coingecko.com/coins/images/6128/thumb/AdeZHR8__400x400.png",
  "I9C": "https://assets.coingecko.com/coins/images/12399/thumb/Vvm9V6YM_400x400.png",
  "SWAT": "https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg",
  "LEZ": "https://assets.coingecko.com/coins/images/21185/thumb/peoplez.PNG",
  "LIL": "https://assets.coingecko.com/coins/images/16042/thumb/lillion.PNG",
  "X0Z": "https://assets.coingecko.com/coins/images/8642/thumb/x0z.png",
  "CELC": "https://assets.coingecko.com/coins/images/6078/thumb/celcoin-logo.png",
  "IGR": "https://assets.coingecko.com/coins/images/25972/thumb/logo.png",
  "VIC": "https://assets.coingecko.com/coins/images/25963/thumb/vic.png",
  "OFF": "https://assets.coingecko.com/coins/images/23312/thumb/pe6yIPvb_400x400.jpg",
  "GIF": "https://assets.coingecko.com/coins/images/19470/thumb/gif.png",
  "DAIK": "https://assets.coingecko.com/coins/images/18599/thumb/daik.png",
  "DOTK": "https://assets.coingecko.com/coins/images/18603/thumb/dotk.png",
  "MNC": "https://assets.coingecko.com/coins/images/98/thumb/mincoin.png",
  "FILK": "https://assets.coingecko.com/coins/images/18607/thumb/filk.png",
  "IBFR": "https://assets.coingecko.com/coins/images/27782/thumb/Qk6pjeZ3_400x400.jpeg",
  "MAPC": "https://assets.coingecko.com/coins/images/313/thumb/mapcoin.png",
  "XMV": "https://assets.coingecko.com/coins/images/4823/thumb/monerov-logo.jpg",
  "BAXS": "https://assets.coingecko.com/coins/images/7732/thumb/MaUNF_dJFGQujn1_y5zYWG_U1NCglt4yBsjyftdpPr5H7LqTsu0XEqcKnnRWcjTUA0DsOLCr1Ekhe37__Wpx83X29R-nbs0UTxz5eElpzw7Mcl0y8-ReYLck8dNYAxJpXG1k48pA4RousepQFj7H9JKq09h5Z4EzBwnJf2P6qFWALryESUUmAOmtByKUD5WoCWI4DIlRorsWSbbl6.jpg",
  "CHEELEE": "https://assets.coingecko.com/coins/images/28573/thumb/CHEEL-200x200.png",
  "FRZW": "https://assets.coingecko.com/coins/images/27701/thumb/Logo_.png",
  "BNODE": "https://assets.coingecko.com/coins/images/8390/thumb/NwO7goPW_400x400.png",
  "MUME": "https://assets.coingecko.com/coins/images/28431/thumb/mu-meme-logo.png",
  "CYFM": "https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png",
  "JAR": "https://assets.coingecko.com/coins/images/8774/thumb/3jmUWB3e_400x400.jpg",
  "LTCK": "https://assets.coingecko.com/coins/images/18582/thumb/ltck.png",
  "0XT": "https://assets.coingecko.com/coins/images/27557/thumb/0xlogo.png",
  "SMTX": "https://assets.coingecko.com/coins/images/27478/thumb/Sumotex_telegram_image_PNG.jpg",
  "BABYBNB": "https://assets.coingecko.com/coins/images/16780/thumb/Babybnb-logo-200px.png",
  "BEFX": "https://assets.coingecko.com/coins/images/9547/thumb/belifex.png",
  "MNMC": "https://assets.coingecko.com/coins/images/11163/thumb/MNMC.png",
  "DEQ": "https://assets.coingecko.com/coins/images/8131/thumb/logo_%285%29.png",
  "SHIBO": "https://assets.coingecko.com/coins/images/22215/thumb/shibonk-MAINLOGO-CG.png",
  "JDC": "https://assets.coingecko.com/coins/images/9958/thumb/Cv-2ca6p_400x400.jpg",
  "M-X": "https://assets.coingecko.com/coins/images/27380/thumb/musclex_logo-14.png",
  "XWLRS": "https://assets.coingecko.com/coins/images/27728/thumb/2.png",
  "TRFX": "https://assets.coingecko.com/coins/images/27052/thumb/triflex.png",
  "POKERFI": "https://assets.coingecko.com/coins/images/18907/thumb/oy6nhrI0_400x400.jpg",
  "CNYX": "https://assets.coingecko.com/coins/images/22771/thumb/_OEJ9P2s_400x400.jpg",
  "HMR": "https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg",
  "CCXX": "https://assets.coingecko.com/coins/images/9843/thumb/coin-gold-300x300-20191106-min.png",
  "SODATSU": "https://assets.coingecko.com/coins/images/28061/thumb/SODATSU_Token_Logo_-_200x200.png",
  "OPIP": "https://assets.coingecko.com/coins/images/28806/thumb/OP_LOGO.png",
  "ALRIHLA": "https://assets.coingecko.com/coins/images/27303/thumb/alrihla_logo200.png",
  "DCASH": "https://assets.coingecko.com/coins/images/25926/thumb/diabolo-logo.png",
  "OUT": "https://assets.coingecko.com/coins/images/27302/thumb/OUTpostDevcoin_200_200.png",
  "MUSICAI": "https://assets.coingecko.com/coins/images/28974/thumb/200X200-2-removebg-preview.png",
  "DION": "https://assets.coingecko.com/coins/images/12080/thumb/e8R3iNOLYQA359IFUhK83G8FKzYQb8WfwEte_kI06fH9DhThaQ0bv3C3wYnh1Lpr1c8sss-mxm0X0VkVTJCtH_ZWuW4krYaYJyyiPPUKN5SzFuahC9MK7beR8TCHPrOvNfsLNKFekCujprbJW3gurBWjjnbO4UmeV_MnfjtVZMHLIIRPOBPwcp7AdvB2Q20SpvM1JTg1r0ln3-q.jpg",
  "KFC": "https://assets.coingecko.com/coins/images/26069/thumb/DED11330-5553-49EC-AF7E-CDAC9E75BA73.jpeg",
  "GSM": "https://assets.coingecko.com/coins/images/245/thumb/gsmcoin.png",
  "YOK": "https://assets.coingecko.com/coins/images/27447/thumb/YOK.png",
  "ZWALL": "https://assets.coingecko.com/coins/images/19477/thumb/4U3lsZWG_400x400_%281%29.jpg",
  "BUP": "https://assets.coingecko.com/coins/images/13206/thumb/BUP_-_LOGO.png",
  "ABY": "https://assets.coingecko.com/coins/images/141/thumb/artbyte.png",
  "HIRENGA": "https://assets.coingecko.com/coins/images/28999/thumb/hirenga.png",
  "VASH": "https://assets.coingecko.com/coins/images/269/thumb/vpncoin-logo.png",
  "BUND": "https://assets.coingecko.com/coins/images/13219/thumb/IMG_20230201_114958_189.png",
  "VGC": "https://assets.coingecko.com/coins/images/13313/thumb/5G_front_glossy.png",
  "ONEMOON": "https://assets.coingecko.com/coins/images/17702/thumb/ONEMOON_%281%29.jpg",
  "BURNING": "https://assets.coingecko.com/coins/images/27026/thumb/Ethburn.png",
  "LUCK": "https://assets.coingecko.com/coins/images/26619/thumb/lucktoken_200.png",
  "ANTE": "https://assets.coingecko.com/coins/images/25855/thumb/ante_symbol.png",
  "SATOZ": "https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg",
  "SFN": "https://assets.coingecko.com/coins/images/27991/thumb/Sinfinite-coin.png",
  "PHO": "https://assets.coingecko.com/coins/images/13836/thumb/200x200_%2810%29.png",
  "FAN": "https://assets.coingecko.com/coins/images/27804/thumb/Fandora_Logo_Round.png",
  "ECA": "https://assets.coingecko.com/coins/images/688/thumb/electra.PNG",
  "RHEGIC2": "https://assets.coingecko.com/coins/images/13969/thumb/ezgif-4-b5306690cb32.jpg",
  "METAVIE": "https://assets.coingecko.com/coins/images/26157/thumb/wNItJMGS_400x400.png",
  "CHECOIN": "https://assets.coingecko.com/coins/images/17399/thumb/hmvUIO89jIpjmOD6astn5S5pTSJqnhc3KowWEcI2.png",
  "STKK": "https://assets.coingecko.com/coins/images/26815/thumb/streakk.png",
  "YY": "https://assets.coingecko.com/coins/images/27182/thumb/291773887_578212017275747_6363541010475065229_n.png",
  "BDOT": "https://assets.coingecko.com/coins/images/23268/thumb/binance-dot_32.png",
  "HALV": "https://assets.coingecko.com/coins/images/12085/thumb/Halv-logo-200x200.png",
  "LCD": "https://assets.coingecko.com/coins/images/23693/thumb/lcd-icon-color-200px.png",
  "SMPC": "https://assets.coingecko.com/coins/images/27680/thumb/smpc-200x200.png",
  "TAO": "https://assets.coingecko.com/coins/images/28452/thumb/ARUsPeNQ_400x400.jpeg",
  "CHAT": "https://assets.coingecko.com/coins/images/14386/thumb/ezgif-6-b78b8423d870.png",
  "DIGI": "https://assets.coingecko.com/coins/images/14960/thumb/RE3Fiua.png",
  "CAPT": "https://assets.coingecko.com/coins/images/16035/thumb/captain.PNG",
  "ZBTX": "https://assets.coingecko.com/coins/images/27135/thumb/ziobitx_logo_%281%29.png",
  "PETH18C": "https://assets.coingecko.com/coins/images/14646/thumb/peth8a.png",
  "ATNT": "https://assets.coingecko.com/coins/images/27263/thumb/ATNT-symbol_200.png",
  "STKATOM": "https://assets.coingecko.com/coins/images/18484/thumb/stkATOM_-_128px.png",
  "BUCK": "https://assets.coingecko.com/coins/images/18357/thumb/arbucks.png",
  "BBYXRP": "https://assets.coingecko.com/coins/images/16862/thumb/Final.png",
  "SWIFY": "https://assets.coingecko.com/coins/images/28402/thumb/photo_2022-12-04_03-17-07.jpg",
  "BFIC": "https://assets.coingecko.com/coins/images/14884/thumb/Asset_1.png",
  "TORPEDO": "https://assets.coingecko.com/coins/images/14969/thumb/l4sLwkwR_400x400.jpg",
  "STILT": "https://assets.coingecko.com/coins/images/26132/thumb/2vfQHbe9_400x400.png",
  "XF": "https://assets.coingecko.com/coins/images/15512/thumb/logoXF240.png",
  "MEPAD": "https://assets.coingecko.com/coins/images/15141/thumb/1619694441492-Memepad_coin.png",
  "HAWK": "https://assets.coingecko.com/coins/images/24459/thumb/3CnlKM0x_400x400.jpg",
  "AVIC": "https://assets.coingecko.com/coins/images/26603/thumb/avic-02.png",
  "SUM": "https://assets.coingecko.com/coins/images/17586/thumb/sunswap.PNG",
  "ADDY": "https://assets.coingecko.com/coins/images/15225/thumb/adamant.png",
  "MOOCHII": "https://assets.coingecko.com/coins/images/15444/thumb/QUVbHkC.png",
  "CZZ": "https://assets.coingecko.com/coins/images/15852/thumb/czz.PNG",
  "DCH": "https://assets.coingecko.com/coins/images/15543/thumb/91Md4Vq.jpg",
  "$DGTV": "https://assets.coingecko.com/coins/images/28050/thumb/532a5b9e-8294-4be1-be18-f04a2cf5f0a9.png",
  "KUKU": "https://assets.coingecko.com/coins/images/26657/thumb/Official_Logo_Round_200x200.png",
  "SHINURI": "https://assets.coingecko.com/coins/images/26640/thumb/CG.png",
  "PZAP": "https://assets.coingecko.com/coins/images/15623/thumb/polyzap.png",
  "TCGCOIN": "https://assets.coingecko.com/coins/images/15715/thumb/Coin-Gecko.png",
  "GXA": "https://assets.coingecko.com/coins/images/26505/thumb/ECRcsefS_400x400.png",
  "OMC": "https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png",
  "PLUG": "https://assets.coingecko.com/coins/images/15824/thumb/PLUG_token.png",
  "BARK": "https://assets.coingecko.com/coins/images/15828/thumb/bark.png",
  "VULC": "https://assets.coingecko.com/coins/images/24726/thumb/YdPqyazR_400x400.jpg",
  "MNRY": "https://assets.coingecko.com/coins/images/16309/thumb/moonery-icon-200.png",
  "GATE": "https://assets.coingecko.com/coins/images/16009/thumb/82168105.png",
  "ANARCHY": "https://assets.coingecko.com/coins/images/26583/thumb/90EWu8yp_400x400.jpg",
  "CAVA": "https://assets.coingecko.com/coins/images/16047/thumb/VimPINu.png",
  "PUGL": "https://assets.coingecko.com/coins/images/16105/thumb/puglife.png",
  "YOUC": "https://assets.coingecko.com/coins/images/11152/thumb/round-400x400.png",
  "BBT": "https://assets.coingecko.com/coins/images/11853/thumb/blockbase_b.png",
  "BZR": "https://assets.coingecko.com/coins/images/26392/thumb/bazaars_logo-color-black-icon.png",
  "PCI": "https://assets.coingecko.com/coins/images/9330/thumb/new_logo_square.png",
  "CASHDOG": "https://assets.coingecko.com/coins/images/16180/thumb/muEcKFE.png",
  "ELLO": "https://assets.coingecko.com/coins/images/23470/thumb/vw0c04f__400x400.jpg",
  "AFT": "https://assets.coingecko.com/coins/images/17976/thumb/afgspinner2.png",
  "NZERO": "https://assets.coingecko.com/coins/images/27676/thumb/Untitled_design_%281%29.png",
  "POWA": "https://assets.coingecko.com/coins/images/27007/thumb/logo_powa_200.png",
  "LITE": "https://assets.coingecko.com/coins/images/26304/thumb/LITE%28200%29%E5%A4%87%E4%BB%BD_%282%29.png",
  "TLC": "https://assets.coingecko.com/coins/images/28177/thumb/tlc.png",
  "KIK": "https://assets.coingecko.com/coins/images/22274/thumb/kikswap.PNG",
  "BANKETH": "https://assets.coingecko.com/coins/images/17401/thumb/BankEth_200x200.png",
  "SDBY": "https://assets.coingecko.com/coins/images/25692/thumb/jIwE4iKm_400x400.jpeg",
  "BTNYX": "https://assets.coingecko.com/coins/images/13684/thumb/token-logo-200px.png",
  "BLOCK-E": "https://assets.coingecko.com/coins/images/26905/thumb/photo_2022-09-07_10.17.08.jpeg",
  "MMUI": "https://assets.coingecko.com/coins/images/16588/thumb/MMUI.jpg",
  "MOONPAW": "https://assets.coingecko.com/coins/images/15471/thumb/MoonPaw-Main-Transparent-2-1-e1622472182767.png",
  "YLDY": "https://assets.coingecko.com/coins/images/16757/thumb/YIELDLY_TOKEN_256x256px.png",
  "SUNC": "https://assets.coingecko.com/coins/images/17601/thumb/ticker_sunc_200_200.png",
  "DMA": "https://assets.coingecko.com/coins/images/26244/thumb/DMA.png",
  "BAVA": "https://assets.coingecko.com/coins/images/23780/thumb/200x200_BAVA_LOGO_%282%29.png",
  "IBH": "https://assets.coingecko.com/coins/images/7900/thumb/kNB221Sz_400x400.jpg",
  "TA": "https://assets.coingecko.com/coins/images/27950/thumb/THE_Ape.png",
  "ARDX": "https://assets.coingecko.com/coins/images/9432/thumb/ARDC.png",
  "$MART": "https://assets.coingecko.com/coins/images/24458/thumb/image_%287%29.png",
  "MTDR": "https://assets.coingecko.com/coins/images/15879/thumb/MATADOR-HEAD-FINAL-2.png",
  "NMT": "https://assets.coingecko.com/coins/images/15811/thumb/NFTMART.png",
  "SPDX": "https://assets.coingecko.com/coins/images/29015/thumb/fgxchgj.png",
  "ZENI": "https://assets.coingecko.com/coins/images/1252/thumb/zennies.png",
  "TRV": "https://assets.coingecko.com/coins/images/8793/thumb/trv_256px.png",
  "ATR": "https://assets.coingecko.com/coins/images/24894/thumb/ATR-BSC.png",
  "WAR": "https://assets.coingecko.com/coins/images/17093/thumb/war.PNG",
  "FOOT": "https://assets.coingecko.com/coins/images/17773/thumb/AThX0Y8g_400x400.jpg",
  "BBFEG": "https://assets.coingecko.com/coins/images/17778/thumb/logo_%281%29.png",
  "DGRN": "https://assets.coingecko.com/coins/images/27570/thumb/degrain.jpeg",
  "TRTLS": "https://assets.coingecko.com/coins/images/21017/thumb/turtles200x200.png",
  "BIN": "https://assets.coingecko.com/coins/images/17932/thumb/logo-v3.png",
  "SAKURA": "https://assets.coingecko.com/coins/images/28640/thumb/photo_2023-01-06_16.00.02.jpeg",
  "LTY": "https://assets.coingecko.com/coins/images/17939/thumb/ledgity.PNG",
  "GLMS": "https://assets.coingecko.com/coins/images/18199/thumb/glm.PNG",
  "DGN": "https://assets.coingecko.com/coins/images/26042/thumb/HyvIWtS.png",
  "BZP": "https://assets.coingecko.com/coins/images/19122/thumb/91uivPTI_400x400.png",
  "EGGPLUS": "https://assets.coingecko.com/coins/images/26362/thumb/200x200.png",
  "G.O.A.T": "https://assets.coingecko.com/coins/images/21537/thumb/IMG_1871.JPG",
  "INUINU": "https://assets.coingecko.com/coins/images/26391/thumb/logo_square_200.png",
  "SPLY": "https://assets.coingecko.com/coins/images/23276/thumb/sply.png",
  "UVT": "https://assets.coingecko.com/coins/images/26875/thumb/photo_2022-04-08_10-31-38_%282%29.png",
  "$JED": "https://assets.coingecko.com/coins/images/18509/thumb/jedstar_logo_coingecko.png",
  "SBNK": "https://assets.coingecko.com/coins/images/18513/thumb/yo_gS6Hi_400x400.png",
  "NUMI": "https://assets.coingecko.com/coins/images/25426/thumb/o59M49P.png",
  "SQUEEZE": "https://assets.coingecko.com/coins/images/18554/thumb/squeeze.png",
  "CRYSTAL": "https://assets.coingecko.com/coins/images/24741/thumb/DFK_Crystal_Token_Logo.351435c0.png",
  "ELIXIR": "https://assets.coingecko.com/coins/images/18941/thumb/starchi.jpg",
  "EVRY": "https://assets.coingecko.com/coins/images/19194/thumb/evry.png",
  "DNFT": "https://assets.coingecko.com/coins/images/19006/thumb/dnft.PNG",
  "WANUSDT": "https://assets.coingecko.com/coins/images/23605/thumb/download-1.png",
  "BFG": "https://assets.coingecko.com/coins/images/27715/thumb/BFG-token-logo.00b4b09c.png",
  "ARDN": "https://assets.coingecko.com/coins/images/19401/thumb/Frame_5_%287%29_%281%29.png",
  "BTP": "https://assets.coingecko.com/coins/images/24867/thumb/32F4D530-6587-4498-B9E3-CC99B6A73357.png",
  "ALTB": "https://assets.coingecko.com/coins/images/19550/thumb/615635338527975b0314223a_Altbase_app_icon.png",
  "BABYOKX": "https://assets.coingecko.com/coins/images/24970/thumb/BABYOKX-200x200.png",
  "ALGB": "https://assets.coingecko.com/coins/images/19580/thumb/13211.png",
  "WALLPHY": "https://assets.coingecko.com/coins/images/26589/thumb/wallphy.png",
  "ALGOPAD": "https://assets.coingecko.com/coins/images/19690/thumb/hcyg4Kz.png",
  "ZIK": "https://assets.coingecko.com/coins/images/8991/thumb/ziktalk.logo.jpeg",
  "ORARE": "https://assets.coingecko.com/coins/images/19696/thumb/Thumbnail_-_500_px_-_Black.png",
  "GOFX": "https://assets.coingecko.com/coins/images/19793/thumb/0Kjm9f4.png",
  "SMTF": "https://assets.coingecko.com/coins/images/25264/thumb/618d410e849c474a30fe91ae_SMTF_token_-_transparent_background.png",
  "MYNE": "https://assets.coingecko.com/coins/images/19817/thumb/wYmPY80__400x400.png",
  "JOLT": "https://assets.coingecko.com/coins/images/25262/thumb/NSj-jWHR_400x400.jpg",
  "PROMISE": "https://assets.coingecko.com/coins/images/17910/thumb/49c62672-0d86-4467-baea-874291066473.png",
  "SOLFI": "https://assets.coingecko.com/coins/images/20729/thumb/dDDqpMiA_400x400.jpg",
  "JK": "https://assets.coingecko.com/coins/images/20392/thumb/14488.png",
  "DHP": "https://assets.coingecko.com/coins/images/20167/thumb/dHealth_Network_Icon_200w.png",
  "SAMU": "https://assets.coingecko.com/coins/images/20187/thumb/tokenlogo200x200.png",
  "BMDA": "https://assets.coingecko.com/coins/images/28878/thumb/LOGO.png",
  "UNLK": "https://assets.coingecko.com/coins/images/27072/thumb/unlk.png",
  "PIXIA": "https://assets.coingecko.com/coins/images/28941/thumb/PixiaAi.png",
  "FLUID": "https://assets.coingecko.com/coins/images/20428/thumb/FluidFi_Logo_32x32px_vtvcai.png",
  "STREETH": "https://assets.coingecko.com/coins/images/24954/thumb/streeth.png",
  "FALAFEL": "https://assets.coingecko.com/coins/images/20426/thumb/falafel.png",
  "IMPACTX": "https://assets.coingecko.com/coins/images/20433/thumb/gen-impact-logo-2-276x300.png",
  "USE": "https://assets.coingecko.com/coins/images/24924/thumb/useless.jpeg",
  "GULL": "https://assets.coingecko.com/coins/images/20590/thumb/polygod.PNG",
  "SDOG": "https://assets.coingecko.com/coins/images/15377/thumb/small_dog.PNG",
  "MYNT": "https://assets.coingecko.com/coins/images/28942/thumb/Asset_1.png",
  "UART": "https://assets.coingecko.com/coins/images/22092/thumb/3Rx82r_w_400x400.png",
  "TXS": "https://assets.coingecko.com/coins/images/27453/thumb/texas200.png",
  "FDLS": "https://assets.coingecko.com/coins/images/24778/thumb/fdls.png",
  "SBAR": "https://assets.coingecko.com/coins/images/20599/thumb/sbar-icon.png",
  "VITY": "https://assets.coingecko.com/coins/images/24796/thumb/vity.png",
  "XOY": "https://assets.coingecko.com/coins/images/26641/thumb/xoycoin_logo.png",
  "APEFUND": "https://assets.coingecko.com/coins/images/20722/thumb/BavuVre2_400x400.jpg",
  "EVERDOT": "https://assets.coingecko.com/coins/images/20773/thumb/Logo-200x200.png",
  "LTNV2": "https://assets.coingecko.com/coins/images/20568/thumb/1-j2MSwg_400x400.jpg",
  "VPAD": "https://assets.coingecko.com/coins/images/20838/thumb/vlaunch_22.png",
  "GZLR": "https://assets.coingecko.com/coins/images/20970/thumb/Screenshot-2021-11-27-at-22-57-22.png",
  "MPG": "https://assets.coingecko.com/coins/images/20971/thumb/MEDPING_LOGO.png",
  "EXOS": "https://assets.coingecko.com/coins/images/25528/thumb/16521.png",
  "SHIBOKI": "https://assets.coingecko.com/coins/images/24620/thumb/Shiboki.jpeg",
  "FUM": "https://assets.coingecko.com/coins/images/21054/thumb/KuH9TXMM_400x400.jpg",
  "SRP": "https://assets.coingecko.com/coins/images/18631/thumb/starpunk.PNG",
  "QWLA": "https://assets.coingecko.com/coins/images/15242/thumb/qwla.png",
  "GYC": "https://assets.coingecko.com/coins/images/24525/thumb/gameyoo-logo-200x200.png",
  "ADAL": "https://assets.coingecko.com/coins/images/24554/thumb/19012.png",
  "BANA": "https://assets.coingecko.com/coins/images/21234/thumb/solshib.png",
  "ASW": "https://assets.coingecko.com/coins/images/24453/thumb/rA5cmPtX_400x400.jpg",
  "ASSET": "https://assets.coingecko.com/coins/images/25475/thumb/asset.png",
  "SOM": "https://assets.coingecko.com/coins/images/25515/thumb/soulsofmeta.7527682e.jpg",
  "SOLCASH": "https://assets.coingecko.com/coins/images/21546/thumb/solcash.png",
  "DXS": "https://assets.coingecko.com/coins/images/24224/thumb/DX-ICON.png",
  "UTC": "https://assets.coingecko.com/coins/images/24240/thumb/utc.png",
  "POLYCUB": "https://assets.coingecko.com/coins/images/24124/thumb/polycub.png",
  "LTEX": "https://assets.coingecko.com/coins/images/22001/thumb/photo1637249460-removebg-preview-1.png",
  "PHAE": "https://assets.coingecko.com/coins/images/21837/thumb/EFIf5tLf_400x400.jpg",
  "STUD": "https://assets.coingecko.com/coins/images/22005/thumb/jPzDUeNn_400x400.jpg",
  "XLMN": "https://assets.coingecko.com/coins/images/22321/thumb/XL-MOON-LOGO-200px.png",
  "NFTK": "https://assets.coingecko.com/coins/images/21970/thumb/PfGXwOcW_400x400.jpg",
  "ONEICHI": "https://assets.coingecko.com/coins/images/25908/thumb/74116843.png",
  "IMRTL": "https://assets.coingecko.com/coins/images/21983/thumb/ONE.png",
  "BPAD": "https://assets.coingecko.com/coins/images/26517/thumb/brisepad.jpg",
  "WEBFOUR": "https://assets.coingecko.com/coins/images/22323/thumb/WebFourLogoNoText.png",
  "CHIWA": "https://assets.coingecko.com/coins/images/22074/thumb/200x200.png",
  "ESOL": "https://assets.coingecko.com/coins/images/23056/thumb/SUWTLy9j_400x400.jpeg",
  "HIMFERS": "https://assets.coingecko.com/coins/images/28719/thumb/hiMFERs.png",
  "MYT": "https://assets.coingecko.com/coins/images/24134/thumb/transparent_logo.png",
  "QUANTIC": "https://assets.coingecko.com/coins/images/23953/thumb/Logo-200.png",
  "ANTG": "https://assets.coingecko.com/coins/images/24320/thumb/200x200.png",
  "PDSHARE": "https://assets.coingecko.com/coins/images/24963/thumb/pdshare.png",
  "MPAY": "https://assets.coingecko.com/coins/images/6360/thumb/Menapay_ico.png",
  "XOV": "https://assets.coingecko.com/coins/images/5396/thumb/xov.jpg",
  "BIST": "https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png",
  "TRNDZ": "https://assets.coingecko.com/coins/images/23088/thumb/Trendsy-logo-200x200-1.png",
  "CHB": "https://assets.coingecko.com/coins/images/24863/thumb/200x_logo.png",
  "BARMY": "https://assets.coingecko.com/coins/images/15850/thumb/bscarmy_logo.jpg",
  "RTC": "https://assets.coingecko.com/coins/images/19024/thumb/rijent.jpg",
  "XXA": "https://assets.coingecko.com/coins/images/10896/thumb/WFhSOqu.png",
  "UNP": "https://assets.coingecko.com/coins/images/27768/thumb/yHxDwXCN_400x400.png",
  "POKELON": "https://assets.coingecko.com/coins/images/24793/thumb/pokelon.png",
  "PIT": "https://assets.coingecko.com/coins/images/15927/thumb/pitbull2.png",
  "EVLD": "https://assets.coingecko.com/coins/images/27770/thumb/photo_5854849223080655555_m_%281%29.jpg",
  "TRYV": "https://assets.coingecko.com/coins/images/25804/thumb/tryv_token_200x200_%281%29.png",
  "GEO$": "https://assets.coingecko.com/coins/images/21632/thumb/Geopoly_Token.png",
  "ETCK": "https://assets.coingecko.com/coins/images/18598/thumb/etck.png",
  "CATGIRL": "https://assets.coingecko.com/coins/images/16472/thumb/catgirl-200x200.png",
  "FADO": "https://assets.coingecko.com/coins/images/23593/thumb/fado.png",
  "ORAIX": "https://assets.coingecko.com/coins/images/28104/thumb/oraix.png",
  "BABYBOO": "https://assets.coingecko.com/coins/images/18234/thumb/babyboo.png",
  "FACE": "https://assets.coingecko.com/coins/images/17039/thumb/FACE.png",
  "LC": "https://assets.coingecko.com/coins/images/8983/thumb/lightning.png",
  "GRBT": "https://assets.coingecko.com/coins/images/25634/thumb/CI_grinbit_200by200.png",
  "COREDAO": "https://assets.coingecko.com/coins/images/23745/thumb/coredao.png",
  "CRK": "https://assets.coingecko.com/coins/images/22667/thumb/Logo_200x200.png",
  "TRDO": "https://assets.coingecko.com/coins/images/27130/thumb/trondao.png",
  "BCHK": "https://assets.coingecko.com/coins/images/18602/thumb/bchk.png",
  "OXSOLID": "https://assets.coingecko.com/coins/images/24257/thumb/oxSOLID.png",
  "NEWS": "https://assets.coingecko.com/coins/images/9572/thumb/PUBLISH_Logo_280X280.png",
  "MOSOLID": "https://assets.coingecko.com/coins/images/28677/thumb/black_mono_logopng.png",
  "ORKL": "https://assets.coingecko.com/coins/images/21936/thumb/zWNiTkm.png",
  "ELTG": "https://assets.coingecko.com/coins/images/25433/thumb/vjEBg-rD_400x400.jpg",
  "BKR": "https://assets.coingecko.com/coins/images/18662/thumb/BKR_log_200.png",
  "POOCOIN": "https://assets.coingecko.com/coins/images/14855/thumb/w4_9ayk3_400x400.png",
  "BONFIRE": "https://assets.coingecko.com/coins/images/15020/thumb/Logo_-_2021-04-27T062421.226.png",
  "METX": "https://assets.coingecko.com/coins/images/18952/thumb/metx.png",
  "SJW": "https://assets.coingecko.com/coins/images/6171/thumb/SJWCoin.png",
  "CHC": "https://assets.coingecko.com/coins/images/24274/thumb/logo-svg.png",
  "RGEN": "https://assets.coingecko.com/coins/images/24198/thumb/TH-Y4qWF_400x400.jpg",
  "TLW": "https://assets.coingecko.com/coins/images/23986/thumb/tlw.png",
  "DODB": "https://assets.coingecko.com/coins/images/23244/thumb/ozmhlZ-N_400x400.png",
  "SKP": "https://assets.coingecko.com/coins/images/27129/thumb/SKP_logo_20x20.png",
  "DSHARE": "https://assets.coingecko.com/coins/images/22194/thumb/dshares.png",
  "SCRATCH": "https://assets.coingecko.com/coins/images/27706/thumb/LogoPlain.png",
  "UNIK": "https://assets.coingecko.com/coins/images/18604/thumb/unik.png",
  "AKS": "https://assets.coingecko.com/coins/images/24097/thumb/aks-coin-200.png",
  "ADACASH": "https://assets.coingecko.com/coins/images/19509/thumb/adacash.png",
  "KYCC": "https://assets.coingecko.com/coins/images/24605/thumb/kycc.png",
  "SUSHIBA": "https://assets.coingecko.com/coins/images/16139/thumb/sushiba.PNG",
  "TCNH": "https://assets.coingecko.com/coins/images/28499/thumb/TBqsNXUtqaLptVK8AYvdPPctpqd8oBYWUC.png",
  "OIOC": "https://assets.coingecko.com/coins/images/16332/thumb/OIOC.png",
  "V": "https://assets.coingecko.com/coins/images/1324/thumb/version.png",
  "DELFI": "https://assets.coingecko.com/coins/images/24085/thumb/logo.png",
  "MLNK": "https://assets.coingecko.com/coins/images/21708/thumb/token_mln.0cf5f320.png",
  "NAFF": "https://assets.coingecko.com/coins/images/24057/thumb/Naffiti_1200x1200.png",
  "TRK": "https://assets.coingecko.com/coins/images/23357/thumb/trk.PNG",
  "SRWD": "https://assets.coingecko.com/coins/images/21777/thumb/SRWD-LOGO-1.png",
  "XST": "https://assets.coingecko.com/coins/images/27897/thumb/XST_200x200.png",
  "PIM": "https://assets.coingecko.com/coins/images/27128/thumb/200x200_PIM_logo-removebg-preview.png",
  "I-STABLE": "https://assets.coingecko.com/coins/images/28843/thumb/I-STABLE-05.png",
  "CPTL": "https://assets.coingecko.com/coins/images/20391/thumb/XbepaiH__400x400.jpg",
  "NUTGV2": "https://assets.coingecko.com/coins/images/25567/thumb/nutgv2.png",
  "LIS": "https://assets.coingecko.com/coins/images/29058/thumb/lis.png",
  "ELO INU": "https://assets.coingecko.com/coins/images/21570/thumb/5gLcVCKI.png",
  "SYNR": "https://assets.coingecko.com/coins/images/22868/thumb/FullML_Yellow_200px.png",
  "IV": "https://assets.coingecko.com/coins/images/23396/thumb/inv.png",
  "BNBBACK": "https://assets.coingecko.com/coins/images/23890/thumb/MxdOnJKe_400x400.jpg",
  "ATTR": "https://assets.coingecko.com/coins/images/4395/thumb/attrace.png",
  "RPILL": "https://assets.coingecko.com/coins/images/27879/thumb/3177f501-401e-418d-a4bc-f72fdf9fe5d7.png",
  "RUX": "https://assets.coingecko.com/coins/images/26156/thumb/img_v2_37fc1523-649d-45bc-a75f-387f9de1bfch.png",
  "COX": "https://assets.coingecko.com/coins/images/21191/thumb/SoXX-GZs_400x400.jpg",
  "RAF": "https://assets.coingecko.com/coins/images/27765/thumb/IMG_20221013_135903_682.png",
  "FNK": "https://assets.coingecko.com/coins/images/13483/thumb/fnk.png",
  "EXENP": "https://assets.coingecko.com/coins/images/18975/thumb/LlydYS1w_400x400.png",
  "NPC": "https://assets.coingecko.com/coins/images/27016/thumb/logo.png",
  "DVX": "https://assets.coingecko.com/coins/images/19569/thumb/ifC5Mmw.png",
  "PSYS": "https://assets.coingecko.com/coins/images/25407/thumb/transparent-bg-pegasys-horizontal-200px.png",
  "CX1": "https://assets.coingecko.com/coins/images/23911/thumb/XnskdxoF_400x400.png",
  "KYAN": "https://assets.coingecko.com/coins/images/14296/thumb/70912817.png",
  "HYP": "https://assets.coingecko.com/coins/images/27563/thumb/photo_2022-09-23_14-41-41.jpg",
  "PWG": "https://assets.coingecko.com/coins/images/21735/thumb/logo-risorse.png",
  "IZU": "https://assets.coingecko.com/coins/images/27997/thumb/coin_gecko.png",
  "IPV": "https://assets.coingecko.com/coins/images/25778/thumb/hhQtNx1X_400x400.jpeg",
  "LTHN": "https://assets.coingecko.com/coins/images/1184/thumb/gIdaZxy.png",
  "ANY": "https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg",
  "KRU": "https://assets.coingecko.com/coins/images/28012/thumb/Icon_only.png",
  "AXLWETH": "https://assets.coingecko.com/coins/images/28171/thumb/axlweth.png",
  "DXCT": "https://assets.coingecko.com/coins/images/17603/thumb/dxct.png",
  "SPOX": "https://assets.coingecko.com/coins/images/28002/thumb/spox_logo.png",
  "VEST": "https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png",
  "ETHK": "https://assets.coingecko.com/coins/images/18537/thumb/0xef71ca2ee68f45b9ad6f72fbdb33d707b872315c.png",
  "TANG": "https://assets.coingecko.com/coins/images/27259/thumb/Icon_400.png",
  "PTR": "https://assets.coingecko.com/coins/images/21381/thumb/144531867-a8016f41-3b31-4d6f-97a1-372a58d48626.png",
  "AZA": "https://assets.coingecko.com/coins/images/28443/thumb/Azarus_Logo_Mark-XXL.png",
  "ETHDOWN": "https://assets.coingecko.com/coins/images/17029/thumb/binance-coin-bnb-logo.png",
  "SMON": "https://assets.coingecko.com/coins/images/18130/thumb/StarMon.png",
  "BOBC": "https://assets.coingecko.com/coins/images/24264/thumb/bobc.png",
  "SOLARIX": "https://assets.coingecko.com/coins/images/26757/thumb/237712644422d81b00a920ad9f9255e.png",
  "WANAVAX": "https://assets.coingecko.com/coins/images/23616/thumb/download-5.png",
  "MINIBTC": "https://assets.coingecko.com/coins/images/21391/thumb/minibtc.png",
  "CI": "https://assets.coingecko.com/coins/images/21563/thumb/KreHpNsX_400x400.jpg",
  "INP": "https://assets.coingecko.com/coins/images/20936/thumb/inpoker.PNG",
  "BITC": "https://assets.coingecko.com/coins/images/7895/thumb/B_GPCvVG_400x400.jpg",
  "XLON": "https://assets.coingecko.com/coins/images/17104/thumb/xlon_coin_logo_200x200.png",
  "WEB": "missing_thumb.png",
  "RMARS": "https://assets.coingecko.com/coins/images/23980/thumb/rmars.png",
  "LITHIUM": "https://assets.coingecko.com/coins/images/17207/thumb/B4XYNU5.png",
  "KCT": "https://assets.coingecko.com/coins/images/25756/thumb/EAO_5JhW_400x400.jpg",
  "MF": "https://assets.coingecko.com/coins/images/23261/thumb/TXirEzAw_400x400.jpg",
  "FITT": "https://assets.coingecko.com/coins/images/27021/thumb/Fitmint_logo.png",
  "GIZA": "https://assets.coingecko.com/coins/images/21144/thumb/_dLPsk9__400x400.jpg",
  "POTS": "https://assets.coingecko.com/coins/images/17517/thumb/moonpot.PNG",
  "PPAD": "https://assets.coingecko.com/coins/images/20202/thumb/playpad.PNG",
  "BETS": "https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png",
  "M87": "https://assets.coingecko.com/coins/images/25957/thumb/MESSIERlogonew_%281%29.png",
  "SDXB": "https://assets.coingecko.com/coins/images/13717/thumb/sdx.png",
  "NSI": "https://assets.coingecko.com/coins/images/19607/thumb/nsi.png",
  "MTRX": "https://assets.coingecko.com/coins/images/27565/thumb/output-onlinepngtools_%287%29.png",
  "CYMI": "https://assets.coingecko.com/coins/images/29032/thumb/Screen_Shot_2023-02-10_at_1.22.01_PM.png",
  "BNK": "https://assets.coingecko.com/coins/images/1949/thumb/bankera.png",
  "MBET": "https://assets.coingecko.com/coins/images/22778/thumb/y5TphxL.jpeg",
  "CAKEPOW": "https://assets.coingecko.com/coins/images/27541/thumb/photo_2022-09-25_17-45-24.jpg",
  "X7C": "https://assets.coingecko.com/coins/images/28224/thumb/X7_Moeda_f%C3%ADsica_Recall-min.png",
  "XLN": "https://assets.coingecko.com/coins/images/28227/thumb/input-onlinepngtools.png",
  "VIA": "https://assets.coingecko.com/coins/images/112/thumb/Via.png",
  "TETUBAL": "https://assets.coingecko.com/coins/images/27969/thumb/tetuBal.png",
  "FURY": "https://assets.coingecko.com/coins/images/27079/thumb/fury.png",
  "VBIT": "https://assets.coingecko.com/coins/images/12394/thumb/Untitled-design-3.png",
  "ADF": "https://assets.coingecko.com/coins/images/5620/thumb/ad-flex-token.png",
  "$PGS": "https://assets.coingecko.com/coins/images/28142/thumb/Logo1-01.png",
  "WTK": "https://assets.coingecko.com/coins/images/13083/thumb/200xWadzToken.png",
  "OCI": "https://assets.coingecko.com/coins/images/27956/thumb/Ociswap-Logo-200x200.png",
  "LEOPARD": "https://assets.coingecko.com/coins/images/16270/thumb/IMG_20210430_200245_458.jpg",
  "PAF": "https://assets.coingecko.com/coins/images/20915/thumb/paf.png",
  "OHMC": "https://assets.coingecko.com/coins/images/3487/thumb/ohm.png",
  "PEPE": "https://assets.coingecko.com/coins/images/26864/thumb/logo-200x200.png",
  "ASY": "https://assets.coingecko.com/coins/images/10632/thumb/c0Q4z8HJ_400x400.jpg",
  "VENTION": "https://assets.coingecko.com/coins/images/17497/thumb/logo-black-200.png",
  "MCH": "https://assets.coingecko.com/coins/images/7843/thumb/lu7IV2ao_400x400.png",
  "KNIGHT": "https://assets.coingecko.com/coins/images/24836/thumb/TokenTR.png",
  "SCL": "https://assets.coingecko.com/coins/images/874/thumb/scl.png",
  "CLOUD": "https://assets.coingecko.com/coins/images/27634/thumb/Untitled_Artwork_%281%29.png",
  "KAIINU": "https://assets.coingecko.com/coins/images/15746/thumb/kaiinu.png",
  "FLOSHIN": "https://assets.coingecko.com/coins/images/21239/thumb/floshin.png",
  "DXGM": "https://assets.coingecko.com/coins/images/21084/thumb/dxgm-token.png",
  "CP": "https://assets.coingecko.com/coins/images/28432/thumb/IMG_20221207_200805_897.jpg",
  "IBY": "https://assets.coingecko.com/coins/images/16423/thumb/qvrS5aNM_400x400.jpg",
  "E8": "https://assets.coingecko.com/coins/images/19707/thumb/OZ52Oms.png",
  "CRFI": "https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png",
  "MNTG": "https://assets.coingecko.com/coins/images/18700/thumb/logo_-_2021-10-01T091320.526.png",
  "LPI": "https://assets.coingecko.com/coins/images/18551/thumb/IMG-20210923-102411-497.jpg",
  "TPC": "https://assets.coingecko.com/coins/images/24606/thumb/tpc.png",
  "DBAY": "https://assets.coingecko.com/coins/images/22705/thumb/g49IDMK.png",
  "QUIN": "https://assets.coingecko.com/coins/images/7041/thumb/quinads-logo.png",
  "HMRN": "https://assets.coingecko.com/coins/images/22702/thumb/G8qNfLO.jpg",
  "HAL": "https://assets.coingecko.com/coins/images/2623/thumb/halcyon.png",
  "WANUSDC": "https://assets.coingecko.com/coins/images/23606/thumb/download-2.png",
  "CROS": "https://assets.coingecko.com/coins/images/28900/thumb/croswap.jpeg",
  "MKT": "https://assets.coingecko.com/coins/images/2906/thumb/Mktcoin_Logo_-_Clean_Version.png",
  "DEUX": "https://assets.coingecko.com/coins/images/23730/thumb/favicon.png",
  "NBL": "https://assets.coingecko.com/coins/images/26873/thumb/nobility.jpg",
  "SCOP": "https://assets.coingecko.com/coins/images/20600/thumb/icon_%2811%29.png",
  "AKI": "https://assets.coingecko.com/coins/images/27746/thumb/200px-aki.png",
  "FOMOETH": "https://assets.coingecko.com/coins/images/21934/thumb/qSCAqd6m_400x400.jpg",
  "MEV": "https://assets.coingecko.com/coins/images/24566/thumb/0sp1DWVw_400x400.png",
  "OLE": "https://assets.coingecko.com/coins/images/26098/thumb/256x256_OLE_Token_Logo.png",
  "INUGAMI": "https://assets.coingecko.com/coins/images/24562/thumb/INUGAMI-BLACK.jpg",
  "ALF": "https://assets.coingecko.com/coins/images/27532/thumb/alphafiofficial.png",
  "CRYPT": "https://assets.coingecko.com/coins/images/18905/thumb/cryptia.png",
  "TEK": "https://assets.coingecko.com/coins/images/2679/thumb/tekcoin.png",
  "AEL": "https://assets.coingecko.com/coins/images/12955/thumb/ael_logo.png",
  "TILL": "https://assets.coingecko.com/coins/images/28594/thumb/TILL_png%28200%29.png",
  "$AQUA": "https://assets.coingecko.com/coins/images/28520/thumb/aqua.png",
  "POO": "https://assets.coingecko.com/coins/images/20004/thumb/poo.png",
  "MESH": "https://assets.coingecko.com/coins/images/25609/thumb/VxtSquTp_400x400.jpeg",
  "BTCK": "https://assets.coingecko.com/coins/images/18527/thumb/11134.png",
  "HELP": "https://assets.coingecko.com/coins/images/6278/thumb/q0EaTkZx_400x400.jpg",
  "POOGROW": "https://assets.coingecko.com/coins/images/26755/thumb/squid.png",
  "SANCTUM": "https://assets.coingecko.com/coins/images/28490/thumb/34B5B9A0-EF11-4EC2-8378-D4576039944F.jpeg",
  "MPWR": "https://assets.coingecko.com/coins/images/25456/thumb/MPWR_200x200.png",
  "DINOEGG": "https://assets.coingecko.com/coins/images/22291/thumb/xOcKL1Fw_400x400.png",
  "NADA": "https://assets.coingecko.com/coins/images/27517/thumb/NADA_200.png",
  "WFX": "https://assets.coingecko.com/coins/images/8559/thumb/dr4OEbWK_400x400.jpg",
  "WDO": "https://assets.coingecko.com/coins/images/22279/thumb/4409.jpg",
  "WCX": "https://assets.coingecko.com/coins/images/14497/thumb/WCX-Token-Logo.png",
  "CWAR": "https://assets.coingecko.com/coins/images/20227/thumb/CWAR_round_200_200.png",
  "DYZILLA": "https://assets.coingecko.com/coins/images/28532/thumb/Dyzilla.png",
  "EMO": "https://assets.coingecko.com/coins/images/25800/thumb/logox200.png",
  "OSEA": "https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png",
  "KURT": "https://assets.coingecko.com/coins/images/676/thumb/kurrent.png",
  "FATCAKE": "https://assets.coingecko.com/coins/images/17400/thumb/Copy-of-Untitled-6.png",
  "CIND": "https://assets.coingecko.com/coins/images/20449/thumb/1_sbv9l6ArIn7oYIhi0oQWoQ.png",
  "MTTCOIN": "https://assets.coingecko.com/coins/images/13228/thumb/mtt.png",
  "7PXS": "https://assets.coingecko.com/coins/images/26153/thumb/7pxs.png",
  "TSHP": "https://assets.coingecko.com/coins/images/9351/thumb/12ships.png",
  "LKD": "https://assets.coingecko.com/coins/images/26446/thumb/linkdao200x200.png",
  "ATPAD": "https://assets.coingecko.com/coins/images/20700/thumb/PU0GdfUG_400x400.jpg",
  "MEXI": "https://assets.coingecko.com/coins/images/21727/thumb/metaxiz.PNG",
  "KRAC": "https://assets.coingecko.com/coins/images/26441/thumb/Krabots_token_icon.png",
  "SHIBCAT": "https://assets.coingecko.com/coins/images/29062/thumb/photo_2023-02-13_17-38-39-removebg-preview.png",
  "GLTM": "https://assets.coingecko.com/coins/images/28597/thumb/_GLTM-Token-Border200.png",
  "SYL": "https://assets.coingecko.com/coins/images/14847/thumb/SYL_V01.png",
  "FORTUNE": "https://assets.coingecko.com/coins/images/28055/thumb/IMG_20221101_002714_180.jpg",
  "WDX": "https://assets.coingecko.com/coins/images/14481/thumb/Wordlex-logo.png",
  "LHB": "https://assets.coingecko.com/coins/images/13943/thumb/MhKEGAJdhvwsPfvi-AeLt0A_lewMPfxr3VrmGMlwUNYyLptvqzfgCn62bhml9-94r7K794FpVSHkxi7z5mw77IkOurzWNClf-MWzhxktjKr9CVJr3hXRtwqs1p9peoLg190hZy88niMuZHL9Hr-lzPXeK9FJ368i-O0iBSTAjYYHc8UM77jZuHpnzdUUGGQN8cv6BvWN1r-qZlR.jpg",
  "MSB": "https://assets.coingecko.com/coins/images/13021/thumb/xREV4lXV.png",
  "GSCARAB": "https://assets.coingecko.com/coins/images/22101/thumb/gscarab-logo.bd366d92.png",
  "CNG": "https://assets.coingecko.com/coins/images/11573/thumb/cng_casino.png",
  "HIPUNKS": "https://assets.coingecko.com/coins/images/26739/thumb/hipunks.png",
  "DLD": "https://assets.coingecko.com/coins/images/21411/thumb/256x256_black_01.png",
  "CENX": "https://assets.coingecko.com/coins/images/20624/thumb/centx.PNG",
  "SOLR": "https://assets.coingecko.com/coins/images/18390/thumb/Sol-Razr-Logo-TICKER.png",
  "SPIN": "https://assets.coingecko.com/coins/images/19881/thumb/7sV8g8d.png",
  "DUT": "https://assets.coingecko.com/coins/images/20704/thumb/dardark200.png",
  "HOTMOON": "https://assets.coingecko.com/coins/images/25206/thumb/hotmoon-coinGecko.png",
  "BMT": "https://assets.coingecko.com/coins/images/7419/thumb/bchain_logo.jpg",
  "GSG": "https://assets.coingecko.com/coins/images/26300/thumb/gsg.png",
  "WATT": "https://assets.coingecko.com/coins/images/27215/thumb/CG_WATT_LOGO.png",
  "TWO": "https://assets.coingecko.com/coins/images/18911/thumb/2gather.PNG",
  "APED": "https://assets.coingecko.com/coins/images/18874/thumb/bteKOcAH_400x400.jpg",
  "YON": "https://assets.coingecko.com/coins/images/21214/thumb/K91jws5t_400x400.png",
  "MOWA": "https://assets.coingecko.com/coins/images/18665/thumb/spqKFki.png",
  "ORGN": "https://assets.coingecko.com/coins/images/22010/thumb/oragonlnew200.png",
  "SOLV": "https://assets.coingecko.com/coins/images/27987/thumb/Solv-Logo5.png",
  "CORD": "https://assets.coingecko.com/coins/images/28013/thumb/IMG-20221026-WA0025.png",
  "ZEDXION": "https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png",
  "DFCH": "https://assets.coingecko.com/coins/images/18842/thumb/defi200px.png",
  "AFINS": "https://assets.coingecko.com/coins/images/26280/thumb/AFINS_token_logo_200x200.png",
  "SYX": "https://assets.coingecko.com/coins/images/21964/thumb/logo.png",
  "STIMMY": "https://assets.coingecko.com/coins/images/20796/thumb/logo.gif",
  "OPTCM": "https://assets.coingecko.com/coins/images/21678/thumb/rsz_logo%281%29.png",
  "DX": "https://assets.coingecko.com/coins/images/4700/thumb/VdZwy0Pv_400x400.png",
  "100K": "https://assets.coingecko.com/coins/images/27058/thumb/IMG_20220824_151254_066.png",
  "GRX": "https://assets.coingecko.com/coins/images/18590/thumb/photo-2021-09-23-15-41-05.jpg",
  "MAXI": "https://assets.coingecko.com/coins/images/25126/thumb/maxilogo200.png",
  "STRIP": "https://assets.coingecko.com/coins/images/20289/thumb/strip_finance.PNG",
  "CHEESUS": "https://assets.coingecko.com/coins/images/23278/thumb/cheesus.png",
  "RLQ": "https://assets.coingecko.com/coins/images/21896/thumb/realliq_copy.png",
  "RNDX": "https://assets.coingecko.com/coins/images/26239/thumb/1_49mImo_400x400.jpg",
  "STD": "https://assets.coingecko.com/coins/images/20711/thumb/14952.png",
  "EVERBNB": "https://assets.coingecko.com/coins/images/21899/thumb/16320.png",
  "DVDX": "https://assets.coingecko.com/coins/images/19052/thumb/DERIVED_Logo_Small_Green.png",
  "VNES": "https://assets.coingecko.com/coins/images/27113/thumb/logo_%281%29.png",
  "WETA": "https://assets.coingecko.com/coins/images/21020/thumb/RLcto7b.png",
  "ONIGIRI": "https://assets.coingecko.com/coins/images/12699/thumb/onigiri-coingecko.png",
  "SUKO": "https://assets.coingecko.com/coins/images/28551/thumb/logo_200.png",
  "GGR": "https://assets.coingecko.com/coins/images/28898/thumb/GGR-200x200.png",
  "SPACEPI": "https://assets.coingecko.com/coins/images/24581/thumb/SpacePilogo200x200.png",
  "CTL": "https://assets.coingecko.com/coins/images/21282/thumb/eEuyEtJ.png",
  "BDOGE": "https://assets.coingecko.com/coins/images/20341/thumb/K4orzzy.png",
  "BTZ": "https://assets.coingecko.com/coins/images/24597/thumb/BTZ_Logo_%28200x200%29.png",
  "SHRM": "https://assets.coingecko.com/coins/images/5994/thumb/shrooms-coin.png",
  "VTAR": "https://assets.coingecko.com/coins/images/5790/thumb/8u9Ybnqk_400x400.jpg",
  "CANU": "https://assets.coingecko.com/coins/images/17340/thumb/canu.PNG",
  "SMR": "https://assets.coingecko.com/coins/images/27286/thumb/shimmer_mark_transparent_200x200.png",
  "TAI": "https://assets.coingecko.com/coins/images/28933/thumb/ava_%283%29.png",
  "RBO": "https://assets.coingecko.com/coins/images/21698/thumb/ROBOOT_LOGO_V2.png",
  "PIO": "https://assets.coingecko.com/coins/images/27687/thumb/logo.png",
  "ETM": "https://assets.coingecko.com/coins/images/18019/thumb/ethersmart.PNG",
  "USDZ": "https://assets.coingecko.com/coins/images/25909/thumb/20475.png",
  "GALI": "https://assets.coingecko.com/coins/images/28007/thumb/gali.jpg",
  "DOGEW": "https://assets.coingecko.com/coins/images/27518/thumb/dogepowlogo.png",
  "YAY": "https://assets.coingecko.com/coins/images/18251/thumb/spaces_-MacxYkWFZNLr04KrFOx_avatar-1626443663682.png",
  "LAR": "https://assets.coingecko.com/coins/images/8892/thumb/pB4iXZbU_400x400.jpg",
  "BLY": "https://assets.coingecko.com/coins/images/12095/thumb/Blocery_logo_200x200_.png",
  "INTL": "https://assets.coingecko.com/coins/images/28782/thumb/logo_200x200.jpeg",
  "ITSB": "https://assets.coingecko.com/coins/images/27268/thumb/ezgif-2-1826f94a8a.jpeg",
  "TOP1": "https://assets.coingecko.com/coins/images/26945/thumb/top1.png",
  "XGP": "https://assets.coingecko.com/coins/images/27420/thumb/en-us_%281%29.png",
  "$EARNY": "https://assets.coingecko.com/coins/images/27264/thumb/IMG-20220913-112019-700.jpg",
  "DOGS": "https://assets.coingecko.com/coins/images/29065/thumb/1024_%281%29.png",
  "AER": "https://assets.coingecko.com/coins/images/15528/thumb/BsoQCUVu_400x400.jpg",
  "LMR": "https://assets.coingecko.com/coins/images/24593/thumb/Lumerin-icon.png",
  "JINDOGE": "https://assets.coingecko.com/coins/images/15417/thumb/JINDOGE.png",
  "MST": "https://assets.coingecko.com/coins/images/27877/thumb/200*200.png",
  "EXO": "https://assets.coingecko.com/coins/images/15590/thumb/LOGO_200x200_CIRCLE.png",
  "MADCHAD": "https://assets.coingecko.com/coins/images/28101/thumb/Mad-Chad-Logo_%281%29.png",
  "COP": "https://assets.coingecko.com/coins/images/14923/thumb/cop.png",
  "MOJOV2": "https://assets.coingecko.com/coins/images/18320/thumb/tTxPCOr.png",
  "QTC": "https://assets.coingecko.com/coins/images/23903/thumb/qtc.png",
  "TITANIA": "https://assets.coingecko.com/coins/images/20457/thumb/titania.PNG",
  "ENTS": "https://assets.coingecko.com/coins/images/6524/thumb/eunomia.png",
  "SOME": "https://assets.coingecko.com/coins/images/15005/thumb/mixsome.PNG",
  "DGP": "https://assets.coingecko.com/coins/images/13291/thumb/dgpay-icon-logo.png",
  "FDC": "https://assets.coingecko.com/coins/images/27244/thumb/FDC_LOGO.jpg",
  "PRPS": "https://assets.coingecko.com/coins/images/2539/thumb/purpose.png",
  "CKP": "https://assets.coingecko.com/coins/images/25195/thumb/NRTOCIMm_400x400.png",
  "SAFESUN": "https://assets.coingecko.com/coins/images/15749/thumb/symbol_logo.png",
  "LIMC": "https://assets.coingecko.com/coins/images/24608/thumb/%D1%82%D0%BE%D0%BA%D0%B5%D0%BD.png",
  "NUME": "https://assets.coingecko.com/coins/images/24766/thumb/nume_token_logo.png",
  "NBP": "https://assets.coingecko.com/coins/images/20517/thumb/nft_bomb.PNG",
  "SHIRD": "https://assets.coingecko.com/coins/images/28074/thumb/CGLOGO-1.png",
  "GPT": "https://assets.coingecko.com/coins/images/2991/thumb/82EMDIqURn_Y_O021ulWgQ.png",
  "FEES": "https://assets.coingecko.com/coins/images/18815/thumb/89023206.png",
  "DINOP": "https://assets.coingecko.com/coins/images/17286/thumb/dinop.jpg",
  "ROCKETFI": "https://assets.coingecko.com/coins/images/26378/thumb/30jxINoE_400x400.jpeg",
  "XBS": "https://assets.coingecko.com/coins/images/231/thumb/bitstake.png",
  "GCN": "https://assets.coingecko.com/coins/images/384/thumb/gcb.png",
  "ZNE": "https://assets.coingecko.com/coins/images/477/thumb/zonecoin.png",
  "CMP": "https://assets.coingecko.com/coins/images/26555/thumb/20056.png",
  "METAFLIP": "https://assets.coingecko.com/coins/images/21663/thumb/16157.png",
  "PEA": "https://assets.coingecko.com/coins/images/25840/thumb/pea-big.1f28f16.png",
  "GMPD": "https://assets.coingecko.com/coins/images/21842/thumb/MdE0tFS0_400x400.jpg",
  "MIRO": "https://assets.coingecko.com/coins/images/1651/thumb/mirocana.jpg",
  "GIG": "https://assets.coingecko.com/coins/images/7521/thumb/Screenshot_2019-01-22_at_7.10.46_PM.png",
  "BRL": "https://assets.coingecko.com/coins/images/22089/thumb/auroraswap.png",
  "IC": "https://assets.coingecko.com/coins/images/2517/thumb/ignition.png",
  "JOB": "https://assets.coingecko.com/coins/images/9352/thumb/Logo_%283%29.png",
  "STOMB": "https://assets.coingecko.com/coins/images/23304/thumb/200stomb.png",
  "MEETONE": "https://assets.coingecko.com/coins/images/5460/thumb/meetone.png",
  "GRAM": "https://assets.coingecko.com/coins/images/6261/thumb/gram_token.png",
  "BSC": "https://assets.coingecko.com/coins/images/9238/thumb/image.png",
  "ETI": "https://assets.coingecko.com/coins/images/3734/thumb/etherinc.png",
  "MARSINU": "https://assets.coingecko.com/coins/images/20222/thumb/marsinu.png",
  "LST": "https://assets.coingecko.com/coins/images/3485/thumb/lst-icon.png",
  "PINU": "https://assets.coingecko.com/coins/images/19335/thumb/Piccolo-Inu.png",
  "PFAN": "https://assets.coingecko.com/coins/images/25899/thumb/white_logo.png",
  "WIS": "https://assets.coingecko.com/coins/images/13133/thumb/n0MTVBrm_400x400.jpg",
  "NEXUS": "https://assets.coingecko.com/coins/images/19091/thumb/x-on-trans-200x200.png",
  "XGK": "https://assets.coingecko.com/coins/images/6575/thumb/F8pONNqi_400x400.jpg",
  "NFTT": "https://assets.coingecko.com/coins/images/19297/thumb/nft_tech.PNG",
  "PLBT": "https://assets.coingecko.com/coins/images/766/thumb/polybius.png",
  "STW": "https://assets.coingecko.com/coins/images/27049/thumb/logo200x200.png",
  "ETER": "https://assets.coingecko.com/coins/images/21471/thumb/eter200px.png",
  "BWT": "https://assets.coingecko.com/coins/images/4777/thumb/bittwatt.png",
  "SHL": "https://assets.coingecko.com/coins/images/21502/thumb/shelling.png",
  "XSPECTAR": "https://assets.coingecko.com/coins/images/25894/thumb/xspectar_logo_transparent_png_1.png",
  "TIDE": "https://assets.coingecko.com/coins/images/28363/thumb/E6vrk6ih.jpg",
  "YORK": "https://assets.coingecko.com/coins/images/17558/thumb/polyyork.PNG",
  "UMAD": "https://assets.coingecko.com/coins/images/20939/thumb/UMAD_.png",
  "MATCH": "https://assets.coingecko.com/coins/images/28488/thumb/logo_%286%29.png",
  "MBK": "https://assets.coingecko.com/coins/images/25947/thumb/9076tn.png",
  "AMT": "https://assets.coingecko.com/coins/images/27246/thumb/AMT_200x200.png",
  "MLC": "https://assets.coingecko.com/coins/images/12471/thumb/61743002.png",
  "VEGAS": "https://assets.coingecko.com/coins/images/25905/thumb/third_about_img.png",
  "MCASH": "https://assets.coingecko.com/coins/images/18632/thumb/DdcZZeP_%282%29.png",
  "BNW": "https://assets.coingecko.com/coins/images/13828/thumb/1_dQKxZmGq8IWJAJHu6kDS5Q.png",
  "AGN": "https://assets.coingecko.com/coins/images/18524/thumb/logo_coingecko_%281%29.png",
  "ROG": "https://assets.coingecko.com/coins/images/25922/thumb/20490.png",
  "BCA": "https://assets.coingecko.com/coins/images/2555/thumb/bitcoin-atom.png",
  "BIGO": "https://assets.coingecko.com/coins/images/10564/thumb/Bigocoin_1200px.jpg",
  "PBIRB": "https://assets.coingecko.com/coins/images/28219/thumb/PARROTLY_BRAND_ASSETS__PARROTLY_ICON_BLUE_-_Copy_-_Copy_-_Copy.png",
  "MTRL": "https://assets.coingecko.com/coins/images/22069/thumb/square-200x200.png",
  "LEAF": "https://assets.coingecko.com/coins/images/6137/thumb/download_%2821%29.png",
  "FASTMOON": "https://assets.coingecko.com/coins/images/14908/thumb/7lE0hlG.png",
  "HTA": "https://assets.coingecko.com/coins/images/10885/thumb/download_%283%29.png",
  "BITCONEY": "https://assets.coingecko.com/coins/images/28672/thumb/BITCONEY_TOKEN_LOGO-200x200.png",
  "SEDO": "https://assets.coingecko.com/coins/images/6866/thumb/sedocoin-logo.jpg",
  "FINU": "https://assets.coingecko.com/coins/images/27882/thumb/LOGO.png",
  "SCOL": "https://assets.coingecko.com/coins/images/6933/thumb/scolcoin-logo.jpg",
  "MYRA": "https://assets.coingecko.com/coins/images/20470/thumb/mytheria.PNG",
  "LPL": "https://assets.coingecko.com/coins/images/14548/thumb/linkpool-logo-256x256.png",
  "BABYBUSD": "https://assets.coingecko.com/coins/images/17370/thumb/babybusd.PNG",
  "PXG": "https://assets.coingecko.com/coins/images/7242/thumb/pxg-coin_3x.png",
  "SHIBK": "https://assets.coingecko.com/coins/images/18606/thumb/shibk.png",
  "KAWAII": "https://assets.coingecko.com/coins/images/15999/thumb/keanuinu.PNG",
  "TRIP": "https://assets.coingecko.com/coins/images/11014/thumb/Tripedia.jpeg",
  "MALL": "https://assets.coingecko.com/coins/images/24183/thumb/gRlsG1JA_400x400.jpg",
  "MSH": "https://assets.coingecko.com/coins/images/18561/thumb/12096.png",
  "ETHPY": "https://assets.coingecko.com/coins/images/12417/thumb/Captura-de-Tela-2020-09-09-a-s-13-54-20.png",
  "OFI": "https://assets.coingecko.com/coins/images/18526/thumb/mgPOoQ8P_400x400.jpg",
  "PEM": "https://assets.coingecko.com/coins/images/25831/thumb/d4H_TCLF_400x400.jpg",
  "OTM": "https://assets.coingecko.com/coins/images/28112/thumb/logo_OTM_200x200.png",
  "GTE": "https://assets.coingecko.com/coins/images/21770/thumb/small-200x200-logo.png",
  "SAP": "https://assets.coingecko.com/coins/images/9109/thumb/sap.PNG",
  "LAVA": "https://assets.coingecko.com/coins/images/26858/thumb/13856.png",
  "OPNN": "https://assets.coingecko.com/coins/images/9358/thumb/53672059_554715805021197_5765693264996859904_n.jpg",
  "SYM": "https://assets.coingecko.com/coins/images/9449/thumb/Picture1.png",
  "KDOGE": "https://assets.coingecko.com/coins/images/16073/thumb/9Yia3_KZ_400x400.jpg",
  "IOC": "https://assets.coingecko.com/coins/images/106/thumb/IONH-LogoS.png",
  "TMC": "https://assets.coingecko.com/coins/images/25849/thumb/65e20074cf9e4bba897431f80da64ffd.jpeg",
  "KUBE": "https://assets.coingecko.com/coins/images/18668/thumb/Kube-Coin-Medium-Avatar.png",
  "SONO": "https://assets.coingecko.com/coins/images/7230/thumb/MeWTs09-_400x400.jpg",
  "YCT": "https://assets.coingecko.com/coins/images/18808/thumb/7W5vEIz-_400x400.jpg",
  "BTSHK": "https://assets.coingecko.com/coins/images/13437/thumb/1010.png",
  "SHM": "https://assets.coingecko.com/coins/images/27937/thumb/shm.png",
  "TRTT": "https://assets.coingecko.com/coins/images/4449/thumb/trittium-logo-icon-transparent-500x500.png",
  "NEKOS": "https://assets.coingecko.com/coins/images/24122/thumb/sVQIaHgA_400x400.jpg",
  "WPT": "https://assets.coingecko.com/coins/images/26431/thumb/200200.png",
  "DOGEKING": "https://assets.coingecko.com/coins/images/23349/thumb/kq245eMw_400x400.jpg",
  "MMSC": "https://assets.coingecko.com/coins/images/18695/thumb/MMSC_logo.png",
  "NXDT": "https://assets.coingecko.com/coins/images/27578/thumb/nxdt.png",
  "TNR": "https://assets.coingecko.com/coins/images/12168/thumb/tonestra_logo.jpg",
  "CHADS": "https://assets.coingecko.com/coins/images/12455/thumb/Chad_VC.png",
  "NFTBS": "https://assets.coingecko.com/coins/images/17291/thumb/200x200_newBook.png",
  "XDNA": "https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png",
  "AUOP": "https://assets.coingecko.com/coins/images/12847/thumb/AO200x200.png",
  "VCC": "https://assets.coingecko.com/coins/images/17391/thumb/Victorum-logo200X200.png",
  "ACRIA": "https://assets.coingecko.com/coins/images/28598/thumb/image002.png",
  "MAI": "https://assets.coingecko.com/coins/images/7581/thumb/mindsync-logo.jpg",
  "MEME20": "https://assets.coingecko.com/coins/images/17858/thumb/unnamed_%285%29.jpg",
  "KORI": "https://assets.coingecko.com/coins/images/21555/thumb/kori.png",
  "OXAI": "https://assets.coingecko.com/coins/images/28992/thumb/oxai-logo-200.png",
  "RICH": "https://assets.coingecko.com/coins/images/15337/thumb/RICH_logo_transparent.png",
  "FROG": "https://assets.coingecko.com/coins/images/21449/thumb/logo.54d7099f.png",
  "SAFEBANK": "https://assets.coingecko.com/coins/images/15367/thumb/SnCqyhNo_400x400.jpg",
  "SME": "https://assets.coingecko.com/coins/images/15529/thumb/safememe.png",
  "POLYGOLD": "https://assets.coingecko.com/coins/images/16055/thumb/wYRJKCT.png",
  "LOGE": "https://assets.coingecko.com/coins/images/27137/thumb/rsz_1ehyhuel_400x400.png",
  "GLDY": "https://assets.coingecko.com/coins/images/2585/thumb/lLY2uKBE_400x400.jpg",
  "ZARD": "https://assets.coingecko.com/coins/images/20749/thumb/FireZard_200.png",
  "XGBL": "https://assets.coingecko.com/coins/images/25635/thumb/NEW_xungible_logo_colour_XGBL_coin.png",
  "SW": "https://assets.coingecko.com/coins/images/15877/thumb/Logo-SafeWolf-white.png",
  "BABYADA": "https://assets.coingecko.com/coins/images/17540/thumb/BabyADA.png",
  "MAFA": "https://assets.coingecko.com/coins/images/22202/thumb/fm47C9GyesDTzt5oZN-pi_OPeK_pDY43-Nyx5uC_212zAnOspQKGp_s_m4H2f5MMfMbnb4Lyj2sFtIxpWCNPlsejZqwL243rcfeUZYZldeuuoxXE54-W5gGhuHA6QPhQIb0Er5Rr6CaeEC9Nh3SMcjYuUPhEacClLzXgjMKiqicxpM483qHLRO7aoibW9GBgWORbOUJnuGuLxycaeO5enQUgW1.jpg",
  "SMSCT": "https://assets.coingecko.com/coins/images/16014/thumb/logo200_%2813%29.png",
  "LANC": "https://assets.coingecko.com/coins/images/17589/thumb/lanceria.PNG",
  "ZET": "https://assets.coingecko.com/coins/images/27/thumb/zetacoin.png",
  "LF": "https://assets.coingecko.com/coins/images/20999/thumb/94867468.png",
  "CHEFCAKE": "https://assets.coingecko.com/coins/images/19976/thumb/chefcake.png",
  "FINT": "https://assets.coingecko.com/coins/images/21222/thumb/quExJXdW_400x400.png",
  "MOONRISE": "https://assets.coingecko.com/coins/images/16532/thumb/PhXXMYY.png",
  "HZM": "https://assets.coingecko.com/coins/images/18032/thumb/hzm.PNG",
  "EGS": "https://assets.coingecko.com/coins/images/24878/thumb/egs.png",
  "STOPELON": "https://assets.coingecko.com/coins/images/16136/thumb/photo_2021-06-04_18-28-00.png",
  "KONGTAMA": "https://assets.coingecko.com/coins/images/24313/thumb/Nl7zwYj.jpeg",
  "SMD": "https://assets.coingecko.com/coins/images/17760/thumb/smd.PNG",
  "UMC": "https://assets.coingecko.com/coins/images/1700/thumb/umbrellacoin.jpg",
  "BRAINS": "https://assets.coingecko.com/coins/images/21356/thumb/CVIaisb.png",
  "METAS": "https://assets.coingecko.com/coins/images/17443/thumb/metas.PNG",
  "AUNI": "https://assets.coingecko.com/coins/images/28083/thumb/Ov2LToF_%281%29.png",
  "PERL": "https://assets.coingecko.com/coins/images/4682/thumb/PERL.eco-Icon-green_6x.png",
  "HEDGEHOG": "https://assets.coingecko.com/coins/images/28552/thumb/hedgie.png",
  "FJT": "https://assets.coingecko.com/coins/images/25568/thumb/fjt.png",
  "ACRV": "https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg",
  "FIRU": "https://assets.coingecko.com/coins/images/17564/thumb/firulais.jpg",
  "COGE": "https://assets.coingecko.com/coins/images/16791/thumb/Dog-Logo_1x.png",
  "TOC": "https://assets.coingecko.com/coins/images/8003/thumb/touchcon.png",
  "DOGBOSS": "https://assets.coingecko.com/coins/images/24934/thumb/278095103_108041131873840_7864245311638218164_n.jpg",
  "BITGATTI": "https://assets.coingecko.com/coins/images/16962/thumb/bitgatti.PNG",
  "GNBT": "https://assets.coingecko.com/coins/images/15963/thumb/Gene-Bank-500px.jpg",
  "XPND": "https://assets.coingecko.com/coins/images/24520/thumb/2XyH_HyB_400x400.jpg",
  "METAM": "https://assets.coingecko.com/coins/images/22340/thumb/image.png",
  "HINA": "https://assets.coingecko.com/coins/images/15993/thumb/download.png",
  "POOF": "https://assets.coingecko.com/coins/images/28885/thumb/C3AD4384-2AB9-4364-8DEA-AE32A091F182.jpeg",
  "PUPDOGE": "https://assets.coingecko.com/coins/images/17473/thumb/pupdoge.PNG",
  "SUDO": "https://assets.coingecko.com/coins/images/27151/thumb/sudo.png",
  "ADAFLECT": "https://assets.coingecko.com/coins/images/18268/thumb/ADAFlect-Logo.png",
  "KALAM": "https://assets.coingecko.com/coins/images/19484/thumb/Kalamint_leaf_logo.png",
  "KOGECOIN": "https://assets.coingecko.com/coins/images/17546/thumb/koge.PNG",
  "BAIT": "https://assets.coingecko.com/coins/images/17616/thumb/bait.PNG",
  "FAVE": "https://assets.coingecko.com/coins/images/17749/thumb/200x200-favecoin.png",
  "JEJUDOGE": "https://assets.coingecko.com/coins/images/15983/thumb/jejudoge.png",
  "GODZ": "https://assets.coingecko.com/coins/images/19672/thumb/13559.png",
  "KDL": "https://assets.coingecko.com/coins/images/26969/thumb/kdl-logo.png",
  "HONEY": "https://assets.coingecko.com/coins/images/18380/thumb/ac6fTCfDQDIV.jpg",
  "NEVA": "https://assets.coingecko.com/coins/images/1421/thumb/nevacoin.png",
  "KEKW": "https://assets.coingecko.com/coins/images/18311/thumb/logo_black_%281%29.png",
  "GOC": "https://assets.coingecko.com/coins/images/3181/thumb/gocrypto.png",
  "SCIX": "https://assets.coingecko.com/coins/images/18367/thumb/LBNawZoQ_400x400.jpg",
  "JFM": "https://assets.coingecko.com/coins/images/19773/thumb/jf.PNG",
  "IDTT": "https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG",
  "CETF": "https://assets.coingecko.com/coins/images/18191/thumb/cetf_logo.png",
  "GLD": "https://assets.coingecko.com/coins/images/27654/thumb/logo.png",
  "SEREN": "https://assets.coingecko.com/coins/images/23412/thumb/seren.png",
  "NEGG": "https://assets.coingecko.com/coins/images/19676/thumb/nest_egg.PNG",
  "MTM": "https://assets.coingecko.com/coins/images/15671/thumb/momemtum-ok-moneda.png",
  "TRUSTNFT": "https://assets.coingecko.com/coins/images/20478/thumb/logo-3_%281%29.png",
  "SHER": "https://assets.coingecko.com/coins/images/27921/thumb/sher.png",
  "QUID": "https://assets.coingecko.com/coins/images/18995/thumb/PcJP5nK.png",
  "GMS": "https://assets.coingecko.com/coins/images/19001/thumb/gamestar.PNG",
  "ASET": "https://assets.coingecko.com/coins/images/19395/thumb/token_200.png",
  "MAGICGLP": "https://assets.coingecko.com/coins/images/29071/thumb/Token-mGLP.png",
  "MSC": "https://assets.coingecko.com/coins/images/23846/thumb/logo200.png",
  "OKG": "https://assets.coingecko.com/coins/images/27297/thumb/ookeenga_32.png",
  "CRB": "https://assets.coingecko.com/coins/images/28687/thumb/CRB-Logo.jpg",
  "BALA": "https://assets.coingecko.com/coins/images/19635/thumb/shambala.PNG",
  "POO DOGE": "https://assets.coingecko.com/coins/images/26999/thumb/A8308774-23F8-4367-9679-F8383A021498.png",
  "CHIM": "https://assets.coingecko.com/coins/images/19638/thumb/chimeras_icon_4_%282%29.png",
  "MGT": "https://assets.coingecko.com/coins/images/17683/thumb/cropped-cropped-mgtlogo.png",
  "RUUF": "https://assets.coingecko.com/coins/images/17968/thumb/44d23204-3919-4ba6-adaf-69a51b9621d8.png",
  "AFRO": "https://assets.coingecko.com/coins/images/19784/thumb/afrostar.PNG",
  "TERRA": "https://assets.coingecko.com/coins/images/19788/thumb/avaterra.PNG",
  "POORDOGE": "https://assets.coingecko.com/coins/images/20112/thumb/poordoge_logo.png",
  "NFTSTYLE": "https://assets.coingecko.com/coins/images/19928/thumb/nftstyle.PNG",
  "ASTRA": "https://assets.coingecko.com/coins/images/24083/thumb/Astra_%281%29_%281%29.png",
  "IBEX": "https://assets.coingecko.com/coins/images/27606/thumb/IqwOmX-c_400x400.jpeg",
  "YETIC": "https://assets.coingecko.com/coins/images/20493/thumb/yetic.png",
  "REAL": "https://assets.coingecko.com/coins/images/21448/thumb/2SIFWp0b_400x400.jpg",
  "PERPI": "https://assets.coingecko.com/coins/images/28272/thumb/logo.png",
  "DOGEAPE": "https://assets.coingecko.com/coins/images/27102/thumb/DOGEAPEx200-190x190.png",
  "RXCG": "https://assets.coingecko.com/coins/images/20989/thumb/evyjvQB.jpeg",
  "SOLIDETH": "https://assets.coingecko.com/coins/images/20867/thumb/solideth.png",
  "XTAG": "https://assets.coingecko.com/coins/images/20912/thumb/xtag.png",
  "CAPY": "https://assets.coingecko.com/coins/images/22000/thumb/logo_-_2021-12-27T143844.581.png",
  "TPV": "https://assets.coingecko.com/coins/images/21250/thumb/248041546_104759122006442_8579359337207343298_n.jpg",
  "$CATS": "https://assets.coingecko.com/coins/images/21387/thumb/ZQc8svVW_400x400.jpg",
  "LUNI": "https://assets.coingecko.com/coins/images/21291/thumb/luni.png",
  "MONGOOSE": "https://assets.coingecko.com/coins/images/21513/thumb/logo_mong.png",
  "AGGL": "https://assets.coingecko.com/coins/images/22265/thumb/aggle.PNG",
  "GMFLOKI": "https://assets.coingecko.com/coins/images/22755/thumb/N-0aM7p6_400x400.jpg",
  "CAKESWAP": "https://assets.coingecko.com/coins/images/21953/thumb/BRZnDZMh.png",
  "HEARN": "https://assets.coingecko.com/coins/images/23867/thumb/h-quadrata.png",
  "GU": "https://assets.coingecko.com/coins/images/17532/thumb/Logo_GU_512.png",
  "WMP": "https://assets.coingecko.com/coins/images/21892/thumb/logo-token_%281%29.png",
  "LAMA": "https://assets.coingecko.com/coins/images/22495/thumb/MetaLama_CG_submission.png",
  "NYT": "https://assets.coingecko.com/coins/images/21839/thumb/nyt_200.png",
  "$RFG": "https://assets.coingecko.com/coins/images/21432/thumb/rfg.png",
  "DRUN": "https://assets.coingecko.com/coins/images/21986/thumb/nAAX7HYj_400x400.jpg",
  "UNW": "https://assets.coingecko.com/coins/images/21276/thumb/unw.png",
  "ARI": "https://assets.coingecko.com/coins/images/22046/thumb/TFxLm5Xv_400x400.jpg",
  "ELONBANK": "https://assets.coingecko.com/coins/images/24722/thumb/200.png",
  "WEB3": "https://assets.coingecko.com/coins/images/26562/thumb/WEB3-TOKEN.png",
  "BINO": "https://assets.coingecko.com/coins/images/22591/thumb/GaNWFUwI_400x400.jpg",
  "PSY": "https://assets.coingecko.com/coins/images/22784/thumb/download.png",
  "DOGECUBE": "https://assets.coingecko.com/coins/images/22425/thumb/doge3-001.png",
  "BBK": "https://assets.coingecko.com/coins/images/22510/thumb/Final-Logo_1.png",
  "VSOL": "https://assets.coingecko.com/coins/images/23344/thumb/solidusS200x200.png",
  "GAR": "https://assets.coingecko.com/coins/images/22562/thumb/download.png",
  "BF": "https://assets.coingecko.com/coins/images/5330/thumb/BitForex-Logo.png",
  "HBG": "https://assets.coingecko.com/coins/images/22928/thumb/HBGiconlogo200.png",
  "MOONEY": "https://assets.coingecko.com/coins/images/22905/thumb/cVWTHdA.png",
  "VLK": "https://assets.coingecko.com/coins/images/25424/thumb/7DBYi_Zc_400x400.jpg",
  "BRB": "https://assets.coingecko.com/coins/images/22239/thumb/brb.png",
  "GRIM EVO": "https://assets.coingecko.com/coins/images/23329/thumb/image_%286%29.png",
  "EBYT": "https://assets.coingecko.com/coins/images/23491/thumb/Untitled-design-17.png",
  "AKITAX": "https://assets.coingecko.com/coins/images/23043/thumb/coingeckologoakitax.png",
  "CDTC": "https://assets.coingecko.com/coins/images/18921/thumb/horizontal-logo.png",
  "SPORK": "https://assets.coingecko.com/coins/images/23358/thumb/sporkdao.PNG",
  "DNL": "https://assets.coingecko.com/coins/images/23554/thumb/Logo-token200px.png",
  "SHIBTAMA": "https://assets.coingecko.com/coins/images/23362/thumb/shibtama.png",
  "META CAR": "https://assets.coingecko.com/coins/images/23446/thumb/YkjaZ6M.jpeg",
  "LFT": "https://assets.coingecko.com/coins/images/24846/thumb/e5x55-kU_400x400.jpg",
  "CRNO": "https://assets.coingecko.com/coins/images/27752/thumb/Chronoly.jpeg",
  "SEN": "https://assets.coingecko.com/coins/images/19847/thumb/sleepearn.PNG",
  "SCIE": "https://assets.coingecko.com/coins/images/20450/thumb/scienta.PNG",
  "LSWAP": "https://assets.coingecko.com/coins/images/24300/thumb/5D7QqYm.png",
  "STICKY": "https://assets.coingecko.com/coins/images/18610/thumb/kjJCd8WN_400x400.jpg",
  "HELPS": "https://assets.coingecko.com/coins/images/23858/thumb/helpslogo.png",
  "FINO": "https://assets.coingecko.com/coins/images/23993/thumb/fino.png",
  "QIN": "https://assets.coingecko.com/coins/images/24143/thumb/qin.png",
  "LUNCH": "https://assets.coingecko.com/coins/images/25808/thumb/200x200.png",
  "BLOS": "https://assets.coingecko.com/coins/images/21889/thumb/W51smE8.png",
  "WLD": "https://assets.coingecko.com/coins/images/24106/thumb/200px-cmc.png",
  "ZORO": "https://assets.coingecko.com/coins/images/24155/thumb/zoro.png",
  "INMER": "https://assets.coingecko.com/coins/images/26653/thumb/ezgif-com-gif-maker_%281%29.png",
  "HTL": "https://assets.coingecko.com/coins/images/8812/thumb/hotelium.PNG",
  "WZRD": "https://assets.coingecko.com/coins/images/24434/thumb/Icon_%281%29.jpg",
  "ZEUM": "https://assets.coingecko.com/coins/images/24448/thumb/AB0cGpnx_400x400.jpg",
  "$SRV": "https://assets.coingecko.com/coins/images/27755/thumb/SURVIVOR-COIN_%283%29.png",
  "HNC": "https://assets.coingecko.com/coins/images/1322/thumb/HNC-COIN-logo200X200.png",
  "TAX": "https://assets.coingecko.com/coins/images/24799/thumb/tax.png",
  "XRPAYNET": "https://assets.coingecko.com/coins/images/24806/thumb/New_CoinGecko_Logo.png",
  "FIST": "https://assets.coingecko.com/coins/images/25051/thumb/download.png",
  "KS": "https://assets.coingecko.com/coins/images/22444/thumb/YX0i-Zz-WP-1-1.png",
  "KTE": "https://assets.coingecko.com/coins/images/25225/thumb/3l4OKCIt_400x400.png",
  "CENNZ": "https://assets.coingecko.com/coins/images/3408/thumb/CENNZ_ticker.png",
  "ANATION": "https://assets.coingecko.com/coins/images/25174/thumb/Logo_Icon_ColorGradient200x200_nvsxez.png",
  "LFNTY": "https://assets.coingecko.com/coins/images/25406/thumb/LFNTY_s.png",
  "ELFT": "https://assets.coingecko.com/coins/images/25308/thumb/L-gWeX0PUsG32TXHRZC5k4sClQWI3EMJ5WCMCvklBbNq0dAlGe2DRnqmch_oUyFheOJS1zhxFGAoByR1bGwBNUDACZdn29vcjmvrbcNuGoeY9097HcE3ZrljFFw8_BD7L77MhHBePDvYTzRhwgySxMOVmZFFmkBHhAK18R-qFIQp_zfs0eOD1uj_aKvySIN4aTPEQsV88KZJk9RP-MlKkitR8l.jpg",
  "TRMX": "https://assets.coingecko.com/coins/images/28164/thumb/trmx.png",
  "PESA": "https://assets.coingecko.com/coins/images/25437/thumb/Pesabase-logo-200x200.png",
  "NEPT": "https://assets.coingecko.com/coins/images/26798/thumb/OH1UiJ16_400x400.jpeg",
  "CASHZ": "https://assets.coingecko.com/coins/images/26245/thumb/Cashzone.png",
  "DGZ": "https://assets.coingecko.com/coins/images/27195/thumb/logo-dogezone.png",
  "8BIT": "https://assets.coingecko.com/coins/images/28908/thumb/WhatsApp_Image_2023-01-18_at_12.38.16.jpeg",
  "HNG": "https://assets.coingecko.com/coins/images/19846/thumb/198364726_4177388515670795_6662680454680253231_n.jpg",
  "CFO": "https://assets.coingecko.com/coins/images/17592/thumb/CFO_logo_200_200px.png",
  "DYNMT": "https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg",
  "HCT": "https://assets.coingecko.com/coins/images/18912/thumb/hurricane.PNG",
  "LUNG": "https://assets.coingecko.com/coins/images/26592/thumb/4-FAECD18-620-B-421-E-A27-E-70-D58-DDCE11-B.png",
  "CENTRO": "https://assets.coingecko.com/coins/images/28353/thumb/logo.png",
  "DHLT": "https://assets.coingecko.com/coins/images/24839/thumb/DeHealth_FB.png",
  "QDT": "https://assets.coingecko.com/coins/images/26502/thumb/qdt_logo.png",
  "SAPP": "https://assets.coingecko.com/coins/images/8478/thumb/Sapphire-logo-transparent-no-text.jpg",
  "CKU": "https://assets.coingecko.com/coins/images/25813/thumb/Cryptoku.png",
  "CLS": "https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png",
  "WTR": "https://assets.coingecko.com/coins/images/27332/thumb/Frame_2932.png",
  "FNB": "https://assets.coingecko.com/coins/images/8157/thumb/FNB_protocol.jpg",
  "VNXAU": "https://assets.coingecko.com/coins/images/28019/thumb/wnxgold.png",
  "G": "https://assets.coingecko.com/coins/images/26126/thumb/MQrn5TGl_400x400.jpg",
  "RAMA": "https://assets.coingecko.com/coins/images/26166/thumb/43d17577-d8fa-432b-b71d-076b22c39825.png",
  "MVRC": "https://assets.coingecko.com/coins/images/28176/thumb/MoveRich_logo%28200x200%29.png",
  "CUBT": "https://assets.coingecko.com/coins/images/26197/thumb/photo-2022-04-20-13-35-28.jpg",
  "WRK": "https://assets.coingecko.com/coins/images/17282/thumb/blkwr.png",
  "ENCTR": "https://assets.coingecko.com/coins/images/26428/thumb/CG_ENCTR_TokenIMG_200x.png",
  "PMG": "https://assets.coingecko.com/coins/images/26549/thumb/%EC%A0%9C%EB%AA%A9_%EC%97%86%EC%9D%8C.png",
  "MAPLE": "https://assets.coingecko.com/coins/images/26548/thumb/logo.png",
  "JEN": "https://assets.coingecko.com/coins/images/26912/thumb/200x200.png",
  "OREO": "https://assets.coingecko.com/coins/images/28582/thumb/oreoswapx.png",
  "UZRS": "https://assets.coingecko.com/coins/images/26720/thumb/uzrs.png",
  "RAW": "https://assets.coingecko.com/coins/images/25277/thumb/Raw_Token_-_Outline.png",
  "IGUP": "https://assets.coingecko.com/coins/images/28434/thumb/IGUP_500x500.png",
  "DICE": "https://assets.coingecko.com/coins/images/26695/thumb/GY2KF8jw_400x400.jpeg",
  "ECS": "https://assets.coingecko.com/coins/images/27575/thumb/ecs_1000x1000.png",
  "BLEX": "https://assets.coingecko.com/coins/images/26854/thumb/200x200_bitlevex_logo.png",
  "SSLX": "https://assets.coingecko.com/coins/images/26935/thumb/StarSlax_%28SSLX%29.png",
  "OKEY": "https://assets.coingecko.com/coins/images/26821/thumb/OKEYCOIN_LOGO.png",
  "ALGF": "https://assets.coingecko.com/coins/images/26822/thumb/algf.png",
  "RUSH": "https://assets.coingecko.com/coins/images/25053/thumb/Kax1yBSz_400x400.png",
  "DCNT": "https://assets.coingecko.com/coins/images/27309/thumb/200-x-200-jpeg.jpg",
  "DECA": "https://assets.coingecko.com/coins/images/26845/thumb/deca_200px.png",
  "CTOSI": "https://assets.coingecko.com/coins/images/27035/thumb/TosiDrop.png",
  "ERO": "https://assets.coingecko.com/coins/images/21680/thumb/ero.PNG",
  "$HMT": "https://assets.coingecko.com/coins/images/27439/thumb/hmt.png",
  "PWFL": "https://assets.coingecko.com/coins/images/27892/thumb/78plUacg_400x400.jpeg",
  "TYPE": "https://assets.coingecko.com/coins/images/4019/thumb/type.png",
  "TOS": "https://assets.coingecko.com/coins/images/17878/thumb/tos.PNG",
  "MBASE": "https://assets.coingecko.com/coins/images/27313/thumb/mbase.png",
  "TAKE": "https://assets.coingecko.com/coins/images/27285/thumb/TAKE_token_200_x_200.png",
  "DING": "https://assets.coingecko.com/coins/images/27822/thumb/sL1uaaTq_400x400.jpeg",
  "RVLX": "https://assets.coingecko.com/coins/images/26534/thumb/circle.png",
  "NTH": "https://assets.coingecko.com/coins/images/27419/thumb/BmN8hNjJ_400x400.jpeg",
  "LCMG": "https://assets.coingecko.com/coins/images/27421/thumb/en-us_%282%29.png",
  "WKC": "https://assets.coingecko.com/coins/images/26037/thumb/Wiki-Logo.png",
  "BENX": "https://assets.coingecko.com/coins/images/27460/thumb/B3S1F8VA_400x400.jpeg",
  "UNIW": "https://assets.coingecko.com/coins/images/27487/thumb/UniWswap-05_%282%29.png",
  "CYC": "https://assets.coingecko.com/coins/images/14065/thumb/b3_DFjFp_400x400.jpg",
  "BYN": "https://assets.coingecko.com/coins/images/14746/thumb/token_symbol.png",
  "JOY": "https://assets.coingecko.com/coins/images/25369/thumb/joy.png",
  "RBD": "https://assets.coingecko.com/coins/images/28044/thumb/ZdhLra0r_400x400.jpeg",
  "TWITTERX": "https://assets.coingecko.com/coins/images/27809/thumb/200photo_2022-10-11_03-48-36.jpg",
  "MEAN": "https://assets.coingecko.com/coins/images/21557/thumb/89934951.png",
  "GSBL": "https://assets.coingecko.com/coins/images/28191/thumb/gasblock_logo_200xW.png",
  "00": "https://assets.coingecko.com/coins/images/27742/thumb/200x200.png",
  "DOGU": "https://assets.coingecko.com/coins/images/28492/thumb/Coingecko_Dogu_Inu_logo.jpg",
  "HAN": "https://assets.coingecko.com/coins/images/27374/thumb/logo_200.png",
  "PROP": "https://assets.coingecko.com/coins/images/28556/thumb/prop.png",
  "SUKI": "https://assets.coingecko.com/coins/images/29027/thumb/Sukiyaki.png",
  "METAG": "https://assets.coingecko.com/coins/images/23929/thumb/FlrPQkBM_400x400.jpg",
  "HIUNDEAD": "https://assets.coingecko.com/coins/images/29031/thumb/hiundead.png",
  "SOFT": "https://assets.coingecko.com/coins/images/28568/thumb/SI-S_200by200.png",
  "MMC": "https://assets.coingecko.com/coins/images/26608/thumb/zdtbAbZJ_400x400.jpeg",
  "RANB": "https://assets.coingecko.com/coins/images/28725/thumb/ranb.png",
  "SCULPT": "https://assets.coingecko.com/coins/images/28102/thumb/Sculptor_master.png",
  "CFARM": "https://assets.coingecko.com/coins/images/27880/thumb/IMG_20221019_070952_177.jpg",
  "TKB": "https://assets.coingecko.com/coins/images/27106/thumb/tokenbot-logo-icon.png",
  "AGX": "https://assets.coingecko.com/coins/images/28297/thumb/AGX-Coin_200.png",
  "SHIBGUN": "https://assets.coingecko.com/coins/images/28518/thumb/shibagun200x200px.png",
  "FISH": "https://assets.coingecko.com/coins/images/14359/thumb/Screen-Shot-2021-03-10-at-1-05-40-AM.png",
  "WSI": "https://assets.coingecko.com/coins/images/28386/thumb/wesendit_token_logo_%281%29.png",
  "MURA": "https://assets.coingecko.com/coins/images/28570/thumb/mura.png",
  "TORA": "https://assets.coingecko.com/coins/images/28531/thumb/z9b6m1yS_400x400.png",
  "CCHG": "https://assets.coingecko.com/coins/images/27789/thumb/C_Charge_logo_small_CMC.png",
  "3P": "https://assets.coingecko.com/coins/images/28626/thumb/123456.png",
  "BTSCRW": "https://assets.coingecko.com/coins/images/28634/thumb/HFDZGM.jpg",
  "GEON": "missing_thumb.png",
  "CTY": "https://assets.coingecko.com/coins/images/26702/thumb/IMG_20220804_011032_777.jpg",
  "PROX": "https://assets.coingecko.com/coins/images/28708/thumb/prox200b.png",
  "NINJAZ": "https://assets.coingecko.com/coins/images/29040/thumb/Untitled_design_%2812%29.png",
  "BRC": "https://assets.coingecko.com/coins/images/27334/thumb/brc.png",
  "SHIBN": "https://assets.coingecko.com/coins/images/28872/thumb/66EDE430-1CD7-4524-B610-FC58A7DCDA3E.jpeg",
  "BCNA": "https://assets.coingecko.com/coins/images/4716/thumb/bcna.png",
  "PEN": "https://assets.coingecko.com/coins/images/26105/thumb/6iWxdkG.png",
  "TWOPAW": "https://assets.coingecko.com/coins/images/29064/thumb/twopaws192x192.png",
  "SINU": "https://assets.coingecko.com/coins/images/26532/thumb/F0BFA676-6521-407F-8A09-EB2FA5E716BB.png",
  "TRXK": "https://assets.coingecko.com/coins/images/18710/thumb/Screenshot_2021-10-01_at_3.08.00_PM.png",
  "SK": "https://assets.coingecko.com/coins/images/16295/thumb/kyvXrEK.png",
  "NFTASCII": "https://assets.coingecko.com/coins/images/19975/thumb/nftasii.PNG",
  "EARN": "https://assets.coingecko.com/coins/images/13009/thumb/1_s2SlrgrX3CnRJIzUkRaFEA.jpeg",
  "XCA": "https://assets.coingecko.com/coins/images/25974/thumb/XCAVATOR.png",
  "CMETA": "https://assets.coingecko.com/coins/images/23681/thumb/logo_text-01.png",
  "SRAT": "https://assets.coingecko.com/coins/images/15970/thumb/logo_200.png",
  "SAGE": "https://assets.coingecko.com/coins/images/18967/thumb/polysage.PNG",
  "MBIRD": "https://assets.coingecko.com/coins/images/18978/thumb/logo_-_2021-10-13T113335.825.png",
  "ECG": "https://assets.coingecko.com/coins/images/25967/thumb/ECG200X200.png",
  "BWJ": "https://assets.coingecko.com/coins/images/22948/thumb/WOJ.png",
  "NMC": "https://assets.coingecko.com/coins/images/8/thumb/namecoin.png",
  "GICT": "https://assets.coingecko.com/coins/images/15759/thumb/gictrade.PNG",
  "TURN": "https://assets.coingecko.com/coins/images/26508/thumb/bigturn.jpeg",
  "WLBO": "https://assets.coingecko.com/coins/images/26134/thumb/wenl.png",
  "SPX": "https://assets.coingecko.com/coins/images/20249/thumb/logo_-_2021-11-12T151134.139.png",
  "MARSRISE": "https://assets.coingecko.com/coins/images/19029/thumb/marsrise.png",
  "CATZ": "https://assets.coingecko.com/coins/images/15519/thumb/MX0hFr7.jpeg",
  "GABECOIN": "https://assets.coingecko.com/coins/images/16276/thumb/2021-06-05_14.31.59.png",
  "MISA": "https://assets.coingecko.com/coins/images/25360/thumb/misa.png",
  "MOONARCH": "https://assets.coingecko.com/coins/images/15567/thumb/android-chrome-256x256.png",
  "ADMONKEY": "https://assets.coingecko.com/coins/images/17075/thumb/cropped-AdMonkey-Logo-250-PNG.png",
  "PIRA": "https://assets.coingecko.com/coins/images/21979/thumb/PIRA_coin_copy.png",
  "SIDD": "https://assets.coingecko.com/coins/images/24850/thumb/TVDrXd4pBw5Edjw7AfCpgGW7azGwBrBiwS.png",
  "SCX": "https://assets.coingecko.com/coins/images/19064/thumb/scx_gecko.png",
  "XBLZD": "https://assets.coingecko.com/coins/images/15281/thumb/android-chrome-512x512_%281%29.png",
  "HEEL": "https://assets.coingecko.com/coins/images/27138/thumb/Heel_Circle.png",
  "BNU": "https://assets.coingecko.com/coins/images/16000/thumb/logo.png",
  "ZCH": "https://assets.coingecko.com/coins/images/15156/thumb/logo_%2815%29.png",
  "FRACTION": "https://assets.coingecko.com/coins/images/15099/thumb/fraction.png",
  "CHAR": "https://assets.coingecko.com/coins/images/15095/thumb/456DC7B8-2651-4993-B8E7-5AD14756CC7E.png",
  "ELONHYPE": "https://assets.coingecko.com/coins/images/25354/thumb/logo-Coin-Gecko.png",
  "SPARK": "https://assets.coingecko.com/coins/images/19839/thumb/sparklab.PNG",
  "CHAM": "https://assets.coingecko.com/coins/images/26841/thumb/cham-200x200.png",
  "SHINNOSUKE": "https://assets.coingecko.com/coins/images/20134/thumb/shinchan.png",
  "SBFC": "https://assets.coingecko.com/coins/images/16147/thumb/sbfcoin.png",
  "DYST": "https://assets.coingecko.com/coins/images/25786/thumb/dystopialogo.jpg",
  "BFIGHT": "https://assets.coingecko.com/coins/images/27491/thumb/Safeimagekit-resized-imgpng_%2819%29.png",
  "BECO": "https://assets.coingecko.com/coins/images/19625/thumb/becoswap.PNG",
  "SER": "https://assets.coingecko.com/coins/images/27757/thumb/Untitled_design_%2810%29.png",
  "SH": "https://assets.coingecko.com/coins/images/15160/thumb/nfthero.PNG",
  "OW": "https://assets.coingecko.com/coins/images/16400/thumb/nBEhjyh.png",
  "ASSA": "https://assets.coingecko.com/coins/images/25575/thumb/assa_coin_logo-02.png",
  "NOA": "https://assets.coingecko.com/coins/images/15723/thumb/XHFjmBTx_400x400.png",
  "SCI": "https://assets.coingecko.com/coins/images/28359/thumb/WhatsApp_Image_2022-10-10_at_14.08.30_%281%29.png",
  "SMC1": "https://assets.coingecko.com/coins/images/27503/thumb/mvsyBzOGKm20JndbDQrD8jXwAVwVTIA87Xc1ULBqb7NcFCv0fnpN71bACOvyIbc798xCyssiXAtEmTyumW2APB_hdvz7E_wti4nFTHTSBclhMWXO5Osiilpb6haIfFFHp1VStEPZ-RiHJtIbiqzyqtnAoD7BE2flxQ1fBLYnzff8-_tQ9-olz-31m8kZQGMPvW7zDw9WFhm-xUGQUd8HJVsFo9_74cK_Ko3fb4sKLidVs0P.jpeg",
  "ETHE": "https://assets.coingecko.com/coins/images/22940/thumb/logomini.jpg",
  "HICLONEX": "https://assets.coingecko.com/coins/images/28436/thumb/hiclonex.png",
  "COVN": "https://assets.coingecko.com/coins/images/22280/thumb/16837.png",
  "BREWLABS": "https://assets.coingecko.com/coins/images/21928/thumb/7xgmOCBW_400x400.jpg",
  "CSX": "https://assets.coingecko.com/coins/images/14373/thumb/CSX-token-200.png",
  "ADAI": "https://assets.coingecko.com/coins/images/10843/thumb/aDAI.png",
  "INX": "https://assets.coingecko.com/coins/images/11694/thumb/logo_%EC%B0%90%EB%B8%94%EB%A3%A8.png",
  "ABAT": "https://assets.coingecko.com/coins/images/11726/thumb/aBAT.png",
  "KIBA": "https://assets.coingecko.com/coins/images/19525/thumb/kiba.png",
  "METAMOON": "https://assets.coingecko.com/coins/images/15744/thumb/ezgif-3-96b1d03006c5.png",
  "CADC": "https://assets.coingecko.com/coins/images/14149/thumb/cadc_2.png",
  "NFTZ": "https://assets.coingecko.com/coins/images/26454/thumb/gNBjBgOO_400x400.jpeg",
  "HECTA": "https://assets.coingecko.com/coins/images/26795/thumb/Icon_%281%29.png",
  "FRR": "https://assets.coingecko.com/coins/images/21658/thumb/uZsO4yI7_400x400.jpg",
  "IFC": "https://assets.coingecko.com/coins/images/21066/thumb/logo-infinity-cash-BETA-Piece%28formatpetit%29.png",
  "FAKT": "https://assets.coingecko.com/coins/images/25248/thumb/fxOi9ZYI_400x400.png",
  "WEAR": "https://assets.coingecko.com/coins/images/24339/thumb/MW_icon.png",
  "HMOON": "https://assets.coingecko.com/coins/images/19585/thumb/hmoon.png",
  "WTIP": "https://assets.coingecko.com/coins/images/4276/thumb/pkPT1UZ.png",
  "ECTR": "https://assets.coingecko.com/coins/images/27511/thumb/vW1LS7PgPcq9R39knqB_zb9bsVoI8yknrUQ5yIA3mVQp9gJDjSP7C-CMjZeVhavUoPSI32aCLlIwtvlyIXOdk8oKppSqEQ6ZckU9DO4Z429LUJV2vfAnsknft6Gl8bkKjktX2nZXYRHagLRfCgvcVvjDbr-EcmNi3gqgBN2liRqHNmaXJ809xCnnzIxA1Yf2fKX-PlZqxShwMSL7VICFLYo61S23HvL5nK3f4kb-94IQ5cd.jpeg",
  "SHIBANFT": "https://assets.coingecko.com/coins/images/23737/thumb/GiIJ9511_400x400.jpg",
  "LUNAPAD": "https://assets.coingecko.com/coins/images/19640/thumb/LUNAPAD_logo.png",
  "DIGIS": "https://assets.coingecko.com/coins/images/27202/thumb/digiswap.jpeg",
  "PORTO": "https://assets.coingecko.com/coins/images/20459/thumb/fcp.jpg",
  "BOTX": "https://assets.coingecko.com/coins/images/6827/thumb/botx.jpg",
  "ASX": "https://assets.coingecko.com/coins/images/27492/thumb/asx.png",
  "CHUBBIES20": "https://assets.coingecko.com/coins/images/17857/thumb/unnamed_%281%29.gif",
  "CBD": "https://assets.coingecko.com/coins/images/15284/thumb/GreenHeart-CBD-Logo-200x200px.png",
  "SDLN": "https://assets.coingecko.com/coins/images/22399/thumb/0AOtDEFi_400x400.png",
  "BBR": "https://assets.coingecko.com/coins/images/13673/thumb/1_AdMyBccrRv0e6rhW7UKJSw.png",
  "PLUT": "https://assets.coingecko.com/coins/images/27964/thumb/LeQ9aIhU_400x400.jpeg",
  "EVOAI": "https://assets.coingecko.com/coins/images/28964/thumb/EvolveAI.png",
  "RCNT": "https://assets.coingecko.com/coins/images/24135/thumb/RCNTToken.png",
  "WEB5": "https://assets.coingecko.com/coins/images/27027/thumb/IMG_20220812_165146_695.png",
  "SHINJA": "https://assets.coingecko.com/coins/images/20545/thumb/SibnoboMainIcon3_3x.png",
  "ZEIT": "https://assets.coingecko.com/coins/images/65/thumb/zeitlogo.png",
  "HDL": "https://assets.coingecko.com/coins/images/25676/thumb/85418370.png",
  "DNS": "https://assets.coingecko.com/coins/images/25675/thumb/1FVbo3BN_400x400.jpeg",
  "FUND": "https://assets.coingecko.com/coins/images/7845/thumb/DV80FOp.png",
  "SHIBORG": "https://assets.coingecko.com/coins/images/23505/thumb/_-5o_lop_400x400.jpg",
  "WFT": "https://assets.coingecko.com/coins/images/20230/thumb/vf.PNG",
  "MILO": "https://assets.coingecko.com/coins/images/23896/thumb/milo.png",
  "DOGEDEAD": "https://assets.coingecko.com/coins/images/23801/thumb/23F00458-004A-46B0-ADBC-FECE786B1FAD.jpeg",
  "GORGEOUS": "https://assets.coingecko.com/coins/images/19957/thumb/gorgeous.png",
  "TRIX": "https://assets.coingecko.com/coins/images/12270/thumb/TRIX_Logo_transparent.png",
  "OXD V2": "https://assets.coingecko.com/coins/images/24468/thumb/rjks-OoT_400x400.jpeg",
  "YTV": "https://assets.coingecko.com/coins/images/14753/thumb/200x200-removebg-preview.png",
  "YLB": "https://assets.coingecko.com/coins/images/21363/thumb/yearnlab.png",
  "NOID": "https://assets.coingecko.com/coins/images/19945/thumb/tokenoid.png",
  "CU": "https://assets.coingecko.com/coins/images/27184/thumb/logo_catchup-03.png",
  "SHN": "https://assets.coingecko.com/coins/images/20555/thumb/SHN-Logo.png",
  "BFPS": "https://assets.coingecko.com/coins/images/27569/thumb/photo_2022-09-29_22.01.39.jpeg",
  "OCB": "https://assets.coingecko.com/coins/images/12315/thumb/200x200-01.png",
  "REDSTONE": "https://assets.coingecko.com/coins/images/27174/thumb/jf6E8c-K_400x400.png",
  "BTCV": "https://assets.coingecko.com/coins/images/16717/thumb/logo_-_2021-06-28T092740.647.png",
  "AREN": "https://assets.coingecko.com/coins/images/12483/thumb/aREN.png",
  "YDA": "https://assets.coingecko.com/coins/images/13493/thumb/yadacoinlogosmall200x200.png",
  "CARE": "https://assets.coingecko.com/coins/images/28378/thumb/46FD299C-3DA4-43E6-A6A2-27F492FC7E4D.jpeg",
  "SF": "https://assets.coingecko.com/coins/images/27686/thumb/SphynxFi-b.png",
  "MXW": "https://assets.coingecko.com/coins/images/9792/thumb/NomTcazP_400x400.jpg",
  "OCTF": "https://assets.coingecko.com/coins/images/22746/thumb/17432.png",
  "RIV2": "https://assets.coingecko.com/coins/images/20686/thumb/logo.png",
  "ANV": "https://assets.coingecko.com/coins/images/16348/thumb/anv.PNG",
  "GFULL": "https://assets.coingecko.com/coins/images/27535/thumb/IMG_20220926_213043_820.jpg",
  "UCA": "https://assets.coingecko.com/coins/images/11032/thumb/UCA.png",
  "BHT": "https://assets.coingecko.com/coins/images/24986/thumb/gWu9bO3X_400x400.jpg",
  "SMARTNFT": "https://assets.coingecko.com/coins/images/20184/thumb/SmartNFT.png",
  "SMARTLOX": "https://assets.coingecko.com/coins/images/20185/thumb/SmartLOX.png",
  "MTO": "https://assets.coingecko.com/coins/images/17157/thumb/mto.PNG",
  "DKC": "https://assets.coingecko.com/coins/images/20232/thumb/duke-coin-profile.png",
  "AJE": "https://assets.coingecko.com/coins/images/21283/thumb/ajeverse.PNG",
  "KDAG": "https://assets.coingecko.com/coins/images/10809/thumb/3xcLUorv_400x400.jpg",
  "IDOODLES": "https://assets.coingecko.com/coins/images/28984/thumb/idoodles.png",
  "FTB": "https://assets.coingecko.com/coins/images/18871/thumb/ftb.png",
  "BITS": "https://assets.coingecko.com/coins/images/250/thumb/bitswift.png",
  "HRDG": "https://assets.coingecko.com/coins/images/22769/thumb/hrdg_logo_200X200.png",
  "MPLX": "https://assets.coingecko.com/coins/images/27344/thumb/mplx.png",
  "URX": "https://assets.coingecko.com/coins/images/6812/thumb/uraniumx.png",
  "FDAI": "https://assets.coingecko.com/coins/images/28473/thumb/fDAI-200x200.png",
  "FCH": "https://assets.coingecko.com/coins/images/10692/thumb/ApIehDnV_400x400.jpg",
  "AENJ": "https://assets.coingecko.com/coins/images/12484/thumb/aENJ.png",
  "BITH": "https://assets.coingecko.com/coins/images/11765/thumb/bith3.png",
  "PANDA": "https://assets.coingecko.com/coins/images/20500/thumb/panda.png",
  "GOM2": "https://assets.coingecko.com/coins/images/10374/thumb/lvAhDIqmH0fh6U3NIiYLmKETR3uUBcySAv-K28eW6CCFm-ODhCdId71Ug5c4TCoEtxsre30Efe08muctK0MlK-JPdAbxilzZ7dHyiBNOCvcc_9AmJIo09TRLaiAafgqcFKsxpNOON2D28oTLnVTaqwxWL8zKSzjbI6ChKTCJKOiM2mq7VhQRZYe93StR30mf2O7DnkqmGEbZ5_i.jpg",
  "SHIBELON": "https://assets.coingecko.com/coins/images/20434/thumb/shibelon.png",
  "TIKTOKEN": "https://assets.coingecko.com/coins/images/27440/thumb/tiktoken.png",
  "SHIBAPUP": "https://assets.coingecko.com/coins/images/15328/thumb/6096950d4ec27bf003a1cdd0_200.png",
  "ZURR": "https://assets.coingecko.com/coins/images/22240/thumb/logo.png",
  "ANTS": "https://assets.coingecko.com/coins/images/11179/thumb/ants200.png",
  "NTRS": "https://assets.coingecko.com/coins/images/9990/thumb/47010860_203643113858754_2966343352238211072_n.jpg",
  "CROBLANC": "https://assets.coingecko.com/coins/images/22633/thumb/1J-t8_cq_400x400.jpg",
  "PLF": "https://assets.coingecko.com/coins/images/10313/thumb/BNXcW4kq_400x400.jpg",
  "ELITE": "https://assets.coingecko.com/coins/images/1062/thumb/elite.png",
  "LOCO": "https://assets.coingecko.com/coins/images/25842/thumb/pj6iF0Q7_400x400.jpg",
  "NSS": "https://assets.coingecko.com/coins/images/9501/thumb/adddcf817f.png",
  "BIU": "https://assets.coingecko.com/coins/images/28990/thumb/cat_%281%29.png",
  "SHIT": "https://assets.coingecko.com/coins/images/25945/thumb/PCNoOl8.png",
  "CCM": "https://assets.coingecko.com/coins/images/17119/thumb/CarCoin_icon_vector.png",
  "AFNTY": "https://assets.coingecko.com/coins/images/27345/thumb/YPtUpB2I_400x400.jpeg",
  "SAITAX": "https://assets.coingecko.com/coins/images/19419/thumb/6mrg5N1.png",
  "EZY": "https://assets.coingecko.com/coins/images/28250/thumb/ezzy_app_icon.png",
  "BTON": "https://assets.coingecko.com/coins/images/28991/thumb/btonlogo.png",
  "PURE": "https://assets.coingecko.com/coins/images/12600/thumb/200x200_pure_logo.png",
  "BIZZ": "https://assets.coingecko.com/coins/images/10979/thumb/bizzcoin.PNG",
  "UNBNK": "https://assets.coingecko.com/coins/images/20171/thumb/logo_-_2021-11-11T115427.135.png",
  "DKN": "https://assets.coingecko.com/coins/images/20283/thumb/Qu5sof9.png",
  "AZRX": "https://assets.coingecko.com/coins/images/11735/thumb/aZRX.png",
  "ANTZ": "https://assets.coingecko.com/coins/images/27486/thumb/Pxy6Fbi9_400x400.jpeg",
  "DVK": "https://assets.coingecko.com/coins/images/22629/thumb/dvk.png",
  "WRB3G": "https://assets.coingecko.com/coins/images/27351/thumb/IMG_20220919_114203_341.jpg",
  "BUC": "https://assets.coingecko.com/coins/images/11561/thumb/XF7boZ2M_400x400.jpg",
  "CRN": "https://assets.coingecko.com/coins/images/24022/thumb/h8GHzr2W_400x400.jpg",
  "TMED": "https://assets.coingecko.com/coins/images/8558/thumb/bAdnHTWUIl4rFB4EfbH2L50BW7xi2T9ubci7v6xfYDipXkrG8Wb4LS8g6eAiMsSKqLJfV-FjH5TrYALqACw4MM2A9NfK_L6Ujnddhw1uEgL0hmVQnY96xZeL0C4kkFRJalreK9wUBzjZ4pRhZLdQf9h1drSTF7NE9moAdQ7KtuOvJqommn_q-TjQpF8GUB7J2Ejs7zIvhXXsUHGI1.jpg",
  "GDO": "https://assets.coingecko.com/coins/images/22204/thumb/logo.png",
  "P2PTXT": "https://assets.coingecko.com/coins/images/27720/thumb/19121.png",
  "DOI": "https://assets.coingecko.com/coins/images/7981/thumb/37300173.png",
  "ABLC": "https://assets.coingecko.com/coins/images/27670/thumb/ablc.png",
  "BELL": "https://assets.coingecko.com/coins/images/8290/thumb/bitcoin256.png",
  "DGLN": "https://assets.coingecko.com/coins/images/21133/thumb/logo_%285%29.png",
  "MTB": "https://assets.coingecko.com/coins/images/23529/thumb/MTBlogo200.png",
  "ASIX+": "https://assets.coingecko.com/coins/images/25323/thumb/logo_%282%29.png",
  "AMKR": "https://assets.coingecko.com/coins/images/11731/thumb/aMKR.png",
  "POCO": "https://assets.coingecko.com/coins/images/18293/thumb/poco.PNG",
  "APISH": "https://assets.coingecko.com/coins/images/25592/thumb/token.png",
  "CXPAD": "https://assets.coingecko.com/coins/images/18626/thumb/cxpad.png",
  "HORSEA": "https://assets.coingecko.com/coins/images/28394/thumb/HorseaLogo_%281%29.png",
  "ASNX": "https://assets.coingecko.com/coins/images/11733/thumb/aSNX.png",
  "NYAN": "https://assets.coingecko.com/coins/images/18309/thumb/Qmf8DSV4RbSuVRLJvmj4WwEMjwtYcCd53EFXPPjkEwzx4B.png",
  "QUA": "https://assets.coingecko.com/coins/images/22465/thumb/Pm9J4C3KD-5W41f2VH2IIWUY3gRCNJqTyu7X4SQt0ptuUYnhm68zPVBxTEgehAE_WSZBSz_TzxLfAWu8P7a8zOSZa8MpuqUmntsWVeK01s7IoEBERumgQq1mcTuxyOQXBH68OZBkC_hqVEiSf4vNw_EgbtCSATx6XTaLUmCO33kTaolgB5CU33MiINZkwvRDsexAQnxT7BWO6F.jpg",
  "PTI": "https://assets.coingecko.com/coins/images/7623/thumb/paytomat.png",
  "DAMO": "https://assets.coingecko.com/coins/images/23419/thumb/61fca5f0a6d496a64342a1d5_twitter_logo_%281%29.png",
  "SAFENAMI": "https://assets.coingecko.com/coins/images/15479/thumb/safenami.png",
  "ATYNE": "https://assets.coingecko.com/coins/images/15855/thumb/ate.PNG",
  "GUAP": "https://assets.coingecko.com/coins/images/11592/thumb/Guapcoin-1-300x300-4-e1591555244759.png",
  "C24": "https://assets.coingecko.com/coins/images/24664/thumb/cICWHcG.jpg",
  "LDOGE": "https://assets.coingecko.com/coins/images/257/thumb/shibainucoinblue3.png",
  "EZI": "https://assets.coingecko.com/coins/images/25472/thumb/ezi.jpg",
  "TRZ": "https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png",
  "SAFESTAR": "https://assets.coingecko.com/coins/images/15612/thumb/safestar.PNG",
  "SPIZ": "https://assets.coingecko.com/coins/images/12213/thumb/aEXTI0vf_400x400.jpg",
  "IMPACTXP": "https://assets.coingecko.com/coins/images/21118/thumb/TJpEKVBW_400x400.jpg",
  "JBX": "https://assets.coingecko.com/coins/images/21120/thumb/CCeIEmvE_400x400.jpg",
  "AKNC": "https://assets.coingecko.com/coins/images/11727/thumb/aKNC.png",
  "NVC": "https://assets.coingecko.com/coins/images/15/thumb/novacoin.png",
  "WIX": "https://assets.coingecko.com/coins/images/28733/thumb/tradewix.png",
  "DAB": "https://assets.coingecko.com/coins/images/25275/thumb/basc-coin.png",
  "APE$": "https://assets.coingecko.com/coins/images/14510/thumb/ape-punk-shards.png",
  "JPYC": "https://assets.coingecko.com/coins/images/17277/thumb/WoZ8rruL_400x400.png",
  "RATS": "https://assets.coingecko.com/coins/images/24612/thumb/rats.png",
  "PEPEBET": "https://assets.coingecko.com/coins/images/27970/thumb/IMG_20221025_143614_101.jpg",
  "FRAG": "https://assets.coingecko.com/coins/images/27999/thumb/264931173_126331099842141_4644032570266092138_n.jpeg",
  "ETHERNAL": "https://assets.coingecko.com/coins/images/25594/thumb/ethernal-200px.png",
  "DOGW": "missing_thumb.png",
  "ZITI": "https://assets.coingecko.com/coins/images/15255/thumb/logo200x200_%284%29.png",
  "BSP": "https://assets.coingecko.com/coins/images/14050/thumb/bsp.png",
  "WBOND": "https://assets.coingecko.com/coins/images/22991/thumb/17528.png",
  "CFTI": "https://assets.coingecko.com/coins/images/24524/thumb/confetti_28.png",
  "OLINK": "https://assets.coingecko.com/coins/images/27842/thumb/2002.png",
  "DIQ": "https://assets.coingecko.com/coins/images/21572/thumb/diq-200.png",
  "IXIR": "https://assets.coingecko.com/coins/images/23884/thumb/CMC_CG_%282%29.png",
  "IWR": "https://assets.coingecko.com/coins/images/22602/thumb/ahdQANE.png",
  "I9X": "https://assets.coingecko.com/coins/images/14124/thumb/unnamed_%282%29.jpg",
  "DOTR": "https://assets.coingecko.com/coins/images/28109/thumb/dotr.png",
  "GGT": "https://assets.coingecko.com/coins/images/28125/thumb/200-icon.png",
  "ZAFI": "https://assets.coingecko.com/coins/images/28138/thumb/Logo-Zakumi.png",
  "DOM": "https://assets.coingecko.com/coins/images/22631/thumb/ancient_kingdom.PNG",
  "AEM": "https://assets.coingecko.com/coins/images/7424/thumb/AEM_logo_only_400px_400px_2px_spacing_red_blue.png",
  "BNANA": "https://assets.coingecko.com/coins/images/7522/thumb/banana-token-logo.jpg",
  "ELM": "https://assets.coingecko.com/coins/images/17723/thumb/cropped-tokens2.png",
  "AMZ": "https://assets.coingecko.com/coins/images/11830/thumb/67655540_2134166093379460_7734829864909275136_o.png",
  "IJC": "https://assets.coingecko.com/coins/images/14099/thumb/Ijascode-Flat-Logo-2-24-2021.png",
  "CANDYLAD": "https://assets.coingecko.com/coins/images/20938/thumb/8Q1tiqi0_400x400.jpg",
  "EPICHERO": "https://assets.coingecko.com/coins/images/18449/thumb/epichero.png",
  "$BEAT": "https://assets.coingecko.com/coins/images/28247/thumb/%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9_%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A1%E1%84%80%E1%85%A1%E1%86%A8%E1%84%92%E1%85%A7%E1%86%BC_%E1%84%87%E1%85%B3%E1%86%AF%E1%84%85%E1%85%AE.png",
  "APK": "https://assets.coingecko.com/coins/images/27343/thumb/logo_%281%29.png",
  "ORT": "https://assets.coingecko.com/coins/images/27580/thumb/ort.png",
  "SOKU": "https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png",
  "BUFFS": "https://assets.coingecko.com/coins/images/21189/thumb/buffsw.PNG",
  "ICOL": "https://assets.coingecko.com/coins/images/8190/thumb/logo.icolcoin.256.x.256.png",
  "ELONGATE": "https://assets.coingecko.com/coins/images/15017/thumb/fRRPDnh4_400x400.jpg",
  "XGS": "https://assets.coingecko.com/coins/images/4704/thumb/genesisx.png",
  "TKG": "https://assets.coingecko.com/coins/images/17208/thumb/takamaka_io_200_200.png",
  "RSC": "https://assets.coingecko.com/coins/images/28146/thumb/RH_Logo_200x200.png",
  "HIGAZERS": "https://assets.coingecko.com/coins/images/28316/thumb/higazers.png",
  "ACEC": "https://assets.coingecko.com/coins/images/26314/thumb/IMG_20220707_112252_931.jpg",
  "MESA": "https://assets.coingecko.com/coins/images/21726/thumb/mymessage.PNG",
  "QDROP": "https://assets.coingecko.com/coins/images/21463/thumb/quizdrop.PNG",
  "TROVE": "https://assets.coingecko.com/coins/images/24764/thumb/coin.png",
  "SAFEST": "https://assets.coingecko.com/coins/images/21480/thumb/safefidu.PNG",
  "CMCC": "https://assets.coingecko.com/coins/images/21494/thumb/cmc.png",
  "DOR": "https://assets.coingecko.com/coins/images/21143/thumb/13977.png",
  "ADVT": "https://assets.coingecko.com/coins/images/28549/thumb/advantis.jpg",
  "MES": "https://assets.coingecko.com/coins/images/9719/thumb/TtPovcoX_400x400.jpg",
  "$KSH": "https://assets.coingecko.com/coins/images/21353/thumb/lkdYjrVS_400x400.jpg",
  "TOOB": "https://assets.coingecko.com/coins/images/25211/thumb/19328.png",
  "MONSTR": "https://assets.coingecko.com/coins/images/21887/thumb/yn3q0se.png",
  "CATS": "https://assets.coingecko.com/coins/images/23569/thumb/17318.png",
  "KOKO": "https://assets.coingecko.com/coins/images/15205/thumb/Kokoswap.png",
  "EMPYR": "https://assets.coingecko.com/coins/images/22382/thumb/Profile_Image_001_07.png",
  "ISR": "https://assets.coingecko.com/coins/images/4888/thumb/insureum.png",
  "PHL": "https://assets.coingecko.com/coins/images/7095/thumb/placeh.png",
  "SWAN": "missing_thumb.png",
  "SCAN": "https://assets.coingecko.com/coins/images/17931/thumb/Logo-scandefi002.png",
  "FIC": "https://assets.coingecko.com/coins/images/7148/thumb/fic.png",
  "EMG": "https://assets.coingecko.com/coins/images/28516/thumb/EMG_APP_logo-removebg-preview.png",
  "VTT": "https://assets.coingecko.com/coins/images/23484/thumb/VTTLogo.png",
  "HDOGE": "https://assets.coingecko.com/coins/images/15787/thumb/hdoge_logo.jpg",
  "METAPETS": "https://assets.coingecko.com/coins/images/20695/thumb/metapets.png",
  "GOMINT": "https://assets.coingecko.com/coins/images/25884/thumb/gomint.png",
  "CIRQ": "https://assets.coingecko.com/coins/images/10739/thumb/CIRQUITY-ICON.png",
  "MTAO": "https://assets.coingecko.com/coins/images/28802/thumb/080A8E4D-5FBB-4567-89E4-1C34224BAC68.jpeg",
  "XQN": "https://assets.coingecko.com/coins/images/5613/thumb/quotient.png",
  "POS": "https://assets.coingecko.com/coins/images/7484/thumb/kemiu3jW_400x400.jpg",
  "TPH": "https://assets.coingecko.com/coins/images/22368/thumb/trustpay.PNG",
  "RADR": "https://assets.coingecko.com/coins/images/23334/thumb/Vt0zWsHQ_400x400.jpg",
  "KARA": "https://assets.coingecko.com/coins/images/21744/thumb/2AEMfaC4nJimG5B.png",
  "TAGR": "https://assets.coingecko.com/coins/images/3093/thumb/tagrcoin.png",
  "SONG": "https://assets.coingecko.com/coins/images/2720/thumb/songcoin.png",
  "MTRA": "https://assets.coingecko.com/coins/images/24276/thumb/Logo_200x200_PNG.png",
  "API": "https://assets.coingecko.com/coins/images/14055/thumb/Screenshot_2022-03-10_at_11.09.50_AM.png",
  "PXI": "https://assets.coingecko.com/coins/images/2674/thumb/primexi.png",
  "MOTO": "https://assets.coingecko.com/coins/images/2648/thumb/motocoin.png",
  "OTHR": "https://assets.coingecko.com/coins/images/25882/thumb/othr.png",
  "TOPC": "https://assets.coingecko.com/coins/images/2547/thumb/topchain.png",
  "MWAR": "https://assets.coingecko.com/coins/images/19919/thumb/LOGO-3.png",
  "BSCAKE": "https://assets.coingecko.com/coins/images/19826/thumb/bunscake.PNG",
  "LSVR": "https://assets.coingecko.com/coins/images/26681/thumb/E312D296-5EB2-4CF1-B98C-A2E47736BA37.jpeg",
  "RTO": "https://assets.coingecko.com/coins/images/20483/thumb/cmc-logo.png",
  "EVERSAFU": "https://assets.coingecko.com/coins/images/24494/thumb/MtFSM75.png",
  "MBONK": "https://assets.coingecko.com/coins/images/14996/thumb/Bonk_v2_gecko.png",
  "DOGEPUP": "https://assets.coingecko.com/coins/images/26930/thumb/dogepup_logo.png",
  "ORLY": "https://assets.coingecko.com/coins/images/3098/thumb/orlycoin.png",
  "CPT": "https://assets.coingecko.com/coins/images/2481/thumb/n3w6Z9xW_400x400.jpg",
  "FXL": "https://assets.coingecko.com/coins/images/18706/thumb/_x-RXA_A_400x400.png",
  "QUON": "https://assets.coingecko.com/coins/images/28769/thumb/22BF9726-E84F-4ED4-ABEB-04F4DCB15926.jpeg",
  "PSDN": "https://assets.coingecko.com/coins/images/24225/thumb/poseidon-pp-TWITTER-PROFILE-PICTURE.png",
  "CRBRUS": "https://assets.coingecko.com/coins/images/24509/thumb/AUdZyrmO_400x400.png",
  "AYA": "https://assets.coingecko.com/coins/images/8456/thumb/logo_%2813%29.png",
  "SHINTAMA": "https://assets.coingecko.com/coins/images/23405/thumb/shintama.png",
  "HP": "https://assets.coingecko.com/coins/images/21951/thumb/FA_Hero_Park_Logo_Blue.png",
  "TEX": "https://assets.coingecko.com/coins/images/20964/thumb/7gyi5TV8_400x400.jpg",
  "YKS": "https://assets.coingecko.com/coins/images/25333/thumb/YourKiss_200.png",
  "FGI": "https://assets.coingecko.com/coins/images/26039/thumb/COKaaBl.png",
  "OKFLY": "https://assets.coingecko.com/coins/images/18383/thumb/7vY1t_Tn_400x400.png",
  "KDAO": "https://assets.coingecko.com/coins/images/22192/thumb/8BpzM6c.png",
  "BFORM": "https://assets.coingecko.com/coins/images/26040/thumb/blatform3_32.png",
  "RCG": "https://assets.coingecko.com/coins/images/18120/thumb/thecharge.PNG",
  "XAEA-XII": "https://assets.coingecko.com/coins/images/23325/thumb/xNXRIgWn_400x400.jpg",
  "SHIBGEKI": "https://assets.coingecko.com/coins/images/24032/thumb/Untitled-design-1.png",
  "SAME": "https://assets.coingecko.com/coins/images/17915/thumb/SAME.png",
  "INS": "https://assets.coingecko.com/coins/images/24681/thumb/4hTuPCCC_400x400.jpg",
  "QBU": "https://assets.coingecko.com/coins/images/17892/thumb/HsYrOmEy_400x400.jpg",
  "DOGEMOON": "https://assets.coingecko.com/coins/images/15308/thumb/DOGEMOON.png",
  "BABYPIG": "https://assets.coingecko.com/coins/images/16990/thumb/babypig.png",
  "MNT": "https://assets.coingecko.com/coins/images/27510/thumb/qEXG4boD_400x400.jpeg",
  "AIDI": "https://assets.coingecko.com/coins/images/18979/thumb/aidi_200.png",
  "HOTZILLA": "https://assets.coingecko.com/coins/images/20239/thumb/HotZilla.png",
  "MOONION": "https://assets.coingecko.com/coins/images/27454/thumb/200.png",
  "SHOX": "https://assets.coingecko.com/coins/images/25018/thumb/Shinjiro_200x200.png",
  "VTS": "https://assets.coingecko.com/coins/images/27431/thumb/vts.png",
  "POLO": "https://assets.coingecko.com/coins/images/17572/thumb/polkaplay_logo_transparent_200.png",
  "ADOGE": "https://assets.coingecko.com/coins/images/24052/thumb/aZ3d84N.jpg",
  "$MAID": "https://assets.coingecko.com/coins/images/17571/thumb/610e48937e73535a83f92ae7_icon200.png",
  "ANSR": "https://assets.coingecko.com/coins/images/18426/thumb/logo_-_2021-09-17T161041.106.png",
  "PVN": "https://assets.coingecko.com/coins/images/18900/thumb/logo_-_2021-10-10T061103.040.png",
  "SEACHAIN": "https://assets.coingecko.com/coins/images/18466/thumb/seachain_INLINE-logo-nosubtext-nostripes_darkBG01.png",
  "SAFEZONE": "https://assets.coingecko.com/coins/images/15485/thumb/safe.PNG",
  "MMS": "https://assets.coingecko.com/coins/images/17978/thumb/8-CF6-EDB1-181-B-4103-8-A60-3-AD6-F73-EA39-C.jpg",
  "EARNX": "https://assets.coingecko.com/coins/images/24234/thumb/earnx.PNG",
  "EBUSD": "https://assets.coingecko.com/coins/images/17686/thumb/EarnBUSD_200.png",
  "BRICKS": "https://assets.coingecko.com/coins/images/17311/thumb/DEV6onU.png",
  "WIFEDOGE": "https://assets.coingecko.com/coins/images/17346/thumb/w_doge_logo200.png",
  "AYFI": "https://assets.coingecko.com/coins/images/17257/thumb/aYFI_2x.png",
  "ARCADIUM": "https://assets.coingecko.com/coins/images/17717/thumb/4dyLxHe.png",
  "ARSW": "https://assets.coingecko.com/coins/images/26048/thumb/arsw.png",
  "MDC": "https://assets.coingecko.com/coins/images/17894/thumb/oemzNgO.png",
  "SFT": "https://assets.coingecko.com/coins/images/25490/thumb/IMG-20220504-144648.png",
  "SOLBERRY": "https://assets.coingecko.com/coins/images/18562/thumb/logo200_%2826%29.png",
  "ULTGG": "https://assets.coingecko.com/coins/images/17033/thumb/ULTGG-COIN-GRADIENT.png",
  "SHIBAMON": "https://assets.coingecko.com/coins/images/19891/thumb/cm170VEL_400x400.jpg",
  "ECOP": "https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG",
  "USDM": "https://assets.coingecko.com/coins/images/21252/thumb/WeChat_Image_20211230181747.jpg",
  "YMPA": "https://assets.coingecko.com/coins/images/22343/thumb/ymplepay-200x200.png",
  "MEWC": "https://assets.coingecko.com/coins/images/27679/thumb/mewc.png",
  "NBNG": "https://assets.coingecko.com/coins/images/16798/thumb/nbng.jpeg",
  "AKW": "https://assets.coingecko.com/coins/images/26478/thumb/logo.png",
  "DOGEFOOD": "https://assets.coingecko.com/coins/images/22475/thumb/LNbEc9.png",
  "PCA": "https://assets.coingecko.com/coins/images/28951/thumb/PCA.png",
  "PAMPTHER": "https://assets.coingecko.com/coins/images/16447/thumb/download_%283%29.png",
  "GENS": "https://assets.coingecko.com/coins/images/21195/thumb/gens.png",
  "ARAI": "https://assets.coingecko.com/coins/images/17245/thumb/aRAI_2x.png",
  "IDM": "https://assets.coingecko.com/coins/images/19665/thumb/8jVtkWu.png",
  "MEM": "https://assets.coingecko.com/coins/images/16370/thumb/mem_gold_200x200_copy.png",
  "OBX": "https://assets.coingecko.com/coins/images/26150/thumb/OBX_token-black_background_preview.png",
  "BUNI": "https://assets.coingecko.com/coins/images/17366/thumb/buni.PNG",
  "ABAL": "https://assets.coingecko.com/coins/images/17242/thumb/aBAL_2x.png",
  "DVRS": "https://assets.coingecko.com/coins/images/26762/thumb/DV-200x200-logo.png",
  "OKA": "https://assets.coingecko.com/coins/images/27120/thumb/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220830211831.png",
  "PINKS": "https://assets.coingecko.com/coins/images/16223/thumb/pinkswap.png",
  "HISAND33": "https://assets.coingecko.com/coins/images/27006/thumb/logo.ab86781.png",
  "GIGA": "https://assets.coingecko.com/coins/images/26919/thumb/final_62fcaacdbcc9640975d3fb7c_822256.png",
  "GAMESAFE": "https://assets.coingecko.com/coins/images/16065/thumb/logo_200x200.png",
  "TOKAU": "https://assets.coingecko.com/coins/images/16182/thumb/TOKAU.jpg",
  "SMGM": "https://assets.coingecko.com/coins/images/16123/thumb/smeg-coingecko_%281%29.png",
  "BTRM": "https://assets.coingecko.com/coins/images/26372/thumb/bitoreum_gold_black.png",
  "DUKE": "https://assets.coingecko.com/coins/images/16121/thumb/3axfBIw.jpeg",
  "TAUR": "https://assets.coingecko.com/coins/images/18655/thumb/taur.PNG",
  "LSR": "https://assets.coingecko.com/coins/images/22597/thumb/lsr.png",
  "2CRZ": "https://assets.coingecko.com/coins/images/16797/thumb/2crz.png",
  "SHIBQUEEN": "missing_thumb.png",
  "TDROP": "https://assets.coingecko.com/coins/images/23319/thumb/tdrop_pf_square_reverse.png",
  "NORA": "https://assets.coingecko.com/coins/images/17993/thumb/Nora-200x200.png",
  "CPX": "https://assets.coingecko.com/coins/images/27451/thumb/fcWV8Qq.png",
  "SNOSHARE": "https://assets.coingecko.com/coins/images/23776/thumb/snoshare.png",
  "FNIX": "https://assets.coingecko.com/coins/images/27392/thumb/qmxma6p3bf7y2vlsjqcr66r7yszatn6j1wowfesm9phhvu.png",
  "CPET": "https://assets.coingecko.com/coins/images/22373/thumb/1_k7J_PpeFqdPM4TES4Pt_zA.jpeg",
  "HARE PLUS": "https://assets.coingecko.com/coins/images/23963/thumb/200.png",
  "BYTE": "https://assets.coingecko.com/coins/images/24539/thumb/binarydao.PNG",
  "MAGICDOGE": "https://assets.coingecko.com/coins/images/19050/thumb/-MagicDoge.png",
  "AAAVE": "https://assets.coingecko.com/coins/images/17248/thumb/amAAVE_2x.png",
  "AGUSD": "https://assets.coingecko.com/coins/images/17246/thumb/aGUSD_2x.png",
  "JFIN": "https://assets.coingecko.com/coins/images/13370/thumb/JFin-Coin-Logo.png",
  "EAGON": "https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg",
  "NVM": "https://assets.coingecko.com/coins/images/24804/thumb/photo_5972293329968282493_x.jpg",
  "GMY": "https://assets.coingecko.com/coins/images/16364/thumb/CRfFHQ7.png",
  "USH": "https://assets.coingecko.com/coins/images/25481/thumb/ush.png",
  "BIDZ": "https://assets.coingecko.com/coins/images/26177/thumb/O99u27zU_400x400.png",
  "PRVG": "https://assets.coingecko.com/coins/images/27585/thumb/prvg.png",
  "WDOG": "https://assets.coingecko.com/coins/images/27907/thumb/WD300x300.png",
  "ZLDA": "https://assets.coingecko.com/coins/images/22185/thumb/Transparent-1-768x768.png",
  "BSAMO": "https://assets.coingecko.com/coins/images/20597/thumb/logo_-_2021-11-19T094813.378.png",
  "MET": "https://assets.coingecko.com/coins/images/23303/thumb/met.png",
  "RPT": "https://assets.coingecko.com/coins/images/18456/thumb/NmHcS53-_400x400.jpg",
  "CKT": "https://assets.coingecko.com/coins/images/20620/thumb/caketools-round-200.png",
  "FXUSD": "https://assets.coingecko.com/coins/images/26954/thumb/fxUSDLogo_200px-200px.png",
  "OLAND": "https://assets.coingecko.com/coins/images/24841/thumb/jP1nV5sb_400x400.jpg",
  "GREATAPE": "https://assets.coingecko.com/coins/images/16224/thumb/Q2YwSCx8_400x400.jpg",
  "MFY": "https://assets.coingecko.com/coins/images/27091/thumb/metafooty-3d.png",
  "AVAI": "https://assets.coingecko.com/coins/images/18859/thumb/ic_orca.png",
  "ITEM": "https://assets.coingecko.com/coins/images/26716/thumb/%ED%86%A0%ED%81%B0%ED%8B%B0%EC%BB%A4%28%EC%8B%AC%EB%B2%8C%29.jpg",
  "BA": "https://assets.coingecko.com/coins/images/14486/thumb/Iu1ccHi.png",
  "GDAI": "https://assets.coingecko.com/coins/images/18997/thumb/preview-gallery-gDAIgecko_%281%29.png",
  "VECT": "https://assets.coingecko.com/coins/images/12625/thumb/Vectorium-5-final.png",
  "VWAVE": "https://assets.coingecko.com/coins/images/26307/thumb/VWAVE-200.png",
  "CHAINCADE": "https://assets.coingecko.com/coins/images/17001/thumb/chaincade.png",
  "CLBK": "https://assets.coingecko.com/coins/images/5090/thumb/7j1i0yIe_400x400.jpg",
  "XGRAPE": "https://assets.coingecko.com/coins/images/27741/thumb/CRYTOLOGO.png",
  "HTMOON2.0": "https://assets.coingecko.com/coins/images/27769/thumb/photo_2022-10-13_18-19-20.jpg",
  "SANINU": "https://assets.coingecko.com/coins/images/20647/thumb/wzP7T0v1_400x400.jpg",
  "ETX": "https://assets.coingecko.com/coins/images/28523/thumb/IMG_20221218_022044_708.jpg",
  "INUSANITY": "https://assets.coingecko.com/coins/images/27719/thumb/inusanity200.png",
  "GETH": "https://assets.coingecko.com/coins/images/14001/thumb/guarda-shield-logo.png",
  "KISHUTAMA": "https://assets.coingecko.com/coins/images/23483/thumb/Screenshot-2022-02-05-at-09-20-53.png",
  "STKE": "https://assets.coingecko.com/coins/images/26738/thumb/algostake.jpg",
  "BALI": "https://assets.coingecko.com/coins/images/2026/thumb/bali.png",
  "$FIT": "https://assets.coingecko.com/coins/images/25398/thumb/photo-2022-02-26-12-48-53.jpg",
  "WEB3T": "https://assets.coingecko.com/coins/images/29050/thumb/logo.png",
  "NOW": "https://assets.coingecko.com/coins/images/8224/thumb/now.png",
  "LABRA": "https://assets.coingecko.com/coins/images/15024/thumb/Labra_Coin.jpg",
  "XAMP": "https://assets.coingecko.com/coins/images/11925/thumb/antiample.png",
  "NASADOGE": "https://assets.coingecko.com/coins/images/17463/thumb/tok.png",
  "SUPS": "https://assets.coingecko.com/coins/images/23772/thumb/Token.png",
  "KPOP": "https://assets.coingecko.com/coins/images/15923/thumb/kpop.PNG",
  "GTI": "https://assets.coingecko.com/coins/images/27954/thumb/LOGO.png",
  "YAYO": "https://assets.coingecko.com/coins/images/16008/thumb/84341687.png",
  "MOL": "https://assets.coingecko.com/coins/images/24744/thumb/MOL_200x200.png",
  "BIRDTOKEN": "https://assets.coingecko.com/coins/images/28591/thumb/birdToken.png",
  "OPENX": "https://assets.coingecko.com/coins/images/19274/thumb/X_color.png",
  "CSCT": "https://assets.coingecko.com/coins/images/19751/thumb/csct.png",
  "XMPT": "https://assets.coingecko.com/coins/images/15827/thumb/xiamipool.jpeg",
  "CTPL": "https://assets.coingecko.com/coins/images/15798/thumb/Cultiplan_200x200_logo.png",
  "HTT": "https://assets.coingecko.com/coins/images/26506/thumb/NbryQhGu_400x400.png",
  "PUNK": "https://assets.coingecko.com/coins/images/17018/thumb/Punk.png",
  "SMOON": "https://assets.coingecko.com/coins/images/16294/thumb/SaylorMoon_With_Moon_%283%29-min.png",
  "DAPPX": "https://assets.coingecko.com/coins/images/15780/thumb/large-logo.png",
  "SCARE": "https://assets.coingecko.com/coins/images/19154/thumb/lyd.png",
  "ASUNAINU": "https://assets.coingecko.com/coins/images/19214/thumb/asuna-logo-200px.jpg",
  "SYMM": "https://assets.coingecko.com/coins/images/18525/thumb/SYMM-Coin-2.png",
  "BUCKS": "https://assets.coingecko.com/coins/images/649/thumb/swagbucks.png",
  "ALP": "https://assets.coingecko.com/coins/images/18797/thumb/alp.PNG",
  "BDY": "https://assets.coingecko.com/coins/images/26612/thumb/19628.png",
  "MS": "https://assets.coingecko.com/coins/images/28656/thumb/IMG_20230105_055425_975.jpg",
  "SAFERMOON": "https://assets.coingecko.com/coins/images/15698/thumb/SAFERMOON-cmk-transparent.png",
  "FRANK": "https://assets.coingecko.com/coins/images/24474/thumb/frank.png",
  "GREENFUEL": "https://assets.coingecko.com/coins/images/24577/thumb/unnamed.png",
  "XBY": "https://assets.coingecko.com/coins/images/644/thumb/xtrabytes-xby-logo.png",
  "JIND": "https://assets.coingecko.com/coins/images/15521/thumb/_POP28D3_400x400.jpg",
  "RYIU": "https://assets.coingecko.com/coins/images/14512/thumb/ryi-unity_logo.png",
  "SPIRAL": "https://assets.coingecko.com/coins/images/26615/thumb/200logo.png",
  "UCO": "https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png",
  "MW": "https://assets.coingecko.com/coins/images/22924/thumb/Logo-Transparency-02-2.png",
  "SHIBCAKE": "https://assets.coingecko.com/coins/images/17662/thumb/IMG_20210812_190731_793.png",
  "GIFT": "https://assets.coingecko.com/coins/images/15541/thumb/5fdvA6T.png",
  "HALO": "https://assets.coingecko.com/coins/images/27758/thumb/coingeckoq.png",
  "MTK": "https://assets.coingecko.com/coins/images/28053/thumb/metatoken_icon.png",
  "PHMN": "https://assets.coingecko.com/coins/images/28207/thumb/phmn_logo.png",
  "HFIL": "https://assets.coingecko.com/coins/images/14109/thumb/HFIL.png",
  "SOCA": "https://assets.coingecko.com/coins/images/23872/thumb/PINK-Token-LOGO.png",
  "ROVER": "https://assets.coingecko.com/coins/images/15418/thumb/inu.png",
  "NANOX": "https://assets.coingecko.com/coins/images/1435/thumb/project-x.png",
  "XSCP": "https://assets.coingecko.com/coins/images/7919/thumb/logo_%286%29.png",
  "BPAY": "https://assets.coingecko.com/coins/images/26642/thumb/logo-sm.jpg",
  "BP": "https://assets.coingecko.com/coins/images/4732/thumb/b-logo-200x200.png",
  "PYQ": "https://assets.coingecko.com/coins/images/16740/thumb/85542908.png",
  "MGCHI": "https://assets.coingecko.com/coins/images/21403/thumb/Lw9uYzse_400x400.jpg",
  "YAG": "https://assets.coingecko.com/coins/images/18030/thumb/V6wG7jI.png",
  "DTOOLS": "https://assets.coingecko.com/coins/images/26914/thumb/1A14974C-016A-4C7B-8B39-584E30A47346.jpeg",
  "TINC": "https://assets.coingecko.com/coins/images/24176/thumb/IMG_20220307_100854_672.png",
  "COSHI": "https://assets.coingecko.com/coins/images/15219/thumb/200.jpg",
  "POCC": "https://assets.coingecko.com/coins/images/7718/thumb/logo_%281%29.png",
  "VBCH": "https://assets.coingecko.com/coins/images/13922/thumb/vbch.png",
  "MKONG": "https://assets.coingecko.com/coins/images/23448/thumb/ssynFLdjk2SIPSop_i8_8tCRn4rnV9hILMl8AnIFx3DtMIHRVoQ48QHOoqYwGuW9DSbZSJWoaPM0NEypRmrU3ZSXf_ZC16p3YBdT7mbP5vhnGyZMDvVoYm8GwKMYgvEeS9kzqlrNZ3bPWFasbdZD3TKh37BvLgUQiDchz5NiAVpUtrew5Frmox4CoEu_TOSvAV_OvG6A2RYeudy0bY0LUCfuU2.jpg",
  "LILFLOKI": "https://assets.coingecko.com/coins/images/19198/thumb/lilfloki200x200coingecko.png",
  "TBK": "https://assets.coingecko.com/coins/images/20334/thumb/RyESzumQ_400x400.jpg",
  "MYTV": "https://assets.coingecko.com/coins/images/7026/thumb/mytv.png",
  "SL": "https://assets.coingecko.com/coins/images/25704/thumb/22223333333.png",
  "ERZ": "https://assets.coingecko.com/coins/images/24809/thumb/17059.png",
  "BINU": "https://assets.coingecko.com/coins/images/22155/thumb/gM-mFn7L_400x400.jpg",
  "GPUNKS20": "https://assets.coingecko.com/coins/images/17859/thumb/unnamed_%282%29.gif",
  "DFC": "https://assets.coingecko.com/coins/images/28521/thumb/dfc.png",
  "LGOLD": "https://assets.coingecko.com/coins/images/14938/thumb/LGOLD-Logo-200.png",
  "PAZZI": "https://assets.coingecko.com/coins/images/11558/thumb/jMJC4g9m_400x400.jpg",
  "CRACE": "https://assets.coingecko.com/coins/images/19529/thumb/13376.png",
  "BUFF": "https://assets.coingecko.com/coins/images/28370/thumb/logo.png",
  "YES": "https://assets.coingecko.com/coins/images/28763/thumb/Logo-Ori_200px.png",
  "AUSDC": "https://assets.coingecko.com/coins/images/28332/thumb/CRYTOLOGO.png",
  "IVAR": "https://assets.coingecko.com/coins/images/27014/thumb/ivar-coin-logo-200x200.png",
  "SDFI": "https://assets.coingecko.com/coins/images/14738/thumb/download_%2819%29.png",
  "HTD": "https://assets.coingecko.com/coins/images/20953/thumb/htd.png",
  "STREAM": "https://assets.coingecko.com/coins/images/16458/thumb/STREAM.png",
  "TOAD": "https://assets.coingecko.com/coins/images/28867/thumb/LuckyToad_200x200.png",
  "MOUSEWORM": "https://assets.coingecko.com/coins/images/28940/thumb/51E17AB5-6CB7-4975-AD03-6F7F86B72188.jpeg",
  "DIC": "https://assets.coingecko.com/coins/images/4910/thumb/daikicoin-logo.png",
  "$ALTI": "https://assets.coingecko.com/coins/images/26772/thumb/footerlogo.png",
  "CLX": "https://assets.coingecko.com/coins/images/23773/thumb/Dozvuvjc5KQHodLsrGZFdoJGcb9mNvSO3QklDVq4.jpg",
  "SHPP": "https://assets.coingecko.com/coins/images/14687/thumb/photo_2020-12-28_16-37-33.jpg",
  "PUMPOPOLY": "https://assets.coingecko.com/coins/images/27738/thumb/pumpopoly-icon-text-200x200.png",
  "BEANS": "https://assets.coingecko.com/coins/images/18547/thumb/logo_-_2021-09-23T100943.437.png",
  "HPL": "https://assets.coingecko.com/coins/images/21450/thumb/happy_land.PNG",
  "ERP": "https://assets.coingecko.com/coins/images/19633/thumb/erp.PNG",
  "CFXT": "https://assets.coingecko.com/coins/images/17671/thumb/Logo_200_%282%29.png",
  "DBUY": "https://assets.coingecko.com/coins/images/17895/thumb/VaxEiZ3.png",
  "JDE": "https://assets.coingecko.com/coins/images/27100/thumb/IxYj3oJj_400x400.jpeg",
  "$COLR": "https://assets.coingecko.com/coins/images/26701/thumb/colverse.jpeg",
  "BRO$": "https://assets.coingecko.com/coins/images/27846/thumb/Logo-200x200-1.png",
  "ALINK": "https://assets.coingecko.com/coins/images/11729/thumb/aLINK.png",
  "JP3G": "https://assets.coingecko.com/coins/images/24656/thumb/JPEGvault_-_Black_font.png",
  "TIKKY": "https://assets.coingecko.com/coins/images/27945/thumb/tikkyinu_logo.png",
  "BTSC": "https://assets.coingecko.com/coins/images/19386/thumb/mDVn3lgM_400x400.jpg",
  "ATUSD": "https://assets.coingecko.com/coins/images/11724/thumb/aTUSD.png",
  "LSP": "https://assets.coingecko.com/coins/images/18173/thumb/Lumenswap-200px.png",
  "DUK+": "https://assets.coingecko.com/coins/images/14374/thumb/dukascoin_200x200_silver.png",
  "SMAK": "https://assets.coingecko.com/coins/images/19445/thumb/smartlink.PNG",
  "LIMIT": "https://assets.coingecko.com/coins/images/12760/thumb/limit_swap_logo.png",
  "YCURVE": "https://assets.coingecko.com/coins/images/11858/thumb/yCrv.png",
  "GREATDANE": "https://assets.coingecko.com/coins/images/27080/thumb/0t9XjT4T_t.jpg",
  "ONEPIECE": "https://assets.coingecko.com/coins/images/19421/thumb/g842dxb.png",
  "GRE": "https://assets.coingecko.com/coins/images/1251/thumb/greencoin.png",
  "NS": "https://assets.coingecko.com/coins/images/14286/thumb/Round-black.png",
  "SCURVE": "https://assets.coingecko.com/coins/images/11899/thumb/Curvefi_sCrv_32.png",
  "MDB": "https://assets.coingecko.com/coins/images/27062/thumb/20039.png",
  "ABUSD": "https://assets.coingecko.com/coins/images/11736/thumb/aBUSD.png",
  "AWBTC": "https://assets.coingecko.com/coins/images/11734/thumb/aWBTC.png",
  "TBANK": "https://assets.coingecko.com/coins/images/23957/thumb/logo_fundo_branco.png",
  "SURGE": "https://assets.coingecko.com/coins/images/18963/thumb/91mZRJs.png",
  "BB": "https://assets.coingecko.com/coins/images/20789/thumb/New-Era-of-Baby-Bali-200x200pxl.png",
  "DBY": "https://assets.coingecko.com/coins/images/25242/thumb/metaderby.png",
  "AUSDT": "https://assets.coingecko.com/coins/images/11725/thumb/aUSDT.png",
  "PIXL": "https://assets.coingecko.com/coins/images/21390/thumb/logo.png",
  "ETL": "https://assets.coingecko.com/coins/images/16892/thumb/M1A63Kr.png",
  "SOCCER": "https://assets.coingecko.com/coins/images/27784/thumb/soccer_inu_logo.png",
  "MAORABBIT": "https://assets.coingecko.com/coins/images/28375/thumb/MAORabbit.png",
  "DTO": "https://assets.coingecko.com/coins/images/19527/thumb/84438129.png",
  "DEFC": "https://assets.coingecko.com/coins/images/17020/thumb/deficoin.png",
  "LFC": "https://assets.coingecko.com/coins/images/24614/thumb/lfc.png",
  "$SNOW": "https://assets.coingecko.com/coins/images/28228/thumb/logo200.png",
  "SIV": "https://assets.coingecko.com/coins/images/22043/thumb/16528.png",
  "WOLVERINU": "https://assets.coingecko.com/coins/images/19374/thumb/logo200.png",
  "BXT": "https://assets.coingecko.com/coins/images/1379/thumb/bittokens.png",
  "LWD": "https://assets.coingecko.com/coins/images/25642/thumb/v-MhJXeSQuKSjz5gJRpptF5E5v95862DXJhkSeVabQcVsbBxzryaaQQ-eqwp0hvjrXQQmQRUKYW3gFfzUi3zVjhWoZKg79rmFF5dL6igKT0iWWpDtfNRcr6XA1mL9WiLgg6EOqwfmuLNu1-qan38OQJve32FKgJ7FL_fAyZRHvSuuIwO4qmcLPVqWo08oYELCC5ParMmS7FfNsHtu38u6j0gxw.jpg",
  "ASUSD": "https://assets.coingecko.com/coins/images/11723/thumb/aSUSD.png",
  "SIP": "https://assets.coingecko.com/coins/images/19637/thumb/Sip_Coin_200x200.png",
  "INFTEE": "https://assets.coingecko.com/coins/images/17010/thumb/infinitee_logo.png",
  "EFK": "https://assets.coingecko.com/coins/images/27876/thumb/E_VFDP8f_400x400.jpeg",
  "SPACE": "https://assets.coingecko.com/coins/images/16378/thumb/Space.png",
  "GINSPIRIT": "https://assets.coingecko.com/coins/images/19859/thumb/GinSpirit_big.png",
  "VSXP": "https://assets.coingecko.com/coins/images/13918/thumb/sxp.png",
  "ENNO": "https://assets.coingecko.com/coins/images/19662/thumb/enno1.png",
  "HLNK": "https://assets.coingecko.com/coins/images/19664/thumb/200x-logo.png",
  "ROCKY": "https://assets.coingecko.com/coins/images/21037/thumb/AjdepqD.png",
  "EXC": "https://assets.coingecko.com/coins/images/24214/thumb/exc.png",
  "CHP": "https://assets.coingecko.com/coins/images/1808/thumb/coinpoker.jpg",
  "PASS": "https://assets.coingecko.com/coins/images/5462/thumb/cQl0gX0.png",
  "ZYB": "https://assets.coingecko.com/coins/images/28943/thumb/logo_with_bg.png",
  "RANKER": "https://assets.coingecko.com/coins/images/23902/thumb/ranker.png",
  "POSS": "https://assets.coingecko.com/coins/images/25994/thumb/Posschain-200x200.png",
  "TVRS": "https://assets.coingecko.com/coins/images/24591/thumb/tvrs.png",
  "VCASH": "https://assets.coingecko.com/coins/images/26976/thumb/Avatar_v2.png",
  "SGMT": "https://assets.coingecko.com/coins/images/26232/thumb/33z7sf9g_400x400.png",
  "PYRO": "https://assets.coingecko.com/coins/images/26662/thumb/PYRO.png",
  "ZDCV2": "https://assets.coingecko.com/coins/images/22589/thumb/LUl0lEMr_400x400.jpg",
  "COINSCOPE": "https://assets.coingecko.com/coins/images/20649/thumb/IpSQZCFv_400x400.jpg",
  "DSOL": "https://assets.coingecko.com/coins/images/19700/thumb/QmdvXyoW82LjwF2w3ya2Sr42WQgXzdTo9jfXW452RJXVLD.png",
  "PIRI": "https://assets.coingecko.com/coins/images/27971/thumb/piri-logo-200.png",
  "INTX": "https://assets.coingecko.com/coins/images/11854/thumb/INTX.png",
  "TTT": "https://assets.coingecko.com/coins/images/21060/thumb/xFYsZV9U_400x400.jpg",
  "DUA": "https://assets.coingecko.com/coins/images/27976/thumb/profile-200x200.png",
  "VBTC": "https://assets.coingecko.com/coins/images/13904/thumb/xbtc.png",
  "BOXC": "https://assets.coingecko.com/coins/images/27940/thumb/BOXC.png",
  "UCHAD": "https://assets.coingecko.com/coins/images/19777/thumb/uchad.PNG",
  "RCX": "https://assets.coingecko.com/coins/images/28616/thumb/Recycle.png",
  "ALTRUCOIN": "https://assets.coingecko.com/coins/images/24512/thumb/Sn_fybmb_400x400.png",
  "FLOKIGROW": "https://assets.coingecko.com/coins/images/27144/thumb/logo.png",
  "TORQ": "https://assets.coingecko.com/coins/images/3486/thumb/torq-coin.png",
  "BOLLY": "https://assets.coingecko.com/coins/images/21610/thumb/IMG-20211208-124337-701.jpg",
  "DFH": "https://assets.coingecko.com/coins/images/24293/thumb/uxwvJpWa_400x400.jpg",
  "POLL": "https://assets.coingecko.com/coins/images/19034/thumb/pollchain.PNG",
  "WT": "https://assets.coingecko.com/coins/images/25152/thumb/logo_200x200.png",
  "WSO": "https://assets.coingecko.com/coins/images/22832/thumb/wso2_200x200.png",
  "PTPA": "https://assets.coingecko.com/coins/images/24262/thumb/pinksale-200x200-1.jpg",
  "CWC": "https://assets.coingecko.com/coins/images/28258/thumb/cwc.png",
  "KOEL": "https://assets.coingecko.com/coins/images/12816/thumb/cuckoo.png",
  "PTA": "https://assets.coingecko.com/coins/images/21645/thumb/VB_hrqI7_400x400.jpg",
  "WNDG95": "https://assets.coingecko.com/coins/images/19965/thumb/windoge95.png",
  "ZRO": "https://assets.coingecko.com/coins/images/28557/thumb/photo_2022-08-16_02-50-00.jpg",
  "CNX": "https://assets.coingecko.com/coins/images/1176/thumb/cryptonex.png",
  "WOLFIES": "https://assets.coingecko.com/coins/images/25133/thumb/wolfies.png",
  "CSPT": "https://assets.coingecko.com/coins/images/28528/thumb/Logo_Ceasports_new.png",
  "MERCE": "https://assets.coingecko.com/coins/images/25867/thumb/merce.png",
  "JKWON": "https://assets.coingecko.com/coins/images/27160/thumb/Untitled_design_%2813%29.png",
  "LOCK": "https://assets.coingecko.com/coins/images/20085/thumb/contracto.PNG",
  "CBONK": "https://assets.coingecko.com/coins/images/28889/thumb/CantoBonk.jpg",
  "ART": "https://assets.coingecko.com/coins/images/20170/thumb/logo-200x200_%2811%29.png",
  "XWC": "https://assets.coingecko.com/coins/images/60/thumb/whitecoin.png",
  "SOB": "https://assets.coingecko.com/coins/images/19908/thumb/SOLA-LAMBO-2.png",
  "NITRODOGE": "https://assets.coingecko.com/coins/images/27161/thumb/nitrodoge_2.png",
  "ZUPI": "https://assets.coingecko.com/coins/images/13935/thumb/zupi.png",
  "CREVA": "https://assets.coingecko.com/coins/images/466/thumb/crevacoin.png",
  "PRI": "https://assets.coingecko.com/coins/images/14995/thumb/c9C2piFC_400x400.png",
  "BCAT": "https://assets.coingecko.com/coins/images/27162/thumb/logo_%281%29.png",
  "BABYCAKE": "https://assets.coingecko.com/coins/images/17180/thumb/baby_cake.PNG",
  "BITV": "https://assets.coingecko.com/coins/images/22825/thumb/Screenshot-2022-01-19-at-17-00-20.png",
  "BIBL": "https://assets.coingecko.com/coins/images/26406/thumb/tLvtI1NT_400x400.jpeg",
  "ADR": "https://assets.coingecko.com/coins/images/24556/thumb/92mtj657_400x400.jpg",
  "AUT": "https://assets.coingecko.com/coins/images/28087/thumb/T-R84Zo32rDOA5Wo66T8jAlqcyjD7Folt1oeCfjlizU.png",
  "SFG": "https://assets.coingecko.com/coins/images/12605/thumb/Z7D8B4b.png",
  "PEACE": "https://assets.coingecko.com/coins/images/24555/thumb/WechatIMG444.jpeg",
  "MTBC": "https://assets.coingecko.com/coins/images/23837/thumb/IMG-20211124-162844-449.png",
  "PETO": "https://assets.coingecko.com/coins/images/25459/thumb/7WMDBHl.png",
  "SHIBDOGE": "missing_thumb.png",
  "KSAMO": "https://assets.coingecko.com/coins/images/21237/thumb/KSAMO_1.png",
  "CRT": "https://assets.coingecko.com/coins/images/12410/thumb/TZ7EvoZdEet2P9E2DKf9CtKf6EAyFwRAS3.png",
  "RVM": "https://assets.coingecko.com/coins/images/27044/thumb/logo_200.png",
  "EVY": "https://assets.coingecko.com/coins/images/5629/thumb/TutkUC1x_400x400_%281%29.jpg",
  "FLC": "https://assets.coingecko.com/coins/images/7446/thumb/logo_%2889%29.png",
  "XMT": "https://assets.coingecko.com/coins/images/22075/thumb/Logo_COIN_-_Gradiente.png",
  "MIC": "https://assets.coingecko.com/coins/images/25085/thumb/iUuLuOEsilei-ecpryv7sGFXvtJ0WOSAX0a4MX7d0huo6DSvKYxhdOzJuNVAAklFK68tW5S5GfoiO6koLbjWp2UDvjSBXi3LCzlp437hqaSLgYwoNsLAQhaVzqwIxyvjjLluyH0R_RykeAXGz3I7wl32qnjkdt43Wa4ZgPqLTVwC8siw_50w46MLRAZWISQ_YbjSVhU20-g9dwUBsKTA4Nyw_I.jpg",
  "NEURALAI": "https://assets.coingecko.com/coins/images/29049/thumb/Neural-AI-logo_%281%29.png",
  "HIDOODLES": "https://assets.coingecko.com/coins/images/27427/thumb/hidoodles.png",
  "LUXETOKEN": "https://assets.coingecko.com/coins/images/28098/thumb/1974A5AA-D9A9-4FBB-BE56-F60BAB25D27D.png",
  "CORNX": "https://assets.coingecko.com/coins/images/24576/thumb/logo200x200.png",
  "GOLD NUGGET": "https://assets.coingecko.com/coins/images/19008/thumb/gold_nugget.png",
  "HXY": "https://assets.coingecko.com/coins/images/11225/thumb/hexmoneygradientsmall.png",
  "RIDES": "https://assets.coingecko.com/coins/images/24172/thumb/5Hj1cTR.png",
  "BYTES": "https://assets.coingecko.com/coins/images/28858/thumb/bytes.png",
  "SMRT": "https://assets.coingecko.com/coins/images/19199/thumb/smrt.png",
  "ZNS": "https://assets.coingecko.com/coins/images/21216/thumb/logo_200x200_black_transparent.png",
  "KURAI": "https://assets.coingecko.com/coins/images/20114/thumb/KuraiInu.png",
  "DINERO": "https://assets.coingecko.com/coins/images/28248/thumb/CABA1DE1-2CB1-46D3-97BC-20305EA67FBB.png",
  "GMEX": "https://assets.coingecko.com/coins/images/19893/thumb/gmex.png",
  "SLOTH": "https://assets.coingecko.com/coins/images/6123/thumb/SLOTH-large.png",
  "HINT": "https://assets.coingecko.com/coins/images/9329/thumb/JwEnTYRktjoozNKeI6qtQ0CEuiwNhl_vWNoXvob7OO-ZJ7YsXqK6oUmBjdXy0XRTnLcOywgKyWP-3WPcpelcT2ZQyBYoYEGLXA4Q-n6utwbn43oTkW3Ro6Mm93tr9lC1lrb3E4vPTS17TYRvYDP_6c9KLulnS4y_E4FlU9u6Y2FkoYhFdrV9-XF1CPif17GxyVa9vr45xfgGmqmU5.jpg",
  "COINMAMA": "https://assets.coingecko.com/coins/images/21591/thumb/mVvHDCUs_400x400.jpg",
  "HIMEEBITS": "https://assets.coingecko.com/coins/images/28126/thumb/himeebit.png",
  "PIVXL": "https://assets.coingecko.com/coins/images/11272/thumb/X1PUupGQ_400x400.png",
  "SOULO": "https://assets.coingecko.com/coins/images/22082/thumb/logo.png",
  "DEVT": "https://assets.coingecko.com/coins/images/21373/thumb/A3RjDqet_400x400.jpg",
  "DOGEZILLA": "https://assets.coingecko.com/coins/images/19331/thumb/dogezilla.PNG",
  "ITR": "https://assets.coingecko.com/coins/images/20180/thumb/VU72TTZ3zN3w.png",
  "F1D": "missing_thumb.png",
  "PRVC": "https://assets.coingecko.com/coins/images/28140/thumb/PrivaCoin-logo.png",
  "BABYFLOKI": "https://assets.coingecko.com/coins/images/18436/thumb/9FkEA4bN.png",
  "SXS": "https://assets.coingecko.com/coins/images/27924/thumb/sxs.png",
  "BABYKITTY": "https://assets.coingecko.com/coins/images/22127/thumb/2021-12-26-12-51-04-1-1.png",
  "RUG": "https://assets.coingecko.com/coins/images/20708/thumb/nnKwMye.png",
  "MARSDOGE": "https://assets.coingecko.com/coins/images/20892/thumb/Logo_Mars_Doge-200x200-1.png",
  "BEUR": "https://assets.coingecko.com/coins/images/28643/thumb/Bonq_logo_b_%281%29.png",
  "AETH": "https://assets.coingecko.com/coins/images/11619/thumb/aETH_2x.png",
  "SPELLFIRE": "https://assets.coingecko.com/coins/images/23107/thumb/17316.png",
  "PABLO": "https://assets.coingecko.com/coins/images/17099/thumb/pablo.PNG",
  "TND": "https://assets.coingecko.com/coins/images/28659/thumb/tenderlogo.png",
  "Y5": "https://assets.coingecko.com/coins/images/24243/thumb/y-5.png",
  "STFU": "https://assets.coingecko.com/coins/images/27364/thumb/logo_CMC.png",
  "VSHARE": "https://assets.coingecko.com/coins/images/24180/thumb/VShare_Token.png",
  "YBX": "https://assets.coingecko.com/coins/images/20228/thumb/60df683719cc056004ea932b_YBX_token_web_bad.png",
  "BBW": "https://assets.coingecko.com/coins/images/24168/thumb/babywhale_32.png",
  "CIMO": "https://assets.coingecko.com/coins/images/28465/thumb/D7287693-BFD2-4E5B-9A52-BBA8749F2B7D.png",
  "CSPD": "https://assets.coingecko.com/coins/images/21469/thumb/CasperPad_New_Logo.png",
  "ZASH": "https://assets.coingecko.com/coins/images/11340/thumb/zimbocash_logo.png",
  "MILLI": "https://assets.coingecko.com/coins/images/20396/thumb/Logo_200px_%281%29.png",
  "KASHH": "https://assets.coingecko.com/coins/images/579/thumb/kashhcoin.png",
  "XCV": "https://assets.coingecko.com/coins/images/19232/thumb/82644942.png",
  "WSD": "https://assets.coingecko.com/coins/images/27410/thumb/EkJVu0W3_400x400.png",
  "NSD": "https://assets.coingecko.com/coins/images/5448/thumb/nasdacoin-logo.png",
  "DEXA": "https://assets.coingecko.com/coins/images/9737/thumb/DEXA_COIN_logo.png",
  "CHIBA": "https://assets.coingecko.com/coins/images/22644/thumb/1qyBuGgr_400x400.jpg",
  "DFGL": "https://assets.coingecko.com/coins/images/12883/thumb/defi_gold.jpg",
  "ALTS": "https://assets.coingecko.com/coins/images/22785/thumb/download.png",
  "MGX": "https://assets.coingecko.com/coins/images/28222/thumb/mangata-x-coin-256px.png",
  "PONZI": "https://assets.coingecko.com/coins/images/3094/thumb/ponzicoin.png",
  "SHIP": "https://assets.coingecko.com/coins/images/3997/thumb/shipchain.png",
  "KUNO": "https://assets.coingecko.com/coins/images/21847/thumb/94E3B862-B2ED-4D30-A1E4-1AFB560835F5.png",
  "LINSPIRIT": "https://assets.coingecko.com/coins/images/22634/thumb/pirate.4fb08b14.png",
  "HEBE": "https://assets.coingecko.com/coins/images/10367/thumb/hebeswap.jpeg",
  "FOMOBABY": "https://assets.coingecko.com/coins/images/20435/thumb/Artboard_29.png",
  "$READ": "https://assets.coingecko.com/coins/images/28275/thumb/4cb31e07-9b23-4e2d-82d7-2fe3e3dc8887.png",
  "TRISE": "https://assets.coingecko.com/coins/images/21519/thumb/gRGDFsS6_400x400.jpg",
  "BSAFU": "https://assets.coingecko.com/coins/images/26872/thumb/IMG-20220812-152357-743.jpg",
  "DRGB": "https://assets.coingecko.com/coins/images/10123/thumb/drgb.PNG",
  "ELC": "https://assets.coingecko.com/coins/images/17195/thumb/eaglecoin.PNG",
  "SIMBAINU": "https://assets.coingecko.com/coins/images/21525/thumb/simba_inu.PNG",
  "NIFTY": "https://assets.coingecko.com/coins/images/23300/thumb/p44KTVAa_400x400.jpg",
  "DELTAF": "https://assets.coingecko.com/coins/images/22328/thumb/HxJa683.png",
  "$MECHA": "https://assets.coingecko.com/coins/images/24374/thumb/w4K4OOMo_400x400.jpg",
  "ANONFLOKI": "https://assets.coingecko.com/coins/images/20464/thumb/download_%2841%29.png",
  "WEBOO": "https://assets.coingecko.com/coins/images/22509/thumb/weboo_2.png",
  "GTCOIN": "https://assets.coingecko.com/coins/images/24719/thumb/hUEmunGU_400x400.jpg",
  "CAKEPUNKS": "https://assets.coingecko.com/coins/images/18215/thumb/cool_2.png",
  "CASTLE": "https://assets.coingecko.com/coins/images/26551/thumb/blue_logo03.png",
  "ALONMARS": "https://assets.coingecko.com/coins/images/29054/thumb/IMG_20230213_020850_600.jpg",
  "BFF": "https://assets.coingecko.com/coins/images/28711/thumb/Better-CMC-Avatar.png",
  "CML": "https://assets.coingecko.com/coins/images/25119/thumb/camelcoinlogo.png",
  "$TIPSY": "https://assets.coingecko.com/coins/images/24821/thumb/TipsyCoin-Icon.png",
  "ZMBE": "https://assets.coingecko.com/coins/images/18670/thumb/MKHznEfH_400x400.jpg",
  "RPTC": "https://assets.coingecko.com/coins/images/18989/thumb/rept.PNG",
  "ONS": "https://assets.coingecko.com/coins/images/13531/thumb/bss.a1671c75.png",
  "SANJI": "https://assets.coingecko.com/coins/images/28301/thumb/photo_2022-04-04_23-49-34.jpg",
  "ASVA": "https://assets.coingecko.com/coins/images/22134/thumb/1_YAFDez1BKcVUHzwDoYcgPQ.png",
  "BTCPX": "https://assets.coingecko.com/coins/images/22630/thumb/MB1aYO7T_400x400.jpg",
  "YT": "https://assets.coingecko.com/coins/images/8321/thumb/cherry.png",
  "Y5TT": "https://assets.coingecko.com/coins/images/26261/thumb/Y53D.png",
  "LIR": "https://assets.coingecko.com/coins/images/3100/thumb/letitride.png",
  "STKD": "https://assets.coingecko.com/coins/images/24819/thumb/dscrt.png",
  "ZUF": "https://assets.coingecko.com/coins/images/20548/thumb/download-2.png",
  "ICN": "https://assets.coingecko.com/coins/images/23797/thumb/Icoin-200x200.png",
  "BHNY": "https://assets.coingecko.com/coins/images/26079/thumb/honey.png",
  "BINSPIRIT": "https://assets.coingecko.com/coins/images/23392/thumb/binspirit.png",
  "DS": "https://assets.coingecko.com/coins/images/15984/thumb/DS_LOG_Transparent_200x200.png",
  "COCO": "https://assets.coingecko.com/coins/images/18811/thumb/logoicon.png",
  "SCY": "https://assets.coingecko.com/coins/images/19308/thumb/SYNCHRONY-LOGO.png",
  "$SWING": "https://assets.coingecko.com/coins/images/28576/thumb/swing.png",
  "NEWB": "https://assets.coingecko.com/coins/images/16029/thumb/NewB_Token_FA_CMC.png",
  "YFIH2": "https://assets.coingecko.com/coins/images/23077/thumb/wnzHxD5M_400x400.jpg",
  "KTX": "https://assets.coingecko.com/coins/images/24352/thumb/KwikTrustLogo.png",
  "STORE": "https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG",
  "BABYLLAMA": "https://assets.coingecko.com/coins/images/27251/thumb/logo200.jpg",
  "K0DE": "https://assets.coingecko.com/coins/images/26788/thumb/logo200x200.png",
  "ELONONE": "https://assets.coingecko.com/coins/images/16082/thumb/AstroElon.png",
  "MINTYS": "https://assets.coingecko.com/coins/images/17975/thumb/Minty-logo-200x200.png",
  "MOONMINER": "https://assets.coingecko.com/coins/images/16579/thumb/YiH7Q1S.png",
  "MPC": "https://assets.coingecko.com/coins/images/26986/thumb/MPC_logo.png",
  "HIFIDENZA": "https://assets.coingecko.com/coins/images/28237/thumb/hifidenza.png",
  "PAXW": "https://assets.coingecko.com/coins/images/27944/thumb/paxcoin_200.fw.png",
  "ULG": "https://assets.coingecko.com/coins/images/8054/thumb/ulgcoin.png",
  "STBZ": "https://assets.coingecko.com/coins/images/12753/thumb/icon.png",
  "BASH": "https://assets.coingecko.com/coins/images/656/thumb/luckchain.png",
  "TBE": "https://assets.coingecko.com/coins/images/17409/thumb/tbe.PNG",
  "VXRP": "https://assets.coingecko.com/coins/images/13921/thumb/vxrp.png",
  "GRV": "https://assets.coingecko.com/coins/images/25549/thumb/200x200.png",
  "MSP": "https://assets.coingecko.com/coins/images/24879/thumb/msp.png",
  "SHINE": "https://assets.coingecko.com/coins/images/24222/thumb/Shine_favicon_200x200.png",
  "ARENA": "https://assets.coingecko.com/coins/images/28728/thumb/200x200_Logo.png",
  "MNTT": "https://assets.coingecko.com/coins/images/15386/thumb/moontrust_logo_200x200px.png",
  "REUM": "https://assets.coingecko.com/coins/images/18217/thumb/RLogoPNG.png",
  "AKL": "https://assets.coingecko.com/coins/images/21455/thumb/Dt6kooD6_400x400.jpg",
  "GTN": "https://assets.coingecko.com/coins/images/27883/thumb/Full_sign.png",
  "TCGC": "https://assets.coingecko.com/coins/images/28548/thumb/tcgc.png",
  "KAREN": "https://assets.coingecko.com/coins/images/16191/thumb/karen.PNG",
  "MSG": "https://assets.coingecko.com/coins/images/28230/thumb/icon_200x200.png",
  "NAUT": "https://assets.coingecko.com/coins/images/14944/thumb/Untitled-design-32-removebg-preview.png",
  "TREES": "https://assets.coingecko.com/coins/images/16202/thumb/ST_newLogo_200x.png",
  "KOACOMBAT": "https://assets.coingecko.com/coins/images/23827/thumb/jGYAi2LB_400x400.jpg",
  "PAPADOGE": "https://assets.coingecko.com/coins/images/16721/thumb/PAPADOGE.jpg",
  "TOPG": "https://assets.coingecko.com/coins/images/26709/thumb/AlphaLabsLogo.png",
  "KCAKE": "https://assets.coingecko.com/coins/images/17319/thumb/logo_-_2021-07-26T173233.519.png",
  "TESINU": "https://assets.coingecko.com/coins/images/17468/thumb/400_filter_nobg_60c70a3c5aae1.jpg",
  "MOONS": "https://assets.coingecko.com/coins/images/12793/thumb/moontools-token-logo.png",
  "DADDYUSDT": "https://assets.coingecko.com/coins/images/18724/thumb/daddy-usdt-logo.png",
  "CAKEW": "https://assets.coingecko.com/coins/images/27726/thumb/200x200.png",
  "VYFI": "https://assets.coingecko.com/coins/images/24331/thumb/download_%289%29.png",
  "BOXERDOGE": "https://assets.coingecko.com/coins/images/17610/thumb/logo200x.png",
  "FOOD": "https://assets.coingecko.com/coins/images/23755/thumb/food.png",
  "BIB": "https://assets.coingecko.com/coins/images/28462/thumb/BIB.png",
  "YFE": "https://assets.coingecko.com/coins/images/12644/thumb/logo-round.png",
  "SPLC": "https://assets.coingecko.com/coins/images/28192/thumb/SupplyCon_Logo_.png",
  "DFP2": "https://assets.coingecko.com/coins/images/19552/thumb/profile-round.png",
  "RTH": "https://assets.coingecko.com/coins/images/2030/thumb/L7kpRMda_400x400.jpg",
  "FFS": "https://assets.coingecko.com/coins/images/26902/thumb/FFS-logo-200x200.png",
  "ETHSHIB": "https://assets.coingecko.com/coins/images/24579/thumb/ETH-SHIBA-200x200-1.png",
  "PITCHFXS": "https://assets.coingecko.com/coins/images/28414/thumb/pitch.png",
  "THS": "https://assets.coingecko.com/coins/images/18497/thumb/logo_theos_200x200.png",
  "CBG": "https://assets.coingecko.com/coins/images/18052/thumb/j3saoPU.png",
  "MONEY": "https://assets.coingecko.com/coins/images/24237/thumb/money.png",
  "ASN": "https://assets.coingecko.com/coins/images/7027/thumb/ascension.png",
  "CYBRRRDOGE": "https://assets.coingecko.com/coins/images/17455/thumb/VJJcCrdU_400x400.png",
  "SHIBACASH": "https://assets.coingecko.com/coins/images/16010/thumb/shibacash_logo200.png",
  "GEMIT": "https://assets.coingecko.com/coins/images/19874/thumb/gemit_logo_200.png",
  "LQNTY": "https://assets.coingecko.com/coins/images/26844/thumb/photo_5161276562430077559_x.jpg",
  "AZNT": "https://assets.coingecko.com/coins/images/26341/thumb/AZNT.png",
  "BURN1COIN": "https://assets.coingecko.com/coins/images/16227/thumb/LqID7du8_400x400.jpg",
  "YFIIG": "https://assets.coingecko.com/coins/images/12587/thumb/yfiigold_logo.png",
  "JAWS": "https://assets.coingecko.com/coins/images/16335/thumb/osP9madX_400x400.jpg",
  "STSR": "https://assets.coingecko.com/coins/images/28202/thumb/Satelstar_logo_200x.png",
  "HYPE": "https://assets.coingecko.com/coins/images/24675/thumb/8130.png",
  "SAFEARN": "https://assets.coingecko.com/coins/images/17186/thumb/logo_-_2021-07-21T064633.059.png",
  "QATARGROW": "https://assets.coingecko.com/coins/images/28312/thumb/photo_2022-11-07_08-53-10.png",
  "4ART": "https://assets.coingecko.com/coins/images/4690/thumb/0_%284%29.png",
  "CIG": "https://assets.coingecko.com/coins/images/22145/thumb/cig.png",
  "SAITANOBI": "https://assets.coingecko.com/coins/images/23473/thumb/saitanobi.png",
  "TPY": "https://assets.coingecko.com/coins/images/26799/thumb/rsz_1mpmojfho_400x400.png",
  "BITB": "https://assets.coingecko.com/coins/images/254/thumb/bitbean.png",
  "GOAT": "https://assets.coingecko.com/coins/images/15525/thumb/qqked5P.png",
  "SHIL": "https://assets.coingecko.com/coins/images/28847/thumb/rsz_logo1.png",
  "RELAX": "https://assets.coingecko.com/coins/images/28129/thumb/6B1F96A9-E306-4328-862A-FBE9209D1C0D.jpeg",
  "MOONLIGHT": "https://assets.coingecko.com/coins/images/15908/thumb/logo-200x200_%284%29.png",
  "EXT": "https://assets.coingecko.com/coins/images/28241/thumb/IMG_20220614_095726_079.jpg",
  "KBD": "https://assets.coingecko.com/coins/images/24003/thumb/mrSWHBjQ_400x400.jpg",
  "EBEN": "https://assets.coingecko.com/coins/images/21330/thumb/200x200.png",
  "GATOR": "https://assets.coingecko.com/coins/images/15634/thumb/Gator.png",
  "THERADIO": "https://assets.coingecko.com/coins/images/28485/thumb/Safeimagekit-resized-imgpng.png",
  "QLT": "https://assets.coingecko.com/coins/images/24536/thumb/8jYfKBTk_400x400.jpg",
  "GOBI": "https://assets.coingecko.com/coins/images/28554/thumb/GOBI.png",
  "VDAI": "https://assets.coingecko.com/coins/images/13920/thumb/venus_dai.png",
  "AWETH": "https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png",
  "TFT": "https://assets.coingecko.com/coins/images/6704/thumb/black_logo_on_white_background_%282%29.jpg",
  "INSURE": "https://assets.coingecko.com/coins/images/23913/thumb/InsureDAO_-_Final_Logo_%28solo%29.png",
  "CNAME": "https://assets.coingecko.com/coins/images/23868/thumb/_NsbVq86_400x400.jpg",
  "TESS": "https://assets.coingecko.com/coins/images/27966/thumb/photo_2022-10-20_10.54.55.jpeg",
  "RAMEN": "https://assets.coingecko.com/coins/images/14046/thumb/SRtRawSI_400x400.png",
  "CRONA": "https://assets.coingecko.com/coins/images/21098/thumb/logox200.png",
  "ISOLA": "https://assets.coingecko.com/coins/images/17460/thumb/IS_token_icon3.png",
  "VDOT": "https://assets.coingecko.com/coins/images/28875/thumb/vDOT.png",
  "SGT": "https://assets.coingecko.com/coins/images/16077/thumb/Space-Goat-200-x-200-01.png",
  "BOXER": "https://assets.coingecko.com/coins/images/16748/thumb/boxer_logo.png",
  "SFIT": "https://assets.coingecko.com/coins/images/28533/thumb/S4F200x200px.png",
  "DEGG": "https://assets.coingecko.com/coins/images/23564/thumb/LDoi0N5YEA_l8vbR4lL_6plCcklHA3ZNEu5Kgf5kVCqKBe8cMKo7azDzuAp2ivUMWlZtHPBcZMirGW-4E6-9Fz8noNIX-1eaVcDYig-QkhNRn5DF8zNO6NPSoIARer8a5Zcbs1thirIU9D9waIOdB2b_y3DcOtX_S8qvVeRxcP0XM6qE1fdyqqkSLOe63JmKXt_Dd9de2k_EkC.jpg",
  "L2DAO": "https://assets.coingecko.com/coins/images/23699/thumb/Khp7Y4Sn.png",
  "PSBD": "https://assets.coingecko.com/coins/images/27217/thumb/Pet-Store-LOGO.png",
  "LEMO": "https://assets.coingecko.com/coins/images/3108/thumb/lemochain.png",
  "KLC": "https://assets.coingecko.com/coins/images/6770/thumb/photo5770789068056144000.jpg",
  "TUF": "https://assets.coingecko.com/coins/images/28410/thumb/2022-12-06_10.10.16.jpg",
  "SPORTS": "https://assets.coingecko.com/coins/images/6806/thumb/Z74Q3Vkv_400x400.jpg",
  "WD": "https://assets.coingecko.com/coins/images/28701/thumb/logo_%2813%29.png",
  "SHIBARMY": "https://assets.coingecko.com/coins/images/19779/thumb/shiba_army.PNG",
  "POCHI": "https://assets.coingecko.com/coins/images/23816/thumb/logo-gold.png",
  "MBLOX": "https://assets.coingecko.com/coins/images/23639/thumb/coin.png",
  "HIPPO": "https://assets.coingecko.com/coins/images/26196/thumb/Safeimagekit-resized-imgpng_%282%29.png",
  "FTN": "https://assets.coingecko.com/coins/images/28478/thumb/lightenicon_200x200.png",
  "TDG": "https://assets.coingecko.com/coins/images/23601/thumb/tdg.png",
  "BABYDOUG": "https://assets.coingecko.com/coins/images/17631/thumb/baby_doug.PNG",
  "ALIEN": "https://assets.coingecko.com/coins/images/20719/thumb/alien_logo.png",
  "KICH": "https://assets.coingecko.com/coins/images/16865/thumb/japanese_coin_logo200.png",
  "HTF": "https://assets.coingecko.com/coins/images/23574/thumb/logo-200.png",
  "TOTEM": "https://assets.coingecko.com/coins/images/25056/thumb/C6_miUEU_400x400.jpg",
  "NSUR": "https://assets.coingecko.com/coins/images/21690/thumb/nsurcoin.png",
  "AENS": "https://assets.coingecko.com/coins/images/15163/thumb/x58-DmSw.png",
  "CHIPS": "https://assets.coingecko.com/coins/images/18698/thumb/chipstars_coin_200x200.png",
  "RESET": "https://assets.coingecko.com/coins/images/26631/thumb/logo.png",
  "METTI": "https://assets.coingecko.com/coins/images/19561/thumb/alLjUap.png",
  "MG": "https://assets.coingecko.com/coins/images/8594/thumb/MinerGate.png",
  "P4C": "https://assets.coingecko.com/coins/images/27236/thumb/logo_200.png",
  "GERA": "https://assets.coingecko.com/coins/images/13686/thumb/GeraCoin_Logo-icon-1000px.png",
  "NFTC": "https://assets.coingecko.com/coins/images/21898/thumb/9681.png",
  "QBC": "https://assets.coingecko.com/coins/images/2826/thumb/quebecoin.png",
  "SENT": "https://assets.coingecko.com/coins/images/16388/thumb/94SwpUOP_400x400.jpg",
  "TWI": "https://assets.coingecko.com/coins/images/13060/thumb/Logo.png",
  "FLOKICOKE": "https://assets.coingecko.com/coins/images/21924/thumb/FlokiCoke_LOGO_Square_200.png",
  "AQUAGOAT": "https://assets.coingecko.com/coins/images/18676/thumb/ag.png",
  "CURRY": "https://assets.coingecko.com/coins/images/13656/thumb/curry_logo.jpg",
  "ARNXM": "https://assets.coingecko.com/coins/images/13753/thumb/1_otmQ1sN0MgsT4idRsPsu3w.png",
  "DSP": "https://assets.coingecko.com/coins/images/26236/thumb/4Wbz4ZFt_400x400.png",
  "XLD": "https://assets.coingecko.com/coins/images/16730/thumb/stellar_diamond.PNG",
  "P21": "https://assets.coingecko.com/coins/images/26899/thumb/IMG_20220816_124852_822_%281%29.png",
  "SMURF": "https://assets.coingecko.com/coins/images/26804/thumb/200x200.png",
  "BIG": "https://assets.coingecko.com/coins/images/24439/thumb/dI9fHKQH_400x400.jpg",
  "MSWAP": "https://assets.coingecko.com/coins/images/13576/thumb/logo_%281%29.png",
  "ZEBRA": "https://assets.coingecko.com/coins/images/27393/thumb/200x200.png",
  "BNBH": "https://assets.coingecko.com/coins/images/20660/thumb/YmZPSg0q_400x400.jpg",
  "CAPP": "https://assets.coingecko.com/coins/images/1302/thumb/cappasity.png",
  "REAP": "https://assets.coingecko.com/coins/images/13109/thumb/REAP.jpg",
  "SCA": "https://assets.coingecko.com/coins/images/16360/thumb/thumbnail_1170823958_vertical_logo_lateral_radiance.png",
  "VETH": "https://assets.coingecko.com/coins/images/14484/thumb/veth_logo_uniswap_200x200.png",
  "MIT": "https://assets.coingecko.com/coins/images/27512/thumb/FjBsKW6V_400x400.jpeg",
  "FUSDC": "https://assets.coingecko.com/coins/images/28471/thumb/fUSDC-200x200.png",
  "KRP": "https://assets.coingecko.com/coins/images/27963/thumb/logo200x200.png",
  "NFT2$": "https://assets.coingecko.com/coins/images/28154/thumb/DCFFCF6C-5981-419A-A730-CF35ADA60420.jpeg",
  "BGC": "https://assets.coingecko.com/coins/images/13503/thumb/logo_%2897%29.png",
  "KLTR": "https://assets.coingecko.com/coins/images/19926/thumb/kbase_logo.jpg",
  "HSUITE": "https://assets.coingecko.com/coins/images/28593/thumb/hbar.jpeg",
  "ZZC": "https://assets.coingecko.com/coins/images/27336/thumb/ZZcoin200x200.jpg",
  "SCS": "https://assets.coingecko.com/coins/images/2929/thumb/scs_logo_200x200.png",
  "WIPE": "https://assets.coingecko.com/coins/images/19967/thumb/wipemyass.jpeg",
  "INMES": "https://assets.coingecko.com/coins/images/26523/thumb/inme_swap.png",
  "MCAU": "https://assets.coingecko.com/coins/images/13149/thumb/meldgold.png",
  "VXVS": "https://assets.coingecko.com/coins/images/13903/thumb/xvs_logo.png",
  "BCF": "https://assets.coingecko.com/coins/images/1323/thumb/bcf.png",
  "CFOOT": "https://assets.coingecko.com/coins/images/27132/thumb/cfoot_logo_200x200.png",
  "SPK": "https://assets.coingecko.com/coins/images/2605/thumb/redsparksta.png",
  "KISHK": "https://assets.coingecko.com/coins/images/26967/thumb/kishk.png",
  "PRV": "https://assets.coingecko.com/coins/images/15384/thumb/Logo-Round.png",
  "FIRSTHARE": "https://assets.coingecko.com/coins/images/22788/thumb/17453.png",
  "GHOSTFACE": "https://assets.coingecko.com/coins/images/15937/thumb/J5oSbp1.png",
  "CLM": "https://assets.coingecko.com/coins/images/6687/thumb/coinclaim.jpg",
  "JGLP": "https://assets.coingecko.com/coins/images/28892/thumb/hatGLP.png",
  "LTZ": "https://assets.coingecko.com/coins/images/9493/thumb/ltz_logo.png",
  "UNFT": "https://assets.coingecko.com/coins/images/15506/thumb/VkZQUF7q_400x400.jpg",
  "PULSEDOGE": "https://assets.coingecko.com/coins/images/19695/thumb/pulse.png",
  "REDAN": "https://assets.coingecko.com/coins/images/8292/thumb/REDAN.png",
  "LUTO": "https://assets.coingecko.com/coins/images/18889/thumb/CG2VIsRg_400x400.jpg",
  "MOOND": "https://assets.coingecko.com/coins/images/22050/thumb/moondlogo.png",
  "VLTC": "https://assets.coingecko.com/coins/images/13908/thumb/ltc.png",
  "CURVE": "https://assets.coingecko.com/coins/images/13248/thumb/curve.png",
  "RI": "https://assets.coingecko.com/coins/images/12536/thumb/Ri_logo_512x512.png",
  "TTC": "https://assets.coingecko.com/coins/images/27649/thumb/logo_%EC%A0%9C%EC%B6%9C%EC%9A%A9.png",
  "YCRV": "https://assets.coingecko.com/coins/images/27622/thumb/yearncrvnew_32.png",
  "MYH": "https://assets.coingecko.com/coins/images/19985/thumb/myh.png",
  "AKITA": "https://assets.coingecko.com/coins/images/14115/thumb/akita.png",
  "SHIBLITE": "https://assets.coingecko.com/coins/images/19830/thumb/shibalite.PNG",
  "COAL": "https://assets.coingecko.com/coins/images/10520/thumb/g6wUzY5a_400x400.png",
  "SPILLWAYS": "https://assets.coingecko.com/coins/images/28358/thumb/1669791775144_%281%29.png",
  "BITD": "https://assets.coingecko.com/coins/images/19244/thumb/8bit.png",
  "DAL": "https://assets.coingecko.com/coins/images/20208/thumb/dal.png",
  "TCO": "https://assets.coingecko.com/coins/images/13811/thumb/logo200_%289%29.png",
  "DOGESHREK": "https://assets.coingecko.com/coins/images/26888/thumb/rcy5TkPI_400x400.jpeg",
  "ESGC": "https://assets.coingecko.com/coins/images/22085/thumb/logo-01.png",
  "DOSHIB": "https://assets.coingecko.com/coins/images/26867/thumb/doshib.png",
  "BITO": "https://assets.coingecko.com/coins/images/7796/thumb/bitopro_28.png",
  "BLEO": "https://assets.coingecko.com/coins/images/18764/thumb/2bP4pJr4wVimqCWjYimXJe2cnCgnAD5Au3rAU7JkDc2.png",
  "MEBU": "https://assets.coingecko.com/coins/images/27466/thumb/Metaburst200.png",
  "DFT": "https://assets.coingecko.com/coins/images/4819/thumb/digifinextoken.png",
  "LOOK": "https://assets.coingecko.com/coins/images/3080/thumb/lookscoin200.png",
  "LOTT": "https://assets.coingecko.com/coins/images/27688/thumb/LOTT.jpg",
  "DWT": "https://assets.coingecko.com/coins/images/27422/thumb/en-us.jpeg",
  "JM": "https://assets.coingecko.com/coins/images/25450/thumb/jm.png",
  "OXFI": "https://assets.coingecko.com/coins/images/25435/thumb/oxfi-logo200.png",
  "BLOOP": "https://assets.coingecko.com/coins/images/26979/thumb/babylooplogo200X200.png",
  "BHAX": "https://assets.coingecko.com/coins/images/20073/thumb/Bithashex_Grey_and_Blue_Logo.jpeg",
  "AGVC": "https://assets.coingecko.com/coins/images/7056/thumb/G4TML4cb_400x400.jpg",
  "HWL": "https://assets.coingecko.com/coins/images/20344/thumb/hwl.png",
  "SKC": "https://assets.coingecko.com/coins/images/10878/thumb/8ZtRME2h_400x400.jpg",
  "GFTM": "https://assets.coingecko.com/coins/images/18991/thumb/preview-gallery-gFTMgecko_%281%29.png",
  "MVRS": "https://assets.coingecko.com/coins/images/22140/thumb/mvrs-200x200.png",
  "D2O": "https://assets.coingecko.com/coins/images/28732/thumb/LinkedIn_d2o_Icon_300x300px.png",
  "AUST": "https://assets.coingecko.com/coins/images/21097/thumb/aust.png",
  "DCCT": "https://assets.coingecko.com/coins/images/24763/thumb/zf3Q-GS4_400x400.jpg",
  "CARR": "https://assets.coingecko.com/coins/images/14403/thumb/tcjOTKE3_400x400.png",
  "FOHO": "https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png",
  "DM": "https://assets.coingecko.com/coins/images/18220/thumb/iIhpL4K.png",
  "OKAGE": "https://assets.coingecko.com/coins/images/28829/thumb/Okage_Inu.png",
  "PRIMO": "https://assets.coingecko.com/coins/images/25508/thumb/19700.png",
  "FIDLE": "https://assets.coingecko.com/coins/images/26158/thumb/fidlecoin_logo%281%29.png",
  "RMAI": "https://assets.coingecko.com/coins/images/26952/thumb/logormai.png",
  "BLOK": "https://assets.coingecko.com/coins/images/18819/thumb/logo-bholdus-6.png",
  "CBSL": "https://assets.coingecko.com/coins/images/27523/thumb/XQ1K7Vy1_400x400.jpeg",
  "ADXX": "https://assets.coingecko.com/coins/images/25600/thumb/1_bUfRZ1ZwuVjSi83vd_vv4Q.jpeg",
  "TOKKI": "https://assets.coingecko.com/coins/images/26958/thumb/tokki.png",
  "HOLO": "https://assets.coingecko.com/coins/images/28077/thumb/holo200.png",
  "BLP": "https://assets.coingecko.com/coins/images/16471/thumb/horizontal-standard-black.png",
  "JUMP": "https://assets.coingecko.com/coins/images/27926/thumb/jump.png",
  "ANK": "https://assets.coingecko.com/coins/images/12308/thumb/photo_2020-08-18_18-22-13.jpg",
  "REW": "https://assets.coingecko.com/coins/images/11614/thumb/REW_medium.png",
  "DEEZNUTS": "https://assets.coingecko.com/coins/images/15967/thumb/deeznuts200x200.png",
  "FUTURE-AI": "https://assets.coingecko.com/coins/images/28841/thumb/200x200.png",
  "REPO": "https://assets.coingecko.com/coins/images/4371/thumb/repocoin-logo.jpg",
  "SMETA": "https://assets.coingecko.com/coins/images/25851/thumb/logo_starkmeta.png",
  "BNA": "https://assets.coingecko.com/coins/images/10289/thumb/sMCxdYBa_400x400.jpg",
  "SAFEMONEY": "https://assets.coingecko.com/coins/images/22953/thumb/Safe-Money-BSC-Logo200.png",
  "ILUS": "https://assets.coingecko.com/coins/images/22662/thumb/Jez4bAM4.png",
  "QTF": "https://assets.coingecko.com/coins/images/14033/thumb/FtSOX9Vy_400x400.jpg",
  "RIKEN": "https://assets.coingecko.com/coins/images/25167/thumb/FiRSQOuA_400x400.jpg",
  "VRBLOCKS": "https://assets.coingecko.com/coins/images/25299/thumb/IMG-20220426-151515-882.jpg",
  "HLY": "https://assets.coingecko.com/coins/images/23416/thumb/holygrail.PNG",
  "BTZC": "https://assets.coingecko.com/coins/images/5470/thumb/Beatzcoin-logo.png",
  "CHEEZ": "https://assets.coingecko.com/coins/images/21169/thumb/cheze.jpg",
  "XCF": "https://assets.coingecko.com/coins/images/10987/thumb/xcf-icon-01-1-flat.png",
  "BLAST": "https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png",
  "DKEY": "https://assets.coingecko.com/coins/images/20212/thumb/dkey.PNG",
  "SOC": "https://assets.coingecko.com/coins/images/25629/thumb/auC_SL79_400x400.jpg",
  "REDKISHU": "https://assets.coingecko.com/coins/images/20569/thumb/iPae-l9j_400x400.jpg",
  "FOREST": "https://assets.coingecko.com/coins/images/26984/thumb/photo_2022-08-20_23-40-23.jpg",
  "CPAD": "https://assets.coingecko.com/coins/images/26186/thumb/Cronospad_logo_200x200.png",
  "TWOGE": "https://assets.coingecko.com/coins/images/26206/thumb/rsz_ttj9bx8b_400x400.png",
  "PAYT": "https://assets.coingecko.com/coins/images/12513/thumb/logo.png",
  "LTD": "https://assets.coingecko.com/coins/images/17492/thumb/LTD-02.png",
  "PACHA": "https://assets.coingecko.com/coins/images/27194/thumb/0_Lzf-8HxwOnPjYWi-.jpeg",
  "BURD": "https://assets.coingecko.com/coins/images/22839/thumb/_TOvRxfx_400x400.jpg",
  "LOTO": "https://assets.coingecko.com/coins/images/6611/thumb/xkKemBDV_400x400.jpg",
  "FLIKS": "https://assets.coingecko.com/coins/images/25117/thumb/Filmcoin_icon_32x32.png",
  "MATSUSWAP": "https://assets.coingecko.com/coins/images/28041/thumb/200x200-1.png",
  "DOGETREND": "https://assets.coingecko.com/coins/images/28612/thumb/200x200.jpg",
  "BELUGA": "https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png",
  "MGS": "https://assets.coingecko.com/coins/images/19117/thumb/py-mXfYD_400x400.jpg",
  "FLOKIS": "https://assets.coingecko.com/coins/images/19370/thumb/flokis.png",
  "EVO": "https://assets.coingecko.com/coins/images/25373/thumb/evo.png",
  "PDAI": "https://assets.coingecko.com/coins/images/12338/thumb/pdai-200px.png",
  "BUN": "https://assets.coingecko.com/coins/images/202/thumb/bunnycoin.png",
  "BLUES": "https://assets.coingecko.com/coins/images/25028/thumb/download.png",
  "XTR": "https://assets.coingecko.com/coins/images/21281/thumb/xtr.png",
  "VCOIN": "https://assets.coingecko.com/coins/images/28577/thumb/vcoin.png",
  "ZENC": "https://assets.coingecko.com/coins/images/25055/thumb/1TMxKir4_400x400.png",
  "KMON": "https://assets.coingecko.com/coins/images/17886/thumb/kryptomon.png",
  "KVT": "https://assets.coingecko.com/coins/images/27220/thumb/kryptview.jpeg",
  "NNN": "https://assets.coingecko.com/coins/images/11852/thumb/nnn.png",
  "AMANA": "https://assets.coingecko.com/coins/images/11730/thumb/aMANA.png",
  "KUKY": "https://assets.coingecko.com/coins/images/7465/thumb/images_%283%29.jpeg",
  "CPTLC": "https://assets.coingecko.com/coins/images/27744/thumb/cptlc-logo-200.png",
  "PTE": "https://assets.coingecko.com/coins/images/13100/thumb/peetdefi_logo.png",
  "MDR": "https://assets.coingecko.com/coins/images/26145/thumb/MudraLogo200x200.png",
  "DBOMB": "https://assets.coingecko.com/coins/images/27946/thumb/IMG_8762E9D638E0-1.jpeg",
  "BIXB": "https://assets.coingecko.com/coins/images/14237/thumb/cropped-coin-192x192.png",
  "NUGEN": "https://assets.coingecko.com/coins/images/28403/thumb/Nugen-coin-logo-200px_%282%29.png",
  "UMINT": "https://assets.coingecko.com/coins/images/24564/thumb/xXlHazn__400x400.jpg",
  "DGAT": "https://assets.coingecko.com/coins/images/19412/thumb/pfeuRgb.png",
  "CRM": "https://assets.coingecko.com/coins/images/1155/thumb/cream.png",
  "WOLFE": "https://assets.coingecko.com/coins/images/19647/thumb/ea2e65_7f14054a4493484ca8b88e2af221a28e_mv2.png",
  "ISDT": "https://assets.coingecko.com/coins/images/12568/thumb/istardust.png",
  "GREYHOUND": "https://assets.coingecko.com/coins/images/20427/thumb/Profile_Pic_%281%29.png",
  "CMPN": "https://assets.coingecko.com/coins/images/27914/thumb/cmpn.png",
  "VFIL": "https://assets.coingecko.com/coins/images/13925/thumb/vfil_final.png",
  "CROGE": "https://assets.coingecko.com/coins/images/24903/thumb/transparent.png",
  "DLA": "https://assets.coingecko.com/coins/images/26771/thumb/200x200.png",
  "NVIR": "https://assets.coingecko.com/coins/images/21359/thumb/Nvir_symbol.png",
  "WCFLT": "https://assets.coingecko.com/coins/images/20815/thumb/CMC_Coinflect_Logo-01.png",
  "NOCH": "https://assets.coingecko.com/coins/images/24880/thumb/logo.png",
  "FFA": "https://assets.coingecko.com/coins/images/26095/thumb/festa_finance.png",
  "MOBY": "https://assets.coingecko.com/coins/images/24998/thumb/nG3ToMV5_400x400.png",
  "JPC": "https://assets.coingecko.com/coins/images/27717/thumb/200_200_JPEX_LOGO_transparent.png",
  "EUBC": "https://assets.coingecko.com/coins/images/6547/thumb/j2Lj7uGH_400x400.jpg",
  "BTH": "https://assets.coingecko.com/coins/images/6241/thumb/kLV4Cv-x_400x400.jpg",
  "ROO": "https://assets.coingecko.com/coins/images/27943/thumb/lucky200.png",
  "DDOGE": "https://assets.coingecko.com/coins/images/24803/thumb/ddoge.png",
  "CHICA": "https://assets.coingecko.com/coins/images/28371/thumb/_chica.png",
  "CINU": "https://assets.coingecko.com/coins/images/26987/thumb/u6XXfLNc_400x400.png",
  "BFLOKI": "https://assets.coingecko.com/coins/images/16759/thumb/floki.PNG",
  "GFLY": "https://assets.coingecko.com/coins/images/28828/thumb/GFLY_LOGO.png",
  "BURROW": "https://assets.coingecko.com/coins/images/25492/thumb/BURROW.png",
  "TBAC": "https://assets.coingecko.com/coins/images/26081/thumb/20655.png",
  "MEK": "https://assets.coingecko.com/coins/images/27530/thumb/unknown_%2819%29.png",
  "B3X": "https://assets.coingecko.com/coins/images/25518/thumb/202209_Token_Gate.io_LogoToken_200x200.png",
  "SHIV": "https://assets.coingecko.com/coins/images/24703/thumb/gk-logo.png",
  "MASK20": "https://assets.coingecko.com/coins/images/17856/thumb/unnamed_%284%29.jpg",
  "PLUTO": "https://assets.coingecko.com/coins/images/26561/thumb/PLUTO_Token_Image.png",
  "MSPACE": "https://assets.coingecko.com/coins/images/22679/thumb/A309-EBB8-43-B8-4-F3-C-8256-B027-BC58-E821.jpg",
  "WNOW": "https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG",
  "CLIST": "https://assets.coingecko.com/coins/images/20733/thumb/F0fir0BN_400x400.jpg",
  "$PIZZA": "https://assets.coingecko.com/coins/images/21368/thumb/mascot200.png",
  "WINRY": "https://assets.coingecko.com/coins/images/19420/thumb/winry.png",
  "RIV": "https://assets.coingecko.com/coins/images/26892/thumb/WechatIMG2340.jpeg",
  "MARVIN": "https://assets.coingecko.com/coins/images/20886/thumb/marvin.png",
  "ANM": "https://assets.coingecko.com/coins/images/24873/thumb/16410.png",
  "MZ": "https://assets.coingecko.com/coins/images/20369/thumb/metazilla_auto_x2.png",
  "BHAT": "https://assets.coingecko.com/coins/images/25656/thumb/bhat.png",
  "SAFEEARTH": "https://assets.coingecko.com/coins/images/15449/thumb/SafeEarthLogo2000.png",
  "DOBE": "https://assets.coingecko.com/coins/images/16002/thumb/doberman.PNG",
  "DARA": "https://assets.coingecko.com/coins/images/17640/thumb/darav2.png",
  "OGT": "https://assets.coingecko.com/coins/images/27314/thumb/octogamex_logo_200x200.png",
  "VDGT": "https://assets.coingecko.com/coins/images/23925/thumb/18282.png",
  "HACHI": "https://assets.coingecko.com/coins/images/28862/thumb/HACHI_now.png",
  "CLA": "https://assets.coingecko.com/coins/images/22165/thumb/93032948.png",
  "GOOFYDOGE": "https://assets.coingecko.com/coins/images/20640/thumb/LR2SGDFq_400x400.jpg",
  "CUP": "https://assets.coingecko.com/coins/images/28278/thumb/Baby.png",
  "MCF": "https://assets.coingecko.com/coins/images/7809/thumb/thumbnail.png",
  "RUNEARN": "https://assets.coingecko.com/coins/images/27594/thumb/Runearner_200x200.png",
  "RETRO": "https://assets.coingecko.com/coins/images/21041/thumb/lWdfnsR.png",
  "TOKI": "https://assets.coingecko.com/coins/images/15501/thumb/Tokyo-Inu-200x200.png",
  "ULTRA": "https://assets.coingecko.com/coins/images/15503/thumb/YjaL3nG.png",
  "ZEUS10000": "https://assets.coingecko.com/coins/images/22559/thumb/2022-01-11_22.10.11.jpg",
  "ATHD": "https://assets.coingecko.com/coins/images/21705/thumb/HI-Smile.1eb82fa1.png",
  "TGOLD": "https://assets.coingecko.com/coins/images/26595/thumb/TaroGold_%281%29.png",
  "MKD": "https://assets.coingecko.com/coins/images/22317/thumb/20211215_125931.jpg",
  "SOMM": "https://assets.coingecko.com/coins/images/23308/thumb/somm_new.png",
  "GP": "https://assets.coingecko.com/coins/images/21162/thumb/GPGP.jpg",
  "KISHIMOTO": "https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png",
  "KTO": "https://assets.coingecko.com/coins/images/21251/thumb/KTO.png",
  "MTG": "https://assets.coingecko.com/coins/images/19302/thumb/mtg.PNG",
  "MFLATE": "https://assets.coingecko.com/coins/images/19598/thumb/mflate.PNG",
  "MBIT": "https://assets.coingecko.com/coins/images/616/thumb/mbitbooks.png",
  "TRBL": "https://assets.coingecko.com/coins/images/21728/thumb/15994.png",
  "FANZ": "https://assets.coingecko.com/coins/images/27722/thumb/FrontFanz.jpg",
  "MILE": "https://assets.coingecko.com/coins/images/24690/thumb/MILE.png",
  "HUA": "https://assets.coingecko.com/coins/images/15651/thumb/chihua.PNG",
  "TKINU": "https://assets.coingecko.com/coins/images/15681/thumb/tkinu.png",
  "FCP": "https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG",
  "ATO": "https://assets.coingecko.com/coins/images/7132/thumb/ato.png",
  "SHIBSC": "https://assets.coingecko.com/coins/images/15800/thumb/shibsc.png",
  "WHL": "https://assets.coingecko.com/coins/images/14328/thumb/ArEX8tkV_400x400.png",
  "JEX": "https://assets.coingecko.com/coins/images/27282/thumb/logo_token_200.png",
  "FLYP": "https://assets.coingecko.com/coins/images/27283/thumb/FLYPE_LOGO_200X200.jpg",
  "HOME": "https://assets.coingecko.com/coins/images/26185/thumb/homecoin_200.png",
  "FANI": "https://assets.coingecko.com/coins/images/24029/thumb/fani_200x200.png",
  "MINECRAFT": "https://assets.coingecko.com/coins/images/21247/thumb/logo_-_2021-12-06T135451.604.png",
  "HIPENGUINS": "https://assets.coingecko.com/coins/images/28381/thumb/63856bebcd8c700001b23c42_hiPENGUINS-_Logo.png",
  "THOREUM": "https://assets.coingecko.com/coins/images/22759/thumb/Thoreum_V3_logo_200x200.png",
  "TUNE": "https://assets.coingecko.com/coins/images/9423/thumb/tunelogo.png",
  "USDW": "https://assets.coingecko.com/coins/images/28132/thumb/LOGO.png",
  "SWAVE": "https://assets.coingecko.com/coins/images/28830/thumb/swave_logo.png",
  "ZOMBIE": "https://assets.coingecko.com/coins/images/28120/thumb/LOGO.png",
  "ESPRO": "https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg",
  "FRATS": "https://assets.coingecko.com/coins/images/26865/thumb/200x200.png",
  "HAO": "https://assets.coingecko.com/coins/images/27318/thumb/5961657012978_.pic.jpg",
  "GERO": "https://assets.coingecko.com/coins/images/15602/thumb/PdAQXY8t_400x400.jpg",
  "MRST": "https://assets.coingecko.com/coins/images/27230/thumb/MRST.png",
  "SAFEICARUS": "https://assets.coingecko.com/coins/images/15325/thumb/safelcarus.PNG",
  "VEGI": "https://assets.coingecko.com/coins/images/8568/thumb/JKUe5u-m_400x400.jpg",
  "BABYKISHU": "https://assets.coingecko.com/coins/images/16645/thumb/baby_kishu.PNG",
  "BTAF": "https://assets.coingecko.com/coins/images/28871/thumb/BTAF_BSC200x200-CoinGecko.png",
  "BKK": "https://assets.coingecko.com/coins/images/7734/thumb/b8a9c996583ee19c2a98dca34b92a26.png",
  "DARKCRYSTL": "https://assets.coingecko.com/coins/images/26486/thumb/1-modified.png",
  "BHD": "https://assets.coingecko.com/coins/images/6902/thumb/CtohuWOU_400x400.jpg",
  "ZARH": "https://assets.coingecko.com/coins/images/8444/thumb/bmdDuMV.png",
  "DANDY": "https://assets.coingecko.com/coins/images/12764/thumb/487ogltc_400x400.jpg",
  "AUTOS": "https://assets.coingecko.com/coins/images/27083/thumb/autos.png",
  "HORSEDRACE": "https://assets.coingecko.com/coins/images/27366/thumb/200x200px.jpg",
  "DSOLAR": "https://assets.coingecko.com/coins/images/27403/thumb/logo200.png",
  "SENDC": "https://assets.coingecko.com/coins/images/28334/thumb/SC_Logo_200x200.png",
  "KPETS": "https://assets.coingecko.com/coins/images/23337/thumb/kyTyD2Fi_400x400.jpg",
  "FLUFFY": "https://assets.coingecko.com/coins/images/21769/thumb/fluffy-logo-md.png",
  "BEM": "https://assets.coingecko.com/coins/images/20896/thumb/12878.png",
  "LAZYSHIBA": "https://assets.coingecko.com/coins/images/21565/thumb/logo-with-lace.png",
  "YVRAI": "https://assets.coingecko.com/coins/images/28792/thumb/yvRAI-128-0x873fB544277FD7b977B196a826459a69E27eA4ea.png",
  "MONDAY": "https://assets.coingecko.com/coins/images/24767/thumb/token.png",
  "SOLNUT": "https://assets.coingecko.com/coins/images/21961/thumb/20211210_004320.png",
  "GRBE": "https://assets.coingecko.com/coins/images/18776/thumb/Logo-200x200_%288%29.png",
  "PIXEL": "https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG",
  "GALAXY": "https://assets.coingecko.com/coins/images/22159/thumb/Nj8bssS8_400x400.jpg",
  "BLOC": "https://assets.coingecko.com/coins/images/6206/thumb/bloc-money.png",
  "COLLAR": "https://assets.coingecko.com/coins/images/18324/thumb/dcLogo.png",
  "MSHIBA": "https://assets.coingecko.com/coins/images/19962/thumb/mshiba.png",
  "RVZ": "https://assets.coingecko.com/coins/images/21996/thumb/LogoBSC.png",
  "ACED": "https://assets.coingecko.com/coins/images/4761/thumb/aced.png",
  "PKTK": "https://assets.coingecko.com/coins/images/28627/thumb/Peak_Token_silver_vector.png",
  "AGTE": "https://assets.coingecko.com/coins/images/18314/thumb/logogecko.png",
  "PIRATECOIN☠": "https://assets.coingecko.com/coins/images/18278/thumb/logo_200_%284%29.png",
  "MELI": "https://assets.coingecko.com/coins/images/20211/thumb/meli_games.PNG",
  "TEDDY": "https://assets.coingecko.com/coins/images/23389/thumb/17859.png",
  "FUSD": "https://assets.coingecko.com/coins/images/24046/thumb/fuse-dollar.png",
  "PWR": "https://assets.coingecko.com/coins/images/22232/thumb/V8pZErwb_400x400.jpg",
  "PS1": "https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg",
  "DANGERMOON": "https://assets.coingecko.com/coins/images/18853/thumb/darkLogo200.png",
  "SOUND": "https://assets.coingecko.com/coins/images/20290/thumb/94028407.png",
  "KABY": "https://assets.coingecko.com/coins/images/18090/thumb/URPKhnop_400x400.jpg",
  "PXDOGE": "https://assets.coingecko.com/coins/images/27919/thumb/pxdoge.png",
  "SHADE": "https://assets.coingecko.com/coins/images/18073/thumb/SHADE-Token-Logo.png",
  "SMX": "https://assets.coingecko.com/coins/images/21331/thumb/solarmine.PNG",
  "PUN": "https://assets.coingecko.com/coins/images/19230/thumb/cryptopunt.PNG",
  "BORUTO": "https://assets.coingecko.com/coins/images/17926/thumb/Doge-Boruto-200x200.png",
  "ESR": "https://assets.coingecko.com/coins/images/23280/thumb/esr.png",
  "UMW": "https://assets.coingecko.com/coins/images/22566/thumb/zjXDugqs_400x400.jpg",
  "FRT": "https://assets.coingecko.com/coins/images/20173/thumb/Untitled-design-7-removebg-preview-2.png",
  "FRZ": "https://assets.coingecko.com/coins/images/28735/thumb/FrZicon.png",
  "GSK": "https://assets.coingecko.com/coins/images/27527/thumb/IMG_20220927_052445_104.jpg",
  "MNFT": "https://assets.coingecko.com/coins/images/26773/thumb/MNFT-ICON-NO-BAKGROUND.png",
  "PUNKS": "https://assets.coingecko.com/coins/images/22337/thumb/PunksIcon_Small.png",
  "SHBAR": "https://assets.coingecko.com/coins/images/20982/thumb/photo_2021-11-29_13.40.47.jpeg",
  "AF-PRESALEDAO": "https://assets.coingecko.com/coins/images/22596/thumb/PresaleDAO-Logo.png",
  "LIGHT": "https://assets.coingecko.com/coins/images/14357/thumb/ClPrk1N.png",
  "LQD": "https://assets.coingecko.com/coins/images/23323/thumb/Cq7F8wZr_400x400.jpg",
  "PC": "https://assets.coingecko.com/coins/images/19442/thumb/plugchain-logo.jpg",
  "DGA": "https://assets.coingecko.com/coins/images/20418/thumb/TsYnMvAF_400x400.jpg",
  "SOLBEAR": "https://assets.coingecko.com/coins/images/20778/thumb/logo.png",
  "BCAKE": "https://assets.coingecko.com/coins/images/21000/thumb/Untitled-design-6.png",
  "SANTA": "https://assets.coingecko.com/coins/images/19654/thumb/eXsu0oNf_400x400.jpg",
  "BUFLOKI": "https://assets.coingecko.com/coins/images/22585/thumb/QhM6uGv9_400x400.jpg",
  "WIZARD": "https://assets.coingecko.com/coins/images/17068/thumb/Wizards.png",
  "SHINJI": "https://assets.coingecko.com/coins/images/28851/thumb/logo_%282%29_%282%29_%281%29_%281%29.png",
  "TOTORO": "https://assets.coingecko.com/coins/images/20277/thumb/cLogo.png",
  "ICHIGO": "https://assets.coingecko.com/coins/images/19243/thumb/ichigoinu.png",
  "ABC": "https://assets.coingecko.com/coins/images/27172/thumb/abc.png",
  "GBET": "https://assets.coingecko.com/coins/images/22708/thumb/8yW1oK9.png",
  "VDORA": "https://assets.coingecko.com/coins/images/22737/thumb/CMC-VDORA-LOGO-200x200.png",
  "SHI3LD": "https://assets.coingecko.com/coins/images/17641/thumb/QRE5xI0.png",
  "RAINSPIRIT": "https://assets.coingecko.com/coins/images/27041/thumb/Coin_1.png",
  "TMT": "https://assets.coingecko.com/coins/images/22490/thumb/TMT-200.png",
  "GOGE": "https://assets.coingecko.com/coins/images/20631/thumb/14766.png",
  "PURR": "https://assets.coingecko.com/coins/images/18329/thumb/purr.png",
  "CARBON": "https://assets.coingecko.com/coins/images/1291/thumb/carboncoin.png",
  "BABYTRUMP": "https://assets.coingecko.com/coins/images/18014/thumb/Baby_Trump_Transpa.png",
  "YOCO": "https://assets.coingecko.com/coins/images/17395/thumb/yocoin.PNG",
  "ELE": "https://assets.coingecko.com/coins/images/1278/thumb/elementrem.png",
  "AUDT": "https://assets.coingecko.com/coins/images/6398/thumb/auditchain.png",
  "SFEX": "https://assets.coingecko.com/coins/images/16938/thumb/sfex.png",
  "SONAR": "https://assets.coingecko.com/coins/images/20494/thumb/S_7gaWIC_400x400.png",
  "DOGET": "https://assets.coingecko.com/coins/images/20458/thumb/PGOrmP9S_400x400.jpg",
  "PXL": "https://assets.coingecko.com/coins/images/19750/thumb/Qma4wzNogtUkuhMgzyKBqzaYBqabLVZrHcLbLmJE38B5XH.png",
  "HIMO": "https://assets.coingecko.com/coins/images/23000/thumb/16726.png",
  "MOONER": "https://assets.coingecko.com/coins/images/19537/thumb/coinmooner.PNG",
  "ES2": "https://assets.coingecko.com/coins/images/27878/thumb/IMG_20221018_193306_362.png",
  "BACK": "https://assets.coingecko.com/coins/images/26973/thumb/logoring200x200.png",
  "$CHOO": "https://assets.coingecko.com/coins/images/28525/thumb/New-Inu.jpeg",
  "GWAR": "https://assets.coingecko.com/coins/images/23686/thumb/D743-B6-E4-282-B-494-A-BC0-B-DE70667-FC561.jpg",
  "LEA": "https://assets.coingecko.com/coins/images/23516/thumb/leapableio-icon.png",
  "CNW": "https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png",
  "MMM7": "https://assets.coingecko.com/coins/images/18216/thumb/logo.png",
  "EBIRD": "https://assets.coingecko.com/coins/images/17465/thumb/earlybird.png",
  "JUSTICE": "https://assets.coingecko.com/coins/images/23555/thumb/JUSTICE_token_logo.png",
  "DELOS": "https://assets.coingecko.com/coins/images/18899/thumb/20211002_161455_0000.png",
  "TIPO": "https://assets.coingecko.com/coins/images/28718/thumb/%281%29_TIPO_Token_logo.png",
  "TRUST": "https://assets.coingecko.com/coins/images/14656/thumb/trustvibes.png",
  "CLEAN": "https://assets.coingecko.com/coins/images/21421/thumb/clean.png",
  "$MARBLE": "https://assets.coingecko.com/coins/images/24043/thumb/marble-logo.png",
  "PGNT": "https://assets.coingecko.com/coins/images/19579/thumb/logo-1024x1024.png",
  "PLCU": "https://assets.coingecko.com/coins/images/23428/thumb/PLC_Ultima.jpg",
  "CL": "https://assets.coingecko.com/coins/images/2445/thumb/coinlancer.png",
  "CSHIB": "https://assets.coingecko.com/coins/images/29020/thumb/P4rr0xzp_400x400.jpg",
  "MOMMYDOGE": "https://assets.coingecko.com/coins/images/16956/thumb/icon200x200_%281%29.png",
  "LUXE": "https://assets.coingecko.com/coins/images/23478/thumb/200200luxe.png",
  "GAINZ": "https://assets.coingecko.com/coins/images/23545/thumb/ZJK276A.jpeg",
  "BERGERDOGE": "https://assets.coingecko.com/coins/images/28384/thumb/photo_2022-12-02_22.18.34.png",
  "YSOY": "https://assets.coingecko.com/coins/images/16822/thumb/ewnD8Bb.png",
  "CTEX": "https://assets.coingecko.com/coins/images/25358/thumb/ctex_logo.png",
  "LUSD3CRV": "https://assets.coingecko.com/coins/images/28321/thumb/curve-dao-token-crv-logo.png",
  "HOMIECOIN": "https://assets.coingecko.com/coins/images/28699/thumb/logo-homie_wars.png",
  "BSR": "https://assets.coingecko.com/coins/images/17554/thumb/BSR200X200.png",
  "LEAP": "https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png",
  "KRAKBABY": "https://assets.coingecko.com/coins/images/16771/thumb/pdXolk5.png",
  "ARKN": "https://assets.coingecko.com/coins/images/23852/thumb/logo1--1-.png",
  "AU": "https://assets.coingecko.com/coins/images/19157/thumb/mrineaq.png",
  "ASPO": "https://assets.coingecko.com/coins/images/20329/thumb/12599.png",
  "WALL": "https://assets.coingecko.com/coins/images/20234/thumb/ovnDSUV_d.png",
  "TENW": "https://assets.coingecko.com/coins/images/23915/thumb/SqGBZEb.png",
  "BLI": "https://assets.coingecko.com/coins/images/21989/thumb/logo.jpg",
  "DADDYDOGE": "https://assets.coingecko.com/coins/images/16931/thumb/icon-200x200_%281%29.png",
  "APOC": "https://assets.coingecko.com/coins/images/24073/thumb/download.png",
  "CICC": "https://assets.coingecko.com/coins/images/6508/thumb/CICC.png",
  "SML": "https://assets.coingecko.com/coins/images/27504/thumb/SML_200px.png",
  "THUNDERETH": "https://assets.coingecko.com/coins/images/18046/thumb/200x200eth.png",
  "BOREDM": "https://assets.coingecko.com/coins/images/27934/thumb/pIhAX2u-_400x400.png",
  "BTCIX": "https://assets.coingecko.com/coins/images/23999/thumb/btcix.png",
  "COSMIC": "https://assets.coingecko.com/coins/images/20596/thumb/COSMIC_Logo_200x200.png",
  "IOSHIB": "https://assets.coingecko.com/coins/images/20338/thumb/logoioshib200.png",
  "FL": "https://assets.coingecko.com/coins/images/13698/thumb/freeliquid_logo.png",
  "CLION": "https://assets.coingecko.com/coins/images/18725/thumb/20210809_213547-3_%282%29.png",
  "LBR": "https://assets.coingecko.com/coins/images/19233/thumb/lrb.PNG",
  "FYT": "https://assets.coingecko.com/coins/images/29078/thumb/flora.png",
  "YOURWALLET": "https://assets.coingecko.com/coins/images/28937/thumb/logo_200.png",
  "CRIME": "https://assets.coingecko.com/coins/images/25254/thumb/crime.png",
  "SLDR": "https://assets.coingecko.com/coins/images/25284/thumb/logo.jpg",
  "T1NY": "https://assets.coingecko.com/coins/images/24426/thumb/coin.png",
  "ANDRO": "https://assets.coingecko.com/coins/images/24227/thumb/logo.png",
  "FLARE": "https://assets.coingecko.com/coins/images/24310/thumb/Solarflare.png",
  "ALLOY": "https://assets.coingecko.com/coins/images/16083/thumb/Alloy.png",
  "CATGE": "https://assets.coingecko.com/coins/images/16045/thumb/Pics-Art-05-16-06-08-53.png",
  "RPS": "https://assets.coingecko.com/coins/images/20918/thumb/nS5mB7aP_400x400.jpg",
  "CLASS": "https://assets.coingecko.com/coins/images/23336/thumb/rKziC8Zt_400x400.jpg",
  "WHE": "https://assets.coingecko.com/coins/images/19056/thumb/whe.jpg",
  "KVERSE": "https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG",
  "EQT": "https://assets.coingecko.com/coins/images/1202/thumb/equitrader.png",
  "BKB": "https://assets.coingecko.com/coins/images/29057/thumb/200x200_PNG_-_BLACK.png",
  "OKANA": "https://assets.coingecko.com/coins/images/28754/thumb/OkamiLana_Logo_Update.png",
  "CYBR": "https://assets.coingecko.com/coins/images/27985/thumb/Logo_Black.png",
  "UZD": "https://assets.coingecko.com/coins/images/27621/thumb/UZD_Logo_200x200.png",
  "NFA": "https://assets.coingecko.com/coins/images/20340/thumb/200x200_%2839%29.png",
  "GOSH": "https://assets.coingecko.com/coins/images/24768/thumb/Untitled.png",
  "HSHIBA": "https://assets.coingecko.com/coins/images/15517/thumb/hshiba.PNG",
  "BRZE": "https://assets.coingecko.com/coins/images/6727/thumb/breezecoin.png",
  "TTN": "https://assets.coingecko.com/coins/images/8194/thumb/pqhBmJl.jpg",
  "KINGS": "https://assets.coingecko.com/coins/images/27643/thumb/photo_2022-10-05_10.01.29.jpeg",
  "LEMC": "https://assets.coingecko.com/coins/images/24750/thumb/lemonchain.jpg",
  "OZONE": "https://assets.coingecko.com/coins/images/29046/thumb/IMG_20230212_003628_724.jpg",
  "DV": "https://assets.coingecko.com/coins/images/21554/thumb/dv.png",
  "FTO": "https://assets.coingecko.com/coins/images/3980/thumb/logo-footer.png",
  "SWOLE": "https://assets.coingecko.com/coins/images/19643/thumb/swole.png",
  "LOWB": "https://assets.coingecko.com/coins/images/15378/thumb/loser.PNG",
  "RR": "https://assets.coingecko.com/coins/images/25546/thumb/19738.png",
  "VLINK": "https://assets.coingecko.com/coins/images/13923/thumb/vlink.png",
  "WIZ": "https://assets.coingecko.com/coins/images/28069/thumb/CG_WIZ.png",
  "RPC": "https://assets.coingecko.com/coins/images/25094/thumb/logo-rpc_200.png",
  "VBETH": "https://assets.coingecko.com/coins/images/13924/thumb/vbeth.png",
  "PETAL": "https://assets.coingecko.com/coins/images/15275/thumb/explorer_logo.png",
  "VIZSLASWAP": "https://assets.coingecko.com/coins/images/25809/thumb/E66D3A9B-3FB7-4D14-AAE9-7476F3C3F546.png",
  "MTSP": "https://assets.coingecko.com/coins/images/24958/thumb/mtsp.png",
  "USC": "https://assets.coingecko.com/coins/images/28479/thumb/USC.png",
  "LEVL": "https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png",
  "EPX": "https://assets.coingecko.com/coins/images/25444/thumb/ellipsis-light_%281%29.png",
  "SAYAN": "https://assets.coingecko.com/coins/images/16302/thumb/Logo1_%283%29.png",
  "ESM": "https://assets.coingecko.com/coins/images/25078/thumb/04._ESPORT_GAME_1_NBG_NT.png",
  "BSKT": "https://assets.coingecko.com/coins/images/14766/thumb/logo.jpg",
  "LCE": "https://assets.coingecko.com/coins/images/14707/thumb/logo200x200_%282%29.png",
  "GENZ": "https://assets.coingecko.com/coins/images/28039/thumb/Qq_rj-aG_400x400.png",
  "GODE": "https://assets.coingecko.com/coins/images/25140/thumb/KQkiY5pe_400x400.jpg",
  "CLEVCVX": "https://assets.coingecko.com/coins/images/27902/thumb/%E6%88%AA%E5%B1%8F2022-10-21_%E4%B8%8A%E5%8D%8812.43.46.png",
  "OGC": "https://assets.coingecko.com/coins/images/14657/thumb/ogc.png",
  "SHIBGOTCHI": "https://assets.coingecko.com/coins/images/25097/thumb/IMG_1881.png",
  "SCCN": "https://assets.coingecko.com/coins/images/25377/thumb/sccn.png",
  "N8V": "https://assets.coingecko.com/coins/images/5600/thumb/n8vcoin.jpg",
  "QUICKCHART": "https://assets.coingecko.com/coins/images/15387/thumb/3-E7934-AD-31-CC-4-A62-8526-82-B0-DE53-BBFB.png",
  "OS76": "https://assets.coingecko.com/coins/images/1429/thumb/osmiumcoin.png",
  "DD": "https://assets.coingecko.com/coins/images/27094/thumb/dragon-doge-logo.84e6ed7e.png",
  "PROXY": "https://assets.coingecko.com/coins/images/28688/thumb/PROXY_%282%29.png",
  "NOE": "https://assets.coingecko.com/coins/images/26627/thumb/200x200.png",
  "MKP": "https://assets.coingecko.com/coins/images/25421/thumb/MKPToken.png",
  "ROCKETBUSD": "https://assets.coingecko.com/coins/images/19977/thumb/ROCKET__ICON_GRADIENT.png",
  "RGLD": "https://assets.coingecko.com/coins/images/25893/thumb/rgld.png",
  "LYO": "https://assets.coingecko.com/coins/images/25093/thumb/imgonline-com-ua-Resize-lFBg5rEIdAB.png",
  "YVYFI": "https://assets.coingecko.com/coins/images/28785/thumb/yvYFI-128-0xE14d13d8B3b85aF791b2AADD661cDBd5E6097Db1.png",
  "GRCC": "https://assets.coingecko.com/coins/images/25414/thumb/mbLQzw4d_400x400.jpg",
  "DOGMI": "https://assets.coingecko.com/coins/images/27636/thumb/dogmi200.png",
  "USX": "https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png",
  "YEAR": "https://assets.coingecko.com/coins/images/4969/thumb/photo_2021-06-08_19-08-11.jpg",
  "CHEX": "https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png",
  "IMI": "https://assets.coingecko.com/coins/images/18865/thumb/imi.PNG",
  "D3D": "https://assets.coingecko.com/coins/images/28921/thumb/LogoD3D.png",
  "KONJ": "https://assets.coingecko.com/coins/images/9604/thumb/konjungate.png",
  "REKT": "https://assets.coingecko.com/coins/images/28910/thumb/REKT_icon_200.png",
  "SODA": "https://assets.coingecko.com/coins/images/18451/thumb/soda_200.png",
  "HPTF": "https://assets.coingecko.com/coins/images/10388/thumb/Heptafranc_200_x_200_.png",
  "STKR": "https://assets.coingecko.com/coins/images/14535/thumb/stakerdao_logo.png",
  "FERT": "https://assets.coingecko.com/coins/images/25425/thumb/200x200_FERT.png",
  "FB": "https://assets.coingecko.com/coins/images/17711/thumb/FB_Logo.png",
  "PINKIE": "https://assets.coingecko.com/coins/images/27801/thumb/Pinkie-01-b.png",
  "N": "missing_thumb.png",
  "BAMBOO": "https://assets.coingecko.com/coins/images/13856/thumb/Bamboo-token-logo-200.png",
  "ESHIB": "https://assets.coingecko.com/coins/images/26955/thumb/200_png.png",
  "ANTX": "https://assets.coingecko.com/coins/images/27628/thumb/CG.jpg",
  "LITX": "https://assets.coingecko.com/coins/images/26327/thumb/200x200px_LOGO.jpeg",
  "MJT": "https://assets.coingecko.com/coins/images/21662/thumb/Y--WYD4r_400x400.jpg",
  "ETHER": "https://assets.coingecko.com/coins/images/28748/thumb/200-x-200_ETHER_LOGO.jpg",
  "JUSDC": "https://assets.coingecko.com/coins/images/28893/thumb/jUSDC.png",
  "KABOSU INU": "https://assets.coingecko.com/coins/images/28619/thumb/Kabosu_Inu.png",
  "DNC": "https://assets.coingecko.com/coins/images/13170/thumb/1528717728file.png",
  "VBUSD": "https://assets.coingecko.com/coins/images/13902/thumb/vbusd.png",
  "VUSDT": "https://assets.coingecko.com/coins/images/13901/thumb/venus_usdt.png",
  "JIMN": "https://assets.coingecko.com/coins/images/25915/thumb/20476.png",
  "PEL": "https://assets.coingecko.com/coins/images/21290/thumb/propel.PNG",
  "CYRUS": "https://assets.coingecko.com/coins/images/25920/thumb/IrtLgS0f_400x400.jpeg",
  "JCC": "https://assets.coingecko.com/coins/images/12889/thumb/junca.jpg",
  "BTCBAM": "https://assets.coingecko.com/coins/images/12854/thumb/logo.png",
  "ANFD": "https://assets.coingecko.com/coins/images/25921/thumb/angry-doge-anfd.png",
  "$RVV": "https://assets.coingecko.com/coins/images/28578/thumb/rvv.png",
  "LKSM": "https://assets.coingecko.com/coins/images/25817/thumb/LKSM.png",
  "TRIB": "https://assets.coingecko.com/coins/images/12576/thumb/New_logo_circle.png",
  "TP": "https://assets.coingecko.com/coins/images/20514/thumb/tp.png",
  "HDS": "https://assets.coingecko.com/coins/images/8505/thumb/pvGh4E0b_400x400.jpg",
  "WENTXN": "https://assets.coingecko.com/coins/images/27178/thumb/Ratboy-LOGO.png",
  "CFL": "https://assets.coingecko.com/coins/images/5832/thumb/IMG_6861.jpg",
  "ASAN": "https://assets.coingecko.com/coins/images/28189/thumb/200200_copy.png",
  "SHINU": "https://assets.coingecko.com/coins/images/28948/thumb/photo_2023-02-04_17-32-00_prev_ui_%282%29.png",
  "HICOOLCATS": "https://assets.coingecko.com/coins/images/28603/thumb/hicoolcats.png",
  "CTCN": "https://assets.coingecko.com/coins/images/10801/thumb/Contracoin-symbol.png",
  "CRON": "https://assets.coingecko.com/coins/images/4192/thumb/1525692237.png",
  "NBOT": "https://assets.coingecko.com/coins/images/8133/thumb/mBKP0Lne_400x400.jpg",
  "BPLC": "https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png",
  "XPN": "https://assets.coingecko.com/coins/images/8297/thumb/Hr9wHItp_400x400.jpg",
  "PAVAX": "https://assets.coingecko.com/coins/images/26065/thumb/pavax.png",
  "CMQ": "https://assets.coingecko.com/coins/images/26171/thumb/256x256_%281%29.png",
  "UNIA": "https://assets.coingecko.com/coins/images/28657/thumb/UNIA_FARM_JUST_THE_LOGO.png",
  "SHIBAZILLA": "https://assets.coingecko.com/coins/images/19603/thumb/LqewZRH.png",
  "VX": "https://assets.coingecko.com/coins/images/10346/thumb/ViteX.png",
  "BPANDA": "https://assets.coingecko.com/coins/images/22511/thumb/IMG-20220111-182208-149.jpg",
  "TECHNO": "https://assets.coingecko.com/coins/images/26906/thumb/IcU1yLq.png",
  "CST": "https://assets.coingecko.com/coins/images/28111/thumb/cst.png",
  "KISHIBURNO": "https://assets.coingecko.com/coins/images/26751/thumb/coing_gecko.jpg",
  "COLX": "https://assets.coingecko.com/coins/images/966/thumb/colossusxt.png",
  "JUICE": "https://assets.coingecko.com/coins/images/20043/thumb/G0xz40p.png",
  "DBD": "https://assets.coingecko.com/coins/images/21691/thumb/DBD-icon_200x200_%281%29.png",
  "PROT": "https://assets.coingecko.com/coins/images/15259/thumb/rpot.PNG",
  "DVC": "https://assets.coingecko.com/coins/images/8263/thumb/LrDC1OmYiqq05_-E3PFLO6oJou6HOvVMFpjEqbYdcvaIPgQp7mF52lpj_rCmQW1FhAoBg5E2Z9o16L3Z7n8SQrMIp1YZNEGHRzc3P0P3jOZnyKvHNuTXQx9q13_kxXG1oMzaN3HfWdUnTMAfKRwghCa7BX67W9pSV-AJkcQ4-5Qy_qlAN28UCUgVnM0k6ZXlNWZo7Vi00CD7657qw.jpg",
  "RFN": "https://assets.coingecko.com/coins/images/28722/thumb/23100.png",
  "GWBTC": "https://assets.coingecko.com/coins/images/18999/thumb/preview-gallery-gWBTCgecko_%281%29.png",
  "SUGAR": "https://assets.coingecko.com/coins/images/29026/thumb/Safeimagekit-resized-img.png",
  "KAZOKU": "https://assets.coingecko.com/coins/images/27136/thumb/kazoku_inu_logo.png",
  "GB": "https://assets.coingecko.com/coins/images/18294/thumb/gbgbgb.jpg",
  "BBOXER": "https://assets.coingecko.com/coins/images/17160/thumb/P4BvEOA.png",
  "ASIC": "https://assets.coingecko.com/coins/images/27952/thumb/ASIC_Token_Logo.png",
  "RAG": "https://assets.coingecko.com/coins/images/27554/thumb/download_%287%29.png",
  "KATZ": "https://assets.coingecko.com/coins/images/27662/thumb/Screenshot_2022-09-01_at_13.49.26.png",
  "BALVEY": "https://assets.coingecko.com/coins/images/28438/thumb/logo_200.png",
  "SPLINK": "https://assets.coingecko.com/coins/images/19609/thumb/splink.png",
  "SNJ": "https://assets.coingecko.com/coins/images/19730/thumb/logo_-_2021-11-01T200443.131.png",
  "DAPP": "https://assets.coingecko.com/coins/images/8116/thumb/dapp-logo.jpg",
  "GA": "https://assets.coingecko.com/coins/images/20745/thumb/IwqVsND.png",
  "DEFILANCER": "https://assets.coingecko.com/coins/images/25970/thumb/download.png",
  "UETH": "https://assets.coingecko.com/coins/images/14624/thumb/uETH.png",
  "NEZUKO": "https://assets.coingecko.com/coins/images/18652/thumb/O7TBGRA.png",
  "FUC": "https://assets.coingecko.com/coins/images/8132/thumb/FUC_fubt_token.png",
  "SKI": "https://assets.coingecko.com/coins/images/3780/thumb/SKI.png",
  "TIIM": "https://assets.coingecko.com/coins/images/12185/thumb/fOf8ZxL.png",
  "VPRC": "https://assets.coingecko.com/coins/images/1432/thumb/vprc-logo.png",
  "YVSNX": "https://assets.coingecko.com/coins/images/28797/thumb/yvSNX-128-0xF29AE508698bDeF169B89834F76704C3B205aedf.png",
  "GON": "https://assets.coingecko.com/coins/images/26073/thumb/APP-09_%282%29aa_%281%29.png",
  "FORGE": "https://assets.coingecko.com/coins/images/24533/thumb/io0E0FB2_400x400.jpg",
  "SWL": "https://assets.coingecko.com/coins/images/6052/thumb/swiftlance_qssxxz.png",
  "STRM": "https://assets.coingecko.com/coins/images/21407/thumb/HRZ0RUOh_400x400.jpg",
  "LUCKY": "https://assets.coingecko.com/coins/images/27685/thumb/IMG_1129.PNG",
  "MAGA": "https://assets.coingecko.com/coins/images/27455/thumb/rsz_jimerkya_400x400.png",
  "FMTA": "https://assets.coingecko.com/coins/images/12983/thumb/fundamenta.png",
  "JIZZ": "https://assets.coingecko.com/coins/images/28985/thumb/bLQvXuh_%281%29.png",
  "PENCIL": "https://assets.coingecko.com/coins/images/25511/thumb/pencilDAO-logo.png",
  "NXL": "https://assets.coingecko.com/coins/images/15178/thumb/NXL-token-logo-200.png",
  "JACK": "https://assets.coingecko.com/coins/images/10991/thumb/Jacktoken200x200.png",
  "WAGMIGAMES": "https://assets.coingecko.com/coins/images/25906/thumb/Eohd79rn_400x400.jpg",
  "HONR": "https://assets.coingecko.com/coins/images/28059/thumb/DF_Logo_200x200.png",
  "SDO": "https://assets.coingecko.com/coins/images/22427/thumb/sdo-new_osvgbz.jpg",
  "RWN": "https://assets.coingecko.com/coins/images/11812/thumb/rsz_60398173_433122580805477_3770982655982567424_n_1.png",
  "CO2": "https://assets.coingecko.com/coins/images/14542/thumb/the_collective_logo.png",
  "MTSKS": "https://assets.coingecko.com/coins/images/27145/thumb/IMG_20220818_213242_850.png",
  "JT": "https://assets.coingecko.com/coins/images/10994/thumb/Af5MFcVY_400x400.jpg",
  "PBNB": "https://assets.coingecko.com/coins/images/25765/thumb/OadXb6ll_400x400.jpg",
  "NAH": "https://assets.coingecko.com/coins/images/6221/thumb/Strayacoin.jpg",
  "DTUBE": "https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png",
  "MICRO": "https://assets.coingecko.com/coins/images/7000/thumb/micromines.png",
  "VDOGE": "https://assets.coingecko.com/coins/images/15058/thumb/doge.f7fbdf1d.png",
  "STBL": "https://assets.coingecko.com/coins/images/26996/thumb/iShot_2022-08-24_11.02.28.png",
  "ICEBRK": "https://assets.coingecko.com/coins/images/16142/thumb/logo_7faf7883d62a6da137abdfa0da9161c9_2x.png",
  "NTB": "https://assets.coingecko.com/coins/images/13710/thumb/8284.png",
  "MVERSE": "https://assets.coingecko.com/coins/images/18403/thumb/A0782-F05-535-C-45-C8-BE4-F-FEBB4-B8-B5933.jpg",
  "CHS": "https://assets.coingecko.com/coins/images/14858/thumb/chainsquare.png",
  "PLC": "https://assets.coingecko.com/coins/images/4013/thumb/platincoin.png",
  "BYND": "https://assets.coingecko.com/coins/images/9773/thumb/beyondcoin.png",
  "TOKC": "https://assets.coingecko.com/coins/images/2522/thumb/tokyo.png",
  "CORAL": "https://assets.coingecko.com/coins/images/13038/thumb/dex.png",
  "GTFO": "https://assets.coingecko.com/coins/images/24344/thumb/logo2.png",
  "SHIBUI": "https://assets.coingecko.com/coins/images/24871/thumb/CoinGecko.png",
  "TARI": "https://assets.coingecko.com/coins/images/26202/thumb/2ipag29S_400x400.png",
  "SSS": "https://assets.coingecko.com/coins/images/21667/thumb/FLjJKQd0_400x400.jpg",
  "KTV": "https://assets.coingecko.com/coins/images/10974/thumb/logo_%2865%29.png",
  "MIRAI": "https://assets.coingecko.com/coins/images/19738/thumb/DJbVoCpq_400x400.jpg",
  "BEER": "https://assets.coingecko.com/coins/images/10682/thumb/-IRzLSxO_400x400.jpg",
  "ITAM": "https://assets.coingecko.com/coins/images/11063/thumb/ITAMGames.png",
  "HRLD": "https://assets.coingecko.com/coins/images/16345/thumb/hclogo-7.png",
  "ELAMA": "https://assets.coingecko.com/coins/images/5650/thumb/Elamachain.png",
  "BNOX": "https://assets.coingecko.com/coins/images/11066/thumb/bnox-color-h256.png",
  "HPT": "https://assets.coingecko.com/coins/images/6628/thumb/Huobi_pool_token.png",
  "BYP": "https://assets.coingecko.com/coins/images/27076/thumb/rsz_byp_logo%281%29.png",
  "DB": "https://assets.coingecko.com/coins/images/14195/thumb/DARK-circle_token_200px.png",
  "JOKE": "https://assets.coingecko.com/coins/images/16719/thumb/newjoketoken.png",
  "AKM": "https://assets.coingecko.com/coins/images/11705/thumb/akm.PNG",
  "DMOON": "https://assets.coingecko.com/coins/images/17177/thumb/dmoon.PNG",
  "HOR": "https://assets.coingecko.com/coins/images/22147/thumb/logo200.png",
  "DDR": "https://assets.coingecko.com/coins/images/8666/thumb/X02nUjMz_400x400.jpg",
  "EURU": "https://assets.coingecko.com/coins/images/12353/thumb/euru.png",
  "FIREROCKET": "https://assets.coingecko.com/coins/images/20384/thumb/rocketfire.PNG",
  "SMASH": "https://assets.coingecko.com/coins/images/21286/thumb/30537055.png",
  "CNTM": "https://assets.coingecko.com/coins/images/8528/thumb/200_200_CNTM-LOGO-07.png",
  "SCORGI": "https://assets.coingecko.com/coins/images/15428/thumb/6085dd530067e37032adedc4_astronaut-corgi-nobg-medium.png",
  "GRN": "https://assets.coingecko.com/coins/images/4398/thumb/GRN-new.png",
  "TKNRM": "https://assets.coingecko.com/coins/images/25674/thumb/tokenarium.jpeg",
  "AIN": "https://assets.coingecko.com/coins/images/13211/thumb/AI_Network_Logo_200x200.png",
  "FSCC": "https://assets.coingecko.com/coins/images/6507/thumb/AnyConv.com__fscc_icon_fin.png",
  "MOB": "https://assets.coingecko.com/coins/images/13311/thumb/mobilecoin.png",
  "VUSDC": "https://assets.coingecko.com/coins/images/13906/thumb/usdc.png",
  "CIR": "https://assets.coingecko.com/coins/images/13594/thumb/logo_200.png",
  "UDAI": "https://assets.coingecko.com/coins/images/13782/thumb/uDAI.png",
  "HDV": "https://assets.coingecko.com/coins/images/24114/thumb/HDV_token.png",
  "RD": "https://assets.coingecko.com/coins/images/18398/thumb/RD200px.png",
  "CARMA": "https://assets.coingecko.com/coins/images/15955/thumb/Color_Mark_Only.png",
  "AMI": "https://assets.coingecko.com/coins/images/14469/thumb/AMMYI.png",
  "CSC": "https://assets.coingecko.com/coins/images/19054/thumb/logo-CSC.png",
  "WRT": "https://assets.coingecko.com/coins/images/26363/thumb/Token_logo_200x200.png",
  "RUPEE": "https://assets.coingecko.com/coins/images/14760/thumb/2_%284%29.png",
  "SABAKA INU": "https://assets.coingecko.com/coins/images/15748/thumb/Logo.png",
  "SOX": "https://assets.coingecko.com/coins/images/14943/thumb/LOGO-for-CMC-V1.png",
  "BGLG": "https://assets.coingecko.com/coins/images/18629/thumb/IMG-20210911-184336-699.jpg",
  "HUNGRY": "https://assets.coingecko.com/coins/images/15067/thumb/hungry.PNG",
  "USDG": "https://assets.coingecko.com/coins/images/15886/thumb/usdg-02.png",
  "SAFECOOKIE": "https://assets.coingecko.com/coins/images/15415/thumb/LJXbdphy_400x400.jpg",
  "DAWGS": "https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png",
  "SHICO": "https://assets.coingecko.com/coins/images/15608/thumb/shibacorgi-200.jpg",
  "BUD": "https://assets.coingecko.com/coins/images/25934/thumb/bdlogo.png",
  "GRIMEX": "https://assets.coingecko.com/coins/images/15902/thumb/spacegrime.PNG",
  "SAVEANIMAL": "https://assets.coingecko.com/coins/images/15907/thumb/200x200CG.png",
  "GZX": "https://assets.coingecko.com/coins/images/17705/thumb/gzx.png",
  "DINK": "https://assets.coingecko.com/coins/images/16583/thumb/dink.png",
  "LICP": "https://assets.coingecko.com/coins/images/21289/thumb/icp.PNG",
  "RGOLD": "https://assets.coingecko.com/coins/images/21324/thumb/rgold-token.png",
  "SANSHU": "https://assets.coingecko.com/coins/images/15370/thumb/m9DgRlXY.jpg",
  "PORNROCKET": "https://assets.coingecko.com/coins/images/16207/thumb/PornRocket.png",
  "KISHUBABY": "https://assets.coingecko.com/coins/images/16753/thumb/kishu_icon_coingecko.png",
  "SOLC": "https://assets.coingecko.com/coins/images/18682/thumb/Solcubator-Logo-Icon.png",
  "DASS": "https://assets.coingecko.com/coins/images/16793/thumb/200_200.png",
  "A4": "https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg",
  "BTRN": "https://assets.coingecko.com/coins/images/17134/thumb/bitroncoin-logo-200x200.png",
  "MFM": "https://assets.coingecko.com/coins/images/17283/thumb/8LwYXCz.jpeg",
  "DEB": "https://assets.coingecko.com/coins/images/20842/thumb/DEB.png",
  "UUSD": "https://assets.coingecko.com/coins/images/19172/thumb/uUSD_Youves.png",
  "FFRAX": "https://assets.coingecko.com/coins/images/28475/thumb/fFRAX-200x200.png",
  "CCAR": "https://assets.coingecko.com/coins/images/17896/thumb/CCAR.jpg",
  "$TIME": "https://assets.coingecko.com/coins/images/17624/thumb/7U1c0mS4_400x400.jpg",
  "XSHARE": "https://assets.coingecko.com/coins/images/28004/thumb/CRYTOLOGO.png",
  "TTX": "https://assets.coingecko.com/coins/images/12770/thumb/ttx.png",
  "THUNDERBNB": "https://assets.coingecko.com/coins/images/17767/thumb/200x200-thunder-bnb.png",
  "PIZA": "https://assets.coingecko.com/coins/images/17785/thumb/piza.PNG",
  "$CINU": "https://assets.coingecko.com/coins/images/20924/thumb/Screenshot-2021-11-23-at-00-45-30.png",
  "LASEREYES": "https://assets.coingecko.com/coins/images/17955/thumb/lasereyes.png",
  "SUNDAE": "https://assets.coingecko.com/coins/images/18392/thumb/Duwsx57h_400x400.jpg",
  "BTRST": "https://assets.coingecko.com/coins/images/18100/thumb/braintrust.PNG",
  "DODI": "https://assets.coingecko.com/coins/images/21035/thumb/crEjNfhF_400x400.jpg",
  "PGN": "https://assets.coingecko.com/coins/images/3856/thumb/pigeon-logo-final-draft-1024px-circle.png",
  "UTG": "https://assets.coingecko.com/coins/images/25664/thumb/communityIcon_4uu1b2y4v2581.png",
  "MEDIC": "https://assets.coingecko.com/coins/images/3519/thumb/heart-logo-for-mediccoin-version-2-1200.png",
  "MAO": "https://assets.coingecko.com/coins/images/1295/thumb/mao-zedong.png",
  "RBXS": "https://assets.coingecko.com/coins/images/22342/thumb/rbxs.jpg",
  "HVT": "https://assets.coingecko.com/coins/images/23102/thumb/1_03RIVsRDc8ZvCojuBZ-obg.png",
  "PINKPANDA": "https://assets.coingecko.com/coins/images/15946/thumb/pinkpanda.png",
  "XGOLD": "https://assets.coingecko.com/coins/images/18870/thumb/kt5-IS0r_400x400.jpg",
  "SICX": "https://assets.coingecko.com/coins/images/18950/thumb/staked_icx.png",
  "GUSDC": "https://assets.coingecko.com/coins/images/19002/thumb/preview-gallery-gUSDCgecko_%281%29.png",
  "HIGH": "https://assets.coingecko.com/coins/images/18973/thumb/logosq200200Coingecko.png",
  "SNE": "https://assets.coingecko.com/coins/images/19303/thumb/sne.png",
  "METAX": "https://assets.coingecko.com/coins/images/19266/thumb/12952.png",
  "FLOKIM": "https://assets.coingecko.com/coins/images/19449/thumb/flokim_logo.png",
  "SQUID": "https://assets.coingecko.com/coins/images/20506/thumb/squid.png",
  "SHIBU": "https://assets.coingecko.com/coins/images/25423/thumb/logocmc.png",
  "MFLOKI": "https://assets.coingecko.com/coins/images/19766/thumb/mfloki.png",
  "CWOLF": "https://assets.coingecko.com/coins/images/21785/thumb/favicolor_%282%29.png",
  "$HIPPO": "https://assets.coingecko.com/coins/images/20728/thumb/iu-n3i1b_400x400.jpg",
  "POINT": "https://assets.coingecko.com/coins/images/27320/thumb/point.png",
  "KSW": "https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png",
  "APD": "https://assets.coingecko.com/coins/images/27071/thumb/200*200.png",
  "DEVO": "https://assets.coingecko.com/coins/images/20353/thumb/Devolution-200x200.png",
  "YYE": "https://assets.coingecko.com/coins/images/20683/thumb/oNFSk4u2_400x400.jpg",
  "STELLARINU": "https://assets.coingecko.com/coins/images/20615/thumb/Screenshot-2021-11-18-at-20-09-23.png",
  "EGAME": "https://assets.coingecko.com/coins/images/20758/thumb/egame.png",
  "ECIO": "https://assets.coingecko.com/coins/images/21884/thumb/Ecio_Token_200x200.png",
  "HOD": "https://assets.coingecko.com/coins/images/16301/thumb/Monogram_2x-100.jpg",
  "LOF": "https://assets.coingecko.com/coins/images/26547/thumb/LOF-LAND-LOGO-200x200.png",
  "WND": "https://assets.coingecko.com/coins/images/19260/thumb/wnd.png",
  "MOUNT": "https://assets.coingecko.com/coins/images/21578/thumb/download_%2845%29.png",
  "DKS": "https://assets.coingecko.com/coins/images/21737/thumb/dks.jpg",
  "SOON": "https://assets.coingecko.com/coins/images/27749/thumb/soon_token_2x.png",
  "GWT": "https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg",
  "MRFLOKI": "https://assets.coingecko.com/coins/images/21926/thumb/Hf9BbqVQ_400x400.jpg",
  "CONDOMS": "https://assets.coingecko.com/coins/images/21814/thumb/DboqD2_o_400x400.jpg",
  "META CLOTH": "https://assets.coingecko.com/coins/images/22588/thumb/SB1LXyqlg4EUdoV.png",
  "BCNT": "https://assets.coingecko.com/coins/images/9661/thumb/bcnt.png",
  "VPND": "https://assets.coingecko.com/coins/images/21977/thumb/logo-rounded.png",
  "PHOTON": "https://assets.coingecko.com/coins/images/23064/thumb/photonswap.jpg",
  "ARUSD": "https://assets.coingecko.com/coins/images/27792/thumb/ArUSD_125x125.png",
  "PUSUKE": "https://assets.coingecko.com/coins/images/28674/thumb/Pusuke_Inu.jpg",
  "DIBS": "https://assets.coingecko.com/coins/images/22184/thumb/Group_764_4.png",
  "KROOK": "https://assets.coingecko.com/coins/images/22160/thumb/143821720-d9c6f5fd-96d7-424f-9b1f-b185451cbb31.png",
  "ABZ": "https://assets.coingecko.com/coins/images/22242/thumb/eagle_logo-01.png",
  "BSGG": "https://assets.coingecko.com/coins/images/22496/thumb/betswap.jpg",
  "CLNY": "https://assets.coingecko.com/coins/images/23269/thumb/Logo.png",
  "UANG": "https://assets.coingecko.com/coins/images/23674/thumb/Cgim2ou.jpeg",
  "MAXR": "https://assets.coingecko.com/coins/images/22402/thumb/17030.png",
  "RAHO": "https://assets.coingecko.com/coins/images/22604/thumb/SkxO6vEv_400x400.jpg",
  "TQUEEN": "https://assets.coingecko.com/coins/images/22531/thumb/Logo-Tiger-Queen.png",
  "OMNIA": "https://assets.coingecko.com/coins/images/25543/thumb/OMNIA.png",
  "AQR": "https://assets.coingecko.com/coins/images/22632/thumb/Xvl0W-30_400x400.jpg",
  "ICOM": "https://assets.coingecko.com/coins/images/23667/thumb/logo-icommunity.png",
  "OKLP": "https://assets.coingecko.com/coins/images/22796/thumb/262654028_2202751929890590_982664396355018092_n.png",
  "STELLA": "https://assets.coingecko.com/coins/images/22801/thumb/stellaswap.jpg",
  "BNV": "https://assets.coingecko.com/coins/images/21846/thumb/Screenshot-2021-12-21-at-11-11-46.png",
  "37C": "https://assets.coingecko.com/coins/images/26942/thumb/37%EB%A1%9C%EA%B3%A0.png",
  "TOMS": "https://assets.coingecko.com/coins/images/22864/thumb/toms_logo_200.png",
  "ESHARE": "https://assets.coingecko.com/coins/images/23013/thumb/sEEkbqt.jpeg",
  "DRAW": "https://assets.coingecko.com/coins/images/23100/thumb/logo_%284%29.png",
  "KRIDA": "https://assets.coingecko.com/coins/images/23104/thumb/bIdz6haF_400x400.jpg",
  "DGH": "https://assets.coingecko.com/coins/images/28391/thumb/Its.png",
  "NXTT": "https://assets.coingecko.com/coins/images/23264/thumb/kG1zm83.png",
  "CVXFXS": "https://assets.coingecko.com/coins/images/29001/thumb/cvxfxs.png",
  "PLAYA": "https://assets.coingecko.com/coins/images/24387/thumb/playa.PNG",
  "RPR": "https://assets.coingecko.com/coins/images/23430/thumb/KGbTNBQ8_400x400.jpg",
  "$WINU": "https://assets.coingecko.com/coins/images/23429/thumb/Walter-Bigger-Circle-Logo.jpg",
  "FTOMB": "https://assets.coingecko.com/coins/images/23590/thumb/200ftomb.png",
  "SHENGWEIHU": "https://assets.coingecko.com/coins/images/23596/thumb/shengweihu.png",
  "MEDOC": "https://assets.coingecko.com/coins/images/23739/thumb/17996.png",
  "A1A": "https://assets.coingecko.com/coins/images/24635/thumb/xBE80XFx_400x400.jpg",
  "RITE": "https://assets.coingecko.com/coins/images/24130/thumb/rite.png",
  "CHEERS": "https://assets.coingecko.com/coins/images/23906/thumb/15236.png",
  "COOHA": "https://assets.coingecko.com/coins/images/23894/thumb/fjPuciCc_400x400.jpg",
  "HEDG": "https://assets.coingecko.com/coins/images/7196/thumb/to3Vj4EZ_400x400.jpg",
  "ISLAMI": "https://assets.coingecko.com/coins/images/24749/thumb/3100-x-3100-px-e1632233028219.png",
  "GCME": "https://assets.coingecko.com/coins/images/23946/thumb/WDBxWle.png",
  "NOSNITCH": "https://assets.coingecko.com/coins/images/25338/thumb/NoSnitch_200.png",
  "META HOUSE": "https://assets.coingecko.com/coins/images/24140/thumb/YqBBH9V.png",
  "LPY": "https://assets.coingecko.com/coins/images/24146/thumb/lpy.png",
  "CINO": "https://assets.coingecko.com/coins/images/24157/thumb/cino.png",
  "AI": "https://assets.coingecko.com/coins/images/20632/thumb/200x200.png",
  "KNOT": "https://assets.coingecko.com/coins/images/24239/thumb/knot.png",
  "SMILE": "https://assets.coingecko.com/coins/images/18520/thumb/wvOvUluD_400x400.jpg",
  "COVID DOGE": "https://assets.coingecko.com/coins/images/24217/thumb/oyFQ8DCV_400x400.jpg",
  "KDAI": "https://assets.coingecko.com/coins/images/27010/thumb/download_%284%29.png",
  "RMTX": "https://assets.coingecko.com/coins/images/21240/thumb/Rematic_Circle_Logo_200x200.png",
  "SHIBKILLER": "https://assets.coingecko.com/coins/images/24618/thumb/shibkiller.png",
  "STI": "https://assets.coingecko.com/coins/images/24628/thumb/50d6850967efa02b20fb24c6c7ba518.png",
  "BBF": "https://assets.coingecko.com/coins/images/24634/thumb/BBF-Token-200x200.png",
  "PRB": "https://assets.coingecko.com/coins/images/24691/thumb/PRB_200x200.png",
  "BBL": "https://assets.coingecko.com/coins/images/22131/thumb/200x200.png",
  "BRANA": "https://assets.coingecko.com/coins/images/27150/thumb/BRANA_LOGO.png",
  "BITSU": "https://assets.coingecko.com/coins/images/25289/thumb/CMC.png",
  "GMETA": "https://assets.coingecko.com/coins/images/25000/thumb/200x200%281%29.png",
  "DXLM": "https://assets.coingecko.com/coins/images/23637/thumb/kFDBk9F.png",
  "LOST": "https://assets.coingecko.com/coins/images/24921/thumb/logo.png",
  "MOSHI": "https://assets.coingecko.com/coins/images/24940/thumb/token.png",
  "CKC": "https://assets.coingecko.com/coins/images/24978/thumb/Screenshot_from_2022-04-11_15-47-44.png",
  "MELON": "https://assets.coingecko.com/coins/images/25089/thumb/MepNVeU.png",
  "SUCH": "https://assets.coingecko.com/coins/images/25100/thumb/1CA10540-30AB-4F34-AF3A-9E8F88471FDF.png",
  "CENS": "https://assets.coingecko.com/coins/images/25075/thumb/GE43rZJ799_zVmGgjsMWTmWPLUnU71OUQEiiH7zHFvLAV4hBWQ06OL-uH2nYHPPrgktz_xt6Ix_WCxRBzJr-kx3vjqDsp8lcGV6LtSmJA8Dh0lWix_SU9eC1TM5dsDsEJ6gvknXg9LQxHjKgugxllEsIzjX6lAK8PABDxOyjBvy9sCXgnlDd__GNY2Bs__XfcEQ3mThaPSIaGTza9n6cRv3dIs.jpg",
  "CRDC": "https://assets.coingecko.com/coins/images/25135/thumb/gQiyJrGcTRjEN-6bI22FOtHOmYF8-_MMYS_U1gIlUm1wf6QxiGnZ64tIeGNNFk9z5xPPch5FMie2grAN4fXQfcjKI9L0EQDVzDEdYDfqWl8ch4ree_q4pypGPNHeSCxnynfOOLAvd9XbKguJHEJNTLAdSKnWCKu92Kho49SplFvDBplt2ruvOz1v5QB795cnjZg4ZemfgfAB_ipJU1YE4TA3uL.jpg",
  "CCY": "https://assets.coingecko.com/coins/images/25252/thumb/logo200.png",
  "KMT": "https://assets.coingecko.com/coins/images/25410/thumb/logo-200.png",
  "MMAPS": "https://assets.coingecko.com/coins/images/25328/thumb/Map_Metrics_%28200x200%29.png",
  "CHD": "https://assets.coingecko.com/coins/images/25401/thumb/logo200x200.png",
  "DOGMSK": "https://assets.coingecko.com/coins/images/26801/thumb/dogMaskedLogo.png",
  "TORR": "https://assets.coingecko.com/coins/images/28954/thumb/Screenshot_2023-02-03_at_09.36.05.png",
  "MORTALDOGE": "https://assets.coingecko.com/coins/images/26898/thumb/LOGO.png",
  "BNBDRAGON": "https://assets.coingecko.com/coins/images/25612/thumb/20166.png",
  "MD3": "https://assets.coingecko.com/coins/images/18266/thumb/0rUOqRY.png",
  "SAGA": "https://assets.coingecko.com/coins/images/1333/thumb/saga-logo-icon.png",
  "SBE": "https://assets.coingecko.com/coins/images/26901/thumb/shiba_evil_logo_200.png",
  "ERTH": "https://assets.coingecko.com/coins/images/26348/thumb/rewards4earth-foundation.png",
  "AUTH": "https://assets.coingecko.com/coins/images/26960/thumb/1.png",
  "SNCT": "https://assets.coingecko.com/coins/images/27331/thumb/02-38-02-LogoFooter_%281%29.png",
  "RODEO": "https://assets.coingecko.com/coins/images/26499/thumb/R3kePX6F_400x400.jpeg",
  "FLAG": "https://assets.coingecko.com/coins/images/20726/thumb/token_logo.ico",
  "LONIA": "https://assets.coingecko.com/coins/images/27232/thumb/Cardalonia_2b_copy.jpeg",
  "HSWAP": "https://assets.coingecko.com/coins/images/27396/thumb/Lo200x200.png",
  "XX": "https://assets.coingecko.com/coins/images/26670/thumb/skG_l3Eh_400x400.jpeg",
  "BLET": "https://assets.coingecko.com/coins/images/26676/thumb/20130.png",
  "SKCS": "https://assets.coingecko.com/coins/images/26682/thumb/sKCS_logo.png",
  "KIB": "https://assets.coingecko.com/coins/images/26929/thumb/Kibble-Token-200px-Round.png",
  "SHIBAW": "https://assets.coingecko.com/coins/images/19626/thumb/shibaw.png",
  "APCG": "https://assets.coingecko.com/coins/images/26836/thumb/apcg.png",
  "STSUKA": "https://assets.coingecko.com/coins/images/27495/thumb/tsuka200.png",
  "MINER": "https://assets.coingecko.com/coins/images/26876/thumb/200x200.png",
  "FUSDT": "https://assets.coingecko.com/coins/images/28472/thumb/fUSDT-200x200.png",
  "$ALPHA": "https://assets.coingecko.com/coins/images/28136/thumb/alpha.png",
  "BIC": "https://assets.coingecko.com/coins/images/28635/thumb/200x200_%283%29.png",
  "FTUSD": "https://assets.coingecko.com/coins/images/28474/thumb/fTUSD-200x200.png",
  "NSHIBA": "https://assets.coingecko.com/coins/images/27941/thumb/LOGO.png",
  "JPGC": "https://assets.coingecko.com/coins/images/27321/thumb/JPGC_LOGO-_Coingecko.png",
  "SPAY": "https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG",
  "GAND": "https://assets.coingecko.com/coins/images/27689/thumb/200_X_200.png",
  "HAREC": "https://assets.coingecko.com/coins/images/27379/thumb/Hare_Chain.png",
  "SBLX": "https://assets.coingecko.com/coins/images/24769/thumb/x86VE7oQ.png",
  "DRAGACE": "https://assets.coingecko.com/coins/images/27735/thumb/Safeimagekit-resized-imgpng_%286%29.png",
  "HAKI": "https://assets.coingecko.com/coins/images/7945/thumb/haki.png",
  "ROCKET": "https://assets.coingecko.com/coins/images/21108/thumb/Screenshot-2021-12-01-at-03-39-43.png",
  "HIVALHALLA": "https://assets.coingecko.com/coins/images/28911/thumb/hiVALHALLA.png",
  "MTVT": "https://assets.coingecko.com/coins/images/27472/thumb/s4Ce6y1r_400x400.jpeg",
  "NIFLOKI": "https://assets.coingecko.com/coins/images/28038/thumb/nifloki.png",
  "UCOIL": "https://assets.coingecko.com/coins/images/27633/thumb/logo.png",
  "SPKU": "https://assets.coingecko.com/coins/images/27702/thumb/Spooky-Uni200x200.png",
  "SHARO": "https://assets.coingecko.com/coins/images/27803/thumb/logosharo.png",
  "VIPS": "https://assets.coingecko.com/coins/images/27834/thumb/200-X-200.png",
  "HIV": "https://assets.coingecko.com/coins/images/27641/thumb/t%C3%A9l%C3%A9charger_%281%29.png",
  "GWD": "https://assets.coingecko.com/coins/images/27771/thumb/200x200logo.png",
  "SNS": "https://assets.coingecko.com/coins/images/23289/thumb/sns.png",
  "AD": "https://assets.coingecko.com/coins/images/27475/thumb/PNG-image.png",
  "SGS": "https://assets.coingecko.com/coins/images/27986/thumb/home_sgs.png",
  "SEAT": "https://assets.coingecko.com/coins/images/27665/thumb/seat.png",
  "DST": "https://assets.coingecko.com/coins/images/27497/thumb/SVG_16576000478201792T.jpg",
  "DCHF": "https://assets.coingecko.com/coins/images/28108/thumb/22249.png",
  "ORACLE": "https://assets.coingecko.com/coins/images/28123/thumb/OS_Logo_200.png",
  "HISQUIGGLE": "https://assets.coingecko.com/coins/images/28127/thumb/hisquiggle.png",
  "MARBLEX7": "https://assets.coingecko.com/coins/images/28322/thumb/Mp_logo_2.png",
  "EXN": "https://assets.coingecko.com/coins/images/25845/thumb/20323.png",
  "WORM": "https://assets.coingecko.com/coins/images/28497/thumb/Worm_Icon_01_1340.png",
  "OPENAI ERC": "https://assets.coingecko.com/coins/images/28412/thumb/OpenAI.jpeg",
  "LCRO": "https://assets.coingecko.com/coins/images/28546/thumb/LCRO_light_%281%29.png",
  "BABYRABBIT": "https://assets.coingecko.com/coins/images/28537/thumb/Babyrabbit.jpeg",
  "CATAI": "https://assets.coingecko.com/coins/images/28945/thumb/Catgirl_AI.png",
  "DTG": "https://assets.coingecko.com/coins/images/28737/thumb/IMG-20230115-WA0013.jpg",
  "HSE": "https://assets.coingecko.com/coins/images/28837/thumb/Hest-stake.png",
  "AXLE": "https://assets.coingecko.com/coins/images/28766/thumb/Artboard_6_3x.png",
  "YVUNI": "https://assets.coingecko.com/coins/images/28781/thumb/yvUNI-128-0xFBEB78a723b8087fD2ea7Ef1afEc93d35E8Bed42.png",
  "NEURONI": "https://assets.coingecko.com/coins/images/28887/thumb/200X200.png",
  "SUPERSTAKE": "https://assets.coingecko.com/coins/images/29008/thumb/logo200x200.png",
  "ARCHAI": "https://assets.coingecko.com/coins/images/28939/thumb/pp.JPG",
  "MDAI": "https://assets.coingecko.com/coins/images/29021/thumb/MemedaoLogo.png",
  "WUSD": "https://assets.coingecko.com/coins/images/28854/thumb/wusd.png",
  "KITTY DINGER": "https://assets.coingecko.com/coins/images/19649/thumb/kitty_dinger.png",
  "DKM": "https://assets.coingecko.com/coins/images/24442/thumb/SAq1GaQc_400x400.jpg",
  "AQU": "https://assets.coingecko.com/coins/images/15933/thumb/mHNYideX_400x400.png",
  "TRMT": "https://assets.coingecko.com/coins/images/25127/thumb/tierrameta_logo-8.png",
  "BSHIB": "https://assets.coingecko.com/coins/images/19786/thumb/nshb.png",
  "HMC": "https://assets.coingecko.com/coins/images/20430/thumb/hmc-300x300.png",
  "CHIRO": "https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png",
  "PHBD": "https://assets.coingecko.com/coins/images/25142/thumb/image.png",
  "$SPLUSV2": "https://assets.coingecko.com/coins/images/21303/thumb/Logo-Safeplus-200x200.png",
  "OPP": "https://assets.coingecko.com/coins/images/27417/thumb/1_YTzF4KXNbEMRykWKGgKpwg.jpeg",
  "DGC": "https://assets.coingecko.com/coins/images/27904/thumb/DGC_200x200.png",
  "NMEEBITS": "https://assets.coingecko.com/coins/images/25196/thumb/unnamed.png",
  "LY": "https://assets.coingecko.com/coins/images/23787/thumb/jQlMieu.png",
  "FANS": "https://assets.coingecko.com/coins/images/14715/thumb/fans_200X200.png",
  "GCT": "https://assets.coingecko.com/coins/images/26260/thumb/token_gct02.png",
  "CDR": "https://assets.coingecko.com/coins/images/27806/thumb/C66960A5-44FA-4D77-922A-DF8E3AB3BC87.jpeg",
  "TSA": "https://assets.coingecko.com/coins/images/17826/thumb/tsa.PNG",
  "RLM": "https://assets.coingecko.com/coins/images/24844/thumb/RLM200x200.png",
  "MRHB": "https://assets.coingecko.com/coins/images/18613/thumb/MRHB_DeFi-02.png",
  "SVC": "https://assets.coingecko.com/coins/images/8241/thumb/silvercashs.png",
  "DDN": "https://assets.coingecko.com/coins/images/19123/thumb/dxwS1PQ0_400x400.png",
  "CDZ": "https://assets.coingecko.com/coins/images/18560/thumb/cdz.PNG",
  "ROFI": "https://assets.coingecko.com/coins/images/21296/thumb/herofi_logo.jpg",
  "SNB": "https://assets.coingecko.com/coins/images/16944/thumb/SNB-Logo-400x400.png",
  "BALTO": "https://assets.coingecko.com/coins/images/28928/thumb/Balto-Token-logo_sq.png",
  "HTDF": "https://assets.coingecko.com/coins/images/10428/thumb/ljNSTB1aq9SOWE49wMCrB4lWtLJbMoZQqlvcWXQwlkGDE5cwp4BME45KtQ6g9bVQN8G5Ix_9Aq0rpu8eu3kErjMLAQViiuLdfLPhqgxmSdcsLcMZHlUR1Yi5kkUnIGnf6mUf10HLMWvuznxpD8dEAomxyqBAgPf0eq9uvvdrmysbvKoJ8A_TBFle-M6UJx8KtH4NguXTR1guR1nuKigdrUJ.jpg",
  "WPOM": "https://assets.coingecko.com/coins/images/28639/thumb/wpom-logo-cg.png",
  "VRSE": "https://assets.coingecko.com/coins/images/26188/thumb/VRSE_Token_%283%29.png",
  "METAF": "https://assets.coingecko.com/coins/images/28585/thumb/logo_200.png",
  "CUC": "https://assets.coingecko.com/coins/images/26862/thumb/CUC_Icon_CoinGecko.png",
  "TİGR": "missing_thumb.png",
  "KUSD": "https://assets.coingecko.com/coins/images/19464/thumb/kolibri.png",
  "NEFTY": "https://assets.coingecko.com/coins/images/28697/thumb/NB_logo_%283%29_%281%29.png",
  "MIND+": "https://assets.coingecko.com/coins/images/24546/thumb/iShot_2022-07-14_12.06.21.png",
  "YVSUSD": "https://assets.coingecko.com/coins/images/28794/thumb/yvsUSD-128-0xa5cA62D95D24A4a350983D5B8ac4EB8638887396.png",
  "ACK": "https://assets.coingecko.com/coins/images/26923/thumb/rsz_3uzi4ohb_400x400_%281%29.png",
  "CBP": "https://assets.coingecko.com/coins/images/12893/thumb/logo_%2818%29.png",
  "GORILLA INU": "https://assets.coingecko.com/coins/images/19426/thumb/gorilla_logo.png",
  "CD": "https://assets.coingecko.com/coins/images/28293/thumb/20221123_013230.jpg",
  "1MCT": "https://assets.coingecko.com/coins/images/24144/thumb/1mct.png",
  "STCELO": "https://assets.coingecko.com/coins/images/27088/thumb/For_larger_icons_%28192x192__512x512__etc%29.png",
  "TOM": "https://assets.coingecko.com/coins/images/13173/thumb/TOM_logo.png",
  "RIP": "https://assets.coingecko.com/coins/images/18274/thumb/fantom_doge_logo.jpg",
  "SLEEPY-SHIB": "https://assets.coingecko.com/coins/images/20321/thumb/OYfY70z.png",
  "AMX": "https://assets.coingecko.com/coins/images/29025/thumb/logo-200.png",
  "PINA": "https://assets.coingecko.com/coins/images/28765/thumb/Frame_18457.png",
  "DKNIGHT": "https://assets.coingecko.com/coins/images/24133/thumb/darkknight.png",
  "FRED": "https://assets.coingecko.com/coins/images/7019/thumb/fred.png",
  "SHIKO": "https://assets.coingecko.com/coins/images/16442/thumb/shiko.PNG",
  "RTT": "https://assets.coingecko.com/coins/images/23525/thumb/W5sB5UGT_400x400.jpg",
  "RIOT": "https://assets.coingecko.com/coins/images/19238/thumb/jyxvIbmJ_400x400.png",
  "PRIME": "missing_thumb.png",
  "POX": "https://assets.coingecko.com/coins/images/12222/thumb/pollux-coin.png",
  "1AMD": "https://assets.coingecko.com/coins/images/26713/thumb/200x200_%281%29.png",
  "EATER": "https://assets.coingecko.com/coins/images/28195/thumb/cute_%281%29.png",
  "FC": "https://assets.coingecko.com/coins/images/6701/thumb/futurescoin.jpg",
  "KING": "https://assets.coingecko.com/coins/images/18728/thumb/cryptoblades-kingdoms.jpeg",
  "CSHIP": "https://assets.coingecko.com/coins/images/21880/thumb/15269.png",
  "KI": "https://assets.coingecko.com/coins/images/26135/thumb/genopets_ki.png",
  "WKCS": "https://assets.coingecko.com/coins/images/16924/thumb/wrapped-kcs.jpeg",
  "RKF": "https://assets.coingecko.com/coins/images/23119/thumb/3ejS6SG.png",
  "MAROSCA": "https://assets.coingecko.com/coins/images/27881/thumb/Cr3sFSxf_400x400.jpg",
  "METAWEB3PA": "https://assets.coingecko.com/coins/images/27028/thumb/logo2.png",
  "NATAS": "https://assets.coingecko.com/coins/images/28144/thumb/natas_new_2.png",
  "WWAN": "https://assets.coingecko.com/coins/images/23604/thumb/download.png",
  "SCOTTY": "https://assets.coingecko.com/coins/images/21755/thumb/sign-logo.png",
  "TSLA": "https://assets.coingecko.com/coins/images/13045/thumb/Tessla-Icon.png",
  "KINU": "https://assets.coingecko.com/coins/images/25374/thumb/kinu.png",
  "CGAZ": "https://assets.coingecko.com/coins/images/24005/thumb/gtEbDVF.jpeg",
  "PITI": "https://assets.coingecko.com/coins/images/27068/thumb/photo_2022-08-26_00-59-26.jpg",
  "LOCUS": "https://assets.coingecko.com/coins/images/6903/thumb/32169759_2050428658545017_7287666225423843328_n.jpg",
  "RKV": "https://assets.coingecko.com/coins/images/28425/thumb/LOGO.png",
  "LBP": "https://assets.coingecko.com/coins/images/24181/thumb/S6z_-9GB_400x400.jpg",
  "BLOSM": "https://assets.coingecko.com/coins/images/15513/thumb/coingecko_%282%29.png",
  "NEKI": "https://assets.coingecko.com/coins/images/21321/thumb/logo.png",
  "FAWA": "https://assets.coingecko.com/coins/images/25897/thumb/logo-token.png",
  "ETNX": "https://assets.coingecko.com/coins/images/4535/thumb/electronero-logo.png",
  "DORAEMONINU": "https://assets.coingecko.com/coins/images/19141/thumb/DI.jpg",
  "MOTG": "https://assets.coingecko.com/coins/images/26496/thumb/iJoylNuO_400x400.jpeg",
  "BULLISH": "https://assets.coingecko.com/coins/images/19181/thumb/bullihs.png",
  "WPKT": "https://assets.coingecko.com/coins/images/19003/thumb/200x200wpkt-04.png",
  "GFUSDT": "https://assets.coingecko.com/coins/images/19000/thumb/preview-gallery-gUSDTgecko_%281%29.png",
  "AIDOGEMINI": "https://assets.coingecko.com/coins/images/28995/thumb/Logo_200px_x_200px.jpg",
  "TZKI": "https://assets.coingecko.com/coins/images/19437/thumb/tzki.png",
  "WOAS": "https://assets.coingecko.com/coins/images/28493/thumb/oasys.jpeg",
  "BDCC": "https://assets.coingecko.com/coins/images/11857/thumb/bitica_400x400.png",
  "TRR": "https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png",
  "HBEE": "https://assets.coingecko.com/coins/images/24081/thumb/HBEE_200.png",
  "BATH": "https://assets.coingecko.com/coins/images/19231/thumb/5n04AmM4_400x400.jpg",
  "PLENTY": "https://assets.coingecko.com/coins/images/18168/thumb/Plenty-Token-Icon-200.png",
  "KITTENS": "https://assets.coingecko.com/coins/images/23947/thumb/IIOISE0-Imgur.png",
  "ARCHIE": "https://assets.coingecko.com/coins/images/28234/thumb/photo1667927837.jpeg",
  "IPISTR": "https://assets.coingecko.com/coins/images/27002/thumb/box-1.png",
  "MAC": "https://assets.coingecko.com/coins/images/679/thumb/machinecoin.png",
  "MMI": "https://assets.coingecko.com/coins/images/27177/thumb/MidasMiner.png",
  "POWV": "https://assets.coingecko.com/coins/images/28090/thumb/PWV-Icons.png",
  "KUOR": "https://assets.coingecko.com/coins/images/28079/thumb/rk6bhsCo_400x400.jpeg",
  "GAMINGSHIBA": "https://assets.coingecko.com/coins/images/21165/thumb/969696.png",
  "UZUMAKI": "https://assets.coingecko.com/coins/images/18585/thumb/XjTLLU9c_400x400.jpg",
  "CHLT": "https://assets.coingecko.com/coins/images/19192/thumb/chellit_ticker-01.png",
  "SUI": "https://assets.coingecko.com/coins/images/24408/thumb/256x256.png",
  "DLAUNCH": "https://assets.coingecko.com/coins/images/19971/thumb/dlaunch.png",
  "LBTC": "https://assets.coingecko.com/coins/images/2233/thumb/lightning-bitcoin.png",
  "FREED": "https://assets.coingecko.com/coins/images/460/thumb/freed.png",
  "ZPRO": "https://assets.coingecko.com/coins/images/25633/thumb/cmc.png",
  "BPTC": "https://assets.coingecko.com/coins/images/27425/thumb/QxZtgPbY_400x400.jpeg",
  "CRAB": "https://assets.coingecko.com/coins/images/29022/thumb/cgcrab.png",
  "PUD": "https://assets.coingecko.com/coins/images/23528/thumb/puddingswap.jpg",
  "WATER": "https://assets.coingecko.com/coins/images/27179/thumb/rsz_owp0qhx8_400x400.png",
  "DORD": "https://assets.coingecko.com/coins/images/27432/thumb/20220923_035557.png",
  "YLGY": "https://assets.coingecko.com/coins/images/27436/thumb/photo_2022-09-19_03-51-46.jpg",
  "HIMOONBIRDS": "https://assets.coingecko.com/coins/images/28663/thumb/himoonbirds.png",
  "SST": "https://assets.coingecko.com/coins/images/11908/thumb/sst.png",
  "TINY": "https://assets.coingecko.com/coins/images/22264/thumb/Kq4gAMJi_400x400.png",
  "ALGOP": "https://assets.coingecko.com/coins/images/15273/thumb/algo.PNG",
  "$BANANA": "https://assets.coingecko.com/coins/images/28736/thumb/Logo---Circle---Light.png",
  "WINE": "https://assets.coingecko.com/coins/images/26885/thumb/Cvbd-cPK_400x400.jpeg",
  "LUM": "https://assets.coingecko.com/coins/images/22009/thumb/lum.png",
  "SAFEBTC": "https://assets.coingecko.com/coins/images/14635/thumb/Logo_Black_200x200.png",
  "CONK": "https://assets.coingecko.com/coins/images/29080/thumb/conk.png",
  "HDN": "https://assets.coingecko.com/coins/images/10058/thumb/NRPePphH_400x400.jpg",
  "ANIFI": "https://assets.coingecko.com/coins/images/27057/thumb/token-200-200.png",
  "YVWETH": "https://assets.coingecko.com/coins/images/28793/thumb/yvWETH-128-0xa258C4606Ca8206D8aA700cE2143D7db854D168c.png",
  "FLOKICHAIN": "https://assets.coingecko.com/coins/images/27180/thumb/photo_2022-09-05_07-15-54.jpg",
  "KYSR": "https://assets.coingecko.com/coins/images/21930/thumb/YQKw1S0__400x400.jpg",
  "SBRT": "https://assets.coingecko.com/coins/images/17140/thumb/4NqipCv__400x400_%281%29.jpg",
  "GBPU": "https://assets.coingecko.com/coins/images/12357/thumb/gbpu.png",
  "JPEGS": "https://assets.coingecko.com/coins/images/22498/thumb/sXd3uwVJ_400x400.png",
  "SV": "https://assets.coingecko.com/coins/images/27355/thumb/200_050photo_2022-08-09_19-28-56.jpg",
  "LLTH": "https://assets.coingecko.com/coins/images/23499/thumb/-c-tn85C_400x400.jpeg",
  "CPTINU": "https://assets.coingecko.com/coins/images/21600/thumb/200x200-transparent-logo.png",
  "DILI": "https://assets.coingecko.com/coins/images/9485/thumb/BYf_d6kekNWOY34VLGPvf7RcVj8SeRi7P65Nk0W7ji1jir_qSLfssffnNW0ZzQSWzhhsNRkMCL7qcX4CCaMTbgKb0D7b5yNeStKVMm2deZd9cONMuItgoKUXxIYkHElfk1PW2uiNZbg6a2K0OHtZTvkt1xo8YVKuthskXaryOkM6Ax-MXQlHOfbjwCcnQQjHxAucufrCZJfDgrY.jpg",
  "LOCAL": "https://assets.coingecko.com/coins/images/28726/thumb/local_money.jpeg",
  "GAMINGDOGE": "https://assets.coingecko.com/coins/images/19783/thumb/gamingdoge.png",
  "SLIZ": "https://assets.coingecko.com/coins/images/28968/thumb/sliz-logo.png",
  "LILLI": "https://assets.coingecko.com/coins/images/26061/thumb/lilli_200x200.png",
  "CPRX": "https://assets.coingecko.com/coins/images/20768/thumb/8iQSd5YY_400x400.jpg",
  "PIP": "https://assets.coingecko.com/coins/images/27476/thumb/LOGO_%281%29.png",
  "DBNB": "https://assets.coingecko.com/coins/images/26544/thumb/Screenshot_2022-07-22_at_18.16.11.png",
  "SEND": "https://assets.coingecko.com/coins/images/1308/thumb/social-send.png",
  "VD": "https://assets.coingecko.com/coins/images/8498/thumb/1*4Sjb0dbd3g-o3PJ2HgghLQ.png",
  "MBR": "https://assets.coingecko.com/coins/images/21590/thumb/bPtJEfOW_400x400.jpg",
  "DOGEDIGGER": "missing_thumb.png",
  "FOXT": "https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png",
  "NEBULA": "https://assets.coingecko.com/coins/images/20908/thumb/rLvxXxbk_400x400.jpg",
  "AKREP": "https://assets.coingecko.com/coins/images/22041/thumb/16531_%281%29.png",
  "DP": "https://assets.coingecko.com/coins/images/1138/thumb/digitalprice.png",
  "MIE": "https://assets.coingecko.com/coins/images/18565/thumb/mi_netowrk.PNG",
  "SUCCESS": "https://assets.coingecko.com/coins/images/18592/thumb/UNddrrkK.png",
  "YVAAVE": "https://assets.coingecko.com/coins/images/28784/thumb/yvAAVE-128-0xd9788f3931Ede4D5018184E198699dC6d66C1915.png",
  "LSILVER": "https://assets.coingecko.com/coins/images/14939/thumb/LSILVER-Logo-200.png",
  "EVRF": "https://assets.coingecko.com/coins/images/22903/thumb/evrf.png",
  "BOOTY": "https://assets.coingecko.com/coins/images/16130/thumb/boty.PNG",
  "MP": "https://assets.coingecko.com/coins/images/23671/thumb/G7TivHP.png",
  "SFO": "https://assets.coingecko.com/coins/images/18943/thumb/7qv-tC6x_400x400.jpg",
  "ACY": "https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg",
  "YVLINK": "https://assets.coingecko.com/coins/images/28791/thumb/yvLINK-128-0x671a912C10bba0CFA74Cfc2d6Fba9BA1ed9530B2.png",
  "AXSUSHI": "https://assets.coingecko.com/coins/images/17256/thumb/aXSUSHI_2x.png",
  "LEVE": "https://assets.coingecko.com/coins/images/27929/thumb/leve.png",
  "PSHARE": "https://assets.coingecko.com/coins/images/22077/thumb/crypto-tomb-share-e8b971e5.png",
  "DFE": "https://assets.coingecko.com/coins/images/13682/thumb/200x200DFE.png",
  "INCO": "https://assets.coingecko.com/coins/images/27063/thumb/_INCO_200x200.png",
  "RXS": "https://assets.coingecko.com/coins/images/19831/thumb/rune.PNG",
  "SSD": "https://assets.coingecko.com/coins/images/24302/thumb/logo-32-2.png",
  "AAA": "https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png",
  "TRXC": "https://assets.coingecko.com/coins/images/4626/thumb/trxc.png",
  "CPLT": "https://assets.coingecko.com/coins/images/26707/thumb/Logo-CPLT-200x.png",
  "MIVRS": "https://assets.coingecko.com/coins/images/27042/thumb/MINIONVERSE_LOGO_200x200.png",
  "DCRN": "https://assets.coingecko.com/coins/images/26473/thumb/CS27g0U0_400x400.jpeg",
  "GEMG": "https://assets.coingecko.com/coins/images/18614/thumb/gemg.PNG",
  "BKS": "https://assets.coingecko.com/coins/images/17775/thumb/TKH-0Ju5_400x400.jpg",
  "IFIT": "https://assets.coingecko.com/coins/images/27616/thumb/IFIT_200x200.png",
  "PRED": "https://assets.coingecko.com/coins/images/21684/thumb/pc.png",
  "PMC": "https://assets.coingecko.com/coins/images/9229/thumb/pmc.PNG",
  "YVCOMP": "https://assets.coingecko.com/coins/images/28786/thumb/yvCOMP-128-0x4A3FE75762017DB0eD73a71C9A06db7768DB5e66.png",
  "MVEDA": "https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png",
  "FCON": "https://assets.coingecko.com/coins/images/21760/thumb/transparent-200x200.png",
  "BITCCA": "https://assets.coingecko.com/coins/images/25998/thumb/20574.png",
  "DHX": "https://assets.coingecko.com/coins/images/15755/thumb/Datahighway-Logo-big.png",
  "WALV": "https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png",
  "NST": "https://assets.coingecko.com/coins/images/22248/thumb/unnamed.jpg",
  "CMD": "https://assets.coingecko.com/coins/images/17879/thumb/uawrbab.png",
  "WCRO": "https://assets.coingecko.com/coins/images/20401/thumb/cro.jpg",
  "VIDA": "https://assets.coingecko.com/coins/images/14346/thumb/vidia.png",
  "NASTR": "https://assets.coingecko.com/coins/images/28481/thumb/Rectangle_152.png",
  "CBANK": "https://assets.coingecko.com/coins/images/13473/thumb/HHeUVDBF5clKgE4vW_dyCtcTZqWDRw_vDe1MA3lIJWI4wEZW2BwF5dHFbm_ZpJNTWf85sZxCBVl2R7u29kGg37bE8XkGh3CneW6SkVwbnQEe0r2WR6yy6THxMvzIMiicWza82-NyjMitEo84_2E_C5LCiBcdTPFrjNQiMs1DTSJuaRTNB81KRsdcXNW0p2j7PH8O7Xk3QrWVjPe.jpg",
  "LOAN": "https://assets.coingecko.com/coins/images/22859/thumb/Loan-Logo.png",
  "CVTX": "https://assets.coingecko.com/coins/images/28922/thumb/cvtx.png",
  "SCOOBI": "https://assets.coingecko.com/coins/images/16101/thumb/scoobi_doge.jpg",
  "TALI": "https://assets.coingecko.com/coins/images/21849/thumb/200_%2815%29.png",
  "SWPT": "https://assets.coingecko.com/coins/images/20420/thumb/swaptracker-logo-200x200.png",
  "BABIL": "https://assets.coingecko.com/coins/images/27252/thumb/PKUhX3jRL.png",
  "MHT": "https://assets.coingecko.com/coins/images/21845/thumb/EP4fc4J-_400x400.jpg",
  "BPTL": "https://assets.coingecko.com/coins/images/28883/thumb/photo_2023-01-18_03-27-04.png",
  "GVILLE": "https://assets.coingecko.com/coins/images/28408/thumb/gville-logo-trans-200x200.png",
  "WBTT": "https://assets.coingecko.com/coins/images/24933/thumb/TKfjV9RNKJJCqPvBtK8L7Knykh7DNWvnYt.png",
  "MHUNT": "https://assets.coingecko.com/coins/images/24985/thumb/200x200.png",
  "WNCG": "https://assets.coingecko.com/coins/images/17747/thumb/WNCG.png",
  "AES": "https://assets.coingecko.com/coins/images/27231/thumb/AES_TICKER_200.png",
  "AEGIS": "https://assets.coingecko.com/coins/images/26294/thumb/iShot_2022-07-07_10.44.16.png",
  "BUNNYZILLA": "https://assets.coingecko.com/coins/images/20139/thumb/bz.png",
  "CSW": "https://assets.coingecko.com/coins/images/26982/thumb/Safeimagekit-CSW_logopng.png",
  "BLUSGD": "https://assets.coingecko.com/coins/images/28417/thumb/SGD.png",
  "SHINO": "https://assets.coingecko.com/coins/images/28890/thumb/output-onlinepngtools_%281%29.png",
  "QUILL": "https://assets.coingecko.com/coins/images/28513/thumb/photo_2022-10-14_21-16-54.jpg",
  "CAKITA": "https://assets.coingecko.com/coins/images/15563/thumb/cakita.jpg",
  "HOGT": "https://assets.coingecko.com/coins/images/16237/thumb/0xFC33785f44c1069010b5De466eE7657C27aB8A0E.png",
  "WKD": "https://assets.coingecko.com/coins/images/20121/thumb/wakanda.PNG",
  "$EFB": "https://assets.coingecko.com/coins/images/24268/thumb/efb.png",
  "STNEAR": "https://assets.coingecko.com/coins/images/24250/thumb/st-NEAR-token-white-dark-purple-circle.png",
  "KCCM": "https://assets.coingecko.com/coins/images/17748/thumb/kcc.PNG",
  "YVUSDC": "https://assets.coingecko.com/coins/images/28779/thumb/yvUSDC-128-0xe2F6b9773BF3A015E2aA70741Bde1498bdB9425b.png",
  "SHF": "https://assets.coingecko.com/coins/images/27733/thumb/shf.png",
  "XCC": "https://assets.coingecko.com/coins/images/20898/thumb/dHnrNJRV_400x400.jpg",
  "YVUSDT": "https://assets.coingecko.com/coins/images/28780/thumb/yvUSDT-128-0x7Da96a3891Add058AdA2E826306D812C638D87a7.png",
  "IMMO": "https://assets.coingecko.com/coins/images/22189/thumb/immortal_logo.png",
  "FLOKISANTA": "https://assets.coingecko.com/coins/images/28535/thumb/XHR83fw.png",
  "$NA": "https://assets.coingecko.com/coins/images/27039/thumb/NA.png",
  "GENES": "https://assets.coingecko.com/coins/images/8857/thumb/logo256_%281%29.png",
  "MBT": "https://assets.coingecko.com/coins/images/24002/thumb/logo_-_2022-02-28T113304.386.png",
  "CROOM": "https://assets.coingecko.com/coins/images/28511/thumb/609AAA5C-5E4B-49B8-86D6-61B5C46BE5A7.jpeg",
  "SAFECLASSIC": "https://assets.coingecko.com/coins/images/27125/thumb/logos.png",
  "KLB": "https://assets.coingecko.com/coins/images/18489/thumb/klb_round_200.png",
  "ODEX": "https://assets.coingecko.com/coins/images/26464/thumb/photo_2022-07-10_23-45-25.jpg",
  "THECITADEL": "https://assets.coingecko.com/coins/images/20445/thumb/B307-A646-EBD2-456-C-B29-D-1-B00-A470-F8-CE.jpg",
  "ACTN": "https://assets.coingecko.com/coins/images/5079/thumb/act.jpg",
  "MMT": "https://assets.coingecko.com/coins/images/5697/thumb/mmt600.png",
  "BTCMZ": "https://assets.coingecko.com/coins/images/9508/thumb/logo_%285%29.png",
  "SHIBM": "https://assets.coingecko.com/coins/images/23969/thumb/shibm.png",
  "F9": "https://assets.coingecko.com/coins/images/16858/thumb/logo-f9.png",
  "PTCL": "https://assets.coingecko.com/coins/images/28036/thumb/200x200.png",
  "GENSHIN": "https://assets.coingecko.com/coins/images/20525/thumb/2yb2a0kp_400x400.jpg",
  "XLPFI": "https://assets.coingecko.com/coins/images/29042/thumb/xLPFi-min.png",
  "GBT": "https://assets.coingecko.com/coins/images/28927/thumb/photo_2023-02-02_02-44-01.jpg",
  "BKT": "https://assets.coingecko.com/coins/images/16650/thumb/bkt.png",
  "SWEEP": "https://assets.coingecko.com/coins/images/27848/thumb/sweep200.png",
  "JPD": "https://assets.coingecko.com/coins/images/28001/thumb/62caad33f3c5f30014065a1d-image-p-1660157227220-File-name.png",
  "$KEI": "https://assets.coingecko.com/coins/images/15797/thumb/realicon.png",
  "$SSHIBA": "https://assets.coingecko.com/coins/images/15869/thumb/SuperShibaLogoSmall.png",
  "LITHO": "https://assets.coingecko.com/coins/images/21128/thumb/6gizpBLn.png",
  "ETW": "https://assets.coingecko.com/coins/images/28724/thumb/IMG_20230111_170205_646.jpg",
  "ASV": "https://assets.coingecko.com/coins/images/22241/thumb/200200.png",
  "GRANX": "https://assets.coingecko.com/coins/images/18658/thumb/granx-002.png",
  "KGT": "https://assets.coingecko.com/coins/images/22937/thumb/xnSx6H.f960d9.KGTcoin.png",
  "CLYC": "https://assets.coingecko.com/coins/images/28808/thumb/light.png",
  "WOKT": "https://assets.coingecko.com/coins/images/17575/thumb/WeChat_Image_20220118095654.png",
  "CXN": "https://assets.coingecko.com/coins/images/12622/thumb/Webp.net-resizeimage.png",
  "XMETA": "https://assets.coingecko.com/coins/images/23332/thumb/xmeta.png",
  "WADA": "https://assets.coingecko.com/coins/images/24791/thumb/ezgif-2-07900fc0ac.jpg",
  "RU": "https://assets.coingecko.com/coins/images/20539/thumb/N7d6LRy.png",
  "HG": "https://assets.coingecko.com/coins/images/8817/thumb/60762471_431874227611586_5376521021266329600_n.png",
  "WSHEC": "https://assets.coingecko.com/coins/images/21550/thumb/_WSHEC_new_Logo_200x200.png",
  "ERT": "https://assets.coingecko.com/coins/images/1684/thumb/esports.jpg",
  "PUZZLE": "https://assets.coingecko.com/coins/images/26560/thumb/PUZZLE_Token_Image.png",
  "FTRB": "https://assets.coingecko.com/coins/images/23939/thumb/ym1Hf4x2_400x400.jpg",
  "WJXN": "https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg",
  "MHAM": "https://assets.coingecko.com/coins/images/26632/thumb/metahamster200x200.png",
  "TBL": "https://assets.coingecko.com/coins/images/22917/thumb/15695.png",
  "FPC": "https://assets.coingecko.com/coins/images/24774/thumb/logo.png",
  "FED": "https://assets.coingecko.com/coins/images/8856/thumb/FedoraTransGold512.png",
  "SHINSHU": "https://assets.coingecko.com/coins/images/28093/thumb/shinshu200.png",
  "BNBD": "https://assets.coingecko.com/coins/images/15354/thumb/J6gLEc0.png",
  "LBLOCK": "https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png",
  "KOI": "https://assets.coingecko.com/coins/images/26730/thumb/koi.png",
  "SKRY": "https://assets.coingecko.com/coins/images/22042/thumb/16529.png",
  "HYPER": "https://assets.coingecko.com/coins/images/27847/thumb/IMG_20221015_231037_796.png",
  "SONIC": "https://assets.coingecko.com/coins/images/28489/thumb/155EE8AF-26AA-4CEC-9EC5-18532E779474.jpeg",
  "XXP": "https://assets.coingecko.com/coins/images/15375/thumb/XXP_logo.png",
  "WLEO": "https://assets.coingecko.com/coins/images/12626/thumb/4XfO3w3.png",
  "SMARTSHIB": "https://assets.coingecko.com/coins/images/22665/thumb/smlogo.png",
  "FATOSHI": "https://assets.coingecko.com/coins/images/22523/thumb/logo_200x200.png",
  "TBAKE": "https://assets.coingecko.com/coins/images/15184/thumb/tbakelogo-1.png",
  "BORG": "https://assets.coingecko.com/coins/images/23020/thumb/lgo-head-export.png",
  "XLV": "https://assets.coingecko.com/coins/images/28844/thumb/200.png",
  "BHO": "https://assets.coingecko.com/coins/images/18625/thumb/12280.png",
  "CARB": "https://assets.coingecko.com/coins/images/14911/thumb/6070c8f4d4cbfb91f2848128_logo-256px.png",
  "$DHEEMS": "https://assets.coingecko.com/coins/images/26917/thumb/cheemsdoge-1.png",
  "ANA": "https://assets.coingecko.com/coins/images/25012/thumb/ANA_Logo.png",
  "CFXQ": "https://assets.coingecko.com/coins/images/15031/thumb/CFXQ_TOKEN_LOGO_200x200.png",
  "BSCM": "https://assets.coingecko.com/coins/images/19120/thumb/YTCqjSB.png",
  "BUNNYROCKET": "https://assets.coingecko.com/coins/images/19140/thumb/OvhRGGx.png",
  "TCG2": "https://assets.coingecko.com/coins/images/18035/thumb/TCG2.jpg",
  "NPICK": "https://assets.coingecko.com/coins/images/25151/thumb/Logo200_%281%29.png",
  "COURSE": "https://assets.coingecko.com/coins/images/25832/thumb/200.png",
  "MTCL": "https://assets.coingecko.com/coins/images/18036/thumb/TQzTNi5.png",
  "PROTEO": "https://assets.coingecko.com/coins/images/27993/thumb/proteo-logo-badge.png",
  "FLWR": "https://assets.coingecko.com/coins/images/23534/thumb/FLWR-Token-Front-ALPHA.png",
  "WDF": "https://assets.coingecko.com/coins/images/28430/thumb/WDF_200x200.png",
  "SHIBT": "https://assets.coingecko.com/coins/images/20744/thumb/logo_-_2021-11-23T101625.925.png",
  "SCOOT": "https://assets.coingecko.com/coins/images/15859/thumb/logo256.png",
  "3OH": "https://assets.coingecko.com/coins/images/26398/thumb/98052236.jpeg",
  "PIG": "https://assets.coingecko.com/coins/images/14338/thumb/pig.png",
  "HBD": "https://assets.coingecko.com/coins/images/10855/thumb/w_q7vezk_400x400.jpg",
  "SHKOOBY": "https://assets.coingecko.com/coins/images/19790/thumb/shkooby.png",
  "FAFI": "https://assets.coingecko.com/coins/images/20248/thumb/200x200_%2837%29.png",
  "CLAY": "https://assets.coingecko.com/coins/images/27826/thumb/download_%2814%29.png",
  "WHOO": "https://assets.coingecko.com/coins/images/23537/thumb/hooo.jpg",
  "RANTS": "https://assets.coingecko.com/coins/images/25269/thumb/website-rants-300x300.png",
  "MSOT": "https://assets.coingecko.com/coins/images/13141/thumb/BTour_Icon.png",
  "WUKONG": "https://assets.coingecko.com/coins/images/27238/thumb/wukong.png",
  "VELON": "https://assets.coingecko.com/coins/images/25346/thumb/Viking_Elon_200.png",
  "SAWA": "https://assets.coingecko.com/coins/images/27996/thumb/SAWA-logo-200x200.png",
  "PLSR": "https://assets.coingecko.com/coins/images/26306/thumb/pulsar_logo_no_back.png",
  "OPT": "https://assets.coingecko.com/coins/images/26318/thumb/OPT_Transparent.png",
  "XPT": "https://assets.coingecko.com/coins/images/27990/thumb/input-onlinejpgtools.jpg",
  "ENTC": "https://assets.coingecko.com/coins/images/19677/thumb/ENTC_symbol.png",
  "WONE": "https://assets.coingecko.com/coins/images/18183/thumb/wonelogo.png",
  "SMRTR": "https://assets.coingecko.com/coins/images/19634/thumb/kXN8SJ0.png",
  "YPC": "https://assets.coingecko.com/coins/images/25375/thumb/ypc.png",
  "SLIME": "https://assets.coingecko.com/coins/images/25004/thumb/19481.png",
  "STLE": "https://assets.coingecko.com/coins/images/27590/thumb/LOGO.png",
  "PCN": "https://assets.coingecko.com/coins/images/25687/thumb/97762641.png",
  "KITSUNE": "https://assets.coingecko.com/coins/images/27661/thumb/200pxtranparent.png",
  "RXT": "https://assets.coingecko.com/coins/images/27598/thumb/Logo_rimaunangis_200x200_%283%29.png",
  "FICO": "https://assets.coingecko.com/coins/images/22627/thumb/fico.png",
  "SSAP": "https://assets.coingecko.com/coins/images/23161/thumb/humanworld-logo200x200.png",
  "WBCH": "https://assets.coingecko.com/coins/images/21386/thumb/0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04.png",
  "SLEEPEE": "https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png",
  "PINT": "https://assets.coingecko.com/coins/images/14016/thumb/PINT.png",
  "DECODE": "https://assets.coingecko.com/coins/images/27149/thumb/DecodeCoin_LOGO_200X200.png",
  "ISK": "https://assets.coingecko.com/coins/images/27428/thumb/ISKRA_logo.png",
  "BRIBE": "https://assets.coingecko.com/coins/images/23567/thumb/bribe-v2.png",
  "PSYCHODOGE": "https://assets.coingecko.com/coins/images/17398/thumb/JUuShTOQ.png",
  "$MAX": "https://assets.coingecko.com/coins/images/26936/thumb/BPIJ7HUN_400x400.jpg",
  "FILM": "https://assets.coingecko.com/coins/images/26308/thumb/3tWbk-T2_400x400.jpeg",
  "NC": "https://assets.coingecko.com/coins/images/13701/thumb/nc_logo.png",
  "GHD": "https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png",
  "PYBC": "https://assets.coingecko.com/coins/images/22573/thumb/LPepLyMW_400x400.png",
  "SHIBMERICAN": "https://assets.coingecko.com/coins/images/17008/thumb/60e1bb9c402f36243557ca79_200.png",
  "CSHARE": "https://assets.coingecko.com/coins/images/23063/thumb/CSHARE-200x200.png",
  "WXRP": "https://assets.coingecko.com/coins/images/19952/thumb/WXRP_revision-256px.png",
  "NOX": "https://assets.coingecko.com/coins/images/28803/thumb/NOX_TOKEN.png",
  "CARBO": "https://assets.coingecko.com/coins/images/26311/thumb/cleancarbologo.png",
  "EMAX": "https://assets.coingecko.com/coins/images/15540/thumb/EMAX-Coin-Final2000x.png",
  "DXY": "https://assets.coingecko.com/coins/images/12839/thumb/dxy_finance.png",
  "LOUD": "https://assets.coingecko.com/coins/images/18528/thumb/Loud-Market-200x200.png",
  "USDF": "https://assets.coingecko.com/coins/images/26920/thumb/USDF_200x200.png",
  "CSTAR": "https://assets.coingecko.com/coins/images/19492/thumb/cs_v2_Logo_only.png",
  "YVLUSD": "https://assets.coingecko.com/coins/images/28788/thumb/yvLUSD-128-0x378cb52b00F9D0921cb46dFc099CFf73b42419dC.png",
  "VTG": "https://assets.coingecko.com/coins/images/25270/thumb/vtg.png",
  "ANOM": "https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png",
  "WCKB": "https://assets.coingecko.com/coins/images/27502/thumb/ckb.jpeg",
  "LIVE": "https://assets.coingecko.com/coins/images/12323/thumb/TVgAYofpQku5G4zenXnvxhbZxpzzrk8WVK.png",
  "TANK": "https://assets.coingecko.com/coins/images/21966/thumb/LN-MRxWg_400x400.jpg",
  "DK": "https://assets.coingecko.com/coins/images/28693/thumb/DK_Token.png",
  "MANY": "https://assets.coingecko.com/coins/images/21937/thumb/fiJanA94_400x400.jpg",
  "GDG": "https://assets.coingecko.com/coins/images/26345/thumb/3kmKPRHZ_400x400.jpeg",
  "DFM": "https://assets.coingecko.com/coins/images/24673/thumb/dfm.jpg",
  "ABOAT": "https://assets.coingecko.com/coins/images/27213/thumb/aboat.jpg",
  "BABYXRP": "https://assets.coingecko.com/coins/images/21064/thumb/KrrE8T3.jpeg",
  "XRICE": "https://assets.coingecko.com/coins/images/26645/thumb/-6283054314280824753_121.jpg",
  "LSHARE": "https://assets.coingecko.com/coins/images/26038/thumb/LSHARE.png",
  "DMN": "https://assets.coingecko.com/coins/images/22212/thumb/IMG-20220103-031446-496.jpg",
  "SLAB": "https://assets.coingecko.com/coins/images/28947/thumb/ShibAI-Labs-Logo-PNG.png",
  "GPYX": "https://assets.coingecko.com/coins/images/6280/thumb/gpyx.png",
  "P202": "https://assets.coingecko.com/coins/images/26590/thumb/hqdf9m6R_400x400.png",
  "HOOP": "https://assets.coingecko.com/coins/images/27405/thumb/HOOP_TOKEN.png",
  "KTZ": "https://assets.coingecko.com/coins/images/22023/thumb/nJSKxSI5_400x400.jpg",
  "EBSO": "https://assets.coingecko.com/coins/images/18636/thumb/eBSO_logo_szines.png",
  "ELS": "https://assets.coingecko.com/coins/images/20966/thumb/communityIcon_kr83rxrl1ij71.png",
  "BNJ": "https://assets.coingecko.com/coins/images/13852/thumb/BNJ_COIN.png",
  "SHIBGX": "https://assets.coingecko.com/coins/images/21248/thumb/cM870ezC_400x400.jpg",
  "$V": "https://assets.coingecko.com/coins/images/27029/thumb/Vendetta-DAO-200x200.png",
  "ADO": "https://assets.coingecko.com/coins/images/27346/thumb/ado-200x200.png",
  "EPCT": "https://assets.coingecko.com/coins/images/28901/thumb/EpicsCoin200x200.png",
  "ARCADE": "https://assets.coingecko.com/coins/images/27939/thumb/arcade.png",
  "CRG": "https://assets.coingecko.com/coins/images/10355/thumb/header_2.png",
  "SARABI": "https://assets.coingecko.com/coins/images/27350/thumb/512v2.png",
  "MECH": "https://assets.coingecko.com/coins/images/19403/thumb/mech_master.PNG",
  "LOOTDOLLAR": "https://assets.coingecko.com/coins/images/25605/thumb/20148.png",
  "CHOPPER": "https://assets.coingecko.com/coins/images/18644/thumb/Chopper-Inu-Logo.png",
  "LOX": "https://assets.coingecko.com/coins/images/18704/thumb/LOX.png",
  "BTCPAY": "https://assets.coingecko.com/coins/images/26994/thumb/photo_2022-08-22_16-38-30-removebg-preview.png",
  "RWSC": "https://assets.coingecko.com/coins/images/19042/thumb/rwsc.PNG",
  "BANKERS": "https://assets.coingecko.com/coins/images/26654/thumb/bull-logo-200x200.png",
  "XRAY": "https://assets.coingecko.com/coins/images/27555/thumb/download_%2810%29.png",
  "GMINES": "https://assets.coingecko.com/coins/images/25775/thumb/coingecko.png",
  "SPKY": "https://assets.coingecko.com/coins/images/26324/thumb/H2vW_x5A_400x400.jpeg",
  "TAKEDA": "https://assets.coingecko.com/coins/images/20385/thumb/takeda.png",
  "EVCOIN": "https://assets.coingecko.com/coins/images/19137/thumb/evcoin.png",
  "PNFT": "https://assets.coingecko.com/coins/images/19998/thumb/FcVyUcDq_400x400.jpg",
  "MGC": "https://assets.coingecko.com/coins/images/23736/thumb/jqfIMtiG_400x400.jpg",
  "CLCT": "https://assets.coingecko.com/coins/images/25857/thumb/atUulXVV_400x400.jpg",
  "VKSM": "https://assets.coingecko.com/coins/images/28874/thumb/vKSM.png",
  "UCR": "https://assets.coingecko.com/coins/images/12609/thumb/jowk6jl.png",
  "GAMER": "https://assets.coingecko.com/coins/images/19584/thumb/game_station.PNG",
  "FBT": "https://assets.coingecko.com/coins/images/25122/thumb/Untitled_design__15_-removebg-preview.png",
  "CRDAO": "https://assets.coingecko.com/coins/images/19503/thumb/0.png",
  "METADOGEV2": "https://assets.coingecko.com/coins/images/20053/thumb/metadoge.png",
  "CHOICE": "https://assets.coingecko.com/coins/images/23770/thumb/IMG_2021.jpg",
  "SAITOKI": "https://assets.coingecko.com/coins/images/23586/thumb/saitoki_200.png",
  "GL": "https://assets.coingecko.com/coins/images/11015/thumb/greenlight.PNG",
  "ZLN": "https://assets.coingecko.com/coins/images/7770/thumb/QtOpQwj.png",
  "AXLUSDC": "https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png",
  "ZGD": "https://assets.coingecko.com/coins/images/25544/thumb/lD1w22TUaUn2AkWmkgI5WWSufl5kZ-1Gu3s-g-2A9qZ-DyW5g9UrrBRaOxKp5fcLEghB2VR-XBxw-4mu4M76SjH3YDCvh27CH4RTfKAbtlrEWHNP7oHN9Y6eBrqwfGu_-M0SAJf-dqDRnMNLWIxP8hKtAxaIFR0_JmxdInlMpBJa2WONniOHCeGomlyYeGfBKa4bYytvpIKDwZNBC-b_-enFwb.jpg",
  "AVDO": "https://assets.coingecko.com/coins/images/23675/thumb/PNLL1B2g_400x400.jpg",
  "STREAMN": "https://assets.coingecko.com/coins/images/27043/thumb/logo.png",
  "VRFY": "https://assets.coingecko.com/coins/images/19456/thumb/vrfy.png",
  "THECAT": "https://assets.coingecko.com/coins/images/24624/thumb/200.png",
  "WARZ": "https://assets.coingecko.com/coins/images/26949/thumb/warz.png",
  "XPD": "https://assets.coingecko.com/coins/images/1296/thumb/petrodollar.png",
  "YVWBTC": "https://assets.coingecko.com/coins/images/28796/thumb/yvWBTC-128-0xA696a63cc78DfFa1a63E9E50587C197387FF6C7E.png",
  "$CLEAR": "https://assets.coingecko.com/coins/images/23798/thumb/attachment.png",
  "FOOTIE": "https://assets.coingecko.com/coins/images/17628/thumb/footie.jpeg",
  "GART": "https://assets.coingecko.com/coins/images/28309/thumb/hItUGzio_400x400.jpeg",
  "NESTA": "https://assets.coingecko.com/coins/images/24182/thumb/mPFhh9sZ_400x400.jpg",
  "JSHIBA": "https://assets.coingecko.com/coins/images/15395/thumb/Jomon.png",
  "WITCH": "https://assets.coingecko.com/coins/images/17205/thumb/download_%2829%29.png",
  "SQUIRT": "https://assets.coingecko.com/coins/images/20887/thumb/squirtgame-logo-200x200.png",
  "HBN": "https://assets.coingecko.com/coins/images/28467/thumb/kBBc9jeM_400x400.jpg",
  "CF": "https://assets.coingecko.com/coins/images/2930/thumb/californium.png",
  "BLKZ": "https://assets.coingecko.com/coins/images/25956/thumb/FuUiYV9w_400x400.jpg",
  "OPX": "https://assets.coingecko.com/coins/images/28225/thumb/logo_OPX.png",
  "NXD": "https://assets.coingecko.com/coins/images/23414/thumb/nexus.PNG",
  "GENIUS": "https://assets.coingecko.com/coins/images/17095/thumb/A5-F6-C70-C-F535-4-DDA-8-EBC-31009-D9-E3-C7-E.png",
  "AGRO": "https://assets.coingecko.com/coins/images/21511/thumb/200x200.png",
  "DNKY": "https://assets.coingecko.com/coins/images/21279/thumb/KGBvdHyw_400x400.jpg",
  "LSCN": "https://assets.coingecko.com/coins/images/24062/thumb/LSCN-logo.png",
  "FPL": "https://assets.coingecko.com/coins/images/21042/thumb/photo-2021-11-30-00-35-04.jpg",
  "JYC": "https://assets.coingecko.com/coins/images/25337/thumb/Joe_Yo_Coin_200.png",
  "BABYTETHER": "https://assets.coingecko.com/coins/images/23371/thumb/For-sites-200x200.png",
  "PENNY": "https://assets.coingecko.com/coins/images/27017/thumb/penny_logo200.jpg",
  "MDCY": "https://assets.coingecko.com/coins/images/23995/thumb/mcc.png",
  "SSU": "https://assets.coingecko.com/coins/images/23369/thumb/logo.png",
  "LAINESOL": "https://assets.coingecko.com/coins/images/28184/thumb/laineSOL.png",
  "ZEN-AI": "https://assets.coingecko.com/coins/images/28987/thumb/Untitled_design_%2811%29.png",
  "GALAXYDOGE": "https://assets.coingecko.com/coins/images/23933/thumb/1DCA5DD9-844F-4AE9-811A-A64266FBCFB5.png",
  "DCY": "https://assets.coingecko.com/coins/images/748/thumb/dcy.png",
  "XRPC": "https://assets.coingecko.com/coins/images/28869/thumb/xrpc.jpeg",
  "LEVI": "https://assets.coingecko.com/coins/images/28147/thumb/IMG_20221107_091938_841.png",
  "WXDC": "https://assets.coingecko.com/coins/images/28268/thumb/xinfin.jpeg",
  "HOPE": "https://assets.coingecko.com/coins/images/24153/thumb/logo_hope.png",
  "YVTUSD": "https://assets.coingecko.com/coins/images/28790/thumb/yvTUSD-128-0xFD0877d9095789cAF24c98F7CCe092fa8E120775.png",
  "S2K": "https://assets.coingecko.com/coins/images/25810/thumb/s2k.png",
  "BMBO": "https://assets.coingecko.com/coins/images/19620/thumb/FC0hnduacAAHYFC.png",
  "MEKKA": "https://assets.coingecko.com/coins/images/24118/thumb/k_9e4luFsOmMVOGhBqxBb6we5HkKFE_NwNza--t7MAk.png",
  "BVC": "https://assets.coingecko.com/coins/images/23750/thumb/bvc.png",
  "DHOLD": "https://assets.coingecko.com/coins/images/15559/thumb/X31eQqk.png",
  "ELNC": "https://assets.coingecko.com/coins/images/17864/thumb/fVZiSxD.png",
  "GAU": "https://assets.coingecko.com/coins/images/28151/thumb/200x200.png",
  "FZS": "https://assets.coingecko.com/coins/images/28137/thumb/20221106_210629.png",
  "NSTART": "https://assets.coingecko.com/coins/images/28891/thumb/undefined_-_Imgur.png",
  "VCE": "https://assets.coingecko.com/coins/images/24349/thumb/20220315-164422.png",
  "PLOCK": "https://assets.coingecko.com/coins/images/17375/thumb/Header-Logo1.png",
  "TRACTOR": "https://assets.coingecko.com/coins/images/19651/thumb/logo_-_2021-11-01T100340.535.png",
  "WBNB": "https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png",
  "MARTIANDOGE": "https://assets.coingecko.com/coins/images/20101/thumb/martiandoge.png",
  "QCK": "https://assets.coingecko.com/coins/images/26857/thumb/quicksilver-logo-icon-render.png",
  "OBLOX": "https://assets.coingecko.com/coins/images/23708/thumb/Oceidon-Blox-Logo-Square-GC.png",
  "XRSHIB": "https://assets.coingecko.com/coins/images/23687/thumb/xrshib-red-circle2.png",
  "MNET": "https://assets.coingecko.com/coins/images/21127/thumb/r6EAaVft_400x400.jpg",
  "MOT": "https://assets.coingecko.com/coins/images/18144/thumb/11322.png",
  "WNEAR": "https://assets.coingecko.com/coins/images/18280/thumb/EX4mrWMW_400x400.jpg",
  "BAFI": "https://assets.coingecko.com/coins/images/13512/thumb/BAFI.png",
  "SMOL": "https://assets.coingecko.com/coins/images/25420/thumb/awL5SVbh_400x400.jpg",
  "BSHARE": "https://assets.coingecko.com/coins/images/22299/thumb/15933.png",
  "PHCR": "https://assets.coingecko.com/coins/images/23640/thumb/L7vabJibqXHHmGtsIfIEQRaR3SjaE94QSaKWGlqYU7fpuz--nKsWG4BlB6Ddhn5-tGPWo-MDhfZPVAYG7ZM2vpWrVZlSx1Euw78tZcNey6FvpKX6a8w26qBWYeV7wxFzvW7VKieijrMXQ0BkISpt8d6jrCMBvx2ZFKVxF1x31_2X7WhRyqAgIG3epFPNz7v0j0ECaS7HmE0_sTHM4RS3CW1d0a.jpg",
  "MQST": "https://assets.coingecko.com/coins/images/22912/thumb/o3Dcp_Qi_400x400.jpg",
  "HVILLE": "https://assets.coingecko.com/coins/images/23661/thumb/harmonyville200x200.png",
  "DDAO": "https://assets.coingecko.com/coins/images/23018/thumb/New-Logo.jpg",
  "BROWN": "https://assets.coingecko.com/coins/images/20826/thumb/200x200-LOGO.png",
  "ODA": "https://assets.coingecko.com/coins/images/19546/thumb/oda.png",
  "ZEUS": "https://assets.coingecko.com/coins/images/27107/thumb/logo.9dcd586c.png",
  "D2T": "https://assets.coingecko.com/coins/images/28707/thumb/logo.png",
  "LEMN": "https://assets.coingecko.com/coins/images/24655/thumb/fXVYvQc3_400x400.jpg",
  "GCZ": "https://assets.coingecko.com/coins/images/9876/thumb/rgKttqdN_400x400.png",
  "KUT": "https://assets.coingecko.com/coins/images/26094/thumb/KUT.png",
  "VRT": "https://assets.coingecko.com/coins/images/15734/thumb/vrt-venus-token.png",
  "FTMO": "https://assets.coingecko.com/coins/images/18359/thumb/59qa0dJ.png",
  "DEFUN": "https://assets.coingecko.com/coins/images/27906/thumb/trans.png",
  "PHOON": "https://assets.coingecko.com/coins/images/13730/thumb/photo_2021-01-21_19-05-27.jpg",
  "BUGG": "https://assets.coingecko.com/coins/images/17408/thumb/bugg_finance.PNG",
  "FBURN": "https://assets.coingecko.com/coins/images/28156/thumb/Forever_Burn.jpeg",
  "SAUNA": "https://assets.coingecko.com/coins/images/18313/thumb/wBS86Ool_400x400.jpg",
  "DFSM": "https://assets.coingecko.com/coins/images/27931/thumb/dfsm.png",
  "GALE": "https://assets.coingecko.com/coins/images/25228/thumb/gale200.png",
  "KINGDOG": "https://assets.coingecko.com/coins/images/23703/thumb/200.png",
  "RAK": "https://assets.coingecko.com/coins/images/13078/thumb/rake-logo-200x200.png",
  "ESWAPV2": "https://assets.coingecko.com/coins/images/22728/thumb/83286022.png",
  "SORA": "https://assets.coingecko.com/coins/images/9613/thumb/SorachanCoin200.png",
  "LEUR": "https://assets.coingecko.com/coins/images/23477/thumb/FKr6xjCXwAErOxM.jpg",
  "MICH": "https://assets.coingecko.com/coins/images/10382/thumb/charifyalfa.PNG",
  "DREAMS": "https://assets.coingecko.com/coins/images/19012/thumb/DQ-token.png",
  "SLAY2EARN": "https://assets.coingecko.com/coins/images/26257/thumb/cmc-logo.png",
  "POLKAS": "https://assets.coingecko.com/coins/images/27444/thumb/200x200_logo.png",
  "FNZ": "https://assets.coingecko.com/coins/images/28464/thumb/0BD89268-3700-4357-A5E4-65F61635A755.png",
  "MSCT": "https://assets.coingecko.com/coins/images/27415/thumb/en-us.png",
  "SPAT": "https://assets.coingecko.com/coins/images/19933/thumb/metaspatial.PNG",
  "DECI": "https://assets.coingecko.com/coins/images/27693/thumb/Deci.png",
  "JENNY": "https://assets.coingecko.com/coins/images/28116/thumb/jenny.png",
  "WEVER": "https://assets.coingecko.com/coins/images/25838/thumb/everscale1642689451662.png",
  "WBIND": "https://assets.coingecko.com/coins/images/13055/thumb/o1w2cBW.png",
  "MXZ": "https://assets.coingecko.com/coins/images/27797/thumb/Untitled_design-removebg-preview_%281%29.png",
  "ABEL": "https://assets.coingecko.com/coins/images/28469/thumb/Abel_logo_circle.png",
  "NELUM": "https://assets.coingecko.com/coins/images/27394/thumb/200_050photo_2022-08-09_02-56-49.jpg",
  "ALLBI": "https://assets.coingecko.com/coins/images/26468/thumb/allbestico256x256.png",
  "HPO": "https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg",
  "XTS": "https://assets.coingecko.com/coins/images/24686/thumb/XTS_LOGO-1.png",
  "WAVAX": "https://assets.coingecko.com/coins/images/15075/thumb/wrapped-avax.png",
  "VKT": "https://assets.coingecko.com/coins/images/5625/thumb/vankia-chain.png",
  "BELIEVE": "https://assets.coingecko.com/coins/images/28868/thumb/VI0Ckbrl_400x400.jpg",
  "METAROID": "https://assets.coingecko.com/coins/images/23779/thumb/logo.png",
  "VITC": "https://assets.coingecko.com/coins/images/20661/thumb/vitc_200.png",
  "MITHRIL": "https://assets.coingecko.com/coins/images/20563/thumb/mithril.png",
  "IXP": "https://assets.coingecko.com/coins/images/28128/thumb/IXP_LOGO.jpg",
  "ENGN": "https://assets.coingecko.com/coins/images/20492/thumb/200x200_%2842%29.png",
  "DEBT": "https://assets.coingecko.com/coins/images/26349/thumb/imgonline-com-ua-Resize-FsfTbGcy5U.png",
  "LKT": "https://assets.coingecko.com/coins/images/28269/thumb/Locker.jpeg",
  "CUE": "https://assets.coingecko.com/coins/images/14493/thumb/fav.png",
  "METANIA": "https://assets.coingecko.com/coins/images/22927/thumb/metania-200.png",
  "MCN": "https://assets.coingecko.com/coins/images/17580/thumb/mcn-logo-white-bg.png",
  "ORAO": "https://assets.coingecko.com/coins/images/15189/thumb/orao.PNG",
  "UBX": "https://assets.coingecko.com/coins/images/13000/thumb/1.png",
  "PRIDE": "https://assets.coingecko.com/coins/images/24438/thumb/CHYWVY56_400x400.jpg",
  "SCALP": "https://assets.coingecko.com/coins/images/27691/thumb/scalp512.png",
  "QM": "https://assets.coingecko.com/coins/images/15916/thumb/qm.PNG",
  "PHIVE": "https://assets.coingecko.com/coins/images/25495/thumb/Hive_Logo__200x200_.jpg",
  "TNODE": "https://assets.coingecko.com/coins/images/20534/thumb/trustednode.png",
  "MEVR": "https://assets.coingecko.com/coins/images/23437/thumb/cmc3.png",
  "MELOS": "https://assets.coingecko.com/coins/images/24411/thumb/18551.png",
  "ISIKC": "https://assets.coingecko.com/coins/images/10992/thumb/logo_%2866%29.png",
  "CANN": "https://assets.coingecko.com/coins/images/109/thumb/cannabiscoin.png",
  "$ADOGE": "https://assets.coingecko.com/coins/images/26076/thumb/Safeimagekit-ArabianDogepng.png",
  "DRM": "https://assets.coingecko.com/coins/images/10469/thumb/57414069_286321078958466_8377709696580059136_n.jpg",
  "RLOKI": "https://assets.coingecko.com/coins/images/20299/thumb/200-x-200.png",
  "SAFEHAMSTERS": "https://assets.coingecko.com/coins/images/15831/thumb/QpwIAatV_400x400.jpg",
  "YVSUSHI": "https://assets.coingecko.com/coins/images/28789/thumb/yvSUSHI-128-0x6d765CbE5bC922694afE112C140b8878b9FB0390.png",
  "VMA": "https://assets.coingecko.com/coins/images/25993/thumb/200200.png",
  "W3B": "https://assets.coingecko.com/coins/images/27531/thumb/200x200.png",
  "ACR": "https://assets.coingecko.com/coins/images/29002/thumb/200x200.png",
  "SOUP": "https://assets.coingecko.com/coins/images/28484/thumb/Soup-discord-whi-1.png",
  "AMAX": "https://assets.coingecko.com/coins/images/28349/thumb/WechatIMG360.jpeg",
  "HO": "https://assets.coingecko.com/coins/images/17407/thumb/HALO_logo.png",
  "WPC": "https://assets.coingecko.com/coins/images/27908/thumb/%EC%9B%94%EB%93%9C%ED%8E%98%EC%9D%B4_%ED%86%A0%ED%81%B0_%EB%A1%9C%EA%B3%A0_200.jpg",
  "PMATIC": "https://assets.coingecko.com/coins/images/26366/thumb/l5k8DuE3_400x400.png",
  "CSTC": "https://assets.coingecko.com/coins/images/26509/thumb/index_one.db763c5.png",
  "MBC": "https://assets.coingecko.com/coins/images/6282/thumb/MBC_B_Square.png",
  "MINIFOOTBALL": "https://assets.coingecko.com/coins/images/17693/thumb/cmc_etc_200x200.png",
  "TFBX": "https://assets.coingecko.com/coins/images/8842/thumb/5rd7a55q_400x400.png",
  "ESRC": "https://assets.coingecko.com/coins/images/6302/thumb/specification_img_4.png",
  "CPI": "https://assets.coingecko.com/coins/images/12178/thumb/Z7x7eUa.png",
  "MAXX": "https://assets.coingecko.com/coins/images/27203/thumb/maxxfinance.jpeg",
  "ETHF": "https://assets.coingecko.com/coins/images/27310/thumb/etf.jpeg",
  "BTCU": "https://assets.coingecko.com/coins/images/360/thumb/bitcoinultra.png",
  "CTRAIN": "https://assets.coingecko.com/coins/images/22849/thumb/RlJMgaB.png",
  "CGC": "https://assets.coingecko.com/coins/images/23988/thumb/cgc.png",
  "NBTC": "https://assets.coingecko.com/coins/images/17006/thumb/nbtc.png",
  "$PLSA": "https://assets.coingecko.com/coins/images/26891/thumb/2022-08-15_16.40.06.jpg",
  "COLD": "https://assets.coingecko.com/coins/images/15607/thumb/cold-finance.png",
  "BRE": "https://assets.coingecko.com/coins/images/24953/thumb/bre.png",
  "ZSKRAT": "https://assets.coingecko.com/coins/images/27593/thumb/200.png",
  "SHAG": "https://assets.coingecko.com/coins/images/26758/thumb/SHAG-200.png",
  "LVM": "https://assets.coingecko.com/coins/images/27097/thumb/200x200logo.png",
  "PUFFS": "https://assets.coingecko.com/coins/images/17672/thumb/puff.PNG",
  "MONONOKE-INU": "https://assets.coingecko.com/coins/images/18769/thumb/z9YDK0f.png",
  "WIZT": "https://assets.coingecko.com/coins/images/28710/thumb/wizt-logo.jpg",
  "MRG": "https://assets.coingecko.com/coins/images/6833/thumb/wemerge200.png",
  "PLSB": "https://assets.coingecko.com/coins/images/28690/thumb/%E2%82%BF.png",
  "ABLE": "https://assets.coingecko.com/coins/images/17819/thumb/aac9bfb0-f27f-48f3-9b3e-ec090e10d3e1.png",
  "PUBE": "https://assets.coingecko.com/coins/images/15425/thumb/2618620f.jpg",
  "BONY": "https://assets.coingecko.com/coins/images/28622/thumb/logo_ok_1_%282%29.png",
  "SPEP": "https://assets.coingecko.com/coins/images/20581/thumb/pepe_300.png",
  "SPG": "https://assets.coingecko.com/coins/images/21900/thumb/15529.png",
  "HTN": "https://assets.coingecko.com/coins/images/9002/thumb/htn%28200x200%29.png",
  "CCAP": "https://assets.coingecko.com/coins/images/19021/thumb/cues.png",
  "SAVE": "https://assets.coingecko.com/coins/images/15353/thumb/200_Save_black.jpg",
  "PEXO": "https://assets.coingecko.com/coins/images/21895/thumb/plant_axosu.PNG",
  "WXTC": "https://assets.coingecko.com/coins/images/11024/thumb/WeChain.png",
  "OPT3": "https://assets.coingecko.com/coins/images/27817/thumb/optimus-logo-03.png",
  "ASG": "https://assets.coingecko.com/coins/images/27816/thumb/asg.png",
  "URUST": "https://assets.coingecko.com/coins/images/27751/thumb/20221010_164134.png",
  "DUO": "https://assets.coingecko.com/coins/images/2659/thumb/Parallelcoin.png",
  "BULK": "https://assets.coingecko.com/coins/images/15266/thumb/Bulk_whale_round.png",
  "BBQ": "https://assets.coingecko.com/coins/images/15262/thumb/bbq.png",
  "YV1INCH": "https://assets.coingecko.com/coins/images/28795/thumb/yv1INCH-128-0xB8C3B7A2A618C552C23B1E4701109a9E756Bab67.png",
  "SNACK": "https://assets.coingecko.com/coins/images/24869/thumb/Logo-Crypto-Snack-Clean-Gold-Coin-Version.png",
  "NOBF": "https://assets.coingecko.com/coins/images/22545/thumb/ba60ykk8_400x400.png",
  "ST": "https://assets.coingecko.com/coins/images/22543/thumb/STLogo200x.png",
  "KFR": "https://assets.coingecko.com/coins/images/21706/thumb/king200.png",
  "GXE": "https://assets.coingecko.com/coins/images/25754/thumb/GXE.png",
  "KMASK": "https://assets.coingecko.com/coins/images/27866/thumb/kitsun.png",
  "FLAFLO": "https://assets.coingecko.com/coins/images/23346/thumb/VrMX8rOf_400x400.jpg",
  "DKT": "https://assets.coingecko.com/coins/images/19115/thumb/logo-png-min.png",
  "TSD": "https://assets.coingecko.com/coins/images/18678/thumb/TSDfinalresize.png",
  "USDU": "https://assets.coingecko.com/coins/images/12332/thumb/usdu.png",
  "OPV": "https://assets.coingecko.com/coins/images/22622/thumb/opv.png",
  "GALW": "https://assets.coingecko.com/coins/images/27524/thumb/GalacticWar_PNG_2.png",
  "EFLOKI": "https://assets.coingecko.com/coins/images/23500/thumb/logo-200x200.jpeg",
  "MODX": "https://assets.coingecko.com/coins/images/6603/thumb/LOGO-MODX.png",
  "OLYMPIC DOGE": "https://assets.coingecko.com/coins/images/19894/thumb/doge.jpeg",
  "WZM": "https://assets.coingecko.com/coins/images/21891/thumb/logo_200_200_%281%29.png",
  "VNXLU": "https://assets.coingecko.com/coins/images/9945/thumb/vnx.PNG",
  "GABX": "https://assets.coingecko.com/coins/images/26415/thumb/GABX.png",
  "YHC": "https://assets.coingecko.com/coins/images/20351/thumb/13766.png",
  "ATOZ": "https://assets.coingecko.com/coins/images/26472/thumb/Race_Kingdom_Logo.png",
  "LUNAX": "https://assets.coingecko.com/coins/images/23146/thumb/LunaX_1.png",
  "THG": "https://assets.coingecko.com/coins/images/18414/thumb/c4N4n8k-_400x400.png",
  "NIRV": "https://assets.coingecko.com/coins/images/25050/thumb/NIRV.png",
  "GINU": "https://assets.coingecko.com/coins/images/28323/thumb/5FADC518-3EE8-4914-A627-602B9C3FAFB4.jpeg",
  "MTE": "https://assets.coingecko.com/coins/images/26974/thumb/IMG-20220809-WA0020.jpg",
  "TRIO": "https://assets.coingecko.com/coins/images/27684/thumb/IMG_1127.PNG",
  "GENF": "https://assets.coingecko.com/coins/images/27833/thumb/200photo_2022-10-09_06-35-34.jpg",
  "DIE": "https://assets.coingecko.com/coins/images/27903/thumb/newlogo.jpg",
  "XTAROT": "https://assets.coingecko.com/coins/images/24285/thumb/xtarot.png",
  "MUSO": "https://assets.coingecko.com/coins/images/20715/thumb/cMwOUI9V_400x400.jpg",
  "AURUM": "https://assets.coingecko.com/coins/images/18131/thumb/xcqAUkU.png",
  "UPS": "https://assets.coingecko.com/coins/images/20179/thumb/logo_-_2021-11-11T122041.439.png",
  "DRIP": "https://assets.coingecko.com/coins/images/21340/thumb/11093.png",
  "PETT": "https://assets.coingecko.com/coins/images/27147/thumb/pett-logo.png",
  "CSD": "https://assets.coingecko.com/coins/images/26416/thumb/Logo_Vector_M_%281%29.png",
  "$BINGO": "https://assets.coingecko.com/coins/images/27887/thumb/200_%282%29.png",
  "PULSE": "https://assets.coingecko.com/coins/images/19011/thumb/photo_2021-11-11_06-24-09.jpg",
  "ATK": "https://assets.coingecko.com/coins/images/21333/thumb/attack.PNG",
  "GAMES": "https://assets.coingecko.com/coins/images/1729/thumb/logo-yt_1_1_1_200x200.png",
  "WTRX": "https://assets.coingecko.com/coins/images/22471/thumb/xOesRfpN_400x400.jpg",
  "DGSTB": "https://assets.coingecko.com/coins/images/17929/thumb/Dogestribute-logo.png",
  "COWS": "https://assets.coingecko.com/coins/images/21876/thumb/cowboy_snake.PNG",
  "EIFI": "https://assets.coingecko.com/coins/images/17120/thumb/eifi.PNG",
  "LADA": "https://assets.coingecko.com/coins/images/25108/thumb/Logo_Small_Resized.png",
  "DAC": "https://assets.coingecko.com/coins/images/5494/thumb/dac.png",
  "WXDAI": "https://assets.coingecko.com/coins/images/14584/thumb/wrapped-xdai-logo.png",
  "WISH": "https://assets.coingecko.com/coins/images/17670/thumb/brave_q8sUJDRjqo.png",
  "FWIN-AI": "https://assets.coingecko.com/coins/images/29006/thumb/IMG_20230208_235738_212.png",
  "QODEX": "https://assets.coingecko.com/coins/images/28767/thumb/qoda_logo.png",
  "CHECKR": "https://assets.coingecko.com/coins/images/25226/thumb/CHECKR-logo.png",
  "CAH": "https://assets.coingecko.com/coins/images/28470/thumb/MTLOGO.png",
  "SOGA": "https://assets.coingecko.com/coins/images/8967/thumb/O-rsO7g2_400x400.jpg",
  "BTCA": "https://assets.coingecko.com/coins/images/14279/thumb/icon-dark.png",
  "MOVD": "https://assets.coingecko.com/coins/images/19236/thumb/MOVD_logo.png",
  "TRINITY": "https://assets.coingecko.com/coins/images/27890/thumb/TrinitySWap.png",
  "AADA": "https://assets.coingecko.com/coins/images/27825/thumb/download_%2813%29.png",
  "ZUZ": "https://assets.coingecko.com/coins/images/14444/thumb/ZUZ_Icon2-100.png",
  "DFKGREGG": "https://assets.coingecko.com/coins/images/27551/thumb/pet-egg-grey.png",
  "$EARN": "https://assets.coingecko.com/coins/images/28925/thumb/200x200EverEarnETHc.png",
  "C4E": "https://assets.coingecko.com/coins/images/28199/thumb/logo_C4E_bez_tla3.png",
  "EVAPE": "https://assets.coingecko.com/coins/images/18477/thumb/EVERYAPE.jpg",
  "SEANCE": "https://assets.coingecko.com/coins/images/22231/thumb/01ed0a6a-35e8-45e1-9cdd-ff4a4756a36f.png",
  "TEAM": "https://assets.coingecko.com/coins/images/27711/thumb/TEAM.png",
  "LFGO": "https://assets.coingecko.com/coins/images/22244/thumb/LK5s4mx-d5GIPPPmilZLRBtxH0OvOAp02sbgYCbFRtE.png",
  "SEAMLESS": "https://assets.coingecko.com/coins/images/20836/thumb/h9aSYw1B_400x400.jpg",
  "WFLOW": "https://assets.coingecko.com/coins/images/24045/thumb/wrapped-flow.png",
  "FCN": "https://assets.coingecko.com/coins/images/17476/thumb/logo_-_2021-08-02T172142.650.png",
  "CPAN": "https://assets.coingecko.com/coins/images/19103/thumb/span.PNG",
  "SHARES": "https://assets.coingecko.com/coins/images/25038/thumb/MgI7lu4.png",
  "STREAMERINU": "https://assets.coingecko.com/coins/images/27994/thumb/logo.png",
  "GSTAR": "https://assets.coingecko.com/coins/images/28209/thumb/gstar.png",
  "EXE": "https://assets.coingecko.com/coins/images/9466/thumb/8x8_symbol_512x.png",
  "BDOG": "https://assets.coingecko.com/coins/images/20437/thumb/bdog.jpeg",
  "CLIFF": "https://assets.coingecko.com/coins/images/21624/thumb/Cliff-Logo-with-with-Circle-NO-Background.png",
  "VGLMR": "https://assets.coingecko.com/coins/images/28877/thumb/vGLMR.png",
  "UNIT": "https://assets.coingecko.com/coins/images/1272/thumb/universal-currency.png",
  "PCASH": "https://assets.coingecko.com/coins/images/25750/thumb/103334325.png",
  "NOVA": "https://assets.coingecko.com/coins/images/21672/thumb/bGVB8h2Q_400x400.jpg",
  "FALCONS": "https://assets.coingecko.com/coins/images/19164/thumb/RK04yosg_400x400.jpg",
  "VMOVR": "https://assets.coingecko.com/coins/images/28876/thumb/vMOVR.png",
  "XGC": "https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png",
  "HEFI": "https://assets.coingecko.com/coins/images/26574/thumb/200x200_hefi_symbol_logo.png",
  "APEU": "https://assets.coingecko.com/coins/images/24006/thumb/Glassape-new-1.png",
  "COCN": "https://assets.coingecko.com/coins/images/28179/thumb/cocanetworklogo_%281%29.png",
  "MERIT": "https://assets.coingecko.com/coins/images/27020/thumb/logo_%284%29.png",
  "VPK": "https://assets.coingecko.com/coins/images/23729/thumb/vulture_logo_final-05.png",
  "PRTG": "https://assets.coingecko.com/coins/images/27397/thumb/IMG_20220921_123729_913.jpg",
  "BUCKY": "https://assets.coingecko.com/coins/images/27857/thumb/IMG_20221017_162511_345.png",
  "CART": "https://assets.coingecko.com/coins/images/17583/thumb/logo-64.png",
  "BANDEX": "https://assets.coingecko.com/coins/images/26700/thumb/6233e7f496c72994c313a50a_cactusboy90_%282%29-p-1080.png",
  "WXBTC": "https://assets.coingecko.com/coins/images/15688/thumb/XBTC-Logo-6.png",
  "ALETH": "https://assets.coingecko.com/coins/images/16271/thumb/uS04dyYy_400x400.jpeg",
  "MTR": "https://assets.coingecko.com/coins/images/12149/thumb/Meter-Logo-Vertical-Gray-Light-Blue-rgb-200x200px.png",
  "TIGER": "https://assets.coingecko.com/coins/images/26494/thumb/jungleparty.png",
  "CJET": "https://assets.coingecko.com/coins/images/22907/thumb/cjet.png",
  "SLOT": "https://assets.coingecko.com/coins/images/23297/thumb/200slot.png",
  "WEGLD": "https://assets.coingecko.com/coins/images/22255/thumb/wrapped_elrond.jpg",
  "MF1": "https://assets.coingecko.com/coins/images/21954/thumb/eC1-dKKt_400x400.jpg",
  "RLB": "https://assets.coingecko.com/coins/images/24552/thumb/unziL6wO_400x400.jpg",
  "MTF": "https://assets.coingecko.com/coins/images/21945/thumb/2duK7I_h_400x400.png",
  "NNT": "https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png",
  "HONEYD": "https://assets.coingecko.com/coins/images/21164/thumb/honeyd.png",
  "DZAR": "https://assets.coingecko.com/coins/images/9841/thumb/logo200_%281%29.png",
  "SEG": "https://assets.coingecko.com/coins/images/20935/thumb/seg.png",
  "MRI": "https://assets.coingecko.com/coins/images/23784/thumb/mri.png",
  "HPN": "https://assets.coingecko.com/coins/images/27148/thumb/logo.png",
  "WKAVA": "https://assets.coingecko.com/coins/images/26387/thumb/kava.jpeg",
  "PELE": "https://assets.coingecko.com/coins/images/19481/thumb/pele-token-1028.png",
  "PLEB": "https://assets.coingecko.com/coins/images/22093/thumb/LOGO_for_Listing.png",
  "HCK": "https://assets.coingecko.com/coins/images/21929/thumb/16422.png",
  "GTR": "https://assets.coingecko.com/coins/images/21869/thumb/trader-ghost-1.png",
  "CRND": "https://assets.coingecko.com/coins/images/25288/thumb/LOGO_%282%29.png",
  "4MW": "https://assets.coingecko.com/coins/images/22860/thumb/i9isHRLb_400x400.jpg",
  "STWEMIX": "https://assets.coingecko.com/coins/images/28761/thumb/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2023-01-17_%E1%84%8B%E1%85%A9%E1%84%92%E1%85%AE_1.42.27.png",
  "UMY": "https://assets.coingecko.com/coins/images/21211/thumb/E3GB2FfQ_400x400.jpg",
  "BAB": "https://assets.coingecko.com/coins/images/20592/thumb/_BAB_coinmedium.png",
  "XPRESS": "https://assets.coingecko.com/coins/images/20467/thumb/xvlKHAMJ_400x400.jpg",
  "BTLLR": "https://assets.coingecko.com/coins/images/13200/thumb/bttlr.png",
  "KLH": "https://assets.coingecko.com/coins/images/24820/thumb/logo.2fe68a84.png",
  "KASA": "https://assets.coingecko.com/coins/images/28354/thumb/kasa_blue_logo_200x200.png",
  "ZEON": "https://assets.coingecko.com/coins/images/4247/thumb/XZqXYc2j_400x400.jpg",
  "CHIH": "https://assets.coingecko.com/coins/images/20528/thumb/rsz_chihuahua-token.png",
  "BABYCATS": "https://assets.coingecko.com/coins/images/23959/thumb/1F7D09EB-6E0E-4AD3-9CF8-128D2A8C2C7F.png",
  "RXC": "https://assets.coingecko.com/coins/images/20949/thumb/ofJzS3VY_400x400.jpg",
  "JAIHO": "https://assets.coingecko.com/coins/images/20311/thumb/jaiho.PNG",
  "GLXY": "https://assets.coingecko.com/coins/images/25389/thumb/glxy.png",
  "FEB": "https://assets.coingecko.com/coins/images/20159/thumb/feb.png",
  "TURBO": "https://assets.coingecko.com/coins/images/25891/thumb/turbo.png",
  "BBETH": "https://assets.coingecko.com/coins/images/20125/thumb/shVTIpHA_400x400.jpg",
  "HNB": "https://assets.coingecko.com/coins/images/8126/thumb/GyEELaxS_400x400.jpg",
  "CSMS": "https://assets.coingecko.com/coins/images/19875/thumb/cosmosstarter.PNG",
  "NAC": "https://assets.coingecko.com/coins/images/26404/thumb/TVH8BqeUcvGgZc9y1z4Dqgxu37qjx2zepZ.jpeg",
  "DJN": "https://assets.coingecko.com/coins/images/21134/thumb/logo.png",
  "ASC": "https://assets.coingecko.com/coins/images/24501/thumb/Token.jpg",
  "MEMA": "https://assets.coingecko.com/coins/images/24009/thumb/200x200.png",
  "MIYAZAKI": "https://assets.coingecko.com/coins/images/19889/thumb/DzuUGOg.png",
  "GELDF": "https://assets.coingecko.com/coins/images/19597/thumb/geld.PNG",
  "TXT": "missing_thumb.png",
  "TXC": "https://assets.coingecko.com/coins/images/20984/thumb/1638050660072-921.jpg",
  "HOKK": "https://assets.coingecko.com/coins/images/14985/thumb/hokk.png",
  "CARROT": "https://assets.coingecko.com/coins/images/19098/thumb/12111.png",
  "$SCATE": "https://assets.coingecko.com/coins/images/25178/thumb/Whats-App-Image-2022-03-20-at-4-35-57-PM.jpg",
  "DOGERISEUP": "https://assets.coingecko.com/coins/images/21201/thumb/U3nxk6ow_400x400.jpg",
  "LNDLRD": "https://assets.coingecko.com/coins/images/25342/thumb/LANDLORD_200.png",
  "PBOS": "https://assets.coingecko.com/coins/images/28529/thumb/Phobos_Token_symbol.png",
  "SPKL": "https://assets.coingecko.com/coins/images/20086/thumb/14169.png",
  "METASFM": "https://assets.coingecko.com/coins/images/21257/thumb/logo-200x200.png",
  "OSQTH": "https://assets.coingecko.com/coins/images/22806/thumb/DyVT5XPV_400x400.jpg",
  "ABB": "https://assets.coingecko.com/coins/images/28158/thumb/Untitled_design.png",
  "BIOT": "https://assets.coingecko.com/coins/images/14167/thumb/logo_%2895%29.png",
  "GRIMACE": "https://assets.coingecko.com/coins/images/24207/thumb/ustF4Y_V_400x400.jpg",
  "A.O.T": "https://assets.coingecko.com/coins/images/21757/thumb/ageoftanks_logo_metal_%281%29.png",
  "BELLY": "https://assets.coingecko.com/coins/images/21754/thumb/NO68UIhV_400x400.jpg",
  "ROR": "https://assets.coingecko.com/coins/images/12133/thumb/XFHh5lxGU1UmRBu6JtZLyxkHtBqPSuqDWPUBMso2GJJxfVm1ZkmA3JrV6DEQps4ojt_TPJsEzn-sZMZCDhY2RSyXuMPCGZPIrswYlVqw6SkiwSBmdytU_HmbDXEiISkDHs9R9g1cBC02vwFrKSd8KUPIvXuAF0uncyTe_-OH_EgN-B2br7mtlPnXPoQ3bmLXkds2Q5_SCt1TG3W.jpg",
  "IGO": "https://assets.coingecko.com/coins/images/21752/thumb/igo.png",
  "SEOR": "https://assets.coingecko.com/coins/images/24397/thumb/-yycZmh7_400x400.png",
  "DRV": "https://assets.coingecko.com/coins/images/21660/thumb/drv.png",
  "BLADE": "https://assets.coingecko.com/coins/images/17677/thumb/LOGO_256_x_256.png",
  "MI": "https://assets.coingecko.com/coins/images/19139/thumb/mi.jpg",
  "FGC": "https://assets.coingecko.com/coins/images/4445/thumb/image0.jpeg",
  "SILK": "https://assets.coingecko.com/coins/images/28057/thumb/SILK_Logo.png",
  "LTK": "https://assets.coingecko.com/coins/images/7573/thumb/LTK.jpg",
  "FUMA": "https://assets.coingecko.com/coins/images/19282/thumb/logo_400.png",
  "TRDC": "https://assets.coingecko.com/coins/images/18675/thumb/logo-200x200.png",
  "KSEED": "https://assets.coingecko.com/coins/images/12545/thumb/kush.finance-512.png",
  "WBUSD": "https://assets.coingecko.com/coins/images/19498/thumb/wbusd.png",
  "BABYZOROINU": "https://assets.coingecko.com/coins/images/25334/thumb/Babyzoro-inu_200.png",
  "HORNY": "https://assets.coingecko.com/coins/images/28979/thumb/horny.png",
  "WW": "https://assets.coingecko.com/coins/images/6120/thumb/WW-large.png",
  "DIGAU": "https://assets.coingecko.com/coins/images/26106/thumb/UceACaeY_400x400.jpg",
  "KAIJU": "https://assets.coingecko.com/coins/images/18282/thumb/kaijuworlds_200.png",
  "DSOCCER": "https://assets.coingecko.com/coins/images/27750/thumb/DvyI43z.jpg",
  "FUZZ": "https://assets.coingecko.com/coins/images/18395/thumb/fuzz.PNG",
  "ARB": "https://assets.coingecko.com/coins/images/26046/thumb/IMG_3600.png",
  "WUSDT": "https://assets.coingecko.com/coins/images/19507/thumb/wusdt.png",
  "ICB": "https://assets.coingecko.com/coins/images/26323/thumb/photo_2022-07-06_16-58-56.jpg",
  "FSHN": "https://assets.coingecko.com/coins/images/8798/thumb/U_2764_RedHeart_256x256.png",
  "WUSDR": "https://assets.coingecko.com/coins/images/28800/thumb/wUSDRlogo.png",
  "IVC": "https://assets.coingecko.com/coins/images/7366/thumb/EcleHY7b_400x400.jpg",
  "GWINK": "https://assets.coingecko.com/coins/images/28772/thumb/g_3d.png",
  "NAGA": "https://assets.coingecko.com/coins/images/24895/thumb/Naga-200x200.png",
  "WKLAY": "https://assets.coingecko.com/coins/images/26241/thumb/klaytn-447b48a244a62e21497e9e980569572089ee29fd320b8d03ceb2f68526689218.png",
  "YVHEGIC": "https://assets.coingecko.com/coins/images/28798/thumb/yvHEGIC-128-0xe11ba472F74869176652C35D30dB89854b5ae84D.png",
  "BABYSAITAMA": "https://assets.coingecko.com/coins/images/17584/thumb/babysaitama.png",
  "KBTC": "https://assets.coingecko.com/coins/images/25816/thumb/jKEvMy-9_400x400.jpeg",
  "MCAP": "https://assets.coingecko.com/coins/images/22320/thumb/EMWN_Ybl_400x400.jpg",
  "BULLDOG": "https://assets.coingecko.com/coins/images/22344/thumb/bulldog.PNG",
  "WSTR": "https://assets.coingecko.com/coins/images/23972/thumb/wstr.png",
  "CRANE": "https://assets.coingecko.com/coins/images/26195/thumb/20785.png",
  "TRIBL": "https://assets.coingecko.com/coins/images/27774/thumb/TRIBL_Logo_200x200.png",
  "MNTTBSC": "https://assets.coingecko.com/coins/images/21685/thumb/moontrustlogo-200.jpg",
  "KOYO": "https://assets.coingecko.com/coins/images/27697/thumb/Capture-removebg-preview_%283%29.png",
  "SATS": "https://assets.coingecko.com/coins/images/17382/thumb/vR68Iyq.png",
  "BISWAP": "https://assets.coingecko.com/coins/images/17487/thumb/biswap.PNG",
  "DOGE2": "https://assets.coingecko.com/coins/images/17539/thumb/k4NUHHaO_400x400.jpg",
  "ERABBIT": "https://assets.coingecko.com/coins/images/19262/thumb/erabbit.png",
  "WTOMO": "https://assets.coingecko.com/coins/images/27618/thumb/tomochain.jpeg",
  "UNIM": "https://assets.coingecko.com/coins/images/23321/thumb/SVJI5-cerIpV4P1K3d_LeLKN3ZlE4eaazbnPEzrKhnU.png",
  "SCTK": "https://assets.coingecko.com/coins/images/17331/thumb/sprk.jpeg",
  "SUPD": "https://assets.coingecko.com/coins/images/17315/thumb/PMTsoQt.png",
  "OMT": "https://assets.coingecko.com/coins/images/27562/thumb/200X200_OMT.png",
  "YAMP": "https://assets.coingecko.com/coins/images/17301/thumb/0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79.png",
  "VB": "https://assets.coingecko.com/coins/images/28863/thumb/2x2.png",
  "20WETH-80BAL": "https://assets.coingecko.com/coins/images/28005/thumb/0x3d468ab2329f296e1b9d8476bb54dd77d8c2320f.png",
  "HATCHY": "https://assets.coingecko.com/coins/images/27560/thumb/HATCHY_200x200_Logo.png",
  "SBANK": "https://assets.coingecko.com/coins/images/17179/thumb/SafeBank_200x200.png",
  "F4H": "https://assets.coingecko.com/coins/images/27638/thumb/200x200.PNG",
  "KUMU": "https://assets.coingecko.com/coins/images/27064/thumb/200_200.png",
  "ETB": "https://assets.coingecko.com/coins/images/24049/thumb/cmc.png",
  "BCM": "https://assets.coingecko.com/coins/images/9322/thumb/ZdoYrfw.png",
  "WETHW": "https://assets.coingecko.com/coins/images/27470/thumb/wethw.png",
  "COCA": "missing_thumb.png",
  "SIM": "https://assets.coingecko.com/coins/images/17712/thumb/Logo_Simba_200x200.png",
  "DRIVECRYPTO": "https://assets.coingecko.com/coins/images/27430/thumb/IMG_20220922_132114_882.png",
  "LORY": "https://assets.coingecko.com/coins/images/16804/thumb/LORY.png",
  "AAMMDAI": "https://assets.coingecko.com/coins/images/17197/thumb/aAMMDAI_2x.png",
  "AVEX": "https://assets.coingecko.com/coins/images/28393/thumb/IMG_20221129_125713_361.png",
  "TSY": "https://assets.coingecko.com/coins/images/22020/thumb/NPt7LCJ3_400x400.jpg",
  "NKCLC": "https://assets.coingecko.com/coins/images/17893/thumb/w02Nksm-.png",
  "METI": "https://assets.coingecko.com/coins/images/27414/thumb/iShot_2022-09-22_22.24.06.png",
  "GGP": "https://assets.coingecko.com/coins/images/27413/thumb/4fhubWpA_400x400.jpeg",
  "BBTC": "https://assets.coingecko.com/coins/images/14867/thumb/binance-btc_32.png",
  "ETHBNT": "https://assets.coingecko.com/coins/images/10664/thumb/ETHBNT_Relay.png",
  "DPAD": "https://assets.coingecko.com/coins/images/24312/thumb/0vrp38DQ_400x400.jpg",
  "BINGDWENDWEN": "https://assets.coingecko.com/coins/images/23387/thumb/bingdwendwen.png",
  "ICC": "https://assets.coingecko.com/coins/images/22195/thumb/cropped-logo.png",
  "FLOSHIDO": "https://assets.coingecko.com/coins/images/29004/thumb/photo_2023-02-08_23-51-29.jpg",
  "BWC": "https://assets.coingecko.com/coins/images/16138/thumb/bongweed.PNG",
  "NLA": "https://assets.coingecko.com/coins/images/24299/thumb/nla200x200.png",
  "BTCB": "https://assets.coingecko.com/coins/images/14108/thumb/Binance-bitcoin.png",
  "WCUBE": "https://assets.coingecko.com/coins/images/26295/thumb/bb2877f9f24d4c70a93b2c6a128b338f.png",
  "SALO": "https://assets.coingecko.com/coins/images/27674/thumb/token.png",
  "XALGO": "https://assets.coingecko.com/coins/images/26335/thumb/xALGO.png",
  "CTR": "https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png",
  "LOON": "https://assets.coingecko.com/coins/images/11673/thumb/ErUvUA2D4Q8hPWXxZBIuiI8MD0nrU2rDEY56d6EtVzJDTqlflAFXa2bgJ_kFwb8OtotfAvESvm-dik7d_SeJJh23f6RosUJ0AzU64QcKk5H3fu300KOJjFDFsntpRWARFKI5S4Cc8F3pcRPBkFIw6oPF58mKi83sa2DJPl-E5JMqmj6rJh5acb7Fvi80kfziWv3DHhhpg1YJYMP.jpg",
  "PUNCH": "https://assets.coingecko.com/coins/images/28915/thumb/05C04DE3-DCC2-4BDE-8C7A-0B5CE0C825A7.jpeg",
  "MUUU": "https://assets.coingecko.com/coins/images/26052/thumb/IMG_0551.PNG",
  "GLAND": "https://assets.coingecko.com/coins/images/26637/thumb/logo-200x200.png",
  "CBIX-P": "https://assets.coingecko.com/coins/images/16183/thumb/Cubiex.jpg",
  "HELLSING": "https://assets.coingecko.com/coins/images/19795/thumb/hellsing.png",
  "MMAI": "https://assets.coingecko.com/coins/images/27208/thumb/mmai_new_logo.png",
  "TKRN": "https://assets.coingecko.com/coins/images/26848/thumb/20220812_091410.jpg",
  "SHIH-TZU": "https://assets.coingecko.com/coins/images/27330/thumb/IMG_20220916_223352_686.jpg",
  "FHTN": "https://assets.coingecko.com/coins/images/21968/thumb/fishing_town.PNG",
  "1MIL": "https://assets.coingecko.com/coins/images/14990/thumb/4kQ8hRnU_400x400.jpg",
  "F11": "https://assets.coingecko.com/coins/images/21295/thumb/f11.PNG",
  "DHR": "https://assets.coingecko.com/coins/images/21710/thumb/q8jVRGmO_400x400.jpg",
  "TINU": "https://assets.coingecko.com/coins/images/27085/thumb/TELEGRAMINU_logo.png",
  "CHM": "https://assets.coingecko.com/coins/images/13028/thumb/cryptochrome_logo.png",
  "CSM": "https://assets.coingecko.com/coins/images/25774/thumb/CSM_token.png",
  "DRAC": "https://assets.coingecko.com/coins/images/27438/thumb/200X200.png",
  "COB": "https://assets.coingecko.com/coins/images/26243/thumb/Cards_of_BSC_Logo.2def57c0_%281%29.png",
  "ROE": "https://assets.coingecko.com/coins/images/28255/thumb/6J6HQCm.png",
  "BRZH": "https://assets.coingecko.com/coins/images/26213/thumb/BRAYZIN_-_PINK_logo.png",
  "$BABYDOGEINU": "https://assets.coingecko.com/coins/images/17012/thumb/KEtLxnLH_400x400.jpg",
  "EMC1": "https://assets.coingecko.com/coins/images/27086/thumb/emc1.png",
  "WCI": "https://assets.coingecko.com/coins/images/27489/thumb/2022-09-26_20.55.02-removebg-preview.png",
  "DDDX": "https://assets.coingecko.com/coins/images/24938/thumb/200_200.png",
  "CORA": "https://assets.coingecko.com/coins/images/15684/thumb/Corra_C_Logo.png",
  "TITA": "https://assets.coingecko.com/coins/images/20588/thumb/tita.png",
  "CHMB": "https://assets.coingecko.com/coins/images/21686/thumb/_CHMB.png",
  "XPS": "https://assets.coingecko.com/coins/images/21539/thumb/15997.png",
  "PLAT": "https://assets.coingecko.com/coins/images/3083/thumb/PLAT_COIN_PLAT.png",
  "HMDX": "https://assets.coingecko.com/coins/images/18504/thumb/Capture.PNG",
  "WOMI": "https://assets.coingecko.com/coins/images/14675/thumb/ecomi.jpg",
  "PGK": "https://assets.coingecko.com/coins/images/24437/thumb/pgk-logo-200x200.png",
  "MHUB": "https://assets.coingecko.com/coins/images/26529/thumb/MHUB.png",
  "APXP": "https://assets.coingecko.com/coins/images/21542/thumb/APXP-logo_200x200.png",
  "SFMS": "https://assets.coingecko.com/coins/images/16239/thumb/Untitled-design-5.png",
  "WTZ": "https://assets.coingecko.com/coins/images/28016/thumb/KT1PnUZCp3u2KzWr93pn4DD7HAJnm3rWVrgn.png",
  "PEARL": "https://assets.coingecko.com/coins/images/12305/thumb/pearl_finance_logo.png",
  "SFL": "https://assets.coingecko.com/coins/images/27462/thumb/qB-uAxkc_400x400_%281%29.jpg",
  "KHAOS": "https://assets.coingecko.com/coins/images/24881/thumb/logo_icon_200.png",
  "OCAVU": "https://assets.coingecko.com/coins/images/25781/thumb/ocavu.png",
  "INDC": "https://assets.coingecko.com/coins/images/17477/thumb/Untitled-design-6.png",
  "SPROUT": "https://assets.coingecko.com/coins/images/22455/thumb/56z0x6j.png",
  "HSHARES": "https://assets.coingecko.com/coins/images/22172/thumb/QmYPVb2ANXMEaLC1WJSWexztZuRWxEQWu8Pwp1NURk7LSN.png",
  "BLZN": "https://assets.coingecko.com/coins/images/12248/thumb/A8JOuPSJ_400x400.jpg",
  "MAG": "https://assets.coingecko.com/coins/images/27152/thumb/MAG.png",
  "UNIS": "https://assets.coingecko.com/coins/images/10389/thumb/9lTQkX7d_400x400.jpg",
  "OMNOM": "https://assets.coingecko.com/coins/images/26878/thumb/omnom.jpg",
  "VDG": "https://assets.coingecko.com/coins/images/5604/thumb/VDG-ICON.png",
  "AWM": "https://assets.coingecko.com/coins/images/27617/thumb/AnotherWorld.jpeg",
  "SLRM": "https://assets.coingecko.com/coins/images/25255/thumb/SLRM_120X120.png",
  "$BUNIW": "https://assets.coingecko.com/coins/images/27536/thumb/photo_2022-09-25_21.42.03.jpeg",
  "ODYS": "https://assets.coingecko.com/coins/images/28944/thumb/2023-01-23_00.04.47.jpg",
  "DARC": "https://assets.coingecko.com/coins/images/2943/thumb/darctoken.png",
  "COG": "https://assets.coingecko.com/coins/images/27234/thumb/logo200.png",
  "SRC": "https://assets.coingecko.com/coins/images/13027/thumb/SimRacer-500px.png",
  "$WV": "https://assets.coingecko.com/coins/images/28051/thumb/wv_trans_logo_%281%29.png",
  "HCUT": "https://assets.coingecko.com/coins/images/11868/thumb/60633927_588600498303961_3291974703035449344_n.png",
  "XAO": "https://assets.coingecko.com/coins/images/3654/thumb/aSjqdCJ.png",
  "EAT": "https://assets.coingecko.com/coins/images/26268/thumb/dp_YT.jpg",
  "BOULPIK": "https://assets.coingecko.com/coins/images/26871/thumb/BOULPIK_TOKEN.png",
  "AAMMWBTC": "https://assets.coingecko.com/coins/images/17215/thumb/aAMMWBTC_2x.png",
  "STHO": "https://assets.coingecko.com/coins/images/22383/thumb/sTHO_200x200.png",
  "NODL": "https://assets.coingecko.com/coins/images/27019/thumb/nodle.png",
  "PIXIU": "https://assets.coingecko.com/coins/images/17297/thumb/pixiu_picture_.png",
  "GHC": "https://assets.coingecko.com/coins/images/18796/thumb/10L4FB6m_400x400.jpg",
  "CYN": "https://assets.coingecko.com/coins/images/17534/thumb/rmrW1G3.jpg",
  "STB": "https://assets.coingecko.com/coins/images/20707/thumb/-flagDxC_400x400.jpg",
  "DMS": "https://assets.coingecko.com/coins/images/20702/thumb/DMS.png",
  "KRYPTO": "https://assets.coingecko.com/coins/images/15145/thumb/krypto.PNG",
  "DBI": "https://assets.coingecko.com/coins/images/28058/thumb/1Artboard_1_%282%29.png",
  "WTLOS": "https://assets.coingecko.com/coins/images/23952/thumb/tL4cEmvt_400x400.png",
  "LINKK": "https://assets.coingecko.com/coins/images/18539/thumb/0xbeb67de6cc5af652b2d9b0235750ed70f5a2cb0d.png",
  "DOGEP": "https://assets.coingecko.com/coins/images/23689/thumb/LjhKBldd_400x400.png",
  "MTLS": "https://assets.coingecko.com/coins/images/28441/thumb/VI9Adz37_400x400.jpeg",
  "SMCW": "https://assets.coingecko.com/coins/images/24394/thumb/6htFQIdk_400x400.jpg",
  "MASTER": "https://assets.coingecko.com/coins/images/21365/thumb/Logo200x200_%2815%29.png",
  "COMA": "https://assets.coingecko.com/coins/images/28686/thumb/logo_%283%29.png",
  "METAGS": "https://assets.coingecko.com/coins/images/24294/thumb/DdvFfVw.jpeg",
  "WMATIC": "https://assets.coingecko.com/coins/images/14073/thumb/matic.png",
  "HARM": "https://assets.coingecko.com/coins/images/27237/thumb/Untitled_design_%2815%29.png",
  "TDF": "https://assets.coingecko.com/coins/images/19276/thumb/Screenshot-2021-10-21-at-20-29-34.png",
  "ZEFI": "https://assets.coingecko.com/coins/images/15346/thumb/icone_marca.png",
  "TOSHINORI": "https://assets.coingecko.com/coins/images/21068/thumb/2Dp0LWna_400x400.jpg",
  "GMMT": "https://assets.coingecko.com/coins/images/28833/thumb/Gmmt_logo_sym_col.png",
  "FON": "https://assets.coingecko.com/coins/images/25752/thumb/20278.png",
  "HON": "https://assets.coingecko.com/coins/images/23632/thumb/HONOR_icon.png",
  "PACK": "https://assets.coingecko.com/coins/images/22358/thumb/photo_2021-12-03_10-21-14.png",
  "IBGBP": "https://assets.coingecko.com/coins/images/18576/thumb/logo-128_%283%29.png",
  "AAMMUSDT": "https://assets.coingecko.com/coins/images/17200/thumb/aAMMUSDT_2x.png",
  "SCHA": "https://assets.coingecko.com/coins/images/12135/thumb/schain.png",
  "RBTC": "https://assets.coingecko.com/coins/images/5070/thumb/rsk-logo.jpg",
  "BTBS": "https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG",
  "KNIGHTS": "https://assets.coingecko.com/coins/images/27406/thumb/Eldenknights-LOGO.png",
  "BCIO": "https://assets.coingecko.com/coins/images/4168/thumb/BCIO_logo_square_256px.png",
  "ATF": "https://assets.coingecko.com/coins/images/28821/thumb/ATF.png",
  "WTO": "https://assets.coingecko.com/coins/images/28121/thumb/output-onlinepngtools.png",
  "KGL": "https://assets.coingecko.com/coins/images/25789/thumb/1USwzz6C_400x400.png",
  "UNIETH": "https://assets.coingecko.com/coins/images/28477/thumb/uniETH_200.png",
  "SAFO": "https://assets.coingecko.com/coins/images/28042/thumb/3.png",
  "BTCX": "https://assets.coingecko.com/coins/images/5857/thumb/Bitcoinx.png",
  "BTCT": "https://assets.coingecko.com/coins/images/16999/thumb/9486.png",
  "LOPES": "https://assets.coingecko.com/coins/images/28613/thumb/lopes.png",
  "THB": "https://assets.coingecko.com/coins/images/26938/thumb/thb.png",
  "PILLS": "https://assets.coingecko.com/coins/images/18306/thumb/pills.png",
  "SDCRV": "https://assets.coingecko.com/coins/images/27756/thumb/scCRV-2.png",
  "AREA": "https://assets.coingecko.com/coins/images/28764/thumb/photo_2023-01-04_22-48-03.jpg",
  "SOT": "https://assets.coingecko.com/coins/images/28339/thumb/wYXOL79.png",
  "HKC": "https://assets.coingecko.com/coins/images/23433/thumb/C17162-BA-BAC6-4-FB1-847-B-CF956-D18-EB61.jpg",
  "FROGGO": "https://assets.coingecko.com/coins/images/27863/thumb/mutantfroggo-200.jpg",
  "EVD": "https://assets.coingecko.com/coins/images/27785/thumb/evmos-domains.png",
  "BANK$": "https://assets.coingecko.com/coins/images/23017/thumb/C47722-F6-F58-D-4945-AEE0-04020-AB24731.png",
  "LSC": "https://assets.coingecko.com/coins/images/15287/thumb/lsc.PNG",
  "ETOS": "https://assets.coingecko.com/coins/images/18286/thumb/oasis.PNG",
  "APOLLO": "https://assets.coingecko.com/coins/images/22606/thumb/apollo-icon-green-black-w3-200.png",
  "STBB": "https://assets.coingecko.com/coins/images/15187/thumb/stabilize_logo.png",
  "PRESALE": "https://assets.coingecko.com/coins/images/27627/thumb/200x200.png",
  "HDFL": "https://assets.coingecko.com/coins/images/16640/thumb/hdfl.PNG",
  "CFT": "https://assets.coingecko.com/coins/images/26012/thumb/3ade7a8e-3cd3-4764-a343-0d68c05abe65.png",
  "MONS": "https://assets.coingecko.com/coins/images/18382/thumb/Monstersclan_Icon_200X200.png",
  "MTWR": "https://assets.coingecko.com/coins/images/27484/thumb/metawar_200.png",
  "DEXI": "https://assets.coingecko.com/coins/images/18435/thumb/Dexi_Logo_200x200.png",
  "WZEC": "https://assets.coingecko.com/coins/images/13239/thumb/WZEC.png",
  "DIAMONDS": "https://assets.coingecko.com/coins/images/16991/thumb/blackdiamond.jpeg",
  "BABYDOGEZILLA": "https://assets.coingecko.com/coins/images/19612/thumb/200x200-CG.png",
  "DUET": "https://assets.coingecko.com/coins/images/16164/thumb/Duet.jpg",
  "SPACEXDOGE": "https://assets.coingecko.com/coins/images/18406/thumb/Capture.PNG",
  "OKINAMI": "https://assets.coingecko.com/coins/images/26629/thumb/KANAGAWA_%284%29.png",
  "OKINU": "https://assets.coingecko.com/coins/images/27748/thumb/SwJ8JwH.png",
  "WBI": "https://assets.coingecko.com/coins/images/28279/thumb/WBIlogo.png",
  "SM96": "https://assets.coingecko.com/coins/images/28300/thumb/IMG_20221120_122930_391.jpg",
  "1RT": "https://assets.coingecko.com/coins/images/27348/thumb/1RT.png",
  "UV": "https://assets.coingecko.com/coins/images/19673/thumb/13575.png",
  "WPX": "https://assets.coingecko.com/coins/images/10302/thumb/WPX_LOGO_200X200.png",
  "AKTA": "https://assets.coingecko.com/coins/images/26832/thumb/akta.png",
  "OVL": "https://assets.coingecko.com/coins/images/28507/thumb/OVL-black-white-bkgr.png",
  "NOIR": "https://assets.coingecko.com/coins/images/25632/thumb/tOTtZHNb_400x400.jpg",
  "WSTETH": "https://assets.coingecko.com/coins/images/18834/thumb/wstETH.png",
  "DFKGREENEGG": "https://assets.coingecko.com/coins/images/27550/thumb/green-pet-egg.png",
  "EYES": "https://assets.coingecko.com/coins/images/9734/thumb/pTaAZUI6_400x400.jpg",
  "BASED": "https://assets.coingecko.com/coins/images/23507/thumb/based.PNG",
  "BTF": "https://assets.coingecko.com/coins/images/7412/thumb/25354097_138346030206993_1134755924546955396_n.png",
  "SHIKA": "https://assets.coingecko.com/coins/images/27802/thumb/Logo-Shika320.png",
  "LIO": "https://assets.coingecko.com/coins/images/27084/thumb/output-onlinepngtools-55.png",
  "WIOTX": "https://assets.coingecko.com/coins/images/17923/thumb/Njsdvvpv_400x400.jpg",
  "KRS": "https://assets.coingecko.com/coins/images/27678/thumb/KDR.jpg",
  "SBABYDOGE": "https://assets.coingecko.com/coins/images/21436/thumb/logo_%282%29.png",
  "HYFI": "https://assets.coingecko.com/coins/images/14798/thumb/QNvlAIZH_400x400.png",
  "OTR": "https://assets.coingecko.com/coins/images/20157/thumb/VAPiUujl_400x400.png",
  "RCS": "https://assets.coingecko.com/coins/images/27692/thumb/Picsart_22-10-05_23-50-11-490.png",
  "GSYS": "https://assets.coingecko.com/coins/images/27721/thumb/Logo-Genesys.png",
  "GINZA": "https://assets.coingecko.com/coins/images/19906/thumb/GDZGB-Qw_400x400.png",
  "PLD": "https://assets.coingecko.com/coins/images/25092/thumb/logo-pld_200.png",
  "PLAC": "missing_thumb.png",
  "ROBODOGE": "https://assets.coingecko.com/coins/images/17865/thumb/Logo200_%2822%29.png",
  "SWCAT": "https://assets.coingecko.com/coins/images/20899/thumb/star_wars_cat.PNG",
  "KRN": "https://assets.coingecko.com/coins/images/18416/thumb/Je71mCoZ_400x400.png",
  "WBRISE": "https://assets.coingecko.com/coins/images/26461/thumb/EdoeKEln_400x400.jpeg",
  "QIE": "https://assets.coingecko.com/coins/images/27525/thumb/logo.jpg",
  "ROVI": "https://assets.coingecko.com/coins/images/28936/thumb/rovi-coin.png",
  "SWUSD": "https://assets.coingecko.com/coins/images/12918/thumb/swerve.png",
  "WSC": "https://assets.coingecko.com/coins/images/20106/thumb/logo-box.png",
  "XWG": "https://assets.coingecko.com/coins/images/17847/thumb/xworld.PNG",
  "BRNG": "https://assets.coingecko.com/coins/images/17836/thumb/bring_finance.PNG",
  "MILIT": "https://assets.coingecko.com/coins/images/20214/thumb/heroes_militia.PNG",
  "GCAKE": "https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png",
  "TYP": "https://assets.coingecko.com/coins/images/27412/thumb/8xJB5QnY_400x400.jpeg",
  "ZPAINT": "https://assets.coingecko.com/coins/images/19472/thumb/4U3lsZWG_400x400.jpg",
  "GSHIBA": "https://assets.coingecko.com/coins/images/17130/thumb/RHzOHCyb_400x400.jpg",
  "TORII": "https://assets.coingecko.com/coins/images/18718/thumb/torii.PNG",
  "BXS": "https://assets.coingecko.com/coins/images/25644/thumb/81660481.png",
  "WHT": "https://assets.coingecko.com/coins/images/14042/thumb/8524.png",
  "TITI": "https://assets.coingecko.com/coins/images/23775/thumb/Untitled-200-200-px-2.png",
  "ATIS": "https://assets.coingecko.com/coins/images/12112/thumb/atis_token_logo.png",
  "PURSE": "https://assets.coingecko.com/coins/images/20419/thumb/HobiV7k__400x400.jpg",
  "FOY": "https://assets.coingecko.com/coins/images/16648/thumb/foy200.png",
  "NHCT": "https://assets.coingecko.com/coins/images/24974/thumb/624fd8f9e5546b00017c915d_NHCT_icon_3x.png",
  "EYN": "https://assets.coingecko.com/coins/images/26881/thumb/logo-removebg-preview_%282%29_%281%29_%281%29.png",
  "CATVILLS": "https://assets.coingecko.com/coins/images/28400/thumb/JkwPmJ7.png",
  "BHIG": "https://assets.coingecko.com/coins/images/7821/thumb/logstex.PNG",
  "NTG": "https://assets.coingecko.com/coins/images/26778/thumb/IMG_20220809_093430_348.jpg",
  "DDDD": "https://assets.coingecko.com/coins/images/17973/thumb/unnamed_%284%29.png",
  "KOT": "https://assets.coingecko.com/coins/images/22497/thumb/kols.PNG",
  "DOGEALLY": "https://assets.coingecko.com/coins/images/21545/thumb/dogealliance_sq_x200.png",
  "GMNG": "https://assets.coingecko.com/coins/images/12792/thumb/output-onlinepngtools.png_small.png",
  "ODIN": "https://assets.coingecko.com/coins/images/14934/thumb/ODIN_iso_black.png",
  "PRANA": "https://assets.coingecko.com/coins/images/26176/thumb/prANA.png",
  "WCSOV": "https://assets.coingecko.com/coins/images/25179/thumb/9kL0liy4_400x400.jpg",
  "MTVP": "https://assets.coingecko.com/coins/images/25129/thumb/mtvp_200x200.png",
  "NACHO": "https://assets.coingecko.com/coins/images/21635/thumb/NF_nacho_2_200x200.png",
  "USDE": "https://assets.coingecko.com/coins/images/25463/thumb/USDE.png",
  "EXFI": "https://assets.coingecko.com/coins/images/15134/thumb/rGTVPQaD_400x400.jpg",
  "CCP": "https://assets.coingecko.com/coins/images/7127/thumb/PNGLOGO.png",
  "WASTR": "https://assets.coingecko.com/coins/images/26638/thumb/astar.jpeg",
  "FACEMETA": "https://assets.coingecko.com/coins/images/26918/thumb/fmeta.png",
  "CAST": "https://assets.coingecko.com/coins/images/25216/thumb/19705.png",
  "EAD": "https://assets.coingecko.com/coins/images/28203/thumb/8AceqVsW_400x400.jpeg",
  "PIGGIE": "https://assets.coingecko.com/coins/images/27690/thumb/piggie_final512.png",
  "XSOL": "https://assets.coingecko.com/coins/images/20022/thumb/mmJpy6j.png",
  "DMC": "https://assets.coingecko.com/coins/images/14130/thumb/1_hXrVwWTpmRJkPB6Aty0npQ.png",
  "SHIBADOLLARS": "https://assets.coingecko.com/coins/images/22275/thumb/download.png",
  "FETCH": "https://assets.coingecko.com/coins/images/15747/thumb/Mainlogo-200x200-Moonretriever.png",
  "DHD": "https://assets.coingecko.com/coins/images/22234/thumb/dhd.PNG",
  "TERRAFORM": "https://assets.coingecko.com/coins/images/26742/thumb/tf-logo-200x200.png",
  "SMBSWAP": "https://assets.coingecko.com/coins/images/16808/thumb/smb.png",
  "ELCASH": "https://assets.coingecko.com/coins/images/13850/thumb/ELCASH-Logo-transparent.png",
  "MBH": "https://assets.coingecko.com/coins/images/25237/thumb/MBH_LOGO.png",
  "CLASH": "https://assets.coingecko.com/coins/images/23307/thumb/logo_%286%29.png",
  "WOJ": "https://assets.coingecko.com/coins/images/18823/thumb/200x200.png",
  "CISLA": "https://assets.coingecko.com/coins/images/17897/thumb/200x200_CISLA.png",
  "EDEL": "https://assets.coingecko.com/coins/images/26903/thumb/logo_dr_and.png",
  "KODACHI": "https://assets.coingecko.com/coins/images/26927/thumb/logo200_%282%29.png",
  "MVDG": "https://assets.coingecko.com/coins/images/23581/thumb/imgonline-com-ua-Resize-5J7DYgBqxK7I.png",
  "SHBL": "https://assets.coingecko.com/coins/images/16160/thumb/sbl.png",
  "CHAMP": "https://assets.coingecko.com/coins/images/28091/thumb/champ.png",
  "IZI": "https://assets.coingecko.com/coins/images/21791/thumb/izumi-logo-symbol.png",
  "PDF": "https://assets.coingecko.com/coins/images/28827/thumb/photo_2023-01-16_15.08.09.jpeg",
  "WVLX": "https://assets.coingecko.com/coins/images/23900/thumb/AJADWZpI_400x400.png",
  "DCT": "https://assets.coingecko.com/coins/images/20300/thumb/dct200.png",
  "DBEA": "https://assets.coingecko.com/coins/images/25145/thumb/tokenicon200_200.png",
  "ARBIS": "https://assets.coingecko.com/coins/images/18594/thumb/NcAIaWlc_400x400_%281%29.jpg",
  "SUNRISE": "https://assets.coingecko.com/coins/images/19200/thumb/sunrise.PNG",
  "DOTC": "https://assets.coingecko.com/coins/images/22307/thumb/Polka-Classic.png",
  "SRG": "https://assets.coingecko.com/coins/images/27448/thumb/srg.png",
  "BLA": "https://assets.coingecko.com/coins/images/28040/thumb/200x200_blueart_logo.png",
  "DOGEKONGZILLA": "https://assets.coingecko.com/coins/images/19722/thumb/13610.png",
  "DBIO": "https://assets.coingecko.com/coins/images/21562/thumb/dbio.png",
  "INVOX": "https://assets.coingecko.com/coins/images/3200/thumb/invox.png",
  "KOKOA": "https://assets.coingecko.com/coins/images/25431/thumb/FZhc14j__400x400.jpg",
  "AGRI": "https://assets.coingecko.com/coins/images/8716/thumb/agrinovus.png",
  "DEIP": "https://assets.coingecko.com/coins/images/25505/thumb/12738.png",
  "KINGSHIBA": "https://assets.coingecko.com/coins/images/20089/thumb/kingofshiba.PNG",
  "LMCSWAP": "https://assets.coingecko.com/coins/images/20943/thumb/limo.png",
  "PRUF": "https://assets.coingecko.com/coins/images/27482/thumb/prufu-200x200_1.png",
  "RICKMORTYDOXX": "https://assets.coingecko.com/coins/images/19148/thumb/cropped-IMG_20211203_223720_101.png",
  "NINTI": "https://assets.coingecko.com/coins/images/22915/thumb/vlyZNkGr_400x400.jpg",
  "FSK": "https://assets.coingecko.com/coins/images/24317/thumb/qqaS9RUi_400x400.jpg",
  "COUSD": "https://assets.coingecko.com/coins/images/19070/thumb/icon-cousd.png",
  "BISHUFI": "https://assets.coingecko.com/coins/images/19606/thumb/bishu.jpeg",
  "GPS": "https://assets.coingecko.com/coins/images/10020/thumb/gps.png",
  "PFW": "https://assets.coingecko.com/coins/images/20297/thumb/aap4r-tQ_400x400.jpg",
  "DXT": "https://assets.coingecko.com/coins/images/17611/thumb/Dexit_Logo.png",
  "$WOOD": "https://assets.coingecko.com/coins/images/22512/thumb/tokenlogo.png",
  "ONEBTC": "https://assets.coingecko.com/coins/images/15082/thumb/G9oJy1-R.png",
  "KXA": "https://assets.coingecko.com/coins/images/21225/thumb/kxa.png",
  "RASTA": "https://assets.coingecko.com/coins/images/15228/thumb/Rasta_Finance.png",
  "BNBTIGER": "https://assets.coingecko.com/coins/images/22565/thumb/IMG-20220111-212721-633.jpg",
  "DKUMA": "https://assets.coingecko.com/coins/images/26693/thumb/dkumaOG02_CoinGecko.png",
  "DSG": "https://assets.coingecko.com/coins/images/18893/thumb/dsg.PNG",
  "LSTAR": "https://assets.coingecko.com/coins/images/25986/thumb/20581.png",
  "CAND": "https://assets.coingecko.com/coins/images/25030/thumb/CF3qmC_KTVbtMf9aUYs2dTxWr6OIcizowZQTGsM9w_6FwKJM9xsOc2uICQgWqSxWSR5suYbolH6P7V5BQqFlY_gwQfUiqgc9dwnqa9MDf9snNmWNpCGcZ-xUt7dVJBNAlOWbSje54dH__rzoNSb2iMi9gL-qVhda4Xd5r12XVj53e5tOvgWyDTPMP20lC4JjYt_13EU5xBZo529GL0wiHIXagy.jpg",
  "POLLY": "https://assets.coingecko.com/coins/images/18354/thumb/POLLY.png",
  "SUPE": "https://assets.coingecko.com/coins/images/21874/thumb/nF9bWyQe_400x400.jpg",
  "FIFTY": "https://assets.coingecko.com/coins/images/18207/thumb/5150icon.png",
  "SOLDIER": "https://assets.coingecko.com/coins/images/15575/thumb/F63vqcy.png",
  "CYM": "https://assets.coingecko.com/coins/images/24642/thumb/nL86PqSc_400x400.jpg",
  "DGMV": "https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png",
  "KPHI": "https://assets.coingecko.com/coins/images/17696/thumb/XJb3jROS_400x400.jpg",
  "SRD": "https://assets.coingecko.com/coins/images/28023/thumb/9999.png",
  "PLATA": "https://assets.coingecko.com/coins/images/23557/thumb/Mark_logo.png",
  "SHIBC": "https://assets.coingecko.com/coins/images/27241/thumb/1BED03B1-D87F-4E91-B24F-CDED9309D003.jpeg",
  "TEDDY V2": "https://assets.coingecko.com/coins/images/27468/thumb/200X200.png",
  "MYRIA": "https://assets.coingecko.com/coins/images/24685/thumb/-jHXi6y5_400x400.jpg",
  "NASH": "https://assets.coingecko.com/coins/images/6737/thumb/D7F231BA7E294954A0C2C4EBA6BCEFEB.png",
  "BABYTIGER": "https://assets.coingecko.com/coins/images/22811/thumb/logo_babytiger.png",
  "AAMMUSDC": "https://assets.coingecko.com/coins/images/17226/thumb/aAMMUSDC_2x.png",
  "SAK": "https://assets.coingecko.com/coins/images/27225/thumb/SAK.",
  "CODEX": "https://assets.coingecko.com/coins/images/14723/thumb/TWmfx9J.png",
  "HOTDOGE": "https://assets.coingecko.com/coins/images/15404/thumb/525ea2_88a58501323f44c7a806c008ae2b4858_mv2.png",
  "HOLY": "https://assets.coingecko.com/coins/images/28395/thumb/HG_Token_Design-07.png",
  "UMG": "https://assets.coingecko.com/coins/images/18113/thumb/goldmine.PNG",
  "SWELL": "https://assets.coingecko.com/coins/images/28777/thumb/swell.jpeg",
  "LWC": "https://assets.coingecko.com/coins/images/27739/thumb/Linework-logo_coin_200px-01.png",
  "AAMMWETH": "https://assets.coingecko.com/coins/images/17259/thumb/aAMMWETH_2x.png",
  "L2P": "https://assets.coingecko.com/coins/images/11799/thumb/64280570.png",
  "BABY EVERDOGE": "https://assets.coingecko.com/coins/images/16462/thumb/Baby-Ever-Doge-Logo.png",
  "JF": "https://assets.coingecko.com/coins/images/22263/thumb/jswap.PNG",
  "WSTRAX": "https://assets.coingecko.com/coins/images/28561/thumb/stratis.jpeg",
  "SFU": "https://assets.coingecko.com/coins/images/27367/thumb/aPS95YjU_400x400.jpeg",
  "PAYZ": "https://assets.coingecko.com/coins/images/25040/thumb/payz-logo-2x.png",
  "RHLLOR": "https://assets.coingecko.com/coins/images/28100/thumb/logo_200x.png",
  "EVILSQUID": "https://assets.coingecko.com/coins/images/20648/thumb/yiT-rsHE_400x400.jpg",
  "TNDR": "https://assets.coingecko.com/coins/images/26488/thumb/logo_v3.png",
  "MPM": "https://assets.coingecko.com/coins/images/26965/thumb/TkA1Iou8_400x400.jpeg",
  "SEURO": "https://assets.coingecko.com/coins/images/28201/thumb/sEuroLogo.png",
  "CODE": "https://assets.coingecko.com/coins/images/27011/thumb/CHWxD9GV_400x400.jpeg",
  "TAXHAVENINU": "https://assets.coingecko.com/coins/images/27362/thumb/tax_haven_inu.png",
  "QUO": "https://assets.coingecko.com/coins/images/28078/thumb/logo_quoll_asset.png",
  "IBCHF": "https://assets.coingecko.com/coins/images/18577/thumb/logo-128_%284%29.png",
  "GEEK": "https://assets.coingecko.com/coins/images/28553/thumb/logo300black2.png",
  "SEAH": "https://assets.coingecko.com/coins/images/27705/thumb/512.png",
  "MMY": "https://assets.coingecko.com/coins/images/28547/thumb/Logo_MMY.png",
  "BCRO": "https://assets.coingecko.com/coins/images/26198/thumb/bCROlogo.png",
  "OCV": "https://assets.coingecko.com/coins/images/18770/thumb/Icon-01200.png",
  "WFLR": "https://assets.coingecko.com/coins/images/28705/thumb/flare.png",
  "ZIX": "https://assets.coingecko.com/coins/images/27886/thumb/logo_coinzix_200x200_transparent_png.png",
  "LYD": "https://assets.coingecko.com/coins/images/15195/thumb/512_pure_logo.png",
  "XIX": "https://assets.coingecko.com/coins/images/26915/thumb/million-logo.png",
  "ICD": "https://assets.coingecko.com/coins/images/27853/thumb/A%CC%89nh_chu%CC%A3p_Ma%CC%80n_hi%CC%80nh_2022-10-18_lu%CC%81c_09.05.22.png",
  "XFTT": "https://assets.coingecko.com/coins/images/20025/thumb/BGYHyQ4.png",
  "USDA": "https://assets.coingecko.com/coins/images/27198/thumb/arkadiko.jpg",
  "BTAD": "https://assets.coingecko.com/coins/images/5687/thumb/bitcoin-adult.png",
  "DNDB": "https://assets.coingecko.com/coins/images/23938/thumb/logodndb.png",
  "SET": "https://assets.coingecko.com/coins/images/15592/thumb/new_logo_200.png",
  "REWARDS": "https://assets.coingecko.com/coins/images/17563/thumb/rewards.PNG",
  "PEECH": "https://assets.coingecko.com/coins/images/15376/thumb/PEECH-token-logo-200.png",
  "HAMS": "https://assets.coingecko.com/coins/images/18455/thumb/logo_-_2021-09-20T105215.999.png",
  "IFLT": "https://assets.coingecko.com/coins/images/1377/thumb/inflationcoin.png",
  "MOMAT": "https://assets.coingecko.com/coins/images/16910/thumb/moma.PNG",
  "MGN": "https://assets.coingecko.com/coins/images/27340/thumb/Group_2915.jpg",
  "UDOGE": "https://assets.coingecko.com/coins/images/25705/thumb/logo.png",
  "ELVES": "https://assets.coingecko.com/coins/images/22674/thumb/0vch2b2v_400x400.jpg",
  "GC": "https://assets.coingecko.com/coins/images/28270/thumb/200x200_%282%29.png",
  "RND": "https://assets.coingecko.com/coins/images/24256/thumb/rnd.png",
  "PBT": "https://assets.coingecko.com/coins/images/24598/thumb/pbt.png",
  "SHIBDAO": "https://assets.coingecko.com/coins/images/28881/thumb/200x200.png",
  "PZ": "https://assets.coingecko.com/coins/images/28920/thumb/PZ.jpg",
  "XNFT": "https://assets.coingecko.com/coins/images/17472/thumb/xnft.PNG",
  "AVAT": "https://assets.coingecko.com/coins/images/24899/thumb/round.png",
  "COSG": "https://assets.coingecko.com/coins/images/25641/thumb/20193.png",
  "HDBTC": "https://assets.coingecko.com/coins/images/27024/thumb/20220824_132403.png",
  "CHARIZARD": "https://assets.coingecko.com/coins/images/19415/thumb/QXjLCDl.png",
  "CYOP": "https://assets.coingecko.com/coins/images/20672/thumb/cyop.png",
  "MOMO": "https://assets.coingecko.com/coins/images/14846/thumb/200logo.png",
  "GBA": "https://assets.coingecko.com/coins/images/24882/thumb/MbubWG2l_400x400.jpg",
  "WWDOGE": "https://assets.coingecko.com/coins/images/26884/thumb/hb8hQIWP_400x400.jpeg",
  "ORL": "https://assets.coingecko.com/coins/images/27212/thumb/orl-200-removebg-preview.png",
  "KST": "https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png",
  "BASIS": "https://assets.coingecko.com/coins/images/21444/thumb/tkQevyc.png",
  "REB": "https://assets.coingecko.com/coins/images/24805/thumb/rebellion_dao.png",
  "XFC": "https://assets.coingecko.com/coins/images/7107/thumb/xfc.png",
  "BAJU": "https://assets.coingecko.com/coins/images/28550/thumb/RprqQjxf_400x400.jpg",
  "MINIMONGOOSE": "https://assets.coingecko.com/coins/images/21443/thumb/6fpRJII.jpeg",
  "JNS": "https://assets.coingecko.com/coins/images/27115/thumb/200x200.png",
  "DEVIL": "https://assets.coingecko.com/coins/images/22462/thumb/gX3bAoQg_400x400.png",
  "COD": "https://assets.coingecko.com/coins/images/25164/thumb/YWdHwVMAbIlCp5-7vCUp8Clpp4qOvDnD9vmjAioDpwQBJbobhCW0vJa6izVdZfOeeKBzjRrTW8tk1Z2ec9W-qoYQ-PzF5_5z7iSQKpPAmd5GZSaXrPGsvrgANcZaFM4O6MFqqK8ETyOrCM56Z37XGcEGSYKjhq5x_MzrbWqbzh2eKkIKs6DX9xWzVoBKpBOvc7BceSvQ2Hi3s3GgbdB6Huy67J.jpg",
  "DFS": "https://assets.coingecko.com/coins/images/12132/thumb/defis.3316ee78.png",
  "VAN": "https://assets.coingecko.com/coins/images/24814/thumb/E0t1GdwXMAcMoGD.png",
  "PLSD": "https://assets.coingecko.com/coins/images/25327/thumb/Final_Doge.png",
  "HOU": "https://assets.coingecko.com/coins/images/28340/thumb/token.png",
  "SOG": "https://assets.coingecko.com/coins/images/27619/thumb/sog.png",
  "MIFI": "https://assets.coingecko.com/coins/images/26543/thumb/2022-07-07_05.12.37.jpg",
  "HEP": "https://assets.coingecko.com/coins/images/18705/thumb/0xfd83168291312a0800f44610974350c569d12e42.png",
  "ACAP": "https://assets.coingecko.com/coins/images/24044/thumb/gfSKeO3.png",
  "NUSD": "https://assets.coingecko.com/coins/images/21584/thumb/16044.png",
  "LCT": "https://assets.coingecko.com/coins/images/27936/thumb/LT-Logo-200x200_%281%29_%281%29.png",
  "PIPI": "https://assets.coingecko.com/coins/images/14595/thumb/PIPI.png",
  "GOHM": "https://assets.coingecko.com/coins/images/21129/thumb/token_wsOHM_logo.png",
  "DSC": "https://assets.coingecko.com/coins/images/20831/thumb/logo_-_2021-11-24T132426.774.png",
  "HZD": "https://assets.coingecko.com/coins/images/15234/thumb/hzd.png",
  "SHUNAV2": "https://assets.coingecko.com/coins/images/21526/thumb/Untitled-design-15.png",
  "DPR": "https://assets.coingecko.com/coins/images/14748/thumb/deeper.png",
  "TICKET": "https://assets.coingecko.com/coins/images/22756/thumb/PK65p32H_400x400.jpg",
  "XTT": "https://assets.coingecko.com/coins/images/28274/thumb/XTT.png",
  "EPW": "https://assets.coingecko.com/coins/images/21795/thumb/EPW-Icon.png",
  "PSB": "https://assets.coingecko.com/coins/images/27383/thumb/PSB_token.png",
  "RDL": "https://assets.coingecko.com/coins/images/24166/thumb/F96xM49N_400x400.jpg",
  "NIMB": "https://assets.coingecko.com/coins/images/28579/thumb/nimb.png",
  "$JOKE": "https://assets.coingecko.com/coins/images/21854/thumb/XL19JbPU_400x400.jpg",
  "AISC": "https://assets.coingecko.com/coins/images/28916/thumb/logo.jpg",
  "CHER": "https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG",
  "EFF": "https://assets.coingecko.com/coins/images/26890/thumb/EFF_token.png",
  "GNP": "https://assets.coingecko.com/coins/images/18753/thumb/colori.png",
  "SIL": "https://assets.coingecko.com/coins/images/16660/thumb/3Dyt_hYY_400x400.jpg",
  "YYAVAX": "https://assets.coingecko.com/coins/images/27221/thumb/yyAVAX_Token__200x200.png",
  "QA": "https://assets.coingecko.com/coins/images/18194/thumb/QA-logo-coinMarketCap.png",
  "DIGICHAIN": "https://assets.coingecko.com/coins/images/22213/thumb/digichain.png",
  "NEIBR": "https://assets.coingecko.com/coins/images/24929/thumb/TheNeighboursLogo-color_nbyeag.png",
  "RIFI": "https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg",
  "SPACES": "https://assets.coingecko.com/coins/images/22394/thumb/logo.jpg",
  "BLINU": "https://assets.coingecko.com/coins/images/23252/thumb/BabyLamboInuLogo.png",
  "FINA": "https://assets.coingecko.com/coins/images/18970/thumb/favicon.png",
  "POMG": "https://assets.coingecko.com/coins/images/28658/thumb/pomg-logo-cg.png",
  "RIZE": "https://assets.coingecko.com/coins/images/24027/thumb/mqQwSHB__400x400.jpg",
  "CFL365": "https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG",
  "KSHIB": "https://assets.coingecko.com/coins/images/23065/thumb/d0MGsPSY_400x400.jpg",
  "BCL": "https://assets.coingecko.com/coins/images/27932/thumb/DjB0X_rP_400x400.jpeg",
  "CONC": "https://assets.coingecko.com/coins/images/23356/thumb/concrete_codes.PNG",
  "KAIDA": "https://assets.coingecko.com/coins/images/27867/thumb/Dejitaru_Kaida.jpeg",
  "TCS": "https://assets.coingecko.com/coins/images/18677/thumb/0xFbfAE0DD49882e503982f8eb4b8B1e464ecA0b91.png",
  "GOTG": "https://assets.coingecko.com/coins/images/26107/thumb/%EA%B0%93%EC%A7%80%EB%A1%9C%EA%B3%A0.png",
  "BUFFSHIBA": "https://assets.coingecko.com/coins/images/20018/thumb/I9yDn70Y_400x400.png",
  "ETH2SOCKS": "https://assets.coingecko.com/coins/images/17714/thumb/Q4NQZWp.png",
  "ESI": "https://assets.coingecko.com/coins/images/23485/thumb/output-onlinepngtools.png",
  "SEEDED": "https://assets.coingecko.com/coins/images/23618/thumb/seeded.png",
  "PRDS": "https://assets.coingecko.com/coins/images/25368/thumb/prds.png",
  "BABYSHIBAINU": "https://assets.coingecko.com/coins/images/16805/thumb/BabyShiba.png",
  "ZWZ": "https://assets.coingecko.com/coins/images/23749/thumb/zwz.png",
  "WMLX": "https://assets.coingecko.com/coins/images/28703/thumb/wrapped_millix-200x200_300-dpi_GC.png",
  "HELIOS": "https://assets.coingecko.com/coins/images/18295/thumb/helios.png",
  "VOLR": "https://assets.coingecko.com/coins/images/26896/thumb/logo_200x200.png",
  "OURS": "https://assets.coingecko.com/coins/images/24154/thumb/ours.png",
  "FRNT": "https://assets.coingecko.com/coins/images/24120/thumb/Token_Logo_2_720p.png",
  "FEN": "https://assets.coingecko.com/coins/images/24336/thumb/FEN_TOKEN_COIN_edited.png",
  "BAY": "https://assets.coingecko.com/coins/images/28757/thumb/BayMax_Logo.jpg",
  "RPTR": "https://assets.coingecko.com/coins/images/25265/thumb/Vy7-XGjj_400x400.jpg",
  "RATIO": "https://assets.coingecko.com/coins/images/24543/thumb/ratio.png",
  "0XW": "https://assets.coingecko.com/coins/images/26863/thumb/logo_200.png",
  "LOUVRE": "https://assets.coingecko.com/coins/images/16274/thumb/Louvre_Finance_Logo_-_CoinGecko.png",
  "TGL": "https://assets.coingecko.com/coins/images/28605/thumb/The_Guest_List.png",
  "SNOWBALL": "https://assets.coingecko.com/coins/images/15439/thumb/Untitled-design-9.png",
  "JOC": "https://assets.coingecko.com/coins/images/24883/thumb/f2zOnAa4_400x400.jpg",
  "MEFA": "https://assets.coingecko.com/coins/images/21375/thumb/ZBC4FWKR_400x400.jpg",
  "BLIN": "https://assets.coingecko.com/coins/images/25026/thumb/blin.png",
  "SAHU": "https://assets.coingecko.com/coins/images/15750/thumb/HUSKY_H-1024x683-1.png",
  "DXC": "https://assets.coingecko.com/coins/images/15025/thumb/R1A63oDx_400x400.jpg",
  "BCW": "https://assets.coingecko.com/coins/images/25249/thumb/photo_2022-03-01_19-46-40.jpg",
  "$LAB": "https://assets.coingecko.com/coins/images/25176/thumb/Lab_Logo_circular.png",
  "WFTM": "https://assets.coingecko.com/coins/images/16036/thumb/Fantom.png",
  "IMPULSE": "https://assets.coingecko.com/coins/images/13871/thumb/Impulse_by_FDR_200x200.png",
  "BABYSHIB": "https://assets.coingecko.com/coins/images/17004/thumb/iG11kku.png",
  "GIL": "https://assets.coingecko.com/coins/images/22698/thumb/GIL200.png",
  "HMT": "https://assets.coingecko.com/coins/images/16412/thumb/human_protocol.PNG",
  "FOC": "https://assets.coingecko.com/coins/images/15490/thumb/force.PNG",
  "EDR": "https://assets.coingecko.com/coins/images/3683/thumb/0b805574-ef0d-41dc-b518-8d6148bf4716.png",
  "PSR": "https://assets.coingecko.com/coins/images/25871/thumb/171621938-58e12d04-f26e-43f2-9ec3-8c352690defb.png",
  "OWO": "https://assets.coingecko.com/coins/images/8002/thumb/OWC.png",
  "UNQ": "https://assets.coingecko.com/coins/images/21730/thumb/unq.png",
  "CDL": "https://assets.coingecko.com/coins/images/9960/thumb/2ZP5JaIL_400x400.jpg",
  "INAZ": "https://assets.coingecko.com/coins/images/24315/thumb/0tMkBSx9_400x400.jpg",
  "VAP": "https://assets.coingecko.com/coins/images/24549/thumb/rsz-dark-copy.png",
  "O": "https://assets.coingecko.com/coins/images/25670/thumb/photo_2022-05-27_12-45-29.jpg",
  "CBITBOX": "https://assets.coingecko.com/coins/images/26438/thumb/LOGOOOOBITB0X.jpg",
  "MINISHIB": "https://assets.coingecko.com/coins/images/17122/thumb/miniSHIB-new-200.png",
  "SCROOGE JR": "https://assets.coingecko.com/coins/images/26966/thumb/JR_LOGO.PNG",
  "TFMC": "https://assets.coingecko.com/coins/images/26483/thumb/Tap-Fantasy-MC-Logo.png",
  "H2ON": "https://assets.coingecko.com/coins/images/26326/thumb/H2ON_logo_200_200.png",
  "RDX": "https://assets.coingecko.com/coins/images/26737/thumb/-L4oVsFB_400x400.png",
  "CXC": "https://assets.coingecko.com/coins/images/10005/thumb/52457073.png",
  "MTNS": "https://assets.coingecko.com/coins/images/9787/thumb/mylogo.png",
  "G9": "https://assets.coingecko.com/coins/images/21501/thumb/logo_200.png",
  "WMINIMA": "https://assets.coingecko.com/coins/images/29011/thumb/CoinMarketCap_icon_1_%28200x200%29_black.png",
  "XLAB": "https://assets.coingecko.com/coins/images/8508/thumb/CXQJPNyO_400x400.jpg",
  "WOFO": "https://assets.coingecko.com/coins/images/26703/thumb/logo.png",
  "STEAK": "https://assets.coingecko.com/coins/images/25688/thumb/logo_400.png",
  "ECON": "https://assets.coingecko.com/coins/images/26819/thumb/21193.png",
  "XUC": "https://assets.coingecko.com/coins/images/1052/thumb/exchange-union.png",
  "ING": "https://assets.coingecko.com/coins/images/27651/thumb/ing.png",
  "$ATLAS": "https://assets.coingecko.com/coins/images/27096/thumb/C31C7BC1-4D4D-461A-A084-452B64C8648D.jpeg",
  "SHIB0.5": "https://assets.coingecko.com/coins/images/28957/thumb/halfshib.jpg",
  "WBDC": "https://assets.coingecko.com/coins/images/27158/thumb/30293-01_%281%29.png",
  "SWAPP": "https://assets.coingecko.com/coins/images/16216/thumb/swapp.png",
  "SINGLE": "https://assets.coingecko.com/coins/images/22616/thumb/single.png",
  "MR": "https://assets.coingecko.com/coins/images/23490/thumb/MR_Logo.png",
  "BABYDOGECASH": "https://assets.coingecko.com/coins/images/16846/thumb/babydogecash-cmc-logo.png",
  "BAFC": "https://assets.coingecko.com/coins/images/27287/thumb/LOGO-200x200.png",
  "RBF": "https://assets.coingecko.com/coins/images/28768/thumb/Logo.png",
  "WWSHIB": "https://assets.coingecko.com/coins/images/27371/thumb/N0fKTQVS_400x400.jpeg",
  "REDLC": "https://assets.coingecko.com/coins/images/26961/thumb/REDLC-logo.png",
  "EGGT": "https://assets.coingecko.com/coins/images/27418/thumb/kKDBrCqb_400x400.jpeg",
  "GLDN": "https://assets.coingecko.com/coins/images/27529/thumb/33406C30-2F50-45D8-9F75-5B7EAEB23038.jpeg",
  "PRM": "https://assets.coingecko.com/coins/images/28018/thumb/895kAFqE_400x400.jpeg",
  "SHUSKY": "https://assets.coingecko.com/coins/images/16711/thumb/shusky.png",
  "ECOREAL": "https://assets.coingecko.com/coins/images/6431/thumb/golden_leave_in_200_x_200.png",
  "MCOS": "https://assets.coingecko.com/coins/images/27827/thumb/download_%2815%29.png",
  "RTK": "https://assets.coingecko.com/coins/images/25987/thumb/un_8XpAR_400x400.jpg",
  "LPFI": "https://assets.coingecko.com/coins/images/27704/thumb/output-onlinepngtools_%2827%29.png",
  "FMON": "https://assets.coingecko.com/coins/images/27808/thumb/logo_%281%29.png",
  "SELECT": "https://assets.coingecko.com/coins/images/25131/thumb/NRKjS5Cm_400x400.jpg",
  "OHO": "https://assets.coingecko.com/coins/images/27854/thumb/OHO_200_x_200.png",
  "WSTKSM": "https://assets.coingecko.com/coins/images/27858/thumb/wstKSM.png",
  "JEDALS": "https://assets.coingecko.com/coins/images/26117/thumb/YODA_COIN_SWAP_200x200.png",
  "NPM": "https://assets.coingecko.com/coins/images/28235/thumb/Neptune_Mutual_Logo_Icon_Regular_128.png",
  "WOF": "https://assets.coingecko.com/coins/images/27653/thumb/IMG_20221006_235647_271-removebg-preview_%281%29.png",
  "FT": "https://assets.coingecko.com/coins/images/26809/thumb/CtH9UlRl_400x400.jpeg",
  "SWISSNFTFUND": "https://assets.coingecko.com/coins/images/28221/thumb/LogoSNF200x200.jpg",
  "SOLPAD": "https://assets.coingecko.com/coins/images/16368/thumb/solpad.PNG",
  "HRO": "https://assets.coingecko.com/coins/images/20952/thumb/zQA_yvEK_400x400.jpg",
  "DAISY": "https://assets.coingecko.com/coins/images/17844/thumb/daisy.PNG",
  "CHTS": "https://assets.coingecko.com/coins/images/24203/thumb/CH-TOKEN-200x200.png",
  "DRB": "https://assets.coingecko.com/coins/images/28429/thumb/logo_DRB%281%29.png",
  "FLIGHT": "https://assets.coingecko.com/coins/images/28647/thumb/IO74iBmD_200x200-4.png",
  "GYOKUTO": "https://assets.coingecko.com/coins/images/28502/thumb/200px.png",
  "FPS": "https://assets.coingecko.com/coins/images/21725/thumb/RXvVSpD4_400x400.jpg",
  "LIQD": "https://assets.coingecko.com/coins/images/27056/thumb/liqd.png",
  "REGU": "https://assets.coingecko.com/coins/images/20797/thumb/W1byu.png",
  "IMC": "https://assets.coingecko.com/coins/images/20178/thumb/imc_Logo.png",
  "SIMPLI": "https://assets.coingecko.com/coins/images/21264/thumb/iT_XqHrd_400x400.jpg",
  "MSZ": "https://assets.coingecko.com/coins/images/19941/thumb/msb.png",
  "KNT": "https://assets.coingecko.com/coins/images/28680/thumb/nK_ijQXW_400x400.jpg",
  "MOONSHOT": "https://assets.coingecko.com/coins/images/15221/thumb/JOmzmvag_400x400.jpg",
  "GS1": "https://assets.coingecko.com/coins/images/23583/thumb/-up9F4vj_400x400.jpg",
  "MRXB": "https://assets.coingecko.com/coins/images/20763/thumb/Copy_of_Metrix_Logo_Final_Cut.png",
  "PPUG": "https://assets.coingecko.com/coins/images/19574/thumb/zpWavjSG_400x400.jpg",
  "KRX": "https://assets.coingecko.com/coins/images/21249/thumb/257857376_3111326609090037_1514615544865128134_n.png",
  "NELO": "https://assets.coingecko.com/coins/images/19895/thumb/NELO_Logo_%28200_A%CC%83__200px%29.png",
  "NX": "https://assets.coingecko.com/coins/images/21967/thumb/new-logo.png",
  "CBTC": "https://assets.coingecko.com/coins/images/6384/thumb/bitclassic-logo.png",
  "UPXAU": "https://assets.coingecko.com/coins/images/13534/thumb/UPXAU-1.png",
  "LGX": "https://assets.coingecko.com/coins/images/21816/thumb/1_%284%29.png",
  "SCARAB": "https://assets.coingecko.com/coins/images/22033/thumb/scarab-logo.d6fea1b1.png",
  "MAPE": "https://assets.coingecko.com/coins/images/24456/thumb/ivtx5hHy_400x400.jpg",
  "WNRG": "https://assets.coingecko.com/coins/images/25462/thumb/Energi.jpeg",
  "VSL": "https://assets.coingecko.com/coins/images/28208/thumb/Vetter-Skylabs-200x200.png",
  "LGC": "https://assets.coingecko.com/coins/images/28197/thumb/lgclogo_final-200px.png",
  "HLD": "https://assets.coingecko.com/coins/images/24670/thumb/YFYHhGlJ_400x400.jpg",
  "KILLER": "https://assets.coingecko.com/coins/images/26102/thumb/IMG_20220621_220937_201.png",
  "DFW": "https://assets.coingecko.com/coins/images/24671/thumb/dfw.jpg",
  "WSDQ": "https://assets.coingecko.com/coins/images/21268/thumb/Dn1G5rLx_400x400.jpg",
  "WTWOOL": "https://assets.coingecko.com/coins/images/24680/thumb/IMG-20220327-203818-706.jpg",
  "JUMBO": "https://assets.coingecko.com/coins/images/25558/thumb/jumbo.png",
  "DHG": "https://assets.coingecko.com/coins/images/22314/thumb/dhg.PNG",
  "LQ": "https://assets.coingecko.com/coins/images/23610/thumb/lq.png",
  "HAVEN": "https://assets.coingecko.com/coins/images/17453/thumb/haven.png",
  "SRS": "https://assets.coingecko.com/coins/images/25007/thumb/b3NsEhJE_400x400.jpg",
  "WANATHA": "https://assets.coingecko.com/coins/images/12690/thumb/CrZ8h9FV_400x400.png",
  "RRT": "https://assets.coingecko.com/coins/images/22858/thumb/NyAwD0yH.png",
  "SYA": "https://assets.coingecko.com/coins/images/15440/thumb/syaxflr.png",
  "IM": "https://assets.coingecko.com/coins/images/28930/thumb/IM_Logo_200x200.png",
  "ZBC": "https://assets.coingecko.com/coins/images/24342/thumb/zbc.PNG",
  "LMDA": "https://assets.coingecko.com/coins/images/28653/thumb/XFAIIV1f_400x400.jpg",
  "EVS": "https://assets.coingecko.com/coins/images/25408/thumb/RBX_Symbol.png",
  "COFFIN": "https://assets.coingecko.com/coins/images/19069/thumb/icon-coffin.png",
  "BONES": "https://assets.coingecko.com/coins/images/25025/thumb/JEepabYB_400x400.png",
  "PONT": "https://assets.coingecko.com/coins/images/28152/thumb/pont.png",
  "HOOF": "https://assets.coingecko.com/coins/images/25397/thumb/hoof.png",
  "PRP": "https://assets.coingecko.com/coins/images/19605/thumb/LOGO_PRP_200x200.png",
  "GOLDY": "https://assets.coingecko.com/coins/images/25647/thumb/GODLY.png",
  "THUNDERADA": "https://assets.coingecko.com/coins/images/17950/thumb/logo-thunderada200x200.png",
  "MONSTER": "https://assets.coingecko.com/coins/images/18066/thumb/logo200_%2823%29.png",
  "UGT": "https://assets.coingecko.com/coins/images/17943/thumb/11292.png",
  "HIBIKI": "https://assets.coingecko.com/coins/images/17936/thumb/logo_%281%29.png",
  "GUARD": "https://assets.coingecko.com/coins/images/17995/thumb/LS_wolfDen_logo.0025_Light_200x200.png",
  "WKFX": "missing_thumb.png",
  "FSD": "https://assets.coingecko.com/coins/images/27040/thumb/200x200px.png",
  "MXGP": "https://assets.coingecko.com/coins/images/26011/thumb/b2536e22-82b8-4cb4-b5ca-8aa667569d3e.png",
  "NBM": "https://assets.coingecko.com/coins/images/17606/thumb/200_%289%29.png",
  "MINIBABYDOGE": "https://assets.coingecko.com/coins/images/16970/thumb/minibabydoge.PNG",
  "JW": "https://assets.coingecko.com/coins/images/26143/thumb/jw.png",
  "KFI": "https://assets.coingecko.com/coins/images/15022/thumb/klever.png",
  "ICA": "https://assets.coingecko.com/coins/images/16325/thumb/Icarusnetwork_200-200.png",
  "ZNT": "https://assets.coingecko.com/coins/images/6452/thumb/3CGDn4e.png",
  "GLORY": "https://assets.coingecko.com/coins/images/27747/thumb/logo_200px.png",
  "IRISTOKEN": "https://assets.coingecko.com/coins/images/26518/thumb/200x200.png",
  "SHIELDNET": "https://assets.coingecko.com/coins/images/14909/thumb/ShieldNet.png",
  "DOGPAD": "https://assets.coingecko.com/coins/images/28845/thumb/DogPad.png",
  "MIRROR": "https://assets.coingecko.com/coins/images/27955/thumb/Mirror_Logo_Icon.png",
  "BAIL": "https://assets.coingecko.com/coins/images/25787/thumb/hammer.png",
  "ROEMP": "https://assets.coingecko.com/coins/images/26460/thumb/photo_2022-07-14_23-44-12.jpg",
  "OMEN": "https://assets.coingecko.com/coins/images/16573/thumb/omen.PNG",
  "HPPOT": "https://assets.coingecko.com/coins/images/16097/thumb/hppot.JPG",
  "MAYP": "https://assets.coingecko.com/coins/images/8081/thumb/2u0OXhT9_400x400.png",
  "AAC": "https://assets.coingecko.com/coins/images/2577/thumb/acute-angle-cloud.png",
  "POI$ON": "https://assets.coingecko.com/coins/images/28630/thumb/poisonlogo160x160.png",
  "AMAZINGTEAM": "https://assets.coingecko.com/coins/images/25064/thumb/EXlV29q.jpg",
  "GS": "https://assets.coingecko.com/coins/images/14784/thumb/gs.png",
  "SRLY": "https://assets.coingecko.com/coins/images/23239/thumb/srly.png",
  "GNC": "https://assets.coingecko.com/coins/images/12858/thumb/GNC_asset.png",
  "DART": "https://assets.coingecko.com/coins/images/14834/thumb/dart.PNG",
  "UTT": "https://assets.coingecko.com/coins/images/1741/thumb/utt.png",
  "LTCU": "https://assets.coingecko.com/coins/images/912/thumb/litecoin-ultra.png",
  "PERX": "https://assets.coingecko.com/coins/images/12091/thumb/2AyoUJyQ_400x400.jpg",
  "CCAKE": "https://assets.coingecko.com/coins/images/14547/thumb/CCAKElogo.png",
  "KMW": "https://assets.coingecko.com/coins/images/11534/thumb/JDDp73I5_400x400.jpg",
  "HDG": "https://assets.coingecko.com/coins/images/25482/thumb/hdg.png",
  "SFUSD": "https://assets.coingecko.com/coins/images/28449/thumb/200x200_PNG.png",
  "OMP": "https://assets.coingecko.com/coins/images/27731/thumb/OMP_%28200x200%29.png",
  "2GCC": "https://assets.coingecko.com/coins/images/27514/thumb/Ojh3Dm3-_400x400.jpeg",
  "HLTC": "https://assets.coingecko.com/coins/images/14110/thumb/HLTC.png",
  "VTR": "https://assets.coingecko.com/coins/images/28738/thumb/PvKkGuRiRdK9hHzU37Tw_3FPB3ZpbuiVHg3rn.png",
  "CCOIN": "https://assets.coingecko.com/coins/images/28026/thumb/363216.png",
  "MVS": "https://assets.coingecko.com/coins/images/22135/thumb/logo-200-white.png",
  "KIMCHI": "https://assets.coingecko.com/coins/images/12302/thumb/VBa2Z60o_400x400.png",
  "3CRV": "https://assets.coingecko.com/coins/images/12972/thumb/3pool_128.png",
  "OCEANSV2": "https://assets.coingecko.com/coins/images/27652/thumb/X-kvuVQ1_400x400_%281%29.jpeg",
  "VLT": "https://assets.coingecko.com/coins/images/11638/thumb/vlt-200.png",
  "INFLEX": "https://assets.coingecko.com/coins/images/15701/thumb/inflex.PNG",
  "GGM": "https://assets.coingecko.com/coins/images/22740/thumb/izylmhLV_400x400.png",
  "UMBR": "https://assets.coingecko.com/coins/images/14712/thumb/RX7VJg6.png",
  "GOLD 1": "https://assets.coingecko.com/coins/images/27290/thumb/teh_golden_one_200x200.png",
  "DUCKIES": "https://assets.coingecko.com/coins/images/27630/thumb/Icon_coin_200px.png",
  "DOGECOIN": "https://assets.coingecko.com/coins/images/18516/thumb/BUFF_KOIN.png",
  "WSCRT": "https://assets.coingecko.com/coins/images/13767/thumb/Secret_S_Black_Coingecko.png",
  "CSIX": "https://assets.coingecko.com/coins/images/28905/thumb/csix.png",
  "PSDNOCEAN": "https://assets.coingecko.com/coins/images/27859/thumb/psdnOCEAN.png",
  "SOLPAY": "https://assets.coingecko.com/coins/images/22766/thumb/1_wTkhKXbxhBQxR1BW5WKSNA.png",
  "XRPH": "https://assets.coingecko.com/coins/images/28982/thumb/favicon.png",
  "MNSTRS": "https://assets.coingecko.com/coins/images/18248/thumb/MNSTRS_coingecko.png",
  "VSN": "https://assets.coingecko.com/coins/images/9029/thumb/photo_2020-08-14_20-34-55.jpg",
  "HOHOHO": "https://assets.coingecko.com/coins/images/23936/thumb/18344.png",
  "DQUICK": "https://assets.coingecko.com/coins/images/15185/thumb/quickswap.png",
  "DAVOS": "https://assets.coingecko.com/coins/images/28775/thumb/Token_Logo.png",
  "USHIBA": "https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG",
  "BSTS": "https://assets.coingecko.com/coins/images/18189/thumb/jqhZlCH.png",
  "$BDGV": "https://assets.coingecko.com/coins/images/28110/thumb/IMG_20221102_101102_540.jpg",
  "MSN": "https://assets.coingecko.com/coins/images/18765/thumb/Maison_200x200.png",
  "ARNC": "https://assets.coingecko.com/coins/images/28194/thumb/ARNC_200.png",
  "NEW": "https://assets.coingecko.com/coins/images/4376/thumb/logo.png",
  "KNG": "https://assets.coingecko.com/coins/images/21188/thumb/kanga.PNG",
  "DPDBC": "https://assets.coingecko.com/coins/images/24713/thumb/iShot2022-03-31_10.48.23.png",
  "PMX": "https://assets.coingecko.com/coins/images/28344/thumb/Primex_Finance.jpg",
  "WGL": "https://assets.coingecko.com/coins/images/19361/thumb/wg_logo.png",
  "NINJA": "https://assets.coingecko.com/coins/images/18442/thumb/ninja.PNG",
  "MORPHO": "https://assets.coingecko.com/coins/images/28541/thumb/MORPHO_NETWORK_LOGO.PNG",
  "FUR": "https://assets.coingecko.com/coins/images/19351/thumb/photo_2021-08-18_12-05-50.jpg",
  "EUSD": "https://assets.coingecko.com/coins/images/28445/thumb/eusd.png",
  "HDOT": "https://assets.coingecko.com/coins/images/14107/thumb/hdot.png",
  "MGA": "https://assets.coingecko.com/coins/images/20947/thumb/mga.png",
  "METR": "https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png",
  "CTP": "https://assets.coingecko.com/coins/images/22256/thumb/dchSzDny_400x400.jpg",
  "UCAP": "https://assets.coingecko.com/coins/images/13300/thumb/unicap256.png",
  "HETH": "https://assets.coingecko.com/coins/images/14104/thumb/ETH.a13273b3.png",
  "RMW": "https://assets.coingecko.com/coins/images/28615/thumb/chyksjm.png",
  "DEM": "https://assets.coingecko.com/coins/images/272/thumb/deutsche_emark.png",
  "METABOT": "https://assets.coingecko.com/coins/images/23629/thumb/logo-e1640961358255.jpg",
  "MONX": "https://assets.coingecko.com/coins/images/22742/thumb/icon2_200_200.png",
  "BNB2.0": "https://assets.coingecko.com/coins/images/27753/thumb/7D3C8EC8-CA17-4AC6-8437-C5A1CBEAA784.png",
  "SCV": "https://assets.coingecko.com/coins/images/8840/thumb/Hpio6etr_400x400.jpg",
  "SHIBEV": "https://assets.coingecko.com/coins/images/22851/thumb/photo_2022-01-20-16.00.15.jpeg",
  "SFZ": "https://assets.coingecko.com/coins/images/22483/thumb/BXZ1CNbO_400x400.jpg",
  "DIYAR": "https://assets.coingecko.com/coins/images/22466/thumb/NHeXj778_400x400.jpg",
  "BFIRE": "https://assets.coingecko.com/coins/images/22398/thumb/17042.png",
  "ELEPHANT": "https://assets.coingecko.com/coins/images/15549/thumb/elephant-200.png",
  "NUT": "https://assets.coingecko.com/coins/images/8329/thumb/Native_Utility_Token.png",
  "CVZ": "https://assets.coingecko.com/coins/images/22187/thumb/Copy_of_Copy_of_Logo_without_background%281%29.png",
  "BTOP": "https://assets.coingecko.com/coins/images/22129/thumb/GqSruKVo_400x400.jpg",
  "WCT": "https://assets.coingecko.com/coins/images/1237/thumb/waves-community-token.png",
  "LAY": "https://assets.coingecko.com/coins/images/25795/thumb/WKBrkX4y_400x400.png",
  "DKKS": "https://assets.coingecko.com/coins/images/18930/thumb/Logo_200x200.png",
  "DSF": "https://assets.coingecko.com/coins/images/27736/thumb/dsf.png",
  "ASNT": "https://assets.coingecko.com/coins/images/24959/thumb/asnt.png",
  "ANML": "https://assets.coingecko.com/coins/images/22869/thumb/ANML_LogoAsset.png",
  "CHT": "https://assets.coingecko.com/coins/images/23333/thumb/LusnySJq_400x400.jpg",
  "PRINTS": "https://assets.coingecko.com/coins/images/18143/thumb/prints.png",
  "WRAP": "https://assets.coingecko.com/coins/images/19465/thumb/wrap.png",
  "CIFI": "https://assets.coingecko.com/coins/images/24255/thumb/cifi.png",
  "XBOO": "https://assets.coingecko.com/coins/images/22609/thumb/xboo.png",
  "ZERI_V2": "https://assets.coingecko.com/coins/images/24406/thumb/mYGuqXz.png",
  "MOOLAH": "https://assets.coingecko.com/coins/images/17588/thumb/200-moolah-logo.png",
  "HIDEOUS": "https://assets.coingecko.com/coins/images/19999/thumb/nL_0yV_P_400x400.jpg",
  "DEMIR": "https://assets.coingecko.com/coins/images/21789/thumb/16300.png",
  "GDL": "https://assets.coingecko.com/coins/images/15245/thumb/omADl9QG_400x400.png",
  "RENBTCCURVE": "https://assets.coingecko.com/coins/images/11957/thumb/Curvefi_renCrv_32.png",
  "UIM": "https://assets.coingecko.com/coins/images/21415/thumb/FULL.png",
  "MMTKN": "https://assets.coingecko.com/coins/images/27467/thumb/MMTKNLogo.png",
  "SAUDISHIB": "https://assets.coingecko.com/coins/images/26708/thumb/FvmN7Wa.png",
  "GDT": "https://assets.coingecko.com/coins/images/15335/thumb/gdt.PNG",
  "COOOM": "https://assets.coingecko.com/coins/images/18988/thumb/cooom.png",
  "CAC": "https://assets.coingecko.com/coins/images/22570/thumb/Cosmic-Ape-Logosc.png",
  "INFI": "https://assets.coingecko.com/coins/images/13727/thumb/logo_%287%29.png",
  "CRNCHY": "https://assets.coingecko.com/coins/images/19463/thumb/crunch.jpeg",
  "HIKARI": "https://assets.coingecko.com/coins/images/28953/thumb/HIKARI.png",
  "TGO": "https://assets.coingecko.com/coins/images/27538/thumb/Avatar_%281%29.png",
  "AGSPAD": "https://assets.coingecko.com/coins/images/19013/thumb/mnbTEPws_400x400.jpg",
  "MCC": "https://assets.coingecko.com/coins/images/21621/thumb/mcc.png",
  "DIMI": "https://assets.coingecko.com/coins/images/14288/thumb/dimi_logo_1000px.png",
  "PRGD": "https://assets.coingecko.com/coins/images/26664/thumb/PRGD-Icon-001_%281%29.png",
  "WSYS": "https://assets.coingecko.com/coins/images/23916/thumb/xDiIFaKO_400x400.jpg",
  "LILY": "https://assets.coingecko.com/coins/images/25611/thumb/logo200.png",
  "COLOS": "https://assets.coingecko.com/coins/images/21350/thumb/15800.png",
  "KANA": "https://assets.coingecko.com/coins/images/17981/thumb/koa.PNG",
  "MEB": "https://assets.coingecko.com/coins/images/21885/thumb/create-meblox.png",
  "MOONPOT": "https://assets.coingecko.com/coins/images/28888/thumb/IMG_20230130_210408.png",
  "HARL": "https://assets.coingecko.com/coins/images/21472/thumb/harmony_launcher.PNG",
  "LTNM": "https://assets.coingecko.com/coins/images/20780/thumb/10386.png",
  "BTMC": "https://assets.coingecko.com/coins/images/6177/thumb/9ecCxf_s_400x400.jpg",
  "BRAIN": "https://assets.coingecko.com/coins/images/28966/thumb/200x200.png",
  "MOM": "https://assets.coingecko.com/coins/images/22388/thumb/bQ1PGpaR_400x400.jpg",
  "TNET": "https://assets.coingecko.com/coins/images/8884/thumb/KaOxz76RQ6zN_TtgRxM1LqIM4F4lp3FgE5l-gvZkMuXPe4-IdcoJsT6IDk1d7DMlYdKZbWHaywL_OGU26zjvYLPkaWdwJTreBF3B7-d60BT4kzXNczHwktdsXJvCZCHGZtNUqGBM9jgeXkQR3_Aajo9dugwjuNwGELytKNF9LlV-u1YreUxaLrIZrO4yarUZ9BtdJYqFiOigMdNbv.jpg",
  "BSH": "https://assets.coingecko.com/coins/images/22786/thumb/IMG-20220117-175559-343.png",
  "MLX": "https://assets.coingecko.com/coins/images/26939/thumb/meta.png",
  "$BMINU": "https://assets.coingecko.com/coins/images/27176/thumb/JMpESsLL_400x400.jpeg",
  "TMDS": "https://assets.coingecko.com/coins/images/21641/thumb/tmds.jpg",
  "PINETWORKDEFI": "https://assets.coingecko.com/coins/images/25046/thumb/logo.png",
  "BOKU": "https://assets.coingecko.com/coins/images/20820/thumb/ZdgsxFPV_400x400.png",
  "MEG": "https://assets.coingecko.com/coins/images/28242/thumb/meg.png",
  "ILA": "https://assets.coingecko.com/coins/images/20883/thumb/infinite_launch.PNG",
  "WETC": "https://assets.coingecko.com/coins/images/27126/thumb/1321.png",
  "MTW": "https://assets.coingecko.com/coins/images/22361/thumb/IMG-20220107-151227-500.jpg",
  "CROISSANT": "https://assets.coingecko.com/coins/images/22751/thumb/croissant200x200.png",
  "GFS": "https://assets.coingecko.com/coins/images/25403/thumb/gfs.png",
  "BIPS": "https://assets.coingecko.com/coins/images/15556/thumb/bips_logo_200x200_Trans.png",
  "SILV": "https://assets.coingecko.com/coins/images/24171/thumb/SILV_Logo_%28transparent_circle%29.png",
  "DOGEZ": "https://assets.coingecko.com/coins/images/20511/thumb/dogezilla.png",
  "NSTE": "https://assets.coingecko.com/coins/images/19142/thumb/nst.jpg",
  "VP": "https://assets.coingecko.com/coins/images/28122/thumb/vp.png",
  "NFTPUNK": "https://assets.coingecko.com/coins/images/15544/thumb/200x200%281%29.png",
  "NDP": "https://assets.coingecko.com/coins/images/27798/thumb/nnsdao-200.png",
  "WWE": "https://assets.coingecko.com/coins/images/28361/thumb/IMG_20221130_044817_342.jpg",
  "CSOV": "https://assets.coingecko.com/coins/images/22711/thumb/csov.PNG",
  "MLD": "https://assets.coingecko.com/coins/images/24979/thumb/15b0799ba4867021261e17c538691e9d.png",
  "WSTA": "https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png",
  "SAFEDOGE": "https://assets.coingecko.com/coins/images/25259/thumb/My_Post__57_-removebg-preview.png",
  "AFIB": "https://assets.coingecko.com/coins/images/13433/thumb/AFIB.png",
  "SWDB": "https://assets.coingecko.com/coins/images/28715/thumb/swdb_200x200.png",
  "BEX": "https://assets.coingecko.com/coins/images/25470/thumb/bex.png",
  "AWG": "https://assets.coingecko.com/coins/images/24621/thumb/AWGold_symbol__%281%29.png",
  "TKING": "https://assets.coingecko.com/coins/images/15605/thumb/tigerking.png",
  "CR": "https://assets.coingecko.com/coins/images/28310/thumb/Chromium.png",
  "BLOOD": "https://assets.coingecko.com/coins/images/25527/thumb/RV4nKK2RDO47XzfSLgGs_llgXivhdfpI3tso0.png",
  "SCARY": "https://assets.coingecko.com/coins/images/28056/thumb/IMG_20221025_030416_451-removebg-preview_%282%29.png",
  "FGD": "https://assets.coingecko.com/coins/images/24928/thumb/logo.png",
  "BASHTANK": "https://assets.coingecko.com/coins/images/15390/thumb/IqTGmFXy_400x400.jpg",
  "BCI": "https://assets.coingecko.com/coins/images/21693/thumb/BCI.png",
  "KHALIFA": "https://assets.coingecko.com/coins/images/18944/thumb/khalifa.png",
  "WCFX": "https://assets.coingecko.com/coins/images/25557/thumb/conflux.png",
  "FLOV": "https://assets.coingecko.com/coins/images/23311/thumb/flov.png",
  "UDT": "https://assets.coingecko.com/coins/images/14545/thumb/unlock.jpg",
  "HEE": "https://assets.coingecko.com/coins/images/26569/thumb/HEE.png",
  "BISKIT": "https://assets.coingecko.com/coins/images/28998/thumb/32yqZq_d_400x400_%281%29.png",
  "FLOC": "https://assets.coingecko.com/coins/images/28396/thumb/Christmas_Floki.png",
  "LZN": "https://assets.coingecko.com/coins/images/25165/thumb/cmclznlogo200x.png",
  "UUSDC": "https://assets.coingecko.com/coins/images/13781/thumb/uUSDC.png",
  "MEER": "https://assets.coingecko.com/coins/images/24984/thumb/34613203.png",
  "ROCKS": "https://assets.coingecko.com/coins/images/15432/thumb/rocks-logo-black.png",
  "SWC": "https://assets.coingecko.com/coins/images/28054/thumb/logo.jpg",
  "DLF": "https://assets.coingecko.com/coins/images/25497/thumb/200.png",
  "GALGO": "https://assets.coingecko.com/coins/images/26824/thumb/galgo.png",
  "VNLA": "https://assets.coingecko.com/coins/images/13223/thumb/vanilla_network_logo.jpeg",
  "DDRT": "https://assets.coingecko.com/coins/images/10415/thumb/8WbpGRw.png",
  "NDEFI": "https://assets.coingecko.com/coins/images/18317/thumb/nDEFI.png",
  "XDSHARE": "https://assets.coingecko.com/coins/images/25964/thumb/xdshare.png",
  "GEFI": "https://assets.coingecko.com/coins/images/27592/thumb/IMG_20220930_231934_010_%281%29.jpg",
  "JITOSOL": "https://assets.coingecko.com/coins/images/28046/thumb/JitoSOL-200.png",
  "RBTR": "https://assets.coingecko.com/coins/images/26904/thumb/rbtr.png",
  "VT": "https://assets.coingecko.com/coins/images/25186/thumb/200x.png",
  "DLEGENDS": "https://assets.coingecko.com/coins/images/18006/thumb/my_defi_legends.png",
  "SHG": "https://assets.coingecko.com/coins/images/21650/thumb/shg.png",
  "WAG8": "https://assets.coingecko.com/coins/images/15182/thumb/ag8.jpg",
  "FIWT": "https://assets.coingecko.com/coins/images/20716/thumb/FIWT-Logo.png",
  "SBC": "https://assets.coingecko.com/coins/images/27868/thumb/SBC.png",
  "COAPE": "https://assets.coingecko.com/coins/images/21372/thumb/cofape.PNG",
  "CNTO": "https://assets.coingecko.com/coins/images/29012/thumb/CLR.png",
  "XYA": "https://assets.coingecko.com/coins/images/17017/thumb/logo_gfx.png",
  "STK": "https://assets.coingecko.com/coins/images/21802/thumb/h6l-Gia8_400x400.jpg",
  "MATICPAD": "https://assets.coingecko.com/coins/images/24421/thumb/ILN63o4w_400x400.jpg",
  "XGLI": "https://assets.coingecko.com/coins/images/23292/thumb/glitter_finance.png",
  "PCR": "https://assets.coingecko.com/coins/images/23658/thumb/8pZI6RZG_400x400.jpg",
  "NPI": "https://assets.coingecko.com/coins/images/18000/thumb/logo-npi.png",
  "DMR": "https://assets.coingecko.com/coins/images/18307/thumb/DMR_SYMBOL_OFFICIAL_200X200.png",
  "PBAR": "https://assets.coingecko.com/coins/images/28884/thumb/PBAR_token.png",
  "CGANG": "https://assets.coingecko.com/coins/images/23740/thumb/cgang.png",
  "MEGALAND": "https://assets.coingecko.com/coins/images/20983/thumb/dud5mv4kNaj1PZwSQ3Eazt5voNIycBwyhJQFaXdE.png",
  "DDL": "https://assets.coingecko.com/coins/images/20871/thumb/0inlfwNU_400x400.jpg",
  "CWV": "https://assets.coingecko.com/coins/images/6570/thumb/s-ZJaJdw_400x400.jpg",
  "OKDOT1": "https://assets.coingecko.com/coins/images/26886/thumb/OMulWdJL_400x400.jpeg",
  "WPCI": "https://assets.coingecko.com/coins/images/24390/thumb/new_logo_square_%281%29.png",
  "GRPFT": "https://assets.coingecko.com/coins/images/17002/thumb/logo-square-1.png",
  "RAV": "https://assets.coingecko.com/coins/images/25487/thumb/rav200.png",
  "PETN": "https://assets.coingecko.com/coins/images/17615/thumb/Capture.PNG",
  "BRS": "https://assets.coingecko.com/coins/images/25020/thumb/OnV-HqI1_400x400.jpg",
  "SOCIETY": "https://assets.coingecko.com/coins/images/27824/thumb/download_%2812%29.png",
  "XSHIB": "https://assets.coingecko.com/coins/images/28534/thumb/Qf4TU5kH_400x400.png",
  "CMCX": "https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png",
  "COPYCAT": "https://assets.coingecko.com/coins/images/18276/thumb/copycat.PNG",
  "OCTANE": "https://assets.coingecko.com/coins/images/18521/thumb/octane-launcher-black.png",
  "ELONC": "https://assets.coingecko.com/coins/images/27099/thumb/Dogelon_Classic_Logo_200.png",
  "SWERVE": "https://assets.coingecko.com/coins/images/20858/thumb/94614403.png",
  "LDN": "https://assets.coingecko.com/coins/images/13372/thumb/LudenaProtocol_symbol_200x200.png",
  "GPO": "https://assets.coingecko.com/coins/images/23813/thumb/15462.png",
  "KKT": "https://assets.coingecko.com/coins/images/22598/thumb/KKT_200x200.png",
  "FLOH": "https://assets.coingecko.com/coins/images/27615/thumb/1889d663-2d57-47c9-9f48-f8362abe360e.png",
  "CHAOS": "https://assets.coingecko.com/coins/images/27609/thumb/chaotic_profile_%281%29.png",
  "DPF": "https://assets.coingecko.com/coins/images/28468/thumb/Doge-Pad-15.png",
  "SKYWARD": "https://assets.coingecko.com/coins/images/18285/thumb/favicon_%285%29.png",
  "BAKT": "https://assets.coingecko.com/coins/images/16773/thumb/QXP11lB.png",
  "QFI": "https://assets.coingecko.com/coins/images/27586/thumb/qfi.png",
  "MARD": "https://assets.coingecko.com/coins/images/28645/thumb/logo_mard-rgb.png",
  "LINEAR": "https://assets.coingecko.com/coins/images/25210/thumb/sUld7aEX_400x400.png",
  "BCB": "https://assets.coingecko.com/coins/images/28694/thumb/200x200.jpg",
  "QBIT": "https://assets.coingecko.com/coins/images/15773/thumb/quantum200.png",
  "MUS": "https://assets.coingecko.com/coins/images/17777/thumb/192092455_101594072164832_4485220528670171699_n.jpg",
  "MKAT": "https://assets.coingecko.com/coins/images/16516/thumb/moonkat_transparent_png.png",
  "CHH": "https://assets.coingecko.com/coins/images/26766/thumb/200x200.png",
  "WSIENNA": "https://assets.coingecko.com/coins/images/15420/thumb/sienna.jpeg",
  "ANKRBNB": "https://assets.coingecko.com/coins/images/28451/thumb/ankrBNB.png",
  "PRS": "https://assets.coingecko.com/coins/images/26310/thumb/D39-q9sY_400x400.jpg",
  "GVC": "https://assets.coingecko.com/coins/images/28741/thumb/Villains.jpeg",
  "RAIN": "https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png",
  "BJUNO": "https://assets.coingecko.com/coins/images/27139/thumb/bjuno.jpg",
  "GINUX": "https://assets.coingecko.com/coins/images/15649/thumb/inu-logo-new-200x200.png",
  "BABYFLOKICOIN": "https://assets.coingecko.com/coins/images/18882/thumb/n8hZe-5I_400x400.jpg",
  "HKBY": "https://assets.coingecko.com/coins/images/28495/thumb/IMG_20221214_073842_547_%283%29_%281%29.png",
  "USDJ": "https://assets.coingecko.com/coins/images/10998/thumb/usdj.png",
  "WRFT": "https://assets.coingecko.com/coins/images/28762/thumb/icon_wrft.png",
  "TYPH": "https://assets.coingecko.com/coins/images/14859/thumb/Logo200px.png",
  "ULE": "https://assets.coingecko.com/coins/images/26951/thumb/20220819_225616_0000.png",
  "INNIT": "https://assets.coingecko.com/coins/images/26724/thumb/INNITlogo.jpg",
  "BABYFD": "https://assets.coingecko.com/coins/images/19543/thumb/babyfd.png",
  "RVLNG": "https://assets.coingecko.com/coins/images/24420/thumb/XQokIAeH_400x400.jpg",
  "WET": "https://assets.coingecko.com/coins/images/17353/thumb/cropped-logo-wombat.png",
  "TAUM": "https://assets.coingecko.com/coins/images/23905/thumb/_Token_TAUM_final.png",
  "LETSGO": "https://assets.coingecko.com/coins/images/24034/thumb/3zKU0T83_400x400.jpeg",
  "GFLOKI": "https://assets.coingecko.com/coins/images/20292/thumb/sUdVrvb2_400x400.jpg",
  "ELONGD": "https://assets.coingecko.com/coins/images/15487/thumb/tT2g66pd.jpg",
  "WALLSTREETINU": "https://assets.coingecko.com/coins/images/20551/thumb/logo-coingecko.png",
  "FOL": "https://assets.coingecko.com/coins/images/13755/thumb/folderlabs_logo.png",
  "DRACO": "https://assets.coingecko.com/coins/images/24798/thumb/draco.png",
  "NVG": "https://assets.coingecko.com/coins/images/29059/thumb/nvg.png",
  "OKDOT2": "https://assets.coingecko.com/coins/images/26887/thumb/OMulWdJL_400x400.jpeg",
  "WELA": "https://assets.coingecko.com/coins/images/24855/thumb/oH2NiuBC_400x400.jpeg",
  "EMB": "https://assets.coingecko.com/coins/images/3335/thumb/czcuIce.png",
  "INDY": "https://assets.coingecko.com/coins/images/28303/thumb/IndigoTokenCMC.png",
  "ARIA": "https://assets.coingecko.com/coins/images/27157/thumb/ARIA_LOGO.png",
  "UNL": "https://assets.coingecko.com/coins/images/23576/thumb/unilock-logo.png",
  "LAMBDA": "https://assets.coingecko.com/coins/images/27081/thumb/lHQJk4I9_400x400.jpeg",
  "ZARP": "https://assets.coingecko.com/coins/images/27333/thumb/zarp_coin.png",
  "CDOG": "https://assets.coingecko.com/coins/images/27845/thumb/Cypherdog_Token_Coin.png",
  "$DGF": "https://assets.coingecko.com/coins/images/27398/thumb/200x200.png",
  "TUZLA": "https://assets.coingecko.com/coins/images/24812/thumb/Tuzlaspors-Token-Supply-Started-at-Bitexen-980x400.png",
  "CRANE$": "https://assets.coingecko.com/coins/images/28262/thumb/0DB25887-459D-4307-BDC0-CB92A9A2DA7E.jpeg",
  "$GGT": "https://assets.coingecko.com/coins/images/27092/thumb/G4AL_light.png",
  "OURO": "https://assets.coingecko.com/coins/images/22767/thumb/1AzbOG9Q_400x400.png",
  "DOFI": "https://assets.coingecko.com/coins/images/19563/thumb/dofi.png",
  "$SAVIOR": "https://assets.coingecko.com/coins/images/27183/thumb/2022-09-02_21.06.16.jpg",
  "MOH": "https://assets.coingecko.com/coins/images/27861/thumb/MOH___200-200.png",
  "HATAY": "https://assets.coingecko.com/coins/images/24813/thumb/5pj7q96D_400x400.png",
  "BABYSHIBA": "https://assets.coingecko.com/coins/images/22707/thumb/9CmjzSIW_400x400.jpg",
  "BPRIVA": "https://assets.coingecko.com/coins/images/15252/thumb/priv.png",
  "JKL": "https://assets.coingecko.com/coins/images/28025/thumb/Listing_Logo_-_JKL.png",
  "AHILL": "https://assets.coingecko.com/coins/images/28407/thumb/ahill-logo-trans-200x200.png",
  "MBBT": "https://assets.coingecko.com/coins/images/20986/thumb/ZgVuisG__400x400.jpg",
  "AMZE": "https://assets.coingecko.com/coins/images/21620/thumb/BcE98e6z_400x400.png",
  "SWPD": "https://assets.coingecko.com/coins/images/28818/thumb/Swapped_%28192_%C3%97_192_px%29.png",
  "AXA": "https://assets.coingecko.com/coins/images/9438/thumb/alldex.png",
  "MB": "https://assets.coingecko.com/coins/images/22288/thumb/oNmIh8Ar_400x400.jpg",
  "SMRAT": "https://assets.coingecko.com/coins/images/15241/thumb/SMRAT_200x200.png",
  "BDE": "https://assets.coingecko.com/coins/images/17903/thumb/bde.png",
  "KCC": "https://assets.coingecko.com/coins/images/27339/thumb/200x200.png",
  "VRTX": "https://assets.coingecko.com/coins/images/27927/thumb/vrtx.png",
  "INFS": "https://assets.coingecko.com/coins/images/10716/thumb/WBUD9cx.png",
  "CIOTX": "https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG",
  "MOSS": "https://assets.coingecko.com/coins/images/25639/thumb/moss-governance-token.png",
  "SBSH": "https://assets.coingecko.com/coins/images/16311/thumb/logo_-_2021-06-15T105921.126.png",
  "QCX": "https://assets.coingecko.com/coins/images/4971/thumb/qcx.png",
  "TRDL": "https://assets.coingecko.com/coins/images/13854/thumb/1614634281474-TRDL_LOGO_PNG.png",
  "CCBCH": "https://assets.coingecko.com/coins/images/19747/thumb/ccBCH.png",
  "LQR": "https://assets.coingecko.com/coins/images/20307/thumb/lqt.PNG",
  "BVT": "missing_thumb.png",
  "SUPA": "https://assets.coingecko.com/coins/images/22522/thumb/KmzajETygnMWAKm05ljiI3pc7_JTIdu_H6hhOgaRc-ICj0M9geEpF83iceGbQ6U2PByY3A3jwkHFC0zLEycnplJpUcqb_cl0QV89fPI_h3XdflDh9Sroq22uFfvwQ8lCjl7EFiIKpr-fGbW2ArkJEdPkWL6UJu5DbE6YArcksukEcc_8cpryHjchAdd-imlOugScNPswFZzMqb.jpg",
  "DECENT": "https://assets.coingecko.com/coins/images/22520/thumb/CjIOOXXc_400x400.jpg",
  "HOOK": "https://assets.coingecko.com/coins/images/28288/thumb/hooked2.png",
  "HUAHUA": "https://assets.coingecko.com/coins/images/22485/thumb/logo_transparent_notext.png",
  "TRIPS": "https://assets.coingecko.com/coins/images/14648/thumb/trips_32.png",
  "HEGG": "https://assets.coingecko.com/coins/images/18653/thumb/hegg_200.png",
  "DELTA": "https://assets.coingecko.com/coins/images/14564/thumb/Delta_logo.png",
  "MLS": "https://assets.coingecko.com/coins/images/25665/thumb/I3pUwLZp_400x400.jpg",
  "BUSDT": "https://assets.coingecko.com/coins/images/27459/thumb/busdt.png",
  "BLOVELY": "https://assets.coingecko.com/coins/images/23608/thumb/blovely.png",
  "LIC": "https://assets.coingecko.com/coins/images/14560/thumb/Lightening-cash-logo-circle-200x200.png",
  "BLINK": "https://assets.coingecko.com/coins/images/8525/thumb/link-by-blockmason.jpg",
  "XSB": "https://assets.coingecko.com/coins/images/20958/thumb/xsb-200.png",
  "GTFX": "https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png",
  "SEADOG": "https://assets.coingecko.com/coins/images/19617/thumb/sead.PNG",
  "MOF": "https://assets.coingecko.com/coins/images/2607/thumb/molecular_future.png",
  "BLIZZ": "https://assets.coingecko.com/coins/images/18446/thumb/blizz.PNG",
  "PYD": "https://assets.coingecko.com/coins/images/18460/thumb/PYD200.png",
  "WEC": "https://assets.coingecko.com/coins/images/16313/thumb/WEC_logo.png",
  "MITA": "https://assets.coingecko.com/coins/images/23851/thumb/fcDpmLEb_400x400.png",
  "GME": "https://assets.coingecko.com/coins/images/13820/thumb/gamestop_logo.jpg",
  "NYE": "https://assets.coingecko.com/coins/images/8772/thumb/1n0eimGC_400x400.jpg",
  "OPS": "https://assets.coingecko.com/coins/images/16383/thumb/ops_logo.jpg",
  "MGG": "https://assets.coingecko.com/coins/images/23287/thumb/mgg.png",
  "MIKABOSHI": "https://assets.coingecko.com/coins/images/27048/thumb/photo_2022-08-25_23-44-10.jpg",
  "POLYBABYDOGE": "https://assets.coingecko.com/coins/images/16937/thumb/WMpWi7ub4A1FUu8OZBMgBbn5gexHP0b0abL98yQz_rMdF4FWjwwQa7t4P8ktfWZMrJokFdH7MeYjOWqVf5qBsnInzL2CEl8jhgYS_Sxs0bLh07tUOWAMyYyGfae3EzadIBRSFHZ1P368gDOC7q6cLkNyJGMf2rgE_K2ZV7YWWPjT6bvLF9UX28h1Gup3BhtSOYzRCvaAUpUB.jpg",
  "BDIGG": "https://assets.coingecko.com/coins/images/14503/thumb/DIGG.png",
  "WBB": "https://assets.coingecko.com/coins/images/590/thumb/wild_beast_block.png",
  "RBP": "https://assets.coingecko.com/coins/images/25824/thumb/6295aea8b46cd60001d5ac44_RBS160.png",
  "PHM": "https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG",
  "IDLEUSDTYIELD": "https://assets.coingecko.com/coins/images/11933/thumb/idleusdtv3maxyield_32.png",
  "ROGER": "https://assets.coingecko.com/coins/images/8869/thumb/rogerbirdfix3p5.png",
  "BTTY": "https://assets.coingecko.com/coins/images/28641/thumb/bitcointry_token_logo_100x100.png",
  "FCC": "https://assets.coingecko.com/coins/images/27033/thumb/FCC_200x200.png",
  "BNUSD": "https://assets.coingecko.com/coins/images/18949/thumb/BD.png",
  "EVIC": "https://assets.coingecko.com/coins/images/27292/thumb/evic_200x200.png",
  "EWC": "https://assets.coingecko.com/coins/images/21652/thumb/Swhwfr3G_400x400.jpg",
  "AFD": "https://assets.coingecko.com/coins/images/28589/thumb/dogg.765111d9.png",
  "VEN": "https://assets.coingecko.com/coins/images/26601/thumb/VF-Logo.png",
  "GGG": "https://assets.coingecko.com/coins/images/19269/thumb/Logo_%282%29_%281%29.jpg",
  "STP": "https://assets.coingecko.com/coins/images/27509/thumb/D92uffI__400x400.jpeg",
  "FMB": "https://assets.coingecko.com/coins/images/28618/thumb/fmb.png",
  "ATALEX": "https://assets.coingecko.com/coins/images/27197/thumb/AutoALEX_Token.png",
  "WMF": "https://assets.coingecko.com/coins/images/27433/thumb/IMG_20220923_042845_107.jpg",
  "ZVERSUS": "https://assets.coingecko.com/coins/images/27695/thumb/zversus.png",
  "BRAND": "https://assets.coingecko.com/coins/images/19532/thumb/brandpad.PNG",
  "XPC": "https://assets.coingecko.com/coins/images/7359/thumb/experience_chain.png",
  "LEOX": "https://assets.coingecko.com/coins/images/27435/thumb/LEOX_LOGO.png",
  "HPW": "https://assets.coingecko.com/coins/images/23546/thumb/HPW.png",
  "ESC": "https://assets.coingecko.com/coins/images/21913/thumb/The-Essential-Coin-200x200.png",
  "KS2": "https://assets.coingecko.com/coins/images/25458/thumb/KS.jpeg",
  "MLNT": "https://assets.coingecko.com/coins/images/22061/thumb/uncwDCnY_400x400.jpg",
  "DOGEY": "https://assets.coingecko.com/coins/images/19966/thumb/dogeyellow.png",
  "TWEP": "https://assets.coingecko.com/coins/images/24550/thumb/twep.jpg",
  "RIPH": "https://assets.coingecko.com/coins/images/22084/thumb/RIPH_Coin_200px.png",
  "KBOX": "https://assets.coingecko.com/coins/images/20828/thumb/icon_abe_token-200.png",
  "MLTPX": "https://assets.coingecko.com/coins/images/16226/thumb/moonlift.jpeg",
  "WX": "https://assets.coingecko.com/coins/images/21267/thumb/15691.png",
  "WFAC": "https://assets.coingecko.com/coins/images/27862/thumb/200.png",
  "MVDOLLAR": "https://assets.coingecko.com/coins/images/24066/thumb/MvDollar.png",
  "SFC": "https://assets.coingecko.com/coins/images/21190/thumb/15536.png",
  "BOON": "https://assets.coingecko.com/coins/images/22493/thumb/A5dZNEu.png",
  "LIQR": "https://assets.coingecko.com/coins/images/21580/thumb/b-MDs-Uqg-Q-400x400.jpg",
  "KUSUNOKI": "https://assets.coingecko.com/coins/images/24688/thumb/68747470733a2f2f7777772e6b7573756e6f6b6973616d757261692e636f6d2f696d616765732f6b7573756e6f6b692d30312e706e67.png",
  "BGT": "https://assets.coingecko.com/coins/images/28324/thumb/20221122_112541.png",
  "CARESV2": "https://assets.coingecko.com/coins/images/27577/thumb/caresv2.png",
  "HEROES": "https://assets.coingecko.com/coins/images/17917/thumb/33b73b5a11f3391dd9ef009985308cf5.png",
  "MEFI": "https://assets.coingecko.com/coins/images/27837/thumb/57678426-EC89-4BA2-87AB-301B7D915F1B.jpeg",
  "$LUCA": "https://assets.coingecko.com/coins/images/22445/thumb/luca.png",
  "FXTC": "https://assets.coingecko.com/coins/images/7032/thumb/Fixed_Trade_Coin.png",
  "DEGENR": "https://assets.coingecko.com/coins/images/15040/thumb/tokenlogo2.png",
  "AERO": "https://assets.coingecko.com/coins/images/24659/thumb/logo.png",
  "MTGO": "https://assets.coingecko.com/coins/images/24874/thumb/101.png",
  "MATICPO": "https://assets.coingecko.com/coins/images/22947/thumb/MATICpo_wh_small.png",
  "ENSP": "https://assets.coingecko.com/coins/images/22650/thumb/Sl7FsoEx-Pmz3MLiP1uINdOiyRPpIuQGZySuJNge8VQ6W9WyfavJ5awApU9ZZXY9zomRa9YyGLMraG7EGnNnVsPLz2EXwgIh_DmNrA0p9oZcGcqEsu5ZdcH9fN8WnQbIOLgJWIlHoMNaxlmGCNzsxPVm9tD8--b-QiarGBtH2aSLArQpye_p9-sD5WZ1QvrqsCyFapwQWCGSto.jpg",
  "PNDR": "https://assets.coingecko.com/coins/images/18062/thumb/pandora.PNG",
  "INDSHIB": "https://assets.coingecko.com/coins/images/24941/thumb/mvnnSVVU_400x400.jpg",
  "TMAP": "https://assets.coingecko.com/coins/images/27655/thumb/tmap.png",
  "METAN": "https://assets.coingecko.com/coins/images/23762/thumb/metan.png",
  "$CASIO": "https://assets.coingecko.com/coins/images/22930/thumb/Casio-Icon.png",
  "FATHER": "https://assets.coingecko.com/coins/images/23668/thumb/logo_256px.png",
  "SHIBAKEN": "https://assets.coingecko.com/coins/images/15413/thumb/shibak.png",
  "WILLIE": "https://assets.coingecko.com/coins/images/27948/thumb/YiFf3bWN_400x400.jpg",
  "DARKG": "https://assets.coingecko.com/coins/images/24638/thumb/DARKG.png",
  "NOMADUSDC": "https://assets.coingecko.com/coins/images/24829/thumb/usdc.png",
  "MTLMC3": "https://assets.coingecko.com/coins/images/317/thumb/metal_music_coin.png",
  "TART": "https://assets.coingecko.com/coins/images/22731/thumb/tk4yctbE_400x400_%281%29.jpg",
  "MYIELD": "https://assets.coingecko.com/coins/images/25011/thumb/Icon.png",
  "SUNI": "https://assets.coingecko.com/coins/images/17612/thumb/1VxZJ7.png",
  "GLA": "https://assets.coingecko.com/coins/images/18494/thumb/logo1_%285%29.png",
  "FCD": "https://assets.coingecko.com/coins/images/24570/thumb/FCD_small_200.png",
  "VS": "https://assets.coingecko.com/coins/images/24542/thumb/W0jkQVDm_400x400.png",
  "XPRISM": "https://assets.coingecko.com/coins/images/23631/thumb/xprism.png",
  "ANKRAVAX": "https://assets.coingecko.com/coins/images/28695/thumb/ankrAVAX.png",
  "ATLS": "https://assets.coingecko.com/coins/images/24392/thumb/pIyyPjAw_400x400.jpg",
  "MAV": "https://assets.coingecko.com/coins/images/25134/thumb/logo.png",
  "XCOMB": "https://assets.coingecko.com/coins/images/16012/thumb/logo.png",
  "ANIA": "https://assets.coingecko.com/coins/images/23108/thumb/Logo_A.png",
  "BALN": "https://assets.coingecko.com/coins/images/15303/thumb/balance_tokens.png",
  "TOMOE": "https://assets.coingecko.com/coins/images/12646/thumb/tomoe_logo.png",
  "ARTIC": "https://assets.coingecko.com/coins/images/24279/thumb/Artic_logo_02.png",
  "HOODIE": "https://assets.coingecko.com/coins/images/17842/thumb/cryptopunk-hoodie.png",
  "CRF": "https://assets.coingecko.com/coins/images/21559/thumb/tokenlogo.png",
  "WAL": "https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png",
  "$SPF": "https://assets.coingecko.com/coins/images/28575/thumb/spf.png",
  "CIX": "https://assets.coingecko.com/coins/images/28483/thumb/PHOTO-2022-05-27-11-34-42.jpg",
  "$SANDWICH": "https://assets.coingecko.com/coins/images/23562/thumb/Sandwich_Token_Logo.png",
  "CBI": "https://assets.coingecko.com/coins/images/28186/thumb/200-cbi.png",
  "NIFTSY": "https://assets.coingecko.com/coins/images/19161/thumb/logo200x200.png",
  "TST": "https://assets.coingecko.com/coins/images/20598/thumb/TheStandard-logo_variation-01.png",
  "MBE": "https://assets.coingecko.com/coins/images/28418/thumb/MBE.png",
  "XDEX": "https://assets.coingecko.com/coins/images/14485/thumb/logo.png",
  "ETHFIN": "https://assets.coingecko.com/coins/images/20190/thumb/CMC_%282%29.png",
  "SPEED": "https://assets.coingecko.com/coins/images/24983/thumb/f2zOnAa4_400x400.jpg",
  "METASH": "https://assets.coingecko.com/coins/images/24981/thumb/cI0xX_Gt_400x400.jpg",
  "CEUSDC": "https://assets.coingecko.com/coins/images/24823/thumb/usdc.png",
  "DES": "https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg",
  "PERSIB": "https://assets.coingecko.com/coins/images/28536/thumb/PERSIB-10.png",
  "HBC": "https://assets.coingecko.com/coins/images/12489/thumb/hb_logo.png",
  "HELENA": "https://assets.coingecko.com/coins/images/28364/thumb/helenav2.png",
  "WEST": "https://assets.coingecko.com/coins/images/10464/thumb/53049754.png",
  "XBLL": "https://assets.coingecko.com/coins/images/28580/thumb/Bull_token2.jpg",
  "OSW": "https://assets.coingecko.com/coins/images/24316/thumb/logo-cmc.png",
  "EUROE": "https://assets.coingecko.com/coins/images/28913/thumb/euroe-200x200-round.png",
  "SUNDER": "https://assets.coingecko.com/coins/images/16287/thumb/logo.d2250592.png",
  "BOM": "https://assets.coingecko.com/coins/images/28399/thumb/Logo_BOM_200x200.png",
  "LTRBT": "https://assets.coingecko.com/coins/images/27327/thumb/ltrbt.png",
  "MMG": "https://assets.coingecko.com/coins/images/23522/thumb/mmg.png",
  "RH31": "https://assets.coingecko.com/coins/images/28067/thumb/200.png",
  "MTRK": "https://assets.coingecko.com/coins/images/27375/thumb/MTRK.png",
  "DAIQUIRI": "https://assets.coingecko.com/coins/images/22452/thumb/6787241B-90A3-4D28-8F06-7CE575186CF0.png",
  "ENCWTA": "https://assets.coingecko.com/coins/images/26283/thumb/ENCwTaLogo.jpg",
  "BITWALLET": "https://assets.coingecko.com/coins/images/27378/thumb/photo.jpg",
  "$STRAW": "https://assets.coingecko.com/coins/images/26909/thumb/Sundae_finance_straw.png",
  "GGC": "https://assets.coingecko.com/coins/images/10301/thumb/ggcoin_200.png",
  "IMP": "https://assets.coingecko.com/coins/images/19083/thumb/imp_logo.png",
  "LIBERA": "https://assets.coingecko.com/coins/images/26194/thumb/20791.png",
  "LLVERSE": "https://assets.coingecko.com/coins/images/24230/thumb/logo_leslar_1.png",
  "AMDAI": "https://assets.coingecko.com/coins/images/17251/thumb/amDAI_2x.png",
  "MIL": "https://assets.coingecko.com/coins/images/15893/thumb/logo-png_%281%29.png",
  "MWC": "https://assets.coingecko.com/coins/images/9989/thumb/E5zZUXza_400x400.jpg",
  "SHIBEMP": "https://assets.coingecko.com/coins/images/20201/thumb/shibemp_200_200.png",
  "MYID": "https://assets.coingecko.com/coins/images/15063/thumb/MYID-LOGO.png",
  "IDLESUSDYIELD": "https://assets.coingecko.com/coins/images/11934/thumb/idlesusdv3mmaxyield_32.png",
  "TIGRES": "https://assets.coingecko.com/coins/images/28439/thumb/TIGRES.png",
  "FRZSS": "https://assets.coingecko.com/coins/images/24368/thumb/1100x800_cropped.jpg",
  "EVA": "https://assets.coingecko.com/coins/images/16722/thumb/evan.PNG",
  "FOO": "https://assets.coingecko.com/coins/images/19285/thumb/mm_logo512.png",
  "WGLMR": "https://assets.coingecko.com/coins/images/23688/thumb/wglmr.jpg",
  "FPG": "https://assets.coingecko.com/coins/images/27712/thumb/200X200.png",
  "DPT": "https://assets.coingecko.com/coins/images/21878/thumb/JjlcpQS1_400x400.jpg",
  "DRA": "https://assets.coingecko.com/coins/images/26768/thumb/Drachma_Exchange_%281%29.png",
  "FOXY": "https://assets.coingecko.com/coins/images/26191/thumb/uFYaQEsU_400x400.jpg",
  "CHES": "https://assets.coingecko.com/coins/images/24099/thumb/TokenLogo.png",
  "CEM": "https://assets.coingecko.com/coins/images/27360/thumb/logo200x200.png",
  "TSANGNYON": "https://assets.coingecko.com/coins/images/27611/thumb/81b3e527-f8a9-47f7-8d4d-10f754f501c2.png",
  "PRBLY": "https://assets.coingecko.com/coins/images/20193/thumb/pn.png",
  "MNOP": "https://assets.coingecko.com/coins/images/17765/thumb/mnop-token-200.png",
  "$ADTX": "https://assets.coingecko.com/coins/images/21198/thumb/aurora.png",
  "PLX": "https://assets.coingecko.com/coins/images/18013/thumb/octaplex-icon.png",
  "BTTOLD": "https://assets.coingecko.com/coins/images/7595/thumb/BTT_Token_Graphic.png",
  "BPLUS": "https://assets.coingecko.com/coins/images/21969/thumb/15163.png",
  "FIMI": "https://assets.coingecko.com/coins/images/22659/thumb/token.png",
  "BTRS": "https://assets.coingecko.com/coins/images/7273/thumb/btrs.png",
  "MOVON": "https://assets.coingecko.com/coins/images/25615/thumb/CMWZ4ZT.png",
  "$UPL": "https://assets.coingecko.com/coins/images/22513/thumb/PICKLEstarsROUNDsolid-Transparent.png.png",
  "LIBERO": "https://assets.coingecko.com/coins/images/23971/thumb/libero.png",
  "SHDB": "https://assets.coingecko.com/coins/images/28716/thumb/shdb_200x200.png",
  "MIY": "https://assets.coingecko.com/coins/images/25981/thumb/20570.png",
  "VV": "https://assets.coingecko.com/coins/images/23541/thumb/kxdNBBO.jpeg",
  "STZU": "https://assets.coingecko.com/coins/images/27599/thumb/hero-shihtzu_2.png",
  "IDLEUSDCYIELD": "https://assets.coingecko.com/coins/images/11932/thumb/idleusdcv3_32.png",
  "SSM": "https://assets.coingecko.com/coins/images/23436/thumb/logo200.png",
  "ET": "https://assets.coingecko.com/coins/images/27637/thumb/et_logo.png",
  "SHROOMZ": "https://assets.coingecko.com/coins/images/20990/thumb/6k8KBF3H_400x400.png",
  "RIFICO": "https://assets.coingecko.com/coins/images/25332/thumb/rifico_logo_png_1.png",
  "FPI": "https://assets.coingecko.com/coins/images/24945/thumb/fpi.png",
  "SMC": "https://assets.coingecko.com/coins/images/26109/thumb/200.png",
  "RNRC": "https://assets.coingecko.com/coins/images/9131/thumb/Z03PX1uG_400x400.jpg",
  "FRP": "https://assets.coingecko.com/coins/images/27675/thumb/fameRewardPlus-icon.png",
  "LUMEN": "https://assets.coingecko.com/coins/images/21813/thumb/LVuXhMQ.png",
  "TROLLER": "https://assets.coingecko.com/coins/images/19317/thumb/13048.png",
  "WSGB": "https://assets.coingecko.com/coins/images/28770/thumb/songbird.jpeg",
  "GWP": "https://assets.coingecko.com/coins/images/24093/thumb/gwp.PNG",
  "IONS": "https://assets.coingecko.com/coins/images/15298/thumb/lithium_ions.jpg",
  "DBTYCOON": "https://assets.coingecko.com/coins/images/20705/thumb/db-transparent-200x200.png",
  "PPP": "https://assets.coingecko.com/coins/images/25074/thumb/Icon_PPP_Big_x200.png",
  "QQQ": "https://assets.coingecko.com/coins/images/8442/thumb/LNBLzKy2_400x400.jpg",
  "GDE": "https://assets.coingecko.com/coins/images/27942/thumb/logonew.jpg",
  "CMB": "https://assets.coingecko.com/coins/images/23385/thumb/cmb-200x200.png",
  "STMATIC": "https://assets.coingecko.com/coins/images/24185/thumb/stMATIC.png",
  "IBG": "https://assets.coingecko.com/coins/images/18756/thumb/ibg.png",
  "FOF": "https://assets.coingecko.com/coins/images/26837/thumb/FOF_logo.png",
  "SUZUME": "https://assets.coingecko.com/coins/images/26813/thumb/shitakiri_cmc.png",
  "SDS": "https://assets.coingecko.com/coins/images/16867/thumb/1_551bIKFiNCvKXdUloML0wA.png",
  "REDPANDA": "https://assets.coingecko.com/coins/images/28522/thumb/RedPanda_Earth_Coin_Logo_Textured_200x200.png",
  "OPXVEVELO": "https://assets.coingecko.com/coins/images/28326/thumb/11.jpg",
  "AMWBTC": "https://assets.coingecko.com/coins/images/17265/thumb/amWBTC_2x.png",
  "PEEPS": "https://assets.coingecko.com/coins/images/16777/thumb/CoinGecko_updated_200_by_200.png",
  "AZW": "https://assets.coingecko.com/coins/images/25930/thumb/logoazw.png",
  "TRUSTK": "https://assets.coingecko.com/coins/images/19578/thumb/BasVH3Aj_400x400.png",
  "KSP": "https://assets.coingecko.com/coins/images/13721/thumb/1_0Nr1oZEuvrDp7VVv6JjfQg.jpeg",
  "MEMI": "https://assets.coingecko.com/coins/images/27214/thumb/Metacraft_Mineral_MEMI_icon_200x200px.png",
  "INFINITY": "https://assets.coingecko.com/coins/images/22580/thumb/0DvUslSU_400x400.jpg",
  "METEOR": "https://assets.coingecko.com/coins/images/14889/thumb/Meteor-token-200.png",
  "PEKC": "https://assets.coingecko.com/coins/images/18373/thumb/pekc.jpg",
  "DEER": "https://assets.coingecko.com/coins/images/25890/thumb/deer.png",
  "SOLLAMA": "https://assets.coingecko.com/coins/images/28902/thumb/newTPLogoSollama.png",
  "UUSDT": "https://assets.coingecko.com/coins/images/13778/thumb/uUSDT.png",
  "STKBNB": "https://assets.coingecko.com/coins/images/26725/thumb/stkBNB.png",
  "KFS G": "https://assets.coingecko.com/coins/images/20461/thumb/kfs-logo-icon-dropshadow-450px_%281%29.png",
  "WPE": "https://assets.coingecko.com/coins/images/16647/thumb/opes.png",
  "SHIBARROW": "https://assets.coingecko.com/coins/images/22664/thumb/p3XyjmUp_400x400.jpg",
  "SGG": "https://assets.coingecko.com/coins/images/22673/thumb/YhhMhwOn_400x400.jpg",
  "NMBTC": "https://assets.coingecko.com/coins/images/20059/thumb/logo_-_2021-11-09T161322.006.png",
  "SWU": "https://assets.coingecko.com/coins/images/27442/thumb/swu.png",
  "GOLDR": "https://assets.coingecko.com/coins/images/10363/thumb/original_GRO-768x768.png",
  "SCK": "https://assets.coingecko.com/coins/images/24371/thumb/sck.png",
  "CE": "https://assets.coingecko.com/coins/images/14809/thumb/New_logo_CE.jpg",
  "BETH": "https://assets.coingecko.com/coins/images/13804/thumb/Binnace.png",
  "FAR": "https://assets.coingecko.com/coins/images/13497/thumb/70059912.jpg",
  "SSF": "https://assets.coingecko.com/coins/images/16467/thumb/icon-ssf.png",
  "YNY": "https://assets.coingecko.com/coins/images/23871/thumb/PNG-LOGO-YNY.png",
  "SKT": "https://assets.coingecko.com/coins/images/17674/thumb/logo2.png",
  "SMARS": "https://assets.coingecko.com/coins/images/16143/thumb/83904828.png",
  "LOZ": "https://assets.coingecko.com/coins/images/22897/thumb/loz.png",
  "BNKRX": "https://assets.coingecko.com/coins/images/13049/thumb/logo-exchange-x-256.png",
  "CSI": "https://assets.coingecko.com/coins/images/27612/thumb/Credit-Suisse-Inu.jpg",
  "SOURCE": "https://assets.coingecko.com/coins/images/20740/thumb/source.png",
  "$LUNAPE": "https://assets.coingecko.com/coins/images/26983/thumb/Lunape.png",
  "GENBLOK": "https://assets.coingecko.com/coins/images/27471/thumb/6313c6043c13ed270ede4600_aaaa-p-500.jpg",
  "ZOA": "https://assets.coingecko.com/coins/images/25202/thumb/ZoA-CMC.png",
  "DAR": "https://assets.coingecko.com/coins/images/19837/thumb/dar.png",
  "BRZ": "https://assets.coingecko.com/coins/images/8472/thumb/MicrosoftTeams-image_%286%29.png",
  "BAKC": "https://assets.coingecko.com/coins/images/17052/thumb/BYAC.png",
  "AMWETH": "https://assets.coingecko.com/coins/images/17266/thumb/amWETH_2x.png",
  "HKSM": "https://assets.coingecko.com/coins/images/21723/thumb/4_d5Yqsd_400x400.jpg",
  "SXCC": "https://assets.coingecko.com/coins/images/9480/thumb/sxcc-200px.png",
  "BVL": "https://assets.coingecko.com/coins/images/13195/thumb/4HPwA1D2_400x400.png",
  "REAU": "https://assets.coingecko.com/coins/images/15173/thumb/1LU1ujd.png",
  "DTEVIL": "https://assets.coingecko.com/coins/images/27974/thumb/200x200-IDLETREASUREPARTY-DTEVIL-LOGO.png",
  "OSAKU": "https://assets.coingecko.com/coins/images/26953/thumb/photo_2022-08-12_05-35-57.jpg",
  "MOK": "https://assets.coingecko.com/coins/images/27004/thumb/Mok-100_100-72dpi.png",
  "CHDAO": "https://assets.coingecko.com/coins/images/28584/thumb/WhatsApp_Image_2022-12-26_at_23.53.47.jpeg",
  "ITF": "https://assets.coingecko.com/coins/images/14437/thumb/4UgBz9EJ_400x400.jpg",
  "AMUSDT": "https://assets.coingecko.com/coins/images/17264/thumb/amUSDT_2x.png",
  "AERO-V2": "https://assets.coingecko.com/coins/images/23807/thumb/5_6312017193856926639.png",
  "BAYC": "https://assets.coingecko.com/coins/images/17035/thumb/BAYC.png",
  "UCON": "https://assets.coingecko.com/coins/images/27796/thumb/ucon.png",
  "EQ": "https://assets.coingecko.com/coins/images/18959/thumb/USO9eUfE_400x400.jpg",
  "AMAAVE": "https://assets.coingecko.com/coins/images/17239/thumb/amAAVE_2x.png",
  "NGIT": "https://assets.coingecko.com/coins/images/28644/thumb/logo_ngit.png",
  "ATHUSD": "https://assets.coingecko.com/coins/images/28512/thumb/athusd.png",
  "WEMP": "https://assets.coingecko.com/coins/images/18932/thumb/WEMP_2_200x200_copy.png",
  "BSN": "https://assets.coingecko.com/coins/images/27023/thumb/BSN_brandmark_in_black_circle_preview.png",
  "GNBU": "https://assets.coingecko.com/coins/images/16391/thumb/gnbu.PNG",
  "AMTSOL": "https://assets.coingecko.com/coins/images/27786/thumb/amtSOL200x200.png",
  "VRH": "https://assets.coingecko.com/coins/images/27860/thumb/VRH___200-200.png",
  "DBZ": "https://assets.coingecko.com/coins/images/17308/thumb/IMG_0026.png",
  "MIRL": "https://assets.coingecko.com/coins/images/28021/thumb/mirl.png",
  "AMUSDC": "https://assets.coingecko.com/coins/images/17249/thumb/amUSDC_2x.png",
  "KLX": "https://assets.coingecko.com/coins/images/28926/thumb/klx.png",
  "AGAC": "https://assets.coingecko.com/coins/images/16728/thumb/agac128.png",
  "CHARGE": "https://assets.coingecko.com/coins/images/21405/thumb/afuIh6o9_400x400.jpg",
  "SWIV": "https://assets.coingecko.com/coins/images/24496/thumb/tKRKjCx.png",
  "HSF": "https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png",
  "CHOW": "https://assets.coingecko.com/coins/images/14275/thumb/chow-logo.png",
  "MDZA": "https://assets.coingecko.com/coins/images/13448/thumb/medooza-red-symbol-logo-exchange.png",
  "DARKO": "https://assets.coingecko.com/coins/images/25584/thumb/DARKO.png",
  "SUCKR": "https://assets.coingecko.com/coins/images/28842/thumb/new_pp.png",
  "ITG": "https://assets.coingecko.com/coins/images/16713/thumb/anchor.png",
  "TOURISTS": "https://assets.coingecko.com/coins/images/23878/thumb/TOURISTS-200x200px.png",
  "CTN": "https://assets.coingecko.com/coins/images/24338/thumb/LMWqfjP6_400x400.png",
  "CREAL": "https://assets.coingecko.com/coins/images/27205/thumb/creal.png",
  "DOD": "https://assets.coingecko.com/coins/images/20621/thumb/Untitled_design_%2836%29.png",
  "SOCAP": "https://assets.coingecko.com/coins/images/26083/thumb/oPT2utjj_400x400.png",
  "EURST": "https://assets.coingecko.com/coins/images/20988/thumb/AF3E1D9F-4817-4FC4-BC80-FFF30A3E1B91.PNG",
  "GMC": "https://assets.coingecko.com/coins/images/12561/thumb/GMC-favicon-Asset-200x200.png",
  "MCAT20": "https://assets.coingecko.com/coins/images/14387/thumb/MCAT20.png",
  "YORI": "https://assets.coingecko.com/coins/images/27734/thumb/yori_icon200.png",
  "SROCKET": "https://assets.coingecko.com/coins/images/26361/thumb/srocket.jpeg",
  "WATERFALL": "https://assets.coingecko.com/coins/images/22507/thumb/9pkqOOZq_400x400.jpg",
  "STATIC": "https://assets.coingecko.com/coins/images/21404/thumb/1_Ve6PPOEmEu1LRjg5loJkkQ.png",
  "MAMA": "https://assets.coingecko.com/coins/images/27788/thumb/mama.png",
  "BCITY": "https://assets.coingecko.com/coins/images/24611/thumb/bcity.png",
  "INTD": "https://assets.coingecko.com/coins/images/27134/thumb/INTD.png",
  "SQGL": "https://assets.coingecko.com/coins/images/17041/thumb/SQGL.png",
  "HWT": "https://assets.coingecko.com/coins/images/28559/thumb/hwt.png",
  "ANKRMATIC": "https://assets.coingecko.com/coins/images/25742/thumb/a-matic-c-da4ec10dc9723e695700e25dbf8c8edf.png",
  "RATSDAO": "https://assets.coingecko.com/coins/images/26513/thumb/QTN7EYDL_400x400.jpeg",
  "ERW": "https://assets.coingecko.com/coins/images/19781/thumb/4767_ZeLoop_Icon_PNG.png",
  "NINKY": "https://assets.coingecko.com/coins/images/22762/thumb/NINKY.png",
  "PARADOX": "https://assets.coingecko.com/coins/images/28256/thumb/paradox.png",
  "COOL": "https://assets.coingecko.com/coins/images/17032/thumb/COOL.png",
  "OGY": "https://assets.coingecko.com/coins/images/25788/thumb/4xBAWRCo_400x400.png",
  "$VERSA": "https://assets.coingecko.com/coins/images/27388/thumb/VERSA-Logo-only-Transparent-Background-e1663499020411.png",
  "MAYC": "https://assets.coingecko.com/coins/images/28606/thumb/MAYC_200x200.png",
  "FTP": "https://assets.coingecko.com/coins/images/24041/thumb/logo-black.png",
  "ARMY": "https://assets.coingecko.com/coins/images/24089/thumb/IMG-1283.png",
  "SHIVER": "https://assets.coingecko.com/coins/images/19357/thumb/Shibaverse_Logo.png",
  "MWG": "https://assets.coingecko.com/coins/images/22844/thumb/for-CG.png",
  "SRGT": "https://assets.coingecko.com/coins/images/21512/thumb/SevereRiseGamesLogo.png",
  "LSETH": "https://assets.coingecko.com/coins/images/28856/thumb/LSETH_logo.png",
  "AGOV": "https://assets.coingecko.com/coins/images/14383/thumb/AgoV_Logo1.png",
  "IGN": "https://assets.coingecko.com/coins/images/19583/thumb/igf.PNG",
  "GNL": "https://assets.coingecko.com/coins/images/20350/thumb/gnl.jpeg",
  "$GRUSH": "https://assets.coingecko.com/coins/images/26437/thumb/Grushlogo200.png",
  "WELUPS": "https://assets.coingecko.com/coins/images/22550/thumb/175881221_106803201556753_3147352659598404981_n.png",
  "STKETH": "https://assets.coingecko.com/coins/images/25798/thumb/Ethereum.png",
  "DAI+USDC": "https://assets.coingecko.com/coins/images/26753/thumb/W1sQNVWo_400x400.jpeg",
  "LIMEX": "https://assets.coingecko.com/coins/images/11907/thumb/nw1FE_f4_400x400.png",
  "REFLEX": "https://assets.coingecko.com/coins/images/23417/thumb/Reflex_V2_200x200.png",
  "CVOL": "https://assets.coingecko.com/coins/images/24008/thumb/govi-dao.03ef3083.png",
  "BGAN": "https://assets.coingecko.com/coins/images/17051/thumb/bgan.png",
  "VBNT": "https://assets.coingecko.com/coins/images/14053/thumb/bancorvbnt_32.png",
  "MHSP": "https://assets.coingecko.com/coins/images/11175/thumb/MHSP200x200.png",
  "LOOM": "https://assets.coingecko.com/coins/images/14735/thumb/LOOM.png",
  "BEMD": "https://assets.coingecko.com/coins/images/26620/thumb/20220728_124113.png",
  "HBCH": "https://assets.coingecko.com/coins/images/14105/thumb/HBCh.png",
  "STJUNO": "https://assets.coingecko.com/coins/images/28700/thumb/stJUNO_800.png",
  "COPTER": "https://assets.coingecko.com/coins/images/15217/thumb/att1YJDb_400x400.jpg",
  "DHC": "https://assets.coingecko.com/coins/images/12656/thumb/DHC_Transparent_200x200.png",
  "SUP": "https://assets.coingecko.com/coins/images/13007/thumb/logo-symbol-sup.png",
  "TAN": "https://assets.coingecko.com/coins/images/1963/thumb/takli.png",
  "2CRV": "https://assets.coingecko.com/coins/images/28365/thumb/curve2.png",
  "ZHT": "https://assets.coingecko.com/coins/images/18692/thumb/LOGO1_%287%29.png",
  "PVP": "https://assets.coingecko.com/coins/images/9682/thumb/pvp.png",
  "GHT": "https://assets.coingecko.com/coins/images/12040/thumb/WechatIMG6648.png",
  "MFC": "https://assets.coingecko.com/coins/images/22421/thumb/tokens_1f4631cd-0063-48e5-b611-446cfe25aec8.png",
  "XSTUSD": "https://assets.coingecko.com/coins/images/21601/thumb/15731.png",
  "STILTON": "https://assets.coingecko.com/coins/images/23572/thumb/2022-02-09_11.32.27-removebg-preview.png",
  "WACO": "https://assets.coingecko.com/coins/images/19035/thumb/waco.png",
  "RUGPULL": "https://assets.coingecko.com/coins/images/19734/thumb/Rugpull-P-Logo-200x200.png",
  "LDOT": "https://assets.coingecko.com/coins/images/25847/thumb/iShot2022-06-02_13.14.07-removebg-preview.png",
  "MAST": "https://assets.coingecko.com/coins/images/23539/thumb/msg643016208-53328.jpg",
  "FWW": "https://assets.coingecko.com/coins/images/24687/thumb/KeVZRjeI_400x400.jpg",
  "SRNT": "https://assets.coingecko.com/coins/images/7334/thumb/serenity.jpg",
  "STOSMO": "https://assets.coingecko.com/coins/images/28698/thumb/stOSMO_800.png",
  "EST": "https://assets.coingecko.com/coins/images/24076/thumb/EST.png",
  "CRUX": "https://assets.coingecko.com/coins/images/24538/thumb/Webp.net-resizeimage_%281%29.png",
  "PRGC": "https://assets.coingecko.com/coins/images/25525/thumb/Bjh4zRK1_400x400.png",
  "DGOLD": "https://assets.coingecko.com/coins/images/23832/thumb/H0s7FaEC_400x400.jpg",
  "STKXPRT": "https://assets.coingecko.com/coins/images/19219/thumb/stkXPRT_-_128px.png",
  "CKRACING": "https://assets.coingecko.com/coins/images/25443/thumb/CKR-logo-200x200-1.png",
  "SSG": "https://assets.coingecko.com/coins/images/20967/thumb/FFNaTMMXIAk9d5y.jpg",
  "FOA": "https://assets.coingecko.com/coins/images/22103/thumb/16642.png",
  "AWC": "https://assets.coingecko.com/coins/images/7430/thumb/Atomic_Wallet_Aeron_%281%29.png",
  "TSUKAW": "https://assets.coingecko.com/coins/images/27537/thumb/200Artboard_1.png",
  "CBUNNY": "https://assets.coingecko.com/coins/images/19675/thumb/500x500px.png",
  "MCO2": "https://assets.coingecko.com/coins/images/14414/thumb/ENtxnThA_400x400.jpg",
  "ALK": "https://assets.coingecko.com/coins/images/18391/thumb/alkemi-logo-icon-256x256.png",
  "CCN": "https://assets.coingecko.com/coins/images/20094/thumb/logo_5ae26a5710e532e361b71c4d9ec7be8c_2x.png",
  "RCKC": "https://assets.coingecko.com/coins/images/25436/thumb/Rocket-Global-Logo.png",
  "OTIUM": "https://assets.coingecko.com/coins/images/19935/thumb/KGBTUm7f_400x400.jpg",
  "PIXLS": "https://assets.coingecko.com/coins/images/18351/thumb/pixls.png",
  "HID": "https://assets.coingecko.com/coins/images/16158/thumb/hypersign_ONLYlogo_Yellow.png",
  "G-DAI": "https://assets.coingecko.com/coins/images/28169/thumb/g-dai.png",
  "ASCEND": "https://assets.coingecko.com/coins/images/16019/thumb/icon200.png",
  "NXDF": "https://assets.coingecko.com/coins/images/22783/thumb/NXDF.png",
  "MSI": "https://assets.coingecko.com/coins/images/20785/thumb/MSI.png",
  "ACF": "https://assets.coingecko.com/coins/images/25441/thumb/acf-token_%281%29.png",
  "KWS": "https://assets.coingecko.com/coins/images/18635/thumb/logo-200x200_game_kn.png",
  "WMEMO": "https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png",
  "SYRANT": "https://assets.coingecko.com/coins/images/28372/thumb/syrant200.jpg",
  "YFB2": "https://assets.coingecko.com/coins/images/13064/thumb/yfb2_logo.png",
  "WTRTL": "https://assets.coingecko.com/coins/images/24891/thumb/scC6JQJQ_400x400.jpg",
  "HIMA": "https://assets.coingecko.com/coins/images/19970/thumb/hima.png",
  "BBADGER": "https://assets.coingecko.com/coins/images/14502/thumb/BADGER_DAO.png",
  "HOFR": "https://assets.coingecko.com/coins/images/27671/thumb/2022-12-18_21.22.31.jpg",
  "WSSCR": "https://assets.coingecko.com/coins/images/23956/thumb/wsSCR200px.png",
  "PITQC": "https://assets.coingecko.com/coins/images/27603/thumb/PITQD_LOGO_TWITER.png",
  "BKN": "https://assets.coingecko.com/coins/images/25984/thumb/f7974742-3030-4247-8bc5-752fa19ebfbd.png",
  "OCW": "https://assets.coingecko.com/coins/images/25096/thumb/gTdrrooG_400x400.jpg",
  "PMT": "https://assets.coingecko.com/coins/images/1514/thumb/pmt.png",
  "REBL": "https://assets.coingecko.com/coins/images/20241/thumb/01_logo.png",
  "ZRG": "https://assets.coingecko.com/coins/images/27832/thumb/zrg_300.png",
  "ANG": "https://assets.coingecko.com/coins/images/12134/thumb/AureusNummusGold-logo.png",
  "STATOM": "https://assets.coingecko.com/coins/images/26316/thumb/stATOM.png",
  "BEST": "https://assets.coingecko.com/coins/images/15891/thumb/best.PNG",
  "AXT": "https://assets.coingecko.com/coins/images/11124/thumb/a02f067fc027d99c4f1b1d36ad98205c.png",
  "PUML": "https://assets.coingecko.com/coins/images/12920/thumb/PUML_200x200.png",
  "LP-YCRV": "https://assets.coingecko.com/coins/images/27624/thumb/yearncrvlp_32.png",
  "WEFIN": "https://assets.coingecko.com/coins/images/23117/thumb/We-Fin-Logo.png",
  "IRENA": "https://assets.coingecko.com/coins/images/24507/thumb/Adsiz-tasarim.png",
  "GLYPH": "https://assets.coingecko.com/coins/images/17050/thumb/Glyph.png",
  "GOB": "https://assets.coingecko.com/coins/images/27104/thumb/q1_hZykF_400x400.jpeg",
  "CRIC": "https://assets.coingecko.com/coins/images/18287/thumb/Cricket-Ball-Logo.png",
  "AGFI": "https://assets.coingecko.com/coins/images/26332/thumb/79LDzdib_400x400_%281%29.jpg",
  "BDS": "https://assets.coingecko.com/coins/images/28413/thumb/black_dragon_society_logo_200x200.png",
  "PUDGY": "https://assets.coingecko.com/coins/images/18330/thumb/pudgy.png",
  "GSA": "https://assets.coingecko.com/coins/images/8398/thumb/200_200_GSA_new-logo.png",
  "STAPT": "https://assets.coingecko.com/coins/images/27981/thumb/CvO9xV3C_400x400.jpeg",
  "STYL": "https://assets.coingecko.com/coins/images/27368/thumb/icon-coin.png",
  "ICMC": "https://assets.coingecko.com/coins/images/27984/thumb/32d7333f294248fb89774ad2939fed64_%281%29.jpeg",
  "CADINU": "https://assets.coingecko.com/coins/images/27003/thumb/CADINU-HEAD-200pxx200px.png",
  "CIS": "https://assets.coingecko.com/coins/images/27404/thumb/200_200_%281%29_%282%29.png",
  "LOVELY": "https://assets.coingecko.com/coins/images/19053/thumb/lovely-inu-logo-new.png",
  "LOE": "https://assets.coingecko.com/coins/images/27247/thumb/loe.png",
  "SDG": "https://assets.coingecko.com/coins/images/21085/thumb/SyncDAO-s-icon_1_200x200.png",
  "PAPR": "https://assets.coingecko.com/coins/images/16284/thumb/dollarstack_HQ.png",
  "CAT+": "https://assets.coingecko.com/coins/images/24794/thumb/cat_.png",
  "SARRIES": "https://assets.coingecko.com/coins/images/28503/thumb/Token200x200_%281%29.png",
  "HBO": "https://assets.coingecko.com/coins/images/14034/thumb/hashbridge.png",
  "SRT": "https://assets.coingecko.com/coins/images/25035/thumb/me-44EyS_400x400.jpg",
  "CPOS": "https://assets.coingecko.com/coins/images/19229/thumb/cpos.PNG",
  "RETA": "https://assets.coingecko.com/coins/images/22791/thumb/reta_logo_200.png",
  "MADHAT": "https://assets.coingecko.com/coins/images/27407/thumb/MADHAT_TOKEN_CROP.png",
  "CRVFRAX": "https://assets.coingecko.com/coins/images/26829/thumb/W1sQNVWo_400x400.jpeg",
  "FSC": "https://assets.coingecko.com/coins/images/28190/thumb/fsc.png",
  "NTP": "https://assets.coingecko.com/coins/images/27108/thumb/NTP_Symbol_CoinGecko.jpg",
  "MCUSD": "https://assets.coingecko.com/coins/images/22380/thumb/McUSD_Blue_128x128_Square.jpg",
  "JCR": "https://assets.coingecko.com/coins/images/25251/thumb/Just-Carbon-JCR-2.png",
  "BRACE": "https://assets.coingecko.com/coins/images/27036/thumb/BRACE.png",
  "ACAR": "https://assets.coingecko.com/coins/images/16729/thumb/acr128.png",
  "CEUSDT": "https://assets.coingecko.com/coins/images/24826/thumb/usdt.png",
  "MONKE": "https://assets.coingecko.com/coins/images/20847/thumb/XXqbKW2a_400x400.jpg",
  "PHUNK": "https://assets.coingecko.com/coins/images/17074/thumb/Phunks.png",
  "LEP": "https://assets.coingecko.com/coins/images/24307/thumb/l7Xnx2t__400x400.png",
  "CCF": "https://assets.coingecko.com/coins/images/20852/thumb/logo.jpg",
  "AAMMUNICRVWETH": "https://assets.coingecko.com/coins/images/17220/thumb/aAmmUniCRVWETH.png",
  "UST": "https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png",
  "MIKOTO": "https://assets.coingecko.com/coins/images/27189/thumb/output-onlinepngtools_%285%29.png",
  "MXNT": "https://assets.coingecko.com/coins/images/26087/thumb/MXNT_green.jpg",
  "CIC": "https://assets.coingecko.com/coins/images/26800/thumb/20220809_143946_0000.png",
  "XTUSD": "https://assets.coingecko.com/coins/images/26504/thumb/header-logo.67638e6.png",
  "AAMMUNIYFIWETH": "https://assets.coingecko.com/coins/images/17258/thumb/aAmmUniYFIWETH.png",
  "NYR": "https://assets.coingecko.com/coins/images/22201/thumb/Q-5kE0wd_400x400.jpg",
  "SAIKO": "https://assets.coingecko.com/coins/images/28932/thumb/200x200.png",
  "NIKA": "https://assets.coingecko.com/coins/images/25794/thumb/image_%281%29.png",
  "XSPC": "https://assets.coingecko.com/coins/images/7540/thumb/Spectre_Security.png",
  "IVRY": "https://assets.coingecko.com/coins/images/24389/thumb/portal.PNG",
  "CLIMB": "https://assets.coingecko.com/coins/images/14877/thumb/1_PVr7EXwVbk58gRzDOqoHMQ.png",
  "DGT": "https://assets.coingecko.com/coins/images/27507/thumb/ZW_D8QyY_400x400.jpeg",
  "$MLM": "https://assets.coingecko.com/coins/images/27773/thumb/Moonlight-200-200.png",
  "WAI": "https://assets.coingecko.com/coins/images/19591/thumb/wai.png",
  "HDPUNK": "https://assets.coingecko.com/coins/images/17069/thumb/HDPunks.png",
  "VOL": "https://assets.coingecko.com/coins/images/17374/thumb/volatility.PNG",
  "AAMMUNIDAIWETH": "https://assets.coingecko.com/coins/images/17230/thumb/aAmmUniDAIWETH.png",
  "CCDOGE": "https://assets.coingecko.com/coins/images/18084/thumb/logo.png",
  "NFTG": "https://assets.coingecko.com/coins/images/19390/thumb/Logo_-_2021-10-25T160042.014.png",
  "VITO": "https://assets.coingecko.com/coins/images/28527/thumb/Vito200.png",
  "ADANA": "https://assets.coingecko.com/coins/images/26014/thumb/adanaspor.png",
  "HBDC": "https://assets.coingecko.com/coins/images/12814/thumb/%EC%A7%84%ED%92%88.png",
  "LICO": "https://assets.coingecko.com/coins/images/19772/thumb/AjjErjeF_400x400.png",
  "BBH": "https://assets.coingecko.com/coins/images/19213/thumb/bbh.jpeg",
  "STT": "https://assets.coingecko.com/coins/images/21155/thumb/normal_bean_stt-200x200.png",
  "FTF": "https://assets.coingecko.com/coins/images/28020/thumb/logo_cgk.png",
  "EDX": "https://assets.coingecko.com/coins/images/28646/thumb/EDX_SERVICE_TOKEN.png",
  "AAMMUNIDAIUSDC": "https://assets.coingecko.com/coins/images/17218/thumb/aAmmUniDAIUSDC.png",
  "WTON": "https://assets.coingecko.com/coins/images/14823/thumb/wton.png",
  "G-WBTC": "https://assets.coingecko.com/coins/images/28172/thumb/gwbtc.png",
  "AAMMUNISNXWETH": "https://assets.coingecko.com/coins/images/17224/thumb/aAmmUniSNXWETH.png",
  "AAMMUNIUNIWETH": "https://assets.coingecko.com/coins/images/17225/thumb/aAmmUniUNIWETH.png",
  "MYCE": "https://assets.coingecko.com/coins/images/19182/thumb/BuoVrgpA_400x400.png",
  "WTPOKT": "https://assets.coingecko.com/coins/images/24835/thumb/vjCQH6sw.jpg",
  "TRD": "https://assets.coingecko.com/coins/images/20734/thumb/g5IogLIJ_400x400.jpg",
  "DSFR": "https://assets.coingecko.com/coins/images/18672/thumb/1627014783964.png",
  "BLEC": "https://assets.coingecko.com/coins/images/28977/thumb/blec_logo.png",
  "GOT": "https://assets.coingecko.com/coins/images/25900/thumb/20035.png",
  "HMNG": "https://assets.coingecko.com/coins/images/15403/thumb/Untitled-design-8-removebg-preview.png",
  "CIX100": "https://assets.coingecko.com/coins/images/6106/thumb/cix.png",
  "AAMMUNIBATWETH": "https://assets.coingecko.com/coins/images/17217/thumb/aAmmUniBATWETH.png",
  "INUS": "https://assets.coingecko.com/coins/images/22648/thumb/logo.png",
  "STONE": "https://assets.coingecko.com/coins/images/28588/thumb/STONE_Logo.png",
  "MYPO": "https://assets.coingecko.com/coins/images/28131/thumb/mypologo1.png",
  "SILV2": "https://assets.coingecko.com/coins/images/28860/thumb/silv2.png",
  "WNYC": "https://assets.coingecko.com/coins/images/17123/thumb/J53IJI7.png",
  "PLACE": "https://assets.coingecko.com/coins/images/19993/thumb/place.png",
  "BES": "https://assets.coingecko.com/coins/images/22866/thumb/9R4ImfBo_400x400.jpg",
  "CVLC2": "https://assets.coingecko.com/coins/images/28614/thumb/cvlc2.png",
  "BBBR": "https://assets.coingecko.com/coins/images/27156/thumb/IMG_20220901_200536_561.jpg",
  "XJP": "https://assets.coingecko.com/coins/images/11963/thumb/XJP_LOGO.png",
  "LEDU": "https://assets.coingecko.com/coins/images/3451/thumb/LEDU-education-ecosystem.png",
  "MCLB": "https://assets.coingecko.com/coins/images/8052/thumb/milleniumclub-logo.png",
  "ESES": "https://assets.coingecko.com/coins/images/17653/thumb/eses.png",
  "SBECOM": "https://assets.coingecko.com/coins/images/18186/thumb/sbe.PNG",
  "DSS": "https://assets.coingecko.com/coins/images/12602/thumb/DSS.png",
  "AFYON": "https://assets.coingecko.com/coins/images/26019/thumb/67686d7f-4d80-413f-b1fc-a821ad07ce14.png",
  "ETHMAXY": "https://assets.coingecko.com/coins/images/24047/thumb/ethmaxy.png",
  "REFI": "https://assets.coingecko.com/coins/images/15194/thumb/high.png",
  "GDILDO": "https://assets.coingecko.com/coins/images/16641/thumb/gdildo.PNG",
  "KSD": "https://assets.coingecko.com/coins/images/25452/thumb/download.png",
  "NBO": "https://assets.coingecko.com/coins/images/28076/thumb/C3_NBO_Gradient-01.png",
  "IMGNAI": "https://assets.coingecko.com/coins/images/28666/thumb/200x200-Nai.png",
  "CEWBNB": "https://assets.coingecko.com/coins/images/25041/thumb/7192.png",
  "TMSH": "https://assets.coingecko.com/coins/images/26018/thumb/30e05cac-d567-40e1-b6a7-39aa566ecaa7.png",
  "BLKS": "https://assets.coingecko.com/coins/images/24811/thumb/18434.png",
  "URFA": "https://assets.coingecko.com/coins/images/24816/thumb/urfa.png",
  "G-USDC": "https://assets.coingecko.com/coins/images/28175/thumb/gusdc.png",
  "OLEA": "https://assets.coingecko.com/coins/images/29056/thumb/olea.png",
  "G-WETH": "https://assets.coingecko.com/coins/images/28174/thumb/gweth.png",
  "VPP": "https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png",
  "AAMMUNIMKRWETH": "https://assets.coingecko.com/coins/images/17222/thumb/aAmmUniMKRWETH.png",
  "AAMMBPTBALWETH": "https://assets.coingecko.com/coins/images/17261/thumb/aAmmBptBALWETH.png",
  "MMP": "https://assets.coingecko.com/coins/images/16257/thumb/m_mp.PNG",
  "AAMMUNIRENWETH": "https://assets.coingecko.com/coins/images/17223/thumb/aAmmUniRENWETH.png",
  "IZA": "https://assets.coingecko.com/coins/images/25859/thumb/spaces_ebXpMFLzH2sowWKTINL3_uploads_git-blob-99be8625d129c1d92248059e04dffebe380e34b7_Izanagi_light_blue_-_transparent_%282%29.png",
  "MSTO": "https://assets.coingecko.com/coins/images/24020/thumb/eliUfGAG_400x400.jpg",
  "WXMR": "missing_thumb.png",
  "ANW": "https://assets.coingecko.com/coins/images/11104/thumb/logo_scan.png",
  "MFF": "https://assets.coingecko.com/coins/images/23800/thumb/RGJiUIeo_400x400.jpg",
  "WSHARE": "https://assets.coingecko.com/coins/images/25866/thumb/3.png",
  "PODO": "https://assets.coingecko.com/coins/images/27645/thumb/PODO_TICKER_200.png",
  "AVASTR": "https://assets.coingecko.com/coins/images/17034/thumb/AVASTR.png",
  "LIZ": "https://assets.coingecko.com/coins/images/23570/thumb/download_%2847%29.png",
  "EURE": "https://assets.coingecko.com/coins/images/23354/thumb/eur.png",
  "AMWMATIC": "https://assets.coingecko.com/coins/images/17267/thumb/amWMATIC_2x.png",
  "SPADE": "https://assets.coingecko.com/coins/images/17364/thumb/Polygon-Farm-Finance-Logo-200x200.png",
  "ETERNAL": "https://assets.coingecko.com/coins/images/18344/thumb/rn78txF.png",
  "FBA": "https://assets.coingecko.com/coins/images/26164/thumb/logo_token-2.2.png",
  "PSN": "https://assets.coingecko.com/coins/images/18906/thumb/polka.png",
  "CFC": "https://assets.coingecko.com/coins/images/21775/thumb/monster-06.png",
  "FFWOOL": "https://assets.coingecko.com/coins/images/22142/thumb/ffwg-logo-200res.png",
  "GBD": "https://assets.coingecko.com/coins/images/19686/thumb/13606.png",
  "MILADY": "https://assets.coingecko.com/coins/images/26540/thumb/256x256.png",
  "CRI": "https://assets.coingecko.com/coins/images/24700/thumb/logo200x200.png",
  "XENO": "https://assets.coingecko.com/coins/images/24054/thumb/W3OlCRoK_400x400.jpg",
  "EGON": "https://assets.coingecko.com/coins/images/26866/thumb/photo_2022-08-14_20-49-43.jpg",
  "JKT": "https://assets.coingecko.com/coins/images/20556/thumb/14732.png",
  "BKM": "https://assets.coingecko.com/coins/images/27856/thumb/200x200.png",
  "GERMANY": "https://assets.coingecko.com/coins/images/28461/thumb/MEmL3OcS_400x400.jpg",
  "TMTG": "https://assets.coingecko.com/coins/images/6255/thumb/the_midas_touch_gold.png",
  "PADX": "https://assets.coingecko.com/coins/images/25067/thumb/liHrtBjs_400x400.jpg",
  "FORESTPLUS": "https://assets.coingecko.com/coins/images/8927/thumb/forbiddenforest.png",
  "ZHC": "https://assets.coingecko.com/coins/images/26763/thumb/download_%281%29.png",
  "WCV": "https://assets.coingecko.com/coins/images/24849/thumb/WCV-TOKEN-LOGO-Wellness-Group.png",
  "MOONCAT": "https://assets.coingecko.com/coins/images/17055/thumb/Mooncats.png",
  "SERG": "https://assets.coingecko.com/coins/images/26956/thumb/serglogo.png",
  "PPM": "https://assets.coingecko.com/coins/images/28115/thumb/PunkPanda_200x200.png",
  "MEMELON": "https://assets.coingecko.com/coins/images/25587/thumb/SggRCvQ.png",
  "BDRM": "https://assets.coingecko.com/coins/images/26016/thumb/bodrumspor.png",
  "STDOT": "https://assets.coingecko.com/coins/images/27791/thumb/stDOT.png",
  "ZUSDT": "missing_thumb.png",
  "IBETH": "https://assets.coingecko.com/coins/images/13131/thumb/7675.png",
  "MNDCC": "https://assets.coingecko.com/coins/images/18680/thumb/mndcc_bildmarke_256px.png",
  "FANTA": "https://assets.coingecko.com/coins/images/17354/thumb/Football_fantasy.png",
  "HVI": "https://assets.coingecko.com/coins/images/16812/thumb/k%C3%B6r-nincs_h%C3%A1tt%C3%A9r.png",
  "GXP": "https://assets.coingecko.com/coins/images/18471/thumb/gxp.png",
  "QUIPU": "https://assets.coingecko.com/coins/images/19457/thumb/KT193D4vozYnhGJQVtw7CoxxqphqUEEwK6Vb.png",
  "AAMMBPTWBTCWETH": "https://assets.coingecko.com/coins/images/17260/thumb/aAmmBptWBTCWETH.png",
  "CXETH": "https://assets.coingecko.com/coins/images/23682/thumb/k6iYoed.png",
  "AAMMUNILINKWETH": "https://assets.coingecko.com/coins/images/17221/thumb/aAmmUniLINKWETH.png",
  "SURV": "https://assets.coingecko.com/coins/images/23155/thumb/61f253fde5546b0001579b6c_SURV_logo.png",
  "LCDOT": "https://assets.coingecko.com/coins/images/25846/thumb/Lcdot.png",
  "MLP": "https://assets.coingecko.com/coins/images/26204/thumb/4xZ5Nb52_400x400.jpg",
  "GMINESV2": "https://assets.coingecko.com/coins/images/27522/thumb/coingecko.png",
  "DBR": "https://assets.coingecko.com/coins/images/28530/thumb/DBR.png",
  "MMOO": "missing_thumb.png",
  "TAPT": "https://assets.coingecko.com/coins/images/28029/thumb/Taptlogo.png",
  "DAI-MATIC": "https://assets.coingecko.com/coins/images/19344/thumb/io1v25anp7t7nz954gdam2m27eqp4arr93jly4maa.png",
  "ROUL": "missing_thumb.png",
  "SLEEPY": "https://assets.coingecko.com/coins/images/15008/thumb/logo200x200_%283%29.png",
  "QUINS": "https://assets.coingecko.com/coins/images/28491/thumb/Token200x200.png",
  "SWLD": "https://assets.coingecko.com/coins/images/28574/thumb/swld.png",
  "PNIXS": "https://assets.coingecko.com/coins/images/15319/thumb/dxsqGESE_400x400.jpg",
  "UNIDO": "https://assets.coingecko.com/coins/images/28212/thumb/IMG_20221114_031121_847.jpg",
  "NEF": "https://assets.coingecko.com/coins/images/27794/thumb/nef.png",
  "DPWK": "https://assets.coingecko.com/coins/images/25516/thumb/19845.png",
  "WRAB": "https://assets.coingecko.com/coins/images/28454/thumb/wrab.png",
  "GKEN": "https://assets.coingecko.com/coins/images/26388/thumb/GKEN_TOKEN_LOGO.png",
  "XOPENX": "https://assets.coingecko.com/coins/images/28325/thumb/X_op_red_sm.png",
  "TEE": "https://assets.coingecko.com/coins/images/27209/thumb/splogo200.png",
  "AAMMUNIUSDCWETH": "https://assets.coingecko.com/coins/images/17219/thumb/aAmmUniUSDCWETH.png",
  "DCU": "https://assets.coingecko.com/coins/images/26968/thumb/dcu.png",
  "AAMMUNIWBTCWETH": "https://assets.coingecko.com/coins/images/17231/thumb/aAmmUniWBTCWETH.png",
  "SLG": "https://assets.coingecko.com/coins/images/26436/thumb/_SLG.png",
  "AAMMUNIAAVEWETH": "https://assets.coingecko.com/coins/images/17232/thumb/aAmmUniAAVEWETH.png",
  "SHARKS": "https://assets.coingecko.com/coins/images/28807/thumb/Sharks.png",
  "TERC": "https://assets.coingecko.com/coins/images/9253/thumb/1000226.png",
  "AAMMUNIWBTCUSDC": "https://assets.coingecko.com/coins/images/17255/thumb/aAmmUniWBTCUSDC.png",
  "UFLOKI": "https://assets.coingecko.com/coins/images/20475/thumb/NC4ZdXue_400x400.jpg",
  "CHFT": "https://assets.coingecko.com/coins/images/11184/thumb/chft.png",
  "NFAI": "https://assets.coingecko.com/coins/images/28758/thumb/NFAilogo-200x200.png",
  "AGV": "https://assets.coingecko.com/coins/images/21603/thumb/astra_guild.PNG",
  "MCL": "https://assets.coingecko.com/coins/images/27352/thumb/MCL.png",
  "CGU": "https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png",
  "AWRT": "https://assets.coingecko.com/coins/images/24800/thumb/awrt.png",
  "KABA": "https://assets.coingecko.com/coins/images/19604/thumb/YXe-Eret_400x400.jpg",
  "FRB": "https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png",
  "HRTS": "https://assets.coingecko.com/coins/images/25304/thumb/HRTS.png",
  "AUSD": "https://assets.coingecko.com/coins/images/25812/thumb/ezgif-1-f4612f5260.png",
  "$CWI": "https://assets.coingecko.com/coins/images/25350/thumb/logo.png",
  "OPM": "https://assets.coingecko.com/coins/images/12337/thumb/opm-200px.png",
  "CXBTC": "https://assets.coingecko.com/coins/images/25535/thumb/cxBTC.png",
  "GGCM": "https://assets.coingecko.com/coins/images/26265/thumb/200x200.png",
  "PRSG": "https://assets.coingecko.com/coins/images/25440/thumb/9b7b68_151e70f35a55490197cb6d97a234b1de_mv2.png",
  "FLTH": "https://assets.coingecko.com/coins/images/29037/thumb/FLTH.png",
  "CXADA": "https://assets.coingecko.com/coins/images/23536/thumb/cxADA_128x128.png",
  "$TMON": "https://assets.coingecko.com/coins/images/24585/thumb/Gm_8ewah_400x400.jpg",
  "IDLEDAIYIELD": "https://assets.coingecko.com/coins/images/11901/thumb/idledai_32.png",
  "IDLEWBTCYIELD": "https://assets.coingecko.com/coins/images/11936/thumb/idlewbtcv3maxyield_32.png",
  "IDLETUSDYIELD": "https://assets.coingecko.com/coins/images/11935/thumb/idletusdv3mMaxyield_32.png",
  "KSTT": "https://assets.coingecko.com/coins/images/26017/thumb/47b4dcba-9b6c-4b3c-a42a-47ea5aeac7fc.png",
  "DCF": "https://assets.coingecko.com/coins/images/27488/thumb/Main_Token.png",
  "LBXC": "https://assets.coingecko.com/coins/images/9482/thumb/sam7Fy3A_400x400.jpg",
  "EVZ": "https://assets.coingecko.com/coins/images/9456/thumb/kLohzILUIln6mHFYOlecpWjINVIH-BVghP2vRTeuD0XteaQa7Lpn4sLcuPN4gHw8MU2pKWZCJRNwBmyyl1CYxplCLDcgSVihMC7vvfmkepY-_O_ImWBA27s4pKNlhcBnBYrc8y5WH0ZB2CjmqPh-32nPslrv329tqFWr2DAR8dl4R5LZGgeZ1ubCdtMoUua6gEL3umYShHBxrYLto.jpg",
  "ZKB": "https://assets.coingecko.com/coins/images/28912/thumb/zk_cross_chain.png",
  "LML": "https://assets.coingecko.com/coins/images/7238/thumb/LML.png",
  "DSU": "https://assets.coingecko.com/coins/images/17482/thumb/photo_2021-08-03_09-24-16.png",
  "XETH": "https://assets.coingecko.com/coins/images/28972/thumb/xeth.png",
  "WOWS": "https://assets.coingecko.com/coins/images/14365/thumb/wows_logo.png",
  "$FORGE": "https://assets.coingecko.com/coins/images/25411/thumb/Logo_%281%29.png",
  "LPNT": "https://assets.coingecko.com/coins/images/14126/thumb/logo_%2894%29.png",
  "JEUR": "https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png",
  "ETHS": "https://assets.coingecko.com/coins/images/26998/thumb/eth-glyph-colored.png",
  "CEWETH": "https://assets.coingecko.com/coins/images/24827/thumb/weth.png",
  "NEOM": "https://assets.coingecko.com/coins/images/22470/thumb/neom.png",
  "OAV": "https://assets.coingecko.com/coins/images/22025/thumb/Kp4MqrC.png",
  "WHALES": "https://assets.coingecko.com/coins/images/27053/thumb/whales.png",
  "BTCI": "https://assets.coingecko.com/coins/images/19126/thumb/btci.png",
  "OGS": "https://assets.coingecko.com/coins/images/21369/thumb/ogs.png",
  "BEFTM": "https://assets.coingecko.com/coins/images/24283/thumb/beefyescrowedftm_32_%281%29.png",
  "MCG": "https://assets.coingecko.com/coins/images/26663/thumb/B2QwV0BL_400x400.jpeg",
  "KCLP": "https://assets.coingecko.com/coins/images/17272/thumb/KCLP.jpg",
  "OPA": "https://assets.coingecko.com/coins/images/16435/thumb/logo.jpg",
  "EDI": "https://assets.coingecko.com/coins/images/11074/thumb/e6YLf6kD_400x400.jpg",
  "DNZ": "https://assets.coingecko.com/coins/images/18082/thumb/dnz.png",
  "BTFA": "https://assets.coingecko.com/coins/images/25032/thumb/ZZpipp8e_400x400.jpg",
  "DEPI": "https://assets.coingecko.com/coins/images/28008/thumb/logo_200.png",
  "G-USDT": "https://assets.coingecko.com/coins/images/28170/thumb/gusdt.png",
  "YFX": "https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG",
  "BSOL": "https://assets.coingecko.com/coins/images/26636/thumb/blazesolana.png",
  "BFHT": "https://assets.coingecko.com/coins/images/13205/thumb/BFHT_Logo_200.png",
  "DUW": "https://assets.coingecko.com/coins/images/24683/thumb/ncAw1BdF_400x400.jpg",
  "JCG": "https://assets.coingecko.com/coins/images/25701/thumb/Just-Carbon-JCG.png",
  "NFP": "https://assets.coingecko.com/coins/images/24989/thumb/NFPLogoCoin.png",
  "GRNC": "https://assets.coingecko.com/coins/images/21581/thumb/logo-green_%281%29.png",
  "METAI": "https://assets.coingecko.com/coins/images/22233/thumb/16782.png",
  "Q2": "https://assets.coingecko.com/coins/images/27640/thumb/Q2_Logo_Color.png",
  "DCA": "https://assets.coingecko.com/coins/images/7673/thumb/DCA.png",
  "IMBTC": "https://assets.coingecko.com/coins/images/10702/thumb/imbtc.png",
  "GSX": "https://assets.coingecko.com/coins/images/15858/thumb/gsx.PNG",
  "USDTSO": "https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png",
  "YFDT": "https://assets.coingecko.com/coins/images/12824/thumb/yfdt_logo.png",
  "USD": "https://assets.coingecko.com/coins/images/12259/thumb/Uniswap_State_Dollar.png",
  "POLYBUNNY": "https://assets.coingecko.com/coins/images/16855/thumb/token-bunny.a8b61846.png",
  "KOSON": "https://assets.coingecko.com/coins/images/27534/thumb/Koson.png",
  "HYPES": "https://assets.coingecko.com/coins/images/27322/thumb/N9aAdTFH_400x400.jpeg",
  "UNICORN": "https://assets.coingecko.com/coins/images/27233/thumb/unicorn.90630d4c.png",
  "NETC": "https://assets.coingecko.com/coins/images/28655/thumb/Network_Capital_Token.png",
  "CXDOGE": "https://assets.coingecko.com/coins/images/23683/thumb/R2747rb.png",
  "BUSINESSES": "https://assets.coingecko.com/coins/images/23690/thumb/VVE-3_Lh_400x400.jpg",
  "DACT": "https://assets.coingecko.com/coins/images/27700/thumb/logodact200x200.png",
  "SRBP": "https://assets.coingecko.com/coins/images/25825/thumb/6295ae88b46cd60001d5ac25_SRBS_160.png",
  "UBI": "https://assets.coingecko.com/coins/images/15269/thumb/ubi.png",
  "VTRA": "https://assets.coingecko.com/coins/images/27133/thumb/VTRA.png",
  "PLAYMATES": "https://assets.coingecko.com/coins/images/23338/thumb/rnd-coin.png",
  "GFK": "https://assets.coingecko.com/coins/images/27764/thumb/fk-gazisehir-gaziantep-logo-AFC5A8528E-seeklogo.com.png",
  "ST-YCRV": "https://assets.coingecko.com/coins/images/27623/thumb/st-yCRV-128-0x27B5739e22ad9033bcBf192059122d163b60349D.png",
  "YMS": "https://assets.coingecko.com/coins/images/24815/thumb/Yeni_Malatyaspor_logo.svg.png",
  "FBD": "https://assets.coingecko.com/coins/images/27658/thumb/200x200.png",
  "OCCT": "https://assets.coingecko.com/coins/images/20302/thumb/OCCT-SPROCCT_200x200.PNG",
  "MNU": "https://assets.coingecko.com/coins/images/28669/thumb/mnu.png",
  "IHC": "https://assets.coingecko.com/coins/images/18835/thumb/imgonline-com-ua-Resize-QspvgKzsspQpANbd.png",
  "XBTC": "https://assets.coingecko.com/coins/images/22035/thumb/xbtc.png",
  "BSI": "https://assets.coingecko.com/coins/images/19589/thumb/bsi.PNG",
  "UWBTC": "https://assets.coingecko.com/coins/images/14625/thumb/uBTC.png",
  "DBA": "https://assets.coingecko.com/coins/images/19177/thumb/DBA-icon-black-08-01.jpg",
  "UBO": "https://assets.coingecko.com/coins/images/28075/thumb/C3_UBO_Gradient-01.png",
  "CALL": "https://assets.coingecko.com/coins/images/10276/thumb/gca-favicon.png",
  "SMNC": "https://assets.coingecko.com/coins/images/12928/thumb/SMNC-logo.png",
  "FXM": "https://assets.coingecko.com/coins/images/24758/thumb/FXM.png",
  "TPOS": "https://assets.coingecko.com/coins/images/19979/thumb/-BMejjyB_400x400.png",
  "OKAYB": "https://assets.coingecko.com/coins/images/27170/thumb/9815.png",
  "IHF": "https://assets.coingecko.com/coins/images/3747/thumb/ihp.png",
  "BFYC": "https://assets.coingecko.com/coins/images/23455/thumb/luBUevs.png",
  "ECN": "https://assets.coingecko.com/coins/images/18529/thumb/WeChat_Image_20210928174723.jpg",
  "RMOG": "https://assets.coingecko.com/coins/images/22578/thumb/honWJBY.png",
  "MCPC": "https://assets.coingecko.com/coins/images/7669/thumb/Mobile-Crypto-Pay-Coin-logo.png",
  "HTH": "https://assets.coingecko.com/coins/images/5072/thumb/help-the-homeless-coin.png",
  "ANTA": "https://assets.coingecko.com/coins/images/25841/thumb/ANTA-200-200.png",
  "ENFT": "https://assets.coingecko.com/coins/images/26013/thumb/0d83e836-fc1e-4fcc-a3e7-8357bfd33cd2.png",
  "USDI": "https://assets.coingecko.com/coins/images/25999/thumb/20598.png",
  "ATLX": "https://assets.coingecko.com/coins/images/22178/thumb/atlx-200x200.png",
  "ESNC": "https://assets.coingecko.com/coins/images/27923/thumb/esnc.png",
  "BMP": "https://assets.coingecko.com/coins/images/13207/thumb/BMP_logo.png",
  "ECTO": "https://assets.coingecko.com/coins/images/21325/thumb/PDjB5PI.png",
  "SBCC": "https://assets.coingecko.com/coins/images/24822/thumb/V6xfvLzv_400x400.jpg",
  "EHX": "https://assets.coingecko.com/coins/images/24825/thumb/_0VJF4cs_400x400.jpg",
  "ECELL": "https://assets.coingecko.com/coins/images/12624/thumb/ecell_logo_128.png",
  "GDC": "https://assets.coingecko.com/coins/images/10122/thumb/Vp4L1x4Hu4HEuEPUl0gSocDaVifkXlCqSd8A72ml_Q0uwEc9uBWBTwjS43_NP8eiiZVveJDIQXguWUXYk3MLZiFOER-8CGO120I0NGU_L8aTJ8c50a-O_AbkY-4nUkPYhUTNgf3rkcqkXnKecixQwLfkJG2XTCLwNvo_ox05x0_hU1Z__FOT4O7AeU-PC6skc85NCVS68cnZjxcUBOY9K.jpg",
  "CSMATIC": "https://assets.coingecko.com/coins/images/28352/thumb/csMatic.png",
  "CEWFTM": "https://assets.coingecko.com/coins/images/25014/thumb/Fantom.png",
  "DAOJONES": "https://assets.coingecko.com/coins/images/22611/thumb/daojones.png",
  "RSO": "https://assets.coingecko.com/coins/images/28906/thumb/realsociedad.png",
  "B-80BAL-20WETH": "https://assets.coingecko.com/coins/images/26452/thumb/0x5c6ee304399dbdb9c8ef030ab642b10820db8f56.png",
  "FLIBERO": "https://assets.coingecko.com/coins/images/24833/thumb/200x200.png",
  "SPRITZMOON": "https://assets.coingecko.com/coins/images/24401/thumb/SpritzMoon_logo_new.png",
  "IDLEDAISAFE": "https://assets.coingecko.com/coins/images/11929/thumb/idledai-safe.png",
  "BEPR": "https://assets.coingecko.com/coins/images/18743/thumb/BEPR-1-e1633173706568.png",
  "$FJB": "https://assets.coingecko.com/coins/images/21300/thumb/FJB_150.png",
  "BBI": "https://assets.coingecko.com/coins/images/25640/thumb/a.png",
  "CEWBTC": "https://assets.coingecko.com/coins/images/25013/thumb/3717.png",
  "CRTB": "https://assets.coingecko.com/coins/images/27281/thumb/CRTB.png",
  "WER1": "https://assets.coingecko.com/coins/images/27805/thumb/WeR1logo200x200.png",
  "TSF": "https://assets.coingecko.com/coins/images/7497/thumb/TSF.png",
  "OHMI": "https://assets.coingecko.com/coins/images/28625/thumb/ohmi_icon_circle.png",
  "IPT": "https://assets.coingecko.com/coins/images/27000/thumb/logo.white_%281%29.png",
  "WARE": "https://assets.coingecko.com/coins/images/22635/thumb/Ware-Alt_coin1_200x200.png",
  "VASCO": "https://assets.coingecko.com/coins/images/28263/thumb/Asset_16_4x.png",
  "AGRS": "https://assets.coingecko.com/coins/images/399/thumb/agrs.png",
  "VRG": "https://assets.coingecko.com/coins/images/28648/thumb/IMG_20230104_154530_217.png",
  "AUM": "https://assets.coingecko.com/coins/images/24937/thumb/WDpucyvw.png",
  "ACYC": "https://assets.coingecko.com/coins/images/21109/thumb/acyc.png",
  "CBSN": "https://assets.coingecko.com/coins/images/14781/thumb/bsn_dp_high.png",
  "TH": "https://assets.coingecko.com/coins/images/13058/thumb/Heretics-New-10.png",
  "ORBITAL": "https://assets.coingecko.com/coins/images/25896/thumb/nsJnhI8.png",
  "PRNT": "https://assets.coingecko.com/coins/images/22669/thumb/sGFM0un9_400x400.jpg",
  "WXTZ": "https://assets.coingecko.com/coins/images/14763/thumb/wXTZ-token-FullColor.png",
  "BCRE": "https://assets.coingecko.com/coins/images/26806/thumb/bCRE.png",
  "FLU": "https://assets.coingecko.com/coins/images/28756/thumb/flu.png",
  "DSK": "https://assets.coingecko.com/coins/images/25980/thumb/20572.png",
  "DOGMOON": "https://assets.coingecko.com/coins/images/16857/thumb/6U3yZAUU_400x400.jpg",
  "SERUM": "https://assets.coingecko.com/coins/images/26002/thumb/serum.png",
  "CVNT": "https://assets.coingecko.com/coins/images/5158/thumb/cvnt.png",
  "ICNQ": "https://assets.coingecko.com/coins/images/7830/thumb/dda_logo_small.jpg",
  "BAGS": "https://assets.coingecko.com/coins/images/13694/thumb/BAGS.png",
  "$CRYPT": "https://assets.coingecko.com/coins/images/28865/thumb/plain-skull_Large_200x200.png",
  "GBSK": "https://assets.coingecko.com/coins/images/27356/thumb/GBSK.png",
  "THUG": "https://assets.coingecko.com/coins/images/22678/thumb/q4h6GvG6MQfhXXNJTbLILbZY1OIgLqkJNHzNLClHDiw.png",
  "NFMT": "https://assets.coingecko.com/coins/images/26880/thumb/6mZw77As_400x400.jpeg",
  "IDLEUSDCSAFE": "https://assets.coingecko.com/coins/images/11930/thumb/idleusdc-safe.png",
  "YEFIM": "https://assets.coingecko.com/coins/images/12836/thumb/jOVGT0Y.png",
  "BUFC": "https://assets.coingecko.com/coins/images/29034/thumb/bali.png",
  "BSCGIRL": "https://assets.coingecko.com/coins/images/17271/thumb/icon_200px_16bit.png",
  "ABPT": "https://assets.coingecko.com/coins/images/16788/thumb/ABPT_2x.png",
  "CLB": "https://assets.coingecko.com/coins/images/28859/thumb/clb.png",
  "YGNRCH": "https://assets.coingecko.com/coins/images/28840/thumb/ygNRCH_%281%29.png",
  "RSFT": "https://assets.coingecko.com/coins/images/26630/thumb/RSF200x200.png",
  "OUSDC": "https://assets.coingecko.com/coins/images/26943/thumb/ousdc.png",
  "SOBTC": "https://assets.coingecko.com/coins/images/24917/thumb/opengraph.png",
  "BURR": "https://assets.coingecko.com/coins/images/27169/thumb/7ryKn0fdux0PnpBEiwmop9DaFq2KG8WHmGhhtOYPoAU.png",
  "ASS": "https://assets.coingecko.com/coins/images/14912/thumb/XCsenIJ.png",
  "BHP": "https://assets.coingecko.com/coins/images/7541/thumb/logo_%2847%29.png",
  "NASA": "https://assets.coingecko.com/coins/images/16151/thumb/NASA.png",
  "OBELT": "https://assets.coingecko.com/coins/images/28459/thumb/obelt.png",
  "CMF": "https://assets.coingecko.com/coins/images/23028/thumb/200x200.png",
  "P2PS": "https://assets.coingecko.com/coins/images/2798/thumb/p2ps.png",
  "DAKS": "https://assets.coingecko.com/coins/images/28988/thumb/AKS.png",
  "FANTOMAPES": "https://assets.coingecko.com/coins/images/20411/thumb/fantom_opera_apes.PNG",
  "AGT": "https://assets.coingecko.com/coins/images/28822/thumb/.png.",
  "ISET-84E55E": "https://assets.coingecko.com/coins/images/23394/thumb/DoBnk_qx_400x400.jpg",
  "UPT": "https://assets.coingecko.com/coins/images/10136/thumb/yS35aK0t_400x400_%281%29.jpg",
  "IDLEUSDTSAFE": "https://assets.coingecko.com/coins/images/11931/thumb/idleusdt-safe.png",
  "MNYE": "https://assets.coingecko.com/coins/images/25387/thumb/MNY-E-token-logo.png",
  "UDI": "https://assets.coingecko.com/coins/images/28886/thumb/Asset_10.png",
  "BB-A-DAI": "https://assets.coingecko.com/coins/images/25883/thumb/bb-a-dai.png",
  "EPTT": "https://assets.coingecko.com/coins/images/22377/thumb/EPT.png",
  "CRVRENWSBTC": "https://assets.coingecko.com/coins/images/11958/thumb/Curvefi_sbtcCrv_32.png",
  "BPTN": "https://assets.coingecko.com/coins/images/8412/thumb/7f346702db390a289f5770f008563173.png",
  "GDCC": "missing_thumb.png",
  "ETH2": "https://assets.coingecko.com/coins/images/13853/thumb/5fc5b05df7b4c20006fb9fcb_eth_2.0-01.png",
  "ETH2.0": "https://assets.coingecko.com/coins/images/27305/thumb/Screenshot_2022-09-22_173342-removebg-preview.png",
  "FCF": "https://assets.coingecko.com/coins/images/19089/thumb/transparent_background.png",
  "TEC": "https://assets.coingecko.com/coins/images/18233/thumb/72481541.png",
  "PPS": "https://assets.coingecko.com/coins/images/26660/thumb/PupazziLogo.png",
  "EAC": "https://assets.coingecko.com/coins/images/25466/thumb/eac.png",
  "OMATIC": "https://assets.coingecko.com/coins/images/26993/thumb/download_%281%29.png",
  "ELP": "https://assets.coingecko.com/coins/images/16230/thumb/y24nMGj.png",
  "0X0": "https://assets.coingecko.com/coins/images/28880/thumb/0x0.png",
  "USDCPO": "https://assets.coingecko.com/coins/images/22945/thumb/USDCpo_wh_small.png",
  "BGS": "https://assets.coingecko.com/coins/images/22285/thumb/K3hU77wS_400x400.jpg",
  "TLOD": "https://assets.coingecko.com/coins/images/19145/thumb/told.png",
  "OHANDY": "https://assets.coingecko.com/coins/images/28460/thumb/oHANDY.png",
  "OCTSMM": "https://assets.coingecko.com/coins/images/27095/thumb/photo_2022-08-21_06.15.02.jpeg",
  "DEAR": "https://assets.coingecko.com/coins/images/26525/thumb/logo_pure_black.png",
  "ARTEQ": "https://assets.coingecko.com/coins/images/23407/thumb/arteq.png",
  "KUN": "https://assets.coingecko.com/coins/images/13177/thumb/kun_logo.png",
  "G3CRV": "https://assets.coingecko.com/coins/images/24284/thumb/Untitled_design_%282%29.png",
  "COLLG": "https://assets.coingecko.com/coins/images/17026/thumb/collg.PNG",
  "ULU": "https://assets.coingecko.com/coins/images/12376/thumb/ulu_finance_logo.ico",
  "$REACT": "https://assets.coingecko.com/coins/images/25034/thumb/qCOTjLd.png",
  "SEJUNO": "https://assets.coingecko.com/coins/images/27110/thumb/seJuno.png",
  "SOETH": "https://assets.coingecko.com/coins/images/24918/thumb/6250754.png",
  "OXRP": "https://assets.coingecko.com/coins/images/27009/thumb/download_%283%29.png",
  "DGLD": "https://assets.coingecko.com/coins/images/24361/thumb/gld.JPG",
  "FTMX": "https://assets.coingecko.com/coins/images/24759/thumb/XFTM.png",
  "DIFX": "https://assets.coingecko.com/coins/images/23241/thumb/difx.png",
  "TIGERS": "https://assets.coingecko.com/coins/images/28517/thumb/TIGERS_10.png",
  "BB-A-USDC": "https://assets.coingecko.com/coins/images/25780/thumb/bb-a-usdc.png",
  "CVA": "https://assets.coingecko.com/coins/images/10071/thumb/logo_tondo_%281%29.png",
  "DOGE-1": "https://assets.coingecko.com/coins/images/23778/thumb/IMMAGINE-PER-CMC.png",
  "WGRT": "https://assets.coingecko.com/coins/images/12118/thumb/5630.png",
  "CMUMAMI": "https://assets.coingecko.com/coins/images/28433/thumb/Cqx8QjSL_400x400.jpeg",
  "UMOON": "https://assets.coingecko.com/coins/images/15674/thumb/MoonCats.jpg",
  "STINK": "https://assets.coingecko.com/coins/images/28390/thumb/Cg_size.jpg",
  "IPUNKS": "https://assets.coingecko.com/coins/images/28566/thumb/ipunks.png",
  "IOEN": "https://assets.coingecko.com/coins/images/19095/thumb/12799.png",
  "BITCOIN": "https://assets.coingecko.com/coins/images/21453/thumb/PYNP2xv.jpeg",
  "PMLG": "https://assets.coingecko.com/coins/images/25396/thumb/19329.png",
  "VRGW": "https://assets.coingecko.com/coins/images/27395/thumb/IMG_20220921_091002_981.png",
  "AIB": "https://assets.coingecko.com/coins/images/1337/thumb/AIB_logo.png",
  "BB-A-USDT": "https://assets.coingecko.com/coins/images/25826/thumb/0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c.png",
  "GRAVIAURA": "https://assets.coingecko.com/coins/images/26368/thumb/graviaura.png",
  "CUTE": "https://assets.coingecko.com/coins/images/8328/thumb/bnLvIEl1_400x400.jpg",
  "DAFT": "https://assets.coingecko.com/coins/images/25791/thumb/39d0204c-d1be-4638-88da-c3483683f277_%281%29.png",
  "SCE": "https://assets.coingecko.com/coins/images/27059/thumb/sce.png",
  "SACI": "https://assets.coingecko.com/coins/images/27556/thumb/Asset_14_4x.png",
  "DQQQ": "https://assets.coingecko.com/coins/images/24359/thumb/qqq.JPG",
  "MMETA": "https://assets.coingecko.com/coins/images/24186/thumb/MMETA_200X200.png",
  "CPFC": "https://assets.coingecko.com/coins/images/27989/thumb/Asset_74_4x.png",
  "CMI": "https://assets.coingecko.com/coins/images/23542/thumb/CMI_200x200.png",
  "USDTPO": "https://assets.coingecko.com/coins/images/22944/thumb/USDTpo_wh_small.png",
  "ASTETH": "https://assets.coingecko.com/coins/images/24163/thumb/steth.jpg",
  "NFUP": "https://assets.coingecko.com/coins/images/21052/thumb/logo_-_2021-11-30T163428.088.png",
  "WORK": "https://assets.coingecko.com/coins/images/18546/thumb/mXSw8Qw.png",
  "FIG": "https://assets.coingecko.com/coins/images/24518/thumb/fig.png",
  "INNBC": "https://assets.coingecko.com/coins/images/4043/thumb/INNBC.png",
  "CITIZEN": "https://assets.coingecko.com/coins/images/17751/thumb/QmQmZNp7JNdvYAA8ichVr5bVZUUTfU83zJ8hTZoQfb9YBh.png",
  "GSI": "https://assets.coingecko.com/coins/images/27778/thumb/F6B68321-DEBD-40AE-8B78-EC07EF14D0CD.png",
  "HPB": "https://assets.coingecko.com/coins/images/2363/thumb/HPB_Logo_2020_-_Colour.jpg",
  "CBETH": "https://assets.coingecko.com/coins/images/27008/thumb/cbeth.png",
  "NMOON": "https://assets.coingecko.com/coins/images/25209/thumb/8nZJL9eQ_400x400_%281%29.jpg",
  "USPC": "https://assets.coingecko.com/coins/images/28855/thumb/Full-Color-Black200x200.png",
  "GTX": "https://assets.coingecko.com/coins/images/26289/thumb/jEKTizMb_400x400.png",
  "SOLIDSEX": "https://assets.coingecko.com/coins/images/23992/thumb/solidSEX.png",
  "DARKK": "https://assets.coingecko.com/coins/images/24716/thumb/iShot2022-03-31_11.05.19.png",
  "GAN": "https://assets.coingecko.com/coins/images/19925/thumb/gan.png",
  "KSK": "https://assets.coingecko.com/coins/images/17650/thumb/ksk.png",
  "NPAS": "https://assets.coingecko.com/coins/images/24660/thumb/S3i9bI6n_400x400.jpg",
  "OETH": "https://assets.coingecko.com/coins/images/26274/thumb/-p1Br7oh_400x400.png",
  "IWFT": "https://assets.coingecko.com/coins/images/26022/thumb/a8708129-4ea6-40aa-827f-d6f84869016a.png",
  "AM3CRV": "https://assets.coingecko.com/coins/images/26740/thumb/W1sQNVWo_400x400.jpeg",
  "BXA": "https://assets.coingecko.com/coins/images/7842/thumb/images.png",
  "MCREAL": "https://assets.coingecko.com/coins/images/26214/thumb/asset_mcREAL.png",
  "JCHF": "https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png",
  "BLCT": "https://assets.coingecko.com/coins/images/10810/thumb/esRodCO5_400x400.jpg",
  "ACCG": "https://assets.coingecko.com/coins/images/23744/thumb/accg.png",
  "NZDS": "https://assets.coingecko.com/coins/images/20394/thumb/nzds.png",
  "DRGNZ": "https://assets.coingecko.com/coins/images/24386/thumb/AzJI7FQ.png",
  "IBP": "https://assets.coingecko.com/coins/images/12903/thumb/ibp.",
  "FKSK": "https://assets.coingecko.com/coins/images/26015/thumb/869fbe8b-ced8-465c-97b8-ec3bf0ea23b1.png",
  "FOOTBALL": "https://assets.coingecko.com/coins/images/27423/thumb/10de6243e1947996c24f96f109fd3480.jpeg",
  "HYSS": "https://assets.coingecko.com/coins/images/24589/thumb/wbQp6YlL_400x400.jpg",
  "BAHIA": "https://assets.coingecko.com/coins/images/29005/thumb/Esporte_Clube_Bahia.png",
  "CNF": "https://assets.coingecko.com/coins/images/21780/thumb/cnf.png",
  "SANA": "https://assets.coingecko.com/coins/images/17666/thumb/20210811154811.png",
  "QSD": "https://assets.coingecko.com/coins/images/17985/thumb/QSD_.png",
  "TUSC": "https://assets.coingecko.com/coins/images/3953/thumb/favicon-1.png",
  "JJPY": "https://assets.coingecko.com/coins/images/24327/thumb/jJPY.png",
  "SRMET": "https://assets.coingecko.com/coins/images/22978/thumb/SRMet_wh_small.png",
  "OUSDT": "https://assets.coingecko.com/coins/images/26273/thumb/-p1Br7oh_400x400.png",
  "DBS": "https://assets.coingecko.com/coins/images/25489/thumb/27e0a1_7f0b762e0eeb4584af7cff0fdccedc8e_mv2.png",
  "UTO": "https://assets.coingecko.com/coins/images/24292/thumb/cOuu2J0f_400x400.jpg",
  "DSLV": "https://assets.coingecko.com/coins/images/24367/thumb/SLV.JPG",
  "AXSET": "https://assets.coingecko.com/coins/images/22952/thumb/AXSet_wh_small.png",
  "YVBOOST": "https://assets.coingecko.com/coins/images/15152/thumb/yvBOOST.png",
  "XGEM": "https://assets.coingecko.com/coins/images/22535/thumb/17200.png",
  "JGBP": "https://assets.coingecko.com/coins/images/15726/thumb/jGBP.png",
  "TSUGA": "https://assets.coingecko.com/coins/images/19611/thumb/tsuga.png",
  "STKABPT": "https://assets.coingecko.com/coins/images/16789/thumb/StkABPT_2x.png",
  "DAAPL": "https://assets.coingecko.com/coins/images/24365/thumb/aapl.JPG",
  "MADAI": "https://assets.coingecko.com/coins/images/14075/thumb/maDAI.png",
  "CVAG": "https://assets.coingecko.com/coins/images/17124/thumb/cvag.PNG",
  "DPUNKZ": "https://assets.coingecko.com/coins/images/27171/thumb/2588.png",
  "BHSC": "https://assets.coingecko.com/coins/images/12347/thumb/blackhole.PNG",
  "OORC": "https://assets.coingecko.com/coins/images/28458/thumb/oorc.png",
  "DTSLA": "https://assets.coingecko.com/coins/images/24288/thumb/Untitled_%285_%C3%97_3_cm%29_%281%29.png",
  "MAYFI": "https://assets.coingecko.com/coins/images/14078/thumb/maYFI.png",
  "DLP": "https://assets.coingecko.com/coins/images/27698/thumb/DLP_Logo-1.png",
  "OWBTC": "https://assets.coingecko.com/coins/images/27278/thumb/download_%285%29.png",
  "MAAAVE": "https://assets.coingecko.com/coins/images/14077/thumb/maAAVE.png",
  "INUJUMP": "https://assets.coingecko.com/coins/images/22758/thumb/logo_%282%29.png",
  "EVDC": "https://assets.coingecko.com/coins/images/17406/thumb/EVDC-01-1-2.png",
  "CHIZ": "https://assets.coingecko.com/coins/images/19147/thumb/ZXb2HoUG_400x400.jpg",
  "MAUSDC": "https://assets.coingecko.com/coins/images/14074/thumb/maUSDC.png",
  "MAWETH": "https://assets.coingecko.com/coins/images/14081/thumb/maWETH.png",
  "DNVDA": "https://assets.coingecko.com/coins/images/24709/thumb/iShot2022-03-31_10.00.33.png",
  "DAMZN": "https://assets.coingecko.com/coins/images/24357/thumb/dAMZN.JPG",
  "DSPY": "https://assets.coingecko.com/coins/images/24712/thumb/iShot2022-03-31_10.43.28.png",
  "MALINK": "https://assets.coingecko.com/coins/images/14082/thumb/maLINK.png",
  "DGOOGL": "https://assets.coingecko.com/coins/images/24715/thumb/iShot2022-03-31_11.01.45.png",
  "OBNB": "https://assets.coingecko.com/coins/images/26991/thumb/oBNB.png",
  "USDCET": "https://assets.coingecko.com/coins/images/23019/thumb/USDCet_wh_small.png",
  "DNFLX": "https://assets.coingecko.com/coins/images/24358/thumb/nflx.JPG",
  "LPDI": "https://assets.coingecko.com/coins/images/18866/thumb/lpdi.PNG",
  "DBABA": "https://assets.coingecko.com/coins/images/24708/thumb/2.png",
  "USDTE": "https://assets.coingecko.com/coins/images/24189/thumb/Usdt.e.png",
  "ABBUSD": "https://assets.coingecko.com/coins/images/23061/thumb/logo_-_2022-01-26T091043.556.png",
  "SESCRT": "https://assets.coingecko.com/coins/images/25504/thumb/scrt4.png",
  "DGME": "https://assets.coingecko.com/coins/images/24707/thumb/1.png",
  "DFB": "https://assets.coingecko.com/coins/images/24717/thumb/iShot2022-03-31_11.12.14.png",
  "ACUSD": "https://assets.coingecko.com/coins/images/23057/thumb/7236.png",
  "DAPE": "https://assets.coingecko.com/coins/images/27054/thumb/dape.png",
  "DPLTR": "https://assets.coingecko.com/coins/images/24710/thumb/iShot2022-03-31_10.30.01.png",
  "DCOIN": "https://assets.coingecko.com/coins/images/24711/thumb/iShot2022-03-31_10.38.14.png",
  "DMSFT": "https://assets.coingecko.com/coins/images/24714/thumb/iShot2022-03-31_10.58.14.png",
  "USDTET": "https://assets.coingecko.com/coins/images/23025/thumb/USDTet_wh_small.png",
  "EMTRG": "https://assets.coingecko.com/coins/images/12175/thumb/Dark-blue-icon-transparent-vector-white-icon200x200.png",
  "DVP": "https://assets.coingecko.com/coins/images/9424/thumb/4520.png",
  "DUBI": "https://assets.coingecko.com/coins/images/2606/thumb/decentralized-universal-basic-income.png",
  "APUSDT": "https://assets.coingecko.com/coins/images/23060/thumb/logo_-_2022-01-26T084912.902.png",
  "NETH": "https://assets.coingecko.com/coins/images/24161/thumb/bEth_%281%29.png",
  "IBTCV": "https://assets.coingecko.com/coins/images/16718/thumb/logo_-_2021-06-28T093119.133.png",
  "NLUNA": "https://assets.coingecko.com/coins/images/24159/thumb/bLuna.png",
  "IETHV": "https://assets.coingecko.com/coins/images/16715/thumb/logo_-_2021-06-28T085526.083.png",
  "ITA": "https://assets.coingecko.com/coins/images/27992/thumb/Asset_6_4x.png",
  "REALT-S-11957-OLGA-ST-DETROIT-MI": "https://assets.coingecko.com/coins/images/16672/thumb/11957-Olga-hero-1.jpg",
  "KEES": "https://assets.coingecko.com/coins/images/27513/thumb/lo2XCLcf_400x400.jpeg",
  "AITN": "https://assets.coingecko.com/coins/images/26270/thumb/aitn-200.png",
  "DCIP": "https://assets.coingecko.com/coins/images/17274/thumb/DCIP200.png",
  "REALT-S-1617-S.AVERS-AVE-CHICAGO-IL": "https://assets.coingecko.com/coins/images/16675/thumb/1617-S-Avers-hero-1.jpg",
  "DEEM": "https://assets.coingecko.com/coins/images/24362/thumb/eem.JPG",
  "REALT-S-1815-S.AVERS-AVE-CHICAGO-IL": "https://assets.coingecko.com/coins/images/16674/thumb/1815-S-Avers-hero-1.5.jpg",
  "DTLT": "https://assets.coingecko.com/coins/images/24360/thumb/tlt.JPG",
  "REALT-S-12405-SANTA-ROSA-DR-DETROIT-MI": "https://assets.coingecko.com/coins/images/16671/thumb/12405-Santa-Rosa-hero-1.jpg",
  "DVNQ": "https://assets.coingecko.com/coins/images/24364/thumb/vnq.JPG",
  "DVOO": "https://assets.coingecko.com/coins/images/24363/thumb/voo.JPG",
  "REALT-S-14066-SANTA-ROSA-DR-DETROIT-MI": "https://assets.coingecko.com/coins/images/16677/thumb/14066-Santa-Rosa-hero-1.5.jpg",
  "DURTH": "https://assets.coingecko.com/coins/images/24366/thumb/urth.JPG",
  "REALT-S-4852-4854-W.CORTEZ-ST-CHICAGO-IL": "https://assets.coingecko.com/coins/images/16670/thumb/Cortez-facade-1.jpg",
  "APEAMC": "https://assets.coingecko.com/coins/images/27267/thumb/apeamc.png",
  "MBCC": "https://assets.coingecko.com/coins/images/17328/thumb/mbcc.jpeg"
}

import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/login";
import toastReducer from "./slices/toast";
import licensesReducer from "./slices/licenses";
import tabsReducer from "./slices/tabs";
import entitiesReducer from "./slices/entities";
import supportedCountriesReducer from "./slices/supportedcountries";
import vaspsReducer from "./slices/vasps";
import USsupportedReducer from "./slices/USsupported";
import supportedAssetsReducer from "./slices/supportedAssets";
import restrictedCountriesReducer from "./slices/restrictedCountries";
import entityKYCReducer from "./slices/entityKYC";
import foundersReducer from "./slices/founders";
import onlyCountriesReducer from "./slices/onlyCountries";
import alertsReducer from "./slices/alert";
import filtersReducer from "./slices/filters";
import legalInfoReducer from "./slices/legalInfo";
import regulatoryAlerts from "./slices/regulatory-alerts";
import allLicenses from "./slices/allLicenses"
export const store = configureStore({
  reducer: {
    login: loginReducer,
    toast: toastReducer,
    licenses: licensesReducer,
    tabs: tabsReducer,
    entities: entitiesReducer,
    supportedCountries: supportedCountriesReducer,
    vasps: vaspsReducer,
    USsupported: USsupportedReducer,
    supportedAssets: supportedAssetsReducer,
    restrictedCountries: restrictedCountriesReducer,
    entityKYC: entityKYCReducer,
    founders: foundersReducer,
    onlyCountries: onlyCountriesReducer,
    alerts: alertsReducer,
    filters: filtersReducer,
    legalInfo: legalInfoReducer,
    regalerts: regulatoryAlerts,
    allLicenses:allLicenses
  },
});

// components/GeoChart.js
import React, { useEffect } from 'react';
import { getAlerts,getAlertsLoading } from '../../redux/slices/regulatory-alerts';
import { useSelector } from 'react-redux';
import RegulatoryAlertTable from '../RegulatoryAlertTable';
const GeoChart = () => {
    const regalerts = useSelector(getAlerts);
    const regalertsLoading = useSelector(getAlertsLoading);

  useEffect(() => {
    // Ensure google charts library is loaded
    if (window.google && window.google.charts) {
      window.google.charts.load('current', {
        'packages': ['geochart'],
      });
      window.google.charts.setOnLoadCallback(drawRegionsMap);
    }

    function drawRegionsMap() {
        if(regalerts.regalerts){
            // let array = [
            //     ['Country', 'Popularity'],
            // ];
            const newCountryCounts = regalerts.regalerts.reduce((acc, alert) => ({ ...acc, [alert.country]: (acc[alert.country] || 0) + 1 }), {});
            const transformedData = Object.entries(newCountryCounts).map(([country, count]) => [country, count]);
            transformedData.unshift(['Country', 'Alerts']);





            
            // console.log("arr",newCountryCounts);
            // regulatoryAlerts.regalerts.map(d=>{return [d.name,d.]})
            // ['US', 200],
            //     ['AR', 300],
            //     ['Brazil', 400],
            //     ['Canada', 500],
            //     ['France', 600],
            //     ['RU', 700],
          const data = window.google.visualization.arrayToDataTable(transformedData);
    
          const options = {
            colorAxis: {
              colors: ['#5c73f2', '#4b4bd6', '#0707ed'],  // You can add more colors here as needed.
              values: [1, 50, 200]  // Adjust this based on your data's range
            },
            backgroundColor:'#000'
          };
    
          const chart = new window.google.visualization.GeoChart(document.getElementById('regions_div'));
    
          chart.draw(data, options);
        }
        
    }
  }, [regalerts]);
  const headerTitleClass =
    "px-2 md:px-12 py-2 font-bold text-primary text-lg text-center";

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + " . . ." : str;
  };

  return (
    <>
      <div id="regions_div" style={{ width: '90%', height: '500px',margin:'5%' }}></div>
      {regalerts.regalerts&&
        <RegulatoryAlertTable alerts={regalerts.regalerts} alertsLoading={regalertsLoading} headerTitleClass={headerTitleClass} truncate={truncate} showEntityName={true}/>
      }
    </>
  );
};

export default GeoChart;

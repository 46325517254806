import { countries } from "../utils/countries";

const getCountryCode = (countryName) => {
  let code = null;
  countries.map((item) => {
    if (item.name === countryName) code = item.code;
  });
  return code;
};

export const countryFlag = (country) => {
  if (country?.includes("Abu") || country?.includes("Dubai")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (country?.includes("Washington")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (country?.includes("Moldova")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/MD.svg`}
        className="w-12 h-12 object-cover"
      />
    );
  } else if (country?.includes("Saint Vincent")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/VC.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (country?.includes("Russia")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/RS.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (country?.includes("Cabada")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (country?.includes("Vietnam")) {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg`}
        className="w-full h-full object-cover"
      />
    );
  } else if (
    country === "No" ||
    country === "" ||
    getCountryCode(country) === null
  ) {
    return <></>;
    //   } else if (country?.includes("Hong Kong")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/HK.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Iran")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/IR.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Bosnia")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/BA.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Comoros")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/KM.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Vietnam")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("China")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Russia")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/RS.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Nevis")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/KN.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Macedonia")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/MK.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
    //   } else if (country?.includes("Latvia")) {
    //     return (
    //       <img
    //         alt={country}
    //         src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/LV.svg`}
    //         className="w-12 h-12 object-cover"
    //       />
    //     );
  } else {
    return (
      <img
        alt={country}
        onError={(e) => (e.target.style.display = "none")}
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${getCountryCode(
          country
        )}.svg`}
        className="w-full h-full object-cover"
      />
    );
  }
};

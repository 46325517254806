import newsImg from "../assets/images/news-logo.png";
import { CircleLoading, NoItems } from "../features/Symbols";
import { countryFlag } from "../features/Countries";

const RegulatoryAlertTable = ({alerts,alertsLoading,headerTitleClass,truncate,showEntityName})=>{
    return(
    <>
        <div className="flex flex-col items-start bg-[#161616] m-3 md:m-5 px-2 md:px-8 py-5 rounded-lg md:rounded-2xl shadow-md shadow-[#252233]">
          <div className="flex flex-col w-full">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 scrollbar-hide">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  {alertsLoading === "pending" ? (
                    <CircleLoading />
                  ) : (
                    <table className="w-full h-full min-w-[800px] text-left text-sm font-light">
                      <thead className="font-medium ">
                        <tr className="">
                        {showEntityName&&
                          <th scope="col" className={headerTitleClass}>
                            Entity
                          </th>}
                          <th scope="col" className={headerTitleClass}></th>
                          
                          <th scope="col" className={headerTitleClass}>
                            Description
                          </th>
                          <th
                            scope="col"
                            className={`whitespace-nowrap ${headerTitleClass}`}
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className={`whitespace-nowrap ${headerTitleClass}`}
                          >
                            Link
                          </th>
                          <th scope="col" className={headerTitleClass}>
                            Date
                          </th>
                          <th scope="col" className={headerTitleClass}>
                            Country
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {alerts[0] && alerts?.map((item, i) => (
                          <tr
                            className={`transition duration-300 ease-in-out hover:bg-primary ${
                              i !== -1 ? " border-b-2 border-[#F1F0F0]" : ""
                            }`}
                            key={item.id}
                          >{showEntityName&&
                            <td className={`h-full text-center text-xl font-normal text-primary whitespace-nowrap`}>
                                {item.internal_name}
                            </td>}
                            <td className={` items-center justify-center`}>
                              <div className="flex items-center w-24 h-24 my-3">
                                {item.logo ? (
                                  <img
                                    src={item.logo}
                                    alt="newslogo"
                                  />
                                ) : (
                                  <div className=" bg-inActive w-full h-full rounded-full"></div>
                                )}
                              </div>
                            </td>
                            <td className={`text-center min-w-[355px]`}>
                              <div className="px-5">
                                <p className="text-primary text-base font-semibold leading-4 text-left pb-2.5 pt-0 md:pt-2 xl:pt-2">
                                  {item.regulatory_authority}
                                </p>
                                <p className="text-primary text-sm font-light text-left leading-4 pb-2">
                                  {truncate(item.note, 300)}
                                </p>
                              </div>
                            </td>
                            <td className={`text-center `}>
                              <p className="flex items-center justify-center text-xs bg-[#B0D51A] rounded-full px-5 py-1 text-black font-bold">
                                {item.alert_type}
                              </p>
                            </td>
                            <td>
                              <a
                                href={item.link}
                                target="_blank"
                                className="flex justify-center"
                              >
                                <svg
                                  width="26"
                                  height="26"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 13.0303C-0.00762939 7.96826 3.03577 3.40039 7.71014 1.45801C12.3846 -0.484375 17.7689 0.581543 21.3509 4.15771C24.9329 7.73437 26.0068 13.1172 24.0715 17.7944C22.1361 22.4722 17.573 25.522 12.511 25.5225C5.60986 25.5195 0.0131226 19.9312 0 13.0303V13.0303ZM2.35901 13.0303C2.35284 17.1377 4.82269 20.8442 8.61615 22.4209C12.4097 23.9971 16.7792 23.1318 19.686 20.2295C22.5929 17.3267 23.4642 12.9585 21.8935 9.1626C20.3228 5.36719 16.6194 2.8916 12.5115 2.89209C6.91101 2.89404 2.36932 7.42969 2.35895 13.0298L2.35901 13.0303ZM7.73901 17.7964C7.42761 17.4873 7.31525 17.0298 7.448 16.6118L9.32501 10.6211C9.44025 10.252 9.72913 9.96289 10.098 9.84766L16.098 7.97314C16.5165 7.84131 16.9736 7.95361 17.283 8.26514V8.26514C17.5944 8.57422 17.7068 9.03174 17.574 9.44971L15.697 15.4404C15.5817 15.8091 15.2927 16.0977 14.924 16.2119L8.92401 18.0864C8.81018 18.123 8.69147 18.1416 8.57196 18.1421C8.25928 18.1421 7.95947 18.0171 7.73901 17.7954V17.7964ZM11.391 11.9126L10.372 15.1665L13.633 14.1475L14.652 10.8906L11.391 11.9126Z"
                                    fill="#85A3BB"
                                  />
                                </svg>
                              </a>
                            </td>
                            <td
                              className={`h-full text-center text-xl font-normal text-primary whitespace-nowrap`}
                            >
                              {item.date}
                            </td>
  
                            <td className="">
                              <div
                                className="flex items-center justify-center mr-3"
                                title={
                                  item.country === "Australia"
                                    ? "This data is self-reported. Please contact AUSTRAC for more information"
                                    : item.country
                                }
                              >
                                <span className="w-9 h-9 rounded-full overflow-hidden">
                                  {countryFlag(item.country)}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  {alertsLoading !== "pending" && alerts.length === 0 && (
                    <NoItems />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      )

}
export default RegulatoryAlertTable;
import React, { useEffect } from "react";
import ListLegalNames from "../components/ListLegalNames";
import { getCurrentEntity } from "../redux/slices/entities";
import { useSelector } from "react-redux";
import CountriesJVMap from "../components/CountriesJVMap";

const LegalInfo = () => {
  const currentEntity = useSelector(getCurrentEntity);

  return (
    <div className="bg-black pt-16 md:pt-24 pb-10 h-screen overflow-scroll scrollbar-hide">
      <CountriesJVMap />
      {currentEntity && <ListLegalNames />}
    </div>
  );
};

export default LegalInfo;

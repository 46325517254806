import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchUSsupported = createAsyncThunk(
  "USsupported/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/states-supported?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const getfilteredStates = (data) => {
  let list = {};
  for (let i = 0; i < data[0]?.supported_states.length; i++) {
    list["US-" + data[0]?.supported_states[i]] = 1;
  }

  return list;
};

const getfilteredStates_1 = (data) => {
  let list = [];
  for (let i = 0; i < data[0]?.supported_states.length; i++) {
    let temp = {};
    temp["country"] = data[0]?.supported_states[i];
    temp["type"] = "supported";
    list.push(temp);
  }

  return list;
};

const initialState = {
  USsupported: [],
  USsupported_1: [],
  USloading: "idle",
};

const USsupportedslice = createSlice({
  name: "USsupported",
  initialState,
  reducers: {
    countriesClear: (state) => {
      state.USloading = "idle";
      state.USsupported = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUSsupported.pending, (state, action) => {
      state.USloading = "pending";
    });
    builder.addCase(fetchUSsupported.fulfilled, (state, action) => {
      state.USloading = "idle";
      state.USsupported_1 = getfilteredStates_1(action.payload);
      state.USsupported = getfilteredStates(action.payload);
    });
    builder.addCase(fetchUSsupported.rejected, (state, action) => {
      state.USloading = "failed";
    });
  },
});

export const { countriesClear } = USsupportedslice.actions;

export const getUSsupported = (state) => state.USsupported.USsupported;
export const getUSsupported_1 = (state) => state.USsupported.USsupported_1;
export const getUSloading = (state) => state.USsupported.USloading;

export default USsupportedslice.reducer;

import axios from "axios";
import { config } from "../../helper/config";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const fetchSupportedCountries = createAsyncThunk(
  "supportedCountries/getAPI",
  async (entity) => {
    const accessToken = JSON.parse(localStorage.getItem("vasp-token"));
    const response = await axios.get(
      `${config.API_BASE_URL}/countries-supported?entity=${entity}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
);

const allCountries = (data) => {
  let _allCountries = [];
  if (data) {
    for (let i = 0; i < data[0]?.supported_countries?.length; i++) {
      _allCountries.push({
        country: data[0].supported_countries[i],
        type: "supported",
      });
    }
    for (let i = 0; i < data[0]?.not_supported_countries?.length; i++) {
      _allCountries.push({
        country: data[0].not_supported_countries[i],
        type: "non-supported",
      });
    }
    for (let i = 0; i < data[0]?.other?.length; i++) {
      _allCountries.push({ country: data[0].other[i], type: "other" });
    }
  }

  return _allCountries;
};

const initialState = {
  allCountries: [],
  supportedCountries: [],
  loading: "idle",
};

const supportedcountriesslice = createSlice({
  name: "supportedCountries",
  initialState,
  reducers: {
    countriesClear: (state) => {
      state.loading = "idle";
      state.supportedCountries = [];
    },
    initCLoading: (state) => {
      state.loading = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupportedCountries.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchSupportedCountries.fulfilled, (state, action) => {
      state.loading = "idle";
      state.allCountries = allCountries(action.payload);
      state.supportedCountries = action.payload;
    });
    builder.addCase(fetchSupportedCountries.rejected, (state, action) => {
      state.loading = "failed";
    });
  },
});

export const { countriesClear, initCLoading } = supportedcountriesslice.actions;

export const getSupportedCountries = (state) =>
  state.supportedCountries.supportedCountries;
export const getAllCountries = (state) => state.supportedCountries.allCountries;
export const getCLoading = (state) => state.supportedCountries.loading;

export default supportedcountriesslice.reducer;

import React, { useState } from "react";
import AlertImg from "../assets/images/alert-circle.png";
import DotImg from "../assets/images/dots.png";
import TrafficInformation from "./TrafficInformation";
// import GeoChart from "./GeoChart";

const WebTrafficMap = () => {
  const [show, setShow] = useState(true);
  const [geoData, setGeoData] = useState([['Country']])
  return (
    <div className="relative">
      <div className="absolute top-5 z-20 flex justify-between w-full px-10">
        <p className="flex items-center text-primary font-bold text-[27px]">
          Web Traffic
          <img src={AlertImg} alt="alert" className="mx-1" />
        </p>
        <button onClick={() => setShow(!show)}>
          <img src={DotImg} alt="dots" />
        </button>
      </div>
      {show && (
        <div className="absolute top-28 z-20 px-10">
          <TrafficInformation />
        </div>
      )}
      {/* <GeoChart geoData={geoData} /> */}
    </div>
  );
};

export default WebTrafficMap;

import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LegalInfo from "./pages/LegalInfo";
import KYC from "./pages/KYC";
import RegulatoryInfo from "./pages/RegulatoryInfo";
import RegulatoryWarning from "./pages/RegulatoryWarning";
import Layout from "./pages/Layout";
import GeoInfo from "./pages/GeoInfo";
import SupportedAssets from "./pages/SupportedAssets";
import Network from "./pages/Network";
import WebTraffic from "./pages/WebTraffic";
import Lorem from "./pages/Lorem";
import Settings from "./pages/Settings";
import { Provider } from "react-redux";
import Login from "./pages/Login";
import { store } from "./redux/store";
import Home from "./pages/Home";
import React, { useState, useEffect } from "react";
import RegAlert from "./pages/RegAlert";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Vaspedia.io, Off-chain analytics</title>
        <meta name="description" content="Vaspedia has the best off-chain analytics information" />
      </Helmet>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route element={<Layout />}>
              <Route path="legal-info" element={<LegalInfo />} />
              <Route path="kyc" element={<KYC />} />
              <Route path="regulatory-info" element={<RegulatoryInfo />} />
              <Route path="geo-info" element={<GeoInfo />} />
              <Route path="supported-assets" element={<SupportedAssets />} />
              <Route path="network" element={<Network />} />
              <Route path="web-traffic" element={<WebTraffic />} />
              <Route path="other" element={<Lorem />} />
              <Route path="settings" element={<Settings />} />
              <Route path="regulatory-alert" element={<RegulatoryWarning />} />
              <Route path="regulatory-alert/:itemId" element={<RegAlert />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnlyCountries } from "../redux/slices/onlyCountries";
import { useEffect } from "react";
import {
  changeFilterKeys,
  changeFilterSearchOpen,
  changeFilters,
  getFilterKeys,
  getFilterSearchOpen,
  getFilters,
} from "../redux/slices/filters";
import { SearchCheckMark, UpDownArrow } from "../features/Symbols";
import { countryFlag } from "../features/Countries";
import { useMemo } from "react";

const FilterBy = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [expand, setExpand] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const onlyCountries = useSelector(getOnlyCountries);
  const filterSearchOpen = useSelector(getFilterSearchOpen);
  const filters = useSelector(getFilters);
  const filterKeys = useSelector(getFilterKeys);

  const filteredCountries = useMemo(() => {
    return Array.from(
      new Set(
        onlyCountries
          .filter((item) => item && item !== "Unknown" && item !== "Uknown")
          .map((item) => item.replace(/\s+$/, ""))
          .sort()
      )
    );
  }, [onlyCountries]);

  const data = [
    { id: 0, filterBy: "Primary Location" },
    { id: 1, filterBy: "Has Legal Entity in" },
    { id: 2, filterBy: "Holds Permission Issued in" },
    { id: 3, filterBy: "Offers services in" },
  ];

  const dispatch = useDispatch();

  const handleFilterBy = () => {
    setModalOpen(true);
  };

  const handleItemClick = (id) => {
    dispatch(changeFilterSearchOpen(!filterSearchOpen));
    setCurrentItem(id);
    setExpand(!expand);
  };

  const changeSelected = (item) => {
    const temp = [...filterKeys];
    const el = [...filterKeys[currentItem]];
    if (el.includes(item)) {
      const removeItem = el.filter(function (e) {
        return e !== item;
      });
      temp[currentItem] = removeItem.sort();
    } else {
      el.push(item);
      temp[currentItem] = el.sort();
    }
    dispatch(changeFilterKeys(temp));
    dispatch(changeFilterSearchOpen(false));
  };

  const submitFilter = () => {
    let submitData = [];
    for (let i = 0; i < filterKeys.length; i++) {
      let submitItem = { id: i, value: filterKeys[i] };
      submitData.push(submitItem);
    }
    dispatch(changeFilters(submitData));
    setModalOpen(false);
  };

  const setFilterKeys = () => {
    let temps = [];
    for (let i = 0; i < filters.length; i++) {
      temps.push(filters[i].value);
    }
    dispatch(changeFilterKeys(temps));
  };

  useEffect(() => {
    setFilterKeys();
  }, [filters]);

  return (
    <div className="relative flex items-center justify-end pt-0 md:pt-20 pr-4 md:pr-20">
      <button
        onClick={() => handleFilterBy()}
        className="flex items-center justify-center text-primary text-xs border border-[#FFFFFF] rounded-lg py-2 w-[150px] tracking-widest"
      >
        Filter By
      </button>
      {modalOpen && (
        <div className="absolute flex flex-col gap-3 items-start right-0 md:right-64 top-10 md:top-20 w-full md:w-[414px] p-7 bg-[#0a0a0a] rounded-2xl shadow-md shadow-[#555555] ">
          <p className=" flex justify-between w-full text-primary text-sm font-semibold tracking-widest">
            <span>Filter</span>
            <span
              className="bg-hover rounded-2xl w-5 cursor-pointer"
              onClick={() => setModalOpen(false)}
            >
              &times;
            </span>
          </p>
          <p
            className={`flex items-center justify-between w-full p-1 text-sm text-[#737373] transition-shadow duration-200 ${
              !expand ? "cursor-pointer" : ""
            }`}
            onClick={() => (!expand ? setExpand(true) : null)}
          >
            <span>Filter By :</span>
          </p>
          {data.map((item, index) => (
            <div
              className={`relative flex items-center justify-between w-full ${
                (currentItem === item.id && filterSearchOpen) ||
                !filterSearchOpen
                  ? ""
                  : " opacity-20"
              }  transition duration-100 ease-in-out h-20 border border-[#DCDCDC] rounded-lg hover:shadow-md hover:shadow-white hover:bg-input bg-black cursor-pointer`}
              key={item.id}
            >
              <div
                onClick={() => handleItemClick(item.id)}
                className="flex items-center justify-between w-full h-full"
              >
                <p className="flex flex-col text-primary text-base font-medium leading-5 text-left pl-4">
                  <span>{item.filterBy}</span>
                  <span className="font-light text-sm">
                    {filterKeys[index].length !== 0 &&
                      "( " +
                        filterKeys[index]
                          .map((a) => JSON.stringify(a))
                          .join(", ") +
                        " )"}
                  </span>
                </p>
                <p
                  className={`text-primary pr-4 py-2 ${
                    currentItem === item.id ? "border-r border-[#DCDCDC]" : ""
                  }`}
                >
                  <UpDownArrow />
                </p>
              </div>
              {currentItem === item.id && filterSearchOpen === true && (
                <div className="absolute top-20 max-h-64 w-full overflow-auto rounded-2xl bg-black py-0 text-base sm:text-sm border-2 border-[#4E5E74] scrollbar-hide z-30">
                  <div
                    className={({ active }) =>
                      `relative cursor-default select-none ${
                        active ? "bg-primary text-search" : "text-search"
                      }`
                    }
                  >
                    {filteredCountries.map((item, index) => (
                      <div
                        className={`relative flex items-center px-2 py-5 ${
                          filterKeys[currentItem].includes(item)
                            ? "bg-white text-search border-b-2 hover:bg-pending"
                            : "text-search hover:bg-primary"
                        }`}
                        onClick={() => changeSelected(item)}
                        key={"country" + index}
                      >
                        <div className="flex items-center w-7 h-7 overflow-hidden mx-6 rounded-full">
                          {countryFlag(item)}
                        </div>
                        <span
                          className={`block truncate text-base text-details font-bold ${
                            item === filterKeys[currentItem]
                              ? "tracking-wider"
                              : ""
                          }`}
                        >
                          {item}
                        </span>
                        {filterKeys[currentItem].includes(item) ? (
                          <span className="absolute inset-y-0 right-3 flex items-center pl-3 text-search">
                            <SearchCheckMark />
                          </span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="flex items-center justify-between w-full gap-5 mt-5">
            <button
              className=" flex-1 bg-normal rounded-lg text-primary text-base font-medium h-[58px] mb-5 hover:opacity-80 tracking-wider transition duration-200 "
              onClick={() => submitFilter()}
            >
              Apply
            </button>
            <button
              className=" flex-1 bg-[#868686] rounded-lg text-primary text-base font-medium h-[58px] mb-5 hover:opacity-80 tracking-wider transition duration-200 "
              onClick={() => setFilterKeys()}
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterBy;
